import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
// import ProductPricingComponent from "../../../components/ProductPricingComponent/ProductPricingComponent";
// import ProductPricingComponent1 from "../../../components/ProductPricingComponent1/ProductPricingComponent1";
import ProductPricingComponent2 from "../../../components/ProductPricingComponent2/ProductPricingComponent2";
import ProductPricingComponent3 from "../../../components/ProductPricingComponent3/ProductPricingComponent3";

export default function ProductPricing(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [id_value, SerIdValue] = useState(useParams());

  useEffect(() => {
    console.log("ProductPricing On mount :", props);
    console.log("ProductPricing context :", context);
    console.log("ProductPricing OutletContext :", OutletContext);
  }, []);

  return (
    <div>
        {/* <ProductPricingComponent2  title="Products Pricing" value={id_value} is_menu_open={OutletContext} /> */}
        <ProductPricingComponent3  title="Products Pricing" value={id_value} is_menu_open={OutletContext} />
    </div>
  );
}
