import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import ModalCreateFranchise from "./ModalCreateFranchise";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import toast, { Toaster } from "react-hot-toast";
import { AppConfig } from "../../../config";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroller";

export default function AddonItemAvailable(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [id_value, SerIdValue] = useState(useParams());
  const [addon_data, SetAddonData] = useState([]);
  const [group_addon_data, SetGroupedAddonData] = useState({});
  const [options, SetOptions] = useState();
  const [outlet_unavailable, SetOutletUnavailable] = useState({});
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);
  const [search_data, SetSearchData] = useState("");
  const [width, SetWidth] = useState(innerWidth);
  const [initial_render, SetInitialRender] = useState(true);
  const [changed_available_data, SetChangedAvailableData] = useState([]);
  const [changed_unavailable_data, SetChangedUnAvailableData] = useState([]);
  const ref : any = useRef(null);
  const [width_ref, setWidthRef] = useState(0);
  const [height_ref, setHeightRef] = useState(0);
  const [numbers, setNumbers] = useState([]);
  const [is_loading, SetIsLoading] = useState(true);
  const rows = 5; // Number of skeleton rows
  const columns = 2; // Number of skeleton columns

  useLayoutEffect(() => {
    setWidthRef(ref.current.offsetWidth);
    setHeightRef(ref.current.offsetHeight);
  }, [numbers]);

  useEffect(() => {
    console.log("AddonItemAvailable On mount :", props);
    console.log("AddonItemAvailable context :", context);
    get_addon_item(page_no, search_data,false);

    function handleWindowResize() {
      setWidthRef(ref.current.offsetWidth);
      setHeightRef(ref.current.offsetHeight);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (initial_render) {
      SetInitialRender(false);
    } else {
      if (search_data == "") {
        get_addon_item(page_no, search_data,false);
      } else {
        const timeOutId = setTimeout(() => triggerItemChange(search_data), 500);
        return () => clearTimeout(timeOutId);
      }
    }
  }, [search_data]);

  function triggerItemChange(value : any) {
    SetPageNo((prevValue: any) => {
      prevValue = 1;
      get_addon_item(prevValue, value,false);
      return prevValue;
    });
  }

  async function get_addon_item(page_no_value : any, search_value : any, load_more : any) {
    // SetIsLoading(true);
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        page: page_no_value,
        search: search_value,
      },
    };
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
      let outlets_temp = [];
      outlets_temp.push(id_value?.outlet_id);
      pass_data.get.outlets = JSON.stringify(outlets_temp);
    }
    let response: any = await api("/brand/outlet_addon_item_list", pass_data);
    console.log("/brand/outlet_addon_item_list response :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("data")) {
        let addon_data = response.response.data;
        var grouped = _.mapValues(_.groupBy(addon_data, "account_name"));
        console.log("grouped :", grouped);
        if(load_more){
          SetGroupedAddonData((prevalue : any)=>{
            Object.keys(grouped).map((key)=>{
              prevalue[key] = grouped[key];
            })
            return prevalue;
          })
          SetAddonData(addon_data);
          // SetGroupedAddonData(grouped);
          // SetAddonData(addon_data);
        }else{
          SetGroupedAddonData(grouped);
          SetAddonData(addon_data);
        }
        // SetGroupedAddonData(grouped);
        // SetAddonData(addon_data);

      } else {
        SetAddonData([]);
        SetGroupedAddonData({});
      }
      if (response.response.hasOwnProperty("options")) {
        SetOptions(response.response.options);
      }
      if (response.response.hasOwnProperty("outlet_unavailable")) {
        SetOutletUnavailable(response.response.outlet_unavailable);
      }
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        // SetNextPage(true)
      }
      SetIsLoading(false);
    }
  }

  function on_next() {
    SetPageNo((prevValue: any) => {
      prevValue = prevValue + 1;
      get_addon_item(prevValue, search_data,true);
      return prevValue;
    });
  }

  function on_prev() {
    SetPageNo((prevValue: any) => {
      prevValue = prevValue - 1;
      get_addon_item(prevValue, search_data,false);
      return prevValue;
    });
  }

  async function update_addon_item_available() {
    // let outlet_available = [];
    // let outlet_unavailable_value = [];
    // addon_data.map((a_ele: any, a_index: any) => {
    //   options?.outlets.map((o_ele: any, o_index: any) => {
    //     if (o_ele.checked) {
    //       let f_id = o_ele.id + "_" + a_ele.id;
    //       if (!Object.keys(outlet_unavailable).some((ou_ele: any) => {
    //         if (ou_ele == f_id) {
    //           return true
    //         } else {
    //           return false
    //         }

    //       })) {
    //         outlet_available.push(f_id)
    //       }
    //     }
    //   })
    // })

    // Object.keys(outlet_unavailable).map((ou_ele: any) => {
    //   outlet_unavailable_value.push(ou_ele)
    // })

    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        // available: outlet_available,
        // unavailable: outlet_unavailable_value

        available: changed_available_data,
        unavailable: changed_unavailable_data,
      },
    };
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
      let outlets_temp = [];
      outlets_temp.push(id_value?.outlet_id);
      pass_data.get.outlets = JSON.stringify(outlets_temp);
    }
    console.log("update_addon_item_available pass_data :", pass_data);
    let response: any = await api(
      "/brand/update_addon_item_available",
      pass_data
    );
    console.log("/brand/update_addon_item_available response :", response);
    if (response.status_code == 200) {
      // if (response.response.hasOwnProperty("data")) {
      //   SetAddonData(response.response.data);
      // } else {
      //   SetAddonData([]);
      // }
      // if (response.response.hasOwnProperty("options")) {
      //   SetOptions(response.response.options)
      // }
      // if (response.response.hasOwnProperty("outlet_unavailable")) {
      //   SetOutletUnavailable(response.response.outlet_unavailable)
      // }
      toast.success("Status Updated");
    }
  }

  function is_available(o_ele: any, a_ele: any) {
    let f_id = o_ele.id + "_" + a_ele.id;
    // console.log("f_id :", f_id)
    if (
      Object.keys(outlet_unavailable).some((ou_ele: any) => {
        if (ou_ele == f_id) {
          return true;
        } else {
          return false;
        }
      })
    ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className=" w-100">
      <Toaster position="top-center" reverseOrder={false} />
      {/* Header */}
      {/* <div className={OutletContext ? Object.keys(group_addon_data).length == 0 ? "head-bar w-100" : "head-bar sticky-sm  table-head-bar" : "head-bar sticky-sm"}
        style={OutletContext == false ? { width: width } : {}} > */}
      <div
        className={
          OutletContext
            ? Object.keys(group_addon_data).length == 0
              ? " head-bar w-100"
              : " head-bar sticky-sm"
            : "head-bar sticky-top"
        }
        style={
          OutletContext == false
            ? { width: width }
            : Object.keys(group_addon_data).length != 0
            ? { width: width_ref + 46 }
            : {}
        }
      >
        <div className="row align-items-center">
          {/* title */}
          <div className="col-md-6 col-9 pe-1 sm-mb-2">
            <h6 className="mb-0">Addon Item Available</h6>
          </div>

          {/* search */}
          <div className="col-md-5 col-9 ">
            <div className="row px-md-3 md-none">
              <div className="input-icons form-group">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search..."
                  onChange={(e) => {
                    SetSearchData(e.target.value);
                  }}
                />
                <div className="cursor ps-2 border-left">
                  <Ionicons name="md-search" size={18} color="#663fc3" />
                </div>
              </div>
            </div>
          </div>
          {/* count */}
          <div className="text-center col-md-1 col-3 ps-0">
            <select className="number-select form-select btn-radius form-control pe-0 ps-2">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
      </div>
      {/* body */}
      <div className="w-100">
        {/* <div className={OutletContext ? Object.keys(group_addon_data).length == 0 ? "mt-3 px-md-3 px-sm-0 w-100" : "mt-3 px-md-3 px-sm-0 overflow-page-hidden" : "mt-3 px-md-3 px-sm-0 overflow-hidden mb-5"}
          style={OutletContext == false ? { width: width } : {}} > */}

        <div
          className={
            OutletContext
              ? Object.keys(group_addon_data).length == 0
                ? "mt-3 px-md-3 px-sm-0 w-100"
                : "mt-3 px-md-3 px-sm-0"
              : "mb-5 mt-3 px-md-3 px-sm-0 overflow-hidden"
          }
          style={
            OutletContext == false
              ? { width: width }
              : Object.keys(group_addon_data).length != 0
              ? { width: width_ref + 46 }
              : {}
          }
        >
          <div className="p-3 w-100 bg-fff" ref={ref}>
            {is_loading ? (
              <div className="mt-3 table-responsive">
                <table className="table table-bordered border table-bordered table-condensed">
                  <thead className="sticky-table-head bg-fff">
                    <tr className="th-prod">
                      <th className="bg-fff col p-0" rowSpan="2">
                        <div className="px-3 py-4">
                          <Skeleton width={100} />
                        </div>
                      </th>
                      {Array.from({ length: columns }).map((_, index) => (
                        <th className="col p-0" key={index} colSpan={columns}>
                          <div className="text-center p-2">
                            <Skeleton width={80} />
                          </div>
                        </th>
                      ))}
                    </tr>
                    <tr>
                      {Array.from({ length: columns }).map((_, o_index) =>
                        Array.from({ length: columns }).map((_, c_index) => (
                          <th className="col p-0" key={o_index + "_" + c_index}>
                            <div className="p-2 text-center">
                              <Skeleton width={50} />
                            </div>
                          </th>
                        ))
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: rows }).map((_, index) => (
                      <tr key={index}>
                        <td>
                          <Skeleton width={100} />
                        </td>
                        {Array.from({ length: columns * columns }).map(
                          (_, c_index) => (
                            <td key={c_index}>
                              <Skeleton width={80} />
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : Object.keys(group_addon_data).length == 0 ? (
              <div className="center p-3 bg-fff">
                <div>
                  <img
                    src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img"
                  />
                  <p className="text-center">No data found!</p>
                </div>
              </div>
            ) : (
              <div className="table-responsive">
                <InfiniteScroll
                  // className="row"
                  dataLength={Object.keys(group_addon_data).length}
                  next={() => {
                    on_next();
                  }}
                  hasMore={next_page}
                  loader={
                    <div className="d-flex align-items-center justify-content-center text-center p-5">
                      <div className="lds-dual-ring"></div>
                      <span className=" small ps-2">Just a second ...</span>
                    </div>
                  }
                >
                  <table
                    border="1"
                    className="table align-middle table-bordered table-condensed"
                  >
                    <thead className="sticky-table-head">
                      <tr>
                        <th className="th-prod col p-0">
                          <div className="border-bottom border-right ps-3 p-2">
                            {" "}
                            Name
                          </div>
                        </th>
                        {options?.outlets.map((o_ele: any, o_index: any) =>
                          o_ele.checked ? (
                            <th key={o_index} className="col p-0">
                              <div className="text-center border-bottom border-right p-2 border-top">
                                {o_ele.name}
                              </div>
                            </th>
                          ) : null
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {/* {addon_data.map((a_ele: any, a_index: any)=>(
                          <tr key={a_index}>
                            <td>{a_ele.name}</td>
                            {options?.outlets.map((o_ele: any, o_index: any)=>(
                                o_ele.checked ? (
                                    <td key={o_index}> 
                                    <div className="ms-2 center mt-2 form-check form-switch">
                                        {o_ele.addons.hasOwnProperty(a_ele.id) ? (
                                            <label>
                                                <input
                                                type="checkbox"
                                                className="form-check-input"
                                                ref={input => {
                                                if (input) {
                                                    input.checked = is_available(o_ele,a_ele);
                                                }
                                                }}
                                                onChange={(e)=>{
                                                console.log("e.target.checked :",e.target.checked)
                                                SetOutletUnavailable((prevValue:any)=>{
                                                    let f_id = o_ele.id + "_" + a_ele.id;
                                                    if(e.target.checked){
                                                        delete prevValue[f_id]
                                                    }else{
                                                        prevValue[f_id] = true;
                                                    }
                                                    console.log("prevValue :",prevValue)
                                                    return { ...prevValue}
                                                })
                                                }}
                                            />
                                            </label>
                                        ) : null}
                                        </div>
                                    </td>
                                ) : null
                            ))}
                          </tr>
                        ))} */}

                      {Object.keys(group_addon_data).map((key) => (
                        <>
                          <tr key={key} className="border-bottom">
                            <th className="td-prod p-0">
                              <div className=" bg-fff p-2 ps-3">
                                <p className="text-black fw-bold fs-7">{key}</p>
                              </div>
                            </th>
                          </tr>
                          {group_addon_data[key].map(
                            (a_ele: any, a_index: any) => (
                              <tr key={a_index}>
                                <td className="td-prod p-0">
                                  <div className="border-right bg-fff p-2 td-min-height ps-3">
                                    {a_ele.name}
                                  </div>
                                </td>
                                {options?.outlets.map(
                                  (o_ele: any, o_index: any) =>
                                    o_ele.checked ? (
                                      <td key={o_index}>
                                        <div className="ms-2 center mt-2 form-check form-switch">
                                          {o_ele.addons.hasOwnProperty(
                                            a_ele.id
                                          ) ? (
                                            <label>
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                ref={(input) => {
                                                  if (input) {
                                                    input.checked = is_available(
                                                      o_ele,
                                                      a_ele
                                                    );
                                                  }
                                                }}
                                                onChange={(e) => {
                                                  console.log(
                                                    "e.target.checked :",
                                                    e.target.checked
                                                  );
                                                  // SetOutletUnavailable((prevValue: any) => {
                                                  //   let f_id = o_ele.id + "_" + a_ele.id;
                                                  //   if (e.target.checked) {
                                                  //     delete prevValue[f_id]
                                                  //   } else {
                                                  //     prevValue[f_id] = true;
                                                  //   }
                                                  //   console.log("prevValue :", prevValue)
                                                  //   return { ...prevValue }
                                                  // })

                                                  SetOutletUnavailable(
                                                    (prevValue: any) => {
                                                      let f_id =
                                                        o_ele.id + "_" + a_ele.id;
                                                      if (e.target.checked) {
                                                        delete prevValue[f_id];
                                                        SetChangedAvailableData(
                                                          (prevValue1: any) => {
                                                            prevValue1.push(f_id);
                                                            return [
                                                              ...prevValue1,
                                                            ];
                                                          }
                                                        );
                                                        SetChangedUnAvailableData(
                                                          (prevValue1: any) => {
                                                            let index_value =
                                                              prevValue1.findIndex(
                                                                (k_ele) =>
                                                                  k_ele == f_id
                                                              );
                                                            if (
                                                              index_value > -1
                                                            ) {
                                                              prevValue1.splice(
                                                                index_value,
                                                                1
                                                              );
                                                            }
                                                            return [
                                                              ...prevValue1,
                                                            ];
                                                          }
                                                        );
                                                      } else {
                                                        prevValue[f_id] = true;
                                                        SetChangedAvailableData(
                                                          (prevValue1: any) => {
                                                            let index_value =
                                                              prevValue1.findIndex(
                                                                (k_ele) =>
                                                                  k_ele == f_id
                                                              );
                                                            if (
                                                              index_value > -1
                                                            ) {
                                                              prevValue1.splice(
                                                                index_value,
                                                                1
                                                              );
                                                            }
                                                            return [
                                                              ...prevValue1,
                                                            ];
                                                          }
                                                        );
                                                        SetChangedUnAvailableData(
                                                          (prevValue1: any) => {
                                                            prevValue1.push(f_id);
                                                            return [
                                                              ...prevValue1,
                                                            ];
                                                          }
                                                        );
                                                      }
                                                      console.log(
                                                        "prevValue :",
                                                        prevValue
                                                      );
                                                      return { ...prevValue };
                                                    }
                                                  );
                                                }}
                                              />
                                            </label>
                                          ) : null}
                                        </div>
                                      </td>
                                    ) : null
                                )}
                              </tr>
                            )
                          )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            )}

            <div className="mt-3 mb-4 d-flex align-items-center">
              <div className="d-flex align-items-center">
                {page_no > 1 ? (
                  <button
                    className="me-2 center btn btn-white btn-radius fw-bold btn-sm"
                    onClick={() => {
                      console.log("Prev");
                      on_prev();
                    }}
                  >
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "3172f69196e958f3bc4edb405f97ce29.svg"
                      }
                      className="w-20px"
                    />
                    {/* <MaterialCommunityIcons name="chevron-double-left" size={18} color="#3f41c3" /> */}
                    <p className="text-prime ps-2">Prev</p>
                  </button>
                ) : null}

                {next_page ? (
                  <button
                    className="center btn btn-white btn-radius fw-bold btn-sm"
                    onClick={() => {
                      console.log("Next");
                      on_next();
                    }}
                  >
                    <p className="text-prime pe-2">Next</p>
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "ffb1885fc1b6450d7b6c9652189cdcb2.svg"
                      }
                      className="w-20px"
                    />
                    {/* <MaterialCommunityIcons name="chevron-double-right" size={18} color="#fff" /> */}
                  </button>
                ) : null}
              </div>

              {/* update_button mobile */}
              {Object.keys(group_addon_data).length != 0 ? (
                <div className="ms-auto">
                  <button
                    className="btn bg-prime text-white btn-radius px-4 btn-sm"
                    onClick={() => {
                      update_addon_item_available();
                      // console.log("Franchise new");
                      // SetModalFranchise((prevValue) => {
                      //   prevValue.is_open = true;
                      //   prevValue.type = "create";
                      //   return { ...prevValue };
                      // });
                    }}
                  >
                    Update
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
