import React, { useState, useEffect, useContext } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import bootstrap from "../../../../assets/libraries/bootstrap/js/bootstrap.min.js";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../../../utils/Api";
import { map, omit } from "lodash";
import { useWizard, Wizard } from "react-use-wizard";
// import ModalLocation from "../ModalLocation";
import LocationPicker from "../../../../components/LocationPicker/LocationPicker";

// import ModalLocationDriver from "../ModalLocationDriver";
import ModalAddGallery from "../../Gallery/ModalAddGallery";
import {
  FontAwesome,
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import DataContext from "../../../../utils/Context/DataContext";
import { AppHelper } from "../../../../utils/Helper";

// import ModalLocation from "../ModalLocation/ModalLocation";

import { CSSTransition } from "react-transition-group";
import toast from "react-hot-toast";
// import {
//   withGoogleMap,
//   GoogleMap,
//   withScriptjs,
//   InfoWindow,
//   Marker,
// } from "react-google-maps";
// import Geocode from "react-geocode";
// import Autocomplete from "react-google-autocomplete";
// import { GoogleMapsAPI } from "../../client-config";
// Geocode.setApiKey(GoogleMapsAPI);
// Geocode.enableDebug();

// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
// let map: google.maps.Map;

//     function initMap(): void {
//       map = new google.maps.Map(
//         document.getElementById("map-test") as HTMLElement,
//         {
//           center: { lat: -34.397, lng: 150.644 },
//           zoom: 8,
//         }
//       );
//     }

//     declare global {
//       interface Window {
//         initMap: () => void;
//       }
//     }
const animatedComponents = makeAnimated();

const outlet_initial_value = {
  selected_user: { name: "" },
  user_id: "",
  selected_roles: [],
  selected_custom_roles: [],
  selected_franchise: [],
  roles: { roles: [], custom_roles: [] },
  outlet_name: "",
  address: "",
  map_address: "",
  mobile: "",
  whatsapp: "",
  email: "",
  website: "",
  country: "99",
  state: "31",
  door_no: "",
  city: "",
  pincode: "",
  selected_image: [],
  selected_banner: [],
  approx_preparation_time: "",
  cod: "0",
};

let myModal: any = {};

export default function CreateBrandOutlet(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [errors, setErrors] = useState({});
  const [outlet_data, SetOutletData] = useState(outlet_initial_value);
  const [user_role_options, SetUserRoleOptions] = useState([]);
  const [custom_role_options, SetCustomRoleOptions] = useState([]);
  const [countries_options, SetCountriesOptions] = useState([]);
  const [states, SetStates] = useState([]);
  const [width, SetWidth] = useState(innerWidth);
  const [text, setText] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [user_data, SetUserData] = useState([]);
  const [next_page, SetNextPage] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const [new_outlet_id, SetNewOutletId] = useState(null);
  const [save_error, SetSaveError] = useState(false);
  const [combi_data, SetCombiData] = useState([]);
  const [location_data, SetLocationData] = useState([]);
  const [data, SetData] = useState([]);

  // const [mapModal, SetMapModal] = useState({
  //   is_open: false,
  //   latitude: "",
  //   longitude: "",
  //   // id: "",
  // });

  const [map_modal, SetMapModal] = useState({
    is_open: false,
    data: {},
    from: "",
    latitude: "",
    longitude: "",
    address: "",
  });
  const [mapdriverModal, SetMapDriverModal] = useState({
    is_open: false,
    // id: "",
  });
  const [franchise_options, SetFranchiseOptions] = useState([]);
  //OutletData End
  const [modal_image, SetModalImage] = useState({
    is_open: false,
    selected_images: [],
  });
  const [modal_banner, SetModalBanner] = useState({
    is_open: false,
    selected_banner: [],
  });

  useEffect(() => {
    // console.log("ModalCreateOutlet On mount :", props);
    // let myModal1: any = document.getElementById("CreateOutletModal");
    // myModal1.addEventListener("hidden.bs.modal", function (event: any) {
    //   console.log("event :", event);
    SetOutletData(outlet_initial_value);
    setText("");
    SetPageNo(1);
    get_init();
    get_states();
    get_users(page_no);

    //   props.close(close_data);
    // });
    // var importedScript = document.createElement("script");
    // importedScript.src =
    //   "https://maps.googleapis.com/maps/api/js?key="+AppConfig.google_map_api+"&callback=initMap&v=weekly";
    // document.head.appendChild(importedScript);

    // window.initMap = initMap;
  }, []);

  useEffect(() => {
    // if (props.open) {
    //   console.log("ModalCreateOutlet On mount :", props);
    //   myModal = new bootstrap.Modal(
    //     document.getElementById("CreateOutletModal"),
    //     {}
    //   );
    //   myModal.show();
    // get_init();
    // get_states();
    // get_users(page_no);
  }, []);

  async function get_init() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
    };

    if (props.franchise_id != null) {
      pass_data.get.franchise = props.franchise_id;
    }

    let data_res = await api("/brand/create_outlet_init", pass_data);
    console.log("get_outlet_init response :", data_res.response);
    SetData(data_res.response);
    // let option_data = [];
    // Object.keys(data_res.response).map((key, index) => {
    //   let push_data = {};
    //   if (key == "custom_roles") {
    //     push_data.label = "Custom Roles";
    //     for (var i = 0; i < data_res.response[key].length; i++) {
    //       data_res.response[key][i].type = "custom_roles";
    //     }
    //   } else if (key == "roles") {
    //     push_data.label = "Roles";
    //     for (var i = 0; i < data_res.response[key].length; i++) {
    //       data_res.response[key][i].type = "roles";
    //     }
    //   }
    //   push_data.options = data_res.response[key];
    //   option_data.push(push_data);
    // });
    // console.log("Option Data :", option_data);
    // SetUserRoleOptions(option_data);

    if (data_res.response.hasOwnProperty("roles")) {
      SetUserRoleOptions(data_res.response.roles);
    }

    if (data_res.response.hasOwnProperty("custom_roles")) {
      SetCustomRoleOptions(data_res.response.custom_roles);
    }

    if (data_res.response.hasOwnProperty("franchise")) {
      SetFranchiseOptions(data_res.response.franchise);
    }

    if (data_res.response.hasOwnProperty("countries")) {
      SetCountriesOptions(data_res.response.countries);
    }
  }

  async function get_users(page_value: any, search_value = null) {
    let pass_data = {};
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,
        },
      };
    }
    let response = await api("/brand/get_users", pass_data);
    console.log("Outlet create /brand/get_users response :", response);
    if (response.response.data) {
      SetUserData(response.response.data);
    }
    SetNextPage(response.response.next_page);
  }

  // function onTextChanged(e: any) {
  //   clearTimeout(timer);
  //   const value = e.target.value;
  //   setText(value);
  //   console.log("Text onchange: ", value);
  //   if (value == "") {
  //     let page_no_value = 1;
  //     SetPageNo(page_no_value);
  //     get_users(page_no_value);
  //   } else {
  //     SetTimer(
  //       setTimeout(() => {
  //         get_users(1, value);
  //       }, WAIT_INTERVAL)
  //     );
  //   }
  // }

  const onTextChanged = (e: any) => {
    clearTimeout(timer);
    const value = e.target.value;
    const updatedSelectedUser = { ...outlet_data.selected_user, name: value };

    SetOutletData((oldValues) => ({
      ...oldValues,
      selected_user: updatedSelectedUser,
      // user_id: item.id,
    }));
    // SetOutletData({ selected_user: updatedSelectedUser });
    console.log("Text onchange: ", value);

    if (value === "") {
      const page_no_value = 1;
      SetPageNo(page_no_value);
      get_users(page_no_value);
    } else {
      SetTimer(
        window.setTimeout(() => {
          get_users(1, value);
        }, WAIT_INTERVAL)
      );
    }
  };

  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != "") {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
        },
      };
    }
    let response = await api("/brand/get_users", pass_data);
    console.log("Users get_users response :", response);
    SetUserData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  function outlet_value_on_change(name: any, value: any) {
    validate(name, value);
    SetOutletData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  async function create_outlet() {
    console.log("create_outlet data :", outlet_data);

    // console.log("create_outlet data :", mapModal);

    if (outlet_data.name !== "" || outlet_data.user_id !== "") {
      let post_value = {
        user_id: outlet_data.user_id,
        roles: {},
        name: outlet_data.outlet_name,
        address: outlet_data.address,
        map_address: outlet_data.map_address,
        mobile: outlet_data.mobile,
        whatsapp: outlet_data.whatsapp,
        email: outlet_data.email,
        website: outlet_data.website,
        country: outlet_data.country,
        state: outlet_data.state,
        door_no: outlet_data.door_no,
        city: outlet_data.city,
        pincode: outlet_data.pincode,
        approx_preparation_time: outlet_data.approx_preparation_time,

        latitude: map_modal.latitude,
        longitude: map_modal.longitude,
        cod: outlet_data.cod,
        image: "",
        banner_image: "",
        selected_franchise:
          franchise_options.find((ele) => ele.id === id_value.franchise_id) ||
          [],
        // address: location_data,
      };

      console.log(post_value);

      {
        outlet_data.selected_image[0]?.id &&
          (post_value.image = outlet_data.selected_image[0]?.id);
      }
      {
        outlet_data.selected_banner[0]?.id &&
          (post_value.banner_image = outlet_data.selected_banner[0]?.id);
      }

      // image:  outlet_data.selected_image,
      // banner_image:outlet_data.selected_banner,
      let roles_value = {
        roles: [],
        custom_roles: [],
      };
      outlet_data.selected_roles.map((item: any, index: any) => {
        roles_value.roles.push(item.value);
      });

      outlet_data.selected_custom_roles.map((item: any, index: any) => {
        roles_value.custom_roles.push(item.value);
      });

      post_value.roles = roles_value;
      console.log("post_value :", post_value);
      let pass_data: any = {
        get: {
          brand: id_value.brand_id,
        },
        post: post_value,
      };

      if (props.franchise_id != null) {
        pass_data.get.franchise = props.franchise_id;
      }

      console.log(pass_data);

      // if (outlet_data.selected_franchise != {}) {
      //   pass_data.get.franchise = outlet_data.selected_franchise.value;
      // }

      let data_res = await api("/brand/create_outlet", pass_data);
      console.log("get_outlet_init response :", data_res.response);
      if (data_res.status_code == 201) {
        // let item = close_data;
        // item.action = "save";
        // let outlet_value = {
        //   id: data_res.response.id,
        //   name: outlet_data.name,
        //   address: outlet_data.address,
        //   mobile: outlet_data.mobile,
        // };
        // item.value = outlet_value;
        // console.log("Close data : ", item);
        // SetCloseData(item);
        SetOutletData(outlet_initial_value);
        // myModal.hide();
        history.back();
      } else if (data_res.status_code == 422) {
        toast.error("Please fill manditory data");
      }
    } else {
      SetSaveError(true);
    }
  }

  function validate(name: any, value: any) {
    //A function to validate each input values
    console.log("name :", name);
    console.log("value  :", value);
    switch (name) {
      case "name":
        if (value.length <= 2) {
          // we will set the error state

          setErrors({
            ...errors,
            name: "Outlet name atleast have 3 letters",
          });
        } else {
          let newObj = omit(errors, "name");
          setErrors(newObj);
        }
        break;

      case "address":
        if (value.length <= 0) {
          setErrors({
            ...errors,
            address: "Enter valid address",
          });
        } else {
          let newObj = omit(errors, "address");
          setErrors(newObj);
        }
        break;

      case "mobile":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            mobile: "Enter valid mobile number",
          });
        } else {
          let newObj = omit(errors, "mobile");
          setErrors(newObj);
        }
        break;

      case "whatsapp":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            whatsapp: "Enter valid whatsapp number",
          });
        } else {
          let newObj = omit(errors, "whatsapp");
          setErrors(newObj);
        }
        break;

      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "Enter email not vaild",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      case "website":
        if (
          !new RegExp(
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            website: "Enter website not vaild",
          });
        } else {
          let newObj = omit(errors, "website");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  }

  async function get_states() {
    let pass_data = {
      get: {
        country_id: 99,
      },
    };
    let response = await api("/init/get_states", pass_data);
    console.log("/brand/settings response :", response);
    if (response.status_code == 200) {
      SetStates(response.response.date);
    }
  }

  function onImgSelect() {
    SetModalImage((prevValue: any) => {
      prevValue.is_open = !prevValue.is_open;
      // prevValue.selected_images = data.selected_img;
      return { ...prevValue };
    });
  }

  function onBnrSelect() {
    SetModalBanner((prevValue: any) => {
      prevValue.is_open = !prevValue.is_open;
      // prevValue.selected_images = data.selected_img;
      return { ...prevValue };
    });
  }

  return (
    <div>
      <div
        className={
          OutletContext ? "head-bar sticky-sm" : "head-bar px-1 sticky-sm"
        }
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="row align-items-center pb-2 px-2">
          <div className="col-md-5 sm-mb-2">
            <div className="d-flex align-items-center">
              <div
                className="cursor pe-3"
                onClick={() => {
                  window.history.back();
                }}
              >
                <Ionicons name="arrow-back" size={18} color="black" />
              </div>
              <h6 className="mb-0 ">Create Outlet</h6>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          OutletContext ? "mt-3 px-md-3 px-sm-0" : "mt-3  px-md-3 px-sm-0"
        }
        style={OutletContext == false ? { width: width } : {}}
      >
        <form className="bg-fff p-3">
          {/* <script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?libraries=places&amp;key="+AppConfig.google_map_api+"></script> */}
          <div>
            <div className="mb-2 sticky-top">
              {userOpen ? (
                <div className="d-flex align-items-center  mb-2">
                  <div
                    className="pe-2 cursor"
                    onClick={() => {
                      console.log("back outlet");
                      setUserOpen(false);
                    }}
                  >
                    <MaterialCommunityIcons
                      name="arrow-left"
                      size={20}
                      color="black"
                    />
                  </div>
                  <h6 className="mb-0 modal-title" id="ModalLabel">
                    Select User
                  </h6>
                </div>
              ) : (
                // <h6 className="mb-0  modal-title" id="ModalLabel">
                //   Create Outlet
                // </h6>
                <></>
              )}
            </div>
            <div>
              {userOpen ? (
                <div className="row pt-3">
                  <div className="col-12">
                    <label className="form-label" htmlFor="userName">
                      Select user
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="userName"
                      placeholder="Select User Name"
                      value={outlet_data.selected_user.name}
                      onChange={(e) => {
                        onTextChanged(e);
                      }}
                    />
                  </div>

                  <h6 className="my-4">Available Users</h6>
                  <div className="mt-2 px-3">
                    {user_data.map((item, sugIndex) => (
                      <div
                        className="cursor d-flex align-items-center mb-3"
                        key={sugIndex}
                        onClick={() => {
                          SetOutletData((oldValues) => ({
                            ...oldValues,
                            selected_user: item,
                            user_id: item.id,
                          }));
                          setUserOpen(false);
                          console.log("Select item:", item);
                        }}
                      >
                        {item.dp_url ? (
                          <img
                            // src={
                            //   "https://cdn.1superapp.com/images/320/" +
                            //   img_src.url
                            // }
                            src={item.dp_url}
                            height={20}
                            width={20}

                            // className="cover_img_brand radius"
                          />
                        ) : (
                          <div className="pb-2">
                            <FontAwesome5
                              name="user-alt"
                              size={14}
                              color="#777"
                            />
                          </div>
                        )}

                        <span>
                          <p className="ps-2 text-black pb-2">{item.name}</p>
                          <p className="ps-2 text-black pb-2">
                            {item.user_name}
                          </p>
                        </span>
                      </div>
                    ))}
                  </div>

                  {next_page ? (
                    <p
                      className="text-prime fw-bold mt-3 cursor fs-7"
                      onClick={() => {
                        load_more();
                      }}
                    >
                      Load More
                    </p>
                  ) : null}
                </div>
              ) : (
                <div className="row">
                  {/* Outlet Name */}
                  {console.log(outlet_data)}

                  <div className="col-12 col-md-5 mb-3">
                    <label className="form-label" htmlFor="name">
                      Outlet Name*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={outlet_data.outlet_name}
                      onChange={(e) => {
                        outlet_value_on_change("outlet_name", e.target.value);
                        SetSaveError(false);
                      }}
                    />
                    {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* Franchise */}

                  <div className="col-12 col-md-7 mb-3 ">
                    <label className="form-label" htmlFor="selected_roles">
                      Select Franchise
                    </label>
                    <Select
                      components={animatedComponents}
                      className="basic-select"
                      classNamePrefix="select Franchise"
                      placeholder="Select Franchise"
                      id="selected_roles"
                      isMulti
                      Group
                      value={
                        franchise_options.find(
                          (ele) => ele.id === id_value.franchise_id
                        ) || outlet_data.selected_franchise
                      }
                      options={franchise_options}
                      onChange={(e, action) => {
                        console.log("User Role on change e :", e);
                        console.log("User Role on change action :", action);

                        SetOutletData((oldValues: any) => ({
                          ...oldValues,
                          selected_franchise: e,
                        }));
                        SetSaveError(false);
                      }}
                    />
                    {errors.selected_roles && (
                      <small className="text-red">
                        {errors.selected_roles}
                      </small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* Outlet Name
                  <div className="col-12 col-md-5 mb-3">
                    <label className="form-label" htmlFor="name">
                      Outlet Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      onChange={(e) => {
                        outlet_value_on_change("name", e.target.value);
                        SetSaveError(false);
                      }}
                    />
                    {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div> */}

                  {/* Door No */}
                  <div className="col-12 col-md-2 mb-3">
                    <label className="form-label" htmlFor="name">
                      Door No
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={outlet_data.door_no}
                      onChange={(e) => {
                        outlet_value_on_change("door_no", e.target.value);
                        SetSaveError(false);
                      }}
                    />
                    {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* Address */}
                  <div className="col-12 col-md-8 mb-3">
                    <label className="form-label">Address</label>
                    <textarea
                      rows={1}
                      className="form-control"
                      value={outlet_data.address}
                      onChange={(e) => {
                        outlet_value_on_change("address", e.target.value);
                      }}
                    ></textarea>
                    {errors.address && (
                      <small className="text-red">{errors.address}</small>
                    )}
                  </div>

                  {/* City*/}
                  <div className="col-12 col-md-3 mb-3">
                    <label className="form-label" htmlFor="name">
                      City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={outlet_data.city}
                      onChange={(e) => {
                        outlet_value_on_change("city", e.target.value);
                        SetSaveError(false);
                      }}
                    />
                    {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* Countries */}
                  <div className="col-12 col-md-3 mb-3">
                    <label className="form-label" htmlFor="selected_roles">
                      Country
                    </label>
                    <select
                      className="form-select form-select-lg mb-3"
                      aria-label=".form-select-lg example"
                      value={outlet_data.country}
                      onChange={(e) => {
                        outlet_value_on_change("country", e.target.value);
                      }}
                    >
                      {countries_options.map((ele: any) => (
                        <option
                          value={ele.id}
                          selected={ele.id == "99" ? true : false}
                        >
                          {ele.nicename}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* States */}
                  {outlet_data.country == "99" && (
                    <div className="col-md-4 mb-3">
                      <label className="form-label" htmlFor="selected_roles">
                        State
                      </label>

                      <select
                        className="form-select form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        value={outlet_data.state}
                        onChange={(e) => {
                          outlet_value_on_change("state", e.target.value);
                        }}
                      >
                        {states.map((ele: any) => (
                          <option
                            value={ele.id}
                            selected={ele.id == "31" ? true : false}
                          >
                            {ele.nicename}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {/* pincode */}
                  <div className="col-12 col-md-2 mb-3">
                    <label className="form-label" htmlFor="name">
                      Pincode
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={outlet_data.pincode}
                      onChange={(e) => {
                        outlet_value_on_change("pincode", e.target.value);
                        SetSaveError(false);
                      }}
                    />
                    {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* Location */}
                  <div className="col-12 col-md-9 mb-3">
                    <label className="form-label">Map Location</label>
                    <input
                      className="form-control"
                      value={outlet_data.map_address}
                      onClick={() => {
                        // SetMapModal({ is_open: true, data: {}, from: "" });

                        SetMapModal((prevalue) => {
                          prevalue.is_open = true;
                          prevalue.from = "create";
                          prevalue.latitude = map_modal.latitude;
                          prevalue.longitude = map_modal.longitude;
                          return { ...prevalue };
                        });
                      }}
                    ></input>
                    {/* {errors.address && (
                      <small className="text-red">{errors.address}</small>
                    )} */}
                  </div>

                  {/* driver Location */}

                  {/* <div className="col-12 col-md-6 mb-3">
                    <label className="form-label">Driver Location</label>
                    <input
                      className="form-control"
                      onClick={() => {
                        SetMapDriverModal({
                          is_open: true,
                        });
                      }}
                    ></input>
                    {errors.address && (
                      <small className="text-red">{errors.address}</small>
                    )}
                  </div> */}

                  {/* Mobile Number */}
                  <div className="col-12 col-md-6 mb-3">
                    <label className="form-label">Mobile Number*</label>
                    <input
                      // type="number"
                      className="form-control"
                      value={outlet_data.mobile}
                      onChange={(e) => {
                        if (e.target.value.length == 11) return false;
                        let val = e.target.value.slice(0, 10);
                        outlet_value_on_change("mobile", val);
                        SetSaveError(false);
                      }}
                    />
                    {errors.mobile && (
                      <small className="text-red">{errors.mobile}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* Whatsapp Number */}
                  <div className="col-12 col-md-6 mb-3">
                    <label className="form-label">Whatsapp Number</label>
                    <input
                      // type="number"
                      value={outlet_data.whatsapp}
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value.length == 11) return false;
                        let val = e.target.value.slice(0, 10);
                        outlet_value_on_change("whatsapp", val);
                        SetSaveError(false);
                      }}
                    />
                    {errors.whatsapp && (
                      <small className="text-red">{errors.whatsapp}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* Email */}
                  <div className="col-12 col-md-6 mb-3">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      value={outlet_data.email}
                      onChange={(e) => {
                        outlet_value_on_change("email", e.target.value);
                      }}
                    />
                    {errors.email && (
                      <small className="text-red">{errors.email}</small>
                    )}
                  </div>

                  {/* Website */}
                  <div className="col-12 col-md-6 mb-3">
                    <label className="form-label">Website</label>
                    <input
                      type="text"
                      className="form-control"
                      value={outlet_data.website}
                      onChange={(e) => {
                        outlet_value_on_change("website", e.target.value);
                      }}
                    />
                    {errors.website && (
                      <small className="text-red">{errors.website}</small>
                    )}
                  </div>

                  {/* Add Image */}
                  <div className="col-12 col-md-6 mb-3 mt-3">
                    <label className="form-label text-black fw-bold">
                      {" "}
                      Outlet Logo{" "}
                    </label>

                    {/* Image */}
                    <div>
                      {outlet_data.selected_image.length > 0 ? (
                        <div className="border radius p-1 mt-2">
                          {/* <div className="row py-3 px-2 overflow-img-scroll "> */}
                          {outlet_data.selected_image.map(
                            (img_src, img_index) => (
                              <div className=" p-1" key={img_index}>
                                <div className="card">
                                  <img
                                    // src={
                                    //   "https://cdn.1superapp.com/images/320/" +
                                    //   img_src.url
                                    // }
                                    src={AppHelper.imageSrc(
                                      data.image_base_url,
                                      img_src.url,
                                      { format: "webp", width: 400 }
                                    )}
                                    className="cover_img_brand radius"
                                  />
                                </div>
                              </div>
                            )
                          )}
                          {/* </div> */}
                          <div
                            className="center cursor pt-4 pb-3 bg-fff add-more shadow-top  border border-radius"
                            onClick={() => {
                              onImgSelect(outlet_data.selected_image);
                            }}
                          >
                            <MaterialCommunityIcons
                              name="image-plus"
                              size={18}
                              color="gray"
                            />
                            <p className="ps-2 mb-0 text-darkgray  ">
                              Add / Select images
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="center cursor py-2 border radius mt-2 border-radius"
                          onClick={() => {
                            onImgSelect(modal_image.selected_images);
                          }}
                        >
                          <div className="p-3 text-center">
                            <MaterialCommunityIcons
                              name="image-plus"
                              size={22}
                              color="gray"
                            />
                            <p className="ps-2 mt-3 text-darkgray">
                              Add / Select Images
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        outlet_value_on_change("website", e.target.value);
                      }}
                    /> */}
                    {/* {errors.website && (
                      <small className="text-red">{errors.website}</small>
                    )} */}
                  </div>

                  {/* Banner Image */}
                  <div className="col-12 col-md-6 mb-3 mt-3">
                    <label className="form-label text-black fw-bold">
                      Banner Image
                    </label>

                    {/* Image */}
                    <div>
                      {outlet_data.selected_banner.length > 0 ? (
                        <div className="border radius p-1 mt-2">
                          {/* <div className="row py-3 px-2 overflow-img-scroll "> */}
                          {outlet_data.selected_banner.map(
                            (img_src, img_index) => (
                              <div className="p-1" key={img_index}>
                                <div className="card">
                                  <img
                                    // src={
                                    //   "https://cdn.1superapp.com/images/320/" +
                                    //   img_src.url
                                    // }
                                    src={AppHelper.imageSrc(
                                      data.image_base_url,
                                      img_src.url,
                                      { format: "webp", width: 400 }
                                    )}
                                    className="cover_img_brand radius"
                                  />
                                </div>
                              </div>
                            )
                          )}
                          {/* </div> */}
                          <div
                            className="center cursor pt-4 pb-3 bg-fff add-more shadow-top border border-radius"
                            onClick={() => {
                              onBnrSelect(outlet_data.selected_banner);
                            }}
                          >
                            <MaterialCommunityIcons
                              name="image-plus"
                              size={18}
                              color="gray"
                            />
                            <p className="ps-2 mb-0 text-darkgray ">
                              Add / Select Banners
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="center cursor py-2 border radius mt-2  border border-radius"
                          onClick={() => {
                            onBnrSelect(modal_banner.selected_banner);
                          }}
                        >
                          <div className="p-3 text-center">
                            <MaterialCommunityIcons
                              name="image-plus"
                              size={22}
                              color="gray"
                            />
                            <p className="ps-2 mt-3 text-darkgray">
                              Add / Select Banners
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        outlet_value_on_change("website", e.target.value);
                      }}
                    /> */}
                    {/* {errors.website && (
                      <small className="text-red">{errors.website}</small>
                    )} */}
                  </div>

                  {/* cod */}
                  <div className="col-12 col-md-6  mb-3">
                    <label className="form-label d-flex align-items-center mb-3 cursor">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        name={"filter_category"}
                        value={outlet_data.cod}
                        onChange={(event) => {
                          outlet_value_on_change(
                            "cod",
                            outlet_data.cod == "1" ? "0" : "1"
                          );
                        }}
                      />
                      <p className="capitalize">Allow Cash On Delivery Order</p>
                    </label>
                  </div>
                  {/* prep time */}
                  <div className="col-12 col-md-6 mb-3">
                    <label className="form-label" htmlFor="name">
                      Approx Preperation Time
                    </label>

                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control "
                        id="name"
                        value={outlet_data.approx_preparation_time}
                        onChange={(e) => {
                          outlet_value_on_change(
                            "approx_preparation_time",
                            e.target.value
                          );
                          SetSaveError(false);
                        }}
                      />
                      <span className="input-group-text bg-primary">Min</span>
                    </div>
                    {/* {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null} */}
                  </div>
                  <hr />

                  {/* User Name */}
                  <div className="col-12 col-md-5 mb-3 ">
                    <label htmlFor="userName" className="form-label">
                      User Name*
                    </label>
                    <div
                      onClick={() => {
                        setUserOpen(true);
                        SetSaveError(false);
                      }}
                      className="cursor"
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="userName"
                        value={outlet_data.selected_user?.name}
                        placeholder="Select User Name"
                      />
                    </div>
                    {errors.userName && (
                      <small className="text-red">{errors.userName}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* Role */}
                  <div className="col-12 col-md-6 mb-3">
                    <label className="form-label" htmlFor="selected_roles">
                      Select Role*
                    </label>
                    <Select
                      components={animatedComponents}
                      className="basic-select"
                      classNamePrefix="select Role"
                      placeholder="Select Role"
                      id="selected_roles"
                      isMulti
                      Group
                      value={outlet_data.selected_roles}
                      options={user_role_options}
                      // isSearchable={ false }
                      onChange={(e, action) => {
                        console.log("User Role on change e :", e);
                        console.log("User Role on change action :", action);

                        SetOutletData((oldValues: any) => ({
                          ...oldValues,
                          selected_roles: e,
                        }));
                        SetSaveError(false);
                      }}
                    />
                    {errors.selected_roles && (
                      <small className="text-red">
                        {errors.selected_roles}
                      </small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* C_Role */}
                  <div className="col-12 col-md-6 mb-3 ">
                    <label className="form-label" htmlFor="selected_roles">
                      Select Custom Role
                    </label>
                    <Select
                      components={animatedComponents}
                      className="basic-multi-select"
                      classNamePrefix="select Custom Role"
                      placeholder="Select Custom Role"
                      id="selected_roles"
                      isMulti
                      Group
                      value={outlet_data.selected_custom_roles}
                      options={custom_role_options}
                    />
                    {errors.selected_custom_roles && (
                      <small className="text-red">
                        {errors.selected_custom_roles}
                      </small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div className="w-100 mb-5 mt-3">
              {userOpen ? (
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm btn-radius px-4"
                    onClick={() => {
                      setUserOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="text-end">
                  <button
                    type="button"
                    className="btn bg-prime text-white btn-sm btn-radius px-4"
                    onClick={() => {
                      create_outlet();
                    }}
                  >
                    Create
                  </button>
                </div>
              )}
            </div>
          </div>
          {/* <LocationPicker
        open={mapModal.is_open}
        from={mapModal.from}
        close={(data:any) => {
          console.log("Location picker on close :", data);
          SetMapModal({
            is_open: false,
            data: {},
            from: "",
          });
          if (data.action == "save") {
            SetLocationPickerData(data.value);
            SetShortAddressValue(data.value.short_address_value);
            if (data.from == "add_address") {
              SetAddAddress((prevalue: any) => {
                prevalue.latitude = data.value.lat;
                prevalue.longitude = data.value.lang;
                prevalue.map_address = data.value.full_address_value;
                prevalue.short_map_address = data.value.short_address_value;
                return { ...prevalue };
              });
              validate_add_address();
            }
          }
        }}
      /> */}

          <LocationPicker
            open={map_modal.is_open}
            from={map_modal.from}
            lat={map_modal.latitude}
            long={map_modal.longitude}
            address={map_modal.address}
            picked_lat_long={{
              lat: map_modal.latitude,
              long: map_modal.longitude,
            }}
            picked_address_value={map_modal.address}
            // latitude={mapModal.latitude}
            // longitude={mapModal.longitude}
            close={(data: any) => {
              console.log("Location picker on close :", data);
              SetMapModal((prevalue) => {
                prevalue.is_open = false;
                return { ...prevalue };
              });

              // latitude: "",
              // longitude: "",

              // if (data.action == "save") {
              //   SetLocationPickerData(data.value);
              //   SetShortAddressValue(data.value.short_address_value);
              //   if (data.from == "add_address") {
              //     SetAddAddress((prevalue: any) => {
              //       prevalue.latitude = data.value.lat;
              //       prevalue.longitude = data.value.lang;
              //       prevalue.map_address = data.value.full_address_value;
              //       prevalue.short_map_address = data.value.short_address_value;
              //       return { ...prevalue };
              //     });
              //     validate_add_address();
              //   }
              // }

              // SetOutletData((oldValues) => ({
              //   ...oldValues,
              //   map_address: data.value.full_address_value,

              // }));

              // SetMapModal((prevValue: any) => {
              //   prevValue.is_open = false;
              //   return { ...prevValue };

              // });
              if (data.action == "save") {
                SetOutletData((oldValues) => ({
                  ...oldValues,
                  map_address: data.value.full_address_value,
                }));
                // get_outlets();
                SetMapModal((prevValue: any) => {
                  // prevValue.latitude = data.latitude;
                  // prevValue.longitude = data.longitude;
                  prevValue.latitude = data.value.lat;
                  prevValue.longitude = data.value.lang;
                  prevValue.address = data.value.full_address_value;
                  return { ...prevValue };
                });
              }
            }}
          />

          {/* <ModalLocation
            open={mapModal.is_open}
            latitude={mapModal.latitude}
            longitude={mapModal.longitude}
            close={(data: any) => {
              console.log("Franchise modl on close :", data);

              SetOutletData((oldValues) => ({
                ...oldValues,
                map_address: data.address,

              }));

              SetMapModal((prevValue: any) => {
                prevValue.is_open = false;
                return { ...prevValue };

              });
              if (data.action == "save") {
                // get_outlets();
                SetMapModal((prevValue: any) => {

                  prevValue.latitude = data.latitude;
                  prevValue.longitude = data.longitude;
                  return { ...prevValue };


                });
              }
            }}
          /> */}
          {/* 
          <ModalLocationDriver
            open={mapdriverModal.is_open}
            // id={deleteModal.id}
            close={(data: any) => {

              // console.log("Franchise modl on close :", data);
              SetMapDriverModal((prevValue: any) => {
                prevValue.is_open = false;
                return { ...prevValue };
              });
              if (data.action == "save") {
                // get_outlets();
              }
            }}
          /> */}

          {modal_image.is_open ? (
            <ModalAddGallery
              open={modal_image.is_open}
              type={modal_image.type}
              data={modal_image.data}
              selected={modal_image.selected_images}
              from={"create_outlet"}
              close={(data: any) => {
                console.log("ModalAddGallery on close :", data);
                SetModalImage((prevValue: any) => {
                  prevValue.is_open = false;
                  return { ...prevValue };
                });

                if (data.action == "save") {
                  SetOutletData((prevValue: any) => {
                    prevValue.selected_image = data.value;
                    return { ...prevValue };
                  });
                }
              }}
            />
          ) : null}

          {modal_banner.is_open ? (
            <ModalAddGallery
              open={modal_banner.is_open}
              type={modal_banner.type}
              data={modal_banner.data}
              selected={modal_banner.selected_banner}
              from={"create_banner"}
              close={(data: any) => {
                console.log("ModalAddGallery on close :", data);
                SetModalBanner((prevValue: any) => {
                  prevValue.is_open = false;
                  return { ...prevValue };
                });

                if (data.action == "save") {
                  SetOutletData((prevValue: any) => {
                    prevValue.selected_banner = data.value;
                    return { ...prevValue };
                  });
                }
              }}
            />
          ) : null}
        </form>
      </div>
    </div>
  );
}
