import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
// import ProductPricingComponent from "../../../components/ProductPricingComponent/ProductPricingComponent";
import ProductGroupPricingComponent from "../../../components/ProductGroupPricingComponent/ProductGroupPricingComponent";
import ProductGroupPricingComponent1 from "../../../components/ProductGroupPricingComponent1/ProductGroupPricingComponent1";

export default function ProductGroupPricing(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [id_value, SerIdValue] = useState(useParams());

  useEffect(() => {
    console.log("ProductGroupPricing On mount :", props);
    console.log("ProductGroupPricing context :", context);
    console.log("ProductGroupPricing OutletContext :", OutletContext);
  }, []);

  return (
    <div>
        <ProductGroupPricingComponent1 title={"Products Group Pricing"} value={id_value} is_menu_open={OutletContext} />
        {/* <ProductGroupPricingComponent title={"Products Group Pricing123"} value={id_value} is_menu_open={OutletContext} /> */}
    </div>
  );
}
