import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// import bootstrap from "bootstrap/dist/js/bootstrap";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap.min.js";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import { omit } from "lodash";
import { useWizard, Wizard } from "react-use-wizard";
const animatedComponents = makeAnimated();

const franchise_initial_value = {
  id: "",
  name: "",
  address: "",
  mobile: "",
  whatsapp: "",
  email: "",
  website: "",
};

let myModal: any = {};

export default function ModalEditFranchise(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [franchise_data, SetFranchiseData] = useState(franchise_initial_value);
  const [errors, setErrors]: any = useState({});

  useEffect(() => {
    console.log("ModalEditFranchise On mount :", props);
    console.log("Mobile", props.data.mobile);
    let myModal1: any = document.getElementById("EditFranchiseModal");
    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      console.log("event :", event);
      SetFranchiseData(franchise_initial_value);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    console.log("mobile", props.data.mobile);

    if (props.open) {
      console.log("ModalEditFranchise On mount :", props);

      myModal = new bootstrap.Modal(
        document.getElementById("EditFranchiseModal"),
        {}
      );
      let set_value = {
        id: props.data.id,
        name: props.data.name,
        address: props.data.address,
        mobile: props.data.mobile,
        whatsapp: props.data.whatsapp,
        email: props.data.email,
        website: props.data.website,
      };
      SetFranchiseData(set_value);
      myModal.show();
    }
  }, [props.open]);

  { console.log(myModal) }

  function franchise_value_on_change(name: any, value: any) {
    validate(name, value);
    SetFranchiseData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  async function edit_franchise() {
    console.log("edit_franchise data :", franchise_data);
    let post_value = {
      name: franchise_data.name,
      address: franchise_data.address,
      mobile: franchise_data.mobile,
      whatsapp: franchise_data.whatsapp,
      email: franchise_data.email,
      website: franchise_data.website,
    };
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        franchise: franchise_data.id,
      },
      post: post_value,
    };
    console.log("pass_data :", pass_data);

    let data_res = await api("/brand/edit_franchise", pass_data);
    console.log("edit_franchise response :", data_res.response);
    console.log("edit_outlet response :", data_res.status_code);
    if (data_res.status_code == 200) {
      console.log("/franchise/edit_service status_code 200");
      let item = close_data;
      item.action = "edit";
      item.value = data_res.response.franchise[0];
      item.index = props.index;
      SetCloseData(item);
      myModal.hide();
    }
    // if (data_res.status_code == 201) {
    //   let item = close_data;
    //   item.action = "save";
    //   let franchise_value = {
    //     id: data_res.response.id,
    //     name: franchise_data.name,
    //     address: franchise_data.address,
    //     mobile: franchise_data.mobile,
    //   };
    //   item.value = franchise_value;
    //   console.log("Close data : ", item);
    //   SetCloseData(item);
    //   SetFranchiseData(franchise_initial_value);
    //   myModal.hide();
    // }
  }

  function validate(name: any, value: any) {
    //A function to validate each input values
    console.log("name :", name);
    console.log("value  :", value);
    switch (name) {
      case "name":
        if (value.length <= 2) {
          // we will set the error state

          setErrors({
            ...errors,
            name: "Franchise name atleast have 3 letters",
          });
        } else {
          let newObj = omit(errors, "name");
          setErrors(newObj);
        }
        break;

      case "address":
        if (value.length <= 0) {
          setErrors({
            ...errors,
            address: "Enter valid address",
          });
        } else {
          let newObj = omit(errors, "address");
          setErrors(newObj);
        }
        break;

      case "mobile":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            mobile: "Enter valid mobile number",
          });
        } else {
          let newObj = omit(errors, "mobile");
          setErrors(newObj);
        }
        break;

      case "whatsapp":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            whatsapp: "Enter valid whatsapp number",
          });
        } else {
          let newObj = omit(errors, "whatsapp");
          setErrors(newObj);
        }
        break;

      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "Enter email not vaild",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      case "website":

        if (!new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/).test(value)) {
          setErrors({
            ...errors,
            website: "Enter website not vaild",
          });
        } else {
          let newObj = omit(errors, "website");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  return (
    <div
      className="modal fade"
      id="EditFranchiseModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-fullscreen-sm-down">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Edit Franchise
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>

          <div className="modal-body">
            <div className="row">

              {/* <div className="col-12 col-md-6 mt-3">
                <label className="form-label">Franchise Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={franchise_data.name}
                  onChange={(e) => {
                    franchise_value_on_change("name", e.target.value);
                  }}
                />
                {errors.name && (
                  <small className="text-red">
                    {errors.name}
                  </small>
                )}
              </div> */}

              <div className="col-12 mt-2">
                <label className="form-label text-dark fw-bold">Address</label>
                <textarea
                  rows={8}
                  className="form-control"
                  value={franchise_data.address}
                  onChange={(e) => {
                    franchise_value_on_change("address", e.target.value);
                  }}
                ></textarea>
                {errors.address && (
                  <small className="text-red">
                    {errors.address}
                  </small>
                )}
              </div>

              <div className="col-12 col-md-6 mt-3">
                <label className="form-label text-dark fw-bold">Mobile Number</label>
                <input
                  type="text"
                  className="form-control"
                  value={franchise_data.mobile}
                onChange={(e) => {
                  if (e.target.value.length == 11) return false;
                  let val = e.target.value.slice(0, 10);
                  franchise_value_on_change("mobile", val);
                }}
                />
                {errors.mobile && (
                  <small className="text-red">
                    {errors.mobile}
                  </small>
                )}
              </div>

              <div className="col-12 col-md-6 mt-3">
                <label className="form-label text-dark fw-bold">Whatsapp Number</label>
                <input
                  type="text"
                  className="form-control"
                  value={franchise_data.whatsapp}
                  onChange={(e) => {
                    if (e.target.value.length == 11) return false;
                    let val = e.target.value.slice(0, 10);
                    franchise_value_on_change("whatsapp", val);
                  }}
                />
                {errors.whatsapp && (
                  <small className="text-red">
                    {errors.whatsapp}
                  </small>
                )}
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="form-label text-dark fw-bold">Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={franchise_data.email}
                  onChange={(e) => {
                    franchise_value_on_change("email", e.target.value);
                  }}
                />
                {errors.email && (
                  <small className="text-red">
                    {errors.email}
                  </small>
                )}
              </div>
              <div className="col-12 col-md-6 mt-3">
                <label className="form-label text-dark fw-bold">Website</label>
                <input
                  type="text"
                  className="form-control"
                  value={franchise_data.website}
                  onChange={(e) => {
                    franchise_value_on_change("website", e.target.value);
                  }}
                />
                {errors.website && (
                  <small className="text-red">
                    {errors.website}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <button
                type="button"
                className="btn bg-prime text-white btn-radius fw-bold"
                onClick={() => {
                  edit_franchise();
                }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
