import React, { useState, useEffect, useContext, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import DataContext from "../../utils/Context/DataContext";
import SwipeableDrawer from "../Drawer/SwipeableDrawer";
import { AppHelper } from "../../utils/Helper";
import {
  MaterialIcons,
  FontAwesome5,
  AntDesign,
  Feather,
  Ionicons,
  Entypo,
  Fontisto,
  FontAwesome,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImageContainer from "../ImageContainer/ImageContainer";
// import { CupertinoPane } from "cupertino-pane";
import { AppConfig } from "../../config";

export default function Footer(props: any) {
  const context: any = useContext(DataContext);
  const [id_value, SerIdValue] = useState(useParams());
  const swipeableDrawer: any = {
    settings: { freeMode: true },
    events: {},
    open: false,
    clickBottomOpen: false,
  };
  const [active, SetActive] = useState(false);
  const [props1BtnData, Set1BtnPropsData]: any = useState({
    ...swipeableDrawer,
    customData: {
      subMenu: null,
      subMenuTitle: "",
      style: {
        zIndex: 2,
      },
    },
    settings: {
      breaks: {
        top: { enabled: true, height: window.screen.height },
        middle: { enabled: true, height: 290 },
        // bottom: { enabled: false },
      },
      backdrop: true,
      backdropOpacity: 0,
      bottomClose: true,
    },
  });

  let section_data = [
    {
      id: 68,
      name: "Food",
      slug: "food",
      sector: "2",
      sector_name: "Resturant",
      url: "3fd44bb8c8c4ec8666c83e92f6e90b6f.png",
      order_type: "Vendors",
    },
    {
      id: 71,
      name: "Pickup Drop",
      slug: "pickupdrop",
      sector: "4",
      sector_name: "Pickup Drop",
      url: "ae17567a390713152eb322fc4f15c8d9.png",
      order_type: "Products",
    },
    {
      id: 69,
      name: "Groceries",
      slug: "groceries",
      sector: "1",
      sector_name: "Groceries",
      url: "2bd8ff574a746aa2195f1e5aa884be15.png",
      order_type: "Vendors",
    },
    {
      id: 70,
      name: "Nearby Stores",
      slug: "nearby-stores",
      sector: "3",
      sector_name: "Ecommerce",
      url: "ea060b2a62dd087a79cbbadc09c142e1.png",
      order_type: "Vendors",
    },
    {
      id: 67,
      name: "Market",
      slug: "market",
      sector: "3",
      sector_name: "Ecommerce",
      url: "ab72cd83c426dfb078fa9aa21eff38f0.png",
      order_type: "Products",
    },
  ];

  useEffect(() => {
    console.log("Footer On mount :", props);
    SetActive(props.is_menu_open);
    console.log("Footer context :", context);
  }, [props]);

  useEffect(() => {
    if (props1BtnData.open) {
      context.SetAppData((prevValue: any) => {
        prevValue.scroll_locked++;
        return { ...prevValue };
      });
    } else {
      context.SetAppData((prevValue: any) => {
        if (prevValue.scroll_locked > 0) {
          prevValue.scroll_locked--;
        }
        return { ...prevValue };
      });
    }
  }, [props1BtnData.open]);

  function close1IconDrawer() {
    Set1BtnPropsData({
      ...props1BtnData,
      open: false,
      customData: {
        subMenu: null,
        subMenuTitle: "",
      },
    });
  }

  return (
    <div>
      <div className="navfooter">
        {props1BtnData?.customData?.subMenu != null && (
          <div
            className="cursor bg-fff footer-back d-flex align-center"
            onClick={() => {
              Set1BtnPropsData((prevalue: any) => {
                prevalue.customData.subMenu = null;
                prevalue.customData.subMenuTitle = "";
                return { ...prevalue };
              });
            }}
          >
            <Entypo name="chevron-left" size={20} color="#343a40" />{" "}
            <p className="text-black fs-7">
              {props1BtnData.customData.subMenuTitle}
            </p>
          </div>
        )}
        <div className="bottom-tabs">
          <div className="row">
            <div className="col-2">
              {props.from == "brand" ? (
                <Link
                  className="px-2 w-100 link-loader footertab-btn"
                  to={"/brand/" + id_value.brand_id + "/dashboard"}
                >
                  {/* <FontAwesome name="user-o" size={20} color="#333" /> */}
                  <img
                    src={require("../../assets/svg/home1.svg")}
                    className="w-20px"
                  />
                </Link>
              ) : props.from == "franchise" ? (
                <Link
                  className="px-2 w-100 link-loader footertab-btn"
                  to={
                    "/franchise/" +
                    id_value.brand_id +
                    "/" +
                    id_value.franchise_id +
                    "/dashboard"
                  }
                >
                  {/* <FontAwesome name="user-o" size={20} color="#333" /> */}
                  <img
                    src={require("../../assets/svg/home1.svg")}
                    className="w-20px"
                  />
                </Link>
              ) : props.from == "outlet" ? (
                <Link
                  className="px-2 w-100 link-loader footertab-btn"
                  to={
                    "/outlet/" +
                    id_value.brand_id +
                    "/" +
                    id_value.outlet_id +
                    "/dashboard"
                  }
                >
                  {/* <FontAwesome name="user-o" size={20} color="#333" /> */}
                  <img
                    src={require("../../assets/svg/home1.svg")}
                    className="w-20px"
                  />
                </Link>
              ) : (
                <Link to="/">
                  {/* <AntDesign name="home" size={20} color="#333" /> */}
                  <img
                    src={require("../../assets/svg/home1.svg")}
                    className="w-20px"
                  />
                </Link>
              )}
            </div>
            {/* <div className="col-2">
         
                <Link
                  className="px-2 w-100 link-loader footertab-btn"
                  to={"/orders/home"}
                >
                 
                  <img
                    src={require("../../assets/svg/home1.svg")}
                    className="w-20px"
                  />
                </Link>
             
            </div> */}
            <div className="col-2">
              {props.from == "brand" ? (
                <Link
                  className="px-2 w-100 link-loader footertab-btn"
                  to={"/brand/" + id_value.brand_id + "/manage_channels"}
                >
                  {/* <FontAwesome name="user-o" size={20} color="#333" /> */}
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "26bacf32b58eced45e8d4312c0f88f1c.svg"
                    }
                    className="w-22px"
                  />
                </Link>
              ) : props.from == "outlet" ? (
                <Link
                  className="px-2 w-100 link-loader footertab-btn"
                  to={
                    "/outlet/" +
                    id_value.brand_id +
                    "/" +
                    id_value.outlet_id +
                    "/orders/active"
                  }
                >
                  {/* <FontAwesome name="user-o" size={20} color="#333" /> */}
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "41917b65caf9a4489acfcc6e0b35ffd7.svg"
                    }
                    className="w-22px"
                  />
                </Link>
              ) : props.from == "main" ? (
                // <Link className="px-2 w-100 link-loader footertab-btn" to={"/"}>
                //   {/* <Ionicons name="settings-outline" size={20} color="#333" /> */}
                //   <img
                //     src={
                //       AppConfig.CDN_Media_url +
                //       "5adfbd3943b5ab2bab799db7d411968b.svg"
                //     }
                //     className="w-22px"
                //   />
                // </Link>
                <Link
                className="px-2 w-100 link-loader footertab-btn"
                to={"/orders"}
              >
               
               <img
                    src={
                      AppConfig.CDN_Media_url +
                      "41917b65caf9a4489acfcc6e0b35ffd7.svg"
                    }
                    className="w-22px"
                  />
              </Link>
              ) : (
                <Link
                  className="px-2 w-100 link-loader footertab-btn"
                  to={"/brand/" + id_value.brand_id + "/account"}
                >
                  {/* <Ionicons name="settings-outline" size={20} color="#333" /> */}
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "5adfbd3943b5ab2bab799db7d411968b.svg"
                    }
                    className="w-22px"
                  />
                </Link>
        
              )}
            </div>
            {/* <div className="col-2">
              <a
                className="px-2 w-100 link-loader footertab-btn"
                href="https://1superapp.com/dashboard"
              >
                <img
                  src="https://1superapp.com/assets/images/1superapp-logo-plain.png"
                  width="25"
                />
              </a>
            </div> */}
            {/* 1Super icon */}
            <div
              className="col-2 cursor"
              onClick={() => {
                // SetBottomSheetData((prevalue: any) => {
                //   // prevalue.open = true;
                //   if (prevalue.overflowHeight > 0) {
                //     prevalue.overflowHeight = 0;
                //   } else {
                //     prevalue.overflowHeight = 210;
                //   }
                //   return { ...prevalue };
                // });
                props.menu_click(false);
                // SetBottomSheetData((prevalue: any) => {
                //   if (prevalue.open) {
                //     window.location.href =
                //       "https://1superapp.com/dashboard";
                //   } else {
                //     // prevalue.open = true;
                //     if (prevalue.overflowHeight > 0) {
                //       prevalue.overflowHeight = 0;
                //     } else {
                //       prevalue.overflowHeight = 210;
                //     }
                //     prevalue.open = true;
                //     props.on_bottom_sheet_change(true);
                //     prevalue.customData = {
                //       subMenu: null,
                //       subMenuTitle: "",
                //       style: {
                //         zIndex: 1032,
                //       },
                //     };
                //   }
                //   // setHidden(bottom_sheet_data.open);
                //   return { ...prevalue };
                // });

                if (props1BtnData.open) {
                  window.location.href = "https://1superapp.com/dashboard";
                } else {
                  console.log("props1BtnData", props1BtnData);

                  Set1BtnPropsData((prevalue: any) => {
                    prevalue.open = true;
                    prevalue.settings.breaks.top.height = window.innerHeight;
                    prevalue.customData.subMenu = null;
                    prevalue.customData.subMenuTitle = "";
                    return { ...prevalue };
                  });
                  // props.on_bottom_sheet_change(true);
                }

                // drawer.present({
                //   animated: true,
                // transition: {
                //   duration: 600,
                //   from: {
                //     opacity: 0.7,
                //     // transform: `translateY(280px) perspective(250px) rotateX(65deg) scale(0.3)`,
                //   },
                //   to: {
                //     opacity: 1,
                //   },
                // },
                // });

                // SetBottomSheetIsLoading(true);
                // const timer = setTimeout(() => {
                //   SetBottomSheetIsLoading(false);
                // }, 800);
                return () => clearTimeout(timer);
                // SetPropsData({
                //   ...propsData,
                //   open: true,
                //   customData: {
                //     subMenu: null,
                //     subMenuTitle: "",
                //     style: {
                //       zIndex: 1032,
                //     },
                //   },
                // });
              }}
            >
              {/* <a href="https://1superapp.com/dashboard"> */}
              {/* {bottom_sheet_data.overflowHeight > 0 ? (
                        // overflowHeight = 0
                        <Ionicons
                          className="logo"
                          name="ios-close"
                          size={32}
                          color="black"
                        />
                      ) : ( */}
              {/* // overflowHeight = 210 */}
              {/* <img
                      // src={require("../../assets/img/logo.png")}
                      src={
                        "https://cdn3.1superapp.com/superapp/assets/images/1superapp-logo-plain.png"
                      }
                      style={{ width: 25, height: 25, margin: 5 }}
                      className="logo"
                    /> */}
              {/* <div className="logo-div"> */}
              <ImageContainer
                src={AppHelper.imageSrc(
                  "",
                  "https://cdn3.1superapp.com/superapp/assets/images/1superapp-logo-plain.png",
                  {
                    format: "webp",
                    width: 320,
                  }
                )}
                thumb={AppHelper.imageSrc(
                  "",
                  "https://cdn3.1superapp.com/superapp/assets/images/1superapp-logo-plain.png",
                  {
                    format: "webp",
                    width: 320,
                    quality: 1,
                  }
                )}
                class_name="logo"
                onIsVisible={() => {
                  console.log("ImageContainer On Is visible");
                }}
              />
              {/* </div> */}

              {/* )} */}

              {/* </a> */}
            </div>
            {/* notification */}
            <div className="col-2">
              {props.from == "outlet" ? (
                <Link
                  className="px-2 w-100 link-loader footertab-btn"
                  to={
                    "/outlet/" +
                    id_value.brand_id +
                    "/" +
                    id_value.outlet_id +
                    "/manage_products"
                  }
                >
                  {/* <FontAwesome name="user-o" size={20} color="#333" /> */}
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "d1c760c96a6fa5eed05147200f1284ba.svg"
                    }
                    className="w-22px"
                  />
                </Link>
              ) : (
                <a
                  className="px-2 w-100 link-loader footertab-btn"
                  // href="https://1studio.1superapp.com/notification/view"
                >
                  {/* <Ionicons name="notifications-outline" size={20} color="#333" /> */}
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "d6fd8f144d63f55965ce68becb528d22.svg"
                    }
                    className="w-20px"
                  />
                </a>
              )}
            </div>
            {/* menu */}
            <div className="col-2">
              <div
                // className={
                //   active == true
                //     ? "active-tab sidebar-toggler navbar-toggler p-2 cursor"
                //     : "sidebar-toggler navbar-toggler p-2 cursor"
                // }
                className="sidebar-toggler navbar-toggler p-2 cursor"
                onClick={() => {
                  // console.log("hai");
                  if (props.from != "main") {
                    // props.menu_click(true);
                    // if (props.menu_click(true)) {
                    //   SetActive(true);
                    // } else {
                    //   SetActive(false);
                    // }
                    if (props.is_menu_open) {
                      props.menu_click(false);
                      SetActive(false);
                    } else {
                      props.menu_click(true);
                      SetActive(true);
                    }
                  }
                }}
              >
                {/* <Ionicons name="md-menu-sharp" size={20} color="#333" /> */}
                {active ? (
                  // <img
                  //   src={AppConfig.CDN_Media_url+"0e6e6fe2082e4992fa7b31185cb5f0f4.svg"}
                  //   className="w-22px"
                  // />
                  <Ionicons name="ios-close" size={20} color="#212529" />
                ) : (
                  <img
                    src={require("../../assets/svg/menu2.svg")}
                    className="w-20px"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="swipeable-drawer-container-footer">
        <SwipeableDrawer
          refreshToken={AppHelper.randomStr(10)}
          propsData={props1BtnData}
          parentClass={".swipeable-drawer-container-footer"}
          drawerEvent={(data: any) => {
            if (data == "closed") {
              close1IconDrawer();
            }
          }}
        >
          <div className="swipeable-drawer-content btn-1drawer-content rounded-corner pb-3 px-1">
            <div className="bar btn-1drawer-bar"></div>
            <div>
              {/* Pickup Drop / Blood /  Earn / Business Suite*/}
              {props1BtnData?.customData?.subMenu == null && (
                <div className="dashboard-app-btns superapp_menu_content_home">
                  
                  {section_data?.map(
                    (sections: any, s_index: any) => (
                      <Fragment key={s_index}>
                        {(sections.sector == 1 ||
                          sections.sector == 2 ||
                          (sections.sector == 3 &&
                            sections.order_type == "Vendors") ||
                          sections.sector == 4) && (
                          <div  className="dashboard-app-btn-col">
                            {/* <Link
                              to={"https://shop.1superapp.com/" + sections.slug}
                              className="dashboard-app-btn"
                              key={s_index}
                              onClick={() => {
                                // if (
                                //   sections.sector == 1 ||
                                //   sections.sector == 2 ||
                                //   (sections.sector == 3 &&
                                //     sections.order_type == "Vendors") ||
                                //   sections.sector == 4
                                // ) {
                                //   SaveContext(sections);
                                // }
                                close1IconDrawer();
                              }}
                            > */}
                            <a
                              className="dashboard-app-btn"
                              href={"https://shop.1superapp.com/" + sections.slug}
                            >
                              {sections.url != null ? (
                                <ImageContainer
                                  src={AppHelper.imageSrc(
                                    "https://cdn2.1superapp.com/images/",
                                    sections.url,
                                    { format: "webp", width: 100 }
                                  )}
                                  parentClass=""
                                  thumb={AppHelper.imageSrc(
                                    context.app_data.image_base_url,
                                    sections.url,
                                    { format: "webp", width: 100, quality: 10 }
                                  )}
                                  class_name="img-fluid lazy-hw-40 pt-2"
                                  onIsVisible={() => {
                                    console.log("ImageContainer On Is visible");
                                  }}
                                  thumb_class_name="thumb-img-fluid lazy-hw-40 pt-2"
                                />
                              ) : (
                                <img
                                  src={
                                    AppConfig.CDN_Image_url +
                                    "87283f26129d8fb1d64900caf09f2be9.png"
                                  }
                                  className="img-fluid lazy-hw-40 pt-2"
                                />
                              )}
                              <div className="mt-2 text-dark dashboard-app-btn-title">
                                {sections.name}
                              </div>
                            </a>
                            {/* </Link> */}
                          </div>
                        )}
                      </Fragment>
                    )
                  )}

                  {/* Order Food */}
                  {/* <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://shop.1superapp.com/app/login?r=https://shop.1superapp.com/food"
                    >
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/soup.svg"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/soup.svg"
                        )}
                        class_name="img-fluid lazy-hw-40 pt-2"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-2"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Food
                      </div>
                      <span className="beta-badge">Beta</span>
                    </a>
                  </div> */}

                  {/* Pickup Drop */}
                  {/* <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://shop.1superapp.com/app/login?r=https://shop.1superapp.com/pickupdrop"
                    >
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/delivery-pickupdrop.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/delivery-pickupdrop.png"
                        )}
                        class_name="img-fluid lazy-hw-40 pt-2"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-2"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Pickup Drop
                      </div>
                      <span className="beta-badge">Beta</span>
                    </a>
                  </div> */}

                  {/* Blood */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://1blood.1superapp.com/login"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-1blood.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-1blood.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-1blood.png"
                        )}
                        class_name="img-fluid lazy-hw-40"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Blood
                      </div>
                    </a>
                  </div>

                  {/* Events */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://events.1superapp.com"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/dance.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dance.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dance.png"
                        )}
                        class_name="img-fluid lazy-hw-40"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Events
                      </div>
                    </a>
                  </div>

                  {/* Earn */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      onClick={() => {
                        Set1BtnPropsData((prevalue: any) => {
                          prevalue.customData.subMenu = "earn";
                          prevalue.customData.subMenuTitle = "Earn";
                          return { ...prevalue };
                        });
                      }}
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40 pt-1"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/money-bill-wave-green.svg"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/money-bill-wave-green.svg"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/money-bill-wave-green.svg"
                        )}
                        class_name="img-fluid lazy-hw-40 pt-1"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-1"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Earn
                      </div>
                    </a>
                  </div>

                  {/* Business Suite */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn superapp_menu_business"
                      onClick={() => {
                        Set1BtnPropsData((prevalue: any) => {
                          prevalue.customData.subMenu = "business_suite";
                          prevalue.customData.subMenuTitle = "Business Suite";
                          return { ...prevalue };
                        });
                      }}
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40 pt-2"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/briefcase.svg"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/briefcase.svg"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/briefcase.svg"
                        )}
                        class_name="img-fluid lazy-hw-40 pt-2"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-2"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Business Suite
                      </div>
                    </a>
                  </div>

                  {/* My orders */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://shop.1superapp.com/order"
                    >
                      {/* <img
                          src="https://cdn3.1superapp.com/superapp/assets/images/shopping-bag.png"
                          // src={require("../../assets/img/shopping-bag.png")}
                          className="img-fluid lazy-hw-40 pt-1"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/shopping-bag.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/shopping-bag.png"
                        )}
                        class_name="img-fluid lazy-hw-40 pt-1"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-1"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        My Orders
                      </div>
                    </a>
                  </div>

                  {/* cart */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://shop.1superapp.com/cart?v=all"
                    >
                      {/* <img
                          src="https://cdn3.1superapp.com/superapp/assets/images/cart-shopping-fast.svg"
                          // src={require("../../assets/img/cart-shopping-fast.png")}

                          className="img-fluid lazy-hw-40 pt-1"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/cart-shopping-fast.svg"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/cart-shopping-fast.svg"
                        )}
                        class_name="img-fluid lazy-hw-40 pt-1"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-1"
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Cart
                      </div>
                    </a>
                  </div>
                </div>
              )}

              {/* Delivery Partner / Service Providers */}
              {props1BtnData?.customData?.subMenu == "earn" && (
                <div className="dashboard-app-btns superapp_menu_content_earn">
                  {/* Delivery Partner */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://deliverypartner.1superapp.com/app/login"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40 pt-1"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/delivery-man.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/delivery-man.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/delivery-man.png"
                        )}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-1"
                        class_name="img-fluid lazy-hw-40 pt-1"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Delivery Partner
                      </div>
                      <span className="beta-badge">Beta</span>
                    </a>
                  </div>

                  {/* Service Providers */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn link-loader"
                      href="https://serviceprovider.1superapp.com"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-sp.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-sp.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-sp.png"
                        )}
                        thumb_class_name="thumb-img-fluid lazy-hw-40"
                        class_name="img-fluid lazy-hw-40"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Service Providers
                      </div>
                    </a>
                  </div>
                </div>
              )}

              {/* Seller /Studio / Post Production */}
              {props1BtnData?.customData?.subMenu == "business_suite" && (
                <div className="dashboard-app-btns superapp_menu_content_business">
                  {/* Seller */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn"
                      href="https://sellers.1superapp.com/app/login"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40 pt-1"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/seller.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/seller.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/seller.png"
                        )}
                        thumb_class_name="thumb-img-fluid lazy-hw-40 pt-1"
                        class_name="img-fluid lazy-hw-40 pt-1"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                      />
                      <div className="mt-2 text-dark dashboard-app-btn-title">
                        Sellers
                      </div>
                      <span className="beta-badge">Beta</span>
                    </a>
                  </div>

                  {/* Studio */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn link-loader"
                      href="https://1studio.1superapp.com/dashboard"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-studio.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-studio.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-studio.png"
                        )}
                        thumb_class_name="thumb-img-fluid lazy-hw-40"
                        class_name="img-fluid lazy-hw-40"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                      />
                      <div className="mt-2 dashboard-app-btn-title">Studio</div>
                    </a>
                  </div>

                  {/* Post Production */}
                  <div className="dashboard-app-btn-col">
                    <a
                      className="dashboard-app-btn link-loader"
                      href="https://postproduction.1superapp.com"
                    >
                      {/* <img
                          className="img-fluid lazy-hw-40"
                          width=""
                          src="https://cdn3.1superapp.com/superapp/assets/images/dashboard-postproduction.png"
                        /> */}
                      <ImageContainer
                        src={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-postproduction.png"
                        )}
                        thumb={AppHelper.imageSrc(
                          "",
                          "https://cdn3.1superapp.com/superapp/assets/images/dashboard-postproduction.png"
                        )}
                        thumb_class_name="thumb-img-fluid lazy-hw-40"
                        class_name="img-fluid lazy-hw-40"
                        onIsVisible={() => {
                          console.log("ImageContainer On Is visible");
                        }}
                      />
                      <div className="mt-2 dashboard-app-btn-title">
                        Post Production
                      </div>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    </div>
  );
}
