import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";

export default function OutletSetting(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext()
  const [width, SetWidth] = useState(innerWidth);

  useEffect(() => {
    console.log("OutletSetting On mount :", props);
    console.log("OutletSetting context :", context);
  }, []);

  return (
    <div>
      <div className={OutletContext ? "head-bar  sticky-sm" : "head-bar sticky-sm"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="d-flex align-items-center">
          <h6 className="">Settings</h6>
          <div className="ms-auto">
            <button className="btn btn-custom-outline px-3 btn-sm">Apply</button>
          </div>
        </div>
      </div>
      <div className="mt-3 px-md-3 px-sm-0"

        style={width < 786 ? (
          OutletContext == false ? { width: width } : {}
        ) : (
          OutletContext == false ? { width: width, paddingTop: "3rem" } : {}
        )} >
        <div className="bg-fff p-3">
          <h6 className="mb-3 fs-6">Delivery Type</h6>
          <div className="row align-items-center px-3 pt-2">

            <div className="form-check col-md-4 mb-2 cursor">
              <input className="form-check-input" type="checkbox" value="" id="deliverType1" />
              <label className="form-check-label" htmlFor="deliverType1">
                In Shop
              </label>
            </div>
            <div className="form-check col-md-4 mb-2 cursor">
              <input className="form-check-input" type="checkbox" value="" id="deliverType2" />
              <label className="form-check-label " htmlFor="deliverType2">
                Take Away
              </label>
            </div>
            <div className="form-check col-md-4 mb-2 cursor">
              <input className="form-check-input" type="checkbox" value="" id="deliverType3" />
              <label className="form-check-label" htmlFor="deliverType3">
                Door Delivery
              </label>
            </div>
          </div>

          <div className="mt-2 pt-3 ">
            <h6 className="">Inshop Order Processing Type</h6>

            <div className="row pt-3 ">
              <div className="col-md-6 mb-3">
                <div className="card border radius p-3 pb-1">
                  <p className="text-dark mb-3">Placing Order</p>
                  <div className="form-check mt-1">
                    <input className="form-check-input" type="checkbox" value="" id="placeOrder" />
                    <label className="form-check-label" htmlFor="placeOrder">
                      Item topup in an order
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="card border radius p-3 pb-1">
                  <p className="text-dark mb-3">Accepting Order</p>
                  <div className="row px-2">
                    <div className="form-check col-6">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="accept1" />
                      <label className="form-check-label" htmlFor="accept1">
                        Itemwise
                      </label>
                    </div>
                    <div className="form-check col-6">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="accept2" />
                      <label className="form-check-label" htmlFor="accept2">
                        Orderwise
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <div className="card border radius p-3 pb-1">
                  <p className="text-dark mb-3">Assigning Order</p>
                  <div className="row px-2">
                    <div className="form-check col-6">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="assign1" />
                      <label className="form-check-label" htmlFor="assign1">
                        Itemwise
                      </label>
                    </div>
                    <div className="form-check col-6">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="assign2" />
                      <label className="form-check-label" htmlFor="assign2">
                        Orderwise
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <div className="card border radius p-3 pb-1">
                  <p className="text-dark mb-3">Preparing Order</p>
                  <div className="row px-2">
                    <div className="form-check col-6">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="prepare1" />
                      <label className="form-check-label" htmlFor="prepare1">
                        Itemwise
                      </label>
                    </div>
                    <div className="form-check col-6">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="prepare2" />
                      <label className="form-check-label" htmlFor="prepare2">
                        Orderwise
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <div className="card border radius p-3 pb-1">
                  <p className="text-dark mb-3">Ready To Deliver</p>
                  <div className="row px-2">
                    <div className="form-check col-6">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="ready1" />
                      <label className="form-check-label" htmlFor="ready1">
                        Itemwise
                      </label>
                    </div>
                    <div className="form-check col-6">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="ready2" />
                      <label className="form-check-label" htmlFor="ready2">
                        Orderwise
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <div className="card border radius p-3 pb-1">
                  <p className="text-dark mb-3">Delivering</p>
                  <div className="row px-2">
                    <div className="form-check col-6">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="delivery1" />
                      <label className="form-check-label" htmlFor="delivery1">
                        Itemwise
                      </label>
                    </div>
                    <div className="form-check col-6">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="delivery2" />
                      <label className="form-check-label" htmlFor="delivery2">
                        Orderwise
                      </label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>


      </div>
    </div>
  )
}

