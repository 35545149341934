import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import { omit } from "lodash";

const animatedComponents = makeAnimated();

const franchise_initial_value = {
  gst_percent: "",
  hsn: "",
  package_breadth: "",
  package_breadth_unit: "1",
  package_breadth_unit_name: "",
  package_height: "",
  package_height_unit: "1",
  package_height_unit_name: "",
  package_length: "",
  package_length_unit: "1",
  package_length_unit_name: "",
  package_weight: "",
  package_weight_unit: "1",
  package_weight_unit_name: "",
};

let myModal = {};

export default function ModalEditAdditional(props: any) {
  console.log(props);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());

  const [franchise_data, SetFranchiseData] = useState(franchise_initial_value);
  const [user_role_options, SetUserRoleOptions] = useState({});
  const [text, setText] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [user_data, SetUserData] = useState([]);
  const [next_page, SetNextPage] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const [new_franchise_id, SetNewFranchiseId] = useState(null);
  const [modal_type, SetModalType] = useState("");
  const [dimention_unit, SetDimentionUnit] = useState([]);
  const [weight_unit, SetWeightUnit] = useState([]);
  const [variant_id, SetVariantId] = useState("");




  //FranchiseData End


  useEffect(() => {
    if (props.open) {
      console.log("ModalFranchiseUser On mount :", props);
      console.log("ModalFranchiseUser On mount :", props.units);

      myModal = new bootstrap.Modal(
        document.getElementById("FranchiseUserModal1"),
        {}
      );
      myModal.show();
      // SetModalType(props.type);
      // get_roles();
      // get_users(page_no);
      // if (props.type == "edit" || props.type == "view") {
      let set_data = {
     
        package_breadth: 
        // props.data.package_breadth,

        props.data.hasOwnProperty("package_breadth") &&
        props.data.package_breadth != null
          ? props.data.package_breadth
          : "",
        package_breadth_unit: 
        props.data.hasOwnProperty("package_breadth_unit") &&
        props.data.package_breadth_unit != null
          ? props.data.package_breadth_unit
          : "1",
       
        package_breadth_unit_name:
        props.data.hasOwnProperty("package_breadth_unit_name") &&
        props.data.package_breadth_unit_name != null
          ? props.data.package_breadth_unit_name
          : "",

        package_height: 
        props.data.hasOwnProperty("package_height") &&
        props.data.package_height != null
          ? props.data.package_height
          : "",
        package_height_unit:
        props.data.hasOwnProperty("package_height_unit") &&
        props.data.package_height_unit != null
          ? props.data.package_height_unit
          : "1",

          
      
        package_height_unit_name:
        
        props.data.hasOwnProperty("package_height_unit_name") &&
        props.data.package_height_unit_name != null
          ? props.data.package_height_unit_name
          : "",
  
        package_length:
        props.data.hasOwnProperty("package_length") &&
        props.data.package_length != null
          ? props.data.package_length
          : "",
  
 
        package_length_unit:
        props.data.hasOwnProperty("package_length_unit") &&
        props.data.package_length_unit != null
          ? props.data.package_length_unit
          : "1",
    
        package_length_unit_name: 
        props.data.hasOwnProperty("package_length_unit_name") &&
        props.data.package_length_unit_name != null
          ? props.data.package_length_unit_name
          : "",
     
        package_weight:
        props.data.hasOwnProperty("package_weight") &&
        props.data.package_weight != null
          ? props.data.package_weight
          : "",
      
        package_weight_unit: 
        props.data.hasOwnProperty("package_weight_unit") &&
        props.data.package_weight_unit != null
          ? props.data.package_weight_unit
          : "1",
   
        package_weight_unit_name: 
        props.data.hasOwnProperty("package_weight_unit_name") &&
        props.data.package_weight_unit_name != null
          ? props.data.package_weight_unit_name
          : "",

          gst_percent: 
          props.data.hasOwnProperty("gst_percent") &&
          props.data.gst_percent != null
            ? props.data.gst_percent
            : "",

         
            hsn:

            props.data.hasOwnProperty("hsn") &&
            props.data.hsn != null
              ? props.data.hsn
              : "",
   
      };
      console.log("On edit franchise data :", set_data);
      SetFranchiseData(set_data);
      SetDimentionUnit(props.units.dimensions);
      SetWeightUnit(props.units.weight);
      SetVariantId(props.id);
    }
    // }
  }, [props.open]);

  useEffect(() => {
    console.log("ModalFranchise On mount :", props);
    let myModal1 = document.getElementById("FranchiseUserModal1");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
     

      props.close(close_data);
    });
  }, []);


  async function get_roles() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let data_res = await api("/brand/get_brand_roles", pass_data);
    console.log("get_franchise_roles response :", data_res.response);
    let option_data = [];
    Object.keys(data_res.response).map((key, index) => {
      let push_data = {};
      if (key == "custom_roles") {
        push_data.label = "Custom Roles";
        for (var i = 0; i < data_res.response[key].length; i++) {
          data_res.response[key][i].type = "custom_roles";
        }
        push_data.options = data_res.response[key];
        option_data.push(push_data);
      } else if (key == "roles") {
        push_data.label = "Roles";
        for (var i = 0; i < data_res.response[key].length; i++) {
          data_res.response[key][i].type = "roles";
        }
        push_data.options = data_res.response[key];
        option_data.push(push_data);
      }
    });
    console.log("Option Data :", option_data);
    SetUserRoleOptions(option_data);
    if (props.type == "edit" || props.type == "view") {
      let role_set_value = [];
      if (props.data.value.hasOwnProperty("roles")) {
        props.data.value.roles.map((role_item, role_index) => {
          role_set_value.push({
            label: role_item.name,
            value: role_item.role_id,
            type: "roles",
          });
        });
      }
      if (props.data.value.hasOwnProperty("custom_roles")) {
        props.data.value.custom_roles.map((role_item, role_index) => {
          role_set_value.push({
            label: role_item.name,
            value: role_item.role_id,
            type: "custom_roles",
          });
        });
      }
      SetFranchiseData((oldValues) => ({
        ...oldValues,
        selected_roles: role_set_value,
      }));
    }
  }

  async function get_users(page_value: any, search_value = null) {
    let pass_data = {};
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          franchise: id_value.franchise_id,
          page: page_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          franchise: id_value.franchise_id,
          page: page_value,
        },
      };
    }
    let response = await api("/brand/get_users_add_franchise", pass_data);
    console.log(
      "Franchise create /brand/get_users_add_franchise response :",
      response
    );
    if (response.response.data) {
      SetUserData(response.response.data);
    }
    SetNextPage(response.response.next_page);
  }

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const value = e.target.value;
    setText(value);
    console.log("Text onchange: ", value);
    if (value == "") {
      let page_no_value = 1;
      SetPageNo(page_no_value);
      get_users(page_no_value);
    } else {
      SetTimer(
        setTimeout(() => {
          get_users(1, value);
        }, WAIT_INTERVAL)
      );
    }
  }

  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != "") {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          franchise: id_value.franchise_id,
          page: page_no_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          franchise: id_value.franchise_id,
          page: page_no_value,
        },
      };
    }
    let response = await api("/brand/get_users_add_franchise", pass_data);
    console.log("Users get_users_add_franchise response :", response);
    SetUserData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  function franchise_value_on_change(name: any, value: any) {
    console.log(name,value);
    SetFranchiseData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  // async function add_user() {
  //   console.log("add_user data :", franchise_data);
  //   let post_value = {
  //     user_id: franchise_data.user_id,
  //     roles: {},
  //     address: franchise_data.address,
  //     mobile: franchise_data.mobile,
  //     whatsapp: franchise_data.whatsapp,
  //     email: franchise_data.email,
  //   };
  //   let roles_value = {
  //     roles: [],
  //     custom_roles: [],
  //   };
  //   franchise_data.selected_roles.map((item, index) => {
  //     if (item.type == "roles") {
  //       roles_value.roles.push(item.value);
  //     } else {
  //       roles_value.custom_roles.push(item.value);
  //     }
  //   });
  //   post_value.roles = roles_value;
  //   console.log("post_value :", post_value);
  //   let pass_data = {
  //     get: {
  //       brand: id_value.brand_id,
  //       franchise: id_value.franchise_id,
  //     },
  //     post: post_value,
  //   };
  //   let data_res = await api("/brand/add_franchise_user", pass_data);
  //   console.log("add_franchise_user response :", data_res.response);
  //   if (data_res.status_code == 201) {
  //     let item = close_data;
  //     item.action = "save";
  //     item.value = data_res.response.users[0];
  //     console.log("Close data : ", item);
  //     SetCloseData(item);
  //     SetFranchiseData(franchise_initial_value);
  //     myModal.hide();
  //   }
  // }

  async function update_user() {
    console.log("update_user :", franchise_data);
    let post_value = {
      gst_percent: franchise_data?.gst_percent,
      hsn: franchise_data?.hsn,
      package_breadth: franchise_data?.package_breadth,
      package_breadth_unit: franchise_data?.package_breadth_unit,
   
      package_height: franchise_data?.package_height,
      package_height_unit: franchise_data?.package_height_unit,
  
      package_length: franchise_data?.package_length,
      package_length_unit: franchise_data?.package_length_unit,

      package_weight: franchise_data?.package_weight,
      package_weight_unit: franchise_data?.package_weight_unit,

    };
 
    // franchise_data.selected_roles.map((item, index) => {
    //   if (item.type == "roles") {
    //     roles_value.roles.push(item.value);
    //   } else {
    //     roles_value.custom_roles.push(item.value);
    //   }
    // });
  
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        variant_id: variant_id,
      },
      post: post_value,
    };
    console.log("pass_data :", pass_data);

    let data_res = await api("/brand/brand_product_update_details", pass_data);
    console.log("/brand/brand_product_update_details:", data_res.response);
    if (data_res.status_code == 201) {
      let item = close_data;
      item.action = "edit";
      // item.value = data_res.response.data[0];
      // item.index = props.data.index;
      console.log("Close data : ", item);
      SetCloseData(item);
      SetFranchiseData(franchise_initial_value);
      myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="FranchiseUserModal1"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-fullscreen-sm-down">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              {/* {modal_type == "add"
                ? "Add User"
                : modal_type == "edit"
                ? "Edit User"
                : "View User"}  */}
              Edit Additional Details
            </h6>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            {/* <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button> */}
          </div>
          <div className="modal-body">
            Package Details
            <div className="mt-3">
              {userOpen ? (
                <div className="row">
                  <div className="col-12">
                    <label>Select user</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select User Name"
                      defaultValue={text}
                      onChange={(e) => {
                        onTextChanged(e);
                      }}
                    />
                  </div>

                  <h6 className="mt-3">Available Users</h6>
                  <div className="mt-2">
                    {user_data.map((item, sugIndex) => (
                      <div
                        className="cursor"
                        key={sugIndex}
                        onClick={() => {
                          if (!item.exists) {
                            SetFranchiseData((oldValues) => ({
                              ...oldValues,
                              selected_user: item,
                              user_id: item.id,
                            }));
                            setUserOpen(false);
                          }

                          console.log("Select item:", item);
                        }}
                      >
                        <p>
                          <i className="fas fa-user"></i> {item.name}{" "}
                          {item.exists ? " - User Already Exists" : null}
                        </p>
                      </div>
                    ))}
                  </div>
                  {next_page ? (
                    <p
                      onClick={() => {
                        load_more();
                      }}
                    >
                      Load More
                    </p>
                  ) : null}
                </div>
              ) : (
                <div>
                  {modal_type == "view" || "edit" ? (
                    <div className="row pack">
                      {console.log(franchise_data)}

                      <div className="col-12 col-md-6 mt-2">
                        <label className="form-label">Breadth</label>
                        {console.log(dimention_unit)}
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={franchise_data.package_breadth}
                            onChange={(e) => {
                              franchise_value_on_change("package_breadth", e.target.value);
                            }}
                            className="form-control w-70"
                            placeholder="Enter Breadth"
                            aria-describedby="basic-addon2"
                          />
                          <select
                            className="form-select pack-width"                            
                            aria-label="Default select example"
                            onChange={(e) => {
                              franchise_value_on_change(
                                "package_breadth_unit",
                                e.target.value,
                              );
                            }}
                          >
                            {/* <option selected>{franchise_data.package_breadth_unit_name}</option> */}

                            {dimention_unit?.map((ele: any) => (
                              <option
                                value={ele.id}
                            
                              >
                                {ele.nicename}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mt-2">
                        <label className="form-label">Height</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={franchise_data.package_height}
                            onChange={(e) => {
                              franchise_value_on_change("package_height", e.target.value);
                            }}
                            className="form-control w-70"
                            placeholder="Enter Height"
                            aria-describedby="basic-addon2"
                          />
                          <select
                            className="form-select pack-width"
                            aria-label="Default select example"
                            onChange={(e) => {
                              franchise_value_on_change(
                                "package_height_unit",
                                e.target.value,
                              );
                            }}
                          >
                      
                      {dimention_unit?.map((ele: any) => (
                              <option
                                value={ele.id}
                            
                              >
                                {ele.nicename}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mt-2">
                        <label className="form-label">Length </label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={franchise_data.package_length}
                            onChange={(e) => {
                              franchise_value_on_change("package_length", e.target.value);
                            }}
                            className="form-control w-70"
                            placeholder="Enter Length"
                            aria-describedby="basic-addon2"
                          />
                          <select
                            className="form-select pack-width"
                            aria-label="Default select example"
                            onChange={(e) => {
                              franchise_value_on_change(
                                "package_length_unit",
                                e.target.value,
                              );
                            }}
                          >
                                     {dimention_unit?.map((ele: any) => (
                              <option
                                value={ele.id}
                             className="w-auto"
                              >
                                {ele.nicename}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mt-2">
                        <label className="form-label">Weight</label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={franchise_data.package_weight}
                            className="form-control w-70"
                            onChange={(e) => {
                              franchise_value_on_change("package_weight", e.target.value);
                            }}
                            placeholder="Enter Weight"
                            aria-describedby="basic-addon2"
                          />
                          <select
                            className="form-select pack-width"
                            aria-label="Default select example"
                            onChange={(e) => {
                              franchise_value_on_change(
                                "package_weight_unit",
                                e.target.value,
                              );
                            }}
                          >
                                     {weight_unit?.map((ele: any) => (
                              <option
                                value={ele.id}
                            
                              >
                                {ele.nicename}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 my-2">Tax Information:</div>

                      <div className="col-12 col-md-6 mt-2">
                        <label className="form-label">Gst Percent</label>
                        <input
                          type="text"
                          value={franchise_data.gst_percent}
                          className="form-control w-100"
                          readOnly={modal_type == "view" ? true : false}
                          onChange={(e) => {
                            franchise_value_on_change(
                              "gst_percent",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-6 mt-2">
                        <label className="form-label">Hsn</label>
                        <input
                          type="text"
                          value={franchise_data.hsn}
                          className="form-control  w-100"
                          readOnly={modal_type == "view" ? true : false}
                          onChange={(e) => {
                            franchise_value_on_change("hsn", e.target.value);
                          }}
                        />
                      </div>

               
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            {/* {userOpen ? (
              <div>
                <button
                  type="button"
                  className="btn btn-light-outline"
                  onClick={() => {
                    setUserOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                {modal_type == "add" ? (
                  <button
                    type="button"
                    className="btn btn-light-outline"
                    onClick={() => {
                      add_user();
                    }}
                  >
                    Add
                  </button>
                ) : modal_type == "edit" ? ( */}
            <button
              type="button"
              className="btn bg-prime btn-radius px-4 text-white"
              onClick={() => {
                update_user();
              }}
            >
              Update
            </button>
            {/* ) : (
                  <button
                    type="button"
                    className="btn btn-light-outline"
                    onClick={(event) => {
                      console.log("Close modal", event);
                      SetCloseData(event);
                      myModal1.hide();
                    }}
                  >
                    Close
                  </button>
                )}
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
