import React, { useState, useEffect, useContext, useLayoutEffect, useRef } from "react";
import DataContext from "../../utils/Context/DataContext";
import { Link, useParams } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Select from "react-select";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import {
  MaterialIcons,
  AntDesign,
  Ionicons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import Slider from "@material-ui/core/Slider";
import { api } from "../../utils/Api";
import toast from "react-hot-toast";
import { AppConfig } from "../../config";
import Skeleton from "react-loading-skeleton";


function valuetext(value) {
  return `${value}°C`;
}

const pricingOptions = [
  { value: "outlet", label: "Outlet" },
  { value: "channel", label: "Channel" },
];

const priceType = [
  { value: "margin", label: "Margin Price" },
  { value: "buy", label: "Buying Price" },
  { value: "commission", label: "Commission Price" },
];

function get_group_variant(parent_object, from) {
  let variant_object, variant_unique_id, group_object, group_unique_id;
  if (from == "Non Mrp Pricing") {
    variant_object = parent_object;
    variant_unique_id = parent_object.unique_id;
    group_object =
      variant_object[Object.getOwnPropertySymbols(variant_object)[0]];
    group_unique_id = group_object.unique_id;
    let return_value = {
      variant_object: variant_object,
      variant_unique_id: variant_unique_id,
      group_object: group_object,
      group_unique_id: group_unique_id,
    };
    return return_value;
  } else if (from == "Mrp Pricing") {
    variant_object =
      parent_object[Object.getOwnPropertySymbols(parent_object)[0]];
    variant_unique_id = variant_object.unique_id;
    group_object =
      variant_object[Object.getOwnPropertySymbols(variant_object)[0]];
    group_unique_id = group_object.unique_id;
    let return_value = {
      variant_object: variant_object,
      variant_unique_id: variant_unique_id,
      group_object: group_object,
      group_unique_id: group_unique_id,
    };
    return return_value;
  }
}

const NewCell = (props) => (
  <div className="th-width">
    <div className="d-flex align-items-center">
      {/* price_without_gst */}
      {props.selected_field_option.value == "price_without_gst" ? (
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">
            {props.mrp_data.currency}
          </span>
          <input
            type="number"
            // ref={buttonRef}
            className="form-control"
            placeholder="Price Without GST"
            value={props.value_data.price_without_gst}
            onChange={(event) => {
              console.log("onChange price_without_gst props :", props);
              // event.preventDefault();
              let send_data = {
                outlet_id: props.outlet_data.id,
                event_value: event,
                inventory_id: props.mrp_data.id,
                change_value: "price_without_gst",
                have_gst: props.row_data.hasOwnProperty("gst"),
                gst_value: props.row_data.hasOwnProperty("gst")
                  ? props.row_data.gst
                  : 0,
                hsn_value: props.row_data.hasOwnProperty("gst")
                  ? props.row_data.hsn
                  : 0,
                disable_gst: props.row_data.hasOwnProperty("gst"),
              };
              if (props.from == "outlet") {
                send_data.for_value = "outlet";
              } else {
                send_data.channel_id = props.channel_data.channel_id;
                send_data.variant_id = props.outlet_data.variant_id;
                send_data.for_value = "channel";
              }

              console.log("onChange send_data :", send_data);
              props.on_value_change(send_data);
            }}
          />
        </div>
      ) : null}

      {/* price_with_gst */}
      {props.selected_field_option.value == "price_with_gst" ? (
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">
            {props.mrp_data.currency}
          </span>
          <input
            type="number"
            className="form-control"
            placeholder="Price With GST"
            value={props.value_data.price_with_gst}
            onChange={(event) => {
              console.log("onChange price_with_gst props :", props);
              let send_data = {
                outlet_id: props.outlet_data.id,
                event_value: event,
                inventory_id: props.mrp_data.id,
                change_value: "price_with_gst",
                have_gst: props.row_data.hasOwnProperty("gst"),
                gst_value: props.row_data.hasOwnProperty("gst")
                  ? props.row_data.gst
                  : 0,
                hsn_value: props.row_data.hasOwnProperty("gst")
                  ? props.row_data.hsn
                  : 0,
                disable_gst: props.row_data.hasOwnProperty("gst"),
              };
              if (props.from == "outlet") {
                send_data.for_value = "outlet";
              } else {
                send_data.channel_id = props.channel_data.channel_id;
                send_data.variant_id = props.outlet_data.variant_id;
                send_data.for_value = "channel";
              }
              console.log("onChange price_with_gst send_data :", send_data);
              props.on_value_change(send_data);
            }}
          />
        </div>
      ) : null}

      {/* gst */}
      {props.selected_field_option.value == "gst" ? (
        <div className="input-group">
          <input
            type="number"
            className="form-control"
            placeholder="GST %"
            value={props.value_data.gst}
            onChange={(event) => {
              console.log("onChange gst props :", props);
              let send_data = {
                outlet_id: props.outlet_data.id,
                event_value: event,
                inventory_id: props.mrp_data.id,
                change_value: "gst",
                have_gst: props.row_data.hasOwnProperty("gst"),
                gst_value: props.row_data.hasOwnProperty("gst")
                  ? props.row_data.gst
                  : 0,
                hsn_value: props.row_data.hasOwnProperty("gst")
                  ? props.row_data.hsn
                  : 0,
                disable_gst: props.row_data.hasOwnProperty("gst"),
              };
              if (props.from == "outlet") {
                send_data.for_value = "outlet";
              } else {
                send_data.channel_id = props.channel_data.channel_id;
                send_data.variant_id = props.outlet_data.variant_id;
                send_data.for_value = "channel";
              }
              console.log("onChange gst send_data :", send_data);
              props.on_value_change(send_data);
            }}
          />
        </div>
      ) : null}

      {/* hsn */}
      {props.selected_field_option.value == "hsn" ? (
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">
            {props.mrp_data.currency}
          </span>
          <input
            type="number"
            className="form-control"
            placeholder="HSN"
            value={props.value_data.hsn}
            onChange={(event) => {
              console.log("onChange hsn props :", props);
              let send_data = {
                outlet_id: props.outlet_data.id,
                event_value: event,
                inventory_id: props.mrp_data.id,
                change_value: "hsn",
              };
              if (props.from == "outlet") {
                send_data.for_value = "outlet";
              } else {
                send_data.channel_id = props.channel_data.channel_id;
                send_data.variant_id = props.outlet_data.variant_id;
                send_data.for_value = "channel";
              }
              console.log("onChange gst send_data :", send_data);
              props.on_value_change(send_data);
            }}
          />
        </div>
      ) : null}

      {/* currency_id */}
      {props.selected_field_option.value == "currency_id" ? (
        <Select
          className="Price"
          options={props.currencies_option}
          value={props.value_data.selected_currency}
          onChange={(e) => {
            console.log("onChange hsn props :", props);
            let send_data = {
              outlet_id: props.outlet_data.id,
              event_value: e,
              inventory_id: props.mrp_data.id,
              change_value: "currency",
            };
            if (props.from == "outlet") {
              send_data.for_value = "outlet";
            } else {
              send_data.channel_id = props.channel_data.channel_id;
              send_data.variant_id = props.outlet_data.variant_id;
              send_data.for_value = "channel";
            }
            console.log("onChange gst send_data :", send_data);
            props.on_value_change(send_data);
            // console.log("currency :", e);
            // if (props.from == "outlet") {
            //   props.on_currency_change(
            //     props.outlet_data.id,
            //     props.outlet_data.variant_id,
            //     props.mrp_data.id,
            //     e
            //   );
            // } else {
            //   props.on_currency_change_channel(
            //     props.outlet_data.id,
            //     props.channel_data.channel_id,
            //     props.outlet_data.variant_id,
            //     props.mrp_data.id,
            //     e
            //   );
            // }
          }}
        />
      ) : null}

      {/* info */}
      <div
        className="ps-2 cursor"
        onClick={(event) => {
          // onBtnClick(rowData, props, event);
          console.log("Click Info :", props);
          let pass_data = {
            sellers_price: "",
            mrp_data: "",
          };
          props.on_view_full_data(pass_data);
        }}
      >
        <img src={AppConfig.CDN_Media_url+"66e4e76813fdc9ba6420923c5662b6fc.svg"} className="w-16px" />
        {/* <AntDesign name="infocirlceo" size={15} color="#663fc3" /> */}
      </div>
    </div>
  </div>
);

export default function ProductGroupPricingComponent1(props: any) {
  const context = useContext(DataContext);
  const [table_loading, SetTableLoading] = useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [width, SetWidth] = React.useState(innerWidth);
  const [id_value, SetIdValue] = useState(useParams());
  const [is_loading, SetIsLoading] = useState(false);

  const [hover, setHover] = React.useState(true);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [side_drawer, SetSideDrawer] = React.useState({
    type: "",
  });
  const [value, setValue] = React.useState(30);
  const [price_for, SetPriceFor] = useState(pricingOptions[1]);

  ////////////////////////////////////////////////////////////////    10/06/2022
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);
  const rows = 5; // Number of skeleton rows
  const columns = 2; // Number of skeleton columns
  const [view_option, SetViewOption] = useState([
    { label: "All", value: "all" },
    { label: "New", value: "new" },
    { label: "Active", value: "active" },
    { label: "Unavailable", value: "unavailable" },
    { label: "Paused", value: "paused" },
  ]);
  const [selected_view_option, SetSelectedViewOption] = useState({});
  const [count_option, SetCountOption] = useState([
    { label: "10", value: "10" },
    { label: "20", value: "30" },
    { label: "30", value: "30" },
  ]);
  const [selected_count_option, SetSelectedCountOption] = useState(
    count_option[0]
  );
  const [filter_products_value, SetFilterProductsValue] = useState({
    own_products: false,
    others_products: false,
  });
  const [search_value, SetSearchValue] = useState("");
  const [sort_option, SetSortOption] = useState([
    { label: "Ascending order", value: "asc" },
    { label: "Descending order", value: "desc" },
  ]);
  const [selected_sort_option, SetSelectedSortOption] = useState(
    sort_option[0]
  );
  const [product_brands_option, SetProductBrandsOption] = useState([]);
  const [selected_brand, SetSelectedBrand] = useState([]);
  const [product_categories_option, SetProductCategoriesOption] = useState([]);
  const [selected_category, SetSelectedCategory] = useState([]);
  const [filter_mrp, SetFilterMrp] = useState({
    min_mrp: "",
    max_mrp: "",
  });

  const [product_data, SetProductData] = useState([]);
  const [product_data1, SetProductData1] = useState([]);
  const [outlets, SetOutlet] = useState([]);
  const [size_value, SetSizeValue] = useState([]);
  const [image_base_url, SetImageBaseUrl] = useState("");
  const [selling_type_option, SetSellingTypeOption] = useState([]);
  // const [selected_selling_type, SetSelectedSellingType] = useState({});
  const [channel_option, SetChannelOption] = useState([]);
  const [outlet_option, SetOutletOption] = useState([]);

  const [currencies_option, SetCurrenciesOption] = useState([]);
  const [currencies_key, SetCurrenciesKey] = useState([]);

  //

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function getData() {
    return product_data;
  }

  function slide_drawer(anchor: any, open: any, event: any) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  }

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  /////////////////////////////////

  const [outlet_data, SetOutletData] = useState([]);
  const [selected_outlet_data, SetSelectedOutletData] = useState([]);
  const [channel_data, SetChannelData] = useState([]);
  const [selected_channel_data, SetSelectedChannelData] = useState([]);
  const [product_brands, SetProductBrands] = useState([]);
  const [selected_product_brands, SetSelectedProductBrands] = useState([]);
  const [product_categories, SetProductCategories] = useState([]);
  const [selected_product_categories, SetSelectedProductCategories] = useState(
    []
  );
  const [country_option, SetCountryOption] = useState(
    context.app_data.country_data
  );
  const [selected_country, SetSelectedCountry] = useState([
    {
      id: "99",
      iso: "IN",
      iso3: "IND",
      label: "India",
      name: "INDIA",
      nicename: "India",
      numcode: "356",
      phonecode: "91",
      value: "99",
    },
    // {
    //   // https://sellers.1superapp.com/app_dev/brand/init?brand=8&1id_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ0b2tlbiI6ImRjM2IzMDMzYzMzZDg5MDcwNWE0YTQ0YjZlNzA2YTUyIiwidXNlcm5hbWUiOiJuaXJtYWxkdXJhaSIsIm5hbWUiOiJOaXJtYWwgRHVyYWkgWSJ9.IW13ls4hMzfXQv3sW1OL1VXDReeHVDPx1QcD0kwmnGtxg00X1rtVxVGGJQbdTXoQJ-OIfm4WYtbif81QQb02uLGvCdUomzcgIqNh4w7TYMKXqyCEZQODmh_Wb1seiSdWxc-84cpzf35EK-BKgiZ4YTCxjnPV6L0WbIVdNJ1K2UJsvhWBi8z7zHDvOzLdgsJZcSVJbexLweHBHRonxUi1KbWFGMpYUxRHGym3v1NKVQyY7uFuf41B3wvErAOR0AcND8Moxz_SKK43jLaHF4fYCLGbfH2naciGG7w7XDLKqdmjcwmFZQPeA_dBlNUz3kKdxNZtCqteQ8ATh18hR6wOTA
    //   id: "45",
    //   iso: "CX",
    //   name: "CHRISTMAS ISLAND",
    //   nicename: "Christmas Island",
    //   iso3: null,
    //   numcode: null,
    //   phonecode: "61",
    //   value: "45",
    //   label: "Christmas Island",
    // },
  ]);
  const [field_option, SetFieldOption] = useState([
    // { label: "Sellers Price", value: "sellers_price" },
    // { label: "Selling Type", value: "selling_type" },
    // { label: "Min Selling Price", value: "min_selling_price" },
    // { label: "Max Selling Price", value: "max_selling_price" },
    // { label: "Fixed Selling Price", value: "fixed_selling_price" },
    // { label: "Currency", value: "currency_id" },
    { label: "Price Without GST", value: "price_without_gst" },
    { label: "Price With GST", value: "price_with_gst" },
    { label: "GST", value: "gst" },
    { label: "HSN", value: "hsn" },
    { label: "Currency", value: "currency_id" },
  ]);
  const [selected_field_option, SetSelectedFieldOption] = useState(
    field_option[0]
  );
  const [table_data, SetTableData] = useState([]);
  const [heading_outlets, SetHeadingOutlets] = useState([]);
  const [heading_outlet_channels, SetHeadingOutletChannels] = useState([]);
  const [outlet_columns, SetOutletColumns] = useState("");
  const [outlet_channel_columns, SetOutletChannelColumns] = useState("");
  const [save_price_data, SetSavePriceData] = useState([]);
  const [save_price_channel_data, SetSavePriceChannelData] = useState([]);
  const [initial_render, SetInitialRender] = useState(true);

  // edited only save values
  const [new_save_price_data, SetNewSavePriceData] = useState([]);
  const [new_save_price_channel_data, SetNewSavePriceChannelData] = useState(
    []
  );
  const [new_side_drawer_data, SetNewSideDrawerData] = useState({});
  const ref = useRef(null);
  const [width_ref, setWidthRef] = useState(0);
  const [height_ref, setHeightRef] = useState(0);
  const [numbers, setNumbers] = useState([]);


  useLayoutEffect(() => {
    setWidthRef(ref.current.offsetWidth);
    setHeightRef(ref.current.offsetHeight);
  }, [numbers]);

  useEffect(() => {
    console.log("Pricing Product On mount :", props);
    console.log("Pricing Product context :", context);
    get_outlet_pricing_init();

    function handleWindowResize() {
      setWidthRef(ref.current.offsetWidth);
      setHeightRef(ref.current.offsetHeight);
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    SetCountryOption(context.app_data.country_data);
  }, [context.app_data.country_data]);

  useEffect(() => {
    if (initial_render) {
      SetInitialRender(false);
    } else {
      if (search_value == "") {
        // get_addon_groups(filter_data, false)
        let filter_data = {
          outlet_data: outlet_data,
          channel_data: channel_data,
          price_for: price_for,
          page_no: page_no,
          search_value: search_value,
          view: selected_view_option,
          count: selected_count_option,
          filter_products: filter_products_value,
          sort: selected_sort_option,
          product_brands: selected_product_brands,
          product_categories: selected_product_categories,
        };
        get_pricing_products(selected_country, filter_data);
      } else {
        const timeOutId = setTimeout(() => triggerItemChange(), 500);
        return () => clearTimeout(timeOutId);
      }
    }
  }, [search_value]);

  function triggerItemChange() {
    SetPageNo((prevValue: any) => {
      prevValue = 1;
      let filter_data = {
        outlet_data: outlet_data,
        channel_data: channel_data,
        price_for: price_for,
        page_no: prevValue,
        search_value: search_value,
        view: selected_view_option,
        count: selected_count_option,
        filter_products: filter_products_value,
        sort: selected_sort_option,
        product_brands: selected_product_brands,
        product_categories: selected_product_categories,
      };
      get_pricing_products(selected_country, filter_data);
      return prevValue;
    });
  }

  async function get_outlet_pricing_init() {
    SetIsLoading(true)
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    console.log("get_outlet_pricing_init pass_data :", pass_data);
    let response = await api("/brand/outlet_product_pricing_init", pass_data);
    console.log("/brand/outlet_product_pricing_init response :", response);
    if (response.status_code == 200) {
      let outlet_data, channel_data;
      let selected_product_brand_value = [];
      let selected_product_categories_value = [];
      if (response.response.hasOwnProperty("options")) {
        if (response.response.options.hasOwnProperty("outlets")) {
          outlet_data = response.response.options.outlets;
          outlet_data.map((ele: any) => {
            ele.label = ele.name;
            ele.value = ele.id;
          });
          SetOutletData(outlet_data);
          SetSelectedOutletData(outlet_data);
        }
        if (response.response.options.hasOwnProperty("channels")) {
          channel_data = response.response.options.channels;
          channel_data.map((ele: any) => {
            ele.label = ele.name;
            ele.value = ele.id + "_" + ele.type;
          });
          SetChannelData(channel_data);
          SetSelectedChannelData(channel_data);
        }
        if (response.response.options.hasOwnProperty("product_brands")) {
          // SetProductBrands(response.response.options.product_brands);
          let brand_value = response.response.options.product_brands;
          brand_value.map((ele) => {
            ele.label = ele.name;
            ele.value = ele.id;
          });
          SetProductBrands(brand_value);
          // SetSelectedProductBrands(brand_value);
          // selected_product_brand_value = brand_value;
        }
        if (response.response.options.hasOwnProperty("product_categories")) {
          // SetProductCategories(response.response.options.product_categories);
          let cat_value = response.response.options.product_categories;
          cat_value.map((ele) => {
            ele.label = ele.name;
            ele.value = ele.id;
          });
          SetProductCategories(cat_value);
          // SetSelectedProductCategories(cat_value)
          // selected_product_categories_value = cat_value;
        }
        if (response.response.options.hasOwnProperty("sizes")) {
          SetSizeValue(response.response.options.sizes);
        }
        if (response.response.options.hasOwnProperty("image_base_url")) {
          SetImageBaseUrl(response.response.options.image_base_url);
        }
        if (response.response.options.hasOwnProperty("selling_type")) {
          let selling_type_value = response.response.options.selling_type;
          selling_type_value.map((ele) => {
            ele.label = ele.name;
            ele.value = ele.id;
          });
          SetSellingTypeOption(selling_type_value);
        }
        if (response.response.options.hasOwnProperty("currencies")) {
          SetCurrenciesOption(response.response.options.currencies);
        }
        if (response.response.options.hasOwnProperty("currencies_key")) {
          SetCurrenciesKey(response.response.options.currencies_key);
        }
      }
      let filter_data = {
        outlet_data: outlet_data,
        channel_data: channel_data,
        price_for: price_for,
        page_no: page_no,
        search_value: search_value,
        view: selected_view_option,
        count: selected_count_option,
        filter_products: filter_products_value,
        sort: selected_sort_option,
        product_brands: selected_product_brand_value,
        product_categories: selected_product_categories_value,
      };


      get_pricing_products(selected_country, filter_data);

    }
  }

  async function get_pricing_products(selected_country_value, filter_data) {
    SetIsLoading(true)
    let filter_countries_value = [];
    let filter_outlet_value = [];
    let filter_channel_value = [];
    let filter_product_brands_value = [];
    let filter_product_categories_value = [];

    selected_country_value.map((ele: any) => {
      filter_countries_value.push(ele.id);
    });
    filter_data.outlet_data.map((ele: any) => {
      filter_outlet_value.push(ele.id);
    });
    filter_data.channel_data.map((ele: any) => {
      let push_value = {
        id: ele.id,
        type: ele.type,
      };
      filter_channel_value.push(push_value);
    });
    filter_data.product_brands.map((ele: any) => {
      filter_product_brands_value.push(ele.id);
    });
    filter_data.product_categories.map((ele: any) => {
      filter_product_categories_value.push(ele.id);
    });
    if (filter_data.price_for.value == "outlet") {
      filter_channel_value = [];
    }
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        page: filter_data.page_no,
        view: filter_data.view.value,
        filter_count: filter_data.count.value,
        filter_products: encodeURIComponent(
          JSON.stringify(filter_data.filter_products)
        ),
        filter_countries: encodeURIComponent(
          JSON.stringify(filter_countries_value)
        ),
        filter_outlets: encodeURIComponent(JSON.stringify(filter_outlet_value)),
        filter_channels: encodeURIComponent(
          JSON.stringify(filter_channel_value)
        ),
        search: filter_data.search_value,
        sort: filter_data.sort.value,
        filter_brand: encodeURIComponent(
          JSON.stringify(filter_product_brands_value)
        ),
        // filter_min_mrp: filter_mrp.min_mrp,
        // filter_max_mrp: filter_mrp.max_mrp,
        filter_categories: encodeURIComponent(
          JSON.stringify(filter_product_categories_value)
        ),
      },
    };

    console.log("get_pricing_products pass_data :", pass_data);
    let response = await api(
      "/brand/outlet_product_pricing_list_v4",
      pass_data
    );
    console.log("/brand/outlet_product_pricing_list_v4 response :", response);

    if (response.status_code == 200) {
      
      if (response.response.hasOwnProperty("data")) {
        SetProductData(response.response.data);
        SetProductData1(response.response.data);

        SetTableLoading(false);
        SetIsLoading(false)
      }
      if (response.response.hasOwnProperty("filter")) {
        if (response.response.filter.hasOwnProperty("product_brands")) {
          SetProductBrandsOption(response.response.filter.product_brands);
        }
        if (response.response.filter.hasOwnProperty("product_categories")) {
          SetProductCategoriesOption(
            response.response.filter.product_categories
          );
        }
      }
      if (response.response.hasOwnProperty("next_page")) {
        if (response.response.next_page) {
          SetNextPage(true);
          // SetPageNo((prevValue: any) => {
          //   prevValue = prevValue + 1;
          //   return prevValue;
          // });
        } else {
          SetNextPage(false);
        }
      }
      if (response.response.hasOwnProperty("table_data")) {
        let table_data_value = response.response.table_data;
        SetTableData(table_data_value);
        SetIsLoading(false)

      }
      if (response.response.hasOwnProperty("heading_outlets")) {
        SetHeadingOutlets(response.response.heading_outlets);
      }
      if (response.response.hasOwnProperty("heading_outlet_channels")) {
        SetHeadingOutletChannels(response.response.heading_outlet_channels);
      }
      if (response.response.hasOwnProperty("outlet_columns")) {
        SetOutletColumns(response.response.outlet_columns);
      }
      if (response.response.hasOwnProperty("outlet_channel_columns")) {
        SetOutletChannelColumns(response.response.outlet_channel_columns);
      }
    }
  }

  function found_index(prevValue, data, from) {
    let found_index = prevValue.findIndex((element) => {
      if (from == "outlet") {
        if (
          element.outlet_id == data.outlet_id &&
          element.variant_id == data.variant_id &&
          element.inventory_id == data.inventory_id
        ) {
          return true;
        }
      } else {
        if (
          element.outlet_id == data.outlet_id &&
          element.channel_id == data.channel_id &&
          element.product_id == data.product_id &&
          element.variant_id == data.variant_id &&
          element.inventory_id == data.inventory_id
        ) {
          return true;
        }
      }
    });
    return found_index;
  }

  async function save_product_pricing(selected_country_value, page_no_value) {
    let filter_countries_value = [];
    selected_country_value.map((ele: any) => {
      filter_countries_value.push(ele.id);
    });
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        filter_countries: encodeURIComponent(
          JSON.stringify(filter_countries_value)
        ),
        page: page_no_value,
      },
      post: {},
    };

    if (price_for.value == "outlet") {
      // pass_data.post = { data: save_price_data };
      pass_data.get.type = "outlet";
      pass_data.post = { data: save_price_data };
      pass_data.edited = new_save_price_data;
    } else {
      // pass_data.post = { data: save_price_channel_data };
      pass_data.get.type = "channel";
      // pass_data.post = { data: save_price_channel_data };
      // pass_data.edited = new_save_price_channel_data;
      pass_data.post = { data: new_save_price_channel_data };
    }

    console.log("save_product_pricing pass_data :", pass_data);

    let response = await api(
      "/brand/save_outlet_product_price_v4",
      pass_data,
      false,
      false,
      "raw_data"
    );
    console.log("/brand/save_outlet_product_price_v4 response :", response);
    // if (response.status_code == 200) {
    //   if (response.response.hasOwnProperty("table_data")) {
    //     let table_data_value = response.response.table_data;
    //     SetTableData(table_data_value);
    //   }
    //   if (response.response.hasOwnProperty("heading_outlets")) {
    //     SetHeadingOutlets(response.response.heading_outlets);
    //   }
    //   if (response.response.hasOwnProperty("heading_outlet_channels")) {
    //     SetHeadingOutletChannels(response.response.heading_outlet_channels);
    //   }
    //   if (response.response.hasOwnProperty("outlet_columns")) {
    //     SetOutletColumns(response.response.outlet_columns);
    //   }
    //   if (response.response.hasOwnProperty("outlet_channel_columns")) {
    //     SetOutletChannelColumns(response.response.outlet_channel_columns);
    //   }
    // }
    if (response.status_code == 200) {

      toast.success("Price Saved")

      let filter_data = {
        outlet_data: outlet_data,
        channel_data: channel_data,
        price_for: price_for,
        page_no: page_no,
        search_value: search_value,
        view: selected_view_option,
        count: selected_count_option,
        filter_products: filter_products_value,
        sort: selected_sort_option,
        product_brands: selected_product_brands,
        product_categories: selected_product_categories,
      };
      get_pricing_products(selected_country, filter_data);
    }
  }

  function on_next() {
    SetPageNo((prevValue: any) => {
      prevValue = prevValue + 1;
      // get_addon(prevValue);
      let filter_data = {
        outlet_data: outlet_data,
        channel_data: channel_data,
        price_for: price_for,
        page_no: prevValue,
        search_value: search_value,
        view: selected_view_option,
        count: selected_count_option,
        filter_products: filter_products_value,
        sort: selected_sort_option,
        product_brands: selected_product_brands,
        product_categories: selected_product_categories,
      };
      get_pricing_products(selected_country, filter_data);
      return prevValue;
    });
  }

  function on_prev() {
    SetPageNo((prevValue: any) => {
      prevValue = prevValue - 1;
      // get_addon(prevValue);
      let filter_data = {
        outlet_data: outlet_data,
        channel_data: channel_data,
        price_for: price_for,
        page_no: prevValue,
        search_value: search_value,
        view: selected_view_option,
        count: selected_count_option,
        filter_products: filter_products_value,
        sort: selected_sort_option,
        product_brands: selected_product_brands,
        product_categories: selected_product_categories,
      };
      get_pricing_products(selected_country, filter_data);
      return prevValue;
    });
  }

  const FilterComponent = () => {
    return (
      <div className="bg-fff">
        <div className="p-3 border-bottom sticky-top bg-fff d-flex align-items-center">
          <p className="text-dark fw-bold pb-4 fs-6">Filter</p>
          <div className="ms-auto p-2 cursor" onClick={() => {

          }}>
           <img
                  src={
                    AppConfig.CDN_Media_url +
                    "5f1c84a5b1601dde2c5c53b5fab5c150.svg"
                  }
                  className="type-img"
                />
          </div>
        </div>
        <div className="p-3">
          {/* Set price to */}
          <div className="mb-3">
            <label className="form-label mb-2 text-black">Set price to</label>
            <div className="">
              <Select
                options={pricingOptions}
                defaultValue={pricingOptions[1]}
                isDisabled={true}
                value={price_for}
                onChange={(e) => {
                  console.log("selected_type :", e.value);
                  SetPriceFor(e);
                  let filter_data = {
                    outlet_data: selected_outlet_data,
                    channel_data: selected_channel_data,
                    price_for: e,
                    page_no: page_no,
                    search_value: search_value,
                    view: selected_view_option,
                    count: selected_count_option,
                    filter_products: filter_products_value,
                    sort: selected_sort_option,
                    product_brands: selected_product_brands,
                    product_categories: selected_product_categories,
                  };
                  get_pricing_products(selected_country, filter_data);
                }}
              />
            </div>
          </div>
          {/* select product type */}
          <div className="mb-3">
            <label className="form-label mb-2 text-black">Products</label>
            <div className="mt-2">
              <label className="mb-2">
                <input
                  className="me-2"
                  type="checkbox"
                  checked={filter_products_value.own_products}
                  onChange={(e) => {
                    SetFilterProductsValue((prevValue: any) => {
                      prevValue.own_products = e.target.checked;
                      let filter_data = {
                        outlet_data: selected_outlet_data,
                        channel_data: selected_channel_data,
                        price_for: price_for,
                        page_no: page_no,
                        search_value: search_value,
                        view: selected_view_option,
                        count: selected_count_option,
                        filter_products: prevValue,
                        sort: selected_sort_option,
                        product_brands: selected_product_brands,
                        product_categories: selected_product_categories,
                      };
                      get_pricing_products(selected_country, filter_data);
                      return { ...prevValue };
                    });
                  }}
                />
                Own Products
              </label>
            </div>

            <div className="mt-1">
              <label className="mb-2">
                <input
                  className="me-2"
                  type="checkbox"
                  checked={filter_products_value.others_products}
                  onChange={(e) => {
                    SetFilterProductsValue((prevValue: any) => {
                      prevValue.others_products = e.target.checked;
                      let filter_data = {
                        outlet_data: selected_outlet_data,
                        channel_data: selected_channel_data,
                        price_for: price_for,
                        page_no: page_no,
                        search_value: search_value,
                        view: selected_view_option,
                        count: selected_count_option,
                        filter_products: prevValue,
                        sort: selected_sort_option,
                        product_brands: selected_product_brands,
                        product_categories: selected_product_categories,
                      };
                      get_pricing_products(selected_country, filter_data);
                      return { ...prevValue };
                    });
                  }}
                />
                Others Products
              </label>
            </div>
          </div>
          {/* select count */}
          <div className="mb-3">
            <label className="form-label mb-2 text-black">Select Count</label>
            <div className="">
              <Select
                className="View"
                options={count_option}
                value={selected_count_option}
                onChange={(e) => {
                  console.log("select count :", e);
                  SetSelectedCountOption(e);
                  let filter_data = {
                    outlet_data: selected_outlet_data,
                    channel_data: selected_channel_data,
                    price_for: price_for,
                    page_no: page_no,
                    search_value: search_value,
                    view: selected_view_option,
                    count: e,
                    filter_products: filter_products_value,
                    sort: selected_sort_option,
                    product_brands: selected_product_brands,
                    product_categories: selected_product_categories,
                  };
                  get_pricing_products(selected_country, filter_data);
                }}
              />
            </div>
          </div>
          {/* sort */}
          <div className="mb-3">
            <label className="form-label mb-2 text-black">Sorting</label>
            <div className="">
              <Select
                className="View"
                options={sort_option}
                value={selected_sort_option}
                onChange={(e) => {
                  console.log("select count :", e);
                  SetSelectedSortOption(e);
                  let filter_data = {
                    outlet_data: selected_outlet_data,
                    channel_data: selected_channel_data,
                    price_for: price_for,
                    page_no: page_no,
                    search_value: search_value,
                    view: selected_view_option,
                    count: selected_count_option,
                    filter_products: filter_products_value,
                    sort: e,
                    product_brands: selected_product_brands,
                    product_categories: selected_product_categories,
                  };
                  get_pricing_products(selected_country, filter_data);
                }}
              />
            </div>
          </div>
          {/* Select View */}
          <div className="mb-3">
            <label className="form-label mb-2 text-black">Select View</label>
            <div className="">
              <Select
                className="View"
                options={view_option}
                value={selected_view_option}
                onChange={(e) => {
                  console.log("select view :", e);
                  SetSelectedViewOption(e);
                  let filter_data = {
                    outlet_data: selected_outlet_data,
                    channel_data: selected_channel_data,
                    price_for: price_for,
                    page_no: page_no,
                    search_value: search_value,
                    view: e,
                    count: selected_count_option,
                    filter_products: filter_products_value,
                    sort: selected_sort_option,
                    product_brands: selected_product_brands,
                    product_categories: selected_product_categories,
                  };
                  get_pricing_products(selected_country, filter_data);
                }}
              />
            </div>
          </div>
          {/* Select Brand */}
          <div className="mb-3">
            <label className="form-label mb-2 text-black">Select Brand</label>
            <div className="">
              <Select
                className="Price"
                options={product_brands}
                value={selected_product_brands}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selected_data) => {
                  console.log("selected_outlets :", selected_data);
                  SetSelectedProductBrands(selected_data);
                  let filter_data = {
                    outlet_data: selected_data,
                    channel_data: selected_channel_data,
                    price_for: price_for,
                    page_no: page_no,
                    search_value: search_value,
                    view: selected_view_option,
                    count: selected_count_option,
                    filter_products: filter_products_value,
                    sort: selected_sort_option,
                    product_brands: selected_data,
                    product_categories: selected_product_categories,
                  };
                  get_pricing_products(selected_country, filter_data);
                }}
              />
            </div>
          </div>
          {/* Select Field */}
          <div className="mb-3">
            <label className="form-label mb-2 text-black">Select Field</label>
            <div className="">
              <Select
                className="Price"
                options={field_option}
                value={selected_field_option}
                onChange={(e) => {
                  console.log("selling Field :", e.value);
                  // SetSelectedSellingType(e);
                  SetSelectedFieldOption(e);
                  // selected_field_option = e;
                }}
              />
            </div>
          </div>
          {/* Select Outlets */}
          <div className="mb-3">
            <label className="form-label mb-2 text-black">Select Outlet</label>
            <div className="">
              <Select
                className="Price"
                options={outlet_data}
                value={selected_outlet_data}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selected_data) => {
                  console.log("selected_outlets :", selected_data);
                  SetSelectedOutletData(selected_data);
                  let filter_data = {
                    outlet_data: selected_data,
                    channel_data: selected_channel_data,
                    price_for: price_for,
                    page_no: page_no,
                    search_value: search_value,
                    view: selected_view_option,
                    count: selected_count_option,
                    filter_products: filter_products_value,
                    sort: selected_sort_option,
                    product_brands: selected_product_brands,
                    product_categories: selected_product_categories,
                  };
                  get_pricing_products(selected_country, filter_data);
                }}
              />
            </div>
          </div>
          {/* Select Channels */}
          {price_for.value == "channel" ? (
            <div className="mb-3">
              <label className="form-label mb-2 text-black">
                Select Channels
              </label>
              <div className="">
                <Select
                  className="Price"
                  options={channel_data}
                  value={selected_channel_data}
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selected_data) => {
                    console.log("selected_channels :", selected_data);
                    SetSelectedChannelData(selected_data);
                    let filter_data = {
                      outlet_data: selected_outlet_data,
                      channel_data: selected_data,
                      price_for: price_for,
                      page_no: page_no,
                      search_value: search_value,
                      view: selected_view_option,
                      count: selected_count_option,
                      filter_products: filter_products_value,
                      sort: selected_sort_option,
                      product_brands: selected_product_brands,
                      product_categories: selected_product_categories,
                    };
                    get_pricing_products(selected_country, filter_data);
                  }}
                />
              </div>
            </div>
          ) : null}
          {/* Select Country */}
          <div className="mb-3">
            <label className="form-label mb-2 text-black">Select Country</label>
            <div className="">
              <Select
                className="Price"
                options={country_option}
                value={selected_country}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selected_data) => {
                  console.log("selected_channels :", selected_data);
                  SetSelectedCountry(selected_data);
                  let filter_data = {
                    outlet_data: selected_outlet_data,
                    channel_data: selected_channel_data,
                    price_for: price_for,
                    page_no: page_no,
                    search_value: search_value,
                    view: selected_view_option,
                    count: selected_count_option,
                    filter_products: filter_products_value,
                    sort: selected_sort_option,
                    product_brands: selected_product_brands,
                    product_categories: selected_product_categories,
                  };
                  get_pricing_products(selected_data, filter_data);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  function get_new_cell_value(props, from, row_data) {
    let find_index;
    if (from == "outlet") {
      // outlet
      // finding index
      find_index = save_price_data.findIndex((element) => {
        if (
          element.outlet_id == props.outlet_id &&
          element.variant_id == props.variant_id &&
          element.inventory_id == props.inventory_id
        ) {
          return true;
        }
      });

      if (find_index !== -1) {
        let return_value = {
          price_without_gst:
            save_price_data[find_index].price.price_without_gst,
          price_with_gst: save_price_data[find_index].price.price_with_gst,
          gst: save_price_data[find_index].price.gst,
          hsn: save_price_data[find_index].price.hsn,
          selected_currency: save_price_data[find_index].selected_currency,
        };
        return return_value;
      } else {
        if (
          row_data.outlet_data.hasOwnProperty("price") &&
          Object.keys(row_data.outlet_data.price).length > 0
        ) {
          let selected_currency_value;
          currencies_option.map((cu_ele: any, cu_index: any) => {
            if (cu_ele.id == row_data.value_data.currency_id) {
              selected_currency_value = cu_ele;
            }
          });
          let push_value = {
            outlet_id: props.outlet_id,
            variant_id: props.variant_id,
            inventory_id: props.inventory_id,
            selected_currency: selected_currency_value,
            price: {
              price_without_gst: row_data.outlet_data.price.price_without_gst,
              price_with_gst: row_data.outlet_data.price.price_with_gst,
              gst: row_data.outlet_data.price.gst,
              hsn: row_data.outlet_data.price.hsn,
              currency_id: selected_currency_value.id,
            },
          };
          SetSavePriceData((prevValue: any) => {
            prevValue.push(push_value);
            return [...prevValue];
          });
          let return_value = {
            price_without_gst: row_data.outlet_data.price.price_without_gst,
            price_with_gst: row_data.outlet_data.price.price_with_gst,
            gst: row_data.outlet_data.price.gst,
            hsn: row_data.outlet_data.price.hsn,
            selected_currency: selected_currency_value,
          };
          return return_value;
        } else {
          return "";
        }
      }
    } else {
      // channel
      // finding index
      find_index = save_price_channel_data.findIndex((element) => {
        if (
          element.outlet_id == props.outlet_id &&
          element.channel_id == props.channel_id &&
          element.product_id == props.product_id &&
          element.variant_id == props.variant_id &&
          element.inventory_id == props.inventory_id
        ) {
          return true;
        }
      });

      if (find_index !== -1) {
        let return_value = {
          price_without_gst:
            save_price_channel_data[find_index].price.price_without_gst,
          price_with_gst:
            save_price_channel_data[find_index].price.price_with_gst,
          gst: save_price_channel_data[find_index].price.gst,
          hsn: save_price_channel_data[find_index].price.hsn,
          selected_currency:
            save_price_channel_data[find_index].selected_currency,
        };
        return return_value;
      } else {
        if (
          row_data.channel_data.hasOwnProperty("price") &&
          Object.keys(row_data.channel_data.price).length > 0
        ) {
          let selected_currency_value;
          currencies_option.map((cu_ele: any, cu_index: any) => {
            if (cu_ele.id == row_data.value_data.currency_id) {
              selected_currency_value = cu_ele;
            }
          });
          let push_value = {
            outlet_id: props.outlet_id,
            channel_id: props.channel_id,
            product_id: props.product_id,
            variant_id: props.variant_id,
            inventory_id: props.inventory_id,
            selected_currency: selected_currency_value,
            price: {
              price_without_gst:
                row_data.channel_data.price.selling_price_without_gst,
              price_with_gst:
                row_data.channel_data.price.selling_price_with_gst,
              gst: row_data.gst_value,
              hsn: row_data.hsn_value,
              currency_id: selected_currency_value.id,
            },
          };
          if (row_data.have_gst) {
            push_value.price.gst = row_data.ele_value.gst;
          }
          if (row_data.ele_value.hasOwnProperty("hsn")) {
            push_value.price.hsn = row_data.ele_value.hsn;
          }
          SetSavePriceChannelData((prevValue: any) => {
            prevValue.push(push_value);
            return [...prevValue];
          });
          let return_value = {
            price_without_gst:
              row_data.channel_data.price.selling_price_without_gst,
            price_with_gst: row_data.channel_data.price.selling_price_with_gst,
            gst: row_data.gst_value,
            hsn: row_data.hsn_value,
            selected_currency: selected_currency_value,
          };
          if (row_data.have_gst) {
            return_value.gst = row_data.gst_value;
            return_value.hsn = row_data.hsn_value;
          }
          return return_value;
        } else {
          let return_value = {
            price_without_gst:
              "",
            price_with_gst: "",
            gst: "",
            hsn: "",
            selected_currency: "",
          };
          if (row_data.have_gst) {
            return_value.gst = "";
            return_value.hsn = "";
          }
          return return_value;
          // return "";
        }
      }
    }
  }

  function value_on_change_outlet(get_data: any) {
    SetSavePriceData((prevValue: any) => {
      let is_found_index = found_index(
        prevValue,
        {
          outlet_id: get_data.outlet_id,
          variant_id: get_data.variant_id,
          inventory_id: get_data.inventory_id,
        },
        "outlet"
      );

      if (is_found_index !== -1) {
        if (get_data.change_value == "price_without_gst") {
          prevValue[is_found_index].price.price_without_gst =
            get_data.event_value.target.value;
        }
        if (get_data.change_value == "price_with_gst") {
          prevValue[is_found_index].price.price_with_gst =
            get_data.event_value.target.value;
        }
        if (get_data.change_value == "gst") {
          prevValue[is_found_index].price.gst =
            get_data.event_value.target.value;
        }
        if (get_data.change_value == "hsn") {
          prevValue[is_found_index].price.hsn =
            get_data.event_value.target.value;
        }
        if (get_data.change_value == "currency") {
          prevValue[is_found_index].selected_currency = get_data.event_value;
          prevValue[is_found_index].price.currency_id = get_data.event_value.id;
        }

        SetNewSavePriceData((prevValue1: any) => {
          let is_found_index1 = found_index(
            prevValue1,
            {
              outlet_id: get_data.outlet_id,
              variant_id: get_data.variant_id,
              inventory_id: get_data.inventory_id,
            },
            "outlet"
          );
          if (is_found_index1 !== -1) {
            if (get_data.change_value == "price_without_gst") {
              prevValue1[is_found_index1].price.price_without_gst =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "price_with_gst") {
              prevValue1[is_found_index1].price.price_with_gst =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "gst") {
              prevValue1[is_found_index1].price.gst =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "hsn") {
              prevValue1[is_found_index1].price.hsn =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "currency") {
              prevValue1[is_found_index1].selected_currency =
                get_data.event_value;
              prevValue1[is_found_index1].price.currency_id =
                get_data.event_value.id;
            }
          } else {
            let push_data = {
              outlet_id: get_data.outlet_id,
              variant_id: get_data.variant_id,
              inventory_id: get_data.inventory_id,
              selected_currency: prevValue[is_found_index].selected_currency,
              price: prevValue[is_found_index].price,
            };

            if (get_data.change_value == "price_without_gst") {
              push_data.price.price_without_gst =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "price_with_gst") {
              push_data.price.price_with_gst =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "gst") {
              push_data.price.gst = get_data.event_value.target.value;
            }
            if (get_data.change_value == "hsn") {
              push_data.price.hsn = get_data.event_value.target.value;
            }
            if (get_data.change_value == "currency") {
              push_data.selected_currency = get_data.event_value;
              push_data.price.currency_id = get_data.event_value.id;
            }
            prevValue1.push(push_data);
          }
          return [...prevValue1];
        });
      } else {
        let push_data = {
          outlet_id: get_data.outlet_id,
          variant_id: get_data.variant_id,
          inventory_id: get_data.inventory_id,
          selected_currency: {},
          price: {},
        };
        if (get_data.change_value == "price_without_gst") {
          push_data.price.price_without_gst = get_data.event_value.target.value;
        }
        if (get_data.change_value == "price_with_gst") {
          push_data.price.price_with_gst = get_data.event_value.target.value;
        }
        if (get_data.change_value == "gst") {
          push_data.price.gst = get_data.event_value.target.value;
        }
        if (get_data.change_value == "hsn") {
          push_data.price.hsn = get_data.event_value.target.value;
        }
        if (get_data.change_value == "currency") {
          push_data.selected_currency = get_data.event_value;
          push_data.price.currency_id = get_data.event_value.id;
        }
        prevValue.push(push_data);

        SetNewSavePriceData((prevValue1: any) => {
          let is_found_index1 = found_index(
            prevValue1,
            {
              outlet_id: get_data.outlet_id,
              variant_id: get_data.variant_id,
              inventory_id: get_data.inventory_id,
            },
            "outlet"
          );

          if (is_found_index1 !== -1) {
            if (get_data.change_value == "price_without_gst") {
              prevValue1[is_found_index1].price.price_without_gst =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "price_with_gst") {
              prevValue1[is_found_index1].price.price_with_gst =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "gst") {
              prevValue1[is_found_index1].price.gst =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "hsn") {
              prevValue1[is_found_index1].price.hsn =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "currency") {
              prevValue1[is_found_index1].selected_currency =
                get_data.event_value;
              prevValue1[is_found_index1].price.currency_id =
                get_data.event_value.id;
            }
          } else {
            let push_data1 = {
              outlet_id: get_data.outlet_id,
              variant_id: get_data.variant_id,
              inventory_id: get_data.inventory_id,
              selected_currency: {},
              price: {},
            };

            if (get_data.change_value == "price_without_gst") {
              push_data.price.price_without_gst =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "price_with_gst") {
              push_data.price.price_with_gst =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "gst") {
              push_data.price.gst = get_data.event_value.target.value;
            }
            if (get_data.change_value == "hsn") {
              push_data.price.hsn = get_data.event_value.target.value;
            }
            if (get_data.change_value == "currency") {
              push_data.selected_currency = get_data.event_value;
              push_data.price.currency_id = get_data.event_value.id;
            }
            prevValue1.push(push_data1);
          }
          return [...prevValue1];
        });
      }
      return [...prevValue];
    });
  }

  function get_price_with_gst(gst_value : any, entered_amount : any) {
    if (entered_amount === "") {
      entered_amount = 0;
    }
    let return_value : any, gst_amount : any;
    gst_amount = (entered_amount * gst_value) / 100;
    return_value = parseFloat(entered_amount) + parseFloat(gst_amount);
    console.log("return_value :", return_value);
    // return Math.round(parseFloat(return_value));
    return parseFloat(return_value).toFixed(2);
  }

  function get_price_without_gst(gst_value : any, entered_amount: any) {
    if (entered_amount === "") {
      entered_amount = 0;
    }
    let return_value : any, gst_amount : any;
    let value_123 = 100 / (100 + parseFloat(gst_value));
    let value_456 = entered_amount * value_123;
    gst_amount = entered_amount - value_456;
    return_value = parseFloat(entered_amount) - parseFloat(gst_amount);
    // console.log("value_123 :", value_123);
    // console.log("value_456 :", value_456);
    // console.log("gst_value get_price_without_gst :", gst_value);
    // console.log("gst_amount get_price_without_gst :", gst_amount);
    // console.log("entered_amount get_price_without_gst :", entered_amount);
    // console.log("return_value get_price_without_gst :", return_value);
    // return Math.round(parseFloat(return_value));
    return parseFloat(return_value).toFixed(2);

  }

  function value_on_change_channel(get_data: any) {
    SetSavePriceChannelData((prevValue) => {
      let is_found_index = found_index(
        prevValue,
        {
          outlet_id: get_data.outlet_id,
          channel_id: get_data.channel_id,
          product_id: get_data.product_id,
          variant_id: get_data.variant_id,
          inventory_id: get_data.inventory_id,
        },
        "channel"
      );
      let have_gst, gst_value, hsn_value, disable_value;
      console.log(
        "value_on_change_channel is_found_index :",
        is_found_index,
        prevValue
      );
      if (is_found_index !== -1) {
        have_gst = prevValue[is_found_index].price.hasOwnProperty("gst");
        gst_value = prevValue[is_found_index].price.gst;
        hsn_value = prevValue[is_found_index].price.hsn;
        disable_value = prevValue[is_found_index].price.hasOwnProperty("gst");

        if (
          Object.keys(prevValue[is_found_index].selected_currency).length == 0
        ) {
          prevValue[is_found_index].selected_currency = currencies_option[0];
          prevValue[is_found_index].price.currency_id = currencies_option[0].id;
        }

        // prevValue[is_found_index]

        if (get_data.change_value == "price_without_gst") {
          // prevValue[is_found_index].price.price_without_gst =
          //   get_data.event_value.target.value;

          if (have_gst && gst_value > 0) {
            // prevValue[is_found_index].price.price_without_gst =
            //   get_data.event_value.target.value;
            // let price_with_gst_value =
            //   get_data.event_value.target.value * (1 + gst_value / 100);
            let price_with_gst_value = get_price_with_gst(
              gst_value,
              get_data.event_value.target.value
            );
            // prevValue[is_found_index].price.price_with_gst =
            //     price_with_gst_value;
            if (
              parseFloat(price_with_gst_value) <=
              parseFloat(get_data.mrp_value.mrp)
            ) {
              prevValue[is_found_index].price.price_without_gst =
                get_data.event_value.target.value;
              prevValue[is_found_index].price.price_with_gst =
                price_with_gst_value;
            } else {
              toast.error("Price greater than mrp");
              // prevValue[is_found_index].price.price_without_gst = "";
              prevValue[is_found_index].price.price_with_gst = "";
              // console.log(
              //   "get_data.event_value.target.value :",
              //   get_data.event_value.target.value
              // );
              // console.log("price_with_gst_value :", price_with_gst_value);
              // console.log("get_data.mrp_value.mrp :", get_data.mrp_value.mrp);
            }
            prevValue[is_found_index].price.gst = gst_value;
          } else {
            prevValue[is_found_index].price.price_without_gst =
              get_data.event_value.target.value;
            prevValue[is_found_index].price.price_with_gst =
              get_data.event_value.target.value;
          }
        }
        if (get_data.change_value == "price_with_gst") {
          // prevValue[is_found_index].price.price_with_gst =
          //   get_data.event_value.target.value;
          console.log(
            "get_data.event_value.target.value :",
            get_data.event_value.target.value
          );
          console.log("get_data.mrp_value.mrp :", get_data.mrp_value.mrp);
          console.log("get_data.have_gst :", get_data.have_gst);
          console.log("gst_value :", gst_value);

          if (get_data.have_gst && gst_value > 0) {
            if (
              parseFloat(get_data.event_value.target.value) <=
              parseFloat(get_data.mrp_value.mrp)
            ) {
              prevValue[is_found_index].price.price_with_gst =
                get_data.event_value.target.value;
              // let price_without_gst_value =
              //   get_data.event_value.target.value / (1 + gst_value / 100);
              let price_without_gst_value = get_price_without_gst(
                gst_value,
                get_data.event_value.target.value
              );
              prevValue[is_found_index].price.price_without_gst =
                price_without_gst_value;
              prevValue[is_found_index].price.gst = gst_value;
            } else {
              toast.error("Price greater than mrp");
            }
          } else {
            prevValue[is_found_index].price.price_without_gst =
              get_data.event_value.target.value;
            prevValue[is_found_index].price.price_with_gst =
              get_data.event_value.target.value;
          }
        }
        if (get_data.change_value == "gst") {
          prevValue[is_found_index].price.gst =
            get_data.event_value.target.value;
          if (
            prevValue[is_found_index].price.hasOwnProperty(
              "price_without_gst"
            ) &&
            prevValue[is_found_index].price.hasOwnProperty("price_with_gst")
          ) {
            // prevValue[is_found_index].price.price_without_gst =
            //   prevValue[is_found_index].price.price_without_gst;
            // prevValue[is_found_index].price.price_with_gst =
            //   prevValue[is_found_index].price.price_without_gst *
            //   (1 + event_value.target.value / 100);
            let price_with_gst_value = get_price_with_gst(
              event_value.target.value,
              prevValue[is_found_index].price.price_without_gst
            );
            // prevValue[is_found_index].price.price_with_gst =
            //   price_with_gst_value;
            if (
              parseFloat(price_with_gst_value) <=
              parseFloat(get_data.mrp_value.mrp)
            ) {
              prevValue[is_found_index].price.price_without_gst =
                prevValue[is_found_index].price.price_without_gst;
              prevValue[is_found_index].price.price_with_gst =
                price_with_gst_value;
            } else {
              toast.error("Price greater than mrp");
              prevValue[is_found_index].price.price_with_gst = "";
            }
          }
        }
        if (get_data.change_value == "hsn") {
          prevValue[is_found_index].price.hsn =
            get_data.event_value.target.value;
        }
        if (get_data.change_value == "currency") {
          prevValue[is_found_index].selected_currency = get_data.event_value;
          prevValue[is_found_index].price.currency_id = get_data.event_value.id;
        }
      } else {
        let push_data = {
          outlet_id: get_data.outlet_id,
          channel_id: get_data.channel_id,
          product_id: get_data.product_id,
          variant_id: get_data.o_ele.variant_id,
          inventory_id: get_data.inventory_id,
          selected_currency: currencies_option[0],
          price: {
            currency_id: currencies_option[0].id,
          },
        };
        have_gst = get_data.have_gst;
        gst_value = get_data.gst_value;
        hsn_value = get_data.hsn_value;
        disable_value = get_data.disable_value;
        console.log("get_data :", get_data);
        if (get_data.change_value == "price_without_gst") {
          // push_data.price.price_without_gst = get_data.event_value.target.value;
          if (get_data.have_gst && get_data.gst_value > 0) {
            // push_data.price.price_without_gst =
            //   get_data.event_value.target.value;
            // let price_with_gst_value =
            //   get_data.event_value.target.value *
            //   (1 + get_data.gst_value / 100);
            let price_with_gst_value = get_price_with_gst(
              get_data.gst_value,
              get_data.event_value.target.value
            );
            // push_data.price.price_with_gst = price_with_gst_value;
            if (
              parseFloat(price_with_gst_value) <=
              parseFloat(get_data.mrp_value.mrp)
            ) {
              push_data.price.price_without_gst =
                get_data.event_value.target.value;
              push_data.price.price_with_gst = price_with_gst_value;
            } else {
              toast.error("Price greater than mrp");
              push_data.price.price_with_gst = "";
            }
            push_data.price.gst = get_data.gst_value;
          } else {
            push_data.price.price_without_gst =
              get_data.event_value.target.value;
            push_data.price.price_with_gst = get_data.event_value.target.value;
          }
        }
        if (get_data.change_value == "price_with_gst") {
          console.log(
            "get_data.event_value.target.value 1 :",
            get_data.event_value.target.value
          );
          console.log("get_data.mrp_value.mrp 1 :", get_data.mrp_value.mrp);
          console.log("get_data.have_gst :", get_data.have_gst);
          console.log("gst_value :", gst_value);
          console.log("get_data :", get_data);
          // push_data.price.price_with_gst = get_data.event_value.target.value;
          if (get_data.have_gst && get_data.gst_value > 0) {
            if (
              parseFloat(get_data.event_value.target.value) <=
              parseFloat(get_data.mrp_value.mrp)
            ) {
              push_data.price.price_with_gst =
                get_data.event_value.target.value;
              // let price_without_gst_value =
              //   get_data.event_value.target.value /
              //   (1 + get_data.gst_value / 100);
              let price_without_gst_value = get_price_without_gst(
                get_data.gst_value,
                get_data.event_value.target.value
              );
              push_data.price.price_without_gst = price_without_gst_value;
              push_data.price.gst = get_data.gst_value;
            } else {
              toast.error("Price greater than mrp");
            }
          } else {
            push_data.price.price_without_gst =
              get_data.event_value.target.value;
            push_data.price.price_with_gst = get_data.event_value.target.value;
          }
        }
        if (get_data.change_value == "gst") {
          push_data.price.gst = get_data.event_value.target.value;
          if (
            push_data.price.hasOwnProperty("price_without_gst") &&
            push_data.price.hasOwnProperty("price_with_gst")
          ) {
            // push_data.price.price_without_gst =
            //   push_data.price.price_without_gst;
            // push_data.price.price_with_gst =
            //   push_data.price.price_without_gst *
            //   (1 + get_data.event_value.target.value / 100);
            let price_with_gst_value = get_price_with_gst(
              get_data.event_value.target,
              push_data.price.price_without_gst
            );
            // push_data.price.price_with_gst = price_with_gst_value;
            if (
              parseFloat(price_with_gst_value) <=
              parseFloat(get_data.mrp_value.mrp)
            ) {
              push_data.price.price_without_gst =
                push_data.price.price_without_gst;
              push_data.price.price_with_gst = price_with_gst_value;
            } else {
              toast.error("Price greater than mrp");
              push_data.price.price_with_gst = "";
            }
          }
        }
        if (get_data.change_value == "hsn") {
          push_data.price.hsn = get_data.event_value.target.value;
        }
        if (get_data.change_value == "currency") {
          push_data.selected_currency = get_data.event_value;
          push_data.price.currency_id = get_data.event_value.id;
        }
        prevValue.push(push_data);
      }

      SetNewSavePriceChannelData((prevValue1: any) => {
        let is_found_index1 = found_index(
          prevValue1,
          {
            outlet_id: get_data.outlet_id,
            channel_id: get_data.channel_id,
            product_id: get_data.product_id,
            variant_id: get_data.variant_id,
            inventory_id: get_data.inventory_id,
          },
          "channel"
        );
        let have_gst, gst_value, hsn_value, disable_value;
        if (is_found_index1 !== -1) {
          have_gst = prevValue1[is_found_index1].price.hasOwnProperty("gst");
          gst_value = prevValue1[is_found_index1].price.gst;
          hsn_value = prevValue1[is_found_index1].price.hsn;
          disable_value =
            prevValue1[is_found_index1].price.hasOwnProperty("gst");

          if (
            Object.keys(prevValue1[is_found_index1].selected_currency).length ==
            0
          ) {
            prevValue1[is_found_index1].selected_currency =
              currencies_option[0];
            prevValue1[is_found_index1].price.currency_id =
              currencies_option[0].id;
          }

          if (get_data.change_value == "price_without_gst") {
            // prevValue1[is_found_index1].price.price_without_gst =
            //   get_data.event_value.target.value;
            if (have_gst && gst_value > 0) {
              // prevValue1[is_found_index1].price.price_without_gst =
              //   get_data.event_value.target.value;
              // let price_with_gst_value =
              //   get_data.event_value.target.value * (1 + gst_value / 100);
              let price_with_gst_value = get_price_with_gst(
                gst_value,
                get_data.event_value.target.value
              );
              // prevValue1[is_found_index1].price.price_with_gst =
              //   price_with_gst_value;
              if (
                parseFloat(price_with_gst_value) <=
                parseFloat(get_data.mrp_value.mrp)
              ) {
                prevValue1[is_found_index1].price.price_with_gst =
                  price_with_gst_value;
                prevValue1[is_found_index1].price.price_without_gst =
                  get_data.event_value.target.value;
              } else {
                console.log("Price greater than mrp 1");
                prevValue1[is_found_index1].price.price_with_gst = "";
              }
              prevValue1[is_found_index1].price.gst = gst_value;
            } else {
              prevValue1[is_found_index1].price.price_without_gst =
                get_data.event_value.target.value;
              prevValue1[is_found_index1].price.price_with_gst =
                get_data.event_value.target.value;
            }
          }
          if (get_data.change_value == "price_with_gst") {
            // prevValue1[is_found_index1].price.price_with_gst =
            //   get_data.event_value.target.value;
            if (get_data.have_gst && gst_value > 0) {
              if (
                parseFloat(get_data.event_value.target.value) <=
                parseFloat(get_data.mrp_value.mrp)
              ) {
                prevValue1[is_found_index1].price.price_with_gst =
                  get_data.event_value.target.value;
                // let price_without_gst_value =
                //   get_data.event_value.target.value / (1 + gst_value / 100);
                let price_without_gst_value = get_price_without_gst(
                  gst_value,
                  get_data.event_value.target.value
                );
                prevValue1[is_found_index1].price.price_without_gst =
                  price_without_gst_value;
                prevValue1[is_found_index1].price.gst = gst_value;
              } else {
                // toast.error("Price greater than mrp");
                console.log("Price greater than mrp");
              }
            } else {
              prevValue1[is_found_index1].price.price_without_gst =
                get_data.event_value.target.value;
              prevValue1[is_found_index1].price.price_with_gst =
                get_data.event_value.target.value;
            }
          }
          if (get_data.change_value == "gst") {
            prevValue1[is_found_index1].price.gst =
              get_data.event_value.target.value;
            if (
              prevValue1[is_found_index1].price.hasOwnProperty(
                "price_without_gst"
              ) &&
              prevValue1[is_found_index1].price.hasOwnProperty("price_with_gst")
            ) {
              // prevValue1[is_found_index1].price.price_without_gst =
              //   prevValue1[is_found_index1].price.price_without_gst;
              // prevValue1[is_found_index1].price.price_with_gst =
              //   prevValue1[is_found_index1].price.price_without_gst *
              //   (1 + event_value.target.value / 100);
              let price_with_gst_value = get_price_with_gst(
                event_value.target.value,
                prevValue1[is_found_index1].price.price_without_gst
              );
              // prevValue1[is_found_index1].price.price_with_gst =
              //   price_with_gst_value;
              if (
                parseFloat(price_with_gst_value) <=
                parseFloat(get_data.mrp_value.mrp)
              ) {
                prevValue1[is_found_index1].price.price_without_gst =
                  prevValue1[is_found_index1].price.price_without_gst;
                prevValue1[is_found_index1].price.price_with_gst =
                  price_with_gst_value;
              } else {
                console.log("Price greater than mrp 1");
                prevValue1[is_found_index1].price.price_with_gst = "";
              }
            }
          }
          if (get_data.change_value == "hsn") {
            prevValue1[is_found_index1].price.hsn =
              get_data.event_value.target.value;
          }
          if (get_data.change_value == "currency") {
            prevValue1[is_found_index1].selected_currency =
              get_data.event_value;
            prevValue1[is_found_index1].price.currency_id =
              get_data.event_value.id;
          }
        } else {
          console.log(
            "is_found_index prevValue :",
            is_found_index,
            prevValue,
            get_data
          );
          let push_data = {
            outlet_id: get_data.outlet_id,
            channel_id: get_data.channel_id,
            product_id: get_data.product_id,
            variant_id: get_data.variant_id,
            inventory_id: get_data.inventory_id,
            selected_currency: currencies_option[0],
            price: {
              currency_id: currencies_option[0].id,
            },
            // selected_currency: prevValue[is_found_index].selected_currency,
            // price: prevValue[is_found_index].price,
          };

          if (is_found_index != -1) {
            push_data.selected_currency =
              prevValue[is_found_index].selected_currency;
            push_data.price = prevValue[is_found_index].price;
          } else {
            push_data.selected_currency = {};
            push_data.price = {};
          }

          have_gst = get_data.have_gst;
          gst_value = get_data.gst_value;
          hsn_value = get_data.hsn_value;
          disable_value = get_data.disable_value;

          if (get_data.change_value == "price_without_gst") {
            // push_data.price.price_without_gst = get_data.event_value.target.value;
            if (get_data.have_gst && get_data.gst_value > 0) {
              // push_data.price.price_without_gst =
              //   get_data.event_value.target.value;
              // let price_with_gst_value =
              //   get_data.event_value.target.value *
              //   (1 + get_data.gst_value / 100);
              let price_with_gst_value = get_price_with_gst(
                get_data.gst_value,
                get_data.event_value.target.value
              );
              // push_data.price.price_with_gst = price_with_gst_value;
              if (
                parseFloat(price_with_gst_value) <=
                parseFloat(get_data.mrp_value.mrp)
              ) {
                push_data.price.price_without_gst =
                  get_data.event_value.target.value;
                push_data.price.price_with_gst = price_with_gst_value;
              } else {
                toast.error("Price greater than mrp");
                push_data.price.price_with_gst = "";
              }
              push_data.price.gst = get_data.gst_value;
            } else {
              push_data.price.price_without_gst =
                get_data.event_value.target.value;
              push_data.price.price_with_gst =
                get_data.event_value.target.value;
            }
          }
          if (get_data.change_value == "price_with_gst") {
            // push_data.price.price_with_gst = get_data.event_value.target.value;
            if (get_data.have_gst && get_data.gst_value > 0) {
              if (
                parseFloat(get_data.event_value.target.value) <=
                parseFloat(get_data.mrp_value.mrp)
              ) {
                push_data.price.price_with_gst =
                  get_data.event_value.target.value;
                // let price_without_gst_value =
                //   get_data.event_value.target.value /
                //   (1 + get_data.gst_value / 100);
                let price_without_gst_value = get_price_without_gst(
                  get_data.gst_value,
                  get_data.event_value.target.value
                );
                push_data.price.price_without_gst = price_without_gst_value;
                push_data.price.gst = get_data.gst_value;
              } else {
                // toast.error("Price greater than mrp");
                console.log("Price greater than mrp");
              }
            } else {
              push_data.price.price_without_gst =
                get_data.event_value.target.value;
              push_data.price.price_with_gst =
                get_data.event_value.target.value;
            }
          }
          if (get_data.change_value == "gst") {
            push_data.price.gst = get_data.event_value.target.value;
            if (
              push_data.price.hasOwnProperty("price_without_gst") &&
              push_data.price.hasOwnProperty("price_with_gst")
            ) {
              // push_data.price.price_without_gst =
              //   push_data.price.price_without_gst;
              // push_data.price.price_with_gst =
              //   push_data.price.price_without_gst *
              //   (1 + get_data.event_value.target.value / 100);
              let price_with_gst_value = get_price_with_gst(
                get_data.event_value.target.value,
                push_data.price.price_without_gst
              );
              // push_data.price.price_with_gst = price_with_gst_value;
              if (
                parseFloat(price_with_gst_value) <=
                parseFloat(get_data.mrp_value.mrp)
              ) {
                push_data.price.price_without_gst =
                  push_data.price.price_without_gst;
                push_data.price.price_with_gst = price_with_gst_value;
              } else {
                toast.error("Price greater than mrp");
                push_data.price.price_with_gst = "";
              }
            }
          }
          if (get_data.change_value == "hsn") {
            push_data.price.hsn = get_data.event_value.target.value;
          }
          if (get_data.change_value == "currency") {
            push_data.selected_currency = get_data.event_value;
            push_data.price.currency_id = get_data.event_value.id;
          }
          prevValue1.push(push_data);
        }
        return [...prevValue1];
      });
      return [...prevValue];
    });
  }

  return (
    <div className="">
      {/* <div
        className={
          props.is_menu_open
            ? table_data.length == 0 ? "head-bar w-100" : "head-bar sticky-sm table-head-bar"
            : "head-bar"
        }
        style={props.is_menu_open == false ? { width: width } : {}}
      > */}

      <div className={props.is_menu_open ? table_data.length == 0 ? " head-bar w-100" : " head-bar sticky-sm" : "head-bar sticky-top"}
        style={props.is_menu_open == false ? { width: width } : table_data.length != 0 ? { width: width_ref + 46 } : {}}
      >
        <div className=" mb-3">
          <div className=" d-flex align-items-center">
            <Link to={"/brand/" + props.value.brand_id + "/product_assign"}>
              <MaterialCommunityIcons
                name="arrow-left"
                size={20}
                color="black"
              />
            </Link>
            <h6 className="mb-0 ps-2">{props.title}</h6>

          </div>
        </div>
        <div className="row align-items-cetner">
          {/* search */}
          <div className="col-md-7 col-10 pe-0 ps-3">
            <div className="row px-md-3 md-none">
              <div className="input-icons form-group">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search products"
                  value={search_value}
                  onChange={(e) => {
                    console.log("search value :", e.target.value);
                    SetSearchValue(e.target.value);
                  }}
                />
                <div className="cursor ps-2">
                <img
                    src={
                      AppConfig.CDN_Media_url +
                      "c241785c497fb98f5f7d4e587b3857ba.svg"
                    }
                    className="w-20px"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* filter */}
          <div className="col-md-5 col-2">
            <div
              className="end cursor pt-2"
              onClick={(event) => {
                SetNewSideDrawerData((prevValue: any) => {
                  prevValue.type = "filter";
                  return { ...prevValue };
                });
                if (width < 768) {
                  slide_drawer("bottom", true, event);
                } else {
                  slide_drawer("right", true, event);
                }
              }}
            >
              {/* <Ionicons name="filter" size={18} color="black" /> */}
              <img src={AppConfig.CDN_Image_url+"311ae78abd7e491e4b25539cfbf1a03f.png"} className="type-img-big" />
              <p className="text-darkgray mb-0 ps-2 d-md-block d-sm-none">Filter</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className={
          props.is_menu_open
            ? table_data.length == 0 ? "mt-3 px-md-3 px-sm-0 w-100" : "mt-3 px-md-3 px-sm-0 overflow-page-hidden"
            : "mb-5 mt-3 px-md-3 px-sm-0 overflow-hidden"
        }
        style={props.is_menu_open == false ? { width: width } : {}}
      > */}

      <div className={props.is_menu_open ? table_data.length == 0 ? "mt-3 px-md-3 px-sm-0 w-100" : "mt-3 px-md-3 px-sm-0 overflow-page-hidden" : "mb-5 mt-3 px-md-3 px-sm-0 overflow-hidden"}
        style={props.is_menu_open == false ? { width: width } : table_data.length != 0 ? { width: width_ref + 46 } : {}}
      >
        {/* body */}
        <div ref={ref}>
          <div className="p-3 mt-2 w-100 bg-fff">
          {is_loading?
             <div className="mt-3 table-responsive">
             <table className="table table-bordered border table-bordered table-condensed">
               <thead className="sticky-table-head bg-fff">
                 <tr className="th-prod">
                   <th className="bg-fff col p-0" rowSpan="2">
                     <div className="px-3 py-4"><Skeleton width={100} /></div>
                   </th>
                   {Array.from({ length: columns }).map((_, index) => (
                     <th className="col p-0" key={index} colSpan={columns}>
                       <div className="text-center p-2"><Skeleton width={80} /></div>
                     </th>
                   ))}
                 </tr>
                 <tr>
                   {Array.from({ length: columns }).map((_, o_index) => (
                     Array.from({ length: columns }).map((_, c_index) => (
                       <th className="col p-0" key={o_index + "_" + c_index}>
                         <div className="p-2 text-center"><Skeleton width={50} /></div>
                       </th>
                     ))
                   ))}
                 </tr>
               </thead>
               <tbody>
                 {Array.from({ length: rows }).map((_, index) => (
                   <tr key={index}>
                     <td><Skeleton width={100} /></td>
                     {Array.from({ length: columns * columns }).map((_, c_index) => (
                       <td key={c_index}><Skeleton width={80} /></td>
                     ))}
                   </tr>
                 ))}
               </tbody>
             </table>
           </div>:table_data.length == 0 ? (
              <div className="center p-3 bg-fff">
                <div>
                <img
                    src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img"
                  />
                  <p className="text-center">No data found!</p>
                </div>
              </div>
            ) :
              <div className="mt-3 table-responsive  table-container " >
                <div className="row tle-fixed">
                  <table
                    border="1"
                    className="table table-hover align-middle table-bordered table-condensed" >
                    <thead className="sticky-table-head bg-fff">
                      {price_for.value == "outlet"
                        ? heading_outlets.map((ho_ele: any, ho_index: any) => (
                          <tr key={ho_index} className="">
                            {ho_ele.map((ho1_ele: any, ho1_index: any) => (
                              <th
                                className={
                                  ho1_index == 0
                                    ? "text-center col p-0"
                                    : " text-center-black col p-0 "
                                }
                                key={ho1_index}
                                rowSpan={
                                  ho1_ele.hasOwnProperty("rowspan")
                                    ? ho1_ele.rowspan
                                    : ""
                                }
                                colSpan={
                                  ho1_ele.hasOwnProperty("colspan")
                                    ? ho1_ele.colspan
                                    : ""
                                }
                              >
                                <div
                                  className={
                                    ho1_index == 0
                                      ? "p-2 text-center"
                                      : "p-2"
                                  }
                                >
                                  {ho1_ele.name}
                                </div>
                              </th>
                            ))}
                          </tr>
                        ))
                        : null}
                      {price_for.value == "channel"
                        ? heading_outlet_channels.map(
                          (hoc_ele: any, hoc_index: any) => (
                            <tr key={hoc_index}>
                              {hoc_ele.map((hoc1_ele: any, hoc1_index: any) => (
                                <th
                                  className={
                                    hoc1_ele.name == "Products"
                                      ? "text-center p-0 col bg-fff th-prod"
                                      : "p-0 col text-center bg-fff"
                                  }
                                  key={hoc1_index}
                                  rowSpan={
                                    hoc1_ele.hasOwnProperty("rowspan")
                                      ? hoc1_ele.rowspan
                                      : ""
                                  }
                                  colSpan={
                                    hoc1_ele.hasOwnProperty("colspan")
                                      ? hoc1_ele.colspan
                                      : ""
                                  }
                                >
                                  <div
                                    className={
                                      hoc1_ele.name == "Products"
                                        ? "p-2 text-center py-4"
                                        : "p-2"
                                    }
                                  >
                                    {hoc1_ele.name}
                                  </div>
                                </th>
                              ))}
                            </tr>
                          )
                        )
                        : null}
                    </thead>
                    <tbody>
                      {table_data.map((ele: any, index: any) => (
                        <>
                          {ele.type == "product_group" ? (
                            <tr key={index} className="border-bottom">
                               <th className="td-prod p-0" >
                              <div className=" fs-7 bg-fff p-2 ps-3" colSpan={
                                  price_for.value == "outlet"
                                    ? outlet_columns
                                    : outlet_channel_columns
                                }>
                               {ele.name}
                                </div>                              
                              </th>
                            </tr>
                          ) : null}

                          {ele.type == "product_variant" ? (
                            ele.hasOwnProperty("countries") &&
                              ele.countries.length > 0 ? (
                              ele.countries.map(
                                (country: any, country_index: any) =>
                                  country.hasOwnProperty("states") &&
                                    country.states.length > 0 ? (
                                    country.states.map(
                                      (state: any, state_index: any) =>
                                        state.hasOwnProperty("type") &&
                                          state.type.length > 0 ? (
                                          state.type.map(
                                            (type: any, type_index: any) =>
                                              type.hasOwnProperty("value") &&
                                                type.value.length > 0 ? (
                                                type.value.map(
                                                  (
                                                    value: any,
                                                    value_index: any
                                                  ) => (
                                                    <tr>
                                                      <>
                                                        {/* variant name */}
                                                        {country_index == 0 &&
                                                          state_index == 0 &&
                                                          type_index == 0 &&
                                                          value_index == 0 ? (
                                                          <td
                                                            rowSpan={ele.rowspan}
                                                            className=" bg-fff td-prod"
                                                          >
                                                            <div className="p-2">
                                                              {ele.name}
                                                            </div>
                                                          </td>
                                                        ) : null}
                                                        {/* country name */}
                                                        {state_index == 0 &&
                                                          type_index == 0 &&
                                                          value_index == 0 ? (
                                                          <td
                                                            className=""
                                                            rowSpan={
                                                              country.rowspan
                                                            }
                                                          >
                                                            {country.name}
                                                          </td>
                                                        ) : null}
                                                        {/* state name */}
                                                        {type_index == 0 &&
                                                          value_index == 0 ? (
                                                          <td
                                                            className=""
                                                            rowSpan={state.rowspan}
                                                          >
                                                            {state.name}
                                                          </td>
                                                        ) : null}
                                                        {/* type */}
                                                        {value_index == 0 ? (
                                                          <td
                                                            className=""
                                                            rowSpan={type.rowspan}
                                                          >
                                                            {type.name}
                                                          </td>
                                                        ) : null}
                                                        {/* value */}
                                                        {type.id == "1" ? (
                                                          <>
                                                            <td className="">
                                                              {value.mrp}
                                                            </td>
                                                            {/* mrp */}
                                                            <td className="">
                                                              {value.hasOwnProperty(
                                                                "mrp"
                                                              )
                                                                ? value.mrp
                                                                : null}
                                                            </td>
                                                          </>
                                                        ) : null}
                                                        {/* value */}
                                                        {type.id == "2" ? (
                                                          <>
                                                            <td className="">
                                                              {value.batch_number}
                                                            </td>
                                                            {/* mrp */}
                                                            <td className="">
                                                              {value.hasOwnProperty(
                                                                "mrp"
                                                              )
                                                                ? value.mrp
                                                                : null}
                                                            </td>
                                                          </>
                                                        ) : null}
                                                      </>
                                                      {/* outlets */}
                                                      {value.outlets.map(
                                                        (
                                                          o_ele: any,
                                                          o_index: any
                                                        ) => (
                                                          <>
                                                            {/* outlet */}
                                                            {/* {price_for.value == "outlet" ? (
                                                          <td className="">
                                                            {o_ele.enabled == true ? (
                                                              <NewCell
                                                                from={"outlet"}
                                                                row_data={ele}
                                                                outlet_data={o_ele}
                                                                outlet_index={o_index}
                                                                mrp_data={value}
                                                                selected_field_option={
                                                                  selected_field_option
                                                                }
                                                                currencies_option={
                                                                  currencies_option
                                                                }
                                                                seller_price_value={seller_price_value(
                                                                  {
                                                                    outlet_id: o_ele.id,
                                                                    product_id:
                                                                      o_ele.product_id,
                                                                    variant_id:
                                                                      o_ele.variant_id,
                                                                    mrp_id: value.id,
                                                                  },
                                                                  "outlet",
                                                                  {
                                                                    outlet_data: o_ele,
                                                                    outlet_index: o_index,
                                                                    value_data: value,
                                                                  }
                                                                )}
                                                                on_seller_price_change={(
                                                                  outlet_id,
                                                                  product_id,
                                                                  variant_id,
                                                                  mrp_id,
                                                                  event
                                                                ) => {
                                                                  SetSavePriceData(
                                                                    (prevValue: any) => {
                                                                      let selected_currency_value;
                                                                      currencies_option.map(
                                                                        (
                                                                          cu_ele: any,
                                                                          cu_index: any
                                                                        ) => {
                                                                          if (
                                                                            cu_ele.id ==
                                                                            value.currency_id
                                                                          ) {
                                                                            selected_currency_value =
                                                                              cu_ele;
                                                                          }
                                                                        }
                                                                      );
                                                                      let is_found_index =
                                                                        found_index(
                                                                          prevValue,
                                                                          {
                                                                            outlet_id:
                                                                              outlet_id,
                                                                            product_id:
                                                                              product_id,
                                                                            variant_id:
                                                                              variant_id,
                                                                            mrp_id: mrp_id,
                                                                          },
                                                                          "outlet"
                                                                        );

                                                                      // //////////////////////////////////////////////////////////////////////////

                                                                      let paltform_fee_type =
                                                                        "",
                                                                        platform_fee_value =
                                                                          "",
                                                                        platform_fee_amount =
                                                                          "",
                                                                        selling_price_data =
                                                                          "";
                                                                      let seller_price_data;

                                                                      if (
                                                                        is_found_index != -1
                                                                      ) {
                                                                        seller_price_data =
                                                                          save_price_data[
                                                                            is_found_index
                                                                          ].price
                                                                            .sellers_price;
                                                                      } else {
                                                                        seller_price_data = 0;
                                                                      }

                                                                      if (
                                                                        ele.hasOwnProperty(
                                                                          "platform_fee"
                                                                        )
                                                                      ) {
                                                                        if (
                                                                          ele.platform_fee
                                                                            .percentage !=
                                                                          null
                                                                        ) {
                                                                          paltform_fee_type =
                                                                            "percentage";
                                                                          platform_fee_value =
                                                                            ele.platform_fee
                                                                              .percentage;

                                                                          platform_fee_amount =
                                                                            (ele
                                                                              .platform_fee
                                                                              .percentage /
                                                                              100) *
                                                                            seller_price_data;
                                                                        }
                                                                        if (
                                                                          ele.platform_fee
                                                                            .amount != null
                                                                        ) {
                                                                          paltform_fee_type =
                                                                            "amount";
                                                                          platform_fee_value =
                                                                            ele.platform_fee
                                                                              .amount;

                                                                          platform_fee_amount =
                                                                            ele.platform_fee
                                                                              .amount;
                                                                        }
                                                                      }
                                                                      selling_price_data =
                                                                        parseFloat(
                                                                          platform_fee_amount
                                                                        ) +
                                                                        parseFloat(
                                                                          seller_price_data
                                                                        );

                                                                      // //////////////////////////////////////////////////////////////////////////

                                                                      if (
                                                                        is_found_index !==
                                                                        -1
                                                                      ) {
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.sellers_price =
                                                                          event.target.value;
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].selected_currency =
                                                                          selected_currency_value;
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.paltform_fee_type =
                                                                          paltform_fee_type;
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.platform_fee_value =
                                                                          platform_fee_value;
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.platform_fee_amount =
                                                                          platform_fee_amount;
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.selling_price_data =
                                                                          selling_price_data;
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.mrp_amount =
                                                                          value.mrp;
                                                                      } else {
                                                                        let push_data = {
                                                                          outlet_id:
                                                                            outlet_id,
                                                                          product_id:
                                                                            product_id,
                                                                          variant_id:
                                                                            variant_id,
                                                                          inventory_id: mrp_id,
                                                                          mrp_id: mrp_id,
                                                                          price: {
                                                                            sellers_price:
                                                                              event.target
                                                                                .value,
                                                                            currency_id:
                                                                              selected_currency_value.id,
                                                                            paltform_fee_type:
                                                                              paltform_fee_type,
                                                                            platform_fee_value:
                                                                              platform_fee_value,
                                                                            platform_fee_amount:
                                                                              platform_fee_amount,
                                                                            selling_price_data:
                                                                              selling_price_data,
                                                                            mrp_amount:
                                                                              value.mrp,
                                                                          },
                                                                        };
                                                                        prevValue.push(
                                                                          push_data
                                                                        );
                                                                      }
                                                                      return [...prevValue];
                                                                    }
                                                                  );
                                                                }}
                                                                seller_type_value={seller_type_value(
                                                                  {
                                                                    outlet_id: o_ele.id,
                                                                    product_id:
                                                                      o_ele.product_id,
                                                                    variant_id:
                                                                      o_ele.variant_id,
                                                                    mrp_id: value.id,
                                                                  },
                                                                  "outlet",
                                                                  {
                                                                    outlet_data: o_ele,
                                                                    outlet_index: o_index,
                                                                    value_data: value,
                                                                  }
                                                                )}
                                                                on_seller_type_change={(
                                                                  outlet_id,
                                                                  product_id,
                                                                  variant_id,
                                                                  mrp_id,
                                                                  event
                                                                ) => {
                                                                  SetSavePriceData(
                                                                    (prevValue: any) => {
                                                                      let is_found_index =
                                                                        found_index(
                                                                          prevValue,
                                                                          {
                                                                            outlet_id:
                                                                              outlet_id,
                                                                            product_id:
                                                                              product_id,
                                                                            variant_id:
                                                                              variant_id,
                                                                            mrp_id: mrp_id,
                                                                          },
                                                                          "outlet"
                                                                        );

                                                                      if (
                                                                        is_found_index !==
                                                                        -1
                                                                      ) {
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.selling_type =
                                                                          event;

                                                                        // if (
                                                                        //   event == "1"
                                                                        // ) {
                                                                        //   prevValue[
                                                                        //     is_found_index
                                                                        //   ].price.min_selling_price =
                                                                        //     prevValue[
                                                                        //       is_found_index
                                                                        //     ].price.selling_price_data;
                                                                        // }
                                                                        return [
                                                                          ...prevValue,
                                                                        ];
                                                                      } else {
                                                                        // let push_data = {
                                                                        //   outlet_id:
                                                                        //     outlet_id,
                                                                        //   product_id:
                                                                        //     product_id,
                                                                        //   variant_id:
                                                                        //     variant_id,
                                                                        //   mrp_id: mrp_id,
                                                                        //   price: {
                                                                        //     selling_type:
                                                                        //       event,
                                                                        //   },
                                                                        // };
                                                                        // prevValue.push(
                                                                        //   push_data
                                                                        // );

                                                                        toast.error(
                                                                          "Enter Sellers Price"
                                                                        );
                                                                        return [
                                                                          ...prevValue,
                                                                        ];
                                                                      }
                                                                    }
                                                                  );
                                                                }}
                                                                min_price_value={min_price_value(
                                                                  {
                                                                    outlet_id: o_ele.id,
                                                                    product_id:
                                                                      o_ele.product_id,
                                                                    variant_id:
                                                                      o_ele.variant_id,
                                                                    mrp_id: value.id,
                                                                  },
                                                                  "outlet",
                                                                  {
                                                                    outlet_data: o_ele,
                                                                    outlet_index: o_index,
                                                                    value_data: value,
                                                                  }
                                                                )}
                                                                on_min_price_change={(
                                                                  outlet_id,
                                                                  product_id,
                                                                  variant_id,
                                                                  mrp_id,
                                                                  event
                                                                ) => {
                                                                  SetSavePriceData(
                                                                    (prevValue: any) => {
                                                                      let is_found_index =
                                                                        found_index(
                                                                          prevValue,
                                                                          {
                                                                            outlet_id:
                                                                              outlet_id,
                                                                            product_id:
                                                                              product_id,
                                                                            variant_id:
                                                                              variant_id,
                                                                            mrp_id: mrp_id,
                                                                          },
                                                                          "outlet"
                                                                        );

                                                                      console.log(
                                                                        "prevValue index :",
                                                                        prevValue[
                                                                        is_found_index
                                                                        ]
                                                                      );

                                                                      let selling_price_data;
                                                                      if (
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.hasOwnProperty(
                                                                          "selling_price_data"
                                                                        )
                                                                      ) {
                                                                        selling_price_data =
                                                                          prevValue[
                                                                            is_found_index
                                                                          ].price
                                                                            .selling_price_data;
                                                                      } else {
                                                                        let paltform_fee_type =
                                                                          "",
                                                                          platform_fee_value =
                                                                            "",
                                                                          platform_fee_amount =
                                                                            "",
                                                                          seller_price_data;

                                                                        if (
                                                                          is_found_index !=
                                                                          -1
                                                                        ) {
                                                                          seller_price_data =
                                                                            save_price_data[
                                                                              is_found_index
                                                                            ].price
                                                                              .sellers_price;
                                                                        } else {
                                                                          seller_price_data = 0;
                                                                        }

                                                                        if (
                                                                          ele.hasOwnProperty(
                                                                            "platform_fee"
                                                                          )
                                                                        ) {
                                                                          if (
                                                                            ele.platform_fee
                                                                              .percentage !=
                                                                            null
                                                                          ) {
                                                                            paltform_fee_type =
                                                                              "percentage";
                                                                            platform_fee_value =
                                                                              ele
                                                                                .platform_fee
                                                                                .percentage;

                                                                            platform_fee_amount =
                                                                              (ele
                                                                                .platform_fee
                                                                                .percentage /
                                                                                100) *
                                                                              seller_price_data;
                                                                          }
                                                                          if (
                                                                            ele.platform_fee
                                                                              .amount !=
                                                                            null
                                                                          ) {
                                                                            paltform_fee_type =
                                                                              "amount";
                                                                            platform_fee_value =
                                                                              ele
                                                                                .platform_fee
                                                                                .amount;

                                                                            platform_fee_amount =
                                                                              ele
                                                                                .platform_fee
                                                                                .amount;
                                                                          }
                                                                        }
                                                                        selling_price_data =
                                                                          parseFloat(
                                                                            platform_fee_amount,
                                                                            2
                                                                          ) +
                                                                          parseFloat(
                                                                            seller_price_data,
                                                                            2
                                                                          );
                                                                      }

                                                                      console.log(
                                                                        "selling_price_data :",

                                                                        selling_price_data
                                                                      );

                                                                      if (
                                                                        is_found_index !==
                                                                        -1
                                                                      ) {
                                                                        if (
                                                                          event.target
                                                                            .value >
                                                                          selling_price_data
                                                                        ) {
                                                                          if (
                                                                            prevValue[
                                                                              is_found_index
                                                                            ].price.hasOwnProperty(
                                                                              "max_selling_price"
                                                                            ) &&
                                                                            prevValue[
                                                                              is_found_index
                                                                            ].price
                                                                              .min_selling_price >
                                                                            0
                                                                          ) {
                                                                            if (
                                                                              event.target
                                                                                .value <
                                                                              prevValue[
                                                                                is_found_index
                                                                              ].price
                                                                                .max_selling_price
                                                                            ) {
                                                                              prevValue[
                                                                                is_found_index
                                                                              ].price.min_selling_price =
                                                                                event.target.value;

                                                                              return [
                                                                                ...prevValue,
                                                                              ];
                                                                            } else {
                                                                              prevValue[
                                                                                is_found_index
                                                                              ].price.min_selling_price =
                                                                                event.target.value;
                                                                              toast.error(
                                                                                "Min Price is greater than Max price"
                                                                              );
                                                                              return [
                                                                                ...prevValue,
                                                                              ];
                                                                            }
                                                                          } else {
                                                                            prevValue[
                                                                              is_found_index
                                                                            ].price.min_selling_price =
                                                                              event.target.value;

                                                                            return [
                                                                              ...prevValue,
                                                                            ];
                                                                          }
                                                                        } else {
                                                                          prevValue[
                                                                            is_found_index
                                                                          ].price.min_selling_price =
                                                                            event.target.value;
                                                                          toast.error(
                                                                            "Min Price is lower than selling price"
                                                                          );
                                                                          return [
                                                                            ...prevValue,
                                                                          ];
                                                                        }
                                                                      } else {
                                                                        let push_data = {
                                                                          outlet_id:
                                                                            outlet_id,
                                                                          product_id:
                                                                            product_id,
                                                                          variant_id:
                                                                            variant_id,
                                                                          inventory_id: mrp_id,
                                                                          mrp_id: mrp_id,
                                                                          price: {
                                                                            min_selling_price:
                                                                              event.target
                                                                                .value,
                                                                          },
                                                                        };
                                                                        prevValue.push(
                                                                          push_data
                                                                        );

                                                                        return [
                                                                          ...prevValue,
                                                                        ];
                                                                      }
                                                                    }
                                                                  );
                                                                }}
                                                                max_price_value={max_price_value(
                                                                  {
                                                                    outlet_id: o_ele.id,
                                                                    product_id:
                                                                      o_ele.product_id,
                                                                    variant_id:
                                                                      o_ele.variant_id,
                                                                    mrp_id: value.id,
                                                                  },
                                                                  "outlet",
                                                                  {
                                                                    outlet_data: o_ele,
                                                                    outlet_index: o_index,
                                                                    value_data: value,
                                                                  }
                                                                )}
                                                                on_max_price_change={(
                                                                  outlet_id,
                                                                  product_id,
                                                                  variant_id,
                                                                  mrp_id,
                                                                  event
                                                                ) => {
                                                                  SetSavePriceData(
                                                                    (prevValue: any) => {
                                                                      let is_found_index =
                                                                        found_index(
                                                                          prevValue,
                                                                          {
                                                                            outlet_id:
                                                                              outlet_id,
                                                                            product_id:
                                                                              product_id,
                                                                            variant_id:
                                                                              variant_id,
                                                                            mrp_id: mrp_id,
                                                                          },
                                                                          "outlet"
                                                                        );
                                                                      if (
                                                                        is_found_index !==
                                                                        -1
                                                                      ) {
                                                                        // prevValue[
                                                                        //   is_found_index
                                                                        // ].price.max_selling_price =
                                                                        //   event.target.value;

                                                                        if (
                                                                          prevValue[
                                                                            is_found_index
                                                                          ].price
                                                                            .selling_price_data <=
                                                                          event.target.value
                                                                        ) {
                                                                          if (
                                                                            prevValue[
                                                                              is_found_index
                                                                            ].price.hasOwnProperty(
                                                                              "min_selling_price"
                                                                            ) &&
                                                                            prevValue[
                                                                              is_found_index
                                                                            ].price
                                                                              .min_selling_price >
                                                                            0
                                                                          ) {
                                                                            if (
                                                                              parseFloat(
                                                                                event.target
                                                                                  .value
                                                                              ) >
                                                                              parseFloat(
                                                                                prevValue[
                                                                                  is_found_index
                                                                                ].price
                                                                                  .min_selling_price
                                                                              )
                                                                            ) {
                                                                              prevValue[
                                                                                is_found_index
                                                                              ].price.max_selling_price =
                                                                                event.target.value;
                                                                              toast.success(
                                                                                "Max Price is ok"
                                                                              );
                                                                              return [
                                                                                ...prevValue,
                                                                              ];
                                                                            } else {
                                                                              prevValue[
                                                                                is_found_index
                                                                              ].price.max_selling_price =
                                                                                event.target.value;
                                                                              toast.error(
                                                                                "Max Price is less than Min price"
                                                                              );
                                                                              return [
                                                                                ...prevValue,
                                                                              ];
                                                                            }
                                                                          } else {
                                                                            prevValue[
                                                                              is_found_index
                                                                            ].price.max_selling_price =
                                                                              event.target.value;

                                                                            return [
                                                                              ...prevValue,
                                                                            ];
                                                                          }
                                                                        } else {
                                                                          prevValue[
                                                                            is_found_index
                                                                          ].price.max_selling_price =
                                                                            event.target.value;
                                                                          toast.error(
                                                                            "Max price is lower than selling price"
                                                                          );
                                                                          return [
                                                                            ...prevValue,
                                                                          ];
                                                                        }
                                                                      } else {
                                                                        let push_data = {
                                                                          outlet_id:
                                                                            outlet_id,
                                                                          product_id:
                                                                            product_id,
                                                                          variant_id:
                                                                            variant_id,
                                                                          inventory_id: mrp_id,
                                                                          mrp_id: mrp_id,
                                                                          price: {
                                                                            max_selling_price:
                                                                              event.target
                                                                                .value,
                                                                          },
                                                                        };
                                                                        prevValue.push(
                                                                          push_data
                                                                        );
                                                                        return [
                                                                          ...prevValue,
                                                                        ];
                                                                      }
                                                                    }
                                                                  );
                                                                }}
                                                                fixed_price_value={fixed_price_value(
                                                                  {
                                                                    outlet_id: o_ele.id,
                                                                    product_id:
                                                                      o_ele.product_id,
                                                                    variant_id:
                                                                      o_ele.variant_id,
                                                                    mrp_id: value.id,
                                                                  },
                                                                  "outlet",
                                                                  {
                                                                    outlet_data: o_ele,
                                                                    outlet_index: o_index,
                                                                    value_data: value,
                                                                  }
                                                                )}
                                                                on_fixed_price_change={(
                                                                  outlet_id,
                                                                  product_id,
                                                                  variant_id,
                                                                  mrp_id,
                                                                  event
                                                                ) => {
                                                                  SetSavePriceData(
                                                                    (prevValue: any) => {
                                                                      let is_found_index =
                                                                        found_index(
                                                                          prevValue,
                                                                          {
                                                                            outlet_id:
                                                                              outlet_id,
                                                                            product_id:
                                                                              product_id,
                                                                            variant_id:
                                                                              variant_id,
                                                                            mrp_id: mrp_id,
                                                                          },
                                                                          "outlet"
                                                                        );
                                                                      if (
                                                                        is_found_index !==
                                                                        -1
                                                                      ) {
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.fixed_selling_price =
                                                                          event.target.value;
                                                                      } else {
                                                                        let push_data = {
                                                                          outlet_id:
                                                                            outlet_id,
                                                                          product_id:
                                                                            product_id,
                                                                          variant_id:
                                                                            variant_id,
                                                                          inventory_id: mrp_id,
                                                                          mrp_id: mrp_id,
                                                                          price: {
                                                                            fixed_selling_price:
                                                                              event.target
                                                                                .value,
                                                                          },
                                                                        };
                                                                        prevValue.push(
                                                                          push_data
                                                                        );
                                                                      }
                                                                      return [...prevValue];
                                                                    }
                                                                  );
                                                                }}
                                                                currency_value={currency_value(
                                                                  {
                                                                    outlet_id: o_ele.id,
                                                                    product_id:
                                                                      o_ele.product_id,
                                                                    variant_id:
                                                                      o_ele.variant_id,
                                                                    mrp_id: value.id,
                                                                  },
                                                                  "outlet",
                                                                  {
                                                                    outlet_data: o_ele,
                                                                    outlet_index: o_index,
                                                                    value_data: value,
                                                                  }
                                                                )}
                                                                on_currency_change={(
                                                                  outlet_id,
                                                                  product_id,
                                                                  variant_id,
                                                                  mrp_id,
                                                                  event
                                                                ) => {
                                                                  SetSavePriceData(
                                                                    (prevValue: any) => {
                                                                      let is_found_index =
                                                                        found_index(
                                                                          prevValue,
                                                                          {
                                                                            outlet_id:
                                                                              outlet_id,
                                                                            product_id:
                                                                              product_id,
                                                                            variant_id:
                                                                              variant_id,
                                                                            mrp_id: mrp_id,
                                                                          },
                                                                          "outlet"
                                                                        );
                                                                      if (
                                                                        is_found_index !==
                                                                        -1
                                                                      ) {
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].selected_currency =
                                                                          event;
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.currency_id =
                                                                          event.id;
                                                                      } else {
                                                                        let push_data = {
                                                                          outlet_id:
                                                                            outlet_id,
                                                                          product_id:
                                                                            product_id,
                                                                          variant_id:
                                                                            variant_id,
                                                                          inventory_id: mrp_id,
                                                                          mrp_id: mrp_id,
                                                                          selected_currency:
                                                                            event,
                                                                          price: {
                                                                            currency_id:
                                                                              event.id,
                                                                          },
                                                                        };
                                                                        prevValue.push(
                                                                          push_data
                                                                        );
                                                                      }
                                                                      return [...prevValue];
                                                                    }
                                                                  );
                                                                }}
                                                                on_view_full_data={(
                                                                  data_value
                                                                ) => {
                                                                  console.log(
                                                                    "on_view_full_data:",
                                                                    data_value
                                                                  );
                                                                  let pass_value = {};
                                                                  let is_found_index =
                                                                    found_index(
                                                                      save_price_data,
                                                                      {
                                                                        outlet_id: o_ele.id,
                                                                        product_id:
                                                                          o_ele.product_id,
                                                                        variant_id:
                                                                          o_ele.variant_id,
                                                                        mrp_id: value.id,
                                                                      },
                                                                      "outlet"
                                                                    );
                                                                  let paltform_fee_type =
                                                                    "",
                                                                    platform_fee_value = "",
                                                                    platform_fee_amount =
                                                                      "",
                                                                    selling_price_data = "",
                                                                    seller_price_data = "";

                                                                  if (
                                                                    is_found_index != -1
                                                                  ) {
                                                                    seller_price_data =
                                                                      save_price_data[
                                                                        is_found_index
                                                                      ].price.sellers_price;
                                                                  } else {
                                                                    seller_price_data = 0;
                                                                  }

                                                                  if (
                                                                    ele.hasOwnProperty(
                                                                      "platform_fee"
                                                                    )
                                                                  ) {
                                                                    if (
                                                                      ele.platform_fee
                                                                        .percentage != null
                                                                    ) {
                                                                      paltform_fee_type =
                                                                        "percentage";
                                                                      platform_fee_value =
                                                                        ele.platform_fee
                                                                          .percentage;

                                                                      platform_fee_amount =
                                                                        (ele.platform_fee
                                                                          .percentage /
                                                                          100) *
                                                                        seller_price_data;
                                                                    }
                                                                    if (
                                                                      ele.platform_fee
                                                                        .amount != null
                                                                    ) {
                                                                      paltform_fee_type =
                                                                        "amount";
                                                                      platform_fee_value =
                                                                        ele.platform_fee
                                                                          .amount;

                                                                      platform_fee_amount =
                                                                        ele.platform_fee
                                                                          .amount;
                                                                    }
                                                                  }
                                                                  selling_price_data =
                                                                    parseFloat(
                                                                      platform_fee_amount,
                                                                      2
                                                                    ) +
                                                                    parseFloat(
                                                                      seller_price_data,
                                                                      2
                                                                    );

                                                                  console.log(
                                                                    "selling_price_data :",
                                                                    selling_price_data
                                                                  );
                                                                  console.log(
                                                                    "seller_price_data :",
                                                                    seller_price_data
                                                                  );
                                                                  console.log(
                                                                    "platform_fee_amount :",
                                                                    platform_fee_amount
                                                                  );
                                                                  console.log(
                                                                    "platform_fee_value :",
                                                                    platform_fee_value
                                                                  );
                                                                  console.log(
                                                                    "paltform_fee_type :",
                                                                    paltform_fee_type
                                                                  );

                                                                  let is_found_index_check;
                                                                  SetSavePriceData(
                                                                    (prevValue: any) => {
                                                                      let is_found_index =
                                                                        found_index(
                                                                          prevValue,
                                                                          {
                                                                            outlet_id:
                                                                              o_ele.id,
                                                                            product_id:
                                                                              o_ele.product_id,
                                                                            variant_id:
                                                                              o_ele.variant_id,
                                                                            mrp_id:
                                                                              value.id,
                                                                          },
                                                                          "outlet"
                                                                        );
                                                                      if (
                                                                        is_found_index !==
                                                                        -1
                                                                      ) {
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.paltform_fee_type =
                                                                          paltform_fee_type;
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.platform_fee_value =
                                                                          platform_fee_value;
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.platform_fee_amount =
                                                                          platform_fee_amount;
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.selling_price_data =
                                                                          selling_price_data;
                                                                        prevValue[
                                                                          is_found_index
                                                                        ].price.mrp_amount =
                                                                          value.mrp;
                                                                      } else {
                                                                        let push_data = {
                                                                          outlet_id:
                                                                            o_ele.id,
                                                                          product_id:
                                                                            o_ele.product_id,
                                                                          variant_id:
                                                                            o_ele.variant_id,
                                                                          inventory_id: mrp_id,
                                                                          mrp_id: value.id,
                                                                          selected_currency:
                                                                            {},
                                                                          price: {
                                                                            currency_id: "",
                                                                            paltform_fee_type:
                                                                              paltform_fee_type,
                                                                            platform_fee_value:
                                                                              platform_fee_value,
                                                                            platform_fee_amount:
                                                                              platform_fee_amount,
                                                                            selling_price_data:
                                                                              selling_price_data,
                                                                            mrp_amount:
                                                                              value.mrp,
                                                                          },
                                                                        };
                                                                        prevValue.push(
                                                                          push_data
                                                                        );
                                                                      }

                                                                      console.log(
                                                                        "outlet_id :",
                                                                        o_ele.id
                                                                      );
                                                                      console.log(
                                                                        "product_id :",
                                                                        o_ele.product_id
                                                                      );
                                                                      console.log(
                                                                        "variant_id :",
                                                                        o_ele.variant_id
                                                                      );
                                                                      console.log(
                                                                        "mrp_id :",
                                                                        value.id
                                                                      );

                                                                      return [...prevValue];
                                                                    }
                                                                  );

                                                                  is_found_index_check =
                                                                    found_index(
                                                                      save_price_data,
                                                                      {
                                                                        outlet_id: o_ele.id,
                                                                        product_id:
                                                                          o_ele.product_id,
                                                                        variant_id:
                                                                          o_ele.variant_id,
                                                                        mrp_id: value.id,
                                                                      },
                                                                      "outlet"
                                                                    );

                                                                  console.log(
                                                                    "is_found_index_check :",
                                                                    is_found_index_check
                                                                  );

                                                                  if (width < 768) {
                                                                    slide_drawer(
                                                                      "bottom",
                                                                      true,
                                                                      event
                                                                    );
                                                                  } else {
                                                                    slide_drawer(
                                                                      "right",
                                                                      true,
                                                                      event
                                                                    );
                                                                    console.log(
                                                                      "o_ele on open :",
                                                                      o_ele
                                                                    );
                                                                  }
                                                                }}
                                                              />
                                                            ) : null}
                                                          </td>
                                                        ) : null} */}

                                                            {/* channel */}
                                                            {price_for.value ==
                                                              "channel"
                                                              ? o_ele.hasOwnProperty(
                                                                "channels"
                                                              )
                                                                ? o_ele.channels.map(
                                                                  (
                                                                    c_ele: any,
                                                                    c_index: any
                                                                  ) => (
                                                                    <td className="">
                                                                      {c_ele.enabled ==
                                                                        true &&
                                                                        o_ele.enabled ==
                                                                        true ? (
                                                                        Object.keys(
                                                                          c_ele
                                                                        ).length >
                                                                          0 ? (
                                                                          <NewCell
                                                                            key={
                                                                              index +
                                                                              "_channel_" +
                                                                              o_index +
                                                                              "_" +
                                                                              c_index
                                                                            }
                                                                            from={
                                                                              "channel"
                                                                            }
                                                                            row_data={
                                                                              ele
                                                                            }
                                                                            outlet_data={
                                                                              o_ele
                                                                            }
                                                                            channel_data={
                                                                              c_ele
                                                                            }
                                                                            outlet_index={
                                                                              o_index
                                                                            }
                                                                            channel_index={
                                                                              c_index
                                                                            }
                                                                            mrp_data={
                                                                              value
                                                                            }
                                                                            selected_field_option={
                                                                              selected_field_option
                                                                            }
                                                                            currencies_option={
                                                                              currencies_option
                                                                            }
                                                                            value_data={get_new_cell_value(
                                                                              {
                                                                                outlet_id:
                                                                                  o_ele.id,
                                                                                channel_id:
                                                                                  c_ele.channel_id,
                                                                                product_id:
                                                                                  o_ele.product_id,
                                                                                variant_id:
                                                                                  o_ele.variant_id,
                                                                                inventory_id:
                                                                                  value.id,
                                                                              },
                                                                              "channel",
                                                                              {
                                                                                outlet_data:
                                                                                  o_ele,
                                                                                outlet_index:
                                                                                  o_index,
                                                                                channel_data:
                                                                                  c_ele,
                                                                                channel_index:
                                                                                  c_index,
                                                                                value_data:
                                                                                  value,
                                                                                have_gst:
                                                                                  ele.hasOwnProperty(
                                                                                    "gst"
                                                                                  ),
                                                                                gst_value:
                                                                                  ele.hasOwnProperty(
                                                                                    "gst"
                                                                                  )
                                                                                    ? ele.gst
                                                                                    : 0,
                                                                                hsn_value:
                                                                                  ele.hasOwnProperty(
                                                                                    "hsn"
                                                                                  )
                                                                                    ? ele.hsn
                                                                                    : 0,
                                                                                disable_gst:
                                                                                  ele.hasOwnProperty(
                                                                                    "gst"
                                                                                  ),
                                                                                ele_value:
                                                                                  ele,
                                                                              }
                                                                            )}
                                                                            on_value_change={(
                                                                              data_value
                                                                            ) => {
                                                                              data_value.o_ele =
                                                                                o_ele;
                                                                              data_value.product_id =
                                                                                o_ele.product_id;
                                                                              console.log(
                                                                                "on_value_change data_value channel:",
                                                                                data_value
                                                                              );
                                                                              data_value.mrp_value =
                                                                                value;
                                                                              value_on_change_channel(
                                                                                data_value
                                                                              );
                                                                            }}
                                                                            on_view_full_data={(
                                                                              data_value
                                                                            ) => {
                                                                              console.log(
                                                                                "save price channel data :",
                                                                                save_price_channel_data
                                                                              );
                                                                              let is_found_index_check =
                                                                                found_index(
                                                                                  save_price_channel_data,
                                                                                  {
                                                                                    outlet_id:
                                                                                      o_ele.id,
                                                                                    channel_id:
                                                                                      c_ele.channel_id,
                                                                                    product_id:
                                                                                      o_ele.product_id,
                                                                                    variant_id:
                                                                                      o_ele.variant_id,
                                                                                    inventory_id:
                                                                                      value.id,
                                                                                  },
                                                                                  "channel"
                                                                                );
                                                                              let is_found_new_index_check =
                                                                                found_index(
                                                                                  new_save_price_channel_data,
                                                                                  {
                                                                                    outlet_id:
                                                                                      o_ele.id,
                                                                                    channel_id:
                                                                                      c_ele.channel_id,
                                                                                    product_id:
                                                                                      o_ele.product_id,
                                                                                    variant_id:
                                                                                      o_ele.variant_id,
                                                                                    inventory_id:
                                                                                      value.id,
                                                                                  },
                                                                                  "channel"
                                                                                );
                                                                              if (
                                                                                width <
                                                                                768
                                                                              ) {
                                                                                slide_drawer(
                                                                                  "bottom",
                                                                                  true,
                                                                                  event
                                                                                );
                                                                              } else {
                                                                                slide_drawer(
                                                                                  "right",
                                                                                  true,
                                                                                  event
                                                                                );
                                                                              }
                                                                              let pass_value =
                                                                              {
                                                                                type: "information",
                                                                                from: "channel",
                                                                                mrp_value:
                                                                                  value,
                                                                                index:
                                                                                  is_found_index_check,
                                                                                new_index:
                                                                                  is_found_new_index_check,
                                                                                row_data:
                                                                                {
                                                                                  outlet_id:
                                                                                    o_ele.id,
                                                                                  channel_id:
                                                                                    c_ele.channel_id,
                                                                                  product_id:
                                                                                    o_ele.product_id,
                                                                                  variant_id:
                                                                                    o_ele.variant_id,
                                                                                  inventory_id:
                                                                                    value.id,
                                                                                  selected_currency:
                                                                                    currencies_option[0],
                                                                                  price:
                                                                                  {
                                                                                    price_without_gst:
                                                                                      "",
                                                                                    price_with_gst:
                                                                                      "",
                                                                                    gst: ele.hasOwnProperty(
                                                                                      "gst"
                                                                                    )
                                                                                      ? ele.gst
                                                                                      : "",
                                                                                    hsn: ele.hasOwnProperty(
                                                                                      "hsn"
                                                                                    )
                                                                                      ? ele.hsn
                                                                                      : "",
                                                                                    currency_id:
                                                                                      currencies_option[0]
                                                                                        .id,
                                                                                  },
                                                                                },
                                                                                have_gst:
                                                                                  ele.hasOwnProperty(
                                                                                    "gst"
                                                                                  ),
                                                                                gst_value:
                                                                                  ele.hasOwnProperty(
                                                                                    "gst"
                                                                                  )
                                                                                    ? ele.gst
                                                                                    : "",
                                                                                hsn_value:
                                                                                  ele.hasOwnProperty(
                                                                                    "hsn"
                                                                                  )
                                                                                    ? ele.hsn
                                                                                    : "",
                                                                                diasble_gst:
                                                                                  ele.hasOwnProperty(
                                                                                    "gst"
                                                                                  )
                                                                                    ? true
                                                                                    : false,
                                                                              };
                                                                              if (
                                                                                is_found_index_check !=
                                                                                -1
                                                                              ) {
                                                                                console.log(
                                                                                  "save_price_channel_data :",
                                                                                  save_price_channel_data
                                                                                );
                                                                                pass_value.row_data.selected_currency =
                                                                                  save_price_channel_data[
                                                                                    is_found_index_check
                                                                                  ].selected_currency;
                                                                                pass_value.row_data.price =
                                                                                  save_price_channel_data[
                                                                                    is_found_index_check
                                                                                  ].price;
                                                                                if (
                                                                                  is_found_new_index_check !=
                                                                                  -1
                                                                                ) {
                                                                                  if (
                                                                                    save_price_channel_data[
                                                                                      is_found_index_check
                                                                                    ].price.hasOwnProperty(
                                                                                      "gst"
                                                                                    )
                                                                                  ) {
                                                                                    pass_value.have_gst =
                                                                                      true;
                                                                                    pass_value.gst_value =
                                                                                      save_price_channel_data[
                                                                                        is_found_index_check
                                                                                      ].price.gst;
                                                                                  } else {
                                                                                    pass_value.have_gst =
                                                                                      false;
                                                                                    pass_value.gst_value =
                                                                                      "";
                                                                                  }
                                                                                  if (
                                                                                    save_price_channel_data[
                                                                                      is_found_index_check
                                                                                    ].price.hasOwnProperty(
                                                                                      "hsn"
                                                                                    )
                                                                                  ) {
                                                                                    pass_value.hsn_value =
                                                                                      save_price_channel_data[
                                                                                        is_found_index_check
                                                                                      ].price.hsn;
                                                                                  }
                                                                                  pass_value.diasble_gst =
                                                                                    ele.hasOwnProperty(
                                                                                      "gst"
                                                                                    );
                                                                                } else {
                                                                                  pass_value.diasble_gst =
                                                                                    ele.hasOwnProperty(
                                                                                      "gst"
                                                                                    );
                                                                                }
                                                                              } else {
                                                                                pass_value.row_data.selected_currency =
                                                                                  currencies_option[0];
                                                                                pass_value.row_data.price =
                                                                                {
                                                                                  price_without_gst:
                                                                                    "",
                                                                                  price_with_gst:
                                                                                    "",
                                                                                  gst: ele.hasOwnProperty(
                                                                                    "gst"
                                                                                  )
                                                                                    ? ele.gst
                                                                                    : "",
                                                                                  hsn: ele.hasOwnProperty(
                                                                                    "hsn"
                                                                                  )
                                                                                    ? ele.hsn
                                                                                    : "",
                                                                                  currency_id:
                                                                                    currencies_option[0]
                                                                                      .id,
                                                                                };
                                                                                if (
                                                                                  is_found_new_index_check !=
                                                                                  -1
                                                                                ) {
                                                                                  pass_value.diasble_gst =
                                                                                    false;
                                                                                } else {
                                                                                  pass_value.diasble_gst =
                                                                                    ele.hasOwnProperty(
                                                                                      "gst"
                                                                                    );
                                                                                }
                                                                              }
                                                                              console.log(
                                                                                "pass_value :",
                                                                                pass_value
                                                                              );
                                                                              SetNewSideDrawerData(
                                                                                pass_value
                                                                              );
                                                                            }}
                                                                          />
                                                                        ) : null
                                                                      ) : null}
                                                                    </td>
                                                                  )
                                                                )
                                                                : null
                                                              : null}
                                                          </>
                                                        )
                                                      )}
                                                    </tr>
                                                  )
                                                )
                                              ) : (
                                                <tr>
                                                  {country_index == 0 &&
                                                    state_index == 0 &&
                                                    type_index == 0 ? (
                                                    <td
                                                      rowSpan={ele.rowspan}
                                                      className=" bg-fff td-prod"
                                                    >
                                                      <div className="p-2">
                                                        {ele.name}
                                                      </div>
                                                    </td>
                                                  ) : null}
                                                  {/* country name */}
                                                  {state_index == 0 &&
                                                    type_index == 0 ? (
                                                    <td
                                                      className=""
                                                      rowSpan={country.rowspan}
                                                    >
                                                      {country.name}
                                                    </td>
                                                  ) : null}
                                                  {/* state name */}
                                                  {type_index == 0 ? (
                                                    <td
                                                      className=""
                                                      rowSpan={state.rowspan}
                                                    >
                                                      {state.name}
                                                    </td>
                                                  ) : null}
                                                </tr>
                                              )
                                          )
                                        ) : (
                                          <tr>
                                            {country_index == 0 &&
                                              state_index == 0 ? (
                                              <td
                                                rowSpan={ele.rowspan}
                                                className=" bg-fff"
                                              >
                                                <div className="p-2">
                                                  {ele.name}
                                                </div>
                                              </td>
                                            ) : null}
                                            {/* country name */}
                                            {state_index == 0 ? (
                                              <td
                                                className=""
                                                rowSpan={country.rowspan}
                                              >
                                                {country.name}
                                              </td>
                                            ) : null}
                                          </tr>
                                        )
                                    )
                                  ) : (
                                    <tr>
                                      {country_index == 0 ? (
                                        <>
                                          <td rowSpan={ele.rowspan}>{ele.name}</td>
                                          <td rowSpan={country.rowspan}>
                                            {country.name}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td rowSpan={country.rowspan}>
                                            {country.name}
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  )
                              )
                            ) : (
                              <tr>
                                <td rowSpan={ele.rowspan}>{ele.name}</td>
                              </tr>
                            )
                          ) : null}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            }

            {/* pagination button */}
            <div className="d-flex align-items-center mt-3">
              <div className="d-flex align-items-center">
                {page_no > 1 ? (
                  <button
                    className="me-3 d-flex align-items-center btn btn-text border-prime text-prime ps-1 pe-2 btn-radius btn-sm fw-bold"
                    onClick={() => {
                      console.log("Prev");
                      on_prev();
                    }}
                  >
                 <img
                    src={
                      AppConfig.CDN_Media_url +
                      "3172f69196e958f3bc4edb405f97ce29.svg"
                    }
                    className="w-20px"
                  />
                  <p className="text-prime ps-2">Prev</p>
                  </button>
                ) : null}

                {next_page ? (
                  <button
                    className="d-flex align-items-center btn bg-prime ps-2 pe-1 btn-radius text-white fw-bold btn-sm"
                    onClick={() => {
                      console.log("Next");
                      on_next();
                    }}
                  >
                    <p className="text-prime pe-1">Next</p>
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "ffb1885fc1b6450d7b6c9652189cdcb2.svg"
                    }
                    className="w-20px"
                  />
                  </button>
                ) : null}
              </div>

              {table_data.length != 0 ? (
                <div className="ms-auto">
                  <button
                    className="btn bg-prime px-4 btn-sm btn-radius text-white fw-bold"
                    onClick={() => {
                      save_product_pricing(selected_country, page_no);
                    }}
                  >
                    Save
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {["left", "right", "top", "bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={(event) => {
              SetNewSideDrawerData({});
              slide_drawer(anchor, false, event);
            }}
            onOpen={(event) => {
              slide_drawer(anchor, true, event);
            }}
          >
            {new_side_drawer_data.type == "information" ? (
              <div className="">
                <div className="mt-3 scrollbar-none">
                  <div className="d-flex align-items-center pb-2 ps-3">
                  <h6 className="mb-0">Details</h6>
                  <div
                    className=" cursor px-3 ms-auto"
                    onClick={(event) => {
                      slide_drawer(anchor, false, event);
                    }}
                  >
                     <img
                  src={
                    AppConfig.CDN_Media_url +
                    "5f1c84a5b1601dde2c5c53b5fab5c150.svg"
                  }
                  className="type-img"
                />
                    {/* <Ionicons name="close" size={20} color="black" /> */}
                  </div>
                  </div>
                 
                  <div>
                    {/* price_without_gst */}
                    <div className="m-3">
                      <label>Price Without GST</label>
                      <input
                        className="form-control"
                        type="number"
                        value={
                          new_side_drawer_data.row_data.price.price_without_gst
                        }
                        onChange={(e) => {
                          SetNewSideDrawerData((prevValue: any) => {
                            if (prevValue.have_gst && prevValue.gst_value > 0) {
                              let price_with_gst_value = get_price_with_gst(
                                prevValue.gst_value,
                                e.target.value
                              );
                              // prevValue.row_data.price.price_with_gst =
                              //   price_with_gst_value;
                              if (
                                parseFloat(price_with_gst_value) <=
                                parseFloat(prevValue.mrp_value.mrp)
                              ) {
                                prevValue.row_data.price.price_without_gst =
                                  e.target.value;
                                prevValue.row_data.price.price_with_gst =
                                  price_with_gst_value;
                              } else {
                                toast.error("Price greater than mrp");
                                prevValue.row_data.price.price_with_gst = "";
                              }
                              prevValue.row_data.price.gst =
                                prevValue.gst_value;
                            } else {
                              prevValue.row_data.price.price_without_gst =
                                e.target.value;
                              prevValue.row_data.price.price_with_gst =
                                e.target.value;
                            }
                            return { ...prevValue };
                          });
                        }}
                      />
                    </div>

                    {/* price_with_gst */}
                    <div className="m-3">
                      <label>Price With GST</label>
                      <input
                        className="form-control"
                        type="number"
                        value={
                          new_side_drawer_data.row_data.price.price_with_gst
                        }
                        onChange={(e) => {
                          SetNewSideDrawerData((prevValue: any) => {
                            if (prevValue.have_gst && prevValue.gst_value > 0) {
                              if (
                                parseFloat(e.target.value) <=
                                parseFloat(prevValue.mrp_value.mrp)
                              ) {
                                console.log("e.target.value :", e.target.value);
                                console.log(
                                  "prevValue.mrp_value.mrp :",
                                  prevValue.mrp_value.mrp
                                );
                                prevValue.row_data.price.price_with_gst =
                                  e.target.value;
                                let price_without_gst_value =
                                  get_price_without_gst(
                                    prevValue.gst_value,
                                    e.target.value
                                  );
                                prevValue.row_data.price.price_without_gst =
                                  price_without_gst_value;
                                prevValue.row_data.price.gst =
                                  prevValue.gst_value;
                              } else {
                                toast.error("Price greater than mrp");
                              }
                            } else {
                              prevValue.row_data.price.price_without_gst =
                                e.target.value;
                              prevValue.row_data.price.price_with_gst =
                                e.target.value;
                            }
                            return { ...prevValue };
                          });
                        }}
                      />
                    </div>

                    {/* gst */}
                    <div className="m-3">
                      <label>GST</label>
                      <input
                        className="form-control"
                        type="number"
                        value={new_side_drawer_data.row_data.price.gst}
                        disabled={new_side_drawer_data.diasble_gst}
                        onChange={(e) => {
                          SetNewSideDrawerData((prevValue: any) => {
                            prevValue.row_data.price.gst = e.target.value;
                            if (e.target.value != "" && e.target.value > 0) {
                              if (
                                prevValue.row_data.price.hasOwnProperty(
                                  "price_without_gst"
                                ) &&
                                prevValue.row_data.price.hasOwnProperty(
                                  "price_with_gst"
                                )
                              ) {
                                let price_with_gst_value = get_price_with_gst(
                                  e.target.value,
                                  prevValue.row_data.price.price_without_gst
                                );
                                if (
                                  parseFloat(price_with_gst_value) <=
                                  parseFloat(prevValue.mrp_value.mrp)
                                ) {
                                  prevValue.row_data.price.price_without_gst =
                                    prevValue.row_data.price.price_without_gst;
                                  prevValue.row_data.price.price_with_gst =
                                    price_with_gst_value;
                                } else {
                                  toast.error("Price greater than mrp");
                                  prevValue.row_data.price.price_with_gst = "";
                                }
                              }
                              prevValue.gst_value = e.target.value;
                              prevValue.have_gst = true;
                            }

                            return { ...prevValue };
                          });
                        }}
                      />
                    </div>

                    {/* hsn */}
                    {!new_side_drawer_data.diasble_gst ? (
                      <div className="m-3">
                        <label>HSN</label>
                        <input
                          className="form-control"
                          type="number"
                          value={new_side_drawer_data.row_data.price.hsn}
                          onChange={(e) => {
                            SetNewSideDrawerData((prevValue: any) => {
                              prevValue.row_data.price.hsn = e.target.value;
                              return { ...prevValue };
                            });
                          }}
                        />
                      </div>
                    ) : null}

                    {/* currency */}
                    <div className="m-3">
                      <label>Currency</label>
                      <Select
                        className="Price"
                        options={currencies_option}
                        value={new_side_drawer_data.row_data.selected_currency}
                        onChange={(e) => {
                          console.log("currency :", e);
                          SetNewSideDrawerData((prevValue: any) => {
                            prevValue.row_data.selected_currency = e;
                            prevValue.row_data.price.currency_id = e.id;
                            return { ...prevValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* Save Button */}
                <div className="pt-5 mt-2">
                  <div
                    className="text-center p-3 cursor bottom bg-prime fw-bold text-white"
                    onClick={(event) => {
                      slide_drawer(anchor, false, event);
                      if (new_side_drawer_data.from == "channel") {
                        SetSavePriceChannelData((prevValue: any) => {
                          if (new_side_drawer_data.index != -1) {
                            prevValue[new_side_drawer_data.index] =
                              new_side_drawer_data.row_data;
                          } else {
                            prevValue.push(new_side_drawer_data.row_data);
                          }
                          return [...prevValue];
                        });
                        SetNewSavePriceChannelData((prevValue: any) => {
                          if (new_side_drawer_data.new_index != -1) {
                            prevValue[new_side_drawer_data.new_index] =
                              new_side_drawer_data.row_data;
                          } else {
                            prevValue.push(new_side_drawer_data.row_data);
                          }
                          return [...prevValue];
                        });
                      } else {
                        // outlet
                      }
                      SetNewSideDrawerData({});
                    }}
                  >
                    OK
                  </div>
                </div>
              </div>
            ) : null}

            {new_side_drawer_data.type == "filter" ? (
              <div className="">
                <div className="mb-5 overflowY-500">
                  <FilterComponent />
                </div>
                <div
                  className={width < 768 ? "bottom-fix bg-prime text-white fw-bold w-100 p-3 text-center" : "bottom-fix bg-prime text-white fw-bold w-30 p-3 text-center"}
                  onClick={(event) => {
                    slide_drawer(anchor, false, event);
                  }}
                >
                  Save
                </div>
              </div>
            ) : null}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
