import React, { useState, useEffect, useContext } from "react";

import ModalCreateBrand from "./ModalCreateBrand";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import Header from "../../components/Header/Header";
import ModalCreateOutlet from "./ModalCreateOutlet";
import { Ionicons } from "@expo/vector-icons";
import { Link, useParams, useNavigate, useOutletContext } from "react-router-dom";


import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppConfig } from "../../config";
import Footer from "../../components/Footer/Footer";

const handleDragStart = (e) => e.preventDefault();
const responsive_recent = {
  300: { items: 2 },
  720: { items: 4 },
  912: { items: 6 },
  1024: { items: 8 },
};

const responsive_brand = {
  300: { items: 2 },
  720: { items: 4 },
  912: { items: 6 },
  1024: { items: 8 },
};

const responsive_franchise = {
  300: { items: 2 },
  720: { items: 4 },
  912: { items: 6 },
  1024: { items: 8 },
};

const responsive_outlet = {
  300: { items: 2 },
  720: { items: 4 },
  912: { items: 6 },
  1024: { items: 8 },
};

export default function Home(props: any) {
  const context: any = useContext(DataContext);
  const [create_brand_modal, SetCreateBrandModal] = useState({
    is_open: false,
    data: {},
  });
  const [create_outlet_modal, SetCreateOutletModal] = useState({
    is_open: false,
    data: {},
  });
  const [width, SetWidth] = useState(window.innerWidth);
  const [user_data, SetUserData] = useState({});
  const [recent_data, SetRecentData] = useState([]);
  const [brand_data, SetBrandData] = useState([]);
  const [outlet_data, SetOutletData] = useState([]);
  const [franchise_data, SetFranchiseData] = useState([]);
  const [view_data, SetViewData] = useState({
    data: [],
    type: "",
  });

  const [select_option, SetSelectedOptions] = useState("Recent");
  const [options, SetOptions] = useState([
    { name: "Recent" },
    { name: "Brand" },
    { name: "Franchise" },
    { name: "Outlet" },
  ]);
  const [is_loading, SetIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Home On mount :", props);
    console.log("Home On mount context :", context);
    get_init();
    // get_locales();
  }, []);

  async function get_init() {
    let response: any = await api("/init");
    console.log("app_init :", response);
    if (response.status_code == 200) {
      SetIsLoading(false);
      SetUserData(response.response.user_details);
      localStorage.setItem(
        "user_data",
        JSON.stringify(response.response.user_details)
      );
      if (response.response.accounts.hasOwnProperty("franchise")) {
        SetRecentData(response.response.accounts.franchise);
        let view_value = {
          data: response.response.accounts.franchise,
          type: "franchise",
        };
        SetViewData(view_value);
      }
      if (response.response.accounts.hasOwnProperty("recent")) {
        SetRecentData(response.response.accounts.recent);
        let view_value = {
          data: response.response.accounts.recent,
          type: "recent",
        };
        SetViewData(view_value);
      } else {
        if (response.response.accounts.hasOwnProperty("brands")) {
          let view_value = {
            data: response.response.accounts.brands,
            type: "brands",
          };
          SetViewData(view_value);
        } else {
          let view_value = {
            data: [],
            type: "brands",
          };
          SetViewData(view_value);
        }
      }

      if (response.response.accounts.hasOwnProperty("brands")) {
        SetBrandData(response.response.accounts.brands);
      }
      if (response.response.accounts.hasOwnProperty("outlets")) {
        SetOutletData(response.response.accounts.outlets);
        // if(response.response.accounts.outlets.length>0){
        // navigate("/orders/new");
        // }
        
      }
      if (response.response.accounts.hasOwnProperty("franchise")) {
        SetFranchiseData(response.response.accounts.franchise);
      }

      if (response.response.hasOwnProperty("countries")) {
        context.SetAppData((prevValue: any) => {
          prevValue.country_data = response.response.countries;
          return { ...prevValue };
        });
      }
      if (response.response.hasOwnProperty("application")) {
        context.SetAppData((prevValue: any) => {
          prevValue.application = response.response.application;
          return { ...prevValue };
        });
        // if (
        //   response.response.application.platform === "ios" ||
        //   response.response.application.platform === "android"
        // ) {
          
        // }
        // if (response.response.application.platform === "android") {
        //   res_id = setInterval(function () {
        //     get_hoc();
        //   }, 10000);
        // }
      }
    }
  }

  async function get_locales() {
    let pass_data = {
      get: { lang: context.app_data.selected_language.value },
    };
    let response = await api("/locales", pass_data);
    console.log("locales :", response);

    if (response.response.hasOwnProperty("data")) {
      context.SetAppData((prevValue: any) => {
        prevValue.language_data = response.response.data;
        return { ...prevValue };
      });
    }
  }

  function on_type_change(value: any) {
    let view_value = {
      data: [],
      type: "brands",
    };
    if (value == "recent") {
      view_value.data = recent_data;
      view_value.type = "recent";
    } else if (value == "brands") {
      view_value.data = brand_data;
      view_value.type = "brands";
    } else if (value == "franchise") {
      view_value.data = franchise_data;
      view_value.type = "franchise";
    } else {
      view_value.data = outlet_data;
      view_value.type = "outlets";
    }
    SetViewData(view_value);
  }

  async function view(from: any, data: any, index: any) {
    let set_value: any = {
      brand_id: "",
      outlet_id: "",
      user_data: user_data,
    };

    if (from == "recent") {
      if (data.hasOwnProperty("outlet_id")) {
        console.log("View Outlet");
        set_value.brand_id = data.brand_id;
        set_value.outlet_id = data.outlet_id;
        let view_data_value = {
          name: data.name,
          brand_name: data.brand_name,
          type: "outlet",
          profile: data.profile,
        };
        localStorage.setItem("view_data", JSON.stringify(view_data_value));
      } else {
        console.log("View Brand");
        set_value.brand_id = data.brand_id;
        set_value.outlet_id = null;
        let view_data_value = {
          name: data.name,
          brand_name: "",
          type: "brand",
          profile: data.profile,
        };
        localStorage.setItem("view_data", JSON.stringify(view_data_value));
      }
    } else if (from == "brands") {
      console.log("View Brand");
      set_value.brand_id = data.id;
      set_value.outlet_id = null;
      let view_data_value = {
        name: data.name,
        brand_name: "",
        type: "brand",
        profile: data.profile,
      };
      localStorage.setItem("view_data", JSON.stringify(view_data_value));
    } else if (from == "franchise") {
      console.log("View franchise");
      set_value.brand_id = data.brand_id;
      set_value.outlet_id = data.id;
      let view_data_value = {
        name: data.name,
        brand_name: data.brand_name,
        type: "franchise",
      };
      localStorage.setItem("view_data", JSON.stringify(view_data_value));
    } else if (from == "outlet") {
      console.log("View Outlet");
      set_value.brand_id = data.brand_id;
      set_value.outlet_id = data.id;
      let view_data_value = {
        name: data.name,
        brand_name: data.brand_name,
        type: "outlet",
        profile: data.brand_profile,
      };
      console.log(data.brand_profile);
      localStorage.setItem("view_data", JSON.stringify(view_data_value));
    }

    context.SetAppData((prevValue: any) => {
      prevValue.selected_account_data = data;
      console.log("context prevValue :", prevValue);
      return { ...prevValue };
    });

    let pass_data = {
      get: {
        brand: set_value.brand_id,
        outlet: set_value.outlet_id,
      },
    };
    // context.SetAppData(set_value);

    context.SetAppData((prevValue: any) => {
      prevValue.brand_id = set_value.brand_id;
      prevValue.outlet_id = set_value.outlet_id;
      prevValue.user_data = set_value.user_data;
      return { ...prevValue };
    });

    let response_data = await api("/account/visited", pass_data);
    console.log("api data :", response_data);
  }

  return (
    <div className="">
      {/* <div className="d-sm-block d-md-none">
       <Header />
       </div> */}
      <div className="header-bg"></div>
      <div className="home container sm-p-0">
        <div className="">
          {/* <div className="col-md-3 d-sm-none d-md-block">
            <div className="p-4 sticky-top">
              <img
                src={require("../../assets/img/work6.svg")}
                className="w-50"
              />
            </div>
          </div> */}
          <div className="row fixed-top shadow-bottom align-items-center">
            <div className="sticky-top border-bottom bg-fff ">
              <div className="d-flex align-items-center px-2 px-sm-0 py-3">
                <img
                  src={AppConfig.CDN_Image_url+"2344617f5bc696eacff9e3b5df613b58.png"}
                  className="logo-img"
                />
                <h6 className="mb-0 ps-1  ">SELLERS</h6>
                <Link to={"/orders"} className="ms-auto">
                  <button className="btn btn-white btn-radius text-prime btn-sm fw-bold">
                    {/* <Ionicons name="add-outline" size={14} color="#3f41c3" /> */}
                    Orders
                  </button>
                </Link>
                <Link to={"create_brand"} 
                className="ms-2"
                >
                  <button className="btn btn-white btn-radius text-prime btn-sm fw-bold">
                    {/* <Ionicons name="add-outline" size={14} color="#3f41c3" /> */}
                    Create New
                  </button>
                </Link>
              </div>
              {/* <AliceCarousel
                mouseTracking
                touchMoveDefaultEvents={false}
                // items={productsRestraunt}
                // paddingLeft={50}
                paddingRight={20}
                disableDotsControls
                responsive={responsive}
                disableButtonsControls
                controlsStrategy="alternate"
              >
                {options.map((tab, tabIndex) => (
                  <div
                    className="pt-4"
                    key={tabIndex}
                    onDragStart={handleDragStart}
                  >
                    <div className="cursor text-center">
                      <div
                        className={
                          select_option == tab.name
                            ? "active_head pb-2"
                            : "pb-2"
                        }
                        onClick={() => {
                          SetSelectedOptions(tab.name);
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <p className="text-black fs-7">{tab.name}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </AliceCarousel> */}
            </div>
            {/* <div className="pt-3">
              {select_option == "Recent" ? (
                <div className="row p-2">
                  {recent_data.length > 0 ? (
                    recent_data.map((v_data: any, v_index: any) =>
                      v_data.hasOwnProperty("outlet_id") ? (
                        <div
                          className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3 "
                          onClick={() => {
                            view("recent", v_data, v_index);
                          }}
                          key={v_index}
                        >
                          <Link
                            to={
                              "outlet/" +
                              v_data.brand_id +
                              "/" +
                              v_data.outlet_id
                            }
                            className="home_link"
                          >
                            <div className="card p-2 pt-3">
                              <div className="d-flex align-items-center">
                                <div className="radius px-2 fw-bold bg-light-prime">
                                  <p className="v-small text-prime">Outlet</p>
                                </div>
                                <div className="ms-auto">
                                  <p className="v-small text-black">
                                    #{v_data.outlet_id}
                                  </p>
                                </div>
                              </div>
                              <div className="pt-2">
                                <p className="fs-7 text-black fw-bold">
                                  {v_data.name}
                                </p>
                                <p className="text-black pt-2">
                                  {v_data.brand_name}
                                </p>
                                <div className="d-flex align-items-center pt-2">
                                  <Ionicons
                                    name="ios-location-sharp"
                                    size={14}
                                    color="gray"
                                  />
                                  <p className="oneline small text-gray fw-500">
                                    {v_data.address}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ) : v_data.hasOwnProperty("franchise_id") ? (
                        <div
                          className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3"
                          onClick={() => {
                            view("recent", v_data, v_index);
                          }}
                          key={v_index}
                        >
                          <Link
                            to={"brand/" + v_data.franchise_id}
                            className="home_link"
                          >
                            <div className="card p-2 pt-3">
                              <div className="d-flex align-items-center">
                                <div className="radius px-2 fw-bold bg-light-red">
                                  <p className="v-small text-red">Franchise</p>
                                </div>
                                <div className="ms-auto">
                                  <p className="v-small text-black">
                                    #{v_data.franchise_id}
                                  </p>
                                </div>
                              </div>
                              <div className="pt-2">
                                <p className="fs-7 text-black fw-bold">
                                  {v_data.name}
                                </p>
                                <p className="text-black pt-2">
                                  {v_data.brand_name}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ) : (
                        <div
                          className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3"
                          onClick={() => {
                            view("recent", v_data, v_index);
                          }}
                          key={v_index}
                        >
                          <Link
                            to={"brand/" + v_data.brand_id}
                            className="home_link"
                          >
                            <div className="card p-2 pt-3">
                              <div className="d-flex align-items-center">
                                <div className="radius px-2 fw-bold bg-light-green">
                                  <p className="v-small text-green">Brand</p>
                                </div>
                                <div className="ms-auto">
                                  <p className="v-small text-black">
                                    #{v_data.brand_id}
                                  </p>
                                </div>
                              </div>
                              <div className="pt-2">
                                <p className="fs-7 text-black fw-bold">
                                  {v_data.name}
                                </p>
                                <p className="text-black pt-2">
                                  {v_data.brand_name}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )
                    )
                  ) : (
                    <div>
                      <p className="center">No Recent Data</p>
                    </div>
                  )}
                </div>
              ) : null}

              {select_option == "Brand" ? (
                <div className="row p-2">
                  {brand_data.length > 0 ? (
                    brand_data.map((v_data: any, v_index: any) => (
                      <div
                        className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3"
                        onClick={() => {
                          view("brands", v_data, v_index);
                        }}
                        key={v_index}
                      >
                        <Link to={"brand/" + v_data.id} className="home_link">
                          <div className="card p-2 pt-3">
                            <div className="text-end">
                              <div className="d-flex align-items-center">
                                <div className="radius px-2 fw-bold bg-light-green">
                                  <p className="v-small text-green">Brand</p>
                                </div>
                                <div className="ms-auto">
                                  <p className="v-small text-black">
                                    #{v_data.id}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="pt-2">
                              <p className="fs-7 text-black fw-bold">
                                {v_data.name}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  ) : (
                    <div>
                      <p className="center">No Brands Data</p>
                    </div>
                  )}
                </div>
              ) : null}

              {select_option == "Franchise" ? (
                <div className="row p-2">
                  {franchise_data.length > 0 ? (
                    franchise_data.map((v_data: any, v_index: any) => (
                      <div
                        className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3"
                        onClick={() => {
                          view("franchise", v_data, v_index);
                        }}
                        key={v_index}
                      >
                        <Link
                          to={"franchise/" + v_data.brand_id + "/" + v_data.id}
                          className="home_link"
                        >
                          <div className="card p-2 pt-3">
                            <div className="d-flex align-items-center">
                              <div className="radius px-2 fw-bold bg-light-red">
                                <p className="v-small text-red">Franchise</p>
                              </div>
                              <div className="ms-auto">
                                <p className="v-small text-black">
                                  #{v_data.id}
                                </p>
                              </div>
                            </div>
                            <div className="pt-2">
                              <p className="fs-7 text-black fw-bold">
                                {v_data.name}
                              </p>
                              <p className="text-black pt-2">
                                {v_data.brand_name}
                              </p>
                              <div className="d-flex align-items-center pt-2">
                                <Ionicons
                                  name="ios-location-sharp"
                                  size={14}
                                  color="gray"
                                />
                                <p className="oneline small text-gray fw-500">
                                  {v_data.address}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  ) : (
                    <div>
                      <p className="center">No Franchise Data</p>
                    </div>
                  )}
                </div>
              ) : null}

              {select_option == "Outlet" ? (
                <div className="row p-2">
                  {outlet_data.length > 0 ? (
                    outlet_data.map((v_data: any, v_index: any) => (
                      <div
                        className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3"
                        onClick={() => {
                          view("outlet", v_data, v_index);
                        }}
                        key={v_index}
                      >
                        <Link
                          to={"outlet/" + v_data.brand_id + "/" + v_data.id}
                          className="home_link"
                        >
                          <div className="card p-2 pt-3">
                            <div className="d-flex align-items-center">
                              <div className="radius px-2 fw-bold bg-light-prime">
                                <p className="v-small text-prime">Outlet</p>
                              </div>
                              <div className="ms-auto">
                                <p className="v-small text-black">
                                  #{v_data.id}
                                </p>
                              </div>
                            </div>
                            <div className="pt-2">
                              <p className="fs-7 text-black fw-bold">
                                {v_data.name}
                              </p>
                              <p className="text-black pt-2">
                                {v_data.brand_name}
                              </p>
                              <div className="d-flex align-items-center pt-2">
                                <Ionicons
                                  name="ios-location-sharp"
                                  size={14}
                                  color="gray"
                                />
                                <p className="oneline small text-gray fw-500">
                                  {v_data.address}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  ) : (
                    <div>
                      <p className="center">No Outlets Data</p>
                    </div>
                  )}
                </div>
              ) : null}
            </div> */}
          </div>

          <div className="mt-5 pt-4 mb-5">
            {is_loading ? (
              <div className="row">
                {[1, 2, 3, 4].map((sk_ele: any) => (
                  <div className="col-lg-3 col-md-4 col-6 mb-3 pe-0">
                    {/* <div className="card p-2" id="card-link">
                    <div className="card__header">
                      <div>
                        <img
                          className="card__header header__img skeleton"
                          id="logo-img"
                          alt=""
                        />
                      </div>
                      <h3
                        className="card__header header__title"
                        id="card-title"
                      >
                        <div className="skeleton skeleton-text"></div>
                        <div className="skeleton skeleton-text"></div>
                      </h3>
                    </div>

                    <div className="card__body">
                      <div className="card__body body__text" id="card-details">
                        <div className="skeleton skeleton-text skeleton-text__body"></div>
                      </div>

                      <div className="card__body body__img">
                        <img className="skeleton" alt="" id="cover-img" />
                      </div>
                    </div>

                    <div className="card__footer" id="card-footer">
                      <div className="skeleton skeleton-text skeleton-footer"></div>
                    </div>
                  </div> */}

                    <div className="py-2">
                      <Skeleton height={15} width={50} />
                    </div>
                    <div className="card p-2 pt-3 h-140px">
                      <div className="d-flex align-items-center">
                        <div>
                          <Skeleton height={15} width={50} />
                        </div>
                        <div className="ms-auto">
                          <Skeleton height={10} width={20} />
                        </div>
                      </div>
                      <div className="pt-2">
                        <Skeleton height={10} width={80} />
                        <Skeleton height={10} width={50} />

                        <div className="d-flex align-items-center">
                          <Skeleton height={10} width={10} />
                          <div className="ps-2">
                            <Skeleton height={10} width={60} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="row px-2">
                {/* recent */}
                {/* {select_option == "Recent" ? ( */}
                {/* {recent_data.length > 0 ? (
                  <div className="p-sm-0 p-3 border-bottom mb-3">
                    <h6 className="pb-2">Recent</h6>
                    <div className="row">
                      <AliceCarousel
                        mouseTracking
                        // touchMoveDefaultEvents={false}
                        // items={productsRestraunt}
                        // paddingLeft={50}
                        paddingRight={20}
                        disableDotsControls
                        responsive={responsive_recent}
                        // disableButtonsControls
                        // controlsStrategy="alternate"
                        controlsStrategy="responsive"
                        keyboardNavigation={true}
                        renderPrevButton={() => {
                          return (
                            <div>
                              {width > 548 ? (
                                <div className="center leftabsolute">
                                  <img
                                    className="w-22px"
                                    src={AppConfig.CDN_Media_url+"e78b48d38a0329098b19c0581e760b53.svg"}
                                  />
                                </div>
                              ) : null}
                            </div>
                          );
                        }}
                        renderNextButton={() => {
                          return (
                            <div>
                              {width > 548 ? (
                                <div className="center rightabsolute">
                                  <img src={AppConfig.CDN_Media_url+"c5f4c384e454d91101b93eda429629f0.svg"} className="w-22px" />
                                </div>
                              ) : null}
                            </div>
                          );
                        }}
                        // keyboardNavigation={width > 500 ? true: false}
                      >
                        {recent_data.map((v_data: any, v_index: any) =>
                          v_data.hasOwnProperty("outlet_id") ? (
                            <div
                              className="mb-3 pe-3 "
                              onClick={() => {
                                view("recent", v_data, v_index);
                              }}
                              key={v_index}
                              onDragStart={handleDragStart}
                            >
                              <Link
                                to={
                                  "outlet/" +
                                  v_data.brand_id +
                                  "/" +
                                  v_data.outlet_id + "/dashboard"
                                }
                                className="home_link"
                              >
                                <div className="card p-2 pt-3 h-140px">
                                  <div className="d-flex align-items-center">
                                    <div className="radius px-2 fw-bold bg-light-prime">
                                      <p className="extra-small text-prime">
                                        Outlet
                                      </p>
                                    </div>
                                    <div className="ms-auto">
                                      <p className="v-small text-black">
                                        #{v_data.outlet_id}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="pt-2">
                                    <p className="text-black fw-bold">
                                      {v_data.name}
                                    </p>
                                    <p className="text-black pt-2 extra-small">
                                      {v_data.brand_name}
                                    </p>
                                    <div className="d-flex align-items-center pt-2">
                                      <Ionicons
                                        name="ios-location-sharp"
                                        size={12}
                                        color="#333"
                                      />
                                      <p className="oneline extra-small text-gray fw-500">
                                        {v_data.address}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ) : v_data.hasOwnProperty("franchise_id") ? (
                            <div
                              className="pe-3 mb-3"
                              onClick={() => {
                                view("recent", v_data, v_index);
                              }}
                              key={v_index}
                              onDragStart={handleDragStart}
                            >
                              <Link
                                to={"brand/" + v_data.franchise_id + "/dashboard"}
                                className="home_link"
                              >
                                <div className="card p-2 pt-3 h-140px">
                                  <div className="d-flex align-items-center">
                                    <div className="radius px-2 fw-bold bg-light-red">
                                      <p className="extra-small text-red">
                                        Franchise
                                      </p>
                                    </div>
                                    <div className="ms-auto">
                                      <p className="v-small text-black">
                                        #{v_data.franchise_id}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="pt-2">
                                    <p className="text-black fw-bold">
                                      {v_data.name}
                                    </p>
                                    <p className="text-black pt-2 extra-small">
                                      {v_data.brand_name}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ) : (
                            <div
                              className="pe-3 mb-3"
                              onClick={() => {
                                view("recent", v_data, v_index);
                              }}
                              key={v_index}
                              onDragStart={handleDragStart}
                            >
                              <Link
                                to={"brand/" + v_data.brand_id + "/dashboard"}
                                className="home_link"
                              >
                                <div className="card p-2 pt-3 h-140px">
                                  <div className="d-flex align-items-center">
                                    <div className="radius px-2 fw-bold bg-light-green">
                                      <p className="extra-small text-green">
                                        Brand
                                      </p>
                                    </div>
                                    <div className="ms-auto">
                                      <p className="v-small text-black">
                                        #{v_data.brand_id}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="pt-2">
                                    <p className="text-black fw-bold">
                                      {v_data.name}
                                    </p>
                                    <p className="text-black pt-2 extra-small">
                                      {v_data.brand_name}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          )
                        )}
                      </AliceCarousel>
                    </div>
                  </div>
                ) : null} */}
                {/* ) : null} */}

                {/* brand */}
                {brand_data.length > 0 ? (
                  <div className="p-sm-0 p-3 pt-0 border-bottom mb-3">
                    <h6 className="pb-2">Brand</h6>
                    <div className="row">
                      <AliceCarousel
                        mouseTracking
                        // touchMoveDefaultEvents={false}
                        // items={productsRestraunt}
                        // paddingLeft={50}
                        paddingRight={20}
                        disableDotsControls
                        responsive={responsive_brand}
                        // disableButtonsControls
                        controlsStrategy="responsive"
                        keyboardNavigation={true}
                        renderPrevButton={() => {
                          return (
                            <div>
                              {width > 548 ? (
                                <div className="center leftabsolute">
                                  <img
                                    className="w-22px"
                                    src={AppConfig.CDN_Media_url+"e78b48d38a0329098b19c0581e760b53.svg"}
                                  />
                                </div>
                              ) : null}
                            </div>
                          );
                        }}
                        renderNextButton={() => {
                          return (
                            <div>
                              {width > 548 ? (
                                <div className="center rightabsolute">
                                  <img src={AppConfig.CDN_Media_url+"c5f4c384e454d91101b93eda429629f0.svg"} className="w-22px" />
                                </div>
                              ) : null}
                            </div>
                          );
                        }}
                      >
                        {brand_data.map((v_data: any, v_index: any) => (
                          <div
                            className="pe-3 mb-3"
                            onClick={() => {
                              view("brands", v_data, v_index);
                            }}
                            key={v_index}
                            onDragStart={handleDragStart}
                          >
                            <Link
                              to={"brand/" + v_data.id + "/dashboard"}
                              className="home_link"
                            >
                              <div className="card p-2 pt-3 h-140px">
                                <div className="text-end">
                                  <div className="d-flex align-items-center">
                                    <div className="radius px-2 fw-bold bg-light-green">
                                      <p className="v-small text-green">
                                        Brand
                                      </p>
                                    </div>
                                    <div className="ms-auto">
                                      <p className="v-small text-black">
                                        #{v_data.id}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="pt-2">
                                  <p className=" text-black fw-bold">
                                    {v_data.name}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </AliceCarousel>
                    </div>
                  </div>
                ) : // <div className="p-3">
                //   <div className="card p-3">
                //     <p className="center">No Brands Data!</p>
                //   </div>
                // </div>
                null}

                {/* franchise */}
                {franchise_data.length > 0 ? (
                  <div className="p-sm-0 p-3 pt-0 border-bottom mb-3">
                    <h6 className="pb-2">Franchise</h6>
                    <div className="row">
                      <AliceCarousel
                        mouseTracking
                        // touchMoveDefaultEvents={false}
                        // items={productsRestraunt}
                        // paddingLeft={50}
                        paddingRight={20}
                        disableDotsControls
                        responsive={responsive_franchise}
                        // disableButtonsControls
                        controlsStrategy="responsive"
                        keyboardNavigation={true}
                        renderPrevButton={() => {
                          return (
                            <div>
                              {width > 548 ? (
                                <div className="center leftabsolute">
                                  <img
                                    className="w-22px"
                                    src={AppConfig.CDN_Media_url+"e78b48d38a0329098b19c0581e760b53.svg"}
                                  />
                                </div>
                              ) : null}
                            </div>
                          );
                        }}
                        renderNextButton={() => {
                          return (
                            <div>
                              {width > 548 ? (
                                <div className="center rightabsolute">
                                  <img src={AppConfig.CDN_Media_url+"c5f4c384e454d91101b93eda429629f0.svg"} className="w-22px" />
                                </div>
                              ) : null}
                            </div>
                          );
                        }}
                      >
                        {franchise_data.map((v_data: any, v_index: any) => (
                          <div
                            className="pe-3 mb-3"
                            onClick={() => {
                              view("franchise", v_data, v_index);
                            }}
                            onDragStart={handleDragStart}
                            key={v_index}
                          >
                            <Link
                              to={
                                "franchise/" + v_data.brand_id + "/" + v_data.id + "/dashboard"
                              }
                              className="home_link"
                            >
                              <div className="card p-2 pt-3 h-140px">
                                <div className="d-flex align-items-center">
                                  <div className="radius px-2 fw-bold bg-light-red">
                                    <p className="v-small text-red">
                                      Franchise
                                    </p>
                                  </div>
                                  <div className="ms-auto">
                                    <p className="v-small text-black">
                                      #{v_data.id}
                                    </p>
                                  </div>
                                </div>
                                <div className="pt-2">
                                  <p className="text-black fw-bold">
                                    {v_data.name}
                                  </p>
                                  <p className="text-black pt-2 extrasmall">
                                    {v_data.brand_name}
                                  </p>
                                  <div className="d-flex align-items-center pt-2">
                                    <Ionicons
                                      name="ios-location-sharp"
                                      size={12}
                                      color="#333"
                                    />
                                    <p className="oneline extra-small text-gray fw-500">
                                      {v_data.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </AliceCarousel>
                    </div>
                  </div>
                ) : // <div className="p-3">
                //   <div className="card p-3">
                //     <p className="center">No Franchise Data!</p>
                //   </div>
                // </div>
                null}

                {/* outlet */}
                {outlet_data.length > 0 ? (
                  <div className="p-sm-0 p-3 pt-0 border-bottom mb-3">
                    <h6 className="pb-2">Outlet</h6>
                    <div className="row">
                      <AliceCarousel
                        mouseTracking
                        // touchMoveDefaultEvents={false}
                        // items={productsRestraunt}
                        // paddingLeft={50}
                        paddingRight={20}
                        disableDotsControls
                        responsive={responsive_outlet}
                        // disableButtonsControls
                        controlsStrategy="responsive"
                        keyboardNavigation={true}
                        renderPrevButton={() => {
                          return (
                            <div>
                              {width > 548 ? (
                                <div className="center leftabsolute">
                                  <img
                                    className="w-22px"
                                    src={AppConfig.CDN_Media_url+"e78b48d38a0329098b19c0581e760b53.svg"}
                                  />
                                </div>
                              ) : null}
                            </div>
                          );
                        }}
                        renderNextButton={() => {
                          return (
                            <div>
                              {width > 548 ? (
                                <div className="center rightabsolute">
                                  <img src={AppConfig.CDN_Media_url+"c5f4c384e454d91101b93eda429629f0.svg"} className="w-22px" />
                                </div>
                              ) : null}
                            </div>
                          );
                        }}
                      >
                        {outlet_data.map((v_data: any, v_index: any) => (
                          <div
                            className="pe-3 mb-3"
                            onClick={() => {
                              view("outlet", v_data, v_index);
                            }}
                            key={v_index}
                            onDragStart={handleDragStart}
                          >
                            <Link
                              to={"outlet/" + v_data.brand_id + "/" + v_data.id + "/dashboard"}
                              className="home_link"
                            >
                              <div className="card p-2 pt-3 h-140px">
                                <div className="d-flex align-items-center">
                                  <div className="radius px-2 fw-bold bg-light-prime">
                                    <p className="v-small text-prime">Outlet</p>
                                  </div>
                                  <div className="ms-auto">
                                    <p className="v-small text-black">
                                      #{v_data.id}
                                    </p>
                                  </div>
                                </div>
                                <div className="pt-2">
                                  <p className="text-black fw-bold">
                                    {v_data.name}
                                  </p>
                                  <p className="text-black pt-2 extra-small">
                                    {v_data.brand_name}
                                  </p>
                                  <div className="d-flex align-items-center pt-2">
                                    <Ionicons
                                      name="ios-location-sharp"
                                      size={12}
                                      color="#333"
                                    />
                                    <p className="oneline extra-small text-gray fw-500">
                                      {v_data.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </AliceCarousel>
                    </div>
                  </div>
                ) : // <div className="p-3">
                //   <div className="card p-3">
                //     <p className="center">No Outlets Data!</p>
                //   </div>
                // </div>
                null}
              </div>
            )}
          </div>

          {/* <div className="col-md-3 d-sm-none d-md-block">
            <div className="p-3 bottom-fix">
              <img
                src={require("../../assets/img/delivery.svg")}
                className="w-100"
              />
            </div>
          </div> */}
        </div>
      </div>
      <Footer
        menu_click={(data: any) => {
          // on_menu_click(data);
        }}
        from="main"
      />
      {/* <ModalCreateOutlet
        open={create_outlet_modal.is_open}
        close={(data) => {
          console.log("Create Outlet Modal On Close :", data);
          SetCreateOutletModal((oldValues) => ({
            ...oldValues,
            is_open: false,
          }));
        }}
      /> */}

      <ModalCreateBrand
        open={create_brand_modal.is_open}
        close={(data: any) => {
          console.log("Create Brand Modal On Close :", data);
          SetCreateBrandModal((oldValues) => ({
            ...oldValues,
            is_open: false,
          }));
        }}
      />
    </div>
  );
}
