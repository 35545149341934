import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap"


let myModal : any
export default function DeleteAlert(props : any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });

  useEffect(() => {
    console.log("DeleteAlert On mount :", props);
    let myModal1 = document.getElementById("DeleteAlertModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      //   SetCloseData({ action: "close" });
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("DeleteAlert On props.open :", props);
      //   SetCloseData({ action: "close" });
      myModal = new bootstrap.Modal(
        document.getElementById("DeleteAlertModal"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);

  return (
    <div
      className="modal fade"
      id="DeleteAlertModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Delete {props.title}
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
            Are you sure want to delete {props.title.toLowerCase()} ?
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                let item = close_data;
                item.action = "delete";
                item.value = props.record;
                item.index = props.index;
                SetCloseData(item);
                myModal.hide();
              }}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
