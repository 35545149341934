import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// import bootstrap from "bootstrap/dist/js/bootstrap";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import { omit } from "lodash";
import { useWizard, Wizard } from "react-use-wizard";
const animatedComponents = makeAnimated();

const outlet_initial_value = {
  id: "",
  name: "",
  address: "",
  mobile: "",
  whatsapp: "",
  email: "",
  website: "",
  country_name: "",
  country: "",

  state: "",
  state_name: "",

  door_no: "",
  city: "",
  pincode : "",
};

let myModal : any = {};


export default function ModalEditOutlet(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [outlet_data, SetOutletData] = useState(outlet_initial_value);
  const [errors, setErrors] : any = useState({});
  const [countries_options, SetCountriesOptions] = useState([]);
  const [states,SetStates] = useState([]);


  useEffect(() => {
    console.log("ModalEditOutlet On mount :", props);
    let myModal1 : any = document.getElementById("EditOutletModal");
    myModal1.addEventListener("hidden.bs.modal", function (event : any) {
      console.log("event :", event);
      SetOutletData(outlet_initial_value);
      props.close(close_data);
    });
  }, []);

 
  useEffect(() => {
    if (props.open) {
      console.log("ModalEditOutlet On mount :", props);
     
      myModal = new bootstrap.Modal(
        document.getElementById("EditOutletModal"),
        {}
      );

  
      let set_value = {
        id: props.data.id,
        name: props.data.name,
        address: props.data.address,
        mobile: props.data.mobile,
        whatsapp: props.data.whatsapp,
        email: props.data.email,
        website: props.data.website,
        country_name: props.data.country_name,
        country: props.data.country_id,

        state: props.data.state_id,
        state_name: props.data.state_name,

        door_no: props.data.door_no,
        city: props.data.city,
        pincode : props.data.pincode,
      };
      SetOutletData(set_value);
      
      myModal.show();

      get_init();
      get_states();

      // get_states();
    }
  }, [props.open]);
{console.log(myModal)}
  function outlet_value_on_change(name: any, value: any) {
    validate(name, value);
    SetOutletData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  async function edit_outlet() {
    console.log("edit_outlet data :", outlet_data);
    if (outlet_data.name !== "" ) {
    let post_value = {
      name: outlet_data.name,
      address: outlet_data.address,
      mobile: outlet_data.mobile,
      whatsapp: outlet_data.whatsapp,
      email: outlet_data.email,
      website: outlet_data.website,

     
      country: outlet_data.country,
    
      state: outlet_data.state,
      door_no: outlet_data.door_no,
      city: outlet_data.city,
      pincode : outlet_data.pincode,
    };
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: outlet_data.id,
      },
      post: post_value,
    };
    console.log("pass_data :", pass_data);

    let data_res = await api("/brand/edit_outlet", pass_data);
    
    console.log("edit_outlet response :", data_res.response);
    console.log("edit_outlet response :", data_res.status_code);
    if (data_res.status_code == 200) {
      console.log("/outlet/edit_service status_code 200");
      let item : any = close_data;
      item.action = "edit";
      item.value = data_res.response.outlets[0];
      item.index = props.index;
      SetCloseData(item);
      myModal.hide();
    }
  }
    // if (data_res.status_code == 201) {
    //   let item = close_data;
    //   item.action = "save";
    //   let outlet_value = {
    //     id: data_res.response.id,
    //     name: outlet_data.name,
    //     address: outlet_data.address,
    //     mobile: outlet_data.mobile,
    //   };
    //   item.value = outlet_value;
    //   console.log("Close data : ", item);
    //   SetCloseData(item);
    //   SetOutletData(outlet_initial_value);
    //   myModal.hide();
    // }
  }

  async function get_init() {
    let pass_data : any = {
      get: {
        brand: id_value.brand_id,
      },
    };

    if (props.franchise_id != null) {
      pass_data.get.franchise = props.franchise_id;
    }

    let data_res = await api("/brand/create_outlet_init", pass_data);
    console.log("get_outlet_init response :", data_res.response);
  

    if (data_res.response.hasOwnProperty("countries")) {
      SetCountriesOptions(data_res.response.countries);
    }
  }

  function validate(name: any, value: any) {
    //A function to validate each input values
    console.log("name :", name);
    console.log("value  :", value);
    switch (name) {
      case "name":
        if (value.length <= 2) {
          // we will set the error state

          setErrors({
            ...errors,
            name: "Outlet name atleast have 3 letters",
          });
        } else {
          let newObj = omit(errors, "name");
          setErrors(newObj);
        }
        break;

      case "address":
        if (value.length <= 0) {
          setErrors({
            ...errors,
            address: "Enter valid address",
          });
        } else {
          let newObj = omit(errors, "address");
          setErrors(newObj);
        }
        break;

      case "mobile":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            mobile: "Enter valid mobile number",
          });
        } else {
          let newObj = omit(errors, "mobile");
          setErrors(newObj);
        }
        break;

      case "whatsapp":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            whatsapp: "Enter valid whatsapp number",
          });
        } else {
          let newObj = omit(errors, "whatsapp");
          setErrors(newObj);
        }
        break;

      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "Enter email not vaild",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      case "website":

        if (!new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/).test(value)) {
          setErrors({
            ...errors,
            website: "Enter website not vaild",
          });
        } else {
          let newObj = omit(errors, "website");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  
  async function get_states() {
    let pass_data = {
      get: {
        country_id: 99,
      },
    };
    let response = await api("/init/get_states", pass_data);
    console.log("/brand/settings response :", response);
    if (response.status_code == 200) {
      SetStates(response.response.date);

      }
    
  }




  return (
    <div
      className="modal fade"
      id="EditOutletModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-fullscreen-sm-down">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Edit Outlet
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                console.log(close_data);

                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12 col-md-6 mt-2">
                <label className="form-label">Outlet Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={outlet_data.name}
                  onChange={(e) => {
                    outlet_value_on_change("name", e.target.value);
                  }}
                />
                {errors.name && (
                  <small className="text-red">
                    {errors.name}
                  </small>
                )}
              </div>

                             {/* Door No */}
                             <div className="col-12 col-md-6 mt-2">
                    <label className="form-label" htmlFor="name">
                    Door No
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={outlet_data.door_no}
                      onChange={(e) => {
                        outlet_value_on_change("door_no", e.target.value);
                        // SetSaveError(false);
                      }}
                    />
                    {/* {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null} */}
                  </div>
                      {/* City*/}
                      <div className="col-12 col-md-6 mt-2">
                    <label className="form-label" htmlFor="name">
                    city
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={outlet_data.city}

                      onChange={(e) => {
                        outlet_value_on_change("city", e.target.value);
                        // SetSaveError(false);
                      }}
                    />
                    {/* {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null} */}
                  </div>

                      {/* pincode */}
                      <div className="col-12 col-md-6 mt-2">
                    <label className="form-label" htmlFor="name">
                    Pincode
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={outlet_data.pincode}

                      onChange={(e) => {
                        outlet_value_on_change("pincode", e.target.value);
                        // SetSaveError(false);
                      }}
                    />
                    {/* {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null} */}
                  </div>

                     {/* Countries */}
                     <div className="col-12 col-md-6 mt-2">
                    <label className="form-label" htmlFor="selected_roles">
                      Select Countries
                    </label>
                    <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"   onChange={(e) => {
                          
                            outlet_value_on_change("country", e.target.value);
                          }}>

                             <option selected>{outlet_data.country_name}</option>
 
{countries_options.map((ele: any) => (

 
  <option value={ele.id}>{ele.nicename}</option>

  ))}
</select>
                  </div>
          {/* States */}

                  {outlet_data.country=="99" &&

<div className="col-md-6 md-ps-0 sm-p-0">

<label className="form-label" htmlFor="selected_roles">
                      States
                    </label>

<select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"  onChange={(e) => {
                           outlet_value_on_change("state", e.target.value);
                          }}>

<option selected>{outlet_data.state_name}</option>

{states.map((ele: any) => (


<option value={ele.id} >{ele.nicename}</option>

))}
</select>

</div>
}
              <div className="col-12 col-md-6 mt-2">
                <label className="form-label">Address</label>
                <textarea
                  rows={3}
                  className="form-control"
                  value={outlet_data.address}
                  onChange={(e) => {
                    outlet_value_on_change("address", e.target.value);
                  }}
                ></textarea>
                {errors.address && (
                  <small className="text-red">
                    {errors.address}
                  </small>
                )}
              </div>
              <div className="col-12 col-md-6 mt-2">
                <label className="form-label">Mobile Number</label>
                {console.log(outlet_data)}
                <input
                  type="text"
                  className="form-control"
                  value={outlet_data.mobile}
            
                  onChange={(e) => {
                    if (e.target.value.length == 11) return false;
                    let val = e.target.value.slice(0, 10);
                    outlet_value_on_change("mobile", val);
                  }}
                />
                {errors.mobile && (
                  <small className="text-red">
                    {errors.mobile}
                  </small>
                )}
              </div>
              <div className="col-12 col-md-6 mt-2">
                <label className="form-label">Whatsapp Number</label>
                <input
                  type="text"
                  className="form-control"
                  value={outlet_data.whatsapp}
                  onChange={(e) => {
                    if (e.target.value.length == 11) return false;
                    let val = e.target.value.slice(0, 10);
                    outlet_value_on_change("whatsapp", val);
                  }}
                />
                {errors.whatsapp && (
                  <small className="text-red">
                    {errors.whatsapp}
                  </small>
                )}
              </div>
              <div className="col-12 col-md-6 mt-2">
                <label className="form-label">Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={outlet_data.email}
                  onChange={(e) => {
                    outlet_value_on_change("email", e.target.value);
                  }}
                />
                {errors.email && (
                  <small className="text-red">
                    {errors.email}
                  </small>
                )}
              </div>
              <div className="col-12 col-md-6 mt-2">
                <label className="form-label">Website</label>
                <input
                  type="text"
                  className="form-control"
                  value={outlet_data.website}
                  onChange={(e) => {
                    outlet_value_on_change("website", e.target.value);
                  }}
                />
                {errors.website && (
                  <small className="text-red">
                    {errors.website}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <button
                type="button"
                className="btn bg-prime text-white btn-radius btn-sm px-3"
                onClick={() => {
                  edit_outlet();
                }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
