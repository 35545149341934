import React, { useState, useEffect } from "react";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import { useParams } from "react-router-dom";
import { api } from "../../../utils/Api";


const outlet_initial_value = {

    channel_id: "",
  };
  

export default function ModalDeleteCollections(props : any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  let myModal : any;

  console.log(props.data.id)
  const [id_value, SerIdValue] = useState(useParams());
  const [outlet_data, SetOutletData] = useState(outlet_initial_value);

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("AddServiceModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelDeleteUserRoles On props.open :", props);


      myModal = new bootstrap.Modal(
        document.getElementById("AddServiceModal"),
        {}
      );
      myModal.show();
    }
  }, [props.open]);

 async  function deleteUserRole() {
 
    let pass_data = {
        get: {
          brand: id_value.brand_id,
        },
        post: {
            collections: [props.data.id],
      
        }
      };
      console.log(pass_data)
      let data_res = await api("/brand/delete_collection", pass_data);
      console.log("add_brand_user response :", data_res.response);
      if (data_res.status_code == 200) {
        let item = close_data;
        item.action = "save";
        item.value = data_res.response.data;
        console.log("Close data : ", item);
        SetCloseData(item);
        // SetOutletData(outlet_initial_value);
        myModal.hide();
      }


  
}





  return (
    <div
      className="modal fade"
      id="AddServiceModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Delete Collection
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            Are you sure want to delete Collection?
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              name="channel_id"
              value={props.id}
      
              onClick={(e) => {
                               deleteUserRole(e.target.name,e.target.value)
                              
                              }}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}


