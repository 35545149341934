import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import { omit } from "lodash";

const animatedComponents = makeAnimated();

const outlet_initial_value = {
  name: "",
  mobile: "",
};

let myModal = {};

export default function RequestActivation(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());

  const [outlet_data, SetOutletData]:any = useState(outlet_initial_value);
  const [userOpen, setUserOpen] = useState(false);
  const [modal_type, SetModalType] = useState("");
  //OutletData End

  useEffect(() => {
    if(props.userdetails){
      let userdetails = {
        name: props.userdetails.name,
        mobile: props.userdetails.mobile
      }
      SetOutletData(userdetails);
    }
  }, [props]);

  useEffect(() => {
    let myModal1: any = document.getElementById("ModalRequestActivation");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      SetOutletData(outlet_initial_value);
      props.close(close_data);
    });
  }, []);
  
  useEffect(() => {
    if (props.open) {

      myModal = new bootstrap.Modal(document.getElementById("ModalRequestActivation"), {});
      myModal.show();
      SetModalType(props.type);

      //   get_users(page_no);
    }
  }, [props.open]);

  function outlet_value_on_change(name: any, value: any) {
    SetOutletData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  return (
    <div
      className="modal fade"
      id="ModalRequestActivation"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      // data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Request Activation
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
            <h6 className="px-3">Contact Person</h6>
          <div className="px-3 mb-3">
            <label className="form-label">Name</label>
            <input
              type="text"
              value={outlet_data.name}
              className="form-control"
              onChange={(e) => {
                outlet_value_on_change("name", e.target.value);
              }}
            />
          </div>
          <div className="px-3">
            <label className="form-label">Mobile</label>
            <input
              type="number"
              value={outlet_data.mobile}
              className="form-control"
              onChange={(e) => {
                outlet_value_on_change("mobile", e.target.value);
              }}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-radius px-3 btn-sm"
              onClick={(event) => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary btn-radius px-3 btn-sm"
              onClick={(event) => {
                if(outlet_data.name && outlet_data.mobile){
                    let item:any = close_data;
                    item.action = "save";
                    item.name = outlet_data.name;
                    item.mobile = outlet_data.mobile;
                    SetCloseData(item);
                    myModal.hide();
                }
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
