import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import {
  Entypo,
  Feather,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ModalVariants from "./ModalVariants";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import toast from "react-hot-toast";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";


const handleDragStart = (e) => e.preventDefault();
import { AppHelper } from "../../../utils/Helper";
import { AppConfig } from "../../../config";

const responsive = {
  0: { items: 1 },
  200: { items: 2 },
  300: { items: 2 },
  350: { items: 2 },
  548: { items: 3 },
  720: { items: 5 },
};

function valuetext(value) {
  return `${value}°C`;
}

export default function ProductGroup(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    product_name: "",
    product_id: "",
    product_checked: "",
    unavailable: false,
    data: {},
    type: "",
  });
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [visible_content, SetVisibleContent] = useState(false);

  const [text, setText] = useState("");
  const [filter_open, SetFilterOpen] = useState(false);
  const [id_value, SerIdValue] = useState(useParams());
  const [type, setType] = useState("all");
  const [add_product, SetAddProduct] = useState(true);
  const [filter, SetFilter] = useState([]);
  const [width, SetWidth] = useState(innerWidth);
  const [product_data, SetProductData] = useState([]);
  const [data, SetData] = useState([]);
  const [brand, SetBrand] = useState([]);
  const [category, SetCategory] = useState([]);
  const [sort, SetSort] = useState("");
  const [f_products, SetFproducts] = useState({
    own_products: false,
    others_products: false,
  });
  const [next_page, SetNextPage] = useState(false);
  const [min_max_value, SetMinMaxValue] = React.useState(["", ""]);
  const [count, SetCount] = useState(10);
  const [page_no, SetPageNo] = useState(1);
  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const PRICE_WAIT_INTERVAL = 2000;
  const [view_option, SetViewOption] = useState([
    { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Unavailable", value: "unavailable" },
    { label: "Paused", value: "paused" },
  ]);

  async function get_products(
    page_value: any,
    count_value: any,
    f_min_max_value: any,
    type_a: any,
    search_value: any,
    sort_value: any
  ) {
    let pass_data = {};
    let brand_string = encodeURIComponent(JSON.stringify(brand));
    console.log(brand_string);
    SetPageNo(page_value);

    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value.page_no,
          search: search_value,
          filter_brand: brand_string,
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: f_min_max_value[0],
          filter_max_mrp: f_min_max_value[1],
          sort: sort_value,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          // filter_count: count_value,
          filter_count: count_value,

          view: type_a,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value.page_no,
          filter_brand: brand_string,
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: f_min_max_value[0],
          filter_max_mrp: f_min_max_value[1],
          sort: sort_value,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          // filter_count: count_value,
          view: type_a,
          filter_count: count_value,
        },
      };
    }
    let response: any = await api("/brand/brand_products_v2", pass_data);
    console.log("/brand/brand_products :", response);
    if (response.status_code == 200) {
      SetData(response.response);
      SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
        if (page_value.load_more) {
          SetProductData((prevValue: any) => {
            response?.response?.data?.map((ele) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          SetProductData(response.response.data);

          SetFilter(response.response.filter);
        }
      } else {
        SetProductData([]);
      }

      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
    }
  }

  useEffect(() => {
    console.log("Franchise On mount :", props);
    console.log("Franchise context :", context);
    let pass_data = {
      // load_more: true,

      page_no: page_no,
    };
    get_products(pass_data, count, min_max_value, type, text, sort);
  }, []);

  function sample_fun(anchor, open, event) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  }

  async function input_on_change(event) {
    console.log("input_on_change value", event.target.name);
    console.log("ProductAssign OutletContext :", OutletContext);

    console.log("input_on_change value", event.target.checked);
    console.log("input_on_change value", f_products.others_products);

    let arr = [];

    if (event.target.checked == true) {
      if (event.target.name == "own products") {
        f_products.own_products = true;
      }
    } else {
      if (event.target.name == "own products") {
        f_products.own_products = false;
      }
    }

    if (event.target.checked == true) {
      if (event.target.name == "others products") {
        f_products.others_products = true;
      }
    } else {
      if (event.target.name == "others products") {
        f_products.others_products = false;
      }
    }

    if (event.target.checked == true) {
      if (event.target.name == "filter_brand") {
        brand.push(event.target.value);
      }
    } else {
      if (event.target.name == "filter_brand") {
        brand.splice(brand.indexOf(event.target.value, 1));
      }
    }

    console.log(brand);
    SetBrand(brand);

    if (event.target.checked == true) {
      if (event.target.name == "filter_category") {
        category.push(event.target.value);
      }
    } else {
      if (event.target.name == "filter_category") {
        category.splice(brand.indexOf(event.target.value, 1));
      }
    }

    SetPageNo(1);
    get_products(
      {
        page_no: 1,
      },
      count,
      min_max_value,
      type,
      text,
      sort
    );
  }

  function dataSort() {
    if (sort == "asc") {
      SetSort("desc");
      SetPageNo(1);
      get_products(
        {
          page_no: 1,
        },
        count,
        min_max_value,
        type,
        text,
        "desc"
      );
    } else {
      SetSort("asc");

      SetPageNo(1);
      get_products(
        {
          page_no: 1,
        },
        count,
        min_max_value,
        type,
        text,
        "asc"
      );
    }
  }

  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          search: text,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: min_max_value[0],
          filter_max_mrp: min_max_value[1],
          filter_count: count,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          view: type,
          sort: sort,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: min_max_value[0],
          filter_max_mrp: min_max_value[1],
          filter_count: count,

          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          view: type,
          sort: sort,

          // view: view
        },
      };
    }
    let response: any = await api("/brand/brand_products_v2", pass_data);
    console.log("/brand/add_brand_product_list :", response);
    SetProductData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const search_text = e.target.value;
    setText(search_text);
    console.log("Text onchange: ", search_text);
    if (search_text == "") {
      let page_no_value = 1;
      SetPageNo(1);
      get_products(
        {
          page_no: 1,
        },
        count,
        min_max_value,
        type,
        search_text,
        sort
      );
    } else {
      SetTimer(
        setTimeout(() => {
          SetPageNo(1);
          get_products(
            {
              page_no: 1,
            },
            count,
            min_max_value,
            type,
            search_text,
            sort
          );
        }, WAIT_INTERVAL)
      );
    }
  }

  function countChange(e: any) {
    console.log("countChange:", e.target.value);
    SetCount(e.target.value);
    console.log(count);
    SetPageNo(1);
    get_products(
      {
        page_no: 1,
      },
      e.target.value,
      min_max_value,
      type,
      text,
      sort
    );
  }

  const handleChange = (event: any, newValue: any) => {
    clearTimeout(timer);
    SetMinMaxValue(newValue);
    console.log(newValue);

    if (newValue != "") {
      SetTimer(
        setTimeout(() => {
          SetPageNo(1);
          get_products(
            {
              page_no: 1,
            },
            count,
            newValue,
            type,
            text,
            sort
          );
        }, PRICE_WAIT_INTERVAL)
      );
    }
    // SetMinMaxValue(newValue);

    // get_products(1,count);
  };

  return (
    <div className="">
      {/* Header */}
      <div
        className={
          OutletContext ? "head-bar sticky-sm " : "head-bar  sticky-sm"
        }
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="row align-items-center">
          <div className="col-7 mb-2 p-0">
            <h6 className="mb-0">Products Groups</h6>
          </div>
          <div className="col-5 p-0 end mb-2">
            <Link to={"/brand/" + id_value.brand_id + "/addproducts"}>
            <button className="btn bg-prime text-white btn-radius px-3 btn-sm">
                Add Products
              </button>
            </Link>
          </div>
        </div>

        <div className="py-2 row align-items-center">
          <div className="col-10 col-md-6 p-0 sm-mb-2">
            <div>
              {/* <AliceCarousel
                mouseTracking
                touchMoveDefaultEvents={false}
                // items={productsRestraunt}
                // paddingLeft={50}
                paddingRight={30}
                disableDotsControls
                responsive={responsive}
                disableButtonsControls
                controlsStrategy="alternate"
              > */}

              <div className="mt-1 d-flex align-items-center bg-fff ps-0 p-2 md-px-3 ">
                <div className="d-flex align-items-center scrollX">
                  {view_option.map((options, o_index) => (
                    <button
                      type="button"
                      className={
                        type == options.value
                          ? "btn btn-text header-tap me-1 px-3 center"
                          : "btn btn-text text-black me-1 px-3 center"
                      }
                      // <div key={o_index} onDragStart={handleDragStart}
                      // className={type == options.value ? 'active-tab-view me-2 cursor' : 'tab-view me-2 cursor'}
                      onClick={() => {
                        setType(options.value);
                        SetPageNo(1);
                        get_products(
                          {
                            page_no: 1,
                          },
                          count,
                          min_max_value,
                          options.value,
                          text,
                          sort
                        );
                      }}
                    >
                      {/* <p className={type == options.value ? "text-white px-2 text-center" : "px-2 text-center"}> */}
                      {options.label}
                      {/* </p> */}
                      {/* </div> */}
                    </button>
                  ))}
                  {/* // </AliceCarousel> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-2 d-sm-block d-md-none  p-0 ms-auto">
            <button
              className="btn btn-text sm-mb-2 ps-3"
              onClick={() => {
                SetFilterOpen(true);
              }}
            >
              {/* <Ionicons name="md-options" size={20} color="black" /> */}
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "311ae78abd7e491e4b25539cfbf1a03f.png"
                }
                className="type-img-big"
              />
              <span className=" position-absolute rounded-circle translate-middle badge rounded-pill bg-prime">
                2
              </span>
            </button>
          </div>
          <div className="col-md-6 row align-items-center p-0">
            <div className="col-10 px-sm-0">
              <div className="row px-md-3  md-none">
                <div className="input-icons form-group">
                  <input
                    type="text"
                    className="form-control search-bar p-0"
                    placeholder="Search products"
                    defaultValue={text}
                    onChange={(e) => {
                      onTextChanged(e);
                    }}
                  />
                  <div className="cursor ps-2 border-left">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "c241785c497fb98f5f7d4e587b3857ba.svg"
                    }
                    className="w-20px"
                  />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-3 col-md-2 pe-0">
              <select
                 className="number-select form-select btn-radius form-control pe-0 ps-2"
                defaultValue="10"
                onChange={(e) => {
                  countChange(e)
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>

                <option value="40">40</option>

                <option value="50">50</option>
              </select>
            </div> */}
            <div className="col-2 col-md-2 ps-1">
              <div
                className="pe-2 d-flex align-items-center cursor"
                onClick={() => {
                  dataSort();
                }}
              >
                <img src={AppConfig.CDN_Media_url+"1b59418519237ad4271be18c3bc9011f.svg"} className="w-16px" />
                <p className="text-darkgray ps-1 small fw-bold">Sort</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Body */}
      <div
        className={OutletContext ? " px-md-3 px-sm-0" : "px-md-3 px-sm-0"}
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="row pt-0">
          <div className="col-md-3 px-0 d-sm-none d-md-block mt-3">
            <div className="bg-fff radius p-3 sticky-md-50px overflowY-auto scrollw-none">
              <div className="d-flex ">
                <h6 className="fs-5 fw-500">Filter</h6>
                <div className="ms-auto cursor">
                  <small className="text-black">Clear</small>
                </div>
              </div>
              <div className="my-3">
                <label className="form-label d-flex align-items-center mb-3 cursor">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    name={"own products"}
                    // value={ele.id}
                    onChange={(event) => {
                      input_on_change(event);
                    }}
                  />
                  <p className="capitalize">{"own product"}</p>
                </label>

                <label className="form-label d-flex align-items-center mb-3 cursor">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    name={"others products"}
                    // value={ele.id}
                    onChange={(event) => {
                      input_on_change(event);
                    }}
                  />
                  <p className="capitalize">{"other product"}</p>
                </label>
              </div>
              <div className="d-flex ">
                <h6>Category</h6>
              </div>
              <div className="my-3">
                {filter.product_categories?.map((ele: any) => (
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name={"filter_category"}
                      value={ele.id}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                    <p className="capitalize">{ele.name}</p>
                  </label>
                ))}
              </div>

              <div className="brand">
                <Accordion className="px-0">
                  <AccordionSummary
                    expandIcon={
                      <img src={AppConfig.CDN_Media_url+"78f45bd73c51ade6a32e1185735e5439.svg"} className="w-10px" />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p className="fs-7 fw-bold text-black">Brand</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="w-100">
                      <input
                        type="text"
                        placeholder="Search..."
                        className="form-control btn-radius"
                      />
                      <div className="p-2">
                        {filter.product_brands?.map((ele: any) => (
                          <label className="form-label d-flex align-items-center mb-3 cursor">
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              name={"filter_brand"}
                              value={ele.id}
                              onChange={(event) => {
                                input_on_change(event);
                              }}
                            />
                            <p className="capitalize">{ele.name}</p>
                          </label>
                        ))}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div className="mt-3">
                <p className="text-black" id="range-slider">
                  Mininum Maximum Price
                </p>
                <div className="d-flex align-items-center">
                  {/* <p className="pe-3">Min</p> */}
                  <Slider
                    value={min_max_value}
                    onChange={handleChange}
                    step={20}
                    onClick={() => {
                      console.log("min max :", min_max_value);
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                    min={10}
                    max={5000}
                  />
                  {/* <p className="px-3">Max</p> */}
                </div>

                <div className="row pt-2">
                  <div className="col-6">
                    <label className="form-label mb-1 cursor">Min Price</label>
                    <input
                      type="text"
                      className="form-control"
                      name={"min_mrp"}
                      value={min_max_value[0]}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label mb-1 cursor">Max Price</label>
                    <input
                      type="text"
                      className="form-control"
                      name={"max_mrp"}
                      value={min_max_value[1]}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-9 mt-3">
            {/* <InfiniteScroll
        
          > */}
            {visible_content ? (
              <>
                {add_product ? (
                  <div>
                    {product_data.length > 0 ? (
                      <div className="row p-0">
                        {product_data.map((ele: any) => (
                          <div className="col-md-6 mb-3 px-sm-0 px-md-2">
                            <div className="card p-3">
                              <div className="row">
                                <div className="col-3 pe-2 ps-0">
                                  {ele.images ? (
                                    <img
                                      src={AppHelper.imageSrc(
                                        data.image_base_url,
                                        ele.images[0]?.url,
                                        { format: "webp", width: 100 }
                                      )}
                                      className="prod-group-img"
                                    />
                                  ) : (
                                    <img
                                    src={AppConfig.CDN_Image_url+"33c67a26ab58ada3ee31b91f7efb32bb.png"}
                                      alt="pic"
                                      className="prod-group-img"
                                    />
                                  )}
                                </div>
                                <div className="col-8 p-0">
                                  <h6 className="mb-2">{ele.name}</h6>
                                  <div className="d-flex">
                                    <img src={AppConfig.CDN_Media_url+"034b423e49a75c18ce1518469e6d55be.svg"} className="w-10px" />
                                    {/* <MaterialIcons
                                      name="category"
                                      size={14}
                                      color="gray"
                                    /> */}
                                    <p className="ps-1">
                                      <small className="text-black fw-500">
                                        {ele.category}
                                      </small>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-1 ps-0">
                                  {/* <div className="dropdown menu cursor">
                              <div className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <Entypo name="dots-three-vertical" size={18} color="#aaa" />
                              </div>

                              <ul className="dropdown-menu  dropdown-menu-start">

                                <li className="cursor"
                                  onClick={() => {
                                    console.log("Delete Clicked!");
                                  }}
                                >
                                  <div className="dropdown-item d-flex align-items-center">
                                    <MaterialIcons name="delete-outline" size={18} color="#555" />
                                    <p className="ps-2 text-black">Delete</p>
                                  </div>
                                </li>

                              </ul>
                            </div> */}
                                </div>
                              </div>
                              <div className="py-2 border-bottom">
                                <p className="fw-bold text-black">
                                  MRP : ₹{ele.min_mrp} - ₹{ele.max_mrp}
                                </p>
                              </div>

                              <div className="row py-2">
                                <div className="col-4">
                                  <p className="text-black mb-2 extra-small fw-bold">Variants</p>
                                  <p className="text-gray">
                                    {ele.total_variants +
                                      "(" +
                                      ele.total_active_variants +
                                      ")"}
                                  </p>
                                </div>
                                {/* <div className="col-4">
                            <p className='text-black mb-2'>Addons</p>
                            <p className='text-gray'> {ele.total_addon_groups +
                              '(' +
                              ele.total_active_addon_groups +
                              ')'}</p>
                          </div> */}
                                <div className="col-4">
                                  <p className="text-black mb-2 extra-small fw-bold">Addon Items</p>
                                  <p className="text-gray">
                                    {" "}
                                    {ele.total_addon_items +
                                      "(" +
                                      ele.total_active_addon_items +
                                      ")"}
                                  </p>
                                </div>
                              </div>
                              <div className="border-top pt-2">
                                <div className="row align-items-center">
                                  <div
                                    className="col-6 text-center cursor"
                                    onClick={() => {
                                      console.log("edit variants");
                                      SetModalVariants({
                                        // prevValue.is_open = true;
                                        is_open: true,
                                        product_name: ele.name,
                                        product_id: ele.id,
                                        product_checked: ele.checked,

                                        data: ele.variants,
                                        type: "edit",

                                        // return { ...prevValue }
                                      });
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      {/* <Feather name="edit-3" size={14} color="#663fc3" /> */}
                                      <p className="text-prime fw-bold">View</p>
                                    </div>
                                  </div>
                                  <div
                                    className="col-6 cursor"
                                    onClick={() => {
                                      console.log("add variants");
                                      SetModalVariants({
                                        // prevValue.is_open = true;
                                        is_open: true,
                                        product_name: ele.name,
                                        product_id: ele.id,
                                        product_checked: ele.checked,
                                        unavailable: ele.unavailable,

                                        data: ele.variants,
                                        type: "available",

                                        // return { ...prevValue }
                                      });
                                    }}
                                  >
                                    <div className="end">
                                      {/* <Feather name="check-circle" size={14} color="#663fc3" /> */}
                                      <p className="text-prime fw-bold">
                                        Make Available
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          // <div className="col-md-6">
                          //   <div className="">
                          //     <div className="img-pos">
                          //       {ele.images ? (
                          //         <img
                          //           src={
                          //             data.image_base_url +
                          //             data.sizes[0] +
                          //             '/' +
                          //             ele.images[0].url
                          //           }
                          //           className="prod-group-img"
                          //         />
                          //       ) : (
                          //         <img
                          //           src={
                          //             'https://cpimg.tistatic.com/06747380/b/4/Used-Iphone-12.jpg'
                          //           }
                          //           alt="pic"
                          //           className="prod-group-img"
                          //         />
                          //       )}
                          //     </div>
                          //     <div className="card img-pos-card p-2">
                          //       <div className="text-end p-1">
                          //         <MaterialIcons name="delete-outline" size={18} color="#777" />
                          //       </div>
                          //       <h6 className='mb-2'>{ele.name}</h6>
                          //       <div className="row align-items-center mb-1">
                          //         <div className="col-6 pe-0">
                          //           <div className="d-flex">
                          //             <MaterialIcons name="category" size={14} color="gray" />
                          //             <p className='ps-1'><small className="text-dark">
                          //               {ele.category}
                          //             </small></p>
                          //           </div>

                          //         </div>
                          //         <div className="col-6 px-2 text-end">
                          //           <small className="text-dark">
                          //             ₹{ele.min_mrp} - ₹{ele.max_mrp}
                          //           </small>
                          //         </div>
                          //       </div>

                          //       <p className='mb-1'>
                          //         <small className="text-gray">
                          //           Variants :
                          //           <span className="ps-1 text-dark">
                          //             {ele.total_variants +
                          //               ' ( ' +
                          //               ele.total_active_variants +
                          //               ' )'}
                          //           </span>
                          //         </small>
                          //       </p>

                          //       <p className='mb-1'>
                          //         <small className="text-gray">
                          //           Addons :
                          //           <span className="ps-1 text-dark">
                          //             {ele.total_addon_groups +
                          //               ' ( ' +
                          //               ele.total_active_addon_groups +
                          //               ' )'}
                          //           </span>
                          //         </small>
                          //       </p>

                          //       <p className='mb-2'>
                          //         <small className="text-gray">
                          //           Addon Items :
                          //           <span className="ps-1 text-dark">
                          //             {ele.added_products +
                          //               ' ( ' +
                          //               ele.total_active_addon_items +
                          //               ' )'}
                          //           </span>
                          //         </small>
                          //       </p>
                          //       <div className="border-top py-2">
                          //         <div className="row align-items-center">
                          //           <div className="col-6 text-center cursor"
                          //             onClick={() => {
                          //               console.log('edit variants')
                          //               SetModalVariants({
                          //                 // prevValue.is_open = true;
                          //                 is_open: true,
                          //                 product_name: ele.name,
                          //                 product_id: ele.id,
                          //                 product_checked: ele.checked,

                          //                 data: ele.variants,
                          //                 type: 'edit',

                          //                 // return { ...prevValue }
                          //               })
                          //             }}>
                          //             <div className="d-flex align-items-center">
                          //               <Feather name="edit-3" size={14} color="#663fc3" />
                          //               <p className='text-prime v-small ps-2 fw-bold'>Edit</p>
                          //             </div>
                          //           </div>
                          //           <div className="col-6 cursor"
                          //             onClick={() => {
                          //               console.log('add variants')
                          //               SetModalVariants({
                          //                 // prevValue.is_open = true;
                          //                 is_open: true,
                          //                 product_name: ele.name,
                          //                 product_id: ele.id,
                          //                 product_checked: ele.checked,
                          //                 unavailable: ele.unavailable,

                          //                 data: ele.variants,
                          //                 type: 'available',

                          //                 // return { ...prevValue }
                          //               })
                          //             }}>
                          //             <div className="end">
                          //               <Feather name="check-circle" size={14} color="#663fc3" />
                          //               <p className='text-prime v-small ps-2 fw-bold'>Make Available</p>
                          //             </div>
                          //           </div>
                          //         </div>
                          //       </div>

                          //     </div>

                          //   </div>
                          //   {/* <div
                          //     className="card p-3 mb-2"
                          //     onClick={(event) => {
                          //       if (width < 768) {
                          //         console.log('width', width)
                          //         // SetSelectedData({
                          //         //     value: data,
                          //         //     i: u_index,
                          //         //     type: "active",
                          //         //   });
                          //         sample_fun('bottom', true, event)
                          //       }
                          //     }}
                          //   >
                          //     <div className="row align-items-center mb-2">
                          //       <div className="col-10">
                          //         <h6 className="mb-0">{ele.name}</h6>
                          //       </div>
                          //       <div className="col-2 text-end">
                          //         <FontAwesome
                          //           name="circle"
                          //           size={14}
                          //           color="#18b901"
                          //         />
                          //       </div>
                          //     </div>
                          //     <div className="row">
                          //       <div className="col-md-3 col-3">

                          //         {ele.images ? (
                          //           <img
                          //             src={
                          //               data.image_base_url +
                          //               data.sizes[0] +
                          //               '/' +
                          //               ele.images[0].url
                          //             }
                          //             className="img-fluid"
                          //           />
                          //         ) : (
                          //           <img
                          //             src={
                          //               'https://cpimg.tistatic.com/06747380/b/4/Used-Iphone-12.jpg'
                          //             }
                          //             alt="pic"
                          //             className="img-fluid"
                          //           />
                          //         )}
                          //       </div>
                          //       <div className="col-md-8 col-9 ps-0">
                          //         <div className="row">
                          //           <div className="col-6">
                          //             <p>
                          //               Variants :{' '}
                          //               <small className="text-dark">
                          //                 <span className="text-dark">
                          //                   {ele.total_variants +
                          //                     '(' +
                          //                     ele.total_active_variants +
                          //                     ')'}
                          //                 </span>{' '}
                          //               </small>{' '}
                          //             </p>
                          //           </div>
                          //           <div className="col-6">
                          //             <p>
                          //               Addons :{' '}
                          //               <small className="text-dark">
                          //                 {' '}
                          //                 <span className="text-dark">
                          //                   {ele.total_addon_groups +
                          //                     '(' +
                          //                     ele.total_active_addon_groups +
                          //                     ')'}
                          //                 </span>
                          //               </small>
                          //             </p>
                          //           </div>

                          //           <div className="col-6">
                          //             <p>
                          //               Addon Items :
                          //               <small className="text-dark">
                          //                 {' '}
                          //                 <span className="text-dark">
                          //                   {ele.added_products +
                          //                     '(' +
                          //                     ele.total_active_addon_items +
                          //                     ')'}
                          //                 </span>
                          //               </small>
                          //             </p>
                          //           </div>
                          //           <div className="col-6">
                          //             <p>
                          //               Brand :{' '}
                          //               <small className="text-dark">
                          //                 {ele.product_brand}
                          //               </small>
                          //             </p>
                          //           </div>
                          //           <div className="col-6">
                          //             <p>
                          //               Category :{' '}
                          //               <small className="text-dark">
                          //                 {ele.category}
                          //               </small>
                          //             </p>
                          //           </div>
                          //           <div className="col-12">
                          //             <p>
                          //               MRP :{' '}
                          //               <small className="text-dark">
                          //                 {' '}
                          //                 ₹{ele.min_mrp} - ₹{ele.max_mrp}{' '}
                          //               </small>
                          //             </p>
                          //           </div>
                          //         </div>
                          //       </div>
                          //       <div className="col-md-1 md-ps-0 d-sm-none d-md-block">
                          //         <div className="cursor center my-3">
                          //           <MaterialIcons
                          //             name="do-not-disturb"
                          //             size={16}
                          //             color="gray"
                          //           />
                          //         </div>

                          //         <div
                          //           className="cursor center my-3"
                          //           onClick={() => {
                          //             console.log('add variants')
                          //             SetModalVariants({
                          //               // prevValue.is_open = true;
                          //               is_open: true,
                          //               product_name: ele.name,
                          //               product_id: ele.id,
                          //               product_checked: ele.checked,
                          //               unavailable: ele.unavailable,

                          //               data: ele.variants,
                          //               type: 'available',

                          //               // return { ...prevValue }
                          //             })
                          //           }}
                          //         >
                          //           <MaterialIcons
                          //             name="check-circle"
                          //             size={16}
                          //             color="gray"
                          //           />
                          //         </div>

                          //         <div className="cursor center my-3">
                          //           <MaterialIcons
                          //             name="delete-outline"
                          //             size={16}
                          //             color="gray"
                          //           />
                          //         </div>

                          //         <div
                          //           className="cursor center my-3"
                          //           onClick={() => {
                          //             console.log('add variants')
                          //             SetModalVariants({
                          //               // prevValue.is_open = true;
                          //               is_open: true,
                          //               product_name: ele.name,
                          //               product_id: ele.id,
                          //               product_checked: ele.checked,

                          //               data: ele.variants,
                          //               type: 'edit',

                          //               // return { ...prevValue }
                          //             })
                          //           }}
                          //         >
                          //           <Feather name="edit-3" size={16} color="gray" />
                          //         </div>
                          //       </div>
                          //     </div>
                          //   </div> */}
                          // </div>
                        ))}
                      </div>
                    ) : (
                      <div className="card p-3 text-center">
                         <img src={AppConfig.CDN_Image_url+"e38cbe608dba40c81ec58d266c09055f.png"} className="empty-img"
                  />
                        <p className="v-small mb-3">
                          No Data, Please Add Product Group
                        </p>
                        <Link
                          to={"/brand/" + id_value.brand_id + "/addproducts"}
                        >
                          <button className="btn btn-custom-outline btn-radius px-3 btn-sm">
                            Add Products
                          </button>
                        </Link>
                      </div>
                    )}
                    <div />
                  </div>
                ) : null}
              </>
            ) : (
              <div className="row p-3">
              <div className="col-md-3 px-0 d-sm-none d-md-block">
<div className="bg-fff radius p-3 overflowY-auto scrollw-none">
<div className="d-flex">
 <Skeleton width={100} height={25} />
 <div className="ms-auto cursor">
   <Skeleton width={50} height={20} />
 </div>
</div>
<div className="my-3">
 <label className="form-label d-flex align-items-center mb-3 cursor">
   <Skeleton width={20} height={20} circle={true} />
   <p className="ms-2"><Skeleton width={80} /></p>
 </label>
 <label className="form-label d-flex align-items-center mb-3 cursor">
   <Skeleton width={20} height={20} circle={true} />
   <p className="ms-2"><Skeleton width={80} /></p>
 </label>
</div>
<div className="">
 <h6><Skeleton width={80} /></h6>
</div>
<div className="my-3">
 {[1, 2, 3].map((index) => (
   <label className="form-label d-flex align-items-center mb-3 cursor" key={index}>
     <Skeleton width={20} height={20} circle={true} />
     <p className="ms-2"><Skeleton width={100} /></p>
   </label>
 ))}
</div>
<div className="brand">
 <Accordion className="px-0">
   <AccordionSummary expandIcon={<Skeleton width={20} height={20} />}>
     <p className="fs-7 fw-bold text-black"><Skeleton width={50} /></p>
   </AccordionSummary>
   <AccordionDetails>
     <div className="w-100">
       <Skeleton width="100%" height={30} />
       <div className="p-2">
         {[1, 2, 3].map((index) => (
           <label className="form-label mt-3 d-flex align-items-center" key={index}>
             <Skeleton width={20} height={20} circle={true} />
             <p className="ms-2"><Skeleton width={100} /></p>
           </label>
         ))}
       </div>
     </div>
   </AccordionDetails>
 </Accordion>
</div>
<div className="mt-3">
 <p className="text-black"><Skeleton width={200} /></p>
 <div className="d-flex align-items-center">
   <Skeleton width="100%" height={30} />
 </div>
 <div className="row mt-3">
   <div className="col-6">
     <Skeleton width="100%" height={40} />
   </div>
   <div className="col-6">
     <Skeleton width="100%" height={40} />
   </div>
 </div>
</div>
</div>
</div>

<div className="col-md-9 mb-2" >
<div className="row">
{ [1, 2, 3, 4].map(() => (
<div className="col-md-6 mb-2">
               <div className="card p-3 pb-0">
                 <div className="row">
                   <div className="col-3 pe-0 ps-2">
                     <Skeleton width={100} height={100} />
                   </div>
                   <div className="col-8">
                     <h6><Skeleton width={200} /></h6>
                   </div>
                   <div className="col-1 ps-0">
                     {/* Dropdown menu skeleton */}
                   </div>
                 </div>
                 <div className="py-2">
                   <p><Skeleton count={2} /></p>
                 </div>
                 {/* Package details skeleton */}
                 <div className="row align-items-center pb-2 border-bottom">
              
                 </div>
                 {/* Accordion skeleton */}
                 <div className="prods-card">
                   <Accordion className="px-0">
                     <AccordionSummary expandIcon={<Skeleton width={20} height={20} />}>
                       <p className="fs-7 fw-bold text-black"><Skeleton width={150} /></p>
                     </AccordionSummary>
                     <AccordionDetails>
                       {/* Accordion content skeleton */}
                       <div className="w-100">
                         {/* Package and tax details skeleton */}
                         <p><Skeleton count={4} /></p>
                         <div className="text-end w-100 mt-2">
                           <button className="btn btn-sm px-4 bg-prime btn-radius text-white fw-bold"><Skeleton width={50} /></button>
                         </div>
                       </div>
                     </AccordionDetails>
                   </Accordion>
                 </div>
               </div>
               </div>

           ))}
             </div>

             </div>
             </div>
            )}
            {/* </InfiniteScroll>  */}
          </div>
        </div>

        {/* PR */}

        {/* <ModalVariants
        open={modal_variants.is_open}
        close={(data: any) => {
            console.log("Variants modl on close :", data)
            SetModalVariants((prevValue: any) => {
                prevValue.is_open = false;
                return { ...prevValue }
            })
        }}
      /> */}

            {/* <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={(event) => {
                sample_fun(anchor, false, event);
              }}
              onOpen={(event) => {
                sample_fun(anchor, true, event);
              }}
            >
              <div className="mt-3">
                <div>
                  <div className="d-flex justify-content-center">
                    <div className="w-25 px-4">
                      <hr className="h-3" />
                    </div>
                  </div>
                  <ul>
                    <li
                      className="p-2 pb-3 cursor align-center"
                      onClick={(event) => {
                        console.log("Inactive");
                        sample_fun(anchor, false, event);
                      }}
                    >
                      <MaterialIcons
                        name="do-not-disturb"
                        size={16}
                        color="gray"
                      />
                      &nbsp; Inactive
                    </li>

                    <li
                      className="p-2 pb-3 cursor align-center text-dark"
                      onClick={(event) => {
                        console.log("Remove");
                        sample_fun(anchor, false, event);
                      }}
                    >
                      <MaterialIcons
                        name="delete-outline"
                        size={16}
                        color="gray"
                      />
                      &nbsp; Remove
                    </li>
                    <li
                      className="p-2 pb-3 cursor align-center text-dark"
                      onClick={(event) => {
                        console.log("Edit");
                        sample_fun(anchor, false, event);
                      }}
                    >
                      <Feather name="edit-3" size={16} color="gray" />
                      &nbsp; Edit
                    </li>
                  </ul>
                </div>
              </div>
            </SwipeableDrawer> */}

        <ModalVariants
          open={modal_variants.is_open}
          product={modal_variants.product_name}
          id={modal_variants.product_id}
          checked={modal_variants.product_checked}
          data={modal_variants.data}
          unavailable={modal_variants.unavailable}
          type={modal_variants.type}
          close={(data: any) => {
            console.log("Variants modl on close :", data);
            // SetModalVariants((prevValue: any) => {
            //     prevValue.is_open = false;
            //     return { ...prevValue }
            // })
            SetModalVariants({
              is_open: false,
              product_name: "",
              product_id: "",
              product_checked: "",
              // unavailable: "",

              // return { ...prevValue }
              data: {},
              type: type,
            });

            if (data.action == "add") {
              SetPageNo(1);
              get_products(
                {
                  page_no: 1,
                },
                count,
                min_max_value,
                type,
                text,
                sort
              );
              toast.success("Product edited");
            }
          }}
        />

        {width < 786 ? (
          <SwipeableBottomSheet
            overflowHeight={0}
            style={{ zIndex: 1300 }}
            open={filter_open}
            onClose={() => {
              SetFilterOpen(false);
            }}
            onChange={() => {
              SetFilterOpen(false);
            }}
          >
            <div
              className="bg-fff radius-top"
              style={{ minHeight: "500px", maxHeight: "90vh" }}
            >
              <div className=" drawer-fixed">
                <div className="w-100 center">
                  <hr className="hr-drawer" />
                </div>
                <div className="row align-items-center pt-2 px-3">
                  <div className="col-9">
                    <h6 className="fs-5 fw-500">Filter</h6>
                  </div>
                  <div className="col-3 text-end cursor">
                    <small className="text-black">Clear</small>
                  </div>
                </div>
              </div>
              <div className="p-3 pt-5">
                <div className="mb-3 mt-5">
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name={"own products"}
                      // value={ele.id}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                    <p className="capitalize">{"own product"}</p>
                  </label>

                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name={"others products"}
                      // value={ele.id}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                    <p className="capitalize">{"other product"}</p>
                  </label>
                </div>
                <div className="d-flex ">
                  <h6>Category</h6>
                </div>
                <div className="my-3">
                  {filter.product_categories?.map((ele: any) => (
                    <label className="form-label d-flex align-items-center mb-3 cursor">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        name={"filter_category"}
                        value={ele.id}
                        onChange={(event) => {
                          input_on_change(event);
                        }}
                      />
                      <p className="capitalize">{ele.name}</p>
                    </label>
                  ))}
                </div>

                <div className="brand">
                  <Accordion className="px-0">
                    <AccordionSummary
                      expandIcon={
                        <img src={AppConfig.CDN_Media_url+"78f45bd73c51ade6a32e1185735e5439.svg"} className="w-10px" />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className="fs-7 fw-bold text-black">Brand</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="w-100">
                        <input
                          type="text"
                          placeholder="Search..."
                          className="form-control btn-radius"
                        />
                        <div className="p-2">
                          {filter.product_brands?.map((ele: any) => (
                            <label className="form-label d-flex align-items-center mb-3 cursor">
                              <input
                                type="checkbox"
                                className="form-check-input me-2"
                                name={"filter_brand"}
                                value={ele.id}
                                onChange={(event) => {
                                  input_on_change(event);
                                }}
                              />
                              <p className="capitalize">{ele.name}</p>
                            </label>
                          ))}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="mt-3">
                  <p className="text-black" id="range-slider">
                    Mininum Maximum Price
                  </p>
                  <div className="d-flex align-items-center">
                    {/* <p className="pe-3">Min</p> */}
                    <Slider
                      value={min_max_value}
                      onChange={handleChange}
                      step={20}
                      onClick={() => {
                        console.log("min max :", min_max_value);
                      }}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                      min={10}
                      max={5000}
                    />
                    {/* <p className="px-3">Max</p> */}
                  </div>

                  <div className="row pt-2">
                    <div className="col-6">
                      <label className="form-label mb-1 cursor">
                        Min Price
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name={"min_mrp"}
                        value={min_max_value[0]}
                        onChange={(event) => {
                          input_on_change(event);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-label mb-1 cursor">
                        Max Price
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name={"max_mrp"}
                        value={min_max_value[1]}
                        onChange={(event) => {
                          input_on_change(event);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwipeableBottomSheet>
        ) : null}
      </div>
    </div>
  );
}
