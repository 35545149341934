import React, { useState, useEffect } from "react";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
// import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import { useParams } from "react-router-dom";

let myModal = {};

const userRoles = [
    { value: "admin", label: "Admin" },
    { value: "admin1", label: "Admin1" },
    { value: "admin2", label: "Admin2" },
]


export default function ModalCreateSector(props: any) {
    const [close_data, SetCloseData] = useState({
        action: "close",
    });
    const [id_value, SerIdValue] = useState(useParams());

    useEffect(() => {
        if (props.open) {
            console.log("ModalSector On Open :", props);
            myModal = new bootstrap.Modal(
                document.getElementById("SectorModal"),
                {}
            );
            myModal.show();
        }
    });

    useEffect(() => {
        console.log("ModalSector On mount :", props);
        let myModal1 = document.getElementById("SectorModal");
        myModal1.addEventListener("hidden.bs.modal", function (event) {
            props.close(close_data);
        });
    }, []);



    return (
        <div
            className="modal"
            id="SectorModal"
            tabIndex={-1}
            aria-labelledby="ModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="  modal-title" id="ModalLabel">
                            {props.type == "create" ? (
                                "Create Sector"
                            ) : (
                                props.type == "edit" ? (
                                    "Edit Sector"
                                ) : (
                                    props.type == "outlet" ? (
                                        "Outlet Sector"
                                    ) : (null)
                                )
                            )}
                        </h6>

                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                                let item = close_data;
                                item.action = "close";
                                SetCloseData(item);
                                myModal.hide();
                            }}
                        ></button>
                    </div>
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <input type="text" className="form-control" placeholder="Franchise type" readOnly="edit" />
                            </div>

                            <div className="col-md-6 mb-3">
                                <input type="text" className="form-control" placeholder="Franchise User" />
                            </div>



                            <div className="col-md-6 mb-3">
                                <Select
                                    options={userRoles}
                                    placeholder="User Roles"
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <input type="number" className="form-control" placeholder="Mobile number" />

                            </div>

                            <div className="col-md-12 mb-3">
                                <textarea placeholder="Address" rows={3} className="form-control" />
                            </div>

                            <div className="col-md-4 col-6 mb-3">
                                <input type="text" className="form-control" placeholder="City" />
                            </div>
                            <div className="col-md-4 col-6 mb-3">
                                <input type="text" className="form-control" placeholder="State" />
                            </div>
                            <div className="col-md-4 col-6 mb-3">
                                <input type="text" className="form-control" placeholder="Country" />
                            </div>

                            <div className="col-md-6 col-6  mb-3">
                                <input type="text" className="form-control" placeholder="GST No" />
                            </div>

                            <div className="col-md-6 mb-3">
                                <input type="email" className="form-control" placeholder="Email" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <input type="text" className="form-control" placeholder="Website" />
                            </div>

                        </div>

                        {props.type === "outlet" ? (
                            <div className="pb-3">
                                <label className="form-label d-flex mb-3">
                                    <input type="checkbox" className="form-check me-3" />
                                    Enable Online Store
                                </label>
                                <label className="form-label d-flex">
                                    <input type="checkbox" className="form-check me-3" />
                                    Enable Item Wish Order Management
                                </label>
                            </div>
                        ) : (null)}

                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary-outline btn-radius btn-sm px-3"
                            onClick={() => {
                                let item = close_data;
                                item.action = "close";
                                SetCloseData(item);
                                // SetSaveData(initial_value);
                                myModal.hide();
                            }}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-custom-outline px-3 ms-3 btn-sm"
                            onClick={() => {
                                console.log("save");
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
