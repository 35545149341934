
import React, { useState, useEffect } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import { omit } from "lodash";
import ModalDeleteProduct from "./ModalDeleteProduct";

let myModal = {};

export default function ModalProducts(props: any) {
  console.log(props.data.value?.products);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const OutletContext = useOutletContext();
  const [products, setProducts] = useState([]);
  const [text, setText] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [width, SetWidth] = useState(innerWidth);
  const [modal_type, SetModalType] = useState("");
  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });
  //FranchiseData End

  useEffect(() => {
    console.log("ModalFranchiseUser On mount :", props);
    let myModal1 = document.getElementById("FranchiseUserModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);

      {
        props.data.value &&
        setProducts(props.data.value.products)
      }
      setText("");
      SetPageNo(1);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModalFranchiseUser On mount :", props);
      {
        props.data.value &&
        setProducts(props.data.value.products)
      }

      myModal = new bootstrap.Modal(
        document.getElementById("FranchiseUserModal"),
        {}
      );
      myModal.show();
      SetModalType(props.type);

    }
  }, [props.open]);

  return (
    <div
      className="modal fade"
      id="FranchiseUserModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">

              Products

            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">

            {console.log(products)}

            <ul className="list-group list-group-flush">

              {products?.map((ele: any, index: any) => (
                <li className="list-group-item d-flex">
                  <div>
                    {ele.name}
                    <p> {ele.description.slice(0, 60)}...</p>

                  </div>
                </li>
              ))}

            </ul>


          </div>
          <div className="modal-footer">

          </div>
        </div>
      </div>

      {/* <ModalDeleteProduct
            open={openModal.is_open}
            data={openModal.data}
            close={(data: any) => {
              // console.log("Franchise modl on close :", data);
              SetopenModal((prevValue: any) => {
                prevValue.is_open = false;
                return { ...prevValue };
              });
              if (data.action == "save") {
                get_account();
              }
            }}
          /> */}
    </div>
  );
}
