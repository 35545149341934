import React, { useState, useEffect, useContext } from "react";
import { Outlet, Link, useLocation, useParams } from "react-router-dom";
import OutletSideMenu from "../OutletSideMenu/OutletSideMenu";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Orders from "../../pages/Outlets/Orders/Orders";

import DataContext from "../../utils/Context/DataContext";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "../../utils/Loader";
import { Toaster } from "react-hot-toast";
import { api } from "../../utils/Api";
import SideMenu from "../SideMenu/SideMenu";

export default function OrderHomeLayout(props: any) {
  const context: any = useContext(DataContext);
  const location = useLocation();
  const [id_value, SetIdValue] = useState(useParams());

  const [width, SetWidth] = useState(innerWidth);
  const [is_menu_open, SetIsMenuOpen] = useState(width < 786 ? false : true);

  useEffect(() => {
    console.log("OutletLayout onmount context :", id_value);
    // get_init();
    // get_locales();
  }, [id_value]);

  async function get_locales() {
    let pass_data = {
      get: { lang: context.app_data.selected_language.value },
    };
    let response = await api("/locales", pass_data);
    console.log("locales :", response);

    if (response.response.hasOwnProperty("data")) {
      context.SetAppData((prevValue) => {
        prevValue.language_data = response.response.data;
        return { ...prevValue };
      });
    }
  }

  // async function get_init() {
  //   let response = await api("/init");
  //   console.log("app_init :", response);
  //   if (response.status_code == 200) {
  //     if (response.response.hasOwnProperty("user_details")) {
  //       localStorage.setItem(
  //         "user_data",
  //         JSON.stringify(response.response.user_details)
  //       );
  //     }
  //   }
  // }

  // useEffect(() => {
  //   context.SetSampleData("hai");
  // }, [location]);
//   async function get_init() {
//     let pass_data = {
//       get: {
//         brand: id_value.brand_id,
//         outlet: id_value.outlet_id,
//       },
//     };
//     let response:any = await api("/outlet/init", pass_data);
//     console.log("/outlet/init :", response);
//     if (response.status_code == 200) {
//       // set()
//       // if (response.response.hasOwnProperty("countries")) {
//       context.SetAppData((prevValue: any) => {
//         prevValue.current_outlet = response.response.accounts.active;
//         prevValue.notification = response.response.accounts.order_notification;
//         prevValue.pages = response.response.auth_pages.pages;

//         return { ...prevValue };
//       });
//       // }
//     }
//   }

  function on_menu_click(data: any) {
    console.log("OutletLayout on menu/back click :", data);
    SetIsMenuOpen(data);
  }

  return (
    <>
      {/* <Loader promiseTracker={usePromiseTracker} /> */}
      {/* <div className="page-body"> */}
        {/* {is_menu_open ? (
          <OutletSideMenu
            active={is_menu_open}
            menu_click={(data: any) => {
              on_menu_click(data);
            }}
          />
        ) : null} */}
        {/* {width <= 720 ? (
          <div className="">
            <SideMenu
              is_menu_open={is_menu_open}
              from="mobile_header"
              onSelect={(data: any) => {
                console.log("footer sidemenu open: ", data);
                SetIsMenuOpen(false);
              }}
              type={""}
            />
          </div>
        ) : null} */}
        <div >
          <Header
            is_menu_open={is_menu_open}
            menu_click={(data: any) => {
              console.log("Header menu_click data :", data);
              on_menu_click(data);
            }}
          />
          <div
            className={is_menu_open == false && width > 786 ? "page-main" : ""}
          >
        <Orders  type={props.type}/>
            <Toaster
              position="top-center"
              reverseOrder={false}
              toastOptions={{
                className: "",
                style: {
                  border: "1px solid #171546",
                  padding: "10px",
                },
                // duration: 1500,
                success: {
                  duration: 1500,
                  style: {
                    border: "1px solid #171546",
                    padding: "10px",
                  },
                },
                error: {
                  duration: 1500,
                  style: {
                    border: "1px solid #171546",
                    padding: "10px",
                  },
                },
              }}
            />
          </div>
        </div>
      {/* </div> */}
      <Footer
        is_menu_open = {is_menu_open}
        menu_click={(data: any) => {
          on_menu_click(data);
        }}
        from="main"
      />
    </>
  );
}
