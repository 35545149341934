import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import AddonPricingComponent from "../../../components/AddonPricingComponent/AddonPricingComponent";
import AddonPricingComponent1 from "../../../components/AddonPricingComponent1/AddonPricingComponent1";
import AddonPricingComponent2 from "../../../components/AddonPricingComponent2/AddonPricingComponent2";
import toast from "react-hot-toast";

import { MaterialCommunityIcons } from "@expo/vector-icons";

export default function AddonPricing(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [id_value, SerIdValue] = useState(useParams());

  useEffect(() => {
    console.log("AddonPricing On mount :", props);
    console.log("AddonPricing context :", context);
  }, []);

  return (
    <div>
      <AddonPricingComponent2
        title={"Addon Pricing"}
        value={id_value}
        is_menu_open={OutletContext}
      />
      {/* <AddonPricingComponent title={"Addon Pricing"} value={id_value} is_menu_open={OutletContext} /> */}
      {/* <ProductPricingComponent /> */}
    </div>
  );
}
