import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import { useNavigate } from 'react-router-dom';

import {
  Feather,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";

import ModalVariants from "../../Brands/Products/ModalVariants";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppConfig } from "../../../config";


export default function OutletAddons(props: any) {
  const context:any = useContext(DataContext);
  
  const OutletContext = useOutletContext();
  const [grid_view, SetGridView] = useState(false);
  const [list_view, SetListView] = useState(true);
  const [id_value, SerIdValue] = useState(useParams());
  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    product_name: "",
    product_id: "",
    product_checked: "",
    data: {},
  });
  const [width, SetWidth] = useState(innerWidth);
  const [product_data, SetProductData] = useState([]);
  const [data, SetData] = useState([]);
  const [next_page, SetNextPage] = useState(false);
  const [page_no, SetPageNo] = useState(1);
  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const PRICE_WAIT_INTERVAL = 2000;
  const [text, setText] = useState("");
  const [search, SetSearch] = useState("");
  const [is_loading,SetIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Products On mount :", props);
    console.log("Products context :", context);
    if(context.app_data.pages.hasOwnProperty('31')){
      if(context.app_data.pages[31]){
      get_products(text);
      }else{
        console.log("hi")
        navigate("/no_access");
  
      }
    }else{
      SetIsLoading(true)
    }
    
  }, [context.app_data.pages?.[31]]);

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const search_text = e.target.value;
    setText(search_text);
    SetSearch(search_text);
    console.log("Text onchange: ", search_text);
    if (search_text == "") {
      let page_no_value = 1;
      SetPageNo(page_no_value);
      get_products(search_text);
    } else {
      SetTimer(
        setTimeout(() => {
          get_products(search_text);
        }, WAIT_INTERVAL)
      );
    }
  }

  async function get_products(search_value: any) {
    SetIsLoading(true);
    console.log(id_value);
    let pass_data : any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        page: 1,
        // search: search_value,
      },
    };
    SetSearch((s_prevalue)=>{
      pass_data.get.search = s_prevalue;
      return s_prevalue;
    });

    let response: any = await api("/outlet/get_addons", pass_data);
    console.log("/brand/brand_products :", response.response.data);
    if (response.status_code == 200) {
      SetData(response.response);
      SetIsLoading(false);
      if (response.response.hasOwnProperty("data")) {
        SetProductData(response.response.data);
        SetNextPage(response.response.next_page);
      } else {
        SetProductData([]);
      }
    }
  }

  async function load_more() {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    console.log(id_value);
    pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        page: page_no_value,
        // search: search,
      },
    };
    SetSearch((s_prevalue)=>{
      pass_data.get.search = s_prevalue;
      return s_prevalue;
    });
    let response: any = await api("/outlet/get_addons", pass_data);
    console.log("/brand/brand_products :", response.response.data);
    console.log("/brand/brand_products :", response.response);

    if (response.status_code == 200) {
      SetData(response.response);

      if (response.response.hasOwnProperty("data")) {
        SetProductData((prevValue) => {
          response.response.data.map((item) => {
            prevValue.push(item);
          });
          return [...prevValue];
        });
        // SetProductDetails(response.response.product_details);
        SetNextPage(response.response.next_page);
      } else {
        SetProductData([]);
      }
    }
    SetNextPage(response.response.next_page);
  }

  return (
    <div className="">
      {/* header */}
      <div
        className={OutletContext ? "head-bar sticky-sm" : "head-bar sticky-sm"}
        style={width < 780 ? { width: width } : {}}
      >
        <div className=" row align-items-center">
          <div className="col-md-7 sm-mb-2">
            <h6 className="mb-0">Addons</h6>
          </div>
          <div className="col-md-5 px-sm-0">
            <div className="row px-md-3 md-none">
              <div className="input-icons form-group">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search products"
                  defaultValue={text}
                  onChange={(e) => {
                    onTextChanged(e);
                  }}
                />
                <div className="cursor ps-2 border-left">
                  <Ionicons name="md-search" size={18} color="#170f56" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-4 col-9 ">
            <input type="text" placeholder="Search..." className="form-control" />
          </div>
          <div className="text-center col-md-1 col-3">
            <select className="form-control" defaultValue="10">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div> */}
          <div className="d-sm-none d-md-block text-center col-md-2 mb-2">
            {/* <Link
              to={
                "/outlet/" +
                id_value.brand_id +
                "/" +
                id_value.outlet_id +
                "/addproducts"
              }
            >
              <button className="btn btn-primary">Add New</button>
            </Link> */}
          </div>
        </div>
      </div>

      <div
        className="my-3 px-sm-0 mb-5 pb-4"
        style={
          width < 786
            ? OutletContext == false
              ? { width: width }
              : {}
            : OutletContext == false
            ? { width: width }
            : {}
        }
      >
        <div className="row ">
          <div className="col-md-6 ">

            {is_loading ? (
              [1, 2, 3, 4, 5, 6].map(() => (
                <div className="card py-3 px-2 mb-2">
                  <div className="">
                    <div className="d-flex align-items-center">
                      <Skeleton height={18} width={18} />
                      {/* <img src={require("../../../assets/icon/puzzle.png")} className="type-img-big" /> */}
                      <div className="ps-2">
                        <Skeleton height={10} width={200} />
                      </div>
                      <div className="ms-auto">
                        <Skeleton height={18} width={18} />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              product_data.length > 0 ? (
                <InfiniteScroll
                  dataLength={product_data.length}
                  next={() => {
                    load_more();
                  }}
                  hasMore={next_page}
                  loader={
                    <div className="center m-5">
                      <div className="lds-dual-ring"></div>
                      <p className="text-prime small ps-2">Just a second...</p>
                    </div>
                  }
                >
                  {product_data.map((ele: any) => (
                    <div className="card py-3 px-2 mb-2">
                      <Link
                        to={
                          "/outlet/" +
                          id_value.brand_id +
                          "/" +
                          id_value.outlet_id +
                          "/addons_inventory/" +
                          ele.id
                        }
                      >
                        <div className="">
                          <div className="d-flex align-items-center">
                            <img
                              src={AppConfig.CDN_Media_url+"c6a54eaa96c876d30cb45f1351feba0a.svg"}
                              className="type-img-big"
                            />
                            {/* <img src={require("../../../assets/icon/puzzle.png")} className="type-img-big" /> */}
                            <p className="text-black fw-bold ps-2">{ele.name}</p>
                            <div className="ms-auto">
                              <img src={AppConfig.CDN_Media_url+"9c60a949a5a92320ccf73d7d43ef2a29.svg"} className="w-14px" />
                            </div>
                          </div>

                          {/* <div  className=" bg-lightprime border text-prime radius v-small px-3 py-2">View Inventory</div> */}
                        </div>
                      </Link>
                      {/* <div className="row">
                        <div className="col-md-3 col-3">
                          {ele.images ? (
                            <img
                              src={
                                data.options.image_base_url +
                                data.options.sizes[3] +
                                "/" +
                                ele.images[0].url
                              }
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={
                                "https://cpimg.tistatic.com/06747380/b/4/Used-Iphone-12.jpg"
                              }
                              alt="pic"
                              className="img-fluid"
                            />
                          )}
                        </div>
                        <div className="col-md-8 col-9 ps-0">
                          <div className="row">
                            <div className="col-6">
                              <p>
                                Variants :{" "}
                                <small className="text-dark">
                                  {" "}
                                  <span className="text-dark">
                                    {ele.total_variants +
                                      "(" +
                                      ele.total_active_variants +
                                      ")"}
                                  </span>{" "}
                                </small>{" "}
                              </p>
                            </div>
                            <div className="col-6">
                              <p>
                                Addons :{" "}
                                <small className="text-dark">
                                  {" "}
                                  <span className="text-dark">
                                    {ele.total_addon_groups +
                                      "(" +
                                      ele.total_active_addon_groups +
                                      ")"}
                                  </span>
                                </small>
                              </p>
                            </div>

                            <div className="col-6">
                              <p>
                                Addon Items :
                                <small className="text-dark">
                                  {" "}
                                  <span className="text-dark">
                                    {ele.total_addon_items +
                                      "(" +
                                      ele.total_active_addon_items +
                                      ")"}
                                  </span>
                                </small>
                              </p>
                            </div>
                            <div className="col-6">
                              <p>
                                Brand :{" "}
                                <small className="text-dark">
                                  {ele.product_brand}
                                </small>
                              </p>
                            </div>
                            <div className="col-6">
                              <p>
                                Category :{" "}
                                <small className="text-dark">{ele.category}</small>
                              </p>
                            </div>
                        
                            <div className="col-12">
                              <p>
                                MRP :{" "}
                                <small className="text-dark">
                                  {" "}
                                  ₹{ele.min_mrp} - ₹{ele.max_mrp}{" "}
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-1 md-ps-0 d-sm-none d-md-block">
                          <div className="cursor center my-3">
                            <MaterialIcons
                              name="do-not-disturb"
                              size={16}
                              color="gray"
                            />
                          </div>

                          <div className="cursor center my-3">
                            <MaterialIcons
                              name="delete-outline"
                              size={16}
                              color="gray"
                            />
                          </div>

                          <div
                            className="cursor center my-3"
                            onClick={() => {
                              console.log("add variants");
                              SetModalVariants({
                          
                                is_open: true,
                                product_name: ele.name,
                                product_id: ele.id,
                                product_checked: ele.checked,

                                data: ele.variants,

                              
                              });
                            }}
                          >
                            <Feather name="edit-3" size={16} color="gray" />
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="d-flex justify-content-center">
                        <Link
                          to={
                            "/outlet/" +
                            id_value.brand_id +
                            "/" +
                            id_value.outlet_id +
                            "/product_inventory/" +
                            ele.id
                          }
                        >
                        <button type="button" className="btn btn-primary">View Inventory</button>

                        </Link>
                      </div> */}

                      {/* <button>View Inventory</button> */}
                    </div>
                  ))}
                </InfiniteScroll>
              ) : (
                <div className="card p-3 text-center">
                 <img
                    src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img"
                  />
                  <p className="v-small mb-2">No Data Found!</p>
                </div>
              )
            )}

            {/* <div className="mt-3 mb-5 pb-4">
              {next_page ? (
                <p
                  className="text-prime fw-bold text-center my-2 cursor"
                  onClick={() => {
                    load_more();
                  }}
                >
                  Load More
                </p>
              ) : null}
            </div> */}
          </div>
        </div>
      </div>
      <ModalVariants
        open={modal_variants.is_open}
        product={modal_variants.product_name}
        id={modal_variants.product_id}
        checked={modal_variants.product_checked}
        data={modal_variants.data}
        close={(data: any) => {
          console.log("Variants modl on close :", data);
          // SetModalVariants((prevValue: any) => {
          //     prevValue.is_open = false;
          //     return { ...prevValue }
          // })
          SetModalVariants({
            is_open: false,
            product_name: "",
            product_id: "",
            product_checked: "",

            // return { ...prevValue }
            data: {},
          });

          if (data.action == "create") {
            get_products(text);
          }
        }}
      />
    </div>
  );
}
