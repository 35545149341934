import React, { useState, useEffect, useRef, useMemo } from "react";
import { api } from "../../utils/Api";
import { useParams } from "react-router-dom";
// import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScroll from "react-infinite-scroller";

import Skeleton from "react-loading-skeleton";

const initial_value = {
  selected_user: { name: "" },
};

function SelectUserName({ onDataSelected, type }) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [visible_content, SetVisibleContent] = useState(false);
  const [user_type, SetUserType] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const [is_loading, SetIsLoading] = useState(false);

  const [save_data, SetSaveData] = useState(initial_value);
  const [id_value, SerIdValue] = useState(useParams());

  const [users_data, SetUsersData] = useState([]);
  const [timer, SetTimer] = useState(null);
  const [next_page, SetNextPage] = useState(false);
  const [search, SetSearch] = useState("");
  const scrollParentRef = useRef(null);

  // const [page_no, SetPageNo] = useState(1);
  const WAIT_INTERVAL = 1000; // Example value for WAIT_INTERVAL

  useEffect(() => {
    let get_data = {
      load_more: false,
      search_value: "",
      page_no: 1,
    };
    get_users(get_data, type);
    console.log(type);
    SetUserType(type);
  }, []);

  async function get_users(page_value: any, type: any) {
    setLoading(true);
    SetIsLoading(true);
    console.log(type);
    SetSearch(page_value.search_value);
    let pass_data = {};
    let response: any;
    if (type == "franchise" || type == "brand_user") {
      if (
        page_value.search_value != null &&
        page_value.search_value != "" &&
        page_value.search_value != undefined
      ) {
        pass_data = {
          get: {
            brand: id_value.brand_id,
            page: page_value.page_no,
            search: page_value.search_value,
          },
        };
      } else {
        pass_data = {
          get: {
            brand: id_value.brand_id,
            page: page_value.page_no,
          },
        };
      }
      response = await api("/brand/get_users", pass_data);
      console.log("Outlet create /brand/get_users response :", response);
      if (response?.status_code == 200) {
        if (response.response.hasOwnProperty("data")) {
          if (page_value.load_more) {
            SetUsersData((prevValue: any) => {
              response?.response?.data?.map((ele) => {
                prevValue.push(ele);
              });
              return [...prevValue];
            });
          } else {
            SetUsersData(response.response.data);
          }

          SetVisibleContent(true);
        } else {
          SetUsersData([]);
        }

        if (response.response.hasOwnProperty("next_page")) {
          SetNextPage(response.response.next_page);
        }

        SetIsLoading(false);
      }
    }
    if (type == "outlet") {
      if (
        page_value.search_value != null &&
        page_value.search_value != "" &&
        page_value.search_value != undefined
      ) {
        pass_data = {
          get: {
            brand: id_value.brand_id,
            outlet: id_value.outlet_id,
            page: page_value.page_no,
            search: page_value.search_value,
          },
        };
      } else {
        pass_data = {
          get: {
            brand: id_value.brand_id,
            outlet: id_value.outlet_id,
            page: page_value.page_no,
          },
        };
      }
      response = await api("/outlet/get_users_add_outlet", pass_data);
      console.log(response);
      if (response?.status_code == 200) {
        if (response.response.hasOwnProperty("data")) {
          if (page_value.load_more) {
            SetUsersData((prevValue: any) => {
              response?.response?.data?.map((ele) => {
                prevValue.push(ele);
              });
              return [...prevValue];
            });
          } else {
            SetUsersData(response.response.data);
          }

          SetVisibleContent(true);
        } else {
          SetUsersData([]);
        }

        if (response.response.hasOwnProperty("next_page")) {
          SetNextPage(response.response.next_page);
        }

        SetIsLoading(false);
      }
    }
    if(type == "brand_outlet"){
      if (
        page_value.search_value != null &&
        page_value.search_value != "" &&
        page_value.search_value != undefined
      ) {
        pass_data = {
          get: {
            brand: id_value.brand_id,
            page: page_value.page_no,
            search: page_value.search_value,
          },
        };
      } else {
        pass_data = {
          get: {
            brand: id_value.brand_id,
            page: page_value.page_no,
          },
        };
      }
      response = await api("/brand/get_users_add_outlet", pass_data);
      console.log("Outlet create /brand/get_users_add_outlet :", response);
      if (response?.status_code == 200) {
        if (response.response.hasOwnProperty("data")) {
          if (page_value.load_more) {
            SetUsersData((prevValue: any) => {
              response?.response?.data?.map((ele) => {
                prevValue.push(ele);
              });
              return [...prevValue];
            });
          } else {
            SetUsersData(response.response.data);
          }

          SetVisibleContent(true);
        } else {
          SetUsersData([]);
        }

        if (response.response.hasOwnProperty("next_page")) {
          SetNextPage(response.response.next_page);
        }

        SetIsLoading(false);
      }
    }
  }

  const onTextChanged = (e: any) => {
    clearTimeout(timer);
    setPage(1);
    const value: any = e.target.value;
    const updatedSelectedUser = { ...save_data.selected_user, name: value };

    SetSaveData((oldValues) => ({
      ...oldValues,
      selected_user: updatedSelectedUser,
      // user_id: item.id,
    }));
    // SetOutletData({ selected_user: updatedSelectedUser });
    console.log("Text onchange: ", value);
    let get_data: any = {
      load_more: false,
      page_no: 1,
    };
    if (value === "") {
      setPage(1);
      get_data.search_value = value;
      get_users(get_data, type);
    } else {
      SetTimer(
        window.setTimeout(() => {
          get_data.search_value = value;
          get_users(get_data, type);
        }, WAIT_INTERVAL)
      );
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    // Pass the selected user data to the parent component using the callback
    console.log("handleUserSelect",user)
    onDataSelected(user);
  };

  async function load_more(search_value = "") {
    let page_no_value = page + 1;
    let pass_data = {};

    setPage(page_no_value);
    // if (search_value != "") {
    //   pass_data = {
    //     get: {
    //       brand: id_value.brand_id,
    //       page: page_no_value,
    //       search: search_value,
    //     },
    //   };
    // } else {
    //   pass_data = {
    //     get: {
    //       brand: id_value.brand_id,
    //       page: page_no_value,
    //     },
    //   };
    // }

    let response: any;
    if (type == "franchise" || type == "brand_user") {
      if (search != null && search != "" && search != undefined) {
        pass_data = {
          get: {
            brand: id_value.brand_id,
            page: page_no_value,
            search: search,
          },
        };
      } else {
        pass_data = {
          get: {
            brand: id_value.brand_id,
            page: page_no_value,
          },
        };
      }
      response = await api("/brand/get_users", pass_data);
      console.log("Outlet create /brand/get_users response :", response);
    }
    if (type == "outlet") {
      if (search != null && search != "" && search != undefined) {
        pass_data = {
          get: {
            brand: id_value.brand_id,
            outlet: id_value.outlet_id,
            page: page_no_value,
            search: search,
          },
        };
      } else {
        pass_data = {
          get: {
            brand: id_value.brand_id,
            outlet: id_value.outlet_id,
            page: page_no_value,
          },
        };
      }
      response = await api("/outlet/get_users_add_outlet", pass_data);
    }

    SetUsersData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  return (
    <>
      <div className="row pt-3">
        {visible_content && (
          <div className="col-12">
            {/* <label 
            className="form-label" htmlFor="userName"
            >Select user</label> */}
            <input
              type="text"
              className="form-control"
              id="userName"
              placeholder="Search User,User Name"
              value={save_data?.selected_user?.name}
              onChange={(e) => {
                onTextChanged(e);
              }}
            />
          </div>
        )}
<div style={{ height: '80vh', overflowY: 'auto' }}>
        {is_loading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <div className="d-flex align-items-center mb-3" key={index}>
              <Skeleton circle={true} height={20} width={20} />
              <div className="ps-2">
                <Skeleton width={100} height={20} />
                <Skeleton width={80} height={20} />
              </div>
            </div>
          ))
        ) : users_data.length != 0 ? (
          <>
            <div
              className="mt-2 px-3 users_scroll"
              style={{ height: `${window.innerHeight * 0.8 - 20}px` }}
            >
              {users_data?.map((item, sugIndex) => (
                <div
                  className="cursor d-flex align-items-center mb-3"
                  key={sugIndex}
                  // onClick={() => {

                  onClick={() => handleUserSelect(item)}
                >
                  {item.dp_url ? (
                    <img
                      // src={
                      //   "https://cdn.1superapp.com/images/320/" +
                      //   img_src.url
                      // }
                      src={item.dp_url}
                      height={20}
                      width={20}

                      // className="cover_img_brand radius"
                    />
                  ) : (
                    <div className="pb-2">
                      <img
                        src={require("../../assets/icon/user.png")}
                        // src={
                        //   "https://cdn.1superapp.com/images/320/" +
                        //   img_src.url
                        //    src={item.dp_url}
                        height={20}
                        width={20}

                        // className="cover_img_brand radius"
                      />
                    </div>
                  )}

                  <span>
                    <p className="ps-2 text-black pb-2">{item.name}</p>
                    <p className="ps-2 text-black pb-2">@{item.user_name}</p>
                  </span>
                </div>
              ))}

              {/* <FontAwesome5 name="user-alt" size={14} color="#777" /> */}
              {next_page ? (
                <p
                  className="text-prime fw-bold text-center my-2 cursor"
                  onClick={() => {
                    load_more();
                  }}
                >
                  Load More
                </p>
              ) : null}
            </div>
          </>
        ) : (
          <div className="text-center">
            {" "}
            <p className="my-4 mt-4 ms-4">No results found</p>{" "}
          </div>
        )}
    
    </div>
      </div>
    </>
  );
}

export default SelectUserName;
