import React, { useState, useEffect, useContext } from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
  redirect,
} from "react-router-dom";

// ----------------------------------------------------------------------------->url-iD
import { QueryParamProvider } from "use-query-params";
// ----------------------------------------------------------------------------->Context-passing props
// ----------------------------------------------------------------------------->To aviod props drilling
import { DataProvider } from "./src/utils/Context/DataContext";
// ----------------------------------------------------------------------------->Bring the Data from context
import DataContext from "./src/utils/Context/DataContext";

//------------------------------------------------------------------------------->css
import "./src/assets/libraries/bootstrap/css/bootstrap.min.css";
import "./src/assets/libraries/fontawesome/css/all.min.css";
import "./src/assets/css/style.css";

//---------------------------------------------------------------------------------js
// import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/js/bootstrap.bundle.js";
// import "./src/assets/libraries/bootstrap/js/bootstrap.min.js";
// import "./src/assets/libraries/bootstrap/js/bootstrap.bundle.js";

// import "bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "@popperjs/core";

// -----------------------------------------------------------------------------> Layout
//Components
import OutletLayout from "./src/components/OutletLayout/OutletLayout";
import BrandLayout from "./src/components/BrandLayout/BrandLayout";
import FranchiseLayout from "./src/components/FranchiseLayout/FranchiseLayout";
// import MainLayout from "./src/components/MainLayout/MainLayout";

import { mobileApp } from "./src/utils/MobileApp";

//Pages
import Intro from "./src/pages/Intro/index";
import Home from "./src/pages/Home/Home";
import CreateBrand from "./src/pages/Home/CreateBrand";

//((((((((((((((((0.1))))))))))))))))-------------------------------------------->Brand
import Loader from "./src/utils/Loader";
import { usePromiseTracker } from "react-promise-tracker";
import BrandDashboard from "./src/pages/Brands/Dashboard/BrandDashboard";
import Franchise from "./src/pages/Brands/Franchise/Franchise";
import ViewFranchise from "./src/pages/Brands/Franchise/ViewFranchise";
import ViewOutlet from "./src/pages/Brands/Franchise/ViewOutlet";
import AddProducts from "./src/pages/Brands/Products/AddProducts";
import BrandOutlet from "./src/pages/Brands/Outlets/BrandOutlet";
import BrandViewOutlet from "./src/pages/Brands/Outlets/BrandViewOutlet";
import CreateBrandOutlet from "./src/pages/Brands/Outlets/CreateOutlet/CreateBrandOutlet";
import EditBrandOutlet from "./src/pages/Brands/Outlets/EditBrandOutlet";
import BrandSector from "./src/pages/Brands/Sector/BrandSector";
import BrandSettings from "./src/pages/Brands/Settings/BrandSettings";
import ManageChannels from "./src/pages/Brands/ManageChannels/ManageChannels";
import Collections from "./src/pages/Brands/Collections/Collections";
import AddCollectionProducts from "./src/pages/Brands/Collections/AddCollectionProducts";
import AddCollectionProductGroup from "./src/pages/Brands/Collections/AddCollectionProductGroup";
import CollectionProducts from "./src/pages/Brands/Collections/CollectionProducts";

import BrandUserRole from "./src/pages/Brands/UserRole/BrandUserRole";
import BrandUserRolesAdd from "./src/pages/Brands/UserRole/BrandUserRolesAdd";
import BrandUsers from "./src/pages/Brands/Users/BrandUsers";
import ProductTiming from "./src/pages/Brands/ProductTiming/ProductTiming";
//((((((((((((((((0.1))))))))))))))))-------------------------------------------->Franchise
// import Loader from "./src/utils/Loader";
// import { usePromiseTracker } from "react-promise-tracker";
import FranchiseDashboard from "./src/pages/Franchise/Dashboard/FranchiseDashboard";
// import Franchise from "./src/pages/Brands/Franchise/Franchise";
// import ViewFranchise from "./src/pages/Brands/Franchise/ViewFranchise";
// import ViewOutlet from "./src/pages/Brands/Franchise/ViewOutlet";
// import AddProducts from "./src/pages/Brands/Products/AddProducts";
import FranchiseOutlet from "./src/pages/Franchise/Outlets/FranchiseOutlet";
import FranchiseViewOutlet from "./src/pages/Franchise/Outlets/FranchiseViewOutlet";
// import BrandSector from "./src/pages/Brands/Sector/BrandSector";
// import BrandSettings from "./src/pages/Brands/Settings/BrandSettings";
import FranchiseUserRole from "./src/pages/Franchise/UserRole/FranchiseUserRole";
import FranchiseUserRolesAdd from "./src/pages/Franchise/UserRole/FranchiseUserRolesAdd";
import FranchiseUsers from "./src/pages/Franchise/Users/FranchiseUsers";

// (((((((((((((((((((((((((((0.2)))))))))))))))))))))))))))-------------------------->Outlet
import OutletDashboard from "./src/pages/Outlets/Dashboard/OutletDashboard";
import OutletProducts from "./src/pages/Outlets/Products/OutletProducts";
import OutletAddons from "./src/pages/Outlets/Addons/OutletAddons";
import OutletProductInventory from "./src/pages/Outlets/Products/OutletProductInventory";
import OutletAddonsInventory from "./src/pages/Outlets/Addons/OutletAddonsInventory";
import OutletChannels from "./src/pages/Outlets/Channels/OutletChannels";
import OutletOrders from "./src/pages/Outlets/Orders/OutletOrders";
import Orders from "./src/pages/Outlets/Orders/Orders";
import OutletOrderDetails from "./src/pages/Outlets/Orders/OutletOrderDetails";

import OutletSetting from "./src/pages/Outlets/Setting/OutletSetting";
import OutletCollection from "./src/pages/Outlets/Collection/OutletCollection";
import OutletUsers from "./src/pages/Outlets/Users/OutletUsers";
import ProductGroup from "./src/pages/Brands/Products/ProductGroup";
import Products from "./src/pages/Brands/Variants/Products";

import ProductAssigningComponent from "./src/components/ProductAssigningComponent/ProductAssigningComponent";
import ProductGroupAssign from "./src/pages/Brands/Products/ProductGroupAssign";
import ProductGroupAvailable from "./src/pages/Brands/Products/ProductGroupAvailable";
import ProductGroupPricing from "./src/pages/Brands/Products/ProductGroupPricing";
import ProductAssign from "./src/pages/Brands/Products/ProductAssign";
import ProductAvailable from "./src/pages/Brands/Products/ProductAvailable";
import ProductPricing from "./src/pages/Brands/Products/ProductPricing";
import AddonPricing from "./src/pages/Brands/Products/AddonPricing";
import AddonList from "./src/pages/Brands/Products/AddonList";
import AddonAvailable from "./src/pages/Brands/Products/AddonAvailable";
import AddonItemAvailable from "./src/pages/Brands/Products/AddonItemAvailable";
import OrderHomeLayout from "./src/components/OrderHomeLayout/OrderHomeLayout";

// import "./i18n";
// import { withTranslation } from "react-i18next";
// import en from "./src/utils/locales/en.json";

import OutletAddProducts from "./src/pages/Outlets/Products/OutletAddProducts";
import OutletProductAssign from "./src/pages/Outlets/Products/OutletProductAssign";
import OutletProductPricing from "./src/pages/Outlets/Products/OutletProductPricing";
import OutletCallReminder from "./src/pages/Brands/Outlets/OutletCallReminder";
import Settlement from "./src/pages/Brands/Settlement/Settlement";
import OutletSettlement from "./src/pages/Outlets/Settlement/OutletSettlement";

// Api
//----------------------------------------------------------------------------->Shorten the url of api
import { api } from "./src/utils/Api";
//----------------------------------------------------------------------------->To store the data of the user in cookies txt file
import Cookies from "js-cookie";
import AddProductsNew from "./src/pages/Brands/Variants/AddProductsNew";
import Gallery from "./src/pages/Brands/Gallery/Gallery";
import AvailableTiming from "./src/pages/Brands/Outlets/AvailableTiming";
import MainLayout from "./src/components/MainLayout/MainLayout";
import NoAccessPage from "./src/components/NoAccessPage/NoAccessPage";
import OrdersList from "./src/pages/Outlets/Orders/OrdersList";
import Collections1 from "./src/pages/Brands/Collections1/Collections1";
import BrandManageProducts from "./src/pages/Brands/BrandManageProducts/BrandManageProducts";
import OutletManageProducts from "./src/pages/Outlets/OutletManageProducts/OutletManageProducts";
import QrCodeList from "./src/pages/Brands/QrCode/QrCodeList"
import { AppConfig } from "./src/config";

loadScript(
  "https://maps.googleapis.com/maps/api/js?key="+AppConfig.google_map_api+"&v=weekly&libraries=places"
);

function loadScript(url: any) {
  var index: any = window.document.getElementsByTagName("script")[0];
  var script = window.document.createElement("script");
  script.id = "google_map";
  script.src = url;
  // script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

export default function App() {
  const context: any = useContext(DataContext);
  console.log("App before mount :");
  const [application, SetApplication]: any = useState({ platform: "web" });
  const [language_data, SetLanguageData]: any = useState({});
  const [selected_language, SetSelectedLanguage]: any = useState({});
  const [language_list, SetLanguageList]: any = useState([]);
  const [hasNewOrder, SetHasNewOrder]: any = useState(false);
  let res_id: any;
  

  // --------------------------------------------------------------------------useEffect hook for sideEffect
  useEffect(() => {
    console.log("App On mount :");
    // get_init();
    get_locales();
  
    idle();
    // get_hoc();
    return () => {
      clearInterval(res_id);
      SetLanguageData({});
      SetLanguageList({});
    };
  }, []);

  useEffect(() => {
    if (hasNewOrder) {
      const ring = {
        function: "startringing",
        app_type: "react_web",
      };
      mobileApp(ring);
    } else {
      const ring = {
        function: "refreshringnotifications",
        app_type: "react_web",
      };
      mobileApp(ring);
    }
  }, [hasNewOrder]);

  async function get_locales() {
    let cookie_selected_language = Cookies.get("lang");
    //--------------------------------------------------------------------------->Get the language list
    let pass_data = {};

    if (cookie_selected_language != undefined) {
      pass_data = {
        get: { lang: cookie_selected_language },
      };
    }
    //----------------------------------------------------------------------------->Promise
    let response: any = await api("/locales", pass_data);
    console.log("locales on app :", response);

    if (response?.response.hasOwnProperty("data")) {
      SetLanguageData(response?.response?.data);
    }
    if (response?.response.hasOwnProperty("languages")) {
      SetLanguageList(response?.response?.languages);
      let selected_language_value = {};
      response.response.languages.map((ele: any) => {
        if (ele.checked) {
          selected_language_value = ele;
        }
      });
      SetSelectedLanguage(selected_language_value);
    }
  }

  // async function get_init() {
  //   let response: any = await api("/init");
  //   if (response.status_code == 200) {
  //     if (response.response.hasOwnProperty("application")) {
  //       SetApplication(response.response.application);
  //       if (
  //         response.response.application.platform === "ios" ||
  //         response.response.application.platform === "android"
  //       ) {
  //         let mobileObj: any = {
  //           function: "dashboard",
  //           app_type: "react_web",
  //         };
  //         if (response.response.application.hasOwnProperty("token")) {
  //           mobileObj.userid = response.response.user_details.id;
  //           mobileObj.username = response.response.user_details.username;
  //           mobileObj.token = response.response.application.token;
  //         }
  //         if (
  //           response.response.application.hasOwnProperty("notification_token")
  //         ) {
  //           mobileObj.notification_token =
  //             response.response.application.notification_token;
  //         } else {
  //           mobileObj.notification_token = null;
  //         }
  //         mobileApp(mobileObj);
  //       }
  //       // if (response.response.application.platform === "android") {
  //       //   res_id = setInterval(function () {
  //       //     get_hoc();
  //       //   }, 10000);
  //       // }
  //     }
  //     if (response.response.hasOwnProperty("has_new_order")) {
  //       if (response.response.has_new_order.status) {
  //         let data = response.response.has_new_order;
  //         console.log("window.location :", window.location);
  //         let path = window.location.pathname;
  //         if (
  //           path == "/"
  //           // "/outlet/" + data.brand_id + "/" + data.outlet_id + "/orders/active"
  //         ) {
  //           let account_data = response.response.accounts;
  //           let brand_details: any, outlet_details: any;
  //           account_data.brands.map((ele: any) => {
  //             if (ele.id == data.brand_id) {
  //               brand_details = ele;
  //             }
  //           });
  //           account_data.outlets.map((ele: any) => {
  //             if (ele.id == data.outlet_id) {
  //               outlet_details = ele;
  //             }
  //           });
  //           let view_data_value = {
  //             name: outlet_details.name,
  //             brand_name: outlet_details.brand_name,
  //             type: "outlet",
  //             profile: brand_details.profile,
  //           };
  //           localStorage.setItem("view_data", JSON.stringify(view_data_value));
  //           window.location.href =
  //             window.location.href +
  //             "outlet/" +
  //             data.brand_id +
  //             "/" +
  //             data.outlet_id +
  //             "/" +
  //             "orders/new";
  //         }
  //       }
  //     }
  //   }
  // }

  // async function get_init() {

  //   // const navigate = useNavigate();
  //   let response: any = await api("/init");
  //   console.log("app_init :", response);
  //   if (response.status_code == 200) {
  //     // SetIsLoading(false);
  //     // SetUserData(response.response.user_details);
  //     localStorage.setItem(
  //       "user_data",
  //       JSON.stringify(response.response.user_details)
  //     );
 
  //     if (response.response.accounts.hasOwnProperty("outlets")) {
  //       // SetOutletData(response.response.accounts.outlets);
  //       console.log(response.response.accounts.outlets)
  //       if(response.response.accounts.outlets.length>0){
  //       // navigate("/orders/new");
  //       }
        
  //     }
    

  //     // if (response.response.hasOwnProperty("countries")) {
  //     //   context.SetAppData((prevValue: any) => {
  //     //     prevValue.country_data = response.response.countries;
  //     //     return { ...prevValue };
  //     //   });
  //     // }
  //   }
  // }

  
  async function idle() {
    let data: any = await api("/init/idle");
    if (data.status_code == 200) {
    }
  }

  async function get_hoc() {
    let response: any = await api("/init/hno");
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("status")) {
        SetHasNewOrder(response.response.status);
      }
    }
  }

  return (
    //----------------------------------------------------------------------------------<DataProvider>
    <DataProvider
      selected_language={selected_language}
      language_data={language_data}
      language_list={language_list}
      application={application}
    >
      {/* ((((((((((((((((((((((((((((((((Loading))))))))))))))))))))))))))))))))---------> Loading Animation */}
      <Loader promiseTracker={usePromiseTracker} />
      <BrowserRouter>
        {/* ((((((((((((((((((((((((((((((((1))))))))))))))))))))))))))))))))----------------> For url id*/}
        <QueryParamProvider ReactRouterRoute={RouteAdapter}>
          {/* ((((((((((((((((((((((((((((((((1))))))))))))))))))))))))))))))))---------> Brand */}
          <Routes>
            {/* <Route path="/" element={<MainLayout />}> */}
            <Route index element={<Home />} />
            <Route path="/intro" element={<Intro />} />
            <Route path="/create_brand" element={<CreateBrand />} />
            <Route
              path="/product_assigning"
              element={<ProductAssigningComponent />}
            />
            {/* </Route> */}
            {/* brand */}
            <Route path="brand/:brand_id/" element={<BrandLayout />}>
              <Route
                path="product_timing"
                element={<ProductTiming type="product_timing" />}
              />
              <Route
                path="product_group_timing"
                element={<ProductTiming type="product_group_timing" />}
              />
              {/* index */}
              {/* <Route path="brand/:brand_id/" element={<Navigate to="dashboard" />} /> */}
              <Route path="dashboard" element={<BrandDashboard />} />
              <Route path="franchise" element={<Franchise />} />
              <Route path="settlement" element={<Settlement />} />


              <Route
                path="create_product"
                // element={<Navigate to={ window.location.href = 'https://products.1superapp.com/seller/9/dashboard'} />}
              />

              {/* {() => {
                
                  return null;
              }}/> */}

              {/* element={window.location.assign("https://products.1superapp.com/seller/9/dashboard")} /> */}

              {/* ----------------------------------------------------------------------->settingUp URL ID*/}
              <Route
                path="viewfranchise/:franchise_id/:tab_value"
                element={<ViewFranchise />}
              />
              <Route path="viewoutlet" element={<ViewOutlet />} />

              <Route path="image_gallery" element={<Gallery />} />

              <Route path="Products" element={<Products />} />
              <Route path="product_group" element={<ProductGroup />} />

              <Route path="addproducts" element={<AddProducts />} />
              <Route
                path="product_group_assign"
                element={<ProductGroupAssign />}
              />
              <Route
                path="product_group_available"
                element={<ProductGroupAvailable />}
              />
              <Route
                path="product_group_price"
                element={<ProductGroupPricing />}
              />
              <Route path="addproducts_new" element={<AddProductsNew />} />

              <Route path="product_assign" element={<ProductAssign />} />
              <Route path="product_available" element={<ProductAvailable />} />
              <Route path="product_price" element={<ProductPricing />} />
              <Route
                path="outlet"
                element={<BrandOutlet have_header={true} />}
              />

              <Route
                path="create_brand_outlet"
                element={<CreateBrandOutlet />}
              />
              <Route
                path="create_brand_outlet/:franchise_id"
                element={<CreateBrandOutlet />}
              />
              <Route
                path="edit_brand_outlet/:outlet_id"
                element={<EditBrandOutlet />}
              />
              <Route
                path="available_timing/:outlet_id"
                element={<AvailableTiming />}
              />

              <Route
                path="view_outlet/:outlet_id/:tab_value"
                element={<BrandViewOutlet />}
              />
              <Route path="sector" element={<BrandSector />} />
              <Route path="settings" element={<BrandSettings />} />
              <Route path="manage_channels" element={<ManageChannels />} />
              <Route path="collections" element={<Collections />} />
              <Route path="collections1" element={<Collections1 />} />
              <Route path="manage_products" element={<BrandManageProducts />} />
              <Route path="qr_code" element={<QrCodeList/>} />
              <Route
                path="add_collection_products/:collect_id"
                element={<AddCollectionProducts />}
              />

              <Route
                path="add_collection_product_group/:collect_id"
                element={<AddCollectionProductGroup />}
              />
              <Route
                path="collection_products/:collect_id"
                // element={<AddCollectionProducts />}
                element={<CollectionProducts />}
              />

              <Route path="userrole" element={<BrandUserRole />} />
              <Route
                path="userrole/:page_type/:role_type/:role_id"
                element={<BrandUserRolesAdd />}
              />
              <Route path="users" element={<BrandUsers />} />
              <Route
                path="addon_price/:addon_group_id"
                element={<AddonPricing />}
              />
              <Route path="addon_list" element={<AddonList />} />
              <Route
                path="addon_group_available"
                element={<AddonAvailable />}
              />
              <Route path="addon_item_list" element={<AddonItemAvailable />} />
              <Route
                path="outlet_call_reminder/:outlet_id"
                element={<OutletCallReminder />}
              />
            </Route>
            {/* outLet */}
            <Route path="no_access" element={<NoAccessPage />} />
            <Route path="orders" element={<OrderHomeLayout type="active"/>} />
            <Route path="orders/all" element={<OrderHomeLayout type="all" />} />
              <Route path="orders/active" element={<OrderHomeLayout type="active" />} />
              <Route path="orders/new" element={<OrderHomeLayout type="new" />} />
              <Route path="orders/finished" element={<OrderHomeLayout type="finished" />} />
              <Route path="orders/cancelled" element={<OrderHomeLayout type="cancelled" />} />

            <Route
              path="outlet/:brand_id/:outlet_id"
              element={<OutletLayout />}
            >
              {/* index */}
              {/* <Route path="outlet/:brand_id/:outlet_id" element={<Navigate to="dashboard" />} /> */}
              <Route path="outlet_settlement" element={<OutletSettlement />} />
              
              <Route path="dashboard" element={<OutletDashboard />} />
              <Route path="products" element={<OutletProducts />} />
              <Route path="manage_products" element={<OutletManageProducts />} />
              <Route
                path="product_inventory/:product_id"
                element={<OutletProductInventory />}
              />

              <Route path="addons" element={<OutletAddons />} />
              <Route
                path="addons_inventory/:addons_id"
                element={<OutletAddonsInventory />}
              />

              <Route path="addproducts" element={<OutletAddProducts />} />
              <Route path="channels" element={<OutletChannels />} />
              {/* <Route path="orders/:type" element={<OutletOrders />} /> */}
              <Route path="orders" element={<Orders type="all" />} />
              <Route path="orders/all" element={<Orders type="all" />} />
              <Route path="orders/active" element={<Orders type="active" />} />
              <Route path="orders/new" element={<Orders type="new" />} />
              <Route
                path="orders/finished"
                element={<Orders type="finished" />}
              />
              <Route
                path="orders/cancelled"
                element={<Orders type="cancelled" />}
              />
              <Route
                path="order_details/:order_id"
                element={<OutletOrderDetails />}
              />

              <Route path="setting" element={<OutletSetting />} />
              <Route path="settlement" element={<Settlement />} />

              <Route path="dashboard" element={<OutletDashboard />} />
              <Route path="users" element={<OutletUsers />} />
              <Route path="collection" element={<OutletCollection />} />
              <Route path="product_assign" element={<OutletProductAssign />} />
              <Route
                path="product_pricing"
                element={<OutletProductPricing />}
              />
              <Route path="addon_price" element={<AddonPricing />} />

              <Route path="product_available" element={<ProductAvailable />} />
              <Route
                path="product_group_available"
                element={<ProductGroupAvailable />}
              />
              <Route
                path="addon_group_available"
                element={<AddonAvailable />}
              />
              <Route path="addon_item_list" element={<AddonItemAvailable />} />
            </Route>
            {/* franchise */}
            <Route
              path="franchise/:brand_id/:franchise_id"
              element={<FranchiseLayout />}
            >
              {/* index */}
              {/* <Route path="franchise/:brand_id/:franchise_id" element={<Navigate to="dashboard" />} /> */}
              <Route path="dashboard" element={<FranchiseDashboard />} />
              <Route path="outlet" element={<FranchiseOutlet />} />
              {/* <Route path="franchise" element={<Franchise />} /> */}
              {/* ----------------------------------------------------------------------->settingUp URL ID*/}
              {/* <Route
                path="viewfranchise/:franchise_id/:tab_value"
                element={<ViewFranchise />}
              /> */}
              {/* <Route path="viewoutlet" element={<ViewOutlet />} />
              <Route path="products" element={<Products />} />
              <Route path="addproducts" element={<AddProducts />} />
              <Route path="product_assign" element={<ProductAssign />} />
              <Route path="product_price" element={<ProductPricing />} /> */}
              {/* <Route path="outlet" element={<FranchiseOutlet />} />
              <Route
                path="view_outlet/:outlet_id/:tab_value"
                element={<BrandViewOutlet />}
              />
              <Route path="sector" element={<BrandSector />} />
              <Route path="settings" element={<BrandSettings />} /> */}
              <Route path="userrole" element={<FranchiseUserRole />} />
              <Route
                path="userrole/:page_type/:role_type/:role_id"
                element={<FranchiseUserRolesAdd />}
              />
              <Route path="users" element={<FranchiseUsers />} />
            </Route>
          </Routes>
        </QueryParamProvider>
      </BrowserRouter>
    </DataProvider>
  );
}

function RouteAdapter({ children }: any) {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
}
