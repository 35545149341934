import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import "./FranchiseSideMenu.css";
import DataContext from "../../utils/Context/DataContext";
import {
  AntDesign,
  Ionicons,
  Entypo,
  FontAwesome,
  FontAwesome5,
  Fontisto,
  MaterialIcons,
} from "@expo/vector-icons";

import { useTranslation } from "react-i18next";
import LocalizationContext from "../../utils/Context/LocalizationContext";

const menu_initial_value = [
  {
    id: 1,
    name: "dashboard",
    path: "dashboard",
    // img: <MaterialIcons name="dashboard" size={18} color="gray" />
    // require("../../assets/icon/svg/grid.svg"),
  },

  {
    id: 2,
    name: "outlets",
    path: "outlet",
    // img: <Entypo name="flow-tree" size={18} color="gray" />
    // require("../../assets/icon/svg/user-check.svg"),
  },
  // {
  //   id: 3,
  //   name: "user_roles",
  //   path: "userrole",
  //   img:
  //   <FontAwesome5 name="user-cog" size={18} color="gray" />,

  // },
  // require("../../assets/icon/svg/settings.svg"),
  {
    id: 4,
    name: "users",
    path: "users",
    img: <FontAwesome5 name="user-friends" size={18} color="gray" />,
    // require("../../assets/icon/svg/users.svg"),
  },
  // {
  //   id: 6,
  //   name: "SAMPLE DROPDOWN",
  //   is_open: true,
  //   children: [
  //     {
  //       id: 6.1,
  //       name: "DROPDOWN1",
  //       path: "dp1",
  //     },
  //     {
  //       id: 6.2,
  //       name: "DROPDOWN2",
  //       path: "dp2",
  //     },
  //   ],
  // },
];

export default function FranchiseSideMenu(props) {
  // const { t } = useTranslation();
  // const { t } = useContext(LocalizationContext);

  const context:any = useContext(DataContext);
  const [is_active, SetIsActive] = useState(props.active);
  const [menu_items, SetMenuItems] = useState(menu_initial_value);
  const [user_data, SetUserData] = useState({});
  const [id_value, SerIdValue] = useState(useParams());
  const [view_data, SetViewData] = useState({});
  const [width, SetWidth] = React.useState(innerWidth);
  const [current_franchise, SetCurrentFranchise]:any = useState({});

  useEffect(() => {
    console.log("BrandSideMenu On mount :", props);
    // console.log("AdminSideMenu On context :", context);
    console.log("BrandSideMenu On id_value :", id_value);

    // SetUserData(context.app_data.user_data);
    // let user_value = localStorage.getItem("user_data");
    // SetUserData(JSON.parse(user_value));
    // let view_value = localStorage.getItem("view_data");
    // SetViewData(JSON.parse(view_value));
    let view_value = localStorage.getItem("view_data");
    SetViewData(JSON.parse(view_value));
  }, []);

  useEffect(() => {
    if(context.app_data?.current_franchise?.brand_id){
      SetCurrentFranchise(context.app_data.current_franchise);
    }
  }, [context.app_data.current_franchise]);
  useEffect(() => {
    console.log("AdminSideMenu On props.active :", props);
    SetIsActive(props.active);
  }, [props.active]);

  // function dropdown_action(m_item, m_index) {
  //   let items = menu_items;
  //   items[m_index].is_open = !m_item.is_open;
  //   SetMenuItems([...items]);
  // }

  function dropdown_close() {
    let items = menu_items;
    items.map((item) => {
      if (item.hasOwnProperty("is_open")) {
        item.is_open = false;
      }
    });
    SetMenuItems([...items]);
  }

  return (
    <div className=" pb-5">
      <div className={is_active ? "sidebar active" : "sidebar"} style={{ zIndex: 4000 }}>
        <div className={is_active ? "sidebar-w" : ""}>
          <div className="navbar-profile pt-sm-0 pt-md-3">
            <div className="d-sm-block d-md-none ">
              {/* <i className="fas fa-arrow-left px-3 py-2 text-white"></i> */}
              <div className="d-flex align-items-center pt-1">
                <div className="p-2 cursor">
                  <AntDesign name="logout" size={17} color="#777" />
                </div>
                <div
                  className=" p-2 cursor ms-auto"
                  onClick={() => {
                    if (width < 786) {
                      props.menu_click(false);
                    }
                  }}
                >
                  <Ionicons name="ios-close" size={20} color="#777" />
                </div>
              </div>
            </div>
            <div className="p-2 img-radius">
              {current_franchise?.brand_profile && (
                <img src={current_franchise.brand_profile} className="prof-img" />
              )}
              {/* <img
                src={require("../../assets/img/12.jpg")}
                className="prof-img"
              /> */}
            </div>
            <h6 className="mb-0 pt-2">{current_franchise?.franchise_name}</h6>
            <p className="pt-2 text-darkgray v-small">{current_franchise?.brand_name}</p>
          </div>
          <div className="sidebar-sticky py-5 bg-fff">
            <ul>
              {menu_items.map((m_item, m_index) =>
                m_item.hasOwnProperty("children") ? (
                  <li className="sidebar-dropdown" key={m_index}>
                    <a
                      className={
                        m_item.is_open
                          ? "sidebar-dropdown-btn active"
                          : "sidebar-dropdown-btn"
                      }
                      role="button"
                      onClick={() => {
                        dropdown_action(m_item, m_index);
                      }}
                    >
                      <i className="fas fa-cogs"></i> {context.t(m_item.name)}
                      <i className="fas fa-chevron-down sidebar-dropdown-arrow"></i>
                    </a>
                    <div className="sidebar-dropdown-menu">
                      <ul>
                        {m_item.children.map((m_c_item, m_c_index) => (
                          <li key={m_c_index}>
                            <NavLink
                              className="link-loader"
                              activeclassname="link-active"
                              to={m_c_item.path}
                              onClick={() => {
                                // dropdown_close();
                                if (width < 786) {
                                  props.menu_click(false);
                                }
                              }}
                            >
                              {/* <i className="fas fa-gifts"></i>{" "} */}
                              {context.t(m_c_item.name)}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ) : (
                  <li key={m_index}>
                    <NavLink
                      className="link-loader"
                      activeclassname="link-active"
                      to={m_item.path}
                      onClick={() => {
                        // dropdown_close();
                        if (width < 786) {
                          props.menu_click(false);
                        }
                      }}
                    >
                      {/* <img src={m_item.img} className="icon-img" />{" "} */}
                      {context.t(m_item.name)}
                    </NavLink>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
