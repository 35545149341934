import React, { useState, useEffect } from "react";
// import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap.min.js";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";



let myModal : any;
export default function ModalOrderConfirmed(props) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });


  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("AddServiceModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModelDeleteUserRoles On props.open :", props);

       myModal = new bootstrap.Modal(
        document.getElementById("AddServiceModal"),
        {}
      );
      myModal.show();
      setTimeout(myModal.hide(), 5000)

    }
  }, [props.open]);

  // function deleteUserRole() {
  //   let item : any = close_data;
  //   item.action = "delete";
  //   item.value = props.data;
  //   SetCloseData(item);
  //   myModal.hide();
  // }

  return (
    <div
      className="modal fade"
      id="AddServiceModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
      <div className="modal-content">
            <div className=" full-style">
              <div className="text-center ">
                {/* <div className='circle-circle'>
                    <svg>
                        <circle cx={'70'} cy></circle>
                    </svg>


                </div> */}
                <img
                  src={require("../../../assets/img/check-mark.png")}
                  alt="check-mark"
                  className=" color-orange"
                  height="100"
                  width="100"
                />
                {/* <i className="fa-solid fa-circle-check color-orange" style={{ 'fontSize': 'xxx-large' }}></i> */}
              </div>
              <h1 className="text-center color-orange">Order Confirmed</h1>
              <h6 className="text-center color-orange ">
                Taking you to next order...
              </h6>
              
    

              {/* {setTimeout(deleteUserRole, 5000)}  */}
            </div>
          </div>
      </div>
    </div>
  );
}
