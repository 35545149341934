import React, { useState, useEffect, useContext } from "react";
import { Link, Outlet, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import DataContext from "../../../utils/Context/DataContext";
import { AppHelper } from "../../../utils/Helper";

import {
  AntDesign,
  Entypo,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
  FontAwesome,
} from "@expo/vector-icons";

import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppConfig } from "../../../config";

const initial_time = [{ start: "", end: "" }];
const week_initial_time = [
  { timeRanges: [{ start: "", end: "", day: 1 }] },
  { timeRanges: [{ start: "", end: "", day: 2 }] },
  { timeRanges: [{ start: "", day: 3, end: "" }] },
  { timeRanges: [{ start: "", day: 4, end: "" }] },
  { timeRanges: [{ start: "", day: 5, end: "" }] },
  { timeRanges: [{ start: "", day: 6, end: "" }] },
  { timeRanges: [{ start: "", day: 7, end: "" }] },
];

export default function AvailableTiming(props: any) {
  const [id_value, SetIdValue] = useState(useParams());
  const OutletContext = useOutletContext();

  const [deleted_ids, SetDeletedIds] = useState([]);
  const [id, SetId] = useState("");
  const [outlet_id, SetOutletId] = useState("");
  const [outlet_details, SetOutletDetails] = useState({});

  const [next_page, SetNextPage] = useState(false);

  const [available_diff_time, SetAvailableDiffTime] = useState(false);

  const [timings, setTimings] = useState(initial_time);
  const [week_timings, setWeekTimings] = useState(week_initial_time);
  const [outer_data, SetOuterData] = useState([]);
  const [radio_val, SetRadioVal] = useState("");

  const [page_no, SetPageNo] = useState(1);
  const [pro_index, SetProIndex] = useState(-1);

  const [transaction_able, SetTransactionAble] = useState(false);
  const [filter_data, setFilterData] = useState("");
  const context = useContext(DataContext);
  const [selected_details, SetSelectedDetails] = useState();
  // State to store the timeout ID
  const [timeoutId, setTimeoutId] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [product_list, setProductList] = useState([]);

  const [payments, setPayments] = useState([]);
  const [appliction, SetApplication] = useState([]);
  const [visible_content, SetVisibleContent] = useState(false);
  const [diff_time, SetDiffTime] = useState(false);
  const [product_time, SetProductTime] = useState(id_value.product_timing);

  const [transaction_details, SetTransactionDetails] = useState({});

  const [width, SetWidth] = useState(innerWidth);
  const [selectedApplication, setSelectedApplication] = useState("");

  const handleApplicationChange = (event) => {
    console.log(event.target.value);
    SetPageNo(1);
    let send_data = {
      page_no: 1,
    };
    ProductList(event.target.value, filter_data, send_data);
    SetOutletId(event.target.value);
    setSelectedApplication(event.target.value);
    let pass_data = {
      sort: "",
      page_no: 1,
    };
    setPayments([]);
    // SetTransactionAble(false)
    // get_categories(pass_data, filter_data,event.target.value);
  };

  // function diff_time_able() {
  //   SetDiffTime(true);
  // }
  // function diff_time_disable() {
  //   SetDiffTime(false);
  // }
  function diff_time_disable(e) {
    SetRadioVal(e.target.value);
    if (e.target.value == 1) {
      SetDiffTime(false);
    } else {
      SetDiffTime(true);
    }
  }

  //   async function ProductList(outlet_id, search, get_data) {
  //     // console.log(send_data)
  //     let pass_data = {
  //       get: {
  //         brand: id_value.brand_id,
  //         outlet: outlet_id,
  //         page: get_data.page_no,
  //         search: search,
  //         // application : app
  //         // seller_brand_id: get_data.seller_brand_id,
  //         // seller_outlet_id: get_data.seller_outlet_id,
  //         // sort: get_data.sort
  //       },
  //     };
  //     // if (context.app_data.app_from == 'seller') {
  //     // if (id_value.hasOwnProperty("brand_id")) {
  //     //   pass_data.get.brand = id_value.brand_id;
  //     // } else {
  //     //   pass_data.get.m_id = id_value.m_id;
  //     // }

  //     let response;
  //     if(props.type=="product_timing"){

  //    response = await api(
  //       "/brand/outlet_product_variant_timing_list",
  //       pass_data
  //     );
  //     }
  // else if(props.type=="product_group_timing"){
  //      response = await api(
  //       "/brand/outlet_product_timing_list",
  //       pass_data
  //     );
  //      }
  //     if (response.status_code == 200) {
  //       // setOutlets
  //       console.log(response);
  //       // setProductList(response.response.data)

  //       SetVisibleContent(true);

  //       if (response.response.hasOwnProperty("data")) {
  //         if (get_data.load_more) {
  //           setProductList((prevValue: any) => {
  //             response?.response?.data?.map((ele) => {
  //               prevValue.push(ele);
  //             });
  //             return [...prevValue];
  //           });
  //         } else {
  //           setProductList(response.response.data);

  //       SetOuterData(response.response);

  //           disable();
  //         }
  //       }
  //       if (response.response.hasOwnProperty("next_page")) {
  //         SetNextPage(response.response.next_page);
  //         SetPageNo((prevalue) => {
  //           prevalue = prevalue + 1;
  //           return prevalue;
  //         });
  //       }
  //       // SetApplication(response.response.outlets);

  //       //   console.log(response.response.applications);
  //       //   SetApplication(response.response.applications);

  //       // SetVisibleContent(true);

  //       //   if (response.response.hasOwnProperty("data")) {
  //       //     if (get_data.load_more) {
  //       //       SetApplication((prevValue: any) => {
  //       //         response?.response?.data?.map((ele) => {
  //       //           prevValue.push(ele);
  //       //         });
  //       //         return [...prevValue];
  //       //       });
  //       //     } else {
  //       //       SetApplication(response.response.data);
  //       //     }
  //       //   }

  //       //   if (response.response.hasOwnProperty("next_page")) {
  //       //     SetNextPage(response.response.next_page);
  //       //     SetPageNo((prevalue) => {
  //       //       prevalue = prevalue + 1;
  //       //       return prevalue;
  //       //     });
  //       //   }
  //       //   setPayments((prevValue) => {
  //       //     response.response.data.map((item) => {
  //       //       prevValue.push(item);
  //       //     });
  //       //     return [...prevValue];
  //       //   });
  //       //   SetNextPage(response.response.next_page);
  //       // if (response.response.hasOwnProperty("next_page")) {
  //       //   SetFilterData((prevValue: any) => {
  //       //     prevValue.next_page = response.response.next_page;
  //       //     return { ...prevValue };
  //       //   });
  //       // }
  //       // if (response.response.hasOwnProperty("sizes")) {
  //       //   SetSizeData(response.response.sizes);
  //       // }
  //       // if (response.response.hasOwnProperty("image_base_url")) {
  //       //   SetImageBaseUrl(response.response.image_base_url);
  //       // }
  //       // if (response.response.hasOwnProperty("data")) {
  //       //   let gallery_value = response.response.data;
  //       //   // gallery_value.map((ele: any) => {
  //       //   //   ele.checked = false
  //       //   // })
  //       //   if (fetch_value) {
  //       //     SetGalleryData((prevValue: any) => {
  //       //
  //       //       // response.response.data.map((ele: any) => {
  //       //       //   ele.checked = false
  //       //       //   prevValue.push(ele)
  //       //       // })
  //       //
  //       //       // prevValue = response.response.data
  //       //       let old_data = prevValue,
  //       //         resp_data = response.response.data,
  //       //         new_data = [];
  //       //       old_data.map((ele: any) => {
  //       //         new_data.push(ele);
  //       //       });
  //       //       resp_data.map((ele: any) => {
  //       //         new_data.push(ele);
  //       //       });
  //       //       prevValue = new_data;
  //       //       // prevValue.push.apply(prevValue, response.response.data)
  //       //       SetFilterData((prevValue1: any) => {
  //       //         if (prevValue.every((v_ele) => v_ele.checked == true)) {
  //       //           prevValue1.is_all_checked = true;
  //       //         } else {
  //       //           prevValue1.is_all_checked = false;
  //       //         }
  //       //         return { ...prevValue1 };
  //       //       });
  //       //       return [...prevValue];
  //       //     });
  //       //   } else {
  //       //     SetFilterData((prevValue: any) => {
  //       //       if (gallery_value.every((v_ele) => v_ele.checked == true)) {
  //       //         prevValue.is_all_checked = true;
  //       //       } else {
  //       //         prevValue.is_all_checked = false;
  //       //       }
  //       //       return { ...prevValue };
  //       //     });
  //       //     SetGalleryData(response.response.data);
  //       //   }

  //       //   // SetGalleryData(response.response.data)
  //       // }
  //     }
  //   }

  async function Save(i) {
    console.log(i);
    let deleted_ids = [];

    console.log(timings);
    timings.map((ele) => {
      if (ele.hasOwnProperty("id")) {
        deleted_ids.push(ele.id);
      }
    });

    week_timings.map((ele) => {
      ele.timeRanges.map((m_ele) => {
        if (m_ele.hasOwnProperty("id")) {
          deleted_ids.push(m_ele.id);
        }
      });
    });

    console.log(deleted_ids);
    console.log(week_timings);

    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        // page: get_data.page_no,
        // search: search,
        // application : app
        // seller_brand_id: get_data.seller_brand_id,
        // seller_outlet_id: get_data.seller_outlet_id,
        // sort: get_data.sort
      },
      post: {
        // id: id,
        delete_id: deleted_ids,
      },
    };

    let response;
    // if(props.type=="product_timing"){

    response = await api("/brand/delete_outlet_available_timing", pass_data);
    // }
    // else if(props.type=="product_group_timing"){
    //      response = await api(
    //       "/brand/delete_outlet_product_timing",
    //       pass_data
    //     );
    //      }

    if (response.status_code == 200) {
      console.log(response);

      toast.success("Saved");
      SetAvailableDiffTime(false);
      setTimings(initial_time);

      const week_ini_time = [
        { timeRanges: [{ start: "", end: "", day: 1 }] },
        { timeRanges: [{ start: "", end: "", day: 2 }] },
        { timeRanges: [{ start: "", day: 3, end: "" }] },
        { timeRanges: [{ start: "", day: 4, end: "" }] },
        { timeRanges: [{ start: "", day: 5, end: "" }] },
        { timeRanges: [{ start: "", day: 6, end: "" }] },
        { timeRanges: [{ start: "", day: 7, end: "" }] },
      ];

      setWeekTimings(week_ini_time);
      get_outlet_details();
    }
  }

  useEffect(() => {
    // Update product_time when id_value.product_timing changes

    // SetProductTime(id_value.product_timing);
    get_outlet_details();
    // return () => {
    SetDeletedIds([]);
    SetId("");
    SetOutletId("");
    SetNextPage(false);
    SetAvailableDiffTime(false);
    setTimings(initial_time);
    setWeekTimings(week_initial_time);
    SetOuterData([]);
    SetRadioVal("");
    SetPageNo(1);
    SetProIndex(-1);
    SetTransactionAble(false);
    setFilterData("");
    SetSelectedDetails();
    setTimeoutId(null);
    setOutlets([]);
    setProductList([]);
    setPayments([]);
    SetApplication([]);
    SetVisibleContent(false);
    SetDiffTime(false);

    SetTransactionDetails({});
    SetWidth(innerWidth);
    setSelectedApplication("");

    // }
  }, []);

  useEffect(() => {
    let pass_data = {
      sort: "",
      page_no: 1,
    };
    // get_categories(pass_data, filter_data,selectedApplication);
    // get_categories("from_top");
    // // return () => {
    //   SetDeletedIds([]);
    //   SetId("");
    //   SetOutletId("");
    //   SetNextPage(false);
    //   SetAvailableDiffTime(false);
    //   setTimings(initial_time);
    //   setWeekTimings(week_initial_time);
    //   SetOuterData([]);
    //   SetRadioVal("");
    //   SetPageNo(1);
    //   SetProIndex(-1);
    //   SetTransactionAble(false);
    //   setFilterData("");
    //   SetSelectedDetails();
    //   setTimeoutId(null);
    //   setOutlets([]);
    //   setProductList([]);
    //   setPayments([]);
    //   SetApplication([]);
    //   SetVisibleContent(false);
    //   SetDiffTime(false);
    //   SetProductTime(id_value.product_timing);
    //   SetTransactionDetails({});
    //   SetWidth(innerWidth);
    //   setSelectedApplication("");
    //   console.log(props.type)
    //   // }
    var currentURL = window.location.href;

    // Split the URL by "/"
    var urlParts = currentURL.split("/");

    // Get the last part of the URL
    var lastPart = urlParts[urlParts.length - 1];

    console.log(lastPart);
    // console.log(id_value);
  }, []);

  async function get_outlet_details() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
    };
    let data = await api("/brand/outlet_details", pass_data);
    console.log("outlet_details response :", data);
    if (data.status_code == 200) {
      //   SetOutletDetails(data.response.outlets[0]);
      SetOutletDetails(data.response.outlets[0]);
      const week_ini_time = [
        { timeRanges: [{ start: "", end: "", day: 1 }] },
        { timeRanges: [{ start: "", end: "", day: 2 }] },
        { timeRanges: [{ start: "", day: 3, end: "" }] },
        { timeRanges: [{ start: "", day: 4, end: "" }] },
        { timeRanges: [{ start: "", day: 5, end: "" }] },
        { timeRanges: [{ start: "", day: 6, end: "" }] },
        { timeRanges: [{ start: "", day: 7, end: "" }] },
      ];
      console.log(week_timings);
      //   SetId(ele.id);
      //   SetTransactionDetails(available_daily);
      //   SetProIndex(i);
      if (
        (data.response.outlets[0].available_daily &&
          data.response.outlets[0]?.available_daily.length > 0) ||
        data.response.outlets[0]?.available_weekly.length > 0
      ) {
        console.log("hi");
        SetRadioVal(2);
        SetDiffTime(true);
      } else {
        SetRadioVal(1);

        SetDiffTime(false);
      }
      console.log(data.response.outlets[0].available_daily);
      if (
        data.response.outlets[0]?.available_daily &&
        data.response.outlets[0]?.available_daily.length > 0
      ) {
        const formattedTimings = data.response.outlets[0]?.available_daily.map(
          (dailyTiming) => ({
            start: dailyTiming.start_time_24,
            end: dailyTiming.end_time_24,
            id: dailyTiming.id,
          })
        );

        setTimings(formattedTimings);
      } else {
        const ini_time = [{ start: "", end: "" }];
        setTimings(ini_time);
      }

      if (
        data.response.outlets[0]?.available_weekly &&
        data.response.outlets[0]?.available_weekly.length > 0
      ) {
        let new_week = week_ini_time;
        const formattedWeekTimings = new_week.map((defaultData) => {
          console.log(data.response.outlets[0].available_weekly);

          const defaultDay = defaultData?.timeRanges[0]?.day;

          const matchingData =
            data.response.outlets[0]?.available_weekly.filter(
              (data) => Number(data.day) === defaultDay
            );

          if (matchingData && matchingData.length > 0) {
            // Handle multiple matching data
            console.log(matchingData);
            let timeRanges = matchingData.map(
              ({ id, start_time_24, end_time_24 }) => ({
                start: start_time_24 || "",
                end: end_time_24 || "",
                id,
                day: defaultDay,
              })
            );

            console.log(timeRanges);

            return { timeRanges };
          } else {
            return defaultData;
          }
        });
        console.log(formattedWeekTimings);
        setWeekTimings(formattedWeekTimings);
      } else {
        console.log("hi");

        setWeekTimings(week_initial_time);
      }
      SetTransactionAble(true);
    }
  }
  function enable(ele: any, i: any) {
    const week_ini_time = [
      { timeRanges: [{ start: "", end: "", day: 1 }] },
      { timeRanges: [{ start: "", end: "", day: 2 }] },
      { timeRanges: [{ start: "", day: 3, end: "" }] },
      { timeRanges: [{ start: "", day: 4, end: "" }] },
      { timeRanges: [{ start: "", day: 5, end: "" }] },
      { timeRanges: [{ start: "", day: 6, end: "" }] },
      { timeRanges: [{ start: "", day: 7, end: "" }] },
    ];
    console.log(week_timings);
    SetId(ele.id);
    SetTransactionDetails(ele);
    SetProIndex(i);
    if ((ele?.daily && ele?.daily.length > 0) || ele?.weekly.length > 0) {
      SetRadioVal(2);
      SetDiffTime(true);
    } else {
      SetRadioVal(1);

      SetDiffTime(false);
    }
    console.log(ele.daily);
    if (ele?.daily && ele?.daily.length > 0) {
      const formattedTimings = ele?.daily.map((dailyTiming) => ({
        start: dailyTiming.start_time_24,
        end: dailyTiming.end_time_24,
        id: dailyTiming.id,
      }));

      setTimings(formattedTimings);
    } else {
      const ini_time = [{ start: "", end: "" }];
      setTimings(ini_time);
    }

    if (ele?.weekly && ele?.weekly.length > 0) {
      let new_week = week_ini_time;
      const formattedWeekTimings = new_week.map((defaultData) => {
        console.log(ele.weekly);

        const defaultDay = defaultData?.timeRanges[0]?.day;

        const matchingData = ele?.weekly.filter(
          (data) => Number(data.day) === defaultDay
        );

        if (matchingData && matchingData.length > 0) {
          // Handle multiple matching data
          console.log(matchingData);
          let timeRanges = matchingData.map(
            ({ id, start_time_24, end_time_24 }) => ({
              start: start_time_24 || "",
              end: end_time_24 || "",
              id,
              day: defaultDay,
            })
          );

          console.log(timeRanges);

          return { timeRanges };
        } else {
          return defaultData;
        }
      });
      console.log(formattedWeekTimings);
      setWeekTimings(formattedWeekTimings);
    } else {
      console.log("hi");

      setWeekTimings(week_initial_time);
    }
    SetTransactionAble(true);
  }

  function disable() {
    SetTransactionAble(false);
  }

  const handleInputChange = (e) => {
    const searchValue = e.target.value;
    setFilterData(searchValue);

    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Call your API with the search value
      SetPageNo(1);

      let send_data = {
        page_no: 1,
      };
      ProductList(outlet_id, searchValue, send_data);
      disable();
    }, 1000); // 5 milliseconds delay

    // Save the timeout ID
    setTimeoutId(newTimeoutId);
  };
  const handleTimeChange = (index, field, value, timing) => {
    const newTimings = [...timings];
    newTimings[index][field] = value;
    console.log(timing);
    const { id, ...timingWithoutId } = newTimings[index];
    newTimings[index] = timingWithoutId;
    console.log(deleted_ids);
    if (id != undefined) {
      let updatedDeletedIds = [...deleted_ids, id];

      // Set the updated deleted_ids array using the SetDeletedIds function
      SetDeletedIds(updatedDeletedIds);
    }
    setTimings(newTimings);
    // console
  };

  const addTimingField = () => {
    setTimings([...timings, { start: "", end: "" }]);
  };

  function loadMore() {
    SetPageNo((prevalue: any) => {
      let pass_data = {
        sort: "",
        page_no: prevalue + 1,
      };
      console.log(filter_data);
      get_categories(pass_data, filter_data, selectedApplication);
      return prevalue + 1;
    });
  }

  const PaymentCard = () => {
    return (
      <div className="col-md-6 sm-mt-6 sm-pb-5">
        {product_list.length != 0 ? (
          <>
            <InfiniteScroll
              dataLength={product_list.length}
              next={() => {
                let send_data = {
                  page_no: page_no,

                  load_more: true,
                };
                ProductList(outlet_id, filter_data, send_data);
              }}
              hasMore={next_page}
              loader={
                <div className="center m-5">
                  <h6>Loading...</h6>
                </div>
              }
            >
              {product_list?.map((ele, i) => (
                <div
                  onClick={() => {
                    enable(ele, i);
                    console.log("ele", ele);
                    console.log("i", i);
                    console.log("key", i);
                    SetSelectedDetails(i);
                  }}
                >
                  {/*  key={i} */}
                  <div
                    className={
                      transaction_able == true && selected_details == i
                        ? "active-shadow-layout radius  mb-2 p-3"
                        : "card mb-2 p-3"
                    }
                  >
                    {visible_content ? (
                      <div>
                        {console.log(ele.name)}
                        <div className="pb-2">
                          <div className="d-flex ">
                            <div>
                              {ele.hasOwnProperty("images") &&
                              ele.images[0] != null ? (
                                <img
                                  // src={"https://cdn.1superapp.com/images/320/" + data.image}

                                  src={AppHelper.imageSrc(
                                    outer_data.image_base_url,
                                    ele.images[0].url,
                                    { format: "webp", width: 100 }
                                  )}
                                  className="outlet-image"
                                />
                              ) : (
                                <img
                                  src={AppConfig.CDN_Image_url+"33c67a26ab58ada3ee31b91f7efb32bb.png"}
                                  className="shipment-img"
                                />
                              )}
                            </div>
                            <div className="ms-3">
                              {/* <p className="text-black">Product Name: </p> */}
                              <p className="text-black"> {ele?.name}</p>
                              <p> {ele?.description}</p>

                              {/* <p className="text-black"> {ele?.category}</p> */}
                              <div className="pb-2">
                                <div className="heading pb-2">Category:</div>
                                <div className="value">
                                  <p>{ele?.category}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    ) : (
                      [1, 2, 3, 4].map(() => (
                        <div className="col-md-6 p-1"></div>
                      ))
                    )}
                  </div>
                </div>
              ))}
            </InfiniteScroll>{" "}
          </>
        ) : (
          <div className="card h-100 w-100"> No product</div>
        )}
      </div>
    );
  };

  const handleWeekTimeChange = (dayIndex, timeIndex, property, value) => {
    const updatedWeekTimings = [...week_timings];
    updatedWeekTimings[dayIndex].timeRanges[timeIndex][property] = value;
    // newTimings[index][field] = value;
    console.log(week_timings);
    const { id, ...timingWithoutId } =
      updatedWeekTimings[dayIndex].timeRanges[timeIndex];
    console.log(timingWithoutId);
    updatedWeekTimings[dayIndex].timeRanges[timeIndex] = timingWithoutId;
    console.log(deleted_ids);
    if (id != undefined) {
      let updatedDeletedIds = [...deleted_ids, id];

      // Set the updated deleted_ids array using the SetDeletedIds function
      SetDeletedIds(updatedDeletedIds);
    }
    console.log(updatedWeekTimings);
    setWeekTimings(updatedWeekTimings);
  };

  const addWeekTimingField = (dayIndex) => {
    setWeekTimings((prevWeekTimings) => {
      const updatedWeekTimings = [...prevWeekTimings];
      updatedWeekTimings[dayIndex].timeRanges.push({
        start: "",
        end: "",
        day: dayIndex + 1,
      });
      return updatedWeekTimings;
    });
  };

  async function handleSubmit(i) {
    // Perform actions you want when the button is clicked
    console.log(i);
    let error = false;
    const updatedTimings = timings.filter(
      (timing) =>
        !timing.hasOwnProperty("id") && timing.start != "" && timing.end != ""
    );
    // const updatedWeekTimings = week_timings.filter(timing => !timing.hasOwnProperty('id'));

    timings.map((ele) => {
      if (
        (ele.start == "" && ele.end != "") ||
        (ele.start != "" && ele.end == "")
      ) {
        error = true;
      }

      if (ele.start != "" && ele.end != "" && ele.start > ele.end) {
        error = true;
      }
    });
    const updatedweekTimings = week_timings;

    const week: any = [];
    updatedweekTimings.map((element) => {
      console.log(element);

      element.timeRanges.map((subele) => {
        if (
          !subele.hasOwnProperty("id") &&
          subele.start != "" &&
          subele.end != ""
        ) {
          week.push(subele);
        }

        if (
          (subele.start == "" && subele.end != "") ||
          (subele.start != "" && subele.end == "")
        ) {
          error = true;
        }

        if (
          subele.start != "" &&
          subele.end != "" &&
          subele.start > subele.end
        ) {
          error = true;
        }
      });
    });

    console.log(updatedTimings);

    console.log(week_timings);
    console.log(deleted_ids);
    if (!error) {
      let pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: id_value.outlet_id,
        },
        post: {
          daily_timing: updatedTimings,
          weekly_timing: week,
          //   id: id,
          delete_id: deleted_ids,
        },
      };

      console.log(pass_data);

      let response;
      //   if(props.type=="product_timing"){

      response = await api("/brand/save_outlet_available_timing", pass_data);

      //       }
      //   else if(props.type=="product_group_timing"){
      //        response = await api(
      //         "/brand/save_outlet_product_timing",
      //         pass_data
      //       );
      //        }
      if (response.status_code == 200) {
        // let ele = response.response.data[0];
        // console.log(product_list);
        // const updatedProductList = [...product_list];
        // updatedProductList[i] = ele;
        // console.log(updatedProductList);
        toast.success("Saved");

        // setProductList(updatedProductList);
        get_outlet_details();
      }
    } else {
      toast.error("Check the fields properly");
    }
  }

  function daily_delete(i) {
    setTimings((prevTimings) => {
      // Create a copy of the array
      const newTimings = [...prevTimings];

      console.log(newTimings.length);
      if (newTimings.length == 1) {
        newTimings.splice(i, 1);
        newTimings.push({ start: "", end: "" });
      } else {
        newTimings.splice(i, 1);
      }

      return newTimings;
    });
    const newTimings = [...timings];

    const { id, ...timingWithoutId } = newTimings[i];
    let updatedDeletedIds = [...deleted_ids, id];

    // Set the updated deleted_ids arraysetWeekTimings using the SetDeletedIds function
    SetDeletedIds(updatedDeletedIds);
  }
  function weekly_delete(dayIndex, timeIndex) {
    const updatedWeekTimings = [...week_timings];

    // Extract id and create a copy of the time range without id
    const { id, ...timingWithoutId } =
      updatedWeekTimings[dayIndex].timeRanges[timeIndex];
    let updatedDeletedIds = [...deleted_ids, id];

    // Set the updated deleted_ids array using the SetDeletedIds function
    SetDeletedIds(updatedDeletedIds);
    // Remove the time range from the array
    if (updatedWeekTimings[dayIndex].timeRanges.length == 1) {
      updatedWeekTimings[dayIndex].timeRanges.splice(timeIndex, 1);
      updatedWeekTimings[dayIndex].timeRanges.push({
        start: "",
        end: "",
        day: dayIndex + 1,
      });
    } else {
      updatedWeekTimings[dayIndex].timeRanges.splice(timeIndex, 1);
    }

    // Log the updated arrays for debugging
    console.log(updatedWeekTimings);
    console.log(deleted_ids);

    setWeekTimings(updatedWeekTimings);
  }
  return (
    <div className="">
      <div>
        {/* Header */}
        <div
          className={
            props.have_header
              ? OutletContext
                ? "head-bar sticky-sm"
                : "head-bar  sticky-sm"
              : "p-3 border-bottom bg-fff"
          }
          style={
            props.have_header == true
              ? OutletContext == false
                ? { width: width }
                : {}
              : null
          }
        >
          {/* <div className="row align-items-center pb-2"> */}
          {/* <div className="col-md-5 col-6"> */}
          <div className="d-flex align-items-center px-2">
            <div
              className="cursor"
              onClick={() => {
                window.history.back(-1);
              }}
            >
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "6aee348fd24aa1bdf7042e6b70d1e2bc.svg"
                }
                className="type-img"
              />
            </div>
            <h6 className="mb-0 text-dark fw-bolder px-3">
              {outlet_details.name}
            </h6>
          </div>
          {/* </div> */}
          {/* <div className="col-md-7 col-6 justify-content-end d-flex align-items-center ms-auto"> */}
          {/* <button
              className="btn bg-prime text-white btn-radius btn-sm px-3"
            //   onClick={() => {
            //     // SetIsDrawerOpen(true);
            //     console.log("Add users");
            //     SetModalUser({
            //       is_open: true,
            //       type: "add",
            //       data: {},
            //     });
            //   }}
            >
              Add Users
            </button> */}
          {/* </div> */}
          {/* </div> */}
          {/* <nav className="pt-1 sticky-tabs">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <Link
              to={
                "/brand/" +
                id_value.brand_id +
                "/view_outlet/" +
                id_value.outlet_id +
                "/details"
              }
            >
              <div
                className={
                  params.tab_value == "details"
                    ? "nav-link active cursor"
                    : "nav-link cursor"
                }
                id="details-tab"
                data-bs-toggle="tab"
                data-bs-target="#details"
                role="tab"
                aria-controls="details"
                aria-selected="true"
              >
                Details
              </div>
            </Link>
            <Link
              to={
                "/brand/" +
                id_value.brand_id +
                "/view_outlet/" +
                id_value.outlet_id +
                "/users"
              }
            >
              <div
                className={
                  params.tab_value == "users"
                    ? "nav-link active cursor"
                    : "nav-link cursor"
                }
                id="users-tab"
                data-bs-toggle="tab"
                data-bs-target="#users"
                role="tab"
                aria-controls="users"
                aria-selected="false"
              >
                Users
              </div>
            </Link>
          </div>
        </nav> */}
        </div>
        <div className="mt-3 px-md-3 px-sm-0 pb-5">
          <div className="card p-3">
            {/* <h6 className="  mb-2">}</h6> */}

            <h6 className="mt-3 pt-1">Timing</h6>
            <div className="">
              <div className="py-2">
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    checked={radio_val == "1"}
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    onChange={(e) => diff_time_disable(e)}
                    value={1}
                  />
                  <label
                    className="form-check-label text-black"
                    htmlFor="flexRadioDefault1"
                  >
                    Available all the time
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    checked={radio_val == "2"}
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    onChange={(e) => diff_time_disable(e)}
                    value={2}
                  />
                  <label
                    className="form-check-label text-black"
                    htmlFor="flexRadioDefault2"
                  >
                    Available at particular time
                  </label>
                </div>

                <div className="ps-3">
                  {diff_time && (
                    <div className="">
                      <div className="mb-3 px-2 pt-3 bg-lightprime radius">
                        <div className="d-flex align-items-center pb-3">
                          <p className="text-dark fw-bold">
                            Same time for all days of the week
                          </p>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-10 row p-0">
                            <div className="col-6 ps-0">
                              <p className="ps-2 pb-1 text-darkgray">
                                Start Time
                              </p>
                            </div>
                            <div className="col-6 ps-0">
                              <p className="ps-2 pb-1 text-darkgray">
                                End Time
                              </p>
                            </div>
                          </div>
                        </div>

                        {timings.map((timing, index) => (
                          <div className="pb-3">
                            <div className="row align-items-center">
                              <div className="col-5 ps-0">
                                <input
                                  type="time"
                                  className="form-control"
                                  value={timing.start}
                                  onChange={(e) =>
                                    handleTimeChange(
                                      index,
                                      "start",
                                      e.target.value,
                                      timing
                                    )
                                  }
                                />
                              </div>
                              <div className="col-5 ps-0">
                                <input
                                  type="time"
                                  className="form-control"
                                  value={timing.end}
                                  onChange={(e) =>
                                    handleTimeChange(
                                      index,
                                      "end",
                                      e.target.value,
                                      timing
                                    )
                                  }
                                  min={timing.start}
                                  required
                                />
                              </div>
                              <div className="col-1 p-0">
                                <button
                                  className="btn btn-text text-gray p-0"
                                  onClick={() => {
                                    daily_delete(index);
                                  }}
                                >
                                  <img
                                    src={AppConfig.CDN_Media_url+"a33abb4eebe8f5def96d7da103c71806.svg"}
                                    className="w-14px"
                                  />
                                  {/* x */}
                                </button>
                              </div>
                              {index == timings.length - 1 && (
                                <div className="col-1  ps-0 pe-1">
                                  <button
                                    className="btn btn-text text-prime fw-bold p-0"
                                    onClick={addTimingField}
                                  >
                                    <img
                                      src={AppConfig.CDN_Media_url+"40b898ba05898e0e6bdc3ede61610c75.svg"}
                                      className="w-20px"
                                    />
                                  </button>
                                </div>
                              )}{" "}
                            </div>
                            {timing.start != "" &&
                              timing.end != "" &&
                              timing.start >= timing.end && (
                                <p className="extra-small p-2 text-red">
                                  End time should be greater than start time
                                </p>
                              )}
                          </div>
                        ))}
                      </div>

                      <div className="mb-3 p-2 bg-lightprime radius pt-3">
                        {console.log(week_timings)}
                        <p className="text-dark fw-bold ">
                          Different times during different days of the week
                        </p>

                        {week_timings.map((weekTiming, dayIndex) => (
                          <div key={dayIndex} className="mt-4">
                            {console.log(weekTiming?.timeRanges)}
                            <div className="d-flex align-items-center pb-2">
                              <p className="text-dark fw-bold ps-2">
                                {console.log(weekTiming?.timeRanges)}
                                {weekTiming &&
                                  weekTiming.timeRanges &&
                                  weekTiming.timeRanges.length > 0 &&
                                  weekTiming.timeRanges[0]?.day === 1 &&
                                  "Monday"}
                                {weekTiming.timeRanges[0].day === 2 &&
                                  "Tuesday"}
                                {weekTiming.timeRanges[0].day === 3 &&
                                  "Wednesday"}
                                {weekTiming.timeRanges[0].day === 4 &&
                                  "Thursday"}
                                {weekTiming.timeRanges[0].day === 5 && "Friday"}
                                {weekTiming.timeRanges[0].day === 6 &&
                                  "Saturday"}
                                {weekTiming.timeRanges[0].day === 7 && "Sunday"}
                              </p>
                            </div>
                            <div className="row align-items-center">
                              <div className="col-10 row p-0">
                                <div className="col-6 ps-0">
                                  <p className="ps-2 pb-1 text-darkgray">
                                    Start Time
                                  </p>
                                </div>
                                <div className="col-6 ps-0">
                                  <p className="ps-2 pb-1 text-darkgray">
                                    End Time
                                  </p>
                                </div>
                              </div>
                            </div>
                            {weekTiming.timeRanges.map(
                              (timeRange, timeIndex) => (
                                <div key={timeRange}>
                                  {console.log(timeRange)}
                                  <div className="row align-items-center pb-2">
                                    <div className="col-5 ps-0">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={timeRange.start}
                                        onChange={(e) =>
                                          handleWeekTimeChange(
                                            dayIndex,
                                            timeIndex,
                                            "start",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col-5 ps-0">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={timeRange.end}
                                        onChange={(e) =>
                                          handleWeekTimeChange(
                                            dayIndex,
                                            timeIndex,
                                            "end",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col-1 p-0">
                                      <button
                                        className="btn btn-text text-gray mt-2 px-0"
                                        onClick={() => {
                                          weekly_delete(dayIndex, timeIndex);
                                        }}
                                      >
                                        <img
                                          src={AppConfig.CDN_Media_url+"a33abb4eebe8f5def96d7da103c71806.svg"}
                                          className="w-14px"
                                        />
                                        {/* x */}
                                      </button>
                                    </div>
                                    {timeIndex ==
                                      weekTiming.timeRanges.length - 1 && (
                                      <div className="col-1  ps-0 pe-1">
                                        <button
                                          className="btn btn-text text-prime fw-bold px-0 mt-2"
                                          onClick={() =>
                                            addWeekTimingField(dayIndex)
                                          }
                                        >
                                          <img
                                            src={AppConfig.CDN_Media_url+"14b7bfcd4eba79625ba1165628deeb20.svg"}
                                            className="w-20px"
                                          />
                                        </button>
                                      </div>
                                    )}{" "}
                                  </div>
                                  {timeRange.start != "" &&
                                    timeRange.end != "" &&
                                    timeRange.start >= timeRange.end && (
                                      <p className="extra-small p-2 text-red">
                                        End time should be greater than start
                                        time
                                      </p>
                                    )}
                                </div>
                              )
                            )}
                          </div>
                        ))}
                        <div className="center p-2">
                          <button
                            className="btn bg-prime text-white  mt-3 ms-auto"
                            onClick={() => handleSubmit(pro_index)}
                          >
                            {/* Submit */}
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div class="form-check">
  <input class="form-check-input" type="radio"

checked={!available_diff_time}

onClick={() => diff_time_disable}
  name="exampleRadios" id="exampleRadios1" value="option1" cheked/>
  <label class="form-check-label" for="exampleRadios1">
    Default radio
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="exampleRadios" 
     onClick={() => diff_time_able}

      checked={available_diff_time}

  id="exampleRadios2" value="option2"/>
  <label class="form-check-label" for="exampleRadios2">
    Second default radio
  </label>
</div> */}
                {!diff_time && (
                  <div className="center p-2">
                    <button
                      className="btn bg-prime text-white  mt-3 ms-auto"
                      onClick={() => Save(pro_index)}
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
