import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../../utils/Api";

let myModal: any = {};

export default function ModalCallReminderUser(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [user_data, SetUserData] = useState([]);
  const [selected_user, SetSelectedUser] = useState({});

  useEffect(() => {
    console.log("ModalCallReminderUser On mount :", props);
    let myModal1: any = document.getElementById("OutletUserModal");
    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModalCallReminderUser On mount :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("OutletUserModal"),
        {}
      );
      myModal.show();
      get_users();
    }
  }, [props.open]);

  useEffect(() => {
    if (Object.keys(selected_user).length > 0) {
      add_user(selected_user);
    }
  }, [selected_user]);

  async function get_users() {
    let pass_data = {};
    pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
    };
    let response: any = await api("/brand/outlet_users", pass_data);
    console.log("Outlet create /brand/outlet_users response :", response);
    if (response.response.hasOwnProperty("users")) {
      SetUserData(response.response.users);
    }
  }

  async function add_user(get_data: any) {
    let priority_data = props.users_priority_data;
    priority_data.push(get_data.id);
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
      post: {
        outlet_user_id: get_data.id,
        priority: priority_data,
      },
    };
    let data_res: any = await api(
      "/brand/add_order_call_reminder_user",
      pass_data
    );
    console.log("add_order_call_reminder_user response :", data_res.response);
    if (data_res.status_code == 200) {
      let item: any = close_data;
      item.action = "save";
      item.value = data_res.response;
      console.log("Close data : ", item);
      SetCloseData(item);
      myModal.hide();
    }
  }

  function is_checked(get_data: any) {
    let return_data: any = false;
    // props.reminder_user.map((r_ele: any) => {
    //   return_data = user_data.some((u_ele: any) => u_ele.id == r_ele.id);
    // });
    return_data = props.reminder_user.some(
      (u_ele: any) => u_ele.id == get_data.id
    );
    return return_data;
  }

  function is_new_checked(get_data: any) {
    let return_data: any = false;
    if (selected_user.id == get_data.id) {
      return_data = true;
    }
    return return_data;
  }

  return (
    <div
      className="modal fade"
      id="OutletUserModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-fullscreen-sm-down">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Add User
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
                <h6 className="mt-1">Users List</h6>
                <div className="mt-3">
                  {user_data.map((item: any, sugIndex: any) => (
                    <div
                      className="border-bottom cursor p-2"
                      key={sugIndex}
                      // onClick={() => {
                      //   console.log("Select item:", item);
                      // }}
                    >
                      <label className="form-label row align-items-center px-0">
                        <div className="col-1 ps-0">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={(e) => {
                              console.log("add check");
                              if (e.target.checked) {
                                SetSelectedUser(item);
                              } else {
                                SetSelectedUser({});
                              }
                            }}
                            name={"user_check"}
                            checked={
                              is_checked(item) ? true : is_new_checked(item)
                            }
                            disabled={is_checked(item)}
                          />
                        </div>
                        <div className="col-11 d-flex align-items-center">
                            <img src={item.profile_picture != null ? item.profile_picture : require("../../../assets/img/profile.png")} className="w-30px img-radius" />
                          <p className="text-black ps-2">
                            {item.name}{" "}
                            <span className="text-red v-small">{item.exists ? " - User Already Exists" : null}</span>
                          </p>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
          </div>
          <div className="modal-footer">
            <div>
              <button
                type="button"
                className="btn btn-outline-secondary px-3 btn-sm"
                onClick={(event) => {
                  console.log("Close modal", event);
                  myModal.hide();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
