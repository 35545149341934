// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";

// // import bootstrap from "bootstrap/dist/js/bootstrap";
// import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
// import { api } from "../../../utils/Api";
// import { omit } from "lodash";
// import { useWizard, Wizard } from "react-use-wizard";
// const animatedComponents = makeAnimated();

// const outlet_initial_value = {
//   id: "",
// //   name: "",
//   address: "",
//   mobile: "",
//   whatsapp: "",
//   email: "",
//   selected_roles: [],
//   roles: { roles: [], custom_roles: [] },
// //   website: "",
// };

// let myModal : any = {};


// export default function ModalEditUsers(props: any) {
//     console.log(props);
//   const [close_data, SetCloseData] = useState({
//     action: "close",
//   });
//   const [id_value, SerIdValue] = useState(useParams());
//   const [outlet_data, SetOutletData] = useState(outlet_initial_value);
//   const [errors, setErrors] : any = useState({});
//   const [user_role_options, SetUserRoleOptions] = useState({});
//   const [user_errors, SetUserErrors] = useState(false);

//   useEffect(() => {
//     console.log("ModalEditOutlet On mount :", props);
//     let myModal1 : any = document.getElementById("EditOutletModal");
//     myModal1.addEventListener("hidden.bs.modal", function (event : any) {
//       console.log("event :", event);
//       SetOutletData(outlet_initial_value);
//       props.close(close_data);
//     });
//   }, []);

 
//   useEffect(() => {
//     if (props.open) {
//       console.log("ModalEditOutlet On mount :", props);
     
//       myModal = new bootstrap.Modal(
//         document.getElementById("EditOutletModal"),
//         {}
//       );

  
//       let set_value = {
//         id: props.data.id,
//         selected_roles: [],
//         roles: { roles: [], custom_roles: [] },
//         // name: props.data.name,
//         address: props.data.address,
//         mobile: props.data.mobile,
//         whatsapp: props.data.whatsapp,
//         email: props.data.email,
//         roles: props.data.roles,
//         // website: props.data.website,
//       };
//       SetOutletData(set_value);
      
//       myModal.show();
//     }

//     get_roles();
//   }, [props.open]);
// {console.log(myModal)}
//   function outlet_value_on_change(name: any, value: any) {
//     validate(name, value);
//     SetOutletData((oldValues) => ({
//       ...oldValues,
//       [name]: value,
//     }));
//   }

//   async function get_roles() {
//     let pass_data = {
//       get: {
//         brand: id_value.brand_id,
//       },
//     };
//     let data_res = await api("/brand/get_brand_roles", pass_data);
//     console.log("get_brand_roles response :", data_res.response);
//     let option_data = [];
//     Object.keys(data_res.response).map((key, index) => {
//       let push_data = {};
//       if (key == "custom_roles") {
//         push_data.label = "Custom Roles";
//         for (var i = 0; i < data_res.response[key].length; i++) {
//           data_res.response[key][i].type = "custom_roles";
//         }
//         push_data.options = data_res.response[key];
//         option_data.push(push_data);
//       } else if (key == "roles") {
//         push_data.label = "Roles";
//         for (var i = 0; i < data_res.response[key].length; i++) {
//           data_res.response[key][i].type = "roles";
//         }
//         push_data.options = data_res.response[key];
//         option_data.push(push_data);
//       }
//     });
//     console.log("Option Data :", option_data);
//     SetUserRoleOptions(option_data);
//     if (props.type == "edit" || props.type == "view") {
//       let role_set_value = [];
//       if (props.data.value.hasOwnProperty("roles")) {
//         props.data.value.roles.map((role_item, role_index) => {
//           role_set_value.push({
//             label: role_item.name,
//             value: role_item.role_id,
//             type: "roles",
//           });
//         });
//       }
//       if (props.data.value.hasOwnProperty("custom_roles")) {
//         props.data.value.custom_roles.map((role_item, role_index) => {
//           role_set_value.push({
//             label: role_item.name,
//             value: role_item.role_id,
//             type: "custom_roles",
//           });
//         });
//       }
//       SetOutletData((oldValues) => ({
//         ...oldValues,
//         selected_roles: role_set_value,
//       }));
//     }
//   }

//   async function edit_outlet() {
//     console.log("edit_outlet data :", outlet_data);
//     let post_value = {
//     //   name: outlet_data.name,
//       address: outlet_data.address,
//       mobile: outlet_data.mobile,
//       whatsapp: outlet_data.whatsapp,
//       email: outlet_data.email,
//       user_id:outlet_data.id,
//       roles: {},
    
//     //   website: outlet_data.website,
//     };
//     let roles_value = {
//       roles: [],
//       custom_roles: [],
//     };
//     outlet_data.selected_roles?.map((item, index) => {
//       if (item.type == "roles") {
//         roles_value.roles.push(item.value);
//       } else {
//         roles_value.custom_roles.push(item.value);
//       }
//     });
//     post_value.roles = roles_value;
//     let pass_data = {
//       get: {
//         brand: id_value.brand_id,
//         // outlet: outlet_data.id,
//       },
//       post: post_value,
//     };
//     console.log("pass_data :", pass_data);

//     let data_res : any = await api("/brand/edit_brand_user", pass_data);
//     console.log("edit_outlet response :", data_res.response);
//     console.log("edit_outlet response :", data_res.status_code);
//     if (data_res.status_code == 200) {
//       console.log("/outlet/edit_service status_code 200");
//       let item : any = close_data;
//       item.action = "edit";
//       item.value = data_res.response.brand_users[0];
//       item.index = props.index;
//       SetCloseData(item);
//       myModal.hide();
//     }
//     // if (data_res.status_code == 201) {
//     //   let item = close_data;
//     //   item.action = "save";
//     //   let outlet_value = {
//     //     id: data_res.response.id,
//     //     name: outlet_data.name,
//     //     address: outlet_data.address,
//     //     mobile: outlet_data.mobile,
//     //   };
//     //   item.value = outlet_value;
//     //   console.log("Close data : ", item);
//     //   SetCloseData(item);
//     //   SetOutletData(outlet_initial_value);
//     //   myModal.hide();
//     // }
//   }

//   function validate(name: any, value: any) {
//     //A function to validate each input values
//     console.log("name :", name);
//     console.log("value  :", value);
//     switch (name) {
//       case "name":
//         if (value.length <= 2) {
//           // we will set the error state

//           setErrors({
//             ...errors,
//             name: "Outlet name atleast have 3 letters",
//           });
//         } else {
//           let newObj = omit(errors, "name");
//           setErrors(newObj);
//         }
//         break;

//       case "address":
//         if (value.length <= 0) {
//           setErrors({
//             ...errors,
//             address: "Enter valid address",
//           });
//         } else {
//           let newObj = omit(errors, "address");
//           setErrors(newObj);
//         }
//         break;

//       case "mobile":
//         if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
//           setErrors({
//             ...errors,
//             mobile: "Enter valid mobile number",
//           });
//         } else {
//           let newObj = omit(errors, "mobile");
//           setErrors(newObj);
//         }
//         break;

//       case "whatsapp":
//         if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
//           setErrors({
//             ...errors,
//             whatsapp: "Enter valid whatsapp number",
//           });
//         } else {
//           let newObj = omit(errors, "whatsapp");
//           setErrors(newObj);
//         }
//         break;

//       case "email":
//         if (
//           !new RegExp(
//             /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//           ).test(value)
//         ) {
//           setErrors({
//             ...errors,
//             email: "Enter email not vaild",
//           });
//         } else {
//           let newObj = omit(errors, "email");
//           setErrors(newObj);
//         }
//         break;

//       case "website":

//         if (!new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/).test(value)) {
//           setErrors({
//             ...errors,
//             website: "Enter website not vaild",
//           });
//         } else {
//           let newObj = omit(errors, "website");
//           setErrors(newObj);
//         }
//         break;

//       default:
//         break;
//     }
//   };

 


//   return (
//     <div
//       className="modal fade"
//       id="EditOutletModal"
//       tabIndex={-1}
//       aria-labelledby="ModalLabel"
//       aria-hidden="true"
//       data-bs-backdrop="static"
//     >
//       <div className="modal-dialog">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h6 className="  modal-title" id="ModalLabel">
//               Edit Outlet
//             </h6>
//             <button
//               type="button"
//               className="btn-close"
//               aria-label="Close"
//               onClick={() => {
//                 console.log(close_data);

//                 let item = close_data;
//                 item.action = "close";
//                 SetCloseData(item);
//                 myModal.hide();
//               }}
//             ></button>
//           </div>
//           <div className="modal-body">
//             <div className="row">
//               {/* <div className="col-12 col-md-6 mt-2">
//                 <label className="form-label">Outlet Name</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={outlet_data.name}
//                   onChange={(e) => {
//                     outlet_value_on_change("name", e.target.value);
//                   }}
//                 />
//                 {errors.name && (
//                   <small className="text-red">
//                     {errors.name}
//                   </small>
//                 )}
//               </div> */}
//               <div className="col-12 col-md-6 mt-2">
//                 <label className="form-label">Address</label>
//                 <textarea
//                   rows={3}
//                   className="form-control"
//                   value={outlet_data.address}
//                   onChange={(e) => {
//                     outlet_value_on_change("address", e.target.value);
//                   }}
//                 ></textarea>
//                 {errors.address && (
//                   <small className="text-red">
//                     {errors.address}
//                   </small>
//                 )}
//               </div>
//               <div className="col-12 col-md-6 mt-2">
//                 <label className="form-label">Mobile Number</label>
//                 {console.log(outlet_data)}
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={outlet_data.mobile}
            
//                   onChange={(e) => {
//                     if (e.target.value.length == 11) return false;
//                     let val = e.target.value.slice(0, 10);
//                     outlet_value_on_change("mobile", val);
//                   }}
//                 />
//                 {errors.mobile && (
//                   <small className="text-red">
//                     {errors.mobile}
//                   </small>
//                 )}
//               </div>

//               <div className="col-12 col-md-6 mt-2">
//                     <label className="form-label">Select Role</label>
//                     <Select
//                       // closeMenuOnSelect={false}
//                       components={animatedComponents}
//                       className="basic-multi-select"
//                       classNamePrefix="select"
//                       placeholder="Select Role"
//                       isMulti
//                       Group
//                       value={outlet_data.selected_roles}
//                       options={user_role_options}
//                       onChange={(e, action) => {
//                         console.log("User Role on change e :", e);
//                         console.log("User Role on change action :", action);
//                         SetOutletData((oldValues) => ({
//                           ...oldValues,
//                           selected_roles: e,
//                         }));
//                         outlet_value_on_change("role", e);
//                         SetUserErrors(false);
//                       }}
//                       isDisabled={props.type == "view" ? true : false}
//                     />
//                     {user_errors ? (
//                       <small className="text-red">* Required fields</small>
//                     ) : null}
//                   </div>
//               <div className="col-12 col-md-6 mt-2">
//                 <label className="form-label">Whatsapp Number</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={outlet_data.whatsapp}
//                   onChange={(e) => {
//                     if (e.target.value.length == 11) return false;
//                     let val = e.target.value.slice(0, 10);
//                     outlet_value_on_change("whatsapp", val);
//                   }}
//                 />
//                 {errors.whatsapp && (
//                   <small className="text-red">
//                     {errors.whatsapp}
//                   </small>
//                 )}
//               </div>
//               <div className="col-12 col-md-6 mt-2">
//                 <label className="form-label">Email</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={outlet_data.email}
//                   onChange={(e) => {
//                     outlet_value_on_change("email", e.target.value);
//                   }}
//                 />
//                 {errors.email && (
//                   <small className="text-red">
//                     {errors.email}
//                   </small>
//                 )}
//               </div>
//               {/* <div className="col-12 col-md-6 mt-2">
//                 <label className="form-label">Website</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={outlet_data.website}
//                   onChange={(e) => {
//                     outlet_value_on_change("website", e.target.value);
//                   }}
//                 />
//                 {errors.website && (
//                   <small className="text-red">
//                     {errors.website}
//                   </small>
//                 )}
//               </div> */}
//             </div>
//           </div>
//           <div className="modal-footer">
//             <div>
//               <button
//                 type="button"
//                 className="btn btn-light-outline"
//                 onClick={() => {
//                   edit_outlet();
//                 }}
//               >
//                 Update
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }





import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import { omit } from "lodash";

const animatedComponents = makeAnimated();

const franchise_initial_value = {
  selected_user: {
    id: "",
    name: "",
  },
  user_id: "",
  selected_roles: [],
  roles: { roles: [], custom_roles: [] },
  address: "",
  mobile: "",
  whatsapp: "",
  email: "",
};

let myModal = {};

export default function ModalEditUsers(props: any) {
  console.log(props);
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());

  const [franchise_data, SetFranchiseData] = useState(franchise_initial_value);
  const [user_role_options, SetUserRoleOptions] = useState({});
  const [text, setText] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [user_data, SetUserData] = useState([]);
  const [next_page, SetNextPage] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const [new_franchise_id, SetNewFranchiseId] = useState(null);
  const [modal_type, SetModalType] = useState("");
  //FranchiseData End

  useEffect(() => {
    console.log("ModalFranchiseUser On mount :", props);
    let myModal1 = document.getElementById("FranchiseUserModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      SetFranchiseData(franchise_initial_value);
      setText("");
      SetPageNo(1);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModalFranchiseUser On mount :", props);

      myModal = new bootstrap.Modal(
        document.getElementById("FranchiseUserModal"),
        {}
      );
      myModal.show();
      SetModalType(props.type);
      get_roles();
      get_users(page_no);
      if (props.type == "edit" || props.type == "view") {
        let set_data = {
          // user_id: props.data.value.user_id,
          user_id: props.data.value.id,

          selected_user: {
            id: props.data.value.user_id,
            name: props.data.value.user,
          },
          selected_roles: [],
          roles: { roles: [], custom_roles: [] },
          address:
            props.data.value.hasOwnProperty("address") &&
            props.data.value.address != null
              ? props.data.value.address
              : "",
          mobile:
            props.data.value.hasOwnProperty("mobile") &&
            props.data.value.mobile != null
              ? props.data.value.mobile
              : "",
          whatsapp:
            props.data.value.hasOwnProperty("whatsapp") &&
            props.data.value.whatsapp != null
              ? props.data.value.whatsapp
              : "",
          email:
            props.data.value.hasOwnProperty("email") &&
            props.data.value.email != null
              ? props.data.value.email
              : "",
        };
        console.log("On edit franchise data :", set_data);
        SetFranchiseData(set_data);
      }
    }
  }, [props.open]);

  async function get_roles() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let data_res = await api("/brand/get_brand_roles", pass_data);
    console.log("get_franchise_roles response :", data_res.response);
    let option_data = [];
    Object.keys(data_res.response).map((key, index) => {
      let push_data = {};
      if (key == "custom_roles") {
        push_data.label = "Custom Roles";
        for (var i = 0; i < data_res.response[key].length; i++) {
          data_res.response[key][i].type = "custom_roles";
        }
        push_data.options = data_res.response[key];
        option_data.push(push_data);
      } else if (key == "roles") {
        push_data.label = "Roles";
        for (var i = 0; i < data_res.response[key].length; i++) {
          data_res.response[key][i].type = "roles";
        }
        push_data.options = data_res.response[key];
        option_data.push(push_data);
      }
    });
    console.log("Option Data :", option_data);
    SetUserRoleOptions(option_data);
    if (props.type == "edit" || props.type == "view") {
      let role_set_value = [];
      if (props.data.value.hasOwnProperty("roles")) {
        props.data.value.roles.map((role_item, role_index) => {
          role_set_value.push({
            label: role_item.name,
            value: role_item.role_id,
            type: "roles",
          });
        });
      }
      if (props.data.value.hasOwnProperty("custom_roles")) {
        props.data.value.custom_roles.map((role_item, role_index) => {
          role_set_value.push({
            label: role_item.name,
            value: role_item.role_id,
            type: "custom_roles",
          });
        });
      }
      SetFranchiseData((oldValues) => ({
        ...oldValues,
        selected_roles: role_set_value,
      }));
    }
  }

  async function get_users(page_value: any, search_value = null) {
    let pass_data = {};
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          franchise: id_value.franchise_id,
          page: page_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          franchise: id_value.franchise_id,
          page: page_value,
        },
      };
    }
    let response = await api("/brand/get_users_add_franchise", pass_data);
    console.log(
      "Franchise create /brand/get_users_add_franchise response :",
      response
    );
    if (response.response.data) {
      SetUserData(response.response.data);
    }
    SetNextPage(response.response.next_page);
  }

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const value = e.target.value;
    setText(value);
    console.log("Text onchange: ", value);
    if (value == "") {
      let page_no_value = 1;
      SetPageNo(page_no_value);
      get_users(page_no_value);
    } else {
      SetTimer(
        setTimeout(() => {
          get_users(1, value);
        }, WAIT_INTERVAL)
      );
    }
  }

  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != "") {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          franchise: id_value.franchise_id,
          page: page_no_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          franchise: id_value.franchise_id,
          page: page_no_value,
        },
      };
    }
    let response = await api("/brand/get_users_add_franchise", pass_data);
    console.log("Users get_users_add_franchise response :", response);
    SetUserData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  function franchise_value_on_change(name: any, value: any) {
    SetFranchiseData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  async function add_user() {
    console.log("add_user data :", franchise_data);
    let post_value = {
      user_id: franchise_data.user_id,
      roles: {},
      address: franchise_data.address,
      mobile: franchise_data.mobile,
      whatsapp: franchise_data.whatsapp,
      email: franchise_data.email,
    };
    let roles_value = {
      roles: [],
      custom_roles: [],
    };
    franchise_data.selected_roles.map((item, index) => {
      if (item.type == "roles") {
        roles_value.roles.push(item.value);
      } else {
        roles_value.custom_roles.push(item.value);
      }
    });
    post_value.roles = roles_value;
    console.log("post_value :", post_value);
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        franchise: id_value.franchise_id,
      },
      post: post_value,
    };
    let data_res = await api("/brand/add_franchise_user", pass_data);
    console.log("add_franchise_user response :", data_res.response);
    if (data_res.status_code == 201) {
      let item = close_data;
      item.action = "save";
      item.value = data_res.response.users[0];
      console.log("Close data : ", item);
      SetCloseData(item);
      SetFranchiseData(franchise_initial_value);
      myModal.hide();
    }
  }

  async function update_user() {
    console.log("update_user :", franchise_data);
    let post_value = {
      user_id: franchise_data.user_id,
      roles: {},
      address: franchise_data.address,
      mobile: franchise_data.mobile,
      whatsapp: franchise_data.whatsapp,
      email: franchise_data.email,
    };
    let roles_value = {
      roles: [],
      custom_roles: [],
    };
    franchise_data.selected_roles.map((item, index) => {
      if (item.type == "roles") {
        roles_value.roles.push(item.value);
      } else {
        roles_value.custom_roles.push(item.value);
      }
    });
    post_value.roles = roles_value;
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        // franchise: id_value.franchise_id,
      },
      post: post_value,
    };
    console.log("pass_data :", pass_data);

    let data_res = await api("/brand/edit_brand_user", pass_data);
    console.log("edit_franchise_user response :", data_res.response);
    if (data_res.status_code == 200) {
      let item = close_data;
      item.action = "edit";
      item.value = data_res.response.brand_users[0];
      item.index = props.data.index;
      console.log("Close data : ", item);
      SetCloseData(item);
      SetFranchiseData(franchise_initial_value);
      myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="FranchiseUserModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-fullscreen-sm-down">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              {modal_type == "add"
                ? "Add User"
                : modal_type == "edit"
                ? "Edit User"
                : "View User"}
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div>
              {userOpen ? (
                // <div className="row">
                //   <div className="col-12">
                //     <label>Select user</label>
                //     <input
                //       type="text"
                //       className="form-control"
                //       placeholder="Select User Name"
                //       defaultValue={text}
                //       onChange={(e) => {
                //         onTextChanged(e);
                //       }}
                //     />
                //   </div>

                //   <h6 className="mt-3">Available Users</h6>
                //   <div className="mt-2">
                //     {user_data.map((item, sugIndex) => (
                //       <div
                //         className="cursor"
                //         key={sugIndex}
                //         onClick={() => {
                //           if (!item.exists) {
                //             SetFranchiseData((oldValues) => ({
                //               ...oldValues,
                //               selected_user: item,
                //               user_id: item.id,
                //             }));
                //             setUserOpen(false);
                //           }

                //           console.log("Select item:", item);
                //         }}
                //       >
                //         <p>
                //           <i className="fas fa-user"></i> {item.name}{" "}
                //           {item.exists ? " - User Already Exists" : null}
                //         </p>
                //       </div>
                //     ))}
                //   </div>
                //   {next_page ? (
                //     <p
                //       onClick={() => {
                //         load_more();
                //       }}
                //     >
                //       Load More
                //     </p>
                //   ) : null}
                // </div>
                <></>
              ) : (
                <div>
                  {modal_type == "view" || "edit" ? (
                    <div className="row">
                      <div className="col-12 mt-2">
                        <label htmlFor="userName" className="form-label">
                          User Name
                          {console.log(franchise_data)}
                        </label>
                        <div
                          onClick={() => {
                            if (props.type != "edit") {
                              setUserOpen(true);
                            }
                          }}
                          className="cursor"
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="userName"
                            disabled={
                              props.type == "edit"
                                ? true
                                : props.type == "view"
                                ? true
                                : false
                            }
                            value={franchise_data.selected_user.name}
                            placeholder="Select User Name"
                            onChange={(e) => {}}
                            readOnly={modal_type == "view" ? true : false}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mt-2">
                        <label className="form-label">Select Role</label>
                        <Select
                          // closeMenuOnSelect={false}
                          components={animatedComponents}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Role"
                          isMulti
                          Group
                          value={franchise_data.selected_roles}
                          options={user_role_options}
                          readOnly={modal_type == "view" ? true : false}
                          onChange={(e, action) => {
                            console.log("User Role on change e :", e);
                            console.log("User Role on change action :", action);
                            SetFranchiseData((oldValues) => ({
                              ...oldValues,
                              selected_roles: e,
                            }));
                          }}
                          isDisabled={props.type == "view" ? true : false}
                        />
                      </div>
                      <div className="col-12 col-md-6 mt-2">
                        <label className="form-label">Address</label>
                        <textarea
                          rows={3}
                          value={franchise_data.address}
                          className="form-control"
                          readOnly={modal_type == "view" ? true : false}
                          onChange={(e) => {
                            franchise_value_on_change("address", e.target.value);
                          }}
                        ></textarea>
                      </div>
                      <div className="col-12 col-md-6 mt-2">
                        <label className="form-label">Mobile Number</label>
                        <input
                          type="number"
                          className="form-control"
                          value={franchise_data.mobile}
                          readOnly={modal_type == "view" ? true : false}
                          onChange={(e) => {
                            franchise_value_on_change("mobile", e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-6 mt-2">
                        <label className="form-label">Whatsapp Number</label>
                        <input
                          type="number"
                          value={franchise_data.whatsapp}
                          className="form-control"
                          readOnly={modal_type == "view" ? true : false}
                          onChange={(e) => {
                            franchise_value_on_change("whatsapp", e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-6 mt-2">
                        <label className="form-label">Email</label>
                        <input
                          type="text"
                          value={franchise_data.email}
                          className="form-control"
                          readOnly={modal_type == "view" ? true : false}
                          onChange={(e) => {
                            franchise_value_on_change("email", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            {userOpen ? (
              <div>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm  px-3 btn-radius"
                  onClick={() => {
                    setUserOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                {modal_type == "add" ? (
                  <button
                    type="button"
                    className="btn bg-prime text-white px-3 btn-sm btn-radius"
                    onClick={() => {
                      add_user();
                    }}
                  >
                    Add
                  </button>
                ) : modal_type == "edit" ? (
                  <button
                    type="button"
                    className="btn bg-prime text-white px-3 btn-sm btn-radius"
                    onClick={() => {
                      update_user();
                    }}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm px-3 btn-radius"
                    onClick={(event) => {
                      console.log("Close modal", event);
                      SetCloseData(event);
                      myModal.hide();
                    }}
                  >
                    Close
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
