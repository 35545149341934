// ProductGroupAssigningAvailableComponent

import React, {
  useState,
  useEffect,
  useContext,
  useSetState,
  useLayoutEffect,
  useRef,
} from "react";
import DataContext from "../../utils/Context/DataContext";
import { Link, useParams } from "react-router-dom";
import { Table, Column, HeaderCell, Cell, ColumnGroup } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";



import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import Slider from "@material-ui/core/Slider";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../utils/Api";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import AssignAvailableFilterComponent from "../AssignAvailableFilterComponent/AssignAvailableFilterComponent";
import toast, { Toaster } from "react-hot-toast";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import { AppConfig } from "../../config";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from 'react-router-dom';

import InfiniteScroll from "react-infinite-scroll-component";

function valuetext(value: any) {
  return `${value}°C`;
}

const animatedComponents = makeAnimated();
const handleDragStart = (e: any) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  200: { items: 2 },
  300: { items: 3 },
  350: { items: 3 },
  548: { items: 4 },
  720: { items: 5 },
};

export default function ProductGroupAssigningAvailableComponent(props: any) {
  const context:any = useContext(DataContext);

  const [table_loading, SetTableLoading] = useState(false);
  const [checked, SetChecked] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [width, SetWidth] = useState(innerWidth);
  const [id_value, SetIdValue] = useState(useParams());
  const [is_loading, SetIsLoading] = useState(true);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [value, setValue] = useState(30);
  const rows = 5; // Number of skeleton rows
  const columns = 2; // Number of skeleton columns
  ////////////////////////////////////////////////////////////////    15/10/2022
  const [filter_data, SetFilterData] = useState({
    page_no: 1,
    next_page: false,
    type: { label: "outlet", value: "outlet" },
    type_option: [
      { label: "outlet", value: "outlet" },
      { label: "channel", value: "channel" },
    ],
    view: { label: "All", value: "all" },
    view_option: [
      { label: "All", value: "all" },
      { label: "New", value: "new" },
      { label: "Active", value: "active" },
      { label: "Unavailable", value: "unavailable" },
      { label: "Paused", value: "paused" },
    ],
    outlets: [],
    channels: [],
    custom_channels: [],
    count: { label: "All", value: "all" },
    count_option: [
      { label: "All", value: "all" },
      { label: "10", value: "10" },
      { label: "20", value: "20" },
      { label: "30", value: "30" },
    ],
    filter_products: {
      own_products: false,
      others_products: false,
    },
    search: "",
    sort: { label: "asc", value: "asc" },
    sort_option: [
      { label: "asc", value: "asc" },
      { label: "desc", value: "desc" },
    ],
    filter_brand: [],
    filter_brand_options: [],
    filter_categories: [],
    filter_categories_options: [],
    outlets_options: [],
    channels_options: [],
    custom_channels_options: [],
    min_mrp: "",
    max_mrp: "",
  });
  const [product_data, SetProductData] = useState([]);
  const [outlet_data, SetOutletData] = useState([]);
  const [channel_data, SetChannelData] = useState([]);
  const [all_data, SetAllData] = useState([]);
  const [all_data_saftey, SetAllDataSaftey] = useState([]);
  const [new_checked, SetNewChecked] = useState({
    product_group: [],
    variants: [],
    addon_groups: [],
    addon_items: [],
  });
  const [new_checked1, SetNewChecked1] = useState({
    product_group: {},
    variants: {},
    addon_groups: {},
    addon_items: {},
  });
  const [new_disabled, SetNewDisabled] = useState({
    product_group: [],
    variants: [],
    addon_groups: [],
    addon_items: [],
  });
  const [active_tab, SetActiveTab] = useState({ value: "All", open: false });
  const [initial_render, SetInitialRender] = useState(true);
  const ref = useRef(null);
  const [width_ref, setWidthRef] = useState(0);
  const [height_ref, setHeightRef] = useState(0);
  const [numbers, setNumbers] = useState([]);
  const [bottom_sheet_data, SetBottomSheetData] = useState({
    is_open: false,
    data: {},
  });
  const [uncheck_radio_value, SetUncheckRadioValue] = useState({
    checked_value: "",
    other_value: "",
  });
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setWidthRef(ref.current.offsetWidth);
    setHeightRef(ref.current.offsetHeight);
  }, [numbers]);

  useEffect(() => {
    SetWidth(innerWidth);
    console.log("width :", width);
  }, [props.is_menu_open]);

  useEffect(() => {
    console.log("Add Product On mount :", props);
    // if(context.app_data.pages.hasOwnProperty('17')){
    //   if(context.app_data.pages[17]){
        if (props.type == "available") {
          get_available_products(filter_data, false);
        } else {
          console.log("calling in useEffect[]");
          get_products(filter_data, false);
        }
    
        function handleWindowResize() {
          setWidthRef(ref.current.offsetWidth);
          setHeightRef(ref.current.offsetHeight);
        }
    
        window.addEventListener("resize", handleWindowResize);
    
        return () => {
          window.removeEventListener("resize", handleWindowResize);
        };
    //   }else{
    //     console.log("hi")
    //     navigate("/no_access");
  
    //   }
    // }else{
    //   SetIsLoading(true)
    // }
 
  }, []
  // [context.app_data.pages?.[17]]
);

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  function filter_fun(anchor: any, open: any, event: any) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  }

  async function submit_product_data() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        data: product_data,
      },
    };
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    console.log("pass_data :", pass_data);
    let response: any = await api(
      "/brand/save_outlet_products",
      pass_data,
      false,
      false,
      "raw_data"
    );
    console.log("/brand/save_outlet_products response :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("data")) {
        get_products(filter_data, false);
      }
    }
  }

  useEffect(() => {
    if (initial_render) {
      SetInitialRender(false);
    } else {
      if (filter_data.search == "") {
        console.log("calling in search change empty");
        if (props.type == "assign") {
          get_products(filter_data, false);
        } else {
          get_available_products(filter_data, false);
        }
      } else {
        const timeOutId = setTimeout(
          () => triggerItemChange(filter_data.search),
          500
        );
        return () => clearTimeout(timeOutId);
      }
    }
  }, [filter_data.search]);

  function triggerItemChange(value: any) {
    SetFilterData((prevValue: any) => {
      prevValue.page_no = 1;
      if (props.type == "assign") {
        get_products(prevValue, false);
      } else {
        get_available_products(prevValue, false);
      }
      return { ...prevValue };
    });
  }

  async function get_filter_data() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        product_categories: 1,
        product_brand: 1,
        all_outlets: 1,
        all_channels: 1,
      },
    };
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    console.log("get_filter_data pass_data :", pass_data);
    let response = await api("/init/get", pass_data);
    console.log("/init/get response :", response);
    if (response.status_code == 200) {
      SetFilterData((prevValue: any) => {
        if (response.response.hasOwnProperty("product_categories")) {
          response.response.product_categories.map((c_ele: any) => {
            c_ele.label = c_ele.name;
            c_ele.value = c_ele.id;
          });
          prevValue.filter_categories_options =
            response.response.product_categories;
        }
        if (response.response.hasOwnProperty("product_brands")) {
          prevValue.filter_brand_options = response.response.product_brands;
        }
        if (response.response.hasOwnProperty("outlets")) {
          prevValue.outlets_options = response.response.outlets;
        }
        if (response.response.hasOwnProperty("channels")) {
          let system_data = [],
            custom_data = [];
          response.response.channels.map((c_ele: any) => {
            if (c_ele.type == "system") {
              system_data.push(c_ele);
            } else {
              custom_data.push(c_ele);
            }
          });
          prevValue.channels_options = system_data;
          prevValue.custom_channels_options = custom_data;
        }
        return { ...prevValue };
      });
    }
  }

  //
  async function get_products(filter_value: any, load_more: boolean) {
    // SetIsLoading(true);
    let outlet_value: any = [];
    filter_value.outlets.map((o_ele: any) => {
      outlet_value.push(o_ele.value);
    });
    if (id_value?.outlet_id) {
      outlet_value.push(id_value.outlet_id);
    }
    let channel_value: any = [];
    filter_value.channels.map((c_ele: any) => {
      channel_value.push(c_ele.value);
    });
    let custom_channel_value: any = [];
    filter_value.custom_channels.map((cc_ele: any) => {
      custom_channel_value.push(cc_ele.value);
    });
    let filter_brand_value = [];
    filter_value.filter_brand.map((b_ele: any) => {
      filter_brand_value.push(b_ele.value);
    });
    let filter_categories_value = [];
    filter_value.filter_categories.map((fc_ele: any) => {
      filter_categories_value.push(fc_ele.value);
    });
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        page: filter_value.page_no,
        type: "channel",
        view: filter_value.view.value,
        outlets: encodeURIComponent(JSON.stringify(outlet_value)),
        channels: encodeURIComponent(channel_value),
        custom_channels: encodeURIComponent(custom_channel_value),
        // filter_count: filter_value.count.value,
        filter_products: encodeURIComponent(
          JSON.stringify(filter_value.filter_products)
        ),
        search: filter_value.search,
        sort: filter_value.sort.value,
        filter_brand: encodeURIComponent(filter_value.filter_brand),
        filter_min_mrp: filter_value.min_mrp,
        filter_max_mrp: filter_value.max_mrp,
        filter_categories: encodeURIComponent(filter_value.filter_categories),
      },
    };
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    console.log("get_products pass_data :", pass_data);
    let response: any = await api(
      "/brand/outlet_channel_product_list",
      pass_data
    );
    console.log("/brand/outlet_channel_product_list response :", response);
    if (response.status_code == 200) {
      let res_data = response.response;
      // SetNewChecked({
      //   product_group: [],
      //   variants: [],
      //   addon_groups: [],
      //   addon_items: [],
      // });
      // SetNewDisabled({
      //   product_group: [],
      //   variants: [],
      //   addon_groups: [],
      //   addon_items: [],
      // });
      // SetNewChecked1({
      //   product_group: {},
      //   variants: {},
      //   addon_groups: {},
      //   addon_items: {},
      // });
      // SetAllData(res_data);
      // SetAllDataSaftey(res_data);
      if(!load_more){
        SetNewChecked({
          product_group: [],
          variants: [],
          addon_groups: [],
          addon_items: [],
        });
        SetNewDisabled({
          product_group: [],
          variants: [],
          addon_groups: [],
          addon_items: [],
        });
        SetNewChecked1({
          product_group: {},
          variants: {},
          addon_groups: {},
          addon_items: {},
        });
      }

      SetAllData((prevalue : any)=>{
        if(load_more){
          // res_data.products.map((ele : any)=>{
          //   prevalue.products.push(ele);
          // })
          prevalue.outlets = res_data.outlets;
          prevalue.channels = res_data.channels;
          prevalue.outlet_system_channels = res_data.outlet_system_channels;
          prevalue.outlet_custom_channels = res_data.outlet_custom_channels;

          Object.keys(res_data.outlet_products_assign).map((ele)=>{
            prevalue.outlet_products_assign[ele] = res_data.outlet_products_assign[ele]
          })
          Object.keys(res_data.outlet_products_available).map((ele)=>{
            prevalue.outlet_products_available[ele] = res_data.outlet_products_available[ele]
          })
          Object.keys(res_data.outlet_variants_assign).map((ele)=>{
            prevalue.outlet_variants_assign[ele] = res_data.outlet_variants_assign[ele]
          })
          Object.keys(res_data.outlet_variants_available).map((ele)=>{
            prevalue.outlet_variants_available[ele] = res_data.outlet_variants_available[ele]
          })
          Object.keys(res_data.outlet_addon_groups_assign).map((ele)=>{
            prevalue.outlet_addon_groups_assign[ele] = res_data.outlet_addon_groups_assign[ele]
          })
          Object.keys(res_data.outlet_addon_groups_available).map((ele)=>{
            prevalue.outlet_addon_groups_available[ele] = res_data.outlet_addon_groups_available[ele]
          })
          Object.keys(res_data.outlet_addons_assign).map((ele)=>{
            prevalue.outlet_addons_assign[ele] = res_data.outlet_addons_assign[ele]
          })
          Object.keys(res_data.outlet_addons_available).map((ele)=>{
            prevalue.outlet_addons_available[ele] = res_data.outlet_addons_available[ele]
          })
          Object.keys(res_data.outlet_channel_products_assign).map((ele)=>{
            prevalue.outlet_channel_products_assign[ele] = res_data.outlet_channel_products_assign[ele]
          })
          Object.keys(res_data.outlet_channel_products_available).map((ele)=>{
            prevalue.outlet_channel_products_available[ele] = res_data.outlet_channel_products_available[ele]
          })
          Object.keys(res_data.outlet_channel_variants_assign).map((ele)=>{
            prevalue.outlet_channel_variants_assign[ele] = res_data.outlet_channel_variants_assign[ele]
          })
          Object.keys(res_data.outlet_channel_variants_available).map((ele)=>{
            prevalue.outlet_channel_variants_available[ele] = res_data.outlet_channel_variants_available[ele]
          })
          Object.keys(res_data.outlet_channel_addon_groups_assign).map((ele)=>{
            prevalue.outlet_channel_addon_groups_assign[ele] = res_data.outlet_channel_addon_groups_assign[ele]
          })
          Object.keys(res_data.outlet_channel_addon_groups_available).map((ele)=>{
            prevalue.outlet_channel_addon_groups_available[ele] = res_data.outlet_channel_addon_groups_available[ele]
          })
          Object.keys(res_data.outlet_channel_addons_assign).map((ele)=>{
            prevalue.outlet_channel_addons_assign[ele] = res_data.outlet_channel_addons_assign[ele]
          })
          Object.keys(res_data.outlet_channel_addons_available).map((ele)=>{
            prevalue.outlet_channel_addons_available[ele] = res_data.outlet_channel_addons_available[ele]
          })
          Object.keys(res_data.outlet_channel_products_approved).map((ele)=>{
            prevalue.outlet_channel_products_approved[ele] = res_data.outlet_channel_products_approved[ele]
          })
        }else{
          prevalue = res_data;
        }
        console.log("SetAllData prevalue :",prevalue);
        return prevalue;
      });

      SetAllDataSaftey((prevalue : any)=>{
        if(load_more){
          // res_data.products.map((ele : any)=>{
          //   prevalue.products.push(ele);
          // })
          prevalue.outlets = res_data.outlets;
          prevalue.channels = res_data.channels;
          prevalue.outlet_system_channels = res_data.outlet_system_channels;
          prevalue.outlet_custom_channels = res_data.outlet_custom_channels;

          Object.keys(res_data.outlet_products_assign).map((ele)=>{
            prevalue.outlet_products_assign[ele] = res_data.outlet_products_assign[ele]
          })
          Object.keys(res_data.outlet_products_available).map((ele)=>{
            prevalue.outlet_products_available[ele] = res_data.outlet_products_available[ele]
          })
          Object.keys(res_data.outlet_variants_assign).map((ele)=>{
            prevalue.outlet_variants_assign[ele] = res_data.outlet_variants_assign[ele]
          })
          Object.keys(res_data.outlet_variants_available).map((ele)=>{
            prevalue.outlet_variants_available[ele] = res_data.outlet_variants_available[ele]
          })
          Object.keys(res_data.outlet_addon_groups_assign).map((ele)=>{
            prevalue.outlet_addon_groups_assign[ele] = res_data.outlet_addon_groups_assign[ele]
          })
          Object.keys(res_data.outlet_addon_groups_available).map((ele)=>{
            prevalue.outlet_addon_groups_available[ele] = res_data.outlet_addon_groups_available[ele]
          })
          Object.keys(res_data.outlet_addons_assign).map((ele)=>{
            prevalue.outlet_addons_assign[ele] = res_data.outlet_addons_assign[ele]
          })
          Object.keys(res_data.outlet_addons_available).map((ele)=>{
            prevalue.outlet_addons_available[ele] = res_data.outlet_addons_available[ele]
          })
          Object.keys(res_data.outlet_channel_products_assign).map((ele)=>{
            prevalue.outlet_channel_products_assign[ele] = res_data.outlet_channel_products_assign[ele]
          })
          Object.keys(res_data.outlet_channel_products_available).map((ele)=>{
            prevalue.outlet_channel_products_available[ele] = res_data.outlet_channel_products_available[ele]
          })
          Object.keys(res_data.outlet_channel_variants_assign).map((ele)=>{
            prevalue.outlet_channel_variants_assign[ele] = res_data.outlet_channel_variants_assign[ele]
          })
          Object.keys(res_data.outlet_channel_variants_available).map((ele)=>{
            prevalue.outlet_channel_variants_available[ele] = res_data.outlet_channel_variants_available[ele]
          })
          Object.keys(res_data.outlet_channel_addon_groups_assign).map((ele)=>{
            prevalue.outlet_channel_addon_groups_assign[ele] = res_data.outlet_channel_addon_groups_assign[ele]
          })
          Object.keys(res_data.outlet_channel_addon_groups_available).map((ele)=>{
            prevalue.outlet_channel_addon_groups_available[ele] = res_data.outlet_channel_addon_groups_available[ele]
          })
          Object.keys(res_data.outlet_channel_addons_assign).map((ele)=>{
            prevalue.outlet_channel_addons_assign[ele] = res_data.outlet_channel_addons_assign[ele]
          })
          Object.keys(res_data.outlet_channel_addons_available).map((ele)=>{
            prevalue.outlet_channel_addons_available[ele] = res_data.outlet_channel_addons_available[ele]
          })
          Object.keys(res_data.outlet_channel_products_approved).map((ele)=>{
            prevalue.outlet_channel_products_approved[ele] = res_data.outlet_channel_products_approved[ele]
          })
        }else{
          prevalue = res_data;
        }
        console.log("SetAllData prevalue :",prevalue);
        return prevalue;
      });

      if (res_data.hasOwnProperty("products")) {
        res_data.products.map((p_ele: any) => {
          p_ele.expanded = false;
          if (p_ele.hasOwnProperty("variants")) {
            p_ele.have_expand = p_ele.variants.length > 0 ? true : false;
            p_ele.variants.map((v_ele: any) => {
              v_ele.expanded = false;
              if (v_ele.hasOwnProperty("addon_groups")) {
                v_ele.have_expand =
                  v_ele.addon_groups.length > 0 ? true : false;
                v_ele.addon_groups.map((ag_ele: any) => {
                  ag_ele.expanded = false;
                  if (ag_ele.hasOwnProperty("addon_items")) {
                    ag_ele.have_expand =
                      ag_ele.addon_items.length > 0 ? true : false;
                    ag_ele.addon_items.map((ai_ele: any) => {
                      ai_ele.expanded = false;
                      ai_ele.have_expand = false;
                    });
                  }
                });
              }
            });
          }
        });
        if (load_more) {
          SetProductData((prevalue) => {
            res_data.products.map((ele: any) => {
              prevalue.push(ele);
            });
            return prevalue;
          });
        } else {
          SetProductData(res_data.products);
        }
        // SetProductData(res_data.products);
      }

      if (res_data.hasOwnProperty("outlets")) {
        SetOutletData(res_data.outlets);
      }

      if (res_data.hasOwnProperty("channels")) {
        SetChannelData(res_data.channels);
      }

      SetFilterData((prevValue: any) => {
        if (res_data.hasOwnProperty("next_page")) {
          prevValue.next_page = res_data.next_page;
        }
        return { ...prevValue };
      });
      SetIsLoading(false);
    }
  }

  async function get_available_products(filter_value: any, load_more: boolean) {
    // SetIsLoading(true);
    let outlet_value: any = [];

    filter_value.outlets.map((o_ele: any) => {
      outlet_value.push(o_ele.value);
    });
    if (id_value?.outlet_id) {
      outlet_value.push(id_value.outlet_id);
    }
    let channel_value: any = [];
    filter_value.channels.map((c_ele: any) => {
      channel_value.push(c_ele.value);
    });
    let custom_channel_value: any = [];
    filter_value.custom_channels.map((cc_ele: any) => {
      custom_channel_value.push(cc_ele.value);
    });
    let filter_brand_value = [];
    filter_value.filter_brand.map((b_ele: any) => {
      filter_brand_value.push(b_ele.value);
    });
    let filter_categories_value = [];
    filter_value.filter_categories.map((fc_ele: any) => {
      filter_categories_value.push(fc_ele.value);
    });
    let pass_data: any = {
      // get: {
      //   brand: id_value.brand_id,
      //   page: filter_value.page_no,
      //   view: filter_value.view.value,
      //   // filter_count: filter_value.count.value,
      //   filter_products: encodeURIComponent(
      //     JSON.stringify(filter_value.filter_products),
      //   ),
      //   search: filter_value.search,
      //   sort: filter_value.sort.value,
      //   filter_brand: encodeURIComponent(filter_value.filter_brand),
      //   filter_min_mrp: filter_value.min_mrp,
      //   filter_max_mrp: filter_value.max_mrp,
      //   filter_categories: encodeURIComponent(filter_value.filter_categories),
      // },
      get: {
        brand: id_value.brand_id,
        page: filter_value.page_no,
        type: "channel",
        view: filter_value.view.value,
        outlets: encodeURIComponent(JSON.stringify(outlet_value)),
        channels: encodeURIComponent(channel_value),
        custom_channels: encodeURIComponent(custom_channel_value),
        // filter_count: filter_value.count.value,
        filter_products: encodeURIComponent(
          JSON.stringify(filter_value.filter_products)
        ),
        search: filter_value.search,
        sort: filter_value.sort.value,
        filter_brand: encodeURIComponent(filter_value.filter_brand),
        filter_min_mrp: filter_value.min_mrp,
        filter_max_mrp: filter_value.max_mrp,
        filter_categories: encodeURIComponent(filter_value.filter_categories),
      },
    };
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    console.log("get_available_products pass_data :", pass_data);
    // let response: any = await api(
    //   "/brand/outlet_channel_product_list",
    //   pass_data
    // );
    let response: any = await api(
      "/brand/outlet_channel_product_list_v2",
      pass_data
    );
    console.log("/brand/outlet_channel_product_list response :", response);
    if (response.status_code == 200) {
      let res_data = response.response;
      res_data.outlets.map((o_ele: any, o_index: any) => {
        res_data.channels.map((c_ele: any, c_index: any) => {
          let f1_id: any, f2_id: any;
          f1_id = o_ele.id + "_" + c_ele.id;
          if (c_ele.type == "system") {
            if (res_data.hasOwnProperty("outlet_system_channels")) {
              if (res_data.outlet_system_channels.hasOwnProperty(f1_id)) {
                f2_id = res_data.outlet_system_channels[f1_id];
              }
            }
          }
          if (c_ele.type == "custom") {
            if (res_data.hasOwnProperty("outlet_custom_channels")) {
              if (res_data.outlet_custom_channels.hasOwnProperty(f1_id)) {
                f2_id = res_data.outlet_custom_channels[f1_id];
              }
            }
          }
          res_data.products.map((p_ele: any) => {
            let disable_variant = false;
            let disable_addon_group = false;
            let disable_addon_item = false;
            if (
              res_data.outlet_channel_products_available[
                f2_id + "_" + p_ele.id
              ] == false
            ) {
              disable_variant = true;
              disable_addon_group = true;
              disable_addon_item = true;
            }
            if (p_ele.hasOwnProperty("variants")) {
              p_ele.variants.map((v_ele: any) => {
                if (
                  res_data.outlet_channel_variants_available[
                    f2_id + "_" + v_ele.id
                  ] == false
                ) {
                  disable_addon_group = true;
                  disable_addon_item = true;
                }
                if (disable_variant) {
                  SetNewDisabled((prevValue: any) => {
                    prevValue["variants"].push({
                      product_id: v_ele.id,
                      outlet_id: o_ele.id,
                      channel_id: c_ele.id,
                    });
                    return { ...prevValue };
                  });
                }
                // console.log("Disabling Variant :",{
                //   product_id:v_ele.id,
                //   outlet_id:o_ele.id,
                //   channel_id:c_ele.id
                // })
                if (v_ele.hasOwnProperty("addon_groups")) {
                  v_ele.addon_groups.map((ag_ele: any) => {
                    if (
                      res_data.outlet_channel_addon_groups_available[
                        f2_id + "_" + ag_ele.id
                      ] == false
                    ) {
                      disable_addon_item = true;
                    }

                    if (disable_addon_group) {
                      SetNewDisabled((prevValue: any) => {
                        prevValue["addon_groups"].push({
                          product_id: ag_ele.id,
                          outlet_id: o_ele.id,
                          channel_id: c_ele.id,
                        });
                        return { ...prevValue };
                      });
                    }

                    if (ag_ele.hasOwnProperty("addon_items")) {
                      ag_ele.addon_items.map((ai_ele: any) => {
                        if (
                          res_data.outlet_channel_addon_groups_available[
                            f2_id + "_" + ag_ele.id
                          ] == false
                        ) {
                          disable_addon_item = true;
                        }

                        if (disable_addon_item) {
                          SetNewDisabled((prevValue: any) => {
                            prevValue["addon_items"].push({
                              product_id: ai_ele.id,
                              outlet_id: o_ele.id,
                              channel_id: c_ele.id,
                            });
                            return { ...prevValue };
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        });
      });
      console.log("res_data :", res_data);
      // SetNewChecked({
      //   product_group: [],
      //   variants: [],
      //   addon_groups: [],
      //   addon_items: [],
      // });
      // SetNewDisabled({
      //   product_group: [],
      //   variants: [],
      //   addon_groups: [],
      //   addon_items: [],
      // });
      // SetNewChecked1({
      //   product_group: {},
      //   variants: {},
      //   addon_groups: {},
      //   addon_items: {},
      // });
      // SetAllData(res_data);
      // SetAllDataSaftey(res_data);

      if(!load_more){
        SetNewChecked({
          product_group: [],
          variants: [],
          addon_groups: [],
          addon_items: [],
        });
        SetNewDisabled({
          product_group: [],
          variants: [],
          addon_groups: [],
          addon_items: [],
        });
        SetNewChecked1({
          product_group: {},
          variants: {},
          addon_groups: {},
          addon_items: {},
        });
      }

      SetAllData((prevalue : any)=>{
        if(load_more){
          // res_data.products.map((ele : any)=>{
          //   prevalue.products.push(ele);
          // })
          prevalue.outlets = res_data.outlets;
          prevalue.channels = res_data.channels;
          prevalue.outlet_system_channels = res_data.outlet_system_channels;
          prevalue.outlet_custom_channels = res_data.outlet_custom_channels;

          Object.keys(res_data.outlet_products_assign).map((ele)=>{
            prevalue.outlet_products_assign[ele] = res_data.outlet_products_assign[ele]
          })
          Object.keys(res_data.outlet_products_available).map((ele)=>{
            prevalue.outlet_products_available[ele] = res_data.outlet_products_available[ele]
          })
          Object.keys(res_data.outlet_variants_assign).map((ele)=>{
            prevalue.outlet_variants_assign[ele] = res_data.outlet_variants_assign[ele]
          })
          Object.keys(res_data.outlet_variants_available).map((ele)=>{
            prevalue.outlet_variants_available[ele] = res_data.outlet_variants_available[ele]
          })
          Object.keys(res_data.outlet_addon_groups_assign).map((ele)=>{
            prevalue.outlet_addon_groups_assign[ele] = res_data.outlet_addon_groups_assign[ele]
          })
          Object.keys(res_data.outlet_addon_groups_available).map((ele)=>{
            prevalue.outlet_addon_groups_available[ele] = res_data.outlet_addon_groups_available[ele]
          })
          Object.keys(res_data.outlet_addons_assign).map((ele)=>{
            prevalue.outlet_addons_assign[ele] = res_data.outlet_addons_assign[ele]
          })
          Object.keys(res_data.outlet_addons_available).map((ele)=>{
            prevalue.outlet_addons_available[ele] = res_data.outlet_addons_available[ele]
          })
          Object.keys(res_data.outlet_channel_products_assign).map((ele)=>{
            prevalue.outlet_channel_products_assign[ele] = res_data.outlet_channel_products_assign[ele]
          })
          Object.keys(res_data.outlet_channel_products_available).map((ele)=>{
            prevalue.outlet_channel_products_available[ele] = res_data.outlet_channel_products_available[ele]
          })
          Object.keys(res_data.outlet_channel_variants_assign).map((ele)=>{
            prevalue.outlet_channel_variants_assign[ele] = res_data.outlet_channel_variants_assign[ele]
          })
          Object.keys(res_data.outlet_channel_variants_available).map((ele)=>{
            prevalue.outlet_channel_variants_available[ele] = res_data.outlet_channel_variants_available[ele]
          })
          Object.keys(res_data.outlet_channel_addon_groups_assign).map((ele)=>{
            prevalue.outlet_channel_addon_groups_assign[ele] = res_data.outlet_channel_addon_groups_assign[ele]
          })
          Object.keys(res_data.outlet_channel_addon_groups_available).map((ele)=>{
            prevalue.outlet_channel_addon_groups_available[ele] = res_data.outlet_channel_addon_groups_available[ele]
          })
          Object.keys(res_data.outlet_channel_addons_assign).map((ele)=>{
            prevalue.outlet_channel_addons_assign[ele] = res_data.outlet_channel_addons_assign[ele]
          })
          Object.keys(res_data.outlet_channel_addons_available).map((ele)=>{
            prevalue.outlet_channel_addons_available[ele] = res_data.outlet_channel_addons_available[ele]
          })
          Object.keys(res_data.outlet_channel_products_approved).map((ele)=>{
            prevalue.outlet_channel_products_approved[ele] = res_data.outlet_channel_products_approved[ele]
          })
        }else{
          prevalue = res_data;
        }
        console.log("SetAllData prevalue :",prevalue);
        return prevalue;
      });

      SetAllDataSaftey((prevalue : any)=>{
        if(load_more){
          // res_data.products.map((ele : any)=>{
          //   prevalue.products.push(ele);
          // })
          prevalue.outlets = res_data.outlets;
          prevalue.channels = res_data.channels;
          prevalue.outlet_system_channels = res_data.outlet_system_channels;
          prevalue.outlet_custom_channels = res_data.outlet_custom_channels;

          Object.keys(res_data.outlet_products_assign).map((ele)=>{
            prevalue.outlet_products_assign[ele] = res_data.outlet_products_assign[ele]
          })
          Object.keys(res_data.outlet_products_available).map((ele)=>{
            prevalue.outlet_products_available[ele] = res_data.outlet_products_available[ele]
          })
          Object.keys(res_data.outlet_variants_assign).map((ele)=>{
            prevalue.outlet_variants_assign[ele] = res_data.outlet_variants_assign[ele]
          })
          Object.keys(res_data.outlet_variants_available).map((ele)=>{
            prevalue.outlet_variants_available[ele] = res_data.outlet_variants_available[ele]
          })
          Object.keys(res_data.outlet_addon_groups_assign).map((ele)=>{
            prevalue.outlet_addon_groups_assign[ele] = res_data.outlet_addon_groups_assign[ele]
          })
          Object.keys(res_data.outlet_addon_groups_available).map((ele)=>{
            prevalue.outlet_addon_groups_available[ele] = res_data.outlet_addon_groups_available[ele]
          })
          Object.keys(res_data.outlet_addons_assign).map((ele)=>{
            prevalue.outlet_addons_assign[ele] = res_data.outlet_addons_assign[ele]
          })
          Object.keys(res_data.outlet_addons_available).map((ele)=>{
            prevalue.outlet_addons_available[ele] = res_data.outlet_addons_available[ele]
          })
          Object.keys(res_data.outlet_channel_products_assign).map((ele)=>{
            prevalue.outlet_channel_products_assign[ele] = res_data.outlet_channel_products_assign[ele]
          })
          Object.keys(res_data.outlet_channel_products_available).map((ele)=>{
            prevalue.outlet_channel_products_available[ele] = res_data.outlet_channel_products_available[ele]
          })
          Object.keys(res_data.outlet_channel_variants_assign).map((ele)=>{
            prevalue.outlet_channel_variants_assign[ele] = res_data.outlet_channel_variants_assign[ele]
          })
          Object.keys(res_data.outlet_channel_variants_available).map((ele)=>{
            prevalue.outlet_channel_variants_available[ele] = res_data.outlet_channel_variants_available[ele]
          })
          Object.keys(res_data.outlet_channel_addon_groups_assign).map((ele)=>{
            prevalue.outlet_channel_addon_groups_assign[ele] = res_data.outlet_channel_addon_groups_assign[ele]
          })
          Object.keys(res_data.outlet_channel_addon_groups_available).map((ele)=>{
            prevalue.outlet_channel_addon_groups_available[ele] = res_data.outlet_channel_addon_groups_available[ele]
          })
          Object.keys(res_data.outlet_channel_addons_assign).map((ele)=>{
            prevalue.outlet_channel_addons_assign[ele] = res_data.outlet_channel_addons_assign[ele]
          })
          Object.keys(res_data.outlet_channel_addons_available).map((ele)=>{
            prevalue.outlet_channel_addons_available[ele] = res_data.outlet_channel_addons_available[ele]
          })
          Object.keys(res_data.outlet_channel_products_approved).map((ele)=>{
            prevalue.outlet_channel_products_approved[ele] = res_data.outlet_channel_products_approved[ele]
          })
        }else{
          prevalue = res_data;
        }
        console.log("SetAllData prevalue :",prevalue);
        return prevalue;
      });

      if (res_data.hasOwnProperty("products")) {
        res_data.products.map((p_ele: any) => {
          p_ele.expanded = false;
          if (p_ele.hasOwnProperty("variants")) {
            p_ele.have_expand = p_ele.variants.length > 0 ? true : false;
            p_ele.variants.map((v_ele: any) => {
              v_ele.expanded = false;
              if (v_ele.hasOwnProperty("addon_groups")) {
                v_ele.have_expand =
                  v_ele.addon_groups.length > 0 ? true : false;
                v_ele.addon_groups.map((ag_ele: any) => {
                  ag_ele.expanded = false;
                  if (ag_ele.hasOwnProperty("addon_items")) {
                    ag_ele.have_expand =
                      ag_ele.addon_items.length > 0 ? true : false;
                    ag_ele.addon_items.map((ai_ele: any) => {
                      ai_ele.expanded = false;
                      ai_ele.have_expand = false;
                    });
                  }
                });
              }
            });
          }
        });
        if (load_more) {
          SetProductData((prevalue) => {
            res_data.products.map((ele: any) => {
              prevalue.push(ele);
            });
            return prevalue;
          });
        } else {
          SetProductData(res_data.products);
        }
        // SetProductData(res_data.products);
      }

      if (res_data.hasOwnProperty("outlets")) {
        SetOutletData(res_data.outlets);
      }

      if (res_data.hasOwnProperty("channels")) {
        SetChannelData(res_data.channels);
      }

      SetFilterData((prevValue: any) => {
        if (res_data.hasOwnProperty("next_page")) {
          prevValue.next_page = res_data.next_page;
        }
        return { ...prevValue };
      });

      SetIsLoading(false);
    }
  }

  const OwnAccordion = (accordian_props: any) => {
    const [list_data, SetListData] = useState(accordian_props.ele);

    function is_checked(o_ele: any, c_ele: any) {
      let f1_id, f2_id;
      f1_id = o_ele.id + "_" + c_ele.id;
      if (c_ele.type == "system") {
        if (all_data.hasOwnProperty("outlet_system_channels")) {
          if (all_data.outlet_system_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_system_channels[f1_id];
          } else {
            // console.log("no value 2")
            return false;
          }
        } else {
          // console.log("no value 1")
          return false;
        }
      }
      if (c_ele.type == "custom") {
        if (all_data.hasOwnProperty("outlet_custom_channels")) {
          if (all_data.outlet_custom_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_custom_channels[f1_id];
          } else {
            // console.log("no value 4")
            return false;
          }
        } else {
          // console.log("no value 3")
          return false;
        }
      }

      if (accordian_props.for == "product_group") {
        let f3_id = f2_id + "_" + list_data.id;
        if (all_data.hasOwnProperty("outlet_channel_products_assign")) {
          if (all_data.outlet_channel_products_assign.hasOwnProperty(f3_id)) {
            if (all_data.outlet_channel_products_assign[f3_id] == "checked") {
              return true;
            } else if (
              all_data.outlet_channel_products_assign[f3_id] == "paused"
            ) {
              return "paused";
            } else if (
              all_data.outlet_channel_products_assign[f3_id] == "unchecked"
            ) {
              return false;
            }
          } else {
            // console.log("no value 6")
            return false;
          }
        } else {
          // console.log("no value 5")
          return false;
        }
      }
      if (accordian_props.for == "variants") {
        let f3_id = f2_id + "_" + list_data.id;
        if (all_data.hasOwnProperty("outlet_channel_variants_assign")) {
          if (all_data.outlet_channel_variants_assign.hasOwnProperty(f3_id)) {
            if (all_data.outlet_channel_variants_assign[f3_id] == "checked") {
              return true;
            } else if (
              all_data.outlet_channel_variants_assign[f3_id] == "paused"
            ) {
              return "paused";
            } else if (
              all_data.outlet_channel_variants_assign[f3_id] == "unchecked"
            ) {
              return false;
            }
          } else {
            // console.log("no value 8")
            return false;
          }
        } else {
          // console.log("no value 7")
          return false;
        }
      }
      if (accordian_props.for == "addon_groups") {
        let f3_id = f2_id + "_" + list_data.id;
        if (all_data.hasOwnProperty("outlet_channel_addon_groups_assign")) {
          if (
            all_data.outlet_channel_addon_groups_assign.hasOwnProperty(f3_id)
          ) {
            if (
              all_data.outlet_channel_addon_groups_assign[f3_id] == "checked"
            ) {
              return true;
            } else if (
              all_data.outlet_channel_addon_groups_assign[f3_id] == "paused"
            ) {
              return "paused";
            } else if (
              all_data.outlet_channel_addon_groups_assign[f3_id] == "unchecked"
            ) {
              return false;
            }
          } else {
            // console.log("no value 10")
            return false;
          }
        } else {
          // console.log("no value 9")
          return false;
        }
      }
      if (accordian_props.for == "addon_items") {
        let f3_id = f2_id + "_" + list_data.id;
        if (all_data.hasOwnProperty("outlet_channel_addons_assign")) {
          if (all_data.outlet_channel_addons_assign.hasOwnProperty(f3_id)) {
            if (all_data.outlet_channel_addons_assign[f3_id] == "checked") {
              return true;
            } else if (
              all_data.outlet_channel_addons_assign[f3_id] == "paused"
            ) {
              return "paused";
            } else if (
              all_data.outlet_channel_addons_assign[f3_id] == "unchecked"
            ) {
              return false;
            }
          } else {
            // console.log("no value 12")
            return false;
          }
        } else {
          // console.log("no value 11")
          return false;
        }
      }
    }

    function available_is_checked(o_ele: any, c_ele: any) {
      let f1_id, f2_id;
      f1_id = o_ele.id + "_" + c_ele.id;
      if (c_ele.type == "system") {
        if (all_data.hasOwnProperty("outlet_system_channels")) {
          if (all_data.outlet_system_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_system_channels[f1_id];
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      if (c_ele.type == "custom") {
        if (all_data.hasOwnProperty("outlet_custom_channels")) {
          if (all_data.outlet_custom_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_custom_channels[f1_id];
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      let f3_id = f2_id + "_" + list_data.id;

      if (accordian_props.for == "product_group") {
        if (all_data.outlet_channel_products_available.hasOwnProperty(f3_id)) {
          if (all_data.outlet_channel_products_available[f3_id] != "disabled") {
            let return_data;
            if (all_data.outlet_channel_products_available[f3_id].value == 1) {
              return_data = true;
            } else {
              return_data = false;
            }
            return return_data;
            // return all_data.outlet_channel_products_available[f3_id];
          } else {
            return false;
          }
        } else {
          return false;
        }
      }

      if (accordian_props.for == "variants") {
        if (all_data.outlet_channel_variants_available.hasOwnProperty(f3_id)) {
          if (all_data.outlet_channel_variants_available[f3_id] != "disabled") {
            return all_data.outlet_channel_variants_available[f3_id];
          } else {
            return false;
          }
        } else {
          return false;
        }
      }

      if (accordian_props.for == "addon_groups") {
        if (
          all_data.outlet_channel_addon_groups_available.hasOwnProperty(f3_id)
        ) {
          if (
            all_data.outlet_channel_addon_groups_available[f3_id] != "disabled"
          ) {
            return all_data.outlet_channel_addon_groups_available[f3_id];
          } else {
            return false;
          }
        } else {
          return false;
        }
      }

      if (accordian_props.for == "addon_items") {
        if (all_data.outlet_channel_addons_available.hasOwnProperty(f3_id)) {
          // if(all_data.outlet_channel_addons_available[f3_id] == true){
          //   return true
          // }else if(all_data.outlet_channel_addons_available[f3_id] == false){
          //   return false
          // }
          // else{
          //   return false
          // }
          if (all_data.outlet_channel_addons_available[f3_id] != "disabled") {
            return all_data.outlet_channel_addons_available[f3_id];
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }

    function available_is_disabled(o_ele: any, c_ele: any) {
      let f1_id, f2_id;
      f1_id = o_ele.id + "_" + c_ele.id;
      if (c_ele.type == "system") {
        if (all_data.hasOwnProperty("outlet_system_channels")) {
          if (all_data.outlet_system_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_system_channels[f1_id];
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
      if (c_ele.type == "custom") {
        if (all_data.hasOwnProperty("outlet_custom_channels")) {
          if (all_data.outlet_custom_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_custom_channels[f1_id];
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
      let f3_id = f2_id + "_" + list_data.id;

      if (accordian_props.for == "product_group") {
        if (all_data.outlet_channel_products_available.hasOwnProperty(f3_id)) {
          if (
            new_disabled.product_group.some(
              (some_ele) =>
                some_ele.product_id == list_data.id &&
                some_ele.outlet_id == o_ele.id &&
                some_ele.channel_id == c_ele.id
            )
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }

      if (accordian_props.for == "variants") {
        if (all_data.outlet_channel_variants_available.hasOwnProperty(f3_id)) {
          if (
            new_disabled.variants.some(
              (some_ele) =>
                some_ele.product_id == list_data.id &&
                some_ele.outlet_id == o_ele.id &&
                some_ele.channel_id == c_ele.id
            )
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }

      if (accordian_props.for == "addon_groups") {
        if (
          all_data.outlet_channel_addon_groups_available.hasOwnProperty(f3_id)
        ) {
          if (
            new_disabled.addon_groups.some(
              (some_ele) =>
                some_ele.product_id == list_data.id &&
                some_ele.outlet_id == o_ele.id &&
                some_ele.channel_id == c_ele.id
            )
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }

      if (accordian_props.for == "addon_items") {
        if (all_data.outlet_channel_addons_available.hasOwnProperty(f3_id)) {
          if (
            new_disabled.addon_items.some(
              (some_ele) =>
                some_ele.product_id == list_data.id &&
                some_ele.outlet_id == o_ele.id &&
                some_ele.channel_id == c_ele.id
            )
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    }

    function assign_on_change(o_ele: any, c_ele: any, e: any) {
      let f1_id, f2_id;
      f1_id = o_ele.id + "_" + c_ele.id;
      if (c_ele.type == "system") {
        if (all_data.hasOwnProperty("outlet_system_channels")) {
          if (all_data.outlet_system_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_system_channels[f1_id];
          }
        }
      }
      if (c_ele.type == "custom") {
        if (all_data.hasOwnProperty("outlet_custom_channels")) {
          if (all_data.outlet_custom_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_custom_channels[f1_id];
          }
        }
      }

      if (accordian_props.for == "product_group") {
        let id1_data = f2_id + "_" + list_data.id;
        SetAllData((prevValue: any) => {
          // check/uncheck/pause - parent/child
          prevValue.products.map((p_ele: any) => {
            if (p_ele.id == list_data.id) {
              let f3_id = f2_id + "_" + list_data.id;

              // check/uncheck/pause self
              if (e.target.checked) {
                if (
                  !prevValue.outlet_channel_products_assign.hasOwnProperty(
                    f3_id
                  )
                ) {
                  let send_data = {
                    for: accordian_props.for,
                    pass_data: {
                      product_id: list_data.id,
                      outlet_id: o_ele.id,
                      channel_id: c_ele.id,
                    },
                  };
                  accordian_props.on_new_check(send_data);
                  let send_data1 = {
                    for: accordian_props.for,
                    key: f3_id,
                    value: "checked",
                  };
                  accordian_props.on_new_check1(send_data1);
                }
                prevValue.outlet_channel_products_assign[f3_id] = "checked";
                let send_data1 = {
                  for: "product_group",
                  key: f3_id,
                  value: "checked",
                };
                accordian_props.on_new_check1(send_data1);
              } else {
                if (
                  accordian_props.new_checked.product_group.some(
                    (ele) =>
                      ele.product_id == list_data.id &&
                      ele.outlet_id == o_ele.id &&
                      ele.channel_id == c_ele.id
                  )
                ) {
                  let index =
                    accordian_props.new_checked.product_group.findIndex(
                      (ele) =>
                        ele.product_id == list_data.id &&
                        ele.outlet_id == o_ele.id &&
                        ele.channel_id == c_ele.id
                    );
                  let send_data = {
                    for: accordian_props.for,
                    index: index,
                  };
                  accordian_props.on_new_uncheck(send_data);
                  let send_data1 = {
                    for: "product_group",
                    key: f3_id,
                    value: "unchecked",
                  };
                  accordian_props.on_new_uncheck1(send_data1);
                  delete prevValue.outlet_channel_products_assign[f3_id];
                } else {
                  if (
                    prevValue.outlet_channel_products_assign[f3_id] == "checked"
                  ) {
                    let send_data1 = {
                      for: "product_group",
                      key: f3_id,
                      value: "paused",
                    };
                    accordian_props.on_new_paused1(send_data1);
                    prevValue.outlet_channel_products_assign[f3_id] = "paused";
                  }
                }
              }

              if (p_ele.hasOwnProperty("variants")) {
                p_ele.variants.map((v_ele: any) => {
                  let f3_id = f2_id + "_" + v_ele.id;
                  if (e.target.checked) {
                    if (
                      !prevValue.outlet_channel_variants_assign.hasOwnProperty(
                        f2_id + "_" + v_ele.id
                      )
                    ) {
                      let send_data = {
                        for: "variants",
                        pass_data: {
                          product_id: v_ele.id,
                          outlet_id: o_ele.id,
                          channel_id: c_ele.id,
                        },
                      };
                      accordian_props.on_new_check(send_data);
                      let send_data1 = {
                        for: "variants",
                        key: f3_id,
                        value: "checked",
                      };
                      accordian_props.on_new_check1(send_data1);
                    }
                    prevValue.outlet_channel_variants_assign[
                      f2_id + "_" + v_ele.id
                    ] = "checked";
                    let send_data1 = {
                      for: "variants",
                      key: f2_id + "_" + v_ele.id,
                      value: "checked",
                    };
                    accordian_props.on_new_check1(send_data1);
                  } else {
                    if (
                      accordian_props.new_checked.variants.some(
                        (ele) =>
                          ele.product_id == v_ele.id &&
                          ele.outlet_id == o_ele.id &&
                          ele.channel_id == c_ele.id
                      )
                    ) {
                      let index =
                        accordian_props.new_checked.variants.findIndex(
                          (ele) =>
                            ele.product_id == v_ele.id &&
                            ele.outlet_id == o_ele.id &&
                            ele.channel_id == c_ele.id
                        );
                      let send_data = {
                        for: "variants",
                        index: index,
                      };

                      if (index != -1) {
                        accordian_props.on_new_uncheck(send_data);
                        let send_data1 = {
                          for: "variants",
                          key: f2_id + "_" + v_ele.id,
                          value: "unchecked",
                        };
                        accordian_props.on_new_uncheck1(send_data1);
                        delete prevValue.outlet_channel_variants_assign[
                          f2_id + "_" + v_ele.id
                        ];
                      }
                    } else {
                      if (
                        prevValue.outlet_channel_variants_assign[
                          f2_id + "_" + v_ele.id
                        ] == "checked"
                      ) {
                        let send_data1 = {
                          for: "variants",
                          key: f2_id + "_" + v_ele.id,
                          value: "paused",
                        };
                        accordian_props.on_new_paused1(send_data1);
                        prevValue.outlet_channel_variants_assign[
                          f2_id + "_" + v_ele.id
                        ] = "paused";
                      }
                    }
                  }
                  if (v_ele.hasOwnProperty("addon_groups")) {
                    v_ele.addon_groups.map((ag_ele: any) => {
                      let f3_id = f2_id + "_" + ag_ele.id;
                      if (e.target.checked) {
                        if (
                          !prevValue.outlet_channel_addon_groups_assign.hasOwnProperty(
                            f2_id + "_" + ag_ele.id
                          )
                        ) {
                          let send_data = {
                            for: "addon_groups",
                            pass_data: {
                              product_id: ag_ele.id,
                              outlet_id: o_ele.id,
                              channel_id: c_ele.id,
                            },
                          };
                          accordian_props.on_new_check(send_data);
                          let send_data1 = {
                            for: "addon_groups",
                            key: f3_id,
                            value: "checked",
                          };
                          accordian_props.on_new_check1(send_data1);
                        }
                        prevValue.outlet_channel_addon_groups_assign[
                          f2_id + "_" + ag_ele.id
                        ] = "checked";
                        let send_data1 = {
                          for: "addon_groups",
                          key: f2_id + "_" + ag_ele.id,
                          value: "checked",
                        };
                        accordian_props.on_new_check1(send_data1);
                      } else {
                        if (
                          accordian_props.new_checked.addon_groups.some(
                            (ele) =>
                              ele.product_id == ag_ele.id &&
                              ele.outlet_id == o_ele.id &&
                              ele.channel_id == c_ele.id
                          )
                        ) {
                          let index =
                            accordian_props.new_checked.addon_groups.findIndex(
                              (ele) =>
                                ele.product_id == ag_ele.id &&
                                ele.outlet_id == o_ele.id &&
                                ele.channel_id == c_ele.id
                            );
                          let send_data = {
                            for: "addon_groups",
                            index: index,
                          };
                          if (index != -1) {
                            accordian_props.on_new_uncheck(send_data);
                            let send_data1 = {
                              for: "addon_groups",
                              key: f2_id + "_" + ag_ele.id,
                              value: "unchecked",
                            };
                            accordian_props.on_new_uncheck1(send_data1);
                            delete prevValue.outlet_channel_addon_groups_assign[
                              f2_id + "_" + ag_ele.id
                            ];
                          } else {
                            // prevValue.outlet_channel_addon_groups_assign[f2_id + "_" + ag_ele.id] = "paused"
                          }
                        } else {
                          if (
                            prevValue.outlet_channel_addon_groups_assign[
                              f2_id + "_" + ag_ele.id
                            ] == "checked"
                          ) {
                            let send_data1 = {
                              for: "addon_groups",
                              key: f2_id + "_" + ag_ele.id,
                              value: "paused",
                            };
                            accordian_props.on_new_paused1(send_data1);
                            prevValue.outlet_channel_addon_groups_assign[
                              f2_id + "_" + ag_ele.id
                            ] = "paused";
                          }
                        }
                      }
                      if (ag_ele.hasOwnProperty("addon_items")) {
                        ag_ele.addon_items.map((ai_ele: any) => {
                          let f3_id = f2_id + "_" + ai_ele.id;
                          if (e.target.checked) {
                            if (
                              !prevValue.outlet_channel_addons_assign.hasOwnProperty(
                                f2_id + "_" + ai_ele.id
                              )
                            ) {
                              let send_data = {
                                for: "addon_items",
                                pass_data: {
                                  product_id: ai_ele.id,
                                  outlet_id: o_ele.id,
                                  channel_id: c_ele.id,
                                },
                              };
                              accordian_props.on_new_check(send_data);
                              let send_data1 = {
                                for: "addon_items",
                                key: f3_id,
                                value: "checked",
                              };
                              accordian_props.on_new_check1(send_data1);
                            }
                            prevValue.outlet_channel_addons_assign[
                              f2_id + "_" + ai_ele.id
                            ] = "checked";
                            let send_data1 = {
                              for: "addon_items",
                              key: f2_id + "_" + ai_ele.id,
                              value: "checked",
                            };
                            accordian_props.on_new_check1(send_data1);
                          } else {
                            if (
                              accordian_props.new_checked.addon_items.some(
                                (ele) =>
                                  ele.product_id == ai_ele.id &&
                                  ele.outlet_id == o_ele.id &&
                                  ele.channel_id == c_ele.id
                              )
                            ) {
                              let index =
                                accordian_props.new_checked.addon_items.findIndex(
                                  (ele) =>
                                    ele.product_id == ai_ele.id &&
                                    ele.outlet_id == o_ele.id &&
                                    ele.channel_id == c_ele.id
                                );
                              let send_data = {
                                for: "addon_items",
                                index: index,
                              };

                              if (index != -1) {
                                accordian_props.on_new_uncheck(send_data);
                                let send_data1 = {
                                  for: "addon_items",
                                  key: f2_id + "_" + ai_ele.id,
                                  value: "unchecked",
                                };
                                accordian_props.on_new_uncheck1(send_data1);
                                delete prevValue.outlet_channel_addons_assign[
                                  f2_id + "_" + ai_ele.id
                                ];
                              }
                            } else {
                              if (
                                prevValue.outlet_channel_addons_assign[
                                  f2_id + "_" + ai_ele.id
                                ] == "checked"
                              ) {
                                let send_data1 = {
                                  for: "addon_items",
                                  key: f2_id + "_" + ai_ele.id,
                                  value: "paused",
                                };
                                accordian_props.on_new_paused1(send_data1);
                                prevValue.outlet_channel_addons_assign[
                                  f2_id + "_" + ai_ele.id
                                ] = "paused";
                              }
                            }
                          }
                        });
                      }
                    });
                  }
                });
              }
            }
          });
          return { ...prevValue };
        });
      }

      if (accordian_props.for == "variants") {
        console.log("accordian_props on variants:", accordian_props);
        SetAllData((prevValue: any) => {
          let f3_id = f2_id + "_" + list_data.id;
          // check/uncheck/pause self
          if (e.target.checked) {
            if (
              !prevValue.outlet_channel_variants_assign.hasOwnProperty(
                f2_id + "_" + list_data.id
              )
            ) {
              let send_data = {
                for: accordian_props.for,
                pass_data: {
                  product_id: list_data.id,
                  outlet_id: o_ele.id,
                  channel_id: c_ele.id,
                },
              };
              accordian_props.on_new_check(send_data);

              let send_data1 = {
                for: accordian_props.for,
                key: f3_id,
                value: "checked",
              };
              accordian_props.on_new_check1(send_data1);
            }
            prevValue.outlet_channel_variants_assign[
              f2_id + "_" + list_data.id
            ] = "checked";
            let send_data1 = {
              for: "variants",
              key: f2_id + "_" + list_data.id,
              value: "checked",
            };
            accordian_props.on_new_check1(send_data1);
          } else {
            if (
              accordian_props.new_checked.variants.some(
                (ele) =>
                  ele.product_id == list_data.id &&
                  ele.outlet_id == o_ele.id &&
                  ele.channel_id == c_ele.id
              )
            ) {
              let index = accordian_props.new_checked.variants.findIndex(
                (ele) =>
                  ele.product_id == list_data.id &&
                  ele.outlet_id == o_ele.id &&
                  ele.channel_id == c_ele.id
              );
              let send_data = {
                for: accordian_props.for,
                index: index,
              };
              accordian_props.on_new_uncheck(send_data);
              let send_data1 = {
                for: "variants",
                key: f2_id + "_" + list_data.id,
                value: "unchecked",
              };
              accordian_props.on_new_uncheck1(send_data1);
              delete prevValue.outlet_channel_variants_assign[
                f2_id + "_" + list_data.id
              ];
            } else {
              if (
                prevValue.outlet_channel_variants_assign[
                  f2_id + "_" + list_data.id
                ] == "checked"
              ) {
                let send_data1 = {
                  for: "variants",
                  key: f2_id + "_" + list_data.id,
                  value: "paused",
                };
                accordian_props.on_new_paused1(send_data1);
                prevValue.outlet_channel_variants_assign[
                  f2_id + "_" + list_data.id
                ] = "paused";
              }
              // prevValue.outlet_channel_variants_assign[f3_id] = "paused"
            }
          }

          // check/uncheck/pause - parent/child
          prevValue.products.map((p_ele: any) => {
            if (p_ele.id == accordian_props.parent_id) {
              // child check/uncheck/pause
              let id_values = [];
              if (p_ele.hasOwnProperty("variants")) {
                p_ele.variants.map((v_ele: any) => {
                  id_values.push(f2_id + "_" + v_ele.id);
                  if (v_ele.id == list_data.id) {
                    //
                    if (v_ele.hasOwnProperty("addon_groups")) {
                      v_ele.addon_groups.map((ag_ele: any) => {
                        let f3_id = f2_id + "_" + ag_ele.id;
                        if (e.target.checked) {
                          if (
                            !prevValue.outlet_channel_addon_groups_assign.hasOwnProperty(
                              f2_id + "_" + ag_ele.id
                            )
                          ) {
                            let send_data = {
                              for: "addon_groups",
                              pass_data: {
                                product_id: ag_ele.id,
                                outlet_id: o_ele.id,
                                channel_id: c_ele.id,
                              },
                            };
                            accordian_props.on_new_check(send_data);
                            let send_data1 = {
                              for: "addon_groups",
                              key: f3_id,
                              value: "checked",
                            };
                            accordian_props.on_new_check1(send_data1);
                          }
                          prevValue.outlet_channel_addon_groups_assign[
                            f2_id + "_" + ag_ele.id
                          ] = "checked";
                          let send_data1 = {
                            for: "addon_groups",
                            key: f2_id + "_" + ag_ele.id,
                            value: "checked",
                          };
                          accordian_props.on_new_check1(send_data1);
                        } else {
                          if (
                            accordian_props.new_checked.addon_groups.some(
                              (ele) =>
                                ele.product_id == ag_ele.id &&
                                ele.outlet_id == o_ele.id &&
                                ele.channel_id == c_ele.id
                            )
                          ) {
                            let index =
                              accordian_props.new_checked.addon_groups.findIndex(
                                (ele) =>
                                  ele.product_id == ag_ele.id &&
                                  ele.outlet_id == o_ele.id &&
                                  ele.channel_id == c_ele.id
                              );
                            let send_data = {
                              for: "addon_groups",
                              index: index,
                            };
                            if (index != -1) {
                              accordian_props.on_new_uncheck(send_data);
                              let send_data1 = {
                                for: "addon_groups",
                                key: f2_id + "_" + ag_ele.id,
                                value: "unchecked",
                              };
                              accordian_props.on_new_uncheck1(send_data1);
                              delete prevValue
                                .outlet_channel_addon_groups_assign[
                                f2_id + "_" + ag_ele.id
                              ];
                            }
                          } else {
                            if (
                              prevValue.outlet_channel_addon_groups_assign[
                                f2_id + "_" + ag_ele.id
                              ] == "checked"
                            ) {
                              let send_data1 = {
                                for: "addon_groups",
                                key: f2_id + "_" + ag_ele.id,
                                value: "paused",
                              };
                              accordian_props.on_new_paused1(send_data1);
                              prevValue.outlet_channel_addon_groups_assign[
                                f2_id + "_" + ag_ele.id
                              ] = "paused";
                            }
                          }
                        }
                        if (ag_ele.hasOwnProperty("addon_items")) {
                          ag_ele.addon_items.map((ai_ele: any) => {
                            let f3_id = f2_id + "_" + ai_ele.id;
                            if (e.target.checked) {
                              if (
                                !prevValue.outlet_channel_addons_assign.hasOwnProperty(
                                  f2_id + "_" + ai_ele.id
                                )
                              ) {
                                let send_data = {
                                  for: "addon_items",
                                  pass_data: {
                                    product_id: ai_ele.id,
                                    outlet_id: o_ele.id,
                                    channel_id: c_ele.id,
                                  },
                                };
                                accordian_props.on_new_check(send_data);
                                let send_data1 = {
                                  for: "addon_items",
                                  key: f3_id,
                                  value: "checked",
                                };
                                accordian_props.on_new_check1(send_data1);
                              }
                              prevValue.outlet_channel_addons_assign[
                                f2_id + "_" + ai_ele.id
                              ] = "checked";
                              let send_data1 = {
                                for: "addon_items",
                                key: f2_id + "_" + ai_ele.id,
                                value: "checked",
                              };
                              accordian_props.on_new_check1(send_data1);
                            } else {
                              if (
                                accordian_props.new_checked.addon_items.some(
                                  (ele) =>
                                    ele.product_id == ai_ele.id &&
                                    ele.outlet_id == o_ele.id &&
                                    ele.channel_id == c_ele.id
                                )
                              ) {
                                let index =
                                  accordian_props.new_checked.addon_items.findIndex(
                                    (ele) =>
                                      ele.product_id == ai_ele.id &&
                                      ele.outlet_id == o_ele.id &&
                                      ele.channel_id == c_ele.id
                                  );
                                let send_data = {
                                  for: "addon_items",
                                  index: index,
                                };

                                if (index != -1) {
                                  accordian_props.on_new_uncheck(send_data);
                                  let send_data1 = {
                                    for: "addon_items",
                                    key: f2_id + "_" + ai_ele.id,
                                    value: "unchecked",
                                  };
                                  accordian_props.on_new_uncheck1(send_data1);
                                  delete prevValue.outlet_channel_addons_assign[
                                    f2_id + "_" + ai_ele.id
                                  ];
                                } else {
                                  // prevValue.outlet_channel_addons_assign[f2_id + "_" + ai_ele.id] = "paused"
                                }
                              } else {
                                if (
                                  prevValue.outlet_channel_addons_assign[
                                    f2_id + "_" + ai_ele.id
                                  ] == "checked"
                                ) {
                                  let send_data1 = {
                                    for: "addon_items",
                                    key: f2_id + "_" + ai_ele.id,
                                    value: "paused",
                                  };
                                  accordian_props.on_new_paused1(send_data1);
                                  prevValue.outlet_channel_addons_assign[
                                    f2_id + "_" + ai_ele.id
                                  ] = "paused";
                                }
                                // prevValue.outlet_channel_addons_assign[f3_id] = "paused"
                              }
                            }
                          });
                        }
                      });
                    }
                  }
                });
              }
              ////////////////////////////////////////////
              // parent check/uncheck/pause product_group
              if (e.target.checked) {
                if (
                  !prevValue.outlet_channel_products_assign.hasOwnProperty(
                    f2_id + "_" + accordian_props.parent_id
                  )
                ) {
                  let send_data = {
                    for: "product_group",
                    pass_data: {
                      product_id: accordian_props.parent_id,
                      outlet_id: o_ele.id,
                      channel_id: c_ele.id,
                    },
                  };
                  accordian_props.on_new_check(send_data);
                  let send_data1 = {
                    for: "product_group",
                    key: f3_id,
                    value: "checked",
                  };
                  accordian_props.on_new_check1(send_data1);
                }
                prevValue.outlet_channel_products_assign[
                  f2_id + "_" + accordian_props.parent_id
                ] = "checked";
                let send_data1 = {
                  for: "product_group",
                  key: f2_id + "_" + accordian_props.parent_id,
                  value: "checked",
                };
                accordian_props.on_new_check1(send_data1);
              } else {
                // important console.log("id_values.some((e_ele)=> Object.keys(prevValue.outlet_channel_variants_assign).includes(e_ele)) :",id_values.some((e_ele)=> Object.keys(prevValue.outlet_channel_variants_assign).includes(e_ele)))
                if (
                  accordian_props.new_checked.product_group.some(
                    (ele) =>
                      ele.product_id == accordian_props.parent_id &&
                      ele.outlet_id == o_ele.id &&
                      ele.channel_id == c_ele.id
                  )
                ) {
                  console.log("id_values :", id_values);
                  let item_value = [];
                  id_values.map((ele) => {
                    item_value.push(
                      prevValue.outlet_channel_variants_assign[ele]
                    );
                  });
                  console.log(
                    "item_value on variant product group uncheck :",
                    item_value
                  );
                  if (item_value.every((iv_ele) => iv_ele == undefined)) {
                    console.log("all_unchecked");
                    let index =
                      accordian_props.new_checked.product_group.findIndex(
                        (ele) =>
                          ele.product_id == accordian_props.parent_id &&
                          ele.outlet_id == o_ele.id &&
                          ele.channel_id == c_ele.id
                      );
                    let send_data = {
                      for: "product_group",
                      index: index,
                    };
                    if (index != -1) {
                      accordian_props.on_new_uncheck(send_data);
                      let send_data1 = {
                        for: "product_group",
                        key: f2_id + "_" + accordian_props.parent_id,
                        value: "unchecked",
                      };
                      accordian_props.on_new_uncheck1(send_data1);
                      delete prevValue.outlet_channel_products_assign[
                        f2_id + "_" + accordian_props.parent_id
                      ];
                    }
                  } else {
                    console.log("not all_unchecked");
                  }
                } else {
                  console.log("id_values :", id_values);
                  let item_value = [];
                  id_values.map((ele) => {
                    item_value.push(
                      prevValue.outlet_channel_variants_assign[ele]
                    );
                  });
                  console.log("item_value :", item_value);
                  if (item_value.every((iv_ele) => iv_ele == "paused")) {
                    console.log("all_paused variant product group check");
                    if (
                      prevValue.outlet_channel_products_assign[
                        f2_id + "_" + accordian_props.parent_id
                      ] == "checked"
                    ) {
                      let send_data1 = {
                        for: "product_group",
                        key: f2_id + "_" + accordian_props.parent_id,
                        value: "paused",
                      };
                      accordian_props.on_new_paused1(send_data1);
                      prevValue.outlet_channel_products_assign[
                        f2_id + "_" + accordian_props.parent_id
                      ] = "paused";
                    }
                  } else {
                    console.log("not all_paused");
                  }
                }
              }
            }
          });
          return { ...prevValue };
        });
      }

      if (accordian_props.for == "addon_groups") {
        console.log("accordian_props on addon_groups:", accordian_props);
        let f3_id = f2_id + "_" + list_data.id;
        SetAllData((prevValue: any) => {
          // check/uncheck/pause self
          if (e.target.checked) {
            if (
              !prevValue.outlet_channel_addon_groups_assign.hasOwnProperty(
                f3_id
              )
            ) {
              let send_data = {
                for: accordian_props.for,
                pass_data: {
                  product_id: list_data.id,
                  outlet_id: o_ele.id,
                  channel_id: c_ele.id,
                },
              };
              accordian_props.on_new_check(send_data);
              let send_data1 = {
                for: accordian_props.for,
                key: f3_id,
                value: "checked",
              };
              accordian_props.on_new_check1(send_data1);
            }
            prevValue.outlet_channel_addon_groups_assign[f3_id] = "checked";
            let send_data1 = {
              for: "addon_groups",
              key: f3_id,
              value: "checked",
            };
            accordian_props.on_new_check1(send_data1);
          } else {
            if (
              accordian_props.new_checked.addon_groups.some(
                (ele) =>
                  ele.product_id == list_data.id &&
                  ele.outlet_id == o_ele.id &&
                  ele.channel_id == c_ele.id
              )
            ) {
              let index = accordian_props.new_checked.addon_groups.findIndex(
                (ele) =>
                  ele.product_id == list_data.id &&
                  ele.outlet_id == o_ele.id &&
                  ele.channel_id == c_ele.id
              );
              let send_data = {
                for: accordian_props.for,
                index: index,
              };
              accordian_props.on_new_uncheck(send_data);
              let send_data1 = {
                for: "addon_groups",
                key: f3_id,
                value: "unchecked",
              };
              accordian_props.on_new_uncheck1(send_data1);
              delete prevValue.outlet_channel_addon_groups_assign[f3_id];
            } else {
              if (
                prevValue.outlet_channel_addon_groups_assign[f3_id] == "checked"
              ) {
                let send_data1 = {
                  for: "addon_groups",
                  key: f3_id,
                  value: "paused",
                };
                accordian_props.on_new_paused1(send_data1);
                prevValue.outlet_channel_addon_groups_assign[f3_id] = "paused";
              }
              // prevValue.outlet_channel_addon_groups_assign[f3_id] = "paused"
            }
          }

          // check/uncheck/pause - parent/child
          prevValue.products.map((p_ele: any) => {
            if (p_ele.id == accordian_props.product_group_id) {
              let id_values = [];
              //////////////////////////////////////////
              if (p_ele.hasOwnProperty("variants")) {
                p_ele.variants.map((v_ele: any) => {
                  id_values.push(f2_id + "_" + v_ele.id);
                  if (v_ele.id == accordian_props.parent_id) {
                    let id_values1 = [];
                    //////////////////////////////////////////
                    if (v_ele.hasOwnProperty("addon_groups")) {
                      v_ele.addon_groups.map((ag_ele: any) => {
                        id_values1.push(f2_id + "_" + ag_ele.id);
                        if (ag_ele.id == list_data.id) {
                          //////////////////////////////////////////
                          if (ag_ele.hasOwnProperty("addon_items")) {
                            ag_ele.addon_items.map((ai_ele: any) => {
                              let f3_id = f2_id + "_" + ai_ele.id;
                              if (e.target.checked) {
                                if (
                                  !prevValue.outlet_channel_addons_assign.hasOwnProperty(
                                    f3_id
                                  )
                                ) {
                                  let send_data = {
                                    for: "addon_items",
                                    pass_data: {
                                      product_id: ai_ele.id,
                                      outlet_id: o_ele.id,
                                      channel_id: c_ele.id,
                                    },
                                  };
                                  accordian_props.on_new_check(send_data);
                                  let send_data1 = {
                                    for: "addon_items",
                                    key: f3_id,
                                    value: "checked",
                                  };
                                  accordian_props.on_new_check1(send_data1);
                                }
                                prevValue.outlet_channel_addons_assign[f3_id] =
                                  "checked";
                              } else {
                                if (
                                  accordian_props.new_checked.addon_items.some(
                                    (ele) =>
                                      ele.product_id == ai_ele.id &&
                                      ele.outlet_id == o_ele.id &&
                                      ele.channel_id == c_ele.id
                                  )
                                ) {
                                  let index =
                                    accordian_props.new_checked.addon_items.findIndex(
                                      (ele) =>
                                        ele.product_id == ai_ele.id &&
                                        ele.outlet_id == o_ele.id &&
                                        ele.channel_id == c_ele.id
                                    );
                                  let send_data = {
                                    for: "addon_items",
                                    index: index,
                                  };
                                  if (index != -1) {
                                    accordian_props.on_new_uncheck(send_data);
                                    let send_data1 = {
                                      for: "addon_items",
                                      key: f3_id,
                                      value: "unchecked",
                                    };
                                    accordian_props.on_new_uncheck1(send_data1);
                                    delete prevValue
                                      .outlet_channel_addons_assign[f3_id];
                                  }
                                } else {
                                  if (
                                    prevValue.outlet_channel_addons_assign[
                                      f3_id
                                    ] == "checked"
                                  ) {
                                    let send_data1 = {
                                      for: "addon_items",
                                      key: f3_id,
                                      value: "paused",
                                    };
                                    accordian_props.on_new_paused1(send_data1);
                                    prevValue.outlet_channel_addons_assign[
                                      f3_id
                                    ] = "paused";
                                  }
                                }
                              }
                            });
                          }
                          //////////////////////////////////////////
                        }
                      });
                    }
                    //////////////////////////////////////////
                    // variant check needed
                    if (e.target.checked) {
                      if (
                        !prevValue.outlet_channel_variants_assign.hasOwnProperty(
                          f2_id + "_" + accordian_props.parent_id
                        )
                      ) {
                        let send_data = {
                          for: "variants",
                          pass_data: {
                            product_id: accordian_props.parent_id,
                            outlet_id: o_ele.id,
                            channel_id: c_ele.id,
                          },
                        };
                        accordian_props.on_new_check(send_data);
                        let send_data1 = {
                          for: "variants",
                          key: f3_id,
                          value: "checked",
                        };
                        accordian_props.on_new_check1(send_data1);
                      }
                      prevValue.outlet_channel_variants_assign[
                        f2_id + "_" + accordian_props.parent_id
                      ] = "checked";
                      let send_data1 = {
                        for: "variants",
                        key: f2_id + "_" + accordian_props.parent_id,
                        value: "checked",
                      };
                      accordian_props.on_new_check1(send_data1);
                    } else {
                      if (
                        accordian_props.new_checked.variants.some(
                          (ele) =>
                            ele.product_id == accordian_props.parent_id &&
                            ele.outlet_id == o_ele.id &&
                            ele.channel_id == c_ele.id
                        )
                      ) {
                        console.log(" no need to uncheck variant");
                      } else {
                        if (
                          prevValue.outlet_channel_addons_assign[f3_id] ==
                          "checked"
                        ) {
                          let send_data1 = {
                            for: "variants",
                            key: f2_id + "_" + accordian_props.parent_id,
                            value: "paused",
                          };
                          accordian_props.on_new_paused1(send_data1);
                          prevValue.outlet_channel_variants_assign[
                            f2_id + "_" + accordian_props.parent_id
                          ] = "paused";
                        }
                      }
                    }
                  }
                });
              }
              //////////////////////////////////////////
              // product group check needed
              if (e.target.checked) {
                if (
                  !prevValue.outlet_channel_products_assign.hasOwnProperty(
                    f2_id + "_" + accordian_props.product_group_id
                  )
                ) {
                  let send_data = {
                    for: "product_group",
                    pass_data: {
                      product_id: accordian_props.product_group_id,
                      outlet_id: o_ele.id,
                      channel_id: c_ele.id,
                    },
                  };
                  accordian_props.on_new_check(send_data);
                  let send_data1 = {
                    for: "product_group",
                    key: f3_id,
                    value: "checked",
                  };
                  accordian_props.on_new_check1(send_data1);
                }
                prevValue.outlet_channel_products_assign[
                  f2_id + "_" + accordian_props.product_group_id
                ] = "checked";
                let send_data1 = {
                  for: "product_group",
                  key: f2_id + "_" + accordian_props.product_group_id,
                  value: "checked",
                };
                accordian_props.on_new_check1(send_data1);
              } else {
                console.log(
                  "check789456123 :",
                  accordian_props.new_checked.variants.some(
                    (ele) =>
                      ele.product_id == accordian_props.parent_id &&
                      ele.outlet_id == o_ele.id &&
                      ele.channel_id == c_ele.id
                  )
                );
                console.log(
                  "accordian_props.new_checked.variants :",
                  accordian_props.new_checked.variants
                );
                console.log(
                  "accordian_props.parent_id :",
                  accordian_props.parent_id
                );
                console.log("o_ele.id :", o_ele.id);
                console.log("c_ele.id :", c_ele.id);

                if (
                  accordian_props.new_checked.variants.some(
                    (ele) =>
                      ele.product_id == accordian_props.parent_id &&
                      ele.outlet_id == o_ele.id &&
                      ele.channel_id == c_ele.id
                  )
                  // accordian_props.new_checked.variants.some(
                  //   (ele) =>
                  //     ele.product_id == accordian_props.product_group_id &&
                  //     ele.outlet_id == o_ele.id &&
                  //     ele.channel_id == c_ele.id,
                  // )
                ) {
                  console.log("no need to un check paroduct group");
                } else {
                  console.log("no need to un check paroduct group else paused");
                  // if (
                  //   prevValue.outlet_channel_products_assign[
                  //   f2_id + '_' + accordian_props.product_group_id
                  //   ] == 'checked'
                  // ) {
                  //   let send_data1 = {
                  //     for: 'product_group',
                  //     key:f2_id + '_' + accordian_props.product_group_id,
                  //     value:'paused',
                  //   }
                  //   accordian_props.on_new_paused1(send_data1)
                  //   prevValue.outlet_channel_products_assign[
                  //     f2_id + '_' + accordian_props.product_group_id
                  //   ] = 'paused'
                  // }
                }
              }
            }
          });
          return { ...prevValue };
        });
      }

      if (accordian_props.for == "addon_items") {
        console.log("accordian_props on addon_items:", accordian_props);
        let f3_id = f2_id + "_" + list_data.id;
        SetAllData((prevValue: any) => {
          // check/uncheck/pause self
          if (e.target.checked) {
            if (
              !prevValue.outlet_channel_addons_assign.hasOwnProperty(
                f2_id + "_" + list_data.id
              )
            ) {
              let send_data = {
                for: accordian_props.for,
                pass_data: {
                  product_id: list_data.id,
                  outlet_id: o_ele.id,
                  channel_id: c_ele.id,
                },
              };
              accordian_props.on_new_check(send_data);
              let send_data1 = {
                for: accordian_props.for,
                key: f3_id,
                value: "checked",
              };
              accordian_props.on_new_check1(send_data1);
            }
            prevValue.outlet_channel_addons_assign[f2_id + "_" + list_data.id] =
              "checked";
          } else {
            if (
              accordian_props.new_checked.addon_items.some(
                (ele) =>
                  ele.product_id == list_data.id &&
                  ele.outlet_id == o_ele.id &&
                  ele.channel_id == c_ele.id
              )
            ) {
              let index = accordian_props.new_checked.addon_items.findIndex(
                (ele) =>
                  ele.product_id == list_data.id &&
                  ele.outlet_id == o_ele.id &&
                  ele.channel_id == c_ele.id
              );
              let send_data = {
                for: accordian_props.for,
                index: index,
              };
              accordian_props.on_new_uncheck(send_data);
              let send_data1 = {
                for: "addon_items",
                key: f2_id + "_" + list_data.id,
                value: "unchecked",
              };
              accordian_props.on_new_uncheck1(send_data1);
              delete prevValue.outlet_channel_addons_assign[
                f2_id + "_" + list_data.id
              ];
            } else {
              let send_data1 = {
                for: "addon_items",
                key: f2_id + "_" + list_data.id,
                value: "paused",
              };
              accordian_props.on_new_paused1(send_data1);
              prevValue.outlet_channel_addons_assign[
                f2_id + "_" + list_data.id
              ] = "paused";
            }
          }

          // check/uncheck/pause - parent/child
          prevValue.products.map((p_ele: any) => {
            if (p_ele.id == accordian_props.product_group_id) {
              let id_values = [];

              if (p_ele.hasOwnProperty("variants")) {
                p_ele.variants.map((v_ele: any) => {
                  id_values.push(f2_id + "_" + v_ele.id);

                  if (v_ele.id == accordian_props.product_variant_id) {
                    let id_values1 = [];
                    if (v_ele.hasOwnProperty("addon_groups")) {
                      v_ele.addon_groups.map((ag_ele: any) => {
                        id_values1.push(f2_id + "_" + ag_ele.id);

                        if (ag_ele.id == accordian_props.parent_id) {
                          let id_values2 = [];
                          if (ag_ele.hasOwnProperty("addon_items")) {
                            ag_ele.addon_items.map((ai_ele: any) => {
                              id_values2.push(f2_id + "_" + ai_ele.id);
                            });
                          }

                          // addon group check/uncheck needed
                          if (ag_ele.id == accordian_props.parent_id) {
                            let f3_id = f2_id + "_" + ag_ele.id;
                            if (e.target.checked) {
                              if (
                                !prevValue.outlet_channel_addon_groups_assign.hasOwnProperty(
                                  f2_id + "_" + ag_ele.id
                                )
                              ) {
                                let send_data = {
                                  for: "addon_groups",
                                  pass_data: {
                                    product_id: ag_ele.id,
                                    outlet_id: o_ele.id,
                                    channel_id: c_ele.id,
                                  },
                                };
                                accordian_props.on_new_check(send_data);
                                let send_data1 = {
                                  for: "addon_groups",
                                  key: f3_id,
                                  value: "checked",
                                };
                                accordian_props.on_new_check1(send_data1);
                              }
                              prevValue.outlet_channel_addon_groups_assign[
                                f2_id + "_" + ag_ele.id
                              ] = "checked";
                              let send_data1 = {
                                for: "addon_groups",
                                key: f2_id + "_" + ag_ele.id,
                                value: "checked",
                              };
                              accordian_props.on_new_check1(send_data1);
                            } else {
                              if (
                                accordian_props.new_checked.addon_groups.some(
                                  (ele) =>
                                    ele.product_id == ag_ele.id &&
                                    ele.outlet_id == o_ele.id &&
                                    ele.channel_id == c_ele.id
                                )
                              ) {
                                console.log("id_values2 :", id_values2);
                                let item_value = [];
                                id_values2.map((ele) => {
                                  item_value.push(
                                    prevValue.outlet_channel_addons_assign[ele]
                                  );
                                });
                                console.log(
                                  "item_value on addon group addon item uncheck :",
                                  item_value
                                );
                                if (
                                  item_value.every(
                                    (iv_ele) => iv_ele == undefined
                                  )
                                ) {
                                  console.log("all_unchecked");
                                  let index =
                                    accordian_props.new_checked.addon_groups.findIndex(
                                      (ele) =>
                                        ele.product_id == ag_ele.id &&
                                        ele.outlet_id == o_ele.id &&
                                        ele.channel_id == c_ele.id
                                    );
                                  let send_data = {
                                    for: "addon_groups",
                                    index: index,
                                  };
                                  if (index != -1) {
                                    accordian_props.on_new_uncheck(send_data);
                                    let send_data1 = {
                                      for: "addon_groups",
                                      key: f2_id + "_" + ag_ele.id,
                                      value: "unchecked",
                                    };
                                    accordian_props.on_new_uncheck1(send_data1);
                                    delete prevValue
                                      .outlet_channel_addon_groups_assign[
                                      f2_id + "_" + ag_ele.id
                                    ];
                                  }
                                } else {
                                  console.log("not all_unchecked");
                                }
                              } else {
                                console.log("id_values2 :", id_values2);
                                let item_value = [];
                                id_values2.map((ele) => {
                                  item_value.push(
                                    prevValue.outlet_channel_addons_assign[ele]
                                  );
                                });
                                console.log("item_value :", item_value);
                                if (
                                  item_value.every(
                                    (iv_ele) => iv_ele == "paused"
                                  )
                                ) {
                                  console.log("all_paused");
                                  if (
                                    prevValue
                                      .outlet_channel_addon_groups_assign[
                                      f2_id + "_" + ag_ele.id
                                    ] == "checked"
                                  ) {
                                    let send_data1 = {
                                      for: "addon_groups",
                                      key: f2_id + "_" + ag_ele.id,
                                      value: "paused",
                                    };
                                    accordian_props.on_new_paused1(send_data1);
                                    prevValue.outlet_channel_addon_groups_assign[
                                      f2_id + "_" + ag_ele.id
                                    ] = "paused";
                                  }
                                } else {
                                  console.log("not all_paused");
                                }
                              }
                            }
                          }
                        }
                      });
                    }
                    // variant check/uncheck needed
                    if (e.target.checked) {
                      console.log("on check ");
                      if (
                        !prevValue.outlet_channel_variants_assign.hasOwnProperty(
                          f2_id + "_" + accordian_props.product_variant_id
                        )
                      ) {
                        let send_data = {
                          for: "variants",
                          pass_data: {
                            product_id: accordian_props.product_variant_id,
                            outlet_id: o_ele.id,
                            channel_id: c_ele.id,
                          },
                        };
                        accordian_props.on_new_check(send_data);
                        let send_data1 = {
                          for: "variants",
                          key: f3_id,
                          value: "checked",
                        };
                        accordian_props.on_new_check1(send_data1);
                      }
                      prevValue.outlet_channel_variants_assign[
                        f2_id + "_" + accordian_props.product_variant_id
                      ] = "checked";
                      let send_data1 = {
                        for: "variants",
                        key: f2_id + "_" + accordian_props.product_variant_id,
                        value: "checked",
                      };
                      accordian_props.on_new_check1(send_data1);
                    } else {
                      console.log(" No need to uncheck variant");
                    }
                  }
                });
              }

              // product group check/uncheck needed
              if (e.target.checked) {
                if (
                  !prevValue.outlet_channel_products_assign.hasOwnProperty(
                    f2_id + "_" + accordian_props.product_group_id
                  )
                ) {
                  let send_data = {
                    for: "product_group",
                    pass_data: {
                      product_id: accordian_props.product_group_id,
                      outlet_id: o_ele.id,
                      channel_id: c_ele.id,
                    },
                  };
                  accordian_props.on_new_check(send_data);
                  let send_data1 = {
                    for: "product_group",
                    key: f3_id,
                    value: "checked",
                  };
                  accordian_props.on_new_check1(send_data1);
                }
                prevValue.outlet_channel_products_assign[
                  f2_id + "_" + accordian_props.product_group_id
                ] = "checked";
                let send_data1 = {
                  for: "product_group",
                  key: f2_id + "_" + accordian_props.product_group_id,
                  value: "checked",
                };
                accordian_props.on_new_check1(send_data1);
              } else {
                console.log("No need to uncheck product group");
              }
            }
          });
          return { ...prevValue };
        });
      }
    }

    function available_on_change(o_ele: any, c_ele: any, e: any) {
      let f1_id, f2_id: any;
      f1_id = o_ele.id + "_" + c_ele.id;
      if (c_ele.type == "system") {
        if (all_data.hasOwnProperty("outlet_system_channels")) {
          if (all_data.outlet_system_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_system_channels[f1_id];
          }
        }
      }
      if (c_ele.type == "custom") {
        if (all_data.hasOwnProperty("outlet_custom_channels")) {
          if (all_data.outlet_custom_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_custom_channels[f1_id];
          }
        }
      }

      SetAllData((prevValue: any) => {
        if (accordian_props.for == "product_group") {
          prevValue.products.map((p_ele: any) => {
            if (p_ele.id == list_data.id) {
              if (
                prevValue.outlet_channel_products_available.hasOwnProperty(
                  f2_id + "_" + p_ele.id
                )
              ) {
                if (e.target.checked) {
                  // prevValue.outlet_channel_products_available[
                  //   f2_id + "_" + p_ele.id
                  // ] = true;
                  prevValue.outlet_channel_products_available[
                    f2_id + "_" + p_ele.id
                  ] = {
                    value: 1,
                  };
                  let send_data1 = {
                    for: "product_group",
                    key: f2_id + "_" + p_ele.id,
                    value: {
                      value: 1,
                    },
                  };
                  accordian_props.on_new_check1(send_data1);
                } else {
                  // prevValue.outlet_channel_products_available[
                  //   f2_id + "_" + p_ele.id
                  // ] = false;
                  let send_data1 = {
                    for: "product_group",
                    key: f2_id + "_" + p_ele.id,
                    value: false,
                  };
                  console.log("on un check");
                  SetBottomSheetData((prevalue: any) => {
                    prevalue.is_open = true;
                    prevalue.data = send_data1;
                    return { ...prevalue };
                  });
                  // accordian_props.on_new_uncheck1(send_data1);
                }
              }

              if (p_ele.hasOwnProperty("variants")) {
                p_ele.variants.map((v_ele: any) => {
                  if (v_ele.id == v_ele.id) {
                    if (
                      prevValue.outlet_channel_variants_available.hasOwnProperty(
                        f2_id + "_" + v_ele.id
                      )
                    ) {
                      if (e.target.checked) {
                        prevValue.outlet_channel_variants_available[
                          f2_id + "_" + v_ele.id
                        ] = true;
                        let send_data1 = {
                          for: "variants",
                          key: f2_id + "_" + v_ele.id,
                          value: true,
                        };
                        accordian_props.on_new_check1(send_data1);
                        SetNewDisabled((prevValue: any) => {
                          let index = new_disabled.variants.findIndex(
                            (some_ele) =>
                              some_ele.product_id == v_ele.id &&
                              some_ele.outlet_id == o_ele.id &&
                              some_ele.channel_id == c_ele.id
                          );
                          prevValue["variants"].splice(index, 1);
                          return { ...prevValue };
                        });
                      } else {
                        let index = new_disabled.variants.findIndex(
                          (some_ele) =>
                            some_ele.product_id == v_ele.id &&
                            some_ele.outlet_id == o_ele.id &&
                            some_ele.channel_id == c_ele.id
                        );
                        if (index == -1) {
                          SetNewDisabled((prevValue: any) => {
                            prevValue["variants"].push({
                              product_id: v_ele.id,
                              outlet_id: o_ele.id,
                              channel_id: c_ele.id,
                            });
                            return { ...prevValue };
                          });
                        }
                      }
                    }

                    if (v_ele.hasOwnProperty("addon_groups")) {
                      v_ele.addon_groups.map((ag_ele: any) => {
                        if (ag_ele.id == ag_ele.id) {
                          if (
                            prevValue.outlet_channel_addon_groups_available.hasOwnProperty(
                              f2_id + "_" + ag_ele.id
                            )
                          ) {
                            if (e.target.checked) {
                              prevValue.outlet_channel_addon_groups_available[
                                f2_id + "_" + ag_ele.id
                              ] = true;
                              let send_data1 = {
                                for: "addon_groups",
                                key: f2_id + "_" + ag_ele.id,
                                value: true,
                              };
                              accordian_props.on_new_check1(send_data1);
                              SetNewDisabled((prevValue: any) => {
                                let index = new_disabled.addon_groups.findIndex(
                                  (some_ele) =>
                                    some_ele.product_id == ag_ele.id &&
                                    some_ele.outlet_id == o_ele.id &&
                                    some_ele.channel_id == c_ele.id
                                );
                                prevValue["addon_groups"].splice(index, 1);
                                return { ...prevValue };
                              });
                            } else {
                              let index = new_disabled.addon_groups.findIndex(
                                (some_ele) =>
                                  some_ele.product_id == ag_ele.id &&
                                  some_ele.outlet_id == o_ele.id &&
                                  some_ele.channel_id == c_ele.id
                              );
                              if (index == -1) {
                                SetNewDisabled((prevValue: any) => {
                                  prevValue["addon_groups"].push({
                                    product_id: ag_ele.id,
                                    outlet_id: o_ele.id,
                                    channel_id: c_ele.id,
                                  });
                                  return { ...prevValue };
                                });
                              }
                            }
                          }

                          if (ag_ele.hasOwnProperty("addon_items")) {
                            ag_ele.addon_items.map((ai_ele: any) => {
                              if (ai_ele.id == ai_ele.id) {
                                if (
                                  prevValue.outlet_channel_addons_available.hasOwnProperty(
                                    f2_id + "_" + ai_ele.id
                                  )
                                ) {
                                  if (e.target.checked) {
                                    prevValue.outlet_channel_addons_available[
                                      f2_id + "_" + ai_ele.id
                                    ] = true;
                                    let send_data1 = {
                                      for: "addon_items",
                                      key: f2_id + "_" + ai_ele.id,
                                      value: true,
                                    };
                                    accordian_props.on_new_check1(send_data1);
                                    SetNewDisabled((prevValue: any) => {
                                      let index =
                                        new_disabled.addon_items.findIndex(
                                          (some_ele) =>
                                            some_ele.product_id == ai_ele.id &&
                                            some_ele.outlet_id == o_ele.id &&
                                            some_ele.channel_id == c_ele.id
                                        );
                                      prevValue["addon_items"].splice(index, 1);
                                      return { ...prevValue };
                                    });
                                  } else {
                                    let index =
                                      new_disabled.addon_items.findIndex(
                                        (some_ele) =>
                                          some_ele.product_id == ai_ele.id &&
                                          some_ele.outlet_id == o_ele.id &&
                                          some_ele.channel_id == c_ele.id
                                      );
                                    if (index == -1) {
                                      SetNewDisabled((prevValue: any) => {
                                        prevValue["addon_items"].push({
                                          product_id: ai_ele.id,
                                          outlet_id: o_ele.id,
                                          channel_id: c_ele.id,
                                        });
                                        return { ...prevValue };
                                      });
                                    }
                                  }
                                }
                              }
                            });
                          }
                        }
                      });
                    }
                  }
                });
              }
            }
          });
        }

        if (accordian_props.for == "variants") {
          prevValue.products.map((p_ele: any) => {
            if (p_ele.id == accordian_props.parent_id) {
              if (p_ele.hasOwnProperty("variants")) {
                let variant_value = [];
                p_ele.variants.map((v_ele: any) => {
                  if (v_ele.id == list_data.id) {
                    if (
                      prevValue.outlet_channel_variants_available.hasOwnProperty(
                        f2_id + "_" + v_ele.id
                      )
                    ) {
                      if (e.target.checked) {
                        prevValue.outlet_channel_variants_available[
                          f2_id + "_" + v_ele.id
                        ] = true;
                        let send_data1 = {
                          for: "variants",
                          key: f2_id + "_" + v_ele.id,
                          value: true,
                        };
                        accordian_props.on_new_check1(send_data1);
                      } else {
                        prevValue.outlet_channel_variants_available[
                          f2_id + "_" + v_ele.id
                        ] = false;
                        let send_data1 = {
                          for: "variants",
                          key: f2_id + "_" + v_ele.id,
                          value: false,
                        };
                        accordian_props.on_new_uncheck1(send_data1);
                      }
                    }

                    if (v_ele.hasOwnProperty("addon_groups")) {
                      v_ele.addon_groups.map((ag_ele: any) => {
                        if (ag_ele.id == ag_ele.id) {
                          if (
                            prevValue.outlet_channel_addon_groups_available.hasOwnProperty(
                              f2_id + "_" + ag_ele.id
                            )
                          ) {
                            if (e.target.checked) {
                              prevValue.outlet_channel_addon_groups_available[
                                f2_id + "_" + ag_ele.id
                              ] = true;
                              let send_data1 = {
                                for: "addon_groups",
                                key: f2_id + "_" + ag_ele.id,
                                value: true,
                              };
                              accordian_props.on_new_check1(send_data1);
                              SetNewDisabled((prevValue: any) => {
                                let index = new_disabled.addon_groups.findIndex(
                                  (some_ele) =>
                                    some_ele.product_id == ag_ele.id &&
                                    some_ele.outlet_id == o_ele.id &&
                                    some_ele.channel_id == c_ele.id
                                );
                                prevValue["addon_groups"].splice(index, 1);
                                return { ...prevValue };
                              });
                            } else {
                              let index = new_disabled.addon_groups.findIndex(
                                (some_ele) =>
                                  some_ele.product_id == ag_ele.id &&
                                  some_ele.outlet_id == o_ele.id &&
                                  some_ele.channel_id == c_ele.id
                              );
                              if (index == -1) {
                                SetNewDisabled((prevValue: any) => {
                                  prevValue["addon_groups"].push({
                                    product_id: ag_ele.id,
                                    outlet_id: o_ele.id,
                                    channel_id: c_ele.id,
                                  });
                                  return { ...prevValue };
                                });
                              }
                            }
                          }

                          if (ag_ele.hasOwnProperty("addon_items")) {
                            ag_ele.addon_items.map((ai_ele: any) => {
                              if (ai_ele.id == ai_ele.id) {
                                if (
                                  prevValue.outlet_channel_addons_available.hasOwnProperty(
                                    f2_id + "_" + ai_ele.id
                                  )
                                ) {
                                  if (e.target.checked) {
                                    prevValue.outlet_channel_addons_available[
                                      f2_id + "_" + ai_ele.id
                                    ] = true;
                                    let send_data1 = {
                                      for: "addon_items",
                                      key: f2_id + "_" + ai_ele.id,
                                      value: true,
                                    };
                                    accordian_props.on_new_check1(send_data1);
                                    SetNewDisabled((prevValue: any) => {
                                      let index =
                                        new_disabled.addon_items.findIndex(
                                          (some_ele) =>
                                            some_ele.product_id == ai_ele.id &&
                                            some_ele.outlet_id == o_ele.id &&
                                            some_ele.channel_id == c_ele.id
                                        );
                                      prevValue["addon_items"].splice(index, 1);
                                      return { ...prevValue };
                                    });
                                  } else {
                                    let index =
                                      new_disabled.addon_items.findIndex(
                                        (some_ele) =>
                                          some_ele.product_id == ai_ele.id &&
                                          some_ele.outlet_id == o_ele.id &&
                                          some_ele.channel_id == c_ele.id
                                      );
                                    if (index == -1) {
                                      SetNewDisabled((prevValue: any) => {
                                        prevValue["addon_items"].push({
                                          product_id: ai_ele.id,
                                          outlet_id: o_ele.id,
                                          channel_id: c_ele.id,
                                        });
                                        return { ...prevValue };
                                      });
                                    }
                                  }
                                }
                              }
                            });
                          }
                        }
                      });
                    }
                  }
                  if (
                    prevValue.outlet_channel_variants_available.hasOwnProperty(
                      f2_id + "_" + v_ele.id
                    )
                  ) {
                    variant_value.push(
                      prevValue.outlet_channel_variants_available[
                        f2_id + "_" + v_ele.id
                      ]
                    );
                  }
                });
                console.log("variant_value :", variant_value);
                if (
                  prevValue.outlet_channel_products_available.hasOwnProperty(
                    f2_id + "_" + p_ele.id
                  )
                ) {
                  if (e.target.checked) {
                    if (variant_value.some((ev_ele) => ev_ele == true)) {
                      prevValue.outlet_channel_products_available[
                        f2_id + "_" + p_ele.id
                      ] = true;
                      let send_data1 = {
                        for: "product_group",
                        key: f2_id + "_" + p_ele.id,
                        value: true,
                      };
                      accordian_props.on_new_check1(send_data1);
                    }
                  } else {
                    if (variant_value.every((ev_ele) => ev_ele == false)) {
                      prevValue.outlet_channel_products_available[
                        f2_id + "_" + p_ele.id
                      ] = false;
                      let send_data1 = {
                        for: "product_group",
                        key: f2_id + "_" + p_ele.id,
                        value: false,
                      };
                      accordian_props.on_new_uncheck1(send_data1);
                    }
                  }
                }
              }
            }
          });
        }

        if (accordian_props.for == "addon_groups") {
          prevValue.products.map((p_ele: any) => {
            if (p_ele.id == accordian_props.product_group_id) {
              if (p_ele.hasOwnProperty("variants")) {
                p_ele.variants.map((v_ele: any) => {
                  if (v_ele.id == accordian_props.parent_id) {
                    // prevValue.outlet_channel_variants_available[f2_id+"_"+v_ele.id] = "disabled"
                    if (v_ele.hasOwnProperty("addon_groups")) {
                      v_ele.addon_groups.map((ag_ele: any) => {
                        if (ag_ele.id == list_data.id) {
                          // prevValue.outlet_channel_addon_groups_available[f2_id+"_"+ag_ele.id] = "disabled"
                          if (
                            prevValue.outlet_channel_addon_groups_available.hasOwnProperty(
                              f2_id + "_" + ag_ele.id
                            )
                          ) {
                            if (e.target.checked) {
                              prevValue.outlet_channel_addon_groups_available[
                                f2_id + "_" + ag_ele.id
                              ] = true;
                              let send_data1 = {
                                for: "addon_groups",
                                key: f2_id + "_" + ag_ele.id,
                                value: true,
                              };
                              accordian_props.on_new_check1(send_data1);
                            } else {
                              prevValue.outlet_channel_addon_groups_available[
                                f2_id + "_" + ag_ele.id
                              ] = false;
                              let send_data1 = {
                                for: "addon_groups",
                                key: f2_id + "_" + ag_ele.id,
                                value: false,
                              };
                              accordian_props.on_new_uncheck1(send_data1);
                            }
                          }
                          if (ag_ele.hasOwnProperty("addon_items")) {
                            ag_ele.addon_items.map((ai_ele: any) => {
                              if (ai_ele.id == ai_ele.id) {
                                if (
                                  prevValue.outlet_channel_addons_available.hasOwnProperty(
                                    f2_id + "_" + ai_ele.id
                                  )
                                ) {
                                  if (e.target.checked) {
                                    prevValue.outlet_channel_addons_available[
                                      f2_id + "_" + ai_ele.id
                                    ] = true;
                                    let send_data1 = {
                                      for: "addon_items",
                                      key: f2_id + "_" + ai_ele.id,
                                      value: true,
                                    };
                                    accordian_props.on_new_check1(send_data1);
                                    SetNewDisabled((prevValue: any) => {
                                      let index =
                                        new_disabled.addon_items.findIndex(
                                          (some_ele) =>
                                            some_ele.product_id == ai_ele.id &&
                                            some_ele.outlet_id == o_ele.id &&
                                            some_ele.channel_id == c_ele.id
                                        );
                                      prevValue["addon_items"].splice(index, 1);
                                      return { ...prevValue };
                                    });
                                  } else {
                                    let index =
                                      new_disabled.addon_items.findIndex(
                                        (some_ele) =>
                                          some_ele.product_id == ai_ele.id &&
                                          some_ele.outlet_id == o_ele.id &&
                                          some_ele.channel_id == c_ele.id
                                      );
                                    if (index == -1) {
                                      SetNewDisabled((prevValue: any) => {
                                        prevValue["addon_items"].push({
                                          product_id: ai_ele.id,
                                          outlet_id: o_ele.id,
                                          channel_id: c_ele.id,
                                        });
                                        return { ...prevValue };
                                      });
                                    }
                                  }
                                }
                              }
                            });
                          }
                        }
                      });
                    }
                  }
                });
              }
            }
          });
        }

        if (accordian_props.for == "addon_items") {
          prevValue.products.map((p_ele: any) => {
            if (p_ele.id == accordian_props.product_group_id) {
              if (p_ele.hasOwnProperty("variants")) {
                p_ele.variants.map((v_ele: any) => {
                  if (v_ele.id == accordian_props.product_variant_id) {
                    if (v_ele.hasOwnProperty("addon_groups")) {
                      v_ele.addon_groups.map((ag_ele: any) => {
                        if (ag_ele.id == accordian_props.parent_id) {
                          if (ag_ele.hasOwnProperty("addon_items")) {
                            let addon_item_value = [];
                            ag_ele.addon_items.map((ai_ele: any) => {
                              if (ai_ele.id == list_data.id) {
                                if (
                                  prevValue.outlet_channel_addons_available.hasOwnProperty(
                                    f2_id + "_" + ai_ele.id
                                  )
                                ) {
                                  if (e.target.checked) {
                                    prevValue.outlet_channel_addons_available[
                                      f2_id + "_" + ai_ele.id
                                    ] = true;
                                    let send_data1 = {
                                      for: "addon_items",
                                      key: f2_id + "_" + ai_ele.id,
                                      value: true,
                                    };
                                    accordian_props.on_new_check1(send_data1);
                                  } else {
                                    prevValue.outlet_channel_addons_available[
                                      f2_id + "_" + ai_ele.id
                                    ] = false;
                                    let send_data1 = {
                                      for: "addon_items",
                                      key: f2_id + "_" + ai_ele.id,
                                      value: false,
                                    };
                                    accordian_props.on_new_uncheck1(send_data1);
                                  }
                                }
                              }
                              if (
                                prevValue.outlet_channel_addons_available.hasOwnProperty(
                                  f2_id + "_" + ai_ele.id
                                )
                              ) {
                                addon_item_value.push(
                                  prevValue.outlet_channel_addons_available[
                                    f2_id + "_" + ai_ele.id
                                  ]
                                );
                              }
                            });

                            console.log("addon_item_value :", addon_item_value);
                            if (
                              prevValue.outlet_channel_addon_groups_available.hasOwnProperty(
                                f2_id + "_" + ag_ele.id
                              )
                            ) {
                              if (e.target.checked) {
                                if (
                                  addon_item_value.some(
                                    (ev_ele) => ev_ele == true
                                  )
                                ) {
                                  prevValue.outlet_channel_addon_groups_available[
                                    f2_id + "_" + ag_ele.id
                                  ] = true;
                                  let send_data1 = {
                                    for: "addon_groups",
                                    key: f2_id + "_" + ag_ele.id,
                                    value: true,
                                  };
                                  accordian_props.on_new_check1(send_data1);
                                }
                              } else {
                                if (
                                  addon_item_value.every(
                                    (ev_ele) => ev_ele == false
                                  )
                                ) {
                                  prevValue.outlet_channel_addon_groups_available[
                                    f2_id + "_" + ag_ele.id
                                  ] = false;
                                  let send_data1 = {
                                    for: "addon_groups",
                                    key: f2_id + "_" + ag_ele.id,
                                    value: false,
                                  };
                                  accordian_props.on_new_uncheck1(send_data1);
                                }
                              }
                            }
                          }
                        }
                      });
                    }
                  }
                });
              }
            }
          });
        }

        return { ...prevValue };
      });
    }

    function get_uncheck_value_data(o_ele: any, c_ele: any) {
      let f1_id, f2_id;
      f1_id = o_ele.id + "_" + c_ele.id;
      if (c_ele.type == "system") {
        if (all_data.hasOwnProperty("outlet_system_channels")) {
          if (all_data.outlet_system_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_system_channels[f1_id];
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      if (c_ele.type == "custom") {
        if (all_data.hasOwnProperty("outlet_custom_channels")) {
          if (all_data.outlet_custom_channels.hasOwnProperty(f1_id)) {
            f2_id = all_data.outlet_custom_channels[f1_id];
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      let f3_id = f2_id + "_" + list_data.id;

      if (accordian_props.for == "product_group") {
        if (all_data.outlet_channel_products_available.hasOwnProperty(f3_id)) {
          let return_data;
          if (all_data.outlet_channel_products_available[f3_id].value == 1) {
            return_data = "";
          }
          if (all_data.outlet_channel_products_available[f3_id].value == 2) {
            return_data = "Unavailable Permanent";
          }
          if (all_data.outlet_channel_products_available[f3_id].value == 3) {
            // return_data = "Unavailable By Time";
            if (
              all_data.outlet_channel_products_available[f3_id].hasOwnProperty(
                "hours"
              )
            ) {
              return_data =
                "Available after " +
                all_data.outlet_channel_products_available[f3_id].hours +
                "Hours";
            } else {
              if (
                all_data.outlet_channel_products_available[
                  f3_id
                ].hasOwnProperty("time")
              ) {
                return_data =
                  "Available At " +
                  all_data.outlet_channel_products_available[f3_id].time;
              } else {
                return_data = "";
              }
            }
          }
          if (all_data.outlet_channel_products_available[f3_id].value == 4) {
            // return_data = "Unavailable till Next Available Slot";
            if (
              all_data.outlet_channel_products_available[f3_id].hasOwnProperty(
                "days"
              )
            ) {
              return_data =
                "Available after " +
                all_data.outlet_channel_products_available[f3_id].days +
                " Days";
            } else {
              if (
                all_data.outlet_channel_products_available[
                  f3_id
                ].hasOwnProperty("time")
              ) {
                return_data =
                  "Available At " +
                  all_data.outlet_channel_products_available[f3_id].time;
              } else {
                return_data = "";
              }
            }
          }
          if (all_data.outlet_channel_products_available[f3_id].value == 5) {
            if (
              all_data.outlet_channel_products_available[f3_id].hasOwnProperty(
                "time"
              )
            ) {
              return_data =
                "Available At " +
                all_data.outlet_channel_products_available[f3_id].time;
            } else {
              return_data = "";
            }
          }
          return return_data;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }

    return [
      <tr
        key={accordian_props.key_value}
        className={
          accordian_props.for == "product_group"
            ? "bg-white"
            : accordian_props.for == "variants"
            ? ""
            : accordian_props.for == "addon_groups"
            ? ""
            : ""
        }
      >
        <th className="p-0 td-prod">
          <div
            className={
              accordian_props.for == "product_group"
                ? "th-product-group"
                : accordian_props.for == "variants"
                ? "th-product"
                : accordian_props.for == "addon_groups"
                ? "th-addon-group"
                : "th-addon-item"
            }
          >
            <div className=" ps-3 pe-1 d-flex align-items-center ">
              <p className="me-2 py-3 th-title">
                {/* <p style={{ marginLeft: accordian_props.space + 'px' }} className="text-darkgray ">             */}
                {list_data.name}
              </p>
              {list_data.have_expand ? (
                <div
                  className="ms-auto cursor"
                  onClick={() => {
                    SetListData((prevValue: any) => {
                      // if(prevValue.expanded == true){
                      //   // close
                      //   prevValue.expanded = !prevValue.expanded
                      //   if(accordian_props.for == "product_group"){
                      //     list_data.variants.map((v_ele:any,v_index:any)=>{
                      //       v_ele.expanded = false
                      //       if(v_ele.hasOwnProperty("addon_groups")){
                      //         v_ele.addon_groups.map((aag_ele:any)=>{
                      //           aag_ele.expanded = false;
                      //           if(aag_ele.hasOwnProperty("addon_items")){
                      //             aag_ele.addon_items.map((aai_ele:any)=>{
                      //               aai_ele.expanded = false;
                      //             })
                      //           }
                      //         })
                      //       }
                      //     })
                      //   }

                      //   if(accordian_props.for == "variants"){
                      //     list_data.addon_groups.map((ag_ele:any,v_index:any)=>{
                      //       ag_ele.expanded = false
                      //       if(ag_ele.hasOwnProperty("addon_items")){
                      //         ag_ele.addon_items.map((aai_ele:any)=>{
                      //           aai_ele.expanded = false;
                      //         })
                      //       }
                      //     })
                      //   }

                      //   if(accordian_props.for == "addon_groups"){
                      //     list_data.addon_items.map((ai_ele:any,v_index:any)=>{
                      //       ai_ele.expanded = false
                      //     })
                      //   }
                      // }else{
                      //   // open
                      //   prevValue.expanded = !prevValue.expanded
                      // }
                      prevValue.expanded = !prevValue.expanded;
                      return { ...prevValue };
                    });
                  }}
                >
                  {/* {list_data.expanded ? "Close" : "Open"} */}
                  {list_data.expanded ? (
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "4fbebd04e7a5a122302d062b09356f03.svg"
                      }
                      className="w-10px"
                    />
                  ) : (
                    // <MaterialIcons
                    //   name="keyboard-arrow-down"
                    //   size={20}
                    //   color="#444"
                    // />
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "c5f4c384e454d91101b93eda429629f0.svg"
                      }
                      className="w-20px"
                    />
                    // <MaterialIcons
                    //   name="keyboard-arrow-right"
                    //   size={20}
                    //   color="#444"
                    // />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </th>
        {accordian_props.outlet_data.map(
          (o_ele: any, o_index: any) =>
            o_ele?.checked &&
            // o_index < 2 ?
            accordian_props.channel_data.map((c_ele: any, c_index: any) => (
              <td
                className=""
                key={
                  accordian_props.key_value + "_" + o_index + "_" + c_ele.name
                }
              >
                <div className="center ">
                  {props.type == "assign" ? (
                    <input
                      type="checkbox"
                      className="form-check-input cursor"
                      ref={(input) => {
                        if (input) {
                          if (props.type == "assign") {
                            let check_value = is_checked(o_ele, c_ele);
                            if (check_value == "paused") {
                              input.indeterminate = true;
                            } else {
                              input.checked = check_value;
                            }
                          } else {
                            input.checked = false;
                          }
                        }
                      }}
                      onChange={(e) => {
                        console.log("e.target.checked :", e.target.checked);
                        console.log("Outlet :", o_ele);
                        console.log("Channel :", c_ele);
                        console.log("accordian_props :", accordian_props);
                        assign_on_change(o_ele, c_ele, e);
                      }}
                    />
                  ) : (
                    <div className="text-center mt-2">
                      <div className="center  form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input cursor"
                          ref={(input) => {
                            if (input) {
                              if (props.type == "available") {
                                let check_value = available_is_checked(
                                  o_ele,
                                  c_ele
                                );
                                let disable_value = available_is_disabled(
                                  o_ele,
                                  c_ele
                                );
                                input.checked = check_value;
                                input.disabled = disable_value;
                              } else {
                                input.checked = false;
                                input.disabled = true;
                              }
                            }
                          }}
                          onChange={(e) => {
                            console.log("e.target.checked :", e.target.checked);
                            console.log("Outlet :", o_ele);
                            console.log("Channel :", c_ele);
                            console.log("accordian_props :", accordian_props);
                            available_on_change(o_ele, c_ele, e);
                          }}
                        />
                      </div>
                      <p className="mt-2 v-small text-gray">
                        {get_uncheck_value_data(o_ele, c_ele)}
                      </p>
                    </div>
                  )}
                </div>
              </td>
            ))
          // : null
        )}
      </tr>,
      list_data.expanded && (
        <>
          {accordian_props.for == "product_group"
            ? list_data.variants.map((v_ele: any, v_index: any) => (
                <OwnAccordion
                  key_value={
                    accordian_props.key_value + "_" + v_index + "_" + v_ele.name
                  }
                  // key_value={v_index}
                  ele={v_ele}
                  parent_id={list_data.id}
                  p_index={accordian_props.p_index}
                  for={"variants"}
                  outlet_data={outlet_data}
                  channel_data={accordian_props.channel_data}
                  space={"25"}
                  new_checked={accordian_props.new_checked}
                  on_new_check={(data) => {
                    accordian_props.on_new_check(data);
                    // SetNewChecked((prevValue:any)=>{
                    //   prevValue[data.for].push(data.pass_data)
                    //   return { ...prevValue}
                    // })
                  }}
                  on_new_uncheck={(data) => {
                    accordian_props.on_new_uncheck(data);
                    // SetNewChecked((prevValue:any)=>{
                    //   prevValue[data.for].splice(data.index,1)
                    //   return { ...prevValue}
                    // })
                  }}
                  on_new_check1={(data) => {
                    SetNewChecked1((prevValue: any) => {
                      // prevValue[data.for].push(data.pass_data)
                      prevValue[data.for][data.key] = data.value;
                      return { ...prevValue };
                    });
                  }}
                  on_new_uncheck1={(data) => {
                    SetNewChecked1((prevValue: any) => {
                      // prevValue[data.for].splice(data.index, 1)
                      prevValue[data.for][data.key] = data.value;
                      return { ...prevValue };
                    });
                  }}
                  on_new_paused1={(data) => {
                    SetNewChecked1((prevValue: any) => {
                      // prevValue[data.for].splice(data.index, 1)
                      prevValue[data.for][data.key] = data.value;
                      return { ...prevValue };
                    });
                  }}
                  all_data={all_data}
                />
              ))
            : null}

          {accordian_props.for == "variants"
            ? list_data.addon_groups.map((ag_ele: any, ag_index: any) => (
                <OwnAccordion
                  type="child"
                  key_value={
                    accordian_props.key_value +
                    "_" +
                    ag_index +
                    "_" +
                    ag_ele.name
                  }
                  // key_value={ag_index}
                  ele={ag_ele}
                  p_index={accordian_props.p_index}
                  product_group_id={accordian_props.parent_id}
                  parent_id={list_data.id}
                  for={"addon_groups"}
                  outlet_data={outlet_data}
                  channel_data={accordian_props.channel_data}
                  space={"50"}
                  new_checked={accordian_props.new_checked}
                  on_new_check={(data) => {
                    accordian_props.on_new_check(data);
                    // SetNewChecked((prevValue:any)=>{
                    //   prevValue[data.for].push(data.pass_data)
                    //   return { ...prevValue}
                    // })
                  }}
                  on_new_uncheck={(data) => {
                    accordian_props.on_new_uncheck(data);
                    // SetNewChecked((prevValue:any)=>{
                    //   prevValue[data.for].splice(data.index,1)
                    //   return { ...prevValue}
                    // })
                  }}
                  on_new_check1={(data) => {
                    SetNewChecked1((prevValue: any) => {
                      // prevValue[data.for].push(data.pass_data)
                      prevValue[data.for][data.key] = data.value;
                      return { ...prevValue };
                    });
                  }}
                  on_new_uncheck1={(data) => {
                    SetNewChecked1((prevValue: any) => {
                      // prevValue[data.for].splice(data.index, 1)
                      prevValue[data.for][data.key] = data.value;
                      return { ...prevValue };
                    });
                  }}
                  on_new_paused1={(data) => {
                    SetNewChecked1((prevValue: any) => {
                      // prevValue[data.for].splice(data.index, 1)
                      prevValue[data.for][data.key] = data.value;
                      return { ...prevValue };
                    });
                  }}
                  all_data={all_data}
                />
              ))
            : null}

          {accordian_props.for == "addon_groups"
            ? list_data.addon_items.map((ai_ele: any, ai_index: any) => (
                <OwnAccordion
                  key_value={
                    accordian_props.key_value +
                    "_" +
                    ai_index +
                    "_" +
                    ai_index.name
                  }
                  // key_value={ai_index}
                  ele={ai_ele}
                  p_index={accordian_props.p_index}
                  product_group_id={accordian_props.product_group_id}
                  product_variant_id={accordian_props.parent_id}
                  parent_id={list_data.id}
                  for={"addon_items"}
                  outlet_data={outlet_data}
                  channel_data={accordian_props.channel_data}
                  space={"75"}
                  new_checked={accordian_props.new_checked}
                  on_new_check={(data) => {
                    accordian_props.on_new_check(data);
                    // SetNewChecked((prevValue:any)=>{
                    //   prevValue[data.for].push(data.pass_data)
                    //   return { ...prevValue}
                    // })
                  }}
                  on_new_uncheck={(data) => {
                    accordian_props.on_new_uncheck(data);
                    // SetNewChecked((prevValue:any)=>{
                    //   prevValue[data.for].splice(data.index,1)
                    //   return { ...prevValue}
                    // })
                  }}
                  on_new_check1={(data) => {
                    SetNewChecked1((prevValue: any) => {
                      // prevValue[data.for].push(data.pass_data)
                      prevValue[data.for][data.key] = data.value;
                      return { ...prevValue };
                    });
                  }}
                  on_new_uncheck1={(data) => {
                    SetNewChecked1((prevValue: any) => {
                      // prevValue[data.for].splice(data.index, 1)
                      prevValue[data.for][data.key] = data.value;
                      return { ...prevValue };
                    });
                  }}
                  on_new_paused1={(data) => {
                    SetNewChecked1((prevValue: any) => {
                      // prevValue[data.for].splice(data.index, 1)
                      prevValue[data.for][data.key] = data.value;
                      return { ...prevValue };
                    });
                  }}
                  all_data={all_data}
                />
              ))
            : null}
        </>
      ),
    ];
  };

  async function assign_submit() {
    console.log("assign_submit :", all_data);
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        // data: {
        //   // outlet_products: all_data.outlet_products_assign,
        //   // outlet_variants: all_data.outlet_variants_assign,
        //   // outlet_addon_groups: all_data.outlet_addon_groups_assign,
        //   // outlet_addons: all_data.outlet_addons_assign,
        //   outlet_channel_products: all_data.outlet_channel_products_assign,
        //   outlet_channel_variants: all_data.outlet_channel_variants_assign,
        //   outlet_channel_addon_groups:
        //     all_data.outlet_channel_addon_groups_assign,
        //   outlet_channel_addons: all_data.outlet_channel_addons_assign,
        // },
        data: {
          outlet_channel_products: new_checked1.product_group,
          outlet_channel_variants: new_checked1.variants,
          outlet_channel_addon_groups: new_checked1.addon_groups,
          outlet_channel_addons: new_checked1.addon_items,
        },
      },
    };
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    console.log("save_outlet_channel_products pass_data :", pass_data);
    console.log("save_outlet_channel_products all data :", all_data);
    console.log("save_outlet_channel_products new_checked1 :", new_checked1);

    let response = await api(
      "/brand/save_outlet_channel_products",
      pass_data,
      false,
      false,
      "raw_data"
    );
    console.log("/brand/save_outlet_channel_products response :", response);
    if (response.status_code == 200) {
      toast.success("Update Successful");
      // get_products(filter_data, false);
      SetNewChecked({
        product_group: [],
        variants: [],
        addon_groups: [],
        addon_items: [],
      });
      SetNewDisabled({
        product_group: [],
        variants: [],
        addon_groups: [],
        addon_items: [],
      });
      SetNewChecked1({
        product_group: {},
        variants: {},
        addon_groups: {},
        addon_items: {},
      });
    }
  }

  async function available_submit() {
    console.log("available_submit all_data :", all_data);
    console.log("available_submit new_disabled :", new_disabled);
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        // data: {
        //   // outlet_products : all_data.outlet_products_assign,
        //   outlet_products_available: all_data.outlet_products_available,
        //   // outlet_variants : all_data.outlet_variants_assign,
        //   outlet_variants_available: all_data.outlet_variants_available,
        //   // outlet_addon_groups : all_data.outlet_addon_groups_assign,
        //   outlet_addon_groups_available: all_data.outlet_addon_groups_available,
        //   // outlet_addons : all_data.outlet_addons_assign,
        //   outlet_addons_available: all_data.outlet_addons_available,
        //   // outlet_channel_products : all_data.outlet_channel_products_assign,
        //   outlet_channel_products_available:
        //     all_data.outlet_channel_products_available,
        //   // outlet_channel_variants : all_data.outlet_channel_variants_assign,
        //   outlet_channel_variants_available:
        //     all_data.outlet_channel_variants_available,
        //   // outlet_channel_addon_groups : all_data.outlet_channel_addon_groups_assign,
        //   outlet_channel_addon_groups_available:
        //     all_data.outlet_channel_addon_groups_available,
        //   // outlet_channel_addons : all_data.outlet_channel_addons_assign,
        //   outlet_channel_addons_available:
        //     all_data.outlet_channel_addons_available,
        //   // outlet_channel_products_approved : all_data.outlet_channel_products_approved,
        // },
        data: {
          outlet_channel_products_available: new_checked1.product_group,
          outlet_channel_variants_available: new_checked1.variants,
          outlet_channel_addon_groups_available: new_checked1.addon_groups,
          outlet_channel_addons_available: new_checked1.addon_items,
        },
      },
    };
    if (id_value?.outlet_id) {
      pass_data.get.outlet = id_value?.outlet_id;
    }
    console.log("save_outlet_channel_products pass_data :", pass_data);
    console.log("save_outlet_channel_products all data :", all_data);
    console.log("save_outlet_channel_products new_checked1 :", new_checked1);
    // let response = await api(
    //   "/brand/save_outlet_channel_products",
    //   pass_data,
    //   false,
    //   false,
    //   "raw_data"
    // );
    let response: any = await api(
      "/brand/save_outlet_channel_products_v2",
      pass_data,
      false,
      false,
      "raw_data"
    );
    console.log("/brand/save_outlet_channel_products response :", response);
    if (response.status_code == 200) {
      toast.success("Update Successful");
      SetNewChecked({
        product_group: [],
        variants: [],
        addon_groups: [],
        addon_items: [],
      });
      SetNewDisabled({
        product_group: [],
        variants: [],
        addon_groups: [],
        addon_items: [],
      });
      SetNewChecked1({
        product_group: {},
        variants: {},
        addon_groups: {},
        addon_items: {},
      });
      // get_available_products(filter_data, false);
    }
  }

  function on_next() {
    SetFilterData((prevValue: any) => {
      prevValue.page_no = prevValue.page_no + 1;
      if (props.type == "assign") {
        get_products(prevValue, true);
      } else {
        get_available_products(prevValue, true);
      }
      return { ...prevValue };
    });
  }

  function on_prev() {
    SetFilterData((prevValue: any) => {
      prevValue.page_no = prevValue.page_no - 1;
      if (props.type == "assign") {
        get_products(prevValue, false);
      } else {
        get_available_products(prevValue, false);
      }
      return { ...prevValue };
    });
  }

  function submit_uncheck_time() {
    console.log("submit_uncheck_time");
    SetBottomSheetData((prevalue: any) => {
      let send_data = prevalue.data;
      SetAllData((all_data_prevalue: any) => {
        // all_data_prevalue.outlet_channel_product_variants_available[
        //   send_data.key
        // ] = false;
        // let new_key_value = send_data.key + "_new"
        let new_key_value = send_data.key;
        SetUncheckRadioValue((radio_prevalue: any) => {
          SetNewChecked1((n_c_prevValue: any) => {
            if (radio_prevalue.checked_value == "2 Hours") {
              all_data_prevalue.outlet_channel_products_available[
                new_key_value
              ] = {
                value: 3,
                hours: 2,
              };
              n_c_prevValue[send_data.for][send_data.key] = {
                value: 3,
                hours: 2,
              };
            }
            if (radio_prevalue.checked_value == "4 Hours") {
              all_data_prevalue.outlet_channel_products_available[
                new_key_value
              ] = {
                value: 3,
                hours: 4,
              };
              n_c_prevValue[send_data.for][send_data.key] = {
                value: 3,
                hours: 4,
              };
            }
            if (radio_prevalue.checked_value == "1 Day") {
              all_data_prevalue.outlet_channel_products_available[
                new_key_value
              ] = {
                value: 4,
                days: 1,
              };
              n_c_prevValue[send_data.for][send_data.key] = {
                value: 4,
                days: 1,
              };
            }
            if (radio_prevalue.checked_value == "2 Day") {
              all_data_prevalue.outlet_channel_products_available[
                new_key_value
              ] = {
                value: 4,
                days: 2,
              };
              n_c_prevValue[send_data.for][send_data.key] = {
                value: 4,
                days: 2,
              };
            }
            if (radio_prevalue.checked_value == "Next Available Slot") {
              all_data_prevalue.outlet_channel_products_available[
                new_key_value
              ] = {
                value: 6,
              };
              n_c_prevValue[send_data.for][send_data.key] = {
                value: 6,
              };
            }
            if (radio_prevalue.checked_value == "Custom Date & Time") {
              all_data_prevalue.outlet_channel_products_available[
                new_key_value
              ] = {
                value: 5,
                time: radio_prevalue.other_value,
              };
              n_c_prevValue[send_data.for][send_data.key] = {
                value: 5,
                time: radio_prevalue.other_value,
              };
            }
            if (radio_prevalue.checked_value == "I will turn it on myself") {
              all_data_prevalue.outlet_channel_products_available[
                new_key_value
              ] = {
                value: 2,
              };
              n_c_prevValue[send_data.for][send_data.key] = {
                value: 2,
              };
            }
            return { ...n_c_prevValue };
          });
          return { ...radio_prevalue };
        });
        return { ...all_data_prevalue };
      });
      prevalue.is_open = false;
      prevalue.data = {};
      return { ...prevalue };
    });
    // SetBottomSheetData((prevalue: any) => {
    //   prevalue.is_open = false;
    //   prevalue.data = {};
    //   return { ...prevalue };
    // });
  }

  function next_slot_available() {
    let return_data = false;
    // all_data?.outlet_channel_products_available[
    //   bottom_sheet_data.data.key
    // ].hasOwnProperty("next_slot")
    //   ? false
    //   : true
    SetBottomSheetData((bottom_prevalue: any) => {
      SetAllData((all_data_prevalue: any) => {
        if (bottom_prevalue.is_open) {
          if (
            all_data_prevalue.outlet_channel_products_available[
              bottom_prevalue.data.key
            ].hasOwnProperty("next_slot")
          ) {
            return_data = false;
          } else {
            return_data = true;
          }
        } else {
          return_data = false;
        }
        return all_data_prevalue;
      });
      return bottom_prevalue;
    });
    return return_data;
  }

  function is_submit_disabled() {
    let return_data = false;
    if (Object.keys(new_checked1).length > 0) {
      // if (Object.keys(new_checked1.product_group).length > 0) {
      //   return_data = false;
      // } else {
      //   return_data = true;
      // }
      if (Object.keys(new_checked1.product_group).length > 0) {
        return_data = false;
      } else if(Object.keys(new_checked1.variants).length > 0) {
        return_data = false;
      }else {
        return_data = true;
      }
    } else {
      return_data = true;
    }
    return return_data;
  }

  return (
    <div className="checktable-page">
      <Toaster position="top-center" reverseOrder={false} />
      {/* Header */}
      <div
        className={
          props.is_menu_open
            ? product_data.length == 0
              ? " head-bar w-100"
              : " head-bar sticky-sm"
            : "head-bar sticky-top"
        }
        style={
          props.is_menu_open == false
            ? { width: width }
            : product_data.length != 0
            ? { width: width_ref + 46 }
            : {}
        }
      >
        <div className=" d-flex align-items-center">
          <h6 className="mb-0">{props.title}</h6>
        </div>

        <div className="row align-items-cetner pt-2">
          <div className="col-md-7 p-0 mb-sm-2 mb-md-0">
            <div className="">
              {/* <AliceCarousel
                mouseTracking
                touchMoveDefaultEvents={false}
                // items={productsRestraunt}
                // paddingLeft={50}
                paddingRight={30}
                disableDotsControls
                responsive={responsive}
                disableButtonsControls
                controlsStrategy="alternate"
              > */}
              <div className="mt-1 d-flex align-items-center bg-fff ps-0 p-2 md-px-3 ">
                <div className="d-flex align-items-center scrollX">
                  {filter_data.view_option.map((options, o_index) => (
                    // <div
                    //   key={o_index}
                    //   className="text-center cursor me-2 mt-1"

                    //   onDragStart={handleDragStart}
                    // >
                    <button
                      type="button"
                      className={
                        filter_data.view.label == options.label
                          ? "btn btn-text header-tap me-1 px-3 center"
                          : "btn btn-text text-black me-1 px-3 center"
                      }
                      onClick={(e) => {
                        console.log("Brand on change e :", e);
                        console.log("Brand on change options :", options);
                        SetFilterData((prevValue: any) => {
                          prevValue.view = options;
                          prevValue.page_no = 1;
                          if (props.type == "assign") {
                            get_products(prevValue, false);
                          } else {
                            get_available_products(prevValue, false);
                          }
                          return { ...prevValue };
                        });
                      }}
                    >
                      {options.label}
                    </button>
                    // </div>
                  ))}
                </div>
              </div>
              {/* </AliceCarousel> */}
            </div>
          </div>
          {/* search */}
          <div className="col-md-3 col-10 col-lg-4 p-0">
            <div className="row px-md-2 md-none">
              <div className="input-icons form-group">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search products"
                  value={filter_data.search}
                  onChange={(e) => {
                    console.log("search value :", e.target.value);
                    SetFilterData((prevValue: any) => {
                      prevValue.search = e.target.value;
                      return { ...prevValue };
                    });
                  }}
                />
                <div className="cursor ps-2 ">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "c241785c497fb98f5f7d4e587b3857ba.svg"
                    }
                    className="w-20px"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* filter */}
          <div className="col-2 col-lg-1 p-0 ">
            <div
              className="center cursor pt-2"
              onClick={(event) => {
                if (width < 768) {
                  filter_fun("bottom", true, event);
                } else {
                  filter_fun("right", true, event);
                }
              }}
            >
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "311ae78abd7e491e4b25539cfbf1a03f.png"
                }
                className="type-img-big"
              />
              {/* <Ionicons name="filter" size={18} color="black" /> */}
              {width > 768 ? (
                <p className="text-darkgray mb-0 ps-2">Filter</p>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          props.is_menu_open
            ? product_data.length == 0
              ? "mt-3 px-md-3 px-sm-0 w-100"
              : "mt-3 px-md-3 px-sm-0"
            : "mb-5 mt-3 px-md-3 px-sm-0 overflow-hidden"
        }
        style={
          props.is_menu_open == false
            ? { width: width }
            : product_data.length != 0
            ? { width: width_ref + 46 }
            : {}
        }
      >
        {/* Filter */}
        {/* <div className="mt-3 pt-3 d-md-block d-sm-none bg-fff  px-3">
          <p className='text-black  mb-4 fs-5'>Assigning filters </p>
          {/* <div className="col-md-6">
              <label>View</label>
              <Select
                components={animatedComponents}
                className="basic-select"
                classNamePrefix="select Brand"
                placeholder="Select Brand"
                id="brand"
                // isMulti
                Group
                value={filter_data.view}
                options={filter_data.view_option}
                onChange={(e, action) => {
                  console.log('Brand on change e :', e)
                  console.log('Brand on change action :', action)
                  const timeOutId = setTimeout(() => {
                    SetFilterData((prevValue: any) => {
                      prevValue['view'] = e
                      prevValue.page_no = 1
                      get_products(prevValue)
                      return { ...prevValue }
                    })
                  }, 500)
                  return () => clearTimeout(timeOutId)
                }}
              />
            </div> /}

          <AssignAvailableFilterComponent
            filter_data={filter_data}
            onChange={(data) => {
              const timeOutId = setTimeout(() => {
                SetFilterData((prevValue: any) => {
                  prevValue[data.key] = data.value
                  prevValue.page_no = 1
                  get_products(prevValue)
                  return { ...prevValue }
                })
              }, 500)
              return () => clearTimeout(timeOutId)
            }}
          />
        </div> */}

        <div ref={ref} className="pb-5">
          {/* Body */}
          <div className="p-3 mt-2 w-100 bg-fff">
            {is_loading ? (
              <div className="mt-3 table-responsive">
                <table className="table table-bordered border table-bordered table-condensed">
                  <thead className="sticky-table-head bg-fff">
                    <tr className="th-prod">
                      <th className="bg-fff col p-0" rowSpan="2">
                        <div className="px-3 py-4">
                          <Skeleton width={100} />
                        </div>
                      </th>
                      {Array.from({ length: columns }).map((_, index) => (
                        <th className="col p-0" key={index} colSpan={columns}>
                          <div className="text-center p-2">
                            <Skeleton width={80} />
                          </div>
                        </th>
                      ))}
                    </tr>
                    <tr>
                      {Array.from({ length: columns }).map((_, o_index) =>
                        Array.from({ length: columns }).map((_, c_index) => (
                          <th className="col p-0" key={o_index + "_" + c_index}>
                            <div className="p-2 text-center">
                              <Skeleton width={50} />
                            </div>
                          </th>
                        ))
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: rows }).map((_, index) => (
                      <tr key={index}>
                        <td>
                          <Skeleton width={100} />
                        </td>
                        {Array.from({ length: columns * columns }).map(
                          (_, c_index) => (
                            <td key={c_index}>
                              <Skeleton width={80} />
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : product_data.length == 0 ? (
              <div className="center p-3 bg-fff">
                <div>
                  <img
                    src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img"
                  />
                  <p className="text-center">No data found!</p>
                </div>
              </div>
            ) : (
              <div className="mt-3 table-responsive  table-container fixTableHead ">
                <InfiniteScroll
                  // className="row"
                  dataLength={product_data?.length}
                  next={() => {
                    on_next();
                  }}
                  hasMore={filter_data.next_page}
                  loader={
                    <div className="d-flex align-items-center justify-content-center text-center p-5">
                      <div className="lds-dual-ring"></div>
                      <span className=" small ps-2">Just a second ...</span>
                    </div>
                  }
                >
                  <table className="table align-middle table-bordered table-condensed">
                    {/* Head */}
                    <thead className="bg-fff sticky-table-head">
                      <tr>
                        <th
                          className="text-center col p-0 bg-fff th-prod"
                          rowSpan="2"
                        >
                          <div className="p-2 text-center py-4">Product Name</div>
                        </th>
                        {outlet_data.map(
                          (o_ele: any, o_index: any) =>
                            o_ele?.checked && (
                              <th
                                className="text-center col p-0"
                                key={o_index + "_" + o_ele.name}
                                colSpan={channel_data.length}
                              >
                                <div className="p-2 text-center py-4">
                                  {o_ele.name}
                                </div>
                              </th>
                            )
                        )}
                      </tr>
                      <tr>
                        {outlet_data.map(
                          (o_ele: any, o_index: any) =>
                            o_ele?.checked &&
                            channel_data.map((c_ele: any, c_index: any) => (
                              // c_index < 2? (
                              <th
                                className="p-0 col text-center"
                                key={
                                  c_index + "_" + o_ele.name + "_" + c_ele.name
                                }
                              >
                                <div className="p-2 text-center">
                                  {c_ele.name}
                                </div>
                              </th>
                              // ) : null
                            ))
                        )}
                      </tr>
                    </thead>
                    {/* Body */}
                    <tbody>
                      {product_data.map((p_ele: any, p_index: any) => (
                        <OwnAccordion
                          type="parent"
                          key_value={p_index + "_" + p_ele.name}
                          ele={p_ele}
                          for={"product_group"}
                          parent_id={0}
                          p_index={p_index}
                          outlet_data={outlet_data}
                          channel_data={channel_data}
                          space={"0"}
                          new_checked={new_checked}
                          on_new_check={(data) => {
                            SetNewChecked((prevValue: any) => {
                              prevValue[data.for].push(data.pass_data);
                              return { ...prevValue };
                            });
                          }}
                          on_new_uncheck={(data) => {
                            SetNewChecked((prevValue: any) => {
                              prevValue[data.for].splice(data.index, 1);
                              return { ...prevValue };
                            });
                          }}
                          on_new_check1={(data) => {
                            SetNewChecked1((prevValue: any) => {
                              // prevValue[data.for].push(data.pass_data)
                              prevValue[data.for][data.key] = data.value;
                              return { ...prevValue };
                            });
                          }}
                          on_new_uncheck1={(data) => {
                            SetNewChecked1((prevValue: any) => {
                              // prevValue[data.for].splice(data.index, 1)
                              prevValue[data.for][data.key] = data.value;
                              return { ...prevValue };
                            });
                          }}
                          on_new_paused1={(data) => {
                            SetNewChecked1((prevValue: any) => {
                              // prevValue[data.for].splice(data.index, 1)
                              prevValue[data.for][data.key] = data.value;
                              return { ...prevValue };
                            });
                          }}
                          // all_data = {all_data}
                        />
                      ))}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            )}
          </div>
        </div>
        {/* pagination */}
        {/* <div className="d-flex align-items-center my-3">
          {filter_data.page_no > 1 ? (
            <button
              className="me-2 center btn btn-white btn-radius fw-bold btn-sm"
              onClick={() => {
                console.log("Prev");
                on_prev();
              }}
            >
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "3172f69196e958f3bc4edb405f97ce29.svg"
                }
                className="w-20px"
              />
              <p className="text-prime ps-1">Prev</p>
            </button>
          ) : null}

          {filter_data.next_page ? (
            <button
              className="center btn btn-white btn-radius fw-bold btn-sm"
              onClick={() => {
                console.log("Next");
                on_next();
              }}
            >
              {" "}
              <p className="text-prime pe-1">Next</p>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "ffb1885fc1b6450d7b6c9652189cdcb2.svg"
                }
                className="w-20px"
              />
            </button>
          ) : null}
        </div> */}
        {/* submit */}
        <div
          style={
            props.is_menu_open == false
              ? { width: width }
              : product_data.length != 0
              ? { width: width_ref }
              : {}
          }
          className="align-items-center bg-fff p-2 bottom-sm-50px-fixed bottom-md-0px-fixed sm-w-100 md-w-fill"
        >
          {product_data.length != 0 ? (
            <div className="ms-auto">
              <button
                // className="btn bg-prime text-white btn-radius py-1 px-3 btn-sm"
                className={
                  is_submit_disabled()
                    ? "btn bg-prime text-white btn-radius fw-bold py-1 px-3 btn-lg footer_button"
                    : "btn bg-prime text-white btn-radius fw-bold py-1 px-3 btn-lg footer_button"
                }
                onClick={() => {
                  console.log("submit", new_checked1, is_submit_disabled());
                  console.log("new_checked1 :",new_checked1)
                  if (!is_submit_disabled()) {
                    if (props.type == "assign") {
                      assign_submit();
                    } else if (props.type == "available") {
                      available_submit();
                    }
                  }
                }}
              >
                Submit
              </button>
            </div>
          ) : null}
        </div>
      </div>

      {bottom_sheet_data.is_open ? (
        <div className="out_of_stock_swipe">
          <SwipeableBottomSheet
            overflowHeight={0}
            overlay={true}
            style={{ zIndex: 1300 }}
            open={bottom_sheet_data.is_open}
            onClose={() => {
              SetBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                return { ...prevalue };
              });
              SetUncheckRadioValue({
                checked_value: "",
                other_value: "",
              });
            }}
            onChange={() => {
              // SetFilterOpen(false)
              SetBottomSheetData((prevalue: any) => {
                prevalue.is_open = false;
                prevalue.data = {};
                return { ...prevalue };
              });
              SetUncheckRadioValue({
                checked_value: "",
                other_value: "",
              });
            }}
          >
            <div
              className="bg-fff radius-top"
              style={{ minHeight: "500px", maxHeight: "90vh" }}
            >
              <div className="">
                <div className="w-100 center">
                  <hr className="hr-drawer" />
                </div>
                <div className="p-3">
                  <h6 className="fs-5 fw-600">Set time to make out of stock</h6>
                </div>
              </div>
              <div className="p-3">
                <div className="mb-3 row">
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"2 Hours"}
                      checked={
                        uncheck_radio_value.checked_value == "2 Hours"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        console.log("name event :", event);
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">2 Hours</p>
                  </label>

                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"4 Hours"}
                      checked={
                        uncheck_radio_value.checked_value == "4 Hours"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">4 Hours</p>
                  </label>

                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"1 Day"}
                      checked={
                        uncheck_radio_value.checked_value == "1 Day"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">1 Day</p>
                  </label>

                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"2 Day"}
                      checked={
                        uncheck_radio_value.checked_value == "2 Day"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">2 Days </p>
                  </label>

                  <label
                    className={
                      // checked == false
                      all_data?.outlet_channel_products_available[
                        bottom_sheet_data.data.key
                      ].hasOwnProperty("next_slot")
                        ? "form-label d-flex align-items-center mb-3 cursor"
                        : "form-label d-flex align-items-center mb-3 cursor border-light-gray"
                    }
                  >
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"Next Available Slot"}
                      // disabled={next_slot_available()}
                      disabled={
                        all_data?.outlet_channel_products_available[
                          bottom_sheet_data.data.key
                        ].hasOwnProperty("next_slot")
                          ? false
                          : true
                      }
                      checked={
                        uncheck_radio_value.checked_value ==
                        "Next Available Slot"
                          ? true
                          : false
                      }
                      // value={ele.id}
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p
                      className={
                        // checked == false
                        all_data?.outlet_channel_products_available[
                          bottom_sheet_data.data.key
                        ].hasOwnProperty("next_slot")
                          ? "capitalize"
                          : "capitalize text-light-gray"
                      }
                    >
                      {all_data?.outlet_channel_products_available[
                        bottom_sheet_data.data.key
                      ].hasOwnProperty("next_slot")
                        ? "Next Available Slot At " +
                          all_data?.outlet_channel_products_available[
                            bottom_sheet_data.data.key
                          ].next_slot
                        : "Next Available Slot"}
                    </p>
                  </label>

                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"Custom Date & Time"}
                      // value={ele.id}
                      checked={
                        uncheck_radio_value.checked_value ==
                        "Custom Date & Time"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">Custom Date & Time</p>
                  </label>
                </div>

                {uncheck_radio_value.checked_value == "Custom Date & Time" ? (
                  <div className="px-0 w-50">
                    <input
                      type="datetime-local"
                      className="me-2 form-control"
                      min={new Date().toJSON().slice(0, 10)}
                      onChange={(event: any) => {
                        console.log("date event :", event);
                        let value = event.target.value;
                        let formattedDate = value.split("T");
                        console.log("formattedDate :", formattedDate);
                        var timeSplit = formattedDate[1].split(":");
                        var hours = parseInt(timeSplit[0]);
                        var minutes = parseInt(timeSplit[1]);

                        // Determine AM or PM
                        var period = hours >= 12 ? "PM" : "AM";

                        // Convert hours to 12-hour format
                        hours = hours % 12;
                        hours = hours ? hours : 12; // 0 should be 12

                        // Add leading zero to minutes if needed
                        minutes = minutes < 10 ? "0" + minutes : minutes;

                        // Return the formatted time
                        let time_value = hours + ":" + minutes + " " + period;
                        console.log("time_value :", time_value);
                        let other_value = formattedDate[0] + " " + time_value;
                        console.log("other_value :", other_value);
                        SetUncheckRadioValue((preavlue: any) => {
                          // preavlue.checked_value = event.target.name;
                          preavlue.other_value = other_value;
                          return { ...preavlue };
                        });
                      }}
                    />
                  </div>
                ) : null}
              </div>

              <div className="px-3">
                <div className="d-flex ">
                  <p className="text-dark fw-bold">Manualy turn on</p>
                </div>

                <div className="my-3">
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="radio"
                      className="form-check-input me-2"
                      name={"I will turn it on myself"}
                      // value={ele.id}
                      checked={
                        uncheck_radio_value.checked_value ==
                        "I will turn it on myself"
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        SetUncheckRadioValue((preavlue: any) => {
                          preavlue.checked_value = event.target.name;
                          preavlue.other_value = "";
                          return { ...preavlue };
                        });
                      }}
                    />
                    <p className="capitalize">I will turn it on myself</p>
                  </label>
                </div>
              </div>

              <div className="p-3">
                <div className="end">
                  <button
                    className="btn btn-primary btn-lg btn-block"
                    onClick={() => {
                      submit_uncheck_time();
                    }}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </SwipeableBottomSheet>
        </div>
      ) : null}

      {/* Filter */}
      {["left", "right", "top", "bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={(event) => {
              filter_fun(anchor, false, event);
            }}
            onOpen={(event) => {
              filter_fun(anchor, true, event);
            }}
          >
            {/* <div className="bg-fff p-3">
              <div className="d-flex ">
                <h6>Filter</h6>
                <div
                  className="ms-auto cursor"
                  onClick={() => {
                    filter_fun(anchor, false, event)
                  }}
                >
                  <small>Clear</small>
                </div>
              </div>
              <div className="my-3">
                <label className="form-label d-flex align-items-center">
                  <input type="checkbox" className="form-check me-2" />
                  <small>Own Brand Products</small>
                </label>
                <label className="form-label d-flex align-items-center">
                  <input type="checkbox" className="form-check me-2" />
                  <small>Other Brand Products</small>
                </label>
              </div>

              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="acc-head-id">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#acc-id"
                      aria-expanded="false"
                      aria-controls="acc-id"
                    >
                      Brand
                    </button>
                  </h2>
                  <div
                    id="acc-id"
                    className="accordion-collapse collapse "
                    aria-labelledby="acc-head-id"
                  >
                    <div className="accordion-body">
                      <input
                        type="text"
                        placeholder="Search..."
                        className="form-control"
                      />
                      <div className="p-2">
                        <label className="form-label mt-3 d-flex align-items-center">
                          <input type="checkbox" className="me-2" />
                          Polo
                        </label>
                        <label className="form-label mt-3 d-flex align-items-center">
                          <input type="checkbox" className="me-2" />
                          Polo
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <Typography id="range-slider" gutterBottom>
                  Mininum Maximum Price
                </Typography>
                <div className="d-flex align-items-center">
                  <Slider
                    value={value}
                    onChange={handleChange}
                    onClick={() => {
                      console.log('min max :', value)
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                  />
                </div>

                <div className="row">
                  <div className="col-6">
                    <label className="form-label">Min Price</label>
                    <input
                      type="text"
                      className="form-control"
                      value={value[0]}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label">Max Price</label>
                    <input
                      type="text"
                      className="form-control"
                      value={value[1]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5 mt-2">
              <div
                className="text-center p-3 cursor bottom bg-primary text-white"
                onClick={(event) => {
                  filter_fun(anchor, false, event)
                }}
              >
                Save
              </div>
            </div> */}

            <div className="bg-fff">
              <div className="row sticky-top bg-fff shadow-bottom p-2 align-items-center">
                <div className="col-10 ps-1">
                  <h6 className="py-2 mb-0">Filter</h6>
                </div>
                <div
                  className="col-2 ps-0 pe-1 text-end cursor"
                  onClick={(event) => {
                    filter_fun(anchor, false, event);
                  }}
                >
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "8555c06ac82c76f24547be05700308af.svg"
                    }
                    className="w-20px"
                  />
                </div>
              </div>

              <div className="px-3 ">
                <AssignAvailableFilterComponent
                  filter_data={filter_data}
                  onChange={(data) => {
                    const timeOutId = setTimeout(() => {
                      SetFilterData((prevValue: any) => {
                        prevValue[data.key] = data.value;
                        prevValue.page_no = 1;
                        if (props.type == "assign") {
                          get_products(prevValue, false);
                        } else {
                          get_available_products(prevValue, false);
                        }
                        return { ...prevValue };
                      });
                    }, 500);
                    return () => clearTimeout(timeOutId);
                  }}
                  onClose={(data) => {
                    filter_fun(anchor, data, event);
                  }}
                />
              </div>

              <div
                className={
                  width < 786
                    ? "row pb-5 left-0 right-0 shadow"
                    : "row shadow pb-2"
                }
              >
                <div className="col-6 center pb-3">
                  <div className="bg-fff border text-center radius px-3 py-2 w-100">
                    <p className="text-black fw-bold mb-0">Reset</p>
                  </div>
                </div>
                <div className="col-6 center pb-3">
                  <div
                    className="w-100 bg-prime text-center py-2 radius px-3"
                    onClick={(event) => {
                      filter_fun(anchor, false, event);
                    }}
                  >
                    <p className="text-white fw-bold mb-0">Show products</p>
                  </div>
                </div>
              </div>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
