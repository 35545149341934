import React, { useState, useEffect } from "react";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap.min.js";

// import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";

import { Link, useParams } from "react-router-dom";
import { api } from "../../../utils/Api";

const franchise_initial_value = {
  package_breadth: "",
  package_breadth_unit: "1",
  // package_breadth_unit_name: "",
  package_height: "",
  package_height_unit: "1",
  // package_height_unit_name: "",
  package_length: "",
  package_length_unit: "1",
  // package_length_unit_name: "",
  package_weight: "",
  package_weight_unit: "1",
  // package_weight_unit_name: "",
};

let myModal: any;

export default function ModalEditPackageDetails(props) {
  // console.log(props.data);

  const [product_data, SetProductData] = useState([]);
  const [order_id, SetOrderId] = useState("");
  const [delivery_id, SetDeliveryId] = useState("");

  const [item_arr, SetItemArr] = useState([]);
  const [units, SetUnits] = useState({});

  const [id_value, SerIdValue] = useState(useParams());
  const [status, SetStatus] = useState(false);
  const [franchise_data, SetFranchiseData] = useState(franchise_initial_value);

  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });

  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [matric_volume, SetMatricVolume] = useState(1);

  useEffect(() => {
    console.log("modal package details On mount :", props);
    let myModal1 = document.getElementById("EditPackageModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("modal package details On props.open :", props);
      SetProductData(props.data.product);

      if (props.data.package_details.package_breadth != null) {
        let set_data = {
          package_breadth: props.data.package_details.package_breadth,
          package_breadth_unit: props.data.package_details.package_breadth_unit,
          package_breadth_unit_name:
            props.data.package_details.package_breadth_unit_name,
          package_height: props.data.package_details.package_height,
          package_height_unit: props.data.package_details.package_height_unit,
          package_height_unit_name:
            props.data.package_details.package_height_unit_name,
          package_length: props.data.package_details.package_length,
          package_length_unit: props.data.package_details.package_length_unit,
          package_length_unit_name:
            props.data.package_details.package_length_unit_name,
          package_weight: props.data.package_details.package_weight,
          package_weight_unit: props.data.package_details.package_weight_unit,
          package_weight_unit_name:
            props.data.package_details.package_weight_unit_name,
        };
        console.log("On edit franchise data :", set_data);
        SetFranchiseData(set_data);
      } else {
        SetFranchiseData(franchise_initial_value);
      }
      SetOrderId(props.data.id);
      SetUnits(props.data.units);
      myModal = new bootstrap.Modal(
        document.getElementById("EditPackageModal"),
        {}
      );
      myModal.show();
    } else {
      //   console.log("hide on modal when changge false");
      //   myModal = new bootstrap.Modal(
      //     document.getElementById("EditPackageModal"),
      //     {}
      //   );
      //   myModal.hide();
    }
  }, [props.open]);

  function deleteUserRole() {
    let item: any = close_data;
    item.action = "delete";
    item.value = props.data;
    SetCloseData(item);
    myModal.hide();
  }

  function input_on_change(e: any) {
    console.log(e.target.value);
    console.log(e.target.checked);

    let value = e.target.value;
    let checked = e.target.checked;

    if (checked) {
      item_arr.push(value);
    } else {
      var carIndex = item_arr.indexOf(value);
      //remove car from the colors array
      item_arr.splice(carIndex, 1);
    }
    SetItemArr(item_arr);
    console.log(item_arr);
  }

  function franchise_value_on_change(name: any, value: any) {
    console.log(name, value);
    // SetFranchiseData((oldValues) => ({
    //   ...oldValues,
    //   [name]: value,
    // }));

    SetFranchiseData((prevValue) => {
      prevValue[name] = value;

      if (
        prevValue.package_breadth != null &&
        prevValue.package_height &&
        prevValue.package_length != null
      ) {
        let metric_value: any =
          (prevValue.package_breadth *
            prevValue.package_height *
            prevValue.package_length) /
          5000;
        SetMatricVolume(metric_value);
      }
      return { ...prevValue };
    });
  }

  async function create_shipment() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: order_id,
      },
      post: {
        products: item_arr,
        type: "custom",
      },
    };
    console.log("pass_data :", pass_data);

    let data_res = await api("/outlet/create_delivery", pass_data);
    console.log("/outlet/create_delivery :", data_res.response);
    if (data_res.response.status == true) {
      let item = close_data;
      SetStatus(true);
      SetDeliveryId(data_res.response.deliver_id);
      item.action = "edit";
      // item.value = data_res.response.outlet_users[0];
      // item.index = props.data.index;
      // console.log("Close data : ", item);
      SetCloseData(item);
      // SetFranchiseData(franchise_initial_value);
      // myModal.hide();
    }
  }

  async function save_package_details() {
    // props.close();

    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        delivery_id: order_id,
      },
      post: {
        package_breadth: franchise_data.package_breadth,
        package_breadth_unit: franchise_data.package_breadth_unit,

        package_height: franchise_data.package_height,
        package_height_unit: franchise_data.package_height_unit,

        package_length: franchise_data.package_length,
        package_length_unit: franchise_data.package_length_unit,

        package_weight: franchise_data.package_weight,
        package_weight_unit: franchise_data.package_weight_unit,
      },
    };

    console.log("pass_data :", pass_data);

    let data_res = await api("/outlet/update_delivery_package", pass_data);
    console.log("/outlet/update_delivery_package :", data_res.response);
    if (data_res.status_code == 200) {
      let item = close_data;

      SetMatricVolume(1);
      SetStatus(true);
      item.action = "edit";
      item.value = data_res.response.orders[0];
      // SetStatus(true);
      // item.action = "edit";
      // item.value = data_res.response.orders[0];
      // item.index = props.data.index;
      // console.log("Close data : ", item);
      SetFranchiseData(franchise_initial_value);
      SetCloseData(item);
      // SetFranchiseData(franchise_initial_value);
      myModal.hide();
      // console.log("gfrhjgf")
      // props.close();
    }
  }

  function ModalOnClose(data) {
    SetopenModal({
      is_open: false,
      data: {},
    });
    if (data.action == "delete") {
      console.log("on confirm delete :", data.value);
      delete_user(data.value.element, data.value.index);
    }
  }

  return (
    <div
      className="modal fade"
      id="EditPackageModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-sm-down">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Packaging Details
            </h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            // onClick={(event) => {
            //   SetopenModal({
            //     is_open: true,
            //     data: {
            //       brand: id_value.brand_id,
            //       outlet: id_value.outlet_id,
            //       // order_id: ele.id,
            //     },
            //   });

            //   // accept(ele.id);
            // }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row pack">
              <div className="col-12 col-md-6 mt-2">
                <label className="form-label">Weight</label>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    value={franchise_data.package_weight}
                    onChange={(e) => {
                      franchise_value_on_change("package_weight", e.target.value);
                    }}
                    className="form-control w-70"
                    placeholder="Enter Breadth"
                    aria-describedby="basic-addon2"
                  />


                  {/* <span className="input-group-text txt">Kg</span> */}
                  <select
                    className="form-select pack-width"
                    aria-label="Default select example"
                    value={franchise_data.package_weight_unit}
                    onChange={(e) => {
                      franchise_value_on_change(
                        "package_weight_unit",
                        e.target.value
                      );
                    }}
                  >
                    {/* {console.log(units.weight)} */}
                    {units.weight?.map((ele: any, index: any) => (
                      // console.log(ele)
                      <option value={ele.id} key={index}>
                        {ele.nicename}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-12 col-md-6 mt-2">
                <label className="form-label">Length</label>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control w-70"
                    placeholder="Length"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={franchise_data.package_length}
                    onChange={(e) => {
                      franchise_value_on_change(
                        "package_length",
                        e.target.value
                      );
                    }}
                  />
                  <select
                    className="form-select pack-width"
                    aria-label="Default select example"
                    value={franchise_data.package_length_unit}
                    onChange={(e) => {
                      franchise_value_on_change(
                        "package_length_unit",
                        e.target.value
                      );
                    }}
                  >
                    {units.dimensions?.map((ele: any, index: any) => (
                      <option value={ele.id} key={index} className="w-auto">
                        {ele.nicename}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-12 col-md-6 mt-2">
                <label className="form-label">Breadth</label>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control w-70"
                    placeholder="Breadth"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={franchise_data.package_breadth}
                    onChange={(e) => {
                      franchise_value_on_change(
                        "package_breadth",
                        e.target.value
                      );
                    }}
                  />
                  <select
                    className="form-select pack-width"
                    aria-label="Default select example"
                    value={franchise_data.package_breadth_unit}
                    onChange={(e) => {
                      franchise_value_on_change(
                        "package_breadth_unit",
                        e.target.value
                      );
                    }}
                  >
                    {/* <option selected>{franchise_data.package_breadth_unit_name}</option> */}

                    {units.dimensions?.map((ele: any, index: any) => (
                      <option value={ele.id} key={index}>
                        {ele.nicename}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-12 col-md-6 mt-2">
                <label className="form-label">Height</label>

                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Height"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={franchise_data.package_height}
                    onChange={(e) => {
                      franchise_value_on_change(
                        "package_height",
                        e.target.value
                      );
                    }}
                  />
                  <select
                    className="form-select pack-width"
                    aria-label="Default select example"
                    value={franchise_data.package_height_unit}
                    onChange={(e) => {
                      franchise_value_on_change(
                        "package_height_unit",
                        e.target.value
                      );
                    }}
                  >
                    {units.dimensions?.map((ele: any, index: any) => (
                      <option value={ele.id} key={index}>
                        {ele.nicename}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-12 col-md-6 mt-2">
                <label className="form-label">Volume Metric Weight</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control  w-70"
                    placeholder="Volume metric Weight"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    // readonly=""
                    value={matric_volume == 1 ? "" : matric_volume}
                  />
                  <select
                    className="form-select pack-width"
                    aria-label="Default select example"
                  // onChange={(e) => {
                  //   franchise_value_on_change(
                  //     "package_weight_unit",
                  //     e.target.value,
                  //   );
                  // }}
                  >
                    {/* {console.log(units)} */}
                    {units.weight?.map((ele: any, index: any) => (
                      <option value={ele.id} key={index}>
                        {ele.nicename}
                      </option>
                    ))}
                  </select>
                </div>

              </div>
            </div>
          </div>
          <div className="modal-footer center">
            {/* <Link to="/package_details"> */}
            <button
              type="button"
              className="btn bg-prime btn-radius text-white fw-bold px-3"
              // className="btn-close"
              // data-bs-dismiss="modal"
              onClick={() => {
                // props.close();
                save_package_details();
              }}
            // onClick={(event) => {
            //   SetopenModal({
            //     is_open: true,
            //     data: {
            //       brand: id_value.brand_id,
            //       outlet: id_value.outlet_id,
            //       // order_id: ele.id,
            //     },
            //   });

            //   // accept(ele.id);
            // }}
            >
              Save Package Details
            </button>

            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
