import React, { useState, useEffect, useContext, useRef } from "react";
import DataContext from "../../utils/Context/DataContext";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Ionicons,
} from "@expo/vector-icons";
import { api } from "../../../utils/Api";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import toast from "react-hot-toast";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { mobileApp } from "../../utils/MobileApp";
import Cookies from "js-cookie";
import { AppConfig } from "../../config";
import { AppHelper } from "../../utils/Helper";

var lat: any, long: any;
let map: any;
var importedScript = document.createElement("script");
let myModal: any;
let exit_data = {
  action: "close",
  value: {
    short_address_value: "",
    full_address_value: "",
    lat: "",
    lang: "",
    map_pincode_value: "",
  },
};
let can_save = false;
let full_address_value = "";
let short_address_value = "";
let autocomplete: any;
let marker: any;
let current_location_marker: any;

export default function LocationPickerWithMarker(props: any) {
  const SearchInputRef = useRef(null);
  const context: any = useContext(DataContext);
  const [openCoordinates, setOpenCoordinates]: any = useState({});
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("roadmap");
  const [close_data, SetCloseData] = useState({
    action: "close",
    value: {
      short_address_value: "",
      full_address_value: "",
      lat: "",
      lang: "",
    },
  });
  // const [can_save, SetCanSave] = useState(false);
  // const [full_address_value, SetFullAddressValue] = useState("");
  // const [short_address_value, SetShortAddressValue] = useState("");
  const [location_loading, SetLocationLoading] = useState(false);

  const blueDot = {
    fillColor: "#4285F4",
    fillOpacity: 1,
    path: google.maps.SymbolPath.CIRCLE,
    scale: 8,
    strokeColor: "rgb(255,255,255)",
    strokeWeight: 2,
  };
  const [continue_loading, SetContinueLoading] = useState(false);

  const accuracyCircle = useRef(null);

  useEffect(() => {
    // document.removeEventListener("message", putLocationFromMobile);
    // document.addEventListener("message", putLocationFromMobile);

    console.log("ModelLocationPicker On mount :", props);
    let myModal1 = document.getElementById("LocationPickerWithMarker");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      importedScript.remove();
      // delete(myModal)
      myModal = "";
      console.log("close_data :", close_data);
      console.log("exit_data :", exit_data);

      // SetFullAddressValue("");
      full_address_value = "";
      // SetShortAddressValue("");
      short_address_value = "";
      // SetCanSave(false)
      can_save = false;
      props.close(exit_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("props on change location picker component", props);
      exit_data = {
        action: "close",
        value: {
          short_address_value: "",
          full_address_value: "",
          lat: "",
          lang: "",
          map_pincode_value: "",
        },
      };
      myModal = new bootstrap.Modal(
        document.getElementById("LocationPickerWithMarker"),
        {
          backdrop: false,
        }
      );
      // if (props.from != "c") {
      //   if (props.hasOwnProperty("picked_lat_long")) {
      //     if (
      //       props.picked_lat_long.lat != "" &&
      //       props.picked_lat_long.long != "" &&
      //       props.picked_lat_long.lat != undefined &&
      //       props.picked_lat_long.long != undefined
      //     ) {
      //       lat = props.picked_lat_long.lat;
      //       long = props.picked_lat_long.long;
      //     } else {
      //       lat = 11.341;
      //       long = 77.7172;
      //     }
      //   } else {
      //     lat = 11.341;
      //     long = 77.7172;
      //   }
      // } else {
      //   lat = 11.341;
      //   long = 77.7172;
      // }

      // if (!document.getElementById("google_map")) {
      //   console.log("Adding Script")
      //   importedScript.id = "google_map";
      //   importedScript.src =
      //     "https://maps.googleapis.com/maps/api/js?key=AIzaSyC37EQ5KAX63pqvDFLC5EOSe3znJ0ebFVg&callback=initMap&v=weekly&libraries=places";
      //   document.head.appendChild(importedScript);
      // }else{
      //   console.log("Script already available")
      // }
      // window.initMap = initMap;
      // console.log("window.initMap :",window.initMap)
      // // SetCloseData({
      // //   action: "close",
      // // });
      renderMap();
      myModal.show();
    }
  }, [props.open]);

  const renderMap = () => {
    window.initMap = initMap();
  };

  function initMap(): void {
    console.log("InitMap", props);
    const input = document.getElementById("pac-input") as HTMLInputElement;

    // let location_data = JSON.parse(Cookies.get("location"));
    let can_palce_pick_marker = false;
    // console.log("location_data :", location_data);
    if (props.from != "c") {
      if (props.hasOwnProperty("picked_lat_long")) {
        if (
          props.picked_lat_long?.lat != null &&
          props.picked_lat_long?.long != null &&
          props.picked_lat_long?.lat != undefined &&
          props.picked_lat_long?.long != undefined &&
          props.picked_lat_long?.lat != "" &&
          props.picked_lat_long?.long != ""
        ) {
          lat = props.picked_lat_long?.lat;
          long = props.picked_lat_long?.long;
          input.value = props.picked_address_value;
          can_palce_pick_marker = true;
        } else {
          // if (location_data.lat != null &&
          //     location_data.lang != null &&
          //     location_data.lat != undefined &&
          //     location_data.lang != undefined &&
          //     location_data.lat != "" &&
          //     location_data.lang != ""
          // ) {
          //   console.log("location data != undefined")
          //   // let parse_data = JSON.parse(location_data);
          //   lat = parseFloat(location_data.lat);
          //   long = parseFloat(location_data.lang);
          //   can_palce_pick_marker = false;
          // } else {
          //   console.log("location data == undefined")
          //   lat = 11.341;
          //   long = 77.7172;
          //   getLocation();
          // }
          lat = 11.341;
          long = 77.7172;
          getLocation();
        }
      } else {
        console.log("dont have property like pick lat long");
        lat = 11.341;
        long = 77.7172;
        getLocation();
      }
    } else {
      console.log("props.from == c");
      lat = 11.341;
      long = 77.7172;
      getLocation();
    }
    console.log("lat long:", lat, long);
    map = new google.maps.Map(
      document.getElementById(
        "location-pickup-map-with-marker-100"
      ) as HTMLElement,
      {
        zoom: 12,
        // zoom: 17,
        // center: { lat: 11.3410, lng: 77.7172 },
        center: { lat: Number(lat), lng: Number(long) },
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        streetViewControl: false,
        mapTypeId: "roadmap",
        gestureHandling: "greedy",
        minZoom: 2,
        restriction: {
          latLngBounds: {
            north: 85,
            south: -85,
            west: -180,
            east: 180,
          },
        },
        // scrollwheel: false,
        // disableDoubleClickZoom: true,
      }
    );

    // showMapFormattedAddress();

    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
      types: ["establishment"],
    };

    autocomplete = new google.maps.places.Autocomplete(input, options);

    marker = new google.maps.Marker({
      //   icon: blueDot,
      position: { lat: Number(lat), lng: Number(long) },
      //   title: "You are here!",
    });
    if (can_palce_pick_marker) {
      marker.setMap(map);
    }

    map.addListener("dragend", () => {
      // SetCanSave(false);
      // can_save = false;
      console.log("map dragged");
      // showMapFormattedAddress();
      // autocomplete.bindTo("bounds", map);
    });

    map.addListener("center_changed", () => {
      console.log("map center_changed");
      // lat = map.getCenter()?.lat();
      // long = map.getCenter()?.lng();
      //   const timeOutId = setTimeout(() => showMapFormattedAddress(), 500);
      //   autocomplete.bindTo("bounds", map);
      //   return () => clearTimeout(timeOutId);
    });

    map.addListener("click", (e: any) => {
      console.log("on click", e);
      console.log("on click", e.latLng.lat());
      console.log("on click", e.latLng.lng());
      console.log("Marker");
      // if marker exists and has a .setMap method, hide it
      if (marker && marker.setMap) {
        marker.setMap(null);
      }
      marker = new google.maps.Marker({
        position: { lat: Number(e.latLng.lat()), lng: Number(e.latLng.lng()) },
        map: map,
      });
      can_save = true;
      // map.setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      // lat = map.getCenter()?.lat();
      // long = map.getCenter()?.lng();
      lat = e.latLng.lat();
      long = e.latLng.lng();
      // console.log("map.getZoom() :", map.getZoom());
      // if (map.getZoom() < 15) {
      //   map.setZoom(map.getZoom() + 3);
      // }
      // const timeOutId = setTimeout(() => showMapFormattedAddress(), 500);
      autocomplete.bindTo("bounds", map);
      var textscore = document.getElementById("pac-input") as HTMLInputElement;
      textscore.value = e.latLng.lat() + ", " + e.latLng.lng();
      // textscore.focus();
      // return () => clearTimeout(timeOutId);
    });

    autocomplete.bindTo("bounds", map);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("place_changed place :", place);
      full_address_value = place.formatted_address;
      short_address_value = place.name;
      var textscore = document.getElementById("pac-input") as HTMLInputElement;
      textscore.value = place.formatted_address;
      if (!place.geometry || !place.geometry.location) {
        // window.alert("No details available for input: '" + place.name + "'");
        return;
      }
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
        // showMapFormattedAddress();
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(10);
        // map.setZoom(17);
        // lat = map.getCenter()?.lat();
        // long = map.getCenter()?.lng();
        // showMapFormattedAddress();
      }
      if (marker && marker.setMap) {
        marker.setMap(null);
      }
      marker = new google.maps.Marker({
        position: {
          lat: Number(map.getCenter()?.lat()),
          lng: Number(map.getCenter()?.lng()),
        },
        map: map,
      });
      lat = map.getCenter()?.lat();
      long = map.getCenter()?.lng();
      // showMapFormattedAddress();
      can_save = true;
    });
  }

  function showMapFormattedAddress(on_submit: boolean) {
    // if (lat != null && long != null) {
    var latlng = new google.maps.LatLng(lat, long);
    var geocoder: any = (geocoder = new google.maps.Geocoder());
    geocoder.geocode({ latLng: latlng }, function (results: any, status: any) {
      if (status == google.maps.GeocoderStatus.OK) {
        console.log(results[0].address_components[0].long_name);
        if (results[0].address_components) {
          var textscore = document.getElementById(
            "pac-input"
          ) as HTMLInputElement;
          console.log("address results :", results);
          console.log("textscore :", textscore);
          if (textscore != null) {
            textscore.value = getFormattedAddressFromResult(results);
          } else {
            textscore = {};
            textscore.value = "";
          }
          console.log("textscore.value :", textscore.value);
          // SetFullAddressValue(textscore.value);
          // SetCanSave(true);
          can_save = true;
          full_address_value = textscore.value;
          if (on_submit) {
            confirm_submit();
          }
        }
      }
    });
    SetLocationLoading((prevalue) => {
      prevalue = false;
      return prevalue;
    });
    // autocomplete.unbind("bounds");

    // }
  }

  function getFormattedAddressFromResult(results: any) {
    if (results && results[0].address_components) {
      var address_components = results[0].address_components;
      var formatted_address = "";
      var short_address_stored = false;
      for (var i = 0; i < address_components.length; i++) {
        if (address_components[i].types) {
          var types = address_components[i].types;
          var continueLoop = false;
          for (var j = 0; j < types.length; j++) {
            if (types[j] == "plus_code") {
              continueLoop = true;
            }
          }
          if (i == 0) {
            if (types[0] != "plus_code" && types[0] != "premise") {
              console.log(
                "address_components[i].long_name :",
                address_components[i].long_name
              );
              if (!short_address_stored) {
                short_address_value = address_components[i].long_name;
                short_address_stored = true;
              }
            }
          }
          if (i == 1) {
            if (types[0] != "plus_code" && types[0] != "premise") {
              console.log(
                "address_components[i].long_name :",
                address_components[i].long_name
              );
              if (!short_address_stored) {
                short_address_value = address_components[i].long_name;
                short_address_stored = true;
              }
            }
          }
          if (i == 2) {
            if (types[0] != "plus_code" && types[0] != "premise") {
              console.log(
                "address_components[i].long_name :",
                address_components[i].long_name
              );
              if (!short_address_stored) {
                short_address_value = address_components[i].long_name;
                short_address_stored = true;
              }
            }
          }
        }

        if (continueLoop) {
          continue;
        } else {
          if (i < address_components.length - 1) {
            formatted_address += address_components[i].long_name + ", ";
          } else {
            formatted_address += address_components[i].long_name;
          }
        }
      }
      return formatted_address;
    }
    return "Unnamed address";
  }

  const Spinner = () => <div className="loader-spinner"></div>;

  function update_type(type: String) {
    setOpen(false);
    map.setMapTypeId(type);
    console.log(type);
  }

  function getLocation() {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(showPosition);
    // }
    document.addEventListener("message", putLocationFromMobile);
    if (context.app_data.application.platform == "web") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, errorCallback);
      }
    } else if (
      context.app_data.application.platform == "android" ||
      context.app_data.application.platform == "ios"
    ) {
      mobileApp({
        function: "getlocationlatlng",
        app_type: "react_web",
        accuracy: "Lowest",
      });
    }
  }

  function putLocationFromMobile(message: any) {

    // alert(message);
    // alert(JSON.stringify(message));
    // alert(JSON.stringify(message.data));
    // alert(JSON.stringify(message.data.function));
    // alert(JSON.stringify(message.data.status));
    // alert(JSON.stringify(message.data.lat));
    // alert(JSON.stringify(message.data.lng));
    SetLocationLoading(false);
    if (message.data.function == "getlocationlatlng") {
      // if (message.data.status == "success") {
      if (
        message.data.lat != null &&
        message.data.lng != null &&
        message.data.lat != undefined &&
        message.data.lng != undefined
      ) {
        marker.setMap(null);
        map.setCenter({ lat: message.data.lat, lng: message.data.lng });
        // map.setZoom(10);
        // map.setZoom(12);
        lat = message.data.lat;
        long = message.data.lng;
        // showMapFormattedAddress(false);
        if (current_location_marker && current_location_marker.setMap) {
          marker.setMap(null);
          current_location_marker.setMap(null);
        }
        current_location_marker = new google.maps.Marker({
          icon: blueDot,
          position: {
            lat: Number(message.data.lat),
            lng: Number(message.data.lng),
          },
          map : map,
        });
        // current_location_marker.setMap(map);
        if (marker && marker.setMap) {
          marker.setMap(null);
        }
        marker = new google.maps.Marker({
          position: {
            lat: Number(message.data.lat),
            lng: Number(message.data.lng),
          },
          map: map,
        });
        var textscore = document.getElementById("pac-input") as HTMLInputElement;
        textscore.value = message.data.lat + ", " + message.data.lng;
        can_save = true;
      } else {
        // map.setCenter({ lat: "11.3410", lng: "77.7172" });
        if (props.from != "c") {
          if (props.hasOwnProperty("picked_lat_long")) {
            if (
              props.picked_lat_long.lat != "" &&
              props.picked_lat_long.long != "" &&
              props.picked_lat_long.lat != undefined &&
              props.picked_lat_long.long != undefined
            ) {
              map.setCenter({
                lat: props.picked_lat_long.lat,
                lng: props.picked_lat_long.long,
              });
              var textscore = document.getElementById("pac-input") as HTMLInputElement;
              textscore.value = props.picked_lat_long.lat + ", " + props.picked_lat_long.long;
              can_save = true;
            } else {
              map.setCenter({ lat: 11.341, lng: 77.7172 });
              can_save = false;
            }
          } else {
            map.setCenter({ lat: 11.341, lng: 77.7172 });
            can_save = false;
          }
        } else {
          map.setCenter({ lat: 11.341, lng: 77.7172 });
          can_save = false;
        }

        // map.setZoom(10);
        // map.setZoom(12);
        // lat = "11.3410";
        // long = "77.7172";
        // if (props.from != "c") {
        //   if (props.hasOwnProperty("picked_lat_long")) {
        //     if (
        //       props.picked_lat_long.lat != "" &&
        //       props.picked_lat_long.long != "" &&
        //       props.picked_lat_long.lat != undefined &&
        //       props.picked_lat_long.long != undefined
        //     ) {
        //       lat = props.picked_lat_long.lat;
        //       long = props.picked_lat_long.long;
        //     } else {
        //       lat = 11.341;
        //       long = 77.7172;
        //     }
        //   } else {
        //     lat = 11.341;
        //     long = 77.7172;
        //   }
        // } else {
        //   lat = 11.341;
        //   long = 77.7172;
        // }
        // showMapFormattedAddress();
      }
    }

    document.removeEventListener("message", remove_event_lisitener);
  }

  function remove_event_lisitener() {
    console.log("removed listiner");
  }

  function showPosition(position: any, placeLocationMarker: any = true, placeMarker : any = false) {
    SetLocationLoading(false);
    marker.setMap(null);
    console.log("showPosition :", position);
    if (context.app_data.have_mobile_app_key) {
      AppHelper.SaveLocation(position);
    }
    // map = new google.maps.Map(
    //   document.getElementById("location-pickup-map-100") as HTMLElement,
    //   {
    //     // zoom: 4,
    //     zoom: 17,
    //     // center: { lat: 11.1271, lng: 78.6569 },
    //     center: { lat: 11.341, lng: 77.7172 },
    //     // center: { lat: lat, lng: long },
    //     mapTypeControl: false,
    //     fullscreenControl: false,
    //     zoomControl: false,
    //     streetViewControl: false,
    //     mapTypeId: "roadmap",
    //     gestureHandling: "greedy",
    //   }
    // );
    // map.setCenter({
    //   lat: position.coords.latitude,
    //   lng: position.coords.longitude,
    // });
    // map.setZoom(10);
    // map.setZoom(10);
    lat = position.coords.latitude;
    long = position.coords.longitude;
    autocomplete.bindTo("bounds", map);

    var textscore = document.getElementById("pac-input") as HTMLInputElement;
    textscore.value = position.coords.latitude + ", " + position.coords.longitude;

    // showMapFormattedAddress();
    // const input = document.getElementById("pac-input") as HTMLInputElement;
    // const options = {
    //   fields: ["formatted_address", "geometry", "name"],
    //   strictBounds: false,
    //   types: ["establishment"],
    // };
    // autocomplete = new google.maps.places.Autocomplete(input, options);

    if (placeLocationMarker) {
      current_location_marker = new google.maps.Marker({
        icon: blueDot,
        position: {
          lat: Number(position.coords.latitude),
          lng: Number(position.coords.longitude),
        },
        title: "You are here!",
      });
      current_location_marker.setMap(map);
      if (marker && marker.setMap) {
        marker.setMap(null);
      }
      marker = new google.maps.Marker({
        position: {
          lat: Number(position.coords.latitude),
          lng: Number(position.coords.longitude),
        },
        map: map,
      });
    }

    if(placeMarker){
      if (marker && marker.setMap) {
        marker.setMap(null);
      }
      marker = new google.maps.Marker({
        position: {
          lat: Number(position.coords.latitude),
          lng: Number(position.coords.longitude),
        },
        map: map,
      });
    }

    // map.addListener("dragend", () => {
    //   // SetCanSave(false);
    //   can_save = false;
    //   console.log("map dragged");
    //   // showMapFormattedAddress();
    //   autocomplete.bindTo("bounds", map);
    // });

    // map.addListener("center_changed", () => {
    //   console.log("map center_changed");
    //   lat = map.getCenter()?.lat();
    //   long = map.getCenter()?.lng();
    //   const timeOutId = setTimeout(() => showMapFormattedAddress(), 500);
    //   autocomplete.bindTo("bounds", map);
    //   return () => clearTimeout(timeOutId);
    // });

    // autocomplete.bindTo("bounds", map);
    // autocomplete.addListener("place_changed", () => {
    //   const place = autocomplete.getPlace();
    //   console.log("place_changed place :", place);
    //   if (!place.geometry || !place.geometry.location) {
    //     // window.alert("No details available for input: '" + place.name + "'");
    //     return;
    //   }
    //   if (place.geometry.viewport) {
    //     map.fitBounds(place.geometry.viewport);
    //     // showMapFormattedAddress();
    //   } else {
    //     map.setCenter(place.geometry.location);
    //     // map.setZoom(10);
    //     map.setZoom(17);
    //     // lat = map.getCenter()?.lat();
    //     // long = map.getCenter()?.lng();
    //     // showMapFormattedAddress();
    //   }
    //   lat = map.getCenter()?.lat();
    //   long = map.getCenter()?.lng();
    //   showMapFormattedAddress();
    // });

    coordsSearched("");
  }

  function errorCallback(error: any) {
    // alert("123")
    SetLocationLoading(false);
    console.log("error :", error);
    console.log("props :", props);
    if (props.from != "c") {
      if (props.hasOwnProperty("picked_lat_long")) {
        if (
          props.picked_lat_long.lat != "" &&
          props.picked_lat_long.long != "" &&
          props.picked_lat_long.lat != undefined &&
          props.picked_lat_long.long != undefined
        ) {
          console.log("check 1");
          map.setCenter({
            lat: Number(props.picked_lat_long.lat),
            lng: Number(props.picked_lat_long.long),
          });
        } else {
          console.log("check 2");
          map.setCenter({
            lat: 11.341,
            lng: 77.7172,
          });
        }
      } else {
        console.log("check 3");
        map.setCenter({
          lat: 11.341,
          lng: 77.7172,
        });
      }
    } else {
      console.log("check 4");
      map.setCenter({
        lat: 11.341,
        lng: 77.7172,
      });
    }
    can_save = false;
    map.setZoom(12);
    marker.setMap(null);
    const input = document.getElementById("pac-input") as HTMLInputElement;
    input.value = "";
    // showMapFormattedAddress();
    // const options = {
    //   fields: ["formatted_address", "geometry", "name"],
    //   strictBounds: false,
    //   types: ["establishment"],
    // };
    // autocomplete = new google.maps.places.Autocomplete(input, options);

    // marker = new google.maps.Marker({
    //   icon: blueDot,
    //   position: {
    //     lat: lat,
    //     lng: long,
    //   },
    //   title: "You are here!",
    // });
    // marker.setMap(map);

    // map.addListener("dragend", () => {
    //   // SetCanSave(false);
    //   can_save = false;
    //   console.log("map dragged");
    //   // showMapFormattedAddress();
    //   autocomplete.bindTo("bounds", map);
    // });

    // map.addListener("center_changed", () => {
    //   console.log("map center_changed");
    //   lat = map.getCenter()?.lat();
    //   long = map.getCenter()?.lng();
    //   const timeOutId = setTimeout(() => showMapFormattedAddress(), 500);
    //   return () => clearTimeout(timeOutId);
    //   autocomplete.bindTo("bounds", map);
    // });

    // autocomplete.bindTo("bounds", map);
    // autocomplete.addListener("place_changed", () => {
    //   const place = autocomplete.getPlace();
    //   console.log("place_changed place :", place);
    //   if (!place.geometry || !place.geometry.location) {
    //     // window.alert("No details available for input: '" + place.name + "'");
    //     return;
    //   }
    //   if (place.geometry.viewport) {
    //     map.fitBounds(place.geometry.viewport);
    //     // showMapFormattedAddress();
    //   } else {
    //     map.setCenter(place.geometry.location);
    //     // map.setZoom(10);
    //     map.setZoom(17);
    //     // lat = map.getCenter()?.lat();
    //     // long = map.getCenter()?.lng();
    //     // showMapFormattedAddress();
    //   }
    //   lat = map.getCenter()?.lat();
    //   long = map.getCenter()?.lng();
    //   showMapFormattedAddress();
    // });
  }

  const coordsSearched = (searchInpValue: any) => {
    let latLngInpVal: any = [];
    let latInpVal: any, lngInpVal: any;
    let isNotCoords: any = true;
    if (searchInpValue) {
      searchInpValue = searchInpValue.replace(/^\s+/g, ""); // left trim
      searchInpValue = searchInpValue.replace(/\s+$/g, ""); // right trim
      searchInpValue = searchInpValue.replace(/\s+/g, " "); // replace more than one space into single space
      if (searchInpValue.indexOf(",") > -1) {
        latLngInpVal = searchInpValue.split(",");
      } else if (searchInpValue.indexOf(" ") > -1) {
        latLngInpVal = searchInpValue.split(" ");
      }
      if (latLngInpVal.length > 0 && latLngInpVal.length <= 2) {
        latLngInpVal.map((inpLL: any, i: any) => {
          latLngInpVal[i] = inpLL.replace(/ /g, "");
        });
        let hasNumber = /^[\d.]+$/;
        if (
          hasNumber.test(latLngInpVal[0]) &&
          hasNumber.test(latLngInpVal[1])
        ) {
          latInpVal = parseFloat(latLngInpVal[0]);
          lngInpVal = parseFloat(latLngInpVal[1]);
          if (typeof latInpVal === "number" && typeof lngInpVal === "number") {
            isNotCoords = false;
            setOpenCoordinates({
              lat: latInpVal,
              lng: lngInpVal,
            });
          }
        }
      }
    }
    if (isNotCoords) {
      setOpenCoordinates({
        lat: null,
        lng: null,
      });
    }
  };

  function confirm_submit() {
    SetContinueLoading(false);
    let value_data = {
      short_address_value: short_address_value,
      full_address_value: full_address_value,
      lat: lat,
      lang: long,
    };
    console.log("value_data :", value_data);
    SetCloseData((prevalue) => {
      prevalue.action = "save";
      prevalue.value = value_data;
      return { ...prevalue };
    });
    exit_data.action = "save";
    exit_data.value = value_data;
    exit_data.from = props.from;
    myModal.hide();
  }

  return (
    <div
      className="modal fade"
      id="LocationPickerWithMarker"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog  modal-fullscreen" data-backdrop="false">
        <div className="modal-content">
          <div className="modal-body p-0 loction-picker-modal">
            <div className="d-flex align-items-center bg-fff w-100 p-1  shadow-bottom">
              <div className="w-10per p-2" data-bs-dismiss="modal">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "efc6166c822bebcb9e2814873783d7ce.svg"
                  }
                  className="type-img"
                />
              </div>
              <h5 className="w-80per center mb-0 p-2">Pick location</h5>
              <div className="w-10per"></div>
            </div>
            <div className="map-top-div">
              <div className="map-search-bar center shadow">
                {/* old hide start 17/06/23 */}
                {/* <div className="px-2">
                <input
                  id="pac-input"
                  className="form-control pac-target-input w-90  px-2"
                  type="text"
                  placeholder="Search location"
                />
              </div> */}
                {/* old hide end 17/06/23 */}
                <div className="input-group ">
                  <span
                    className="input-group-text  ps-2 center w-10per"
                    id="map-search"
                    onClick={() => {
                      SetCloseData({
                        action: "close",
                        value: {
                          short_address_value: "",
                          full_address_value: "",
                          lat: "",
                          lang: "",
                        },
                      });
                      exit_data.action = "save";
                      exit_data.value = {
                        short_address_value: "",
                        full_address_value: "",
                        lat: "",
                        lang: "",
                      };
                    }}
                  >
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "3c2285596bce143ffb759071c0b527ac.svg"
                      }
                      className="w-20"
                    />
                  </span>
                  <input
                    id="pac-input"
                    ref={SearchInputRef}
                    aria-label="Username"
                    aria-describedby="map-search"
                    className="form-control form-control1 pac-target-input  border-radius-10"
                    type="text"
                    placeholder="Search location"
                    onChange={(e) => {
                      console.log("loc changed", e);

                      coordsSearched(e.target.value);
                    }}
                  />
                  <span
                    className="input-group-text-last w-10per"
                    onClick={() => {
                      let input = document.getElementById(
                        "pac-input"
                      ) as HTMLInputElement;
                      input.value = "";
                      SearchInputRef.current.focus();
                      coordsSearched("");
                    }}
                  >
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "b224728e6ca0021968c4481f66bdd226.svg"
                      }
                      className="w-20"
                    />
                  </span>
                </div>
              </div>
              {openCoordinates?.lat && openCoordinates?.lng && (
                <div
                  className="map-search-sugg-coords"
                  onClick={() => {
                    console.log("coords on click")
                    let coords = {
                      coords: {
                        latitude: openCoordinates?.lat,
                        longitude: openCoordinates?.lng,
                      },
                    };
                    showPosition(coords, false, true);
                  }}
                >
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "c7bd9550ee34622390e4428c505ba925.svg"
                    }
                    className="img-fluid w-20 me-2"
                  />
                  {openCoordinates?.lat}, {openCoordinates?.lng}
                </div>
              )}
            </div>

            <div id="location-pickup-map-with-marker-100"></div>
            {/* bottom */}
            <div className="map-layer-div end">
              <div>
                {/* layer */}
                <div className="mb-3">
                  <div
                    className="p-2 bg-fff cursor center radius mb-3 shadow"
                    onClick={() => (open ? setOpen(false) : setOpen(true))}
                  >
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "387ad34ee1e70a6e6eb0946203eff996.svg"
                      }
                      className="w-20"
                    />
                    {/* <p className="ps-1 text-white fw-bold">Layer</p> */}
                  </div>
                </div>
                {/* current location */}
                <div className="">
                  <div
                    id="map-current-location"
                    className="p-2 bg-fff center radius cursor mb-3  shadow"
                    onClick={() => {
                      SetLocationLoading((prevalue) => {
                        if (!prevalue) {
                          getLocation();
                          prevalue = true;
                        } else {
                          console.log("location loading");
                        }
                        return prevalue;
                      });
                      setTimeout(() => {
                        SetLocationLoading(false);
                      }, 10000);
                    }}
                  >
                    {location_loading ? (
                      <Spinner />
                    ) : (
                      <img
                        src={
                          AppConfig.CDN_Media_url +
                          "b1583b9b4b1eef5f2c45db550862c922.svg"
                        }
                        className="w-20"
                      />
                    )}

                    {/* gps_fixed */}
                  </div>
                </div>
              </div>
            </div>
            <div className="map-bottom-div">
              <p id="demo"></p>
              <div className="center md-w-50">
                <button
                  type="button"
                  className="btn bg-white center text-prime1 btn-radius w-100 p-3 mb-2 text-capitalize shadow"
                  name="channel_id"
                  onClick={(e) => {
                    if (can_save) {
                      console.log("can save");
                      // confirm_submit();
                      if (!continue_loading) {
                        SetContinueLoading(true);
                        showMapFormattedAddress(true);
                      } else {
                        console.log("continue is loading");
                      }
                    } else {
                      console.log("cannot save");
                      toast.error("Please Choose Loaction");
                    }
                  }}
                >
                  {/* {can_save ? (
                    <div className="w-100 center">
                      <Spinner />
                    </div>
                  ) : (
                    "Done"
                  )} */}
                  {continue_loading ? <Spinner /> : "Continue"}
                </button>
              </div>
            </div>
          </div>
          {/* swipable bottom sheet */}
          <div className="map-layer-swipe">
            <SwipeableBottomSheet
              overflowHeight={0}
              open={open}
              overlay={true}
              // defaultOpen={false}
              onChange={() => {
                setOpen(false);
              }}
              style={{ zIndex: 1500 }}
            >
              <div style={{ height: "240px" }}>
                <div className=" drawer-fixed">
                  <div className="w-100 center">
                    <hr className="hr-drawer" />
                  </div>
                </div>
                <div className="p-3 pt-5 row align-items-center">
                  {/* <h6>Select map layer</h6> */}
                  <div
                    className={"py-3 cursor  col-4 text-center"}
                    data-type="roadmap"
                    onClick={() => update_type("roadmap")}
                  >
                    <img
                      src={
                        AppConfig.CDN_Image_url +
                        "2b1b9228011f7b8ec58f356e6bb769ab.png"
                      }
                      className="map-collection"
                    />
                    <p className="text-black celias-font pt-2"> Road Map</p>
                  </div>
                  <div
                    className="py-3 cursor col-4 text-center "
                    data-type="hybrid"
                    onClick={() => update_type("hybrid")}
                    id="satellite"
                  >
                    <img
                      src={
                        AppConfig.CDN_Image_url +
                        "f8b1b928e57b38c90adc637bb480d1ca.png"
                      }
                      className="map-collection"
                    />
                    <p className="text-black celias-font pt-2">Satellite</p>
                  </div>

                  <div
                    className="py-3 cursor col-4 text-center"
                    data-type="terrain"
                    onClick={() => update_type("terrain")}
                  >
                    <img
                      src={
                        AppConfig.CDN_Image_url +
                        "e0235a5fefe4e689005ef31d2469f2a0.png"
                      }
                      className="map-collection"
                    />
                    <p className="text-black celias-font pt-2">Terrain</p>
                  </div>
                </div>
              </div>
            </SwipeableBottomSheet>
          </div>
        </div>
      </div>
    </div>
  );
}
