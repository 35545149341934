import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import ModalCreateFranchise from "./ModalCreateFranchise";
import ModalFranchiseUser from "./ModalFranchiseUser";
import ModalEditFranchise from "./ModalEditFranchise";
import { AntDesign, Entypo, Feather, FontAwesome, Ionicons, MaterialCommunityIcons, MaterialIcons, SimpleLineIcons } from "@expo/vector-icons";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import DeleteAlert from "../../../components/Alert/DeleteAlert";
import BrandOutlet from "../Outlets/BrandOutlet";
import { AppConfig } from "../../../config";

const table_config = {
  key_column: "id",
  page_size: 10,
  length_menu: [10, 20, 50],
  show_filter: true,
  show_pagination: true,
  button: {
    excel: false,
    print: false,
  },
  language: {
    loading_text: "Please be patient while data loads...",
  },
};

const table_header = [
  {
    name: "User Details",
    width: "20",
  },
  {
    name: "Roles",
    width: "10",
  },
  {
    name: "Custom Roles",
    width: "20",
  },
  {
    name: "Created At",
    width: "20",
  },
  {
    name: "Actions",
    width: "20",
  },
];

export default function ViewFranchise(props: any) {
  const [id_value, SerIdValue] = useState(useParams());
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [width, SetWidth] = useState(innerWidth);
  const params = useParams();
  const [franchise_details, SetFranchiseDetails] = useState({});
  const [franchise_user_data, SetFranchiseUserData] = useState([]);
  //   ------------------------(Last)
  const [edit_franchise_modal, SetEditFranchiseModal] = useState({
    is_open: false,
    data: {},
    index: "",
  });

  const [modal_franchise, SetModalFranchise] = useState({
    is_open: false,
    data: "",
    type: "",
  });
  const [modal_user, SetModalUser] = useState({
    is_open: false,
    type: "",
    data: {},
  });

  const [state, setState] = React.useState({
    bottom: false,
  });

  const [selected_data, SetSelectedData] = useState({
    value: "",
    i: "",
    type: "",
  });

  const [delete_modal, SetDeleteModal] = useState({
    is_open: false,
    title: "",
    record: {},
    index: "",
  });



  //  ------------------------(UsersTab info)
  const table_column = [
    { key: "id", width: 25, text: "Id", sortable: true },
    {
      key: "name",
      width: 150,
      text: "User Name",
      sortable: true,
      cell: (record: any, index: any) => {
        return record.name + "@" + record.username;
      },
    },

    {
      key: "roles",
      width: 150,
      text: "Roles",
      sortable: true,
      cell: (record: any, index: any) => {
        let data_value = "";
        if (record.roles) {
          record.roles.map((role_item: any, role_index: any) => {
            if (role_index == 0) {
              data_value = data_value.concat(role_item.name);
            } else {
              data_value = data_value.concat(",", role_item.name);
            }
          });
        }
        return data_value;
      },
    },
    {
      key: "custom_roles",
      width: 150,
      text: "Custom Roles",
      sortable: true,
      cell: (record: any, index: any) => {
        let data_value = "";
        if (record.custom_roles) {
          record.custom_roles.map((role_item: any, role_index: any) => {
            if (role_index == 0) {
              data_value = data_value.concat(role_item.name);
            } else {
              data_value = data_value.concat(",", role_item.name);
            }
          });
        }

        return data_value;
      },
    },
    { key: "created_at", width: 150, text: "Created At", sortable: true },
    {
      key: "created_user",
      width: 150,
      text: "Created User",
      sortable: true,
      cell: (record: any, index: any) => {
        return record.created_user + "@" + record.created_username;
      },
    },
    {
      key: "action",
      text: "Action",
      width: 250,
      className: "text-center",
      align: "center",
      cell: (record: any, index: any) => {
        return (
          <div className="d-flex justify-content-center">
            {/* //Edit */}
            <div
              onClick={() => {
                let set_data = {
                  value: record,
                  index: index,
                };
                SetModalUser({
                  is_open: true,
                  type: "edit",
                  data: set_data,
                });
                console.log("Edit");
              }}
              style={{ marginRight: "5px" }}
            >
              <i
                className="fas fa-pen pe-4 cursor"
                style={{ color: "gray" }}
              ></i>
            </div>
            {/* //Delete */}
            <div
              onClick={() => {
                remove_user(record, index);
                console.log("delete record:", record);
              }}
            >
              <i
                className="fas fa-trash pe-4 cursor"
                style={{ color: "gray" }}
              ></i>
            </div>
          </div>
        );
      },
    },
  ];


  // ---------------(useEffect)

  // -------------------------------(useEffect with tab-value of users)

  useEffect(() => {
    console.log("ViewFranchise On mount :", props);
    console.log("Franchise context :", context);
    console.log("ProductAssign OutletContext :", OutletContext);
  }, []);

  useEffect(() => {
    if (params.tab_value == "details") {
      console.log("Tab is on details");
      get_franchise_details();
    } else if (params.tab_value == "users") {
      console.log("Tab is on users");
      get_franchise_user_details();
    }
  }, [params]);

  // ---------------(Franchise Details)

  async function get_franchise_details() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        franchise: id_value.franchise_id,
      },
    };
    let data: any = await api("/brand/franchise_details", pass_data);
    console.log("franchise_details response :", data);
    if (data.status_code == 200) {
      if (data.response.hasOwnProperty("franchise")) {
        SetFranchiseDetails(data.response.franchise[0]);
      }
    }
  }

  // ---------------(Get Franchise Users details)
  async function get_franchise_user_details() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        franchise: id_value.franchise_id,
      },
    };
    let data = await api("/brand/franchise_users", pass_data);
    console.log("franchise_users response :", data);
    if (data.status_code == 200) {
      SetFranchiseUserData(data.response.users);
    }
  }

  // ---------------(Remove User)
  async function remove_user(record: any, index: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        franchise: id_value.franchise_id,
      },
      post: {
        user_id: record.id,
      },
    };

    let response: any = await api("/brand/remove_franchise_user", pass_data);
    console.log("remove_franchise_user response :", response);
    if (response.status_code == 200) {
      let data = franchise_user_data;
      data.splice(index, 1);
      console.log("Sliced Data :", data);
      SetFranchiseUserData([...data]);
    }
  }


  // ---------------(Delete_Franchise)
  async function delete_franchise(data: any, index: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        franchise_id: id_value.franchise_id,
      },
    };
    console.log("pass_data :", pass_data);
    let response: any = await api("/brand/delete_franchise", pass_data);
    console.log("/brand/delete_franchise response :", response);
    if (response.status_code == 200) {
      // ---------------(Back button)
      window.history.back();
      // let old_outlet_data = outlet_data;
      // old_outlet_data.splice(index, 1);
      // SetOutletData([...old_outlet_data]);
      // get_outlets();
    }
  }

  // ------------------(Three Dotted Menu)
  const list = (anchor: any) => (
    <div
      className="mt-3"
    // className={ anchor === 'top' || anchor === 'bottom'}
    // role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div>
        <div className="d-flex justify-content-center">
          <div className="w-25 px-4">
            <hr className="h-3" />
          </div>
        </div>
        <ul>
          <li
            className="p-2 pb-3 cursor align-center"
            onClick={(event) => {
              let set_data = {
                value: selected_data.value,
                index: selected_data.i,
              };
              SetModalUser({
                is_open: true,
                type: "view",
                data: set_data,
              });
              sample_fun(anchor, false, event);
              console.log("selected Data: ", selected_data);
            }}
          >
            <img
              src={AppConfig.CDN_Media_url+"85f32ee26cfd14b6b8b0306b6736b6b9.svg"}
              className="me-2 action_menu_img cursor"
            />
            View
          </li>
          <li
            className="p-2 pb-3 cursor align-center"
            onClick={(event) => {
              let set_data = {
                value: selected_data.value,
                index: selected_data.i,
              };
              SetModalUser({
                is_open: true,
                type: "edit",
                data: set_data,
              });
              console.log("Edit");
              sample_fun(anchor, false, event);
            }}
          >
            <img
              src={AppConfig.CDN_Media_url+"549953e966fa109d562d117ad4dfedfd.svg"}
              className="me-2 action_menu_img cursor"
            />
            Edit
          </li>
          <li
            className="p-2 pb-3 cursor align-center"
            onClick={(event) => {
              remove_user(selected_data.value, selected_data.i);
              sample_fun(anchor, false, event);
            }}
          >
            <img
              src={AppConfig.CDN_Media_url+"f89c20a26e87e647e0d98bd7b8a4ac92.svg"}
              className="me-2 action_menu_img cursor"
            />
            Delete
          </li>
        </ul>
      </div>
    </div>
  );

  function sample_fun(anchor: any, open: any, event: any) {
    console.log("sample_fun anchor :", anchor);
    console.log("sample_fun open :", open);
    console.log("sample_fun event :", event);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      console.log("return");
      return;
    }
    console.log("toggle else");

    setState({ ...state, [anchor]: open });
    // toggleDrawer("bottom", true);
  }

  return (
    <div>
      <div className={OutletContext ? franchise_details == null ? " head-bar w-100" : " head-bar sticky-sm" : "head-bar sticky-top"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="bg-fff d-flex align-items-center">
          <div className="d-flex align-items-center">
            <div className="px-1 cursor" onClick={() => {
              window.history.back(1);
            }}>
              {/* <Link to={"/brand/" + id_value.brand_id + "/franchise"}> */}
              <MaterialCommunityIcons name="arrow-left" size={20} color="black" />
              {/* </Link> */}
            </div>
            <h6 className="mb-0 ps-1">Franchise details</h6>
          </div>
          {/* <div className="ms-auto">
            <button
              className="btn btn-custom-outline btn-radius fw-bold px-4 py-1  btn-sm"
              onClick={() => {
                console.log("Franchise new");
                SetModalFranchise((prevValue: any) => {
                  prevValue.is_open = true;
                  prevValue.type = "edit";
                  return { ...prevValue };
                });
              }}
            >
              Edit
            </button>
          </div> */}
        </div>
        <nav className="pt-3 px-2 sticky-tabs">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            {/* --------(Details_tabs) */}
            <Link
              to={
                "/brand/" +
                id_value.brand_id +
                "/viewfranchise/" +
                id_value.franchise_id +
                "/details"
              }
            >
              <div
                className={
                  params.tab_value == "details"
                    ? "nav-link active cursor"
                    : "nav-link cursor"
                }
                id="details-tab"
                data-bs-toggle="tab"
                data-bs-target="#details"
                role="tab"
                aria-controls="details"
                aria-selected="true"
              >
                Details
              </div>
            </Link>

            {/* --------(Users_tabs) */}
            <Link
              to={
                "/brand/" +
                id_value.brand_id +
                "/viewfranchise/" +
                id_value.franchise_id +
                "/users"
              }
            >
              <div
                className={
                  params.tab_value == "users"
                    ? "nav-link active cursor"
                    : "nav-link cursor"
                }
                id="users-tab"
                data-bs-toggle="tab"
                data-bs-target="#users"
                role="tab"
                aria-controls="users"
                aria-selected="false"
              >
                Users
              </div>
            </Link>

            {/* --------(Outlets_tabs) */}
            <Link
              to={
                "/brand/" +
                id_value.brand_id +
                "/viewfranchise/" +
                id_value.franchise_id +
                "/outlets"
              }
            >
              <div
                className={
                  params.tab_value == "outlets"
                    ? "nav-link active cursor"
                    : "nav-link cursor"
                }
                id="outlets-tab"
                data-bs-toggle="tab"
                data-bs-target="#outlets"
                role="tab"
                aria-controls="outlets"
                aria-selected="false"
              >
                Outlets
              </div>
            </Link>
          </div>
        </nav>
      </div>
      {/* ----------------------------(new) */}

      {/* ----------------------------------------(show) */}
      <div className={OutletContext ? "px-md-3 px-sm-0" : " px-md-3 px-sm-0"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="tab-content mb-5">
          {/* Details tab */}
          <div
            className={
              params.tab_value == "details"
                ? "tab-pane fade show active p-1"
                : "tab-pane fade p-1"
            }
            id="details"
            role="tabpanel"
            aria-labelledby="details-tab"
          >

            <div className="p-2">
              <div className="row radius shadow">
                <div className="col-md-3 bg-light-prime p-3 text-center">
                  {franchise_details != null ? (
                    <img
                      src={franchise_details.dp_url}
                      className="franchise-user-img"
                    />
                  ) : (
                    <img
                      src={AppConfig.CDN_Image_url+"78a9cfd4dc78df18e9f3488646798286.png"}
                      className="franchise-user-img"
                    />
                  )}
                  <div className="mt-2">
                    <p className="v-small oneline">@{franchise_details.user_name}</p>

                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <img src={AppConfig.CDN_Media_url+"34cbda808b5d1fa467981298dabc6c1e.svg"} className="w-14px" />
                    {/* <SimpleLineIcons name="location-pin" size={13} color="#222" /> */}
                    <p className="ps-1 small text-black capitalize">
                      {franchise_details.address}
                    </p>
                  </div>
                  <div className="mt-3">
                    <button
                      className="btn bg-prime text-white btn-radius btn-sm px-4 py-1"

                      onClick={() => {
                        SetEditFranchiseModal({

                          is_open: true,
                          data: {
                            address: franchise_details.address,
                            email: franchise_details.email,
                            id: id_value.franchise_id,
                            mobile: franchise_details.mobile,
                            name: franchise_details.name,
                            website: franchise_details.website,
                            whatsapp: franchise_details.whatsapp,
                          },
                          index: 0,
                        });
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <div className="col-md-9 bg-fff p-3">
                  <div className="d-flex">
                    <div className="w-100">
                      <div className="d-flex w-100">
                        <h6 className="mb-1 oneline">{franchise_details.name}</h6>
                        <div className="ms-auto cursor px-2">
                          <div className="dropdown menu user-dropdown">
                            <div
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={AppConfig.CDN_Media_url+"ba1e0dd0ac4b55e689ddcdc4cd7f2b22.svg"} className="w-20px" />
                              {/* <Entypo name="dots-three-vertical" size={16} color="#777" /> */}
                            </div>

                            <ul className="dropdown-menu ms-auto dropdown-menu-start">
                              <li className="cursor"
                                onClick={() => {
                                  SetDeleteModal((prevValue) => {
                                    prevValue.is_open = true;
                                    prevValue.title = "Outlet";
                                    prevValue.record = {};
                                    prevValue.index = 0;
                                    return { ...prevValue };
                                  });
                                }}>
                                <button className="dropdown-item py-2 mb-1 d-flex align-items-center" type="button">
                                  <img src={AppConfig.CDN_Media_url+"f89c20a26e87e647e0d98bd7b8a4ac92.svg"} className="w-20px" />
                                  {/* <MaterialCommunityIcons name="delete-outline" size={18} color="#666" /> */}
                                  <p className="ps-3 fs-7  text-black">Delete</p>
                                </button>
                              </li>
                            </ul>
                          </div>

                        </div>
                      </div>
                      <small className="extra-small">{franchise_details.created_at}</small>

                      <div className="d-flex align-items-center pt-2">
                        <img src={AppConfig.CDN_Media_url+"8693f530b4af706c92d5aee49fad635c.svg"} className="w-14px" />
                        {/* <Ionicons name="md-mail-outline" size={16} color="gray" /> */}
                        <p className="ps-2">
                          {franchise_details.email}
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr className="hr-drawer" />

                  <div className="mt-2">


                    <div className="mt-2">
                      <p className="text-black fw-bold ">Contact Details</p>
                      <div className="d-flex align-items-center pt-3">
                        <img src={AppConfig.CDN_Media_url+"2cc7c27a30b5f18deaa8d839882e6eaf.svg"} className="w-14px" />
                        {/* <Feather name="phone-call" size={14} color="#777" /> */}
                        <p className="ps-2 small">
                          {franchise_details.mobile}
                        </p>
                      </div>

                      <div className="d-flex align-items-center pt-3">
                        <img src={AppConfig.CDN_Media_url+"072080ec311358dc3c3f759630c6283b.svg"} className="w-14px" />
                        {/* <FontAwesome name="whatsapp" size={16} color="#777" /> */}
                        <p className="ps-2 small">
                          {franchise_details.whatsapp}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




            {/* <div className="card p-3">

              <div className="px-sm-0 px-md-2">
                <div className="d-flex align-items-center pb-2 border-bottom">
                  {franchise_details != null ? (
                    <img
                      src={franchise_details.dp_url}
                      className="user_img_radius"
                    />
                  ) : (
                    <img
                      src={require("../../../assets/icon/empty.png")}
                      className="table-user-img"
                    />
                  )}
                  <div className="ps-2 w-100">
                    <h6 className="mb-1 oneline">{franchise_details.name}</h6>
                    <div className="d-flex align-items-center w-100">
                      <p className="v-small oneline">@{franchise_details.user_name}</p>
                      <div className="ms-auto">
                        <small className="extra-small">{franchise_details.created_at}</small>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row align-items-center">
                  <div className="col-md-4">
                    <div className="d-flex align-items-center pt-3">
                      <Ionicons name="md-mail-outline" size={16} color="gray" />
                      <p className="ps-2">
                        {franchise_details.email}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4 ">
                    <div className="d-flex align-items-center pt-3">
                      <Feather name="phone-call" size={14} color="#777" />
                      <p className="ps-2">
                        {franchise_details.mobile}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4 ">
                    <div className="d-flex align-items-center pt-3">
                      <FontAwesome name="whatsapp" size={16} color="#777" />
                      <p className="ps-2">
                        {franchise_details.whatsapp}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center pt-3">
                  <SimpleLineIcons name="location-pin" size={16} color="gray" />
                  <p className="ps-2">
                    {franchise_details.address}
                  </p>
                </div>

                <div className="d-flex align-items-center mt-4 w-100 end">
                  <button
                    className="btn btn-outline-light text-gray border btn-radius btn-sm  px-3 py-1"
                    onClick={() => {
                      SetDeleteModal((prevValue) => {
                        prevValue.is_open = true;
                        prevValue.title = "Outlet";
                        prevValue.record = {};
                        prevValue.index = 0;
                        return { ...prevValue };
                      });
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="btn bg-prime text-white btn-radius btn-sm px-4 py-1 ms-3"

                    onClick={() => {
                      SetEditFranchiseModal({

                        is_open: true,
                        data: {
                          address: franchise_details.address,
                          email: franchise_details.email,
                          id: id_value.franchise_id,
                          mobile: franchise_details.mobile,
                          name: franchise_details.name,
                          website: franchise_details.website,
                          whatsapp: franchise_details.whatsapp,
                        },
                        index: 0,
                      });
                    }}
                  >
                    Edit
                  </button>

                </div>
              </div>
            </div> */}


          </div>

          {/* //Users tab*/}
          <div
            className={
              params.tab_value == "users"
                ? "tab-pane fade show active p-2"
                : "tab-pane fade p-2"
            }
            id="users"
            role="tabpanel"
            aria-labelledby="users-tab"
          >
            <div className="p-3 card">
              <div className="row px-md-3 px-sm-0">

                <div className="col-6 col-md-8 pb-2 text-end">
                  <input
                    type="text"
                    className="form-control btn-radius"
                    id="staticEmail"
                    placeholder="Search in records..."
                  />
                </div>

                <div className="col-3 col-md-2 pb-2 px-0">
                  <div className="d-flex align-items-center w-100">
                    {/* <p className="mb-0 pe-2">Show</p> */}
                    <select
                      className="form-select w-100  btn-radius"
                      aria-label="Default select example "
                    >
                      <option selected value={10}>
                        10
                      </option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                    </select>
                    {/* <p className="mb-0 ps-2">records per page</p> */}
                  </div>
                </div>
                <div className="col-3 col-md-2 pb-2 px-0">
                  <div className="d-flex align-items-center w-100">
                    {/* <p className="mb-0 pe-2">Show</p> */}
                 <button className="btn-primary ms-auto"
                      onClick={() => {
                     
                        SetModalUser({
                          is_open: true,
                          type: "add",
                          // data: set_data,
                        });
                        console.log("Edit");
                      }}
                 >Create</button>
                    {/* <p className="mb-0 ps-2">records per page</p> */}
                  </div>
                </div>
              </div>
              <table className="table table-hover mt-3">
                <div className="d-md-block d-sm-none">
                  <div className="row">
                    {table_header.map((head, t_index) => (
                      <div key={t_index} className={t_index == 0 ? "col-md-4" : "col-md-2"}>
                        <p>{head.name}</p>
                      </div>
                    ))}

                  </div>
                </div>

                <tbody>
                  {franchise_user_data.map((data: any, o_index: any) => (
                    <div className="row border-bottom" key={o_index}>

                      <div className="col-md-4">
                        <div className="d-flex align-items-center mb-2">
                          {franchise_details != null ? (
                            <img
                              src={data.profile_picture}
                              className="table-user-img"
                            />
                          ) : (
                            <img
                              src={AppConfig.CDN_Image_url+"78a9cfd4dc78df18e9f3488646798286.png"}
                              className="table-user-img"
                            />
                          )}
                          <div className="ps-2 w-100">
                            <h6 className="mb-1 oneline">{data.name}</h6>
                            <p className="v-small oneline">@{data.username}</p>
                          </div>
                        </div>
                      </div>
                      {width < 786 ? data.hasOwnProperty("roles")
                        ?
                        <div className="col-md-2">
                          {data.roles.map((role_item: any, role_index: any) => (
                            <div
                              className="center-wrapper"
                              key={role_index}
                            >
                              <p className="mb-1">{width < 786 ? <b className="pe-1 text-black">Roles :</b> : ''}{role_item.name}</p>
                            </div>
                          )
                          )}
                        </div>
                        : null
                        :
                        <div className="col-md-2">
                          {data.hasOwnProperty("roles")
                            ? data.roles.map((role_item: any, role_index: any) => (
                              <div
                                className="center-wrapper"
                                key={role_index}
                              >
                                <p className="mb-1">{width < 786 ? <b className="pe-1 text-black">Roles :</b> : ''}{role_item.name}</p>
                              </div>
                            )) : null}
                        </div>
                      }

                      {width < 786 ? data.hasOwnProperty("custom_roles")
                        ?
                        <div className="col-md-2">
                          {data.custom_roles.map(
                            (role_item: any, role_index: any) => (
                              <div
                                className="center-wrapper"
                                key={role_index}
                              >
                                <p className="mb-1">{width < 786 ? <b className="pe-1 text-black">Custom Roles :</b> : ''}{role_item.name}</p>
                              </div>
                            )
                          )}
                        </div>
                        : null

                        :
                        <div className="col-md-2">
                          {data.hasOwnProperty("custom_roles")
                            ? data.custom_roles.map(
                              (role_item: any, role_index: any) => (
                                <div
                                  className="center-wrapper"
                                  key={role_index}
                                >
                                  <p className="mb-1">{width < 786 ? <b className="pe-1 text-black">Custom Roles :</b> : ''}{role_item.name}</p>
                                </div>
                              )) : null}
                        </div>
                      }

                      <div className="col-md-2 col-6">
                        <p className={width < 786 ? "v-small" : ""}>{data.created_at}</p>
                      </div>
                      <div className="col-md-2 col-6 sm-end">
                        <div className="center-wrapper cursor">
                          <div className="dropdown">
                            <div
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={AppConfig.CDN_Media_url+"ba1e0dd0ac4b55e689ddcdc4cd7f2b22.svg"} className="w-20px" />
                              
                              {/* <Entypo name="dots-three-vertical" size={16} color="#666" /> */}
                            </div>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <a className="dropdown-item" href="#">
                                  <div
                                    className="cursor d-flex align-items-center py-2"
                                    onClick={() => {
                                      let set_data = {
                                        value: data,
                                        index: o_index,
                                      };
                                      SetModalUser({
                                        is_open: true,
                                        type: "view",
                                        data: set_data,
                                      });
                                      console.log("view :", o_index);
                                    }}
                                    style={{ marginRight: "5px" }}
                                  >
                                     <img
              src={AppConfig.CDN_Media_url+"85f32ee26cfd14b6b8b0306b6736b6b9.svg"} className="w-20px" />
                                    <p className="ps-2 text-black">View</p>
                                  </div>
                                </a>
                              </li>
                              {/* Edit */}
                              <li>
                                <a className="dropdown-item" href="#">
                                  <div
                                    className="cursor d-flex align-items-center py-2"
                                    onClick={() => {
                                      let set_data = {
                                        value: data,
                                        index: o_index,
                                      };
                                      SetModalUser({
                                        is_open: true,
                                        type: "edit",
                                        data: set_data,
                                      });
                                      console.log("Edit");
                                    }}
                                  >
                                     <img
              src={AppConfig.CDN_Media_url+"549953e966fa109d562d117ad4dfedfd.svg"} className="w-20px"/>
                                    <p className="ps-2 text-black">Edit</p>
                                  </div>
                                </a>
                              </li>
                              {/* Delete */}
                              <li>
                                <a className="dropdown-item " href="#">
                                  <div
                                    className="cursor d-flex align-items-center py-2"
                                    onClick={() => {
                                      remove_user(data, o_index);
                                    }}
                                  >
                                    {/* <MaterialIcons name="delete-outline" size={18} color="#555" /> */}
                                  <img src={AppConfig.CDN_Media_url+"f89c20a26e87e647e0d98bd7b8a4ac92.svg"} className="w-20px" />
                                    <p className="ps-2 text-black">Delete</p>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                    </div>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <DeleteAlert
            open={delete_modal.is_open}
            title={delete_modal.title}
            record={delete_modal.record}
            index={delete_modal.index}
            close={(data) => {
              SetDeleteModal((prevValue) => {
                prevValue.is_open = false;
                return { ...prevValue };
              });

              if (data.action === "delete") {
                console.log("delete Outlet : ", data);
                delete_franchise(data.value, data.index);
                // delete_service(data.value, data.index);
              }
            }}
          />

          {/* ---------------------------(ModalEditOutlet) */}

          <ModalEditFranchise
            open={edit_franchise_modal.is_open}
            data={edit_franchise_modal.data}
            index={edit_franchise_modal.index}
            close={(data: any) => {
              console.log("Edit Outlet Modal On Close :", data);
              SetEditFranchiseModal({
                is_open: false,
                data: {},
                index: "",
              });
              if (data.action == "edit") {
                get_franchise_details();
                // SetOutletData((prevValue) => {
                //   prevValue[data.index] = data.value;
                //   return [...prevValue];
                // });
              }
            }}
          />
          {/* ----------------------------(Outlets Mount) */}
          <div
            className={
              params.tab_value == "outlets"
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
            id="outlets"
            role="tabpanel"
            aria-labelledby="outlets-tab"
          >
            <div className="mt-3 mb-5">
            <BrandOutlet have_header={false}
              franchise_id={id_value.franchise_id}
            />
            </div>
          </div>

          {/* ---------------------------------(ModalFranchiseUser) */}

          <ModalFranchiseUser
            open={modal_user.is_open}
            type={modal_user.type}
            data={modal_user.data}
            close={(data: any) => {
              console.log(" Franchise User Modal On Close :", data);
              SetModalUser({
                is_open: false,
                type: "",
                data: {},
              });
              if (data.action == "save") {
                SetFranchiseUserData((prevValue) => {
                  console.log("prevValue :", prevValue);
                  if (prevValue == undefined) {
                    prevValue = [];
                    prevValue.push(data.value);
                  } else {
                    prevValue.push(data.value);
                  }
                  return [...prevValue];
                });
                // SetFranchiseUserData(data.value);
              } else if (data.action == "edit") {
                SetFranchiseUserData((prevValue) => {
                  prevValue[data.index] = data.value;
                  return [...prevValue];
                });
              }
            }}
          />

          {["left", "right", "top", "bottom"].map((anchor) => (
            <React.Fragment key={anchor}>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                // onClose={toggleDrawer(anchor, false)}
                // onOpen={toggleDrawer(anchor, true)}
                onClose={(event) => {
                  sample_fun(anchor, false, event);
                }}
                onOpen={(event) => {
                  sample_fun(anchor, true, event);
                }}
              >
                {list(anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          ))}

          {/* -------------------------------------(New) */}
          <ModalCreateFranchise
            open={modal_franchise.is_open}
            type={modal_franchise.type}
            close={(data: any) => {
              console.log("Franchise modl on close :", data);
              SetModalFranchise((prevValue: any) => {
                prevValue.is_open = false;
                return { ...prevValue };
              });
            }}
          />

        </div>
        {/* -----------------------------(late) */}
      </div>
    </div>
  )
}

