import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
const animatedComponents = makeAnimated();

let myModal = {};

export default function ModalImportRoles(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [user_role_options, SetUSerRoleOptions] = useState({});
  const [selected_roles, SetSelectedRoles] = useState({});

  useEffect(() => {
    console.log("ModalImportRoles On mount :", props);
    let myModal1 = document.getElementById("ImportRoleModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      SetSelectedRoles({});
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModalImportRoles On mount :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("ImportRoleModal"),
        {}
      );
      SetUSerRoleOptions(props.data);
      myModal.show();
    }
  }, [props.open]);

  async function import_role() {
    console.log("Import role");
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        role_id: selected_roles.value,
      },
    };
    let data = await api("/brand/get_system_userrole", pass_data);
    console.log("get_system_userrole response :", data);

    if (data.status_code == 200) {
      let item = close_data;
      item.action = "save";
      item.role_value = selected_roles;
      item.page_value = data.response.pages;
      SetCloseData(item);
      myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="ImportRoleModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              Import System Role
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="mt-2">
              <label className="form-label">Select Role</label>
              <Select
                components={animatedComponents}
                className="basic-multi-select"
                classNamePrefix="select Role"
                placeholder="Select Role"
                value={selected_roles}
                options={user_role_options}
                onChange={(e) => {
                  console.log("User Role on change e :", e);
                  SetSelectedRoles(e);
                }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <button
                type="button"
                className="btn bg-prime text-white btn-radius px-3"
                onClick={() => {
                  import_role();
                }}
              >
                Import
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
