// import React, { useState, useEffect, useContext } from "react";
// import DataContext from "../../../utils/Context/DataContext";
// import { Name } from "./Collections";
// import { Link, useParams } from "react-router-dom";
// import { api } from "../../../utils/Api";
// import {
//   MaterialCommunityIcons,
//   Ionicons,
//   MaterialIcons,
// } from "@expo/vector-icons";
// // import ModalVariants from "./ModalVariants";
// import Typography from "@material-ui/core/Typography";
// import Slider from "@material-ui/core/Slider";

// function valuetext(value) {
//   return `${value}°C`;
// }

// export default function CollectionProducts(props: any) {
//   const context = useContext(DataContext);
//   const [modal_variants, SetModalVariants] = useState({
//     is_open: false,
//     product_name: "",
//     product_id: "",
//     product_checked: "",
//     data: {},
//   });
//   const [text, setText] = useState("");
//   const [id_value, SerIdValue] = useState(useParams());
//   const [filter_open, SetFilterOpen] = useState(false);
//   const [product_data, SetProductData] = useState([]);
//   const [data, SetData] = useState([]);
//   const [variant, SetVariant] = useState([]);
//   const [filter, SetFilter] = useState([]);
//   const [timer, SetTimer] = useState(null);


//   const [sort_open, SetSortOpen] = useState(false);
//   const [min_max_value, SetMinMaxValue] = React.useState([20, 500]);
//   const [brand, SetBrand] = useState([]);
//   const [category, SetCategory] = useState([]);
//   const [f_products, SetFproducts] = useState({
//     own_products: false,
//     others_products: false,
//   });

//   const [sort, SetSort] = useState("");
//   const [collection_id, SetCollectionId] = useState("");

//   const [count, SetCount] = useState(10);


//   const [search, SetSearch] = useState("");
//   const [page_no, SetPageNo] = useState(1);

//   const [next_page, SetNextPage] = useState(false);
//   const WAIT_INTERVAL = 700;
//   const PRICE_WAIT_INTERVAL = 2000;

//   // const [sliderWidth, SetSlideWidth] = useState(0);
//   // const [offsetSliderWidht, SetOffsetSlideWidth] = useState(0);
//   // const [min, SetMin] = useState(0);
//   // const [max, SetMax] = useState(200);
//   // const [minValueBetween, SetMinValueBetween] = useState(10);
//   // const [currentMin, SetCurrentMin] = useState(55);
//   // const [inputMin, SetInputMin] = useState(55);
//   // const [currentMax, SetCurrentMax] = useState(100);
//   // const [inputMax, SetInputMax] = useState(100);

//   useEffect(() => {
//     console.log("Add Product On mount :", props);
//     console.log("Add Product context :", context);

//     var baseUrl = (window.location).href; // You can also use document.URL
//     var last = baseUrl.substring(baseUrl.lastIndexOf("/") + 1, baseUrl.length);
//     SetCollectionId(last);
//     get_add_products(page_no,count,min_max_value,last);
//     console.log(f_products);


//   }, []);


//   return (
// <div>

// <Name.Consumer>
//           {(fname) => {
//             return <h1>My Name is {fname}</h1>;
//           }}
//         </Name.Consumer>
// </div>
//   );
//     }





// import React, { useState, useEffect, useContext } from "react";
// import DataContext from "../../../utils/Context/DataContext";
// import { Link, useParams } from "react-router-dom";
// import { api } from "../../../utils/Api";

// import {
//   MaterialCommunityIcons,
//   Ionicons,
//   MaterialIcons,
// } from "@expo/vector-icons";
// // import ModalVariants from "./ModalVariants";
// import Typography from "@material-ui/core/Typography";
// import Slider from "@material-ui/core/Slider";

// function valuetext(value) {
//   return `${value}°C`;
// }

// export default function CollectionProducts(props: any) {
//   const context = useContext(DataContext);
//   const [modal_variants, SetModalVariants] = useState({
//     is_open: false,
//     product_name: "",
//     product_id: "",
//     product_checked: "",
//     data: {},
//   });
//   const [text, setText] = useState("");
//   const [type, setType] = useState("active");

//   const [id_value, SerIdValue] = useState(useParams());
//   const [filter_open, SetFilterOpen] = useState(false);
//   const [product_data, SetProductData] = useState([]);
//   const [data, SetData] = useState([]);
//   const [variant, SetVariant] = useState([]);
//   const [filter, SetFilter] = useState([]);
//   const [timer, SetTimer] = useState(null);



//   const [sort_open, SetSortOpen] = useState(false);
//   const [min_max_value, SetMinMaxValue] = React.useState([20, 500]);
//   const [brand, SetBrand] = useState([]);
//   const [category, SetCategory] = useState([]);
//   const [f_products, SetFproducts] = useState({
//     own_products: false,
//     others_products: false,
//   });

//   const [sort, SetSort] = useState("");
//   const [collection_id, SetCollectionId] = useState("");

//   const [count, SetCount] = useState(10);


//   const [search, SetSearch] = useState("");
//   const [page_no, SetPageNo] = useState(1);

//   const [next_page, SetNextPage] = useState(false);
//   const WAIT_INTERVAL = 700;
//   const PRICE_WAIT_INTERVAL = 2000;

//   // const [sliderWidth, SetSlideWidth] = useState(0);
//   // const [offsetSliderWidht, SetOffsetSlideWidth] = useState(0);
//   // const [min, SetMin] = useState(0);
//   // const [max, SetMax] = useState(200);
//   // const [minValueBetween, SetMinValueBetween] = useState(10);
//   // const [currentMin, SetCurrentMin] = useState(55);
//   // const [inputMin, SetInputMin] = useState(55);
//   // const [currentMax, SetCurrentMax] = useState(100);
//   // const [inputMax, SetInputMax] = useState(100);

//   useEffect(() => {
//     console.log("Add Product On mount :", props);
//     console.log("Add Product context :", context);

//     var baseUrl = (window.location).href; // You can also use document.URL
//     var last = baseUrl.substring(baseUrl.lastIndexOf("/") + 1, baseUrl.length);
//     SetCollectionId(last);
//     // get_add_products(page_no,count,min_max_value,last,type);
//     get_products();
//     console.log(f_products);


//   }, []);

//   function onTextChanged(e: any) {
//     clearTimeout(timer);
//     const search_text = e.target.value;
//     setText(search_text);
//     console.log("Text onchange: ", search_text);
//     if (search_text == "") {
//       let page_no_value = 1;
//       SetPageNo(page_no_value);
//       get_add_products(page_no_value,count,min_max_value,collection_id,type);
//     } else {
//       SetTimer(
//         setTimeout(() => {
//           get_add_products(1,count,min_max_value,collection_id, type,search_text);
//         }, WAIT_INTERVAL)
//       );
//     }
//   }

//   function countChange(e:any){
//     console.log("countChange:",e.target.value);
//     SetCount(e.target.value);
//     get_add_products(1,e.target.value,min_max_value,collection_id,type);
//     get_add_products(1,e.target.value,min_max_value,collection_id,type);

//   }

//   const handleChange = (event:any, newValue:any) => {

//     clearTimeout(timer);
//     SetMinMaxValue(newValue);
//     console.log(newValue);


//     if (newValue != "") {
//        SetTimer(
//         setTimeout(() => {
//           get_add_products(1,count,newValue,collection_id,type);
//         }, PRICE_WAIT_INTERVAL)
//       );
//     }
//     // SetMinMaxValue(newValue);

//     // get_add_products(1,count);
//   };

//   // async function get_add_products(page_value: any,count_value:any,f_min_max_value:any,last:any,type_a:any, search_value = null) {

//   //   SetCollectionId(last);
//   //   let pass_data = {};

//   //   if (search_value != null) {
//   //     pass_data = {
//   //       get: {
//   //         brand: id_value.brand_id,
//   //         page: page_value,
//   //         search: search_value,
//   //         filter_brand: encodeURIComponent(JSON.stringify(brand)),
//   //         filter_categories: encodeURIComponent(JSON.stringify(category)),
//   //         filter_min_mrp: f_min_max_value[0],
//   //         filter_max_mrp: f_min_max_value[1],
//   //         sort: sort,
//   //         filter_products: encodeURIComponent(JSON.stringify(f_products)),
//   //         filter_count:count_value,
//   //         collection_id:last,
//   //         type:type_a,
//   //       },
//   //     };
//   //   } else {
//   //     pass_data = {
//   //       get: {
//   //         brand: id_value.brand_id,
//   //         page: page_value,
//   //         filter_brand: encodeURIComponent(JSON.stringify(brand)),
//   //         filter_categories: encodeURIComponent(JSON.stringify(category)),
//   //         filter_min_mrp: f_min_max_value[0],
//   //         filter_max_mrp: f_min_max_value[1],
//   //         sort: sort,
//   //         filter_products: encodeURIComponent(JSON.stringify(f_products)),
//   //         filter_count:count_value,
//   //         collection_id:last,
//   //         type:type_a,


//   //       },
//   //     };
//   //   }
//   //   let response: any = await api("/brand/add_collection_product_list", pass_data);
//   //   console.log("/brand/add_brand_product_list :", response);
//   //   if (response.status_code == 200) {
//   //     SetData(response.response);

//   //     if (response.response.hasOwnProperty("data")) {
//   //       SetProductData(response.response.data);
//   //       SetFilter(response.response.filter);
//   //       SetNextPage(response.response.next_page);
//   //     } else {
//   //       SetProductData([]);
//   //     }
//   //   }
//   // }

//   async function get_products() {

//    console.log(id_value)
//     let pass_data = {};


//       pass_data = {
//         get: {
//           brand: id_value.brand_id,

//           collection_id: id_value.collect_id,

//         },
//       };

//     let response: any = await api("/brand/collection_products", pass_data);
//     console.log("/brand/collection_products:", response);
//     if (response.status_code == 200) {
//       SetData(response.response);

//       if (response.response.hasOwnProperty("data")) {
//         SetProductData(response.response.data);
//         SetFilter(response.response.filter);
//         SetNextPage(response.response.next_page);
//       } else {
//         SetProductData([]);
//       }
//     }
//   }



//   async function add_products(e:any) {
//     console.log(e.target.value)


//     let pass_data = {};

//       pass_data = {
//         get: {
//           brand: id_value.brand_id,
//           collection_id:collection_id,
//         },
//         post: {
//           products:[e.target.value],
//         },
//       };



//     let response: any = await api("/brand/add_collection_products", pass_data);
//     console.log("/brand/add_collection_products :", response);
//     if (response.status_code == 200) {
//       get_add_products(1,count,min_max_value,collection_id,type);
//     }
//   }


//   async function del_products(e:any) {
//     console.log(e.target.value)


//     let pass_data = {};

//       pass_data = {
//         get: {
//           brand: id_value.brand_id,
//           collection_id:collection_id,
//         },
//         post: {
//           products:[e.target.value],
//         },
//       };



//     let response: any = await api("/brand/remove_collection_products", pass_data);
//     console.log("/brand/remove_collection_products", response);
//     if (response.status_code == 200) {
//       get_add_products(1,count,min_max_value,collection_id,type);
//     }
//   }


//   async function input_on_change(event) {
//     console.log("input_on_change value", event.target.name);

//     console.log("input_on_change value", event.target.checked);
//     console.log("input_on_change value", f_products.others_products);

//     let arr = [];

//     if (event.target.checked == true) {
//       if (event.target.name == "own products") {
//         f_products.own_products = true;
//       }
//     } else {
//       if (event.target.name == "own products") {
//         f_products.own_products = false;
//       }
//     }

//     if (event.target.checked == true) {
//       if (event.target.name == "others products") {
//         f_products.others_products = true;
//       }
//     } else {
//       if (event.target.name == "others products") {
//         f_products.others_products = false;
//       }
//     }

//     if (event.target.checked == true) {
//       if (event.target.name == "filter_brand") {
//         brand.push(event.target.value);
//       }
//     } else {
//       if (event.target.name == "filter_brand") {
//         brand.splice(brand.indexOf(event.target.value, 1));
//       }
//     }

//     console.log(brand);
//     SetBrand(brand);

//     if (event.target.checked == true) {
//       if (event.target.name == "filter_category") {
//         category.push(event.target.value);
//       }
//     } else {
//       if (event.target.name == "filter_category") {
//         category.splice(brand.indexOf(event.target.value, 1));
//       }
//     }

//     get_add_products(1,count,min_max_value,type);
//   }

//   function dataSort() {
//     if (sort == "asc") {
//       SetSort("desc");
//       get_add_products(1,count,min_max_value,collection_id,type);
//     } else if (sort == "desc") {
//       SetSort("asc");

//       get_add_products(1,count,min_max_value,collection_id,type);
//     } else {
//       SetSort("asc");
//     }
//   }

//   async function load_more(search_value = "") {
//     let page_no_value = page_no + 1;
//     let pass_data = {};

//     SetPageNo(page_no_value);
//     if (search_value != null) {
//       pass_data = {
//         get: {
//           brand: id_value.brand_id,
//           page: page_no_value,
//           search: search_value,
//           filter_brand: encodeURIComponent(JSON.stringify(brand)),
//           filter_categories: encodeURIComponent(JSON.stringify(category)),
//           filter_min_mrp: min_max_value[0],
//           filter_max_mrp: min_max_value[1],
//         },
//       };
//     } else {
//       pass_data = {
//         get: {
//           brand: id_value.brand_id,
//           page: page_no_value,
//           filter_brand: encodeURIComponent(JSON.stringify(brand)),
//           filter_categories: encodeURIComponent(JSON.stringify(category)),
//           filter_min_mrp: min_max_value[0],
//           filter_max_mrp: min_max_value[1],
//         },
//       };
//     }
//     let response: any = await api("/brand/add_brand_product_list", pass_data);
//     console.log("/brand/add_brand_product_list :", response);
//     SetProductData((prevValue) => {
//       response.response.data.map((item) => {
//         prevValue.push(item);
//       });
//       return [...prevValue];
//     });
//     SetNextPage(response.response.next_page);
//   }

//   return (
//     <div className="">
//       <div className=" row align-items-center head-bar">
//         <div className="col-7 sm-mb-2">
//           <h6 className="  mb-1">Products</h6>
//         </div>
//         <div className="col-5 ps-0 text-end sm-mb-2">
//           <Link to={"/brand/" + id_value.brand_id + "/addproducts_new"}>
//             <button className="btn btn-primary btn-sm">Add Products</button>
//           </Link>
//         </div>
//       </div>
//       <div className="mt-1 bg-fff ">
//         <div className="p-3 row align-items-center">
//           <div className="tabs col-md-4 p-0 sm-mb-2">
//             <div className="tabs col-md-4 p-0 sm-mb-2">
//             <div
//                 className={
//                   type == "" ? "all_tab tabs-head" : "tabs-head"
//                 }
//                 onClick={() => {
//                   setType("all");

//                   get_products(1, count, min_max_value, "");
//                 }}
//               >
//                 <p className="mb-0">All</p>
//               </div>
//               <div
//                 className={
//                   type == "active" ? "active_tab tabs-head" : "tabs-head"
//                 }
//                 onClick={() => {
//                   setType("active");

//                   get_products(1, count, min_max_value, "active");
//                 }}
//               >
//                 <p className="mb-0">Active</p>
//               </div>
//               <div
//                 className={
//                   type == "unavailable" ? "active_tab tabs-head" : "tabs-head"
//                 }
//                 onClick={() => {
//                   setType("unavailable");
//                   get_products(1, count, min_max_value, "unavailable");
//                 }}
//               >
//                 <p className="mb-0">unavailable </p>
//               </div>
//               <div
//                 className={
//                   type == "paused" ? "active_tab tabs-head" : "tabs-head"
//                 }
//                 onClick={() => {
//                   setType("paused");
//                   get_products(1, count, min_max_value, "paused");
//                 }}
//               >
//                 <p className="mb-0">paused </p>
//               </div>
//             </div>
//           </div>

//           <div className="col-md-8 row ">
//             <div className="col-4 col-md-8">
//               <input
//                 type="text"
//                 placeholder="Search..."
//                 className="form-control"
//                 defaultValue={text}
//                 onChange={(e) => {
//                   onTextChanged(e);
//                 }}
//               />
//             </div>
//             <div className="col-2 col-md-2 ">
//               <select
//                 className="form-control"
//                 defaultValue="10"
//                 onChange={(e) => {
//                   countChange(e);
//                 }}
//               >
//                 <option value="10">10</option>
//                 <option value="20">20</option>
//                 <option value="30">30</option>

//                 <option value="40">40</option>

//                 <option value="50">50</option>
//               </select>
//             </div>
//             <div className="col-2 col-md-2 ">
//               <button
//                 className="btn btn-primary btn-sm ms-auto"
//                 onClick={() => {
//                   dataSort();
//                 }}
//               >
//                 Sort
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* </div> */}

//       <div className="p-3">
//         <div className="row p-3">
//           <div className="col-md-3 md-ps-0 sm-p-0">
//             <div className="bg-fff p-3">
//               <div className="d-flex ">
//                 <h6>Filter</h6>
//                 <div className="ms-auto">
//                   <small>Clear</small>
//                 </div>
//               </div>
//               <div className="my-3">
//                 <label className="form-label d-flex align-items-center">
//                   <input
//                     type="checkbox"
//                     className="form-check me-2"
//                     name={"own products"}
//                     // value={ele.id}
//                     onChange={(event) => {
//                       input_on_change(event);
//                     }}
//                   />
//                   <small>{"own product"}</small>
//                 </label>

//                 <label className="form-label d-flex align-items-center">
//                   <input
//                     type="checkbox"
//                     className="form-check me-2"
//                     name={"others products"}
//                     // value={ele.id}
//                     onChange={(event) => {
//                       input_on_change(event);
//                     }}
//                   />
//                   <small>{"other product"}</small>
//                 </label>
//               </div>
//               <div className="d-flex ">
//                 <h6>Category</h6>
//               </div>
//               <div className="my-3">
//                 {/* {Object.keys(option).length > 0
//                   ? */}
//                   { option?.product_categories?.map((ele: any) => (
//                       <label className="form-label d-flex align-items-center">
//                         <input
//                           type="checkbox"
//                           className="form-check me-2"
//                           name={"filter_category"}
//                           value={ele.id}
//                           onChange={(event) => {
//                             input_on_change(event);
//                           }}
//                         />
//                         <small>{ele.name}</small>
//                       </label>
//                     ))
//                         }
//                   {/* : null} */}
//               </div>

//               <div className="accordion" id="accordionPanelsStayOpenExample">
//                 <div className="accordion-item">
//                   <h2 className="accordion-header" id="acc-head-id">
//                     <button
//                       className="accordion-button"
//                       type="button"
//                       data-bs-toggle="collapse"
//                       data-bs-target="#acc-id"
//                       aria-expanded="false"
//                       aria-controls="acc-id"
//                     >
//                       Brand
//                     </button>
//                   </h2>
//                   <div
//                     id="acc-id"
//                     className="accordion-collapse collapse "
//                     aria-labelledby="acc-head-id"
//                   >
//                     <div className="accordion-body">
//                       <input
//                         type="text"
//                         placeholder="Search..."
//                         className="form-control"
//                       />
//                       <div className="p-2">
//                         {/* {Object.keys(option).length > 0
//                           ?  */}
//                          { option.product_brands?.map((ele: any) => (
//                               <label className="form-label mt-3 d-flex align-items-center">
//                                 <input
//                                   type="checkbox"
//                                   className="me-2"
//                                   name={"filter_brand"}
//                                   value={ele.id}
//                                   onChange={(event) => {
//                                     input_on_change(event);
//                                   }}
//                                 />
//                                 {ele.name}
//                               </label>
//                             ))
//                         }
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="mt-3">
//                 <Typography id="range-slider" gutterBottom>
//                   Mininum Maximum Price
//                 </Typography>
//                 <div className="d-flex align-items-center">
//                   {/* <p className="pe-3">Min</p> */}
//                   <Slider
//                     value={min_max_value}
//                     onChange={handleChange}
//                     step={20}
//                     onClick={() => {
//                       console.log("min max :", min_max_value);
//                     }}
//                     valueLabelDisplay="auto"
//                     aria-labelledby="range-slider"
//                     getAriaValueText={valuetext}
//                     min={10}
//                     max={1000}
//                   />
//                   {/* <p className="px-3">Max</p> */}
//                 </div>

//                 <div className="row">
//                   <div className="col-6">
//                     <label className="form-label">Min Price</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       name={"min_mrp"}
//                       value={min_max_value[0]}
//                       onChange={(event) => {
//                         input_on_change(event);
//                       }}
//                     />
//                   </div>
//                   <div className="col-6">
//                     <label className="form-label">Max Price</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       name={"max_mrp"}
//                       value={min_max_value[1]}
//                       onChange={(event) => {
//                         input_on_change(event);
//                       }}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-md-9 sm-mt-3">
//             <div className="p-3">
//               {add_product ? (
//                 <>
//                   <div className="row">
//                     {product_data.map((ele: any) => (
//                       <div className="col-md-6">
//                         <div
//                           className="card p-3 mb-2"
//                           onClick={(event) => {
//                             if (width < 768) {
//                               console.log("width", width);
//                               // SetSelectedData({
//                               //     value: data,
//                               //     i: u_index,
//                               //     type: "active",
//                               //   });
//                               sample_fun("bottom", true, event);
//                             }
//                           }}
//                         >
//                           <div className="row align-items-center mb-2">

//                             <div className="col-10">
//                               {/* <h6 className="mb-0">Oppo A9 2020</h6> */}

//                               <h6 className="mb-0">                             <input
//                 type="checkbox"
//                 className="me-2"
//                 checked={ele.checked}
//                 name="checked"
//                 // key= {index}
//                 onChange={(e) => {
//                   // check_product(e);
//                 }}
//               />{ele.name}</h6>
//                             </div>
//                             <div className="col-2 text-end">
//                               <FontAwesome
//                                 name="circle"
//                                 size={14}
//                                 color="#18b901"
//                               />
//                             </div>
//                           </div>
//                           <div className="row">
//                             <div className="col-md-3 col-3">
//                               {/* <img src={require("../../../assets/img/mob.png")} className="w-100 product-img" /> */}

//                               {ele.images ? (
//                                 <img
//                                   src={
//                                     // Object.keys(option).length > 0
//                                     //   ?
//                                        data.image_base_url +
//                                        data.sizes[0] +
//                                         "/" +
//                                         ele.images[0]?.url
//                                       // : null
//                                   }
//                                   className="img-fluid mb-auto"
//                                 />
//                               ) : (
//                                 <img
//                                   src={
//                                     "https://cpimg.tistatic.com/06747380/b/4/Used-Iphone-12.jpg"
//                                   }
//                                   alt="pic"
//                                   className="img-fluid"
//                                 />
//                               )}

// <div className="mt-5" >
// {ele.checked?
//                               <button className="btn btn-primary"
//                               onClick={() => {
//                                 Pause(ele.id);
//                               }}
//                               >pause</button>
//                               :
//                               <button className="btn btn-primary"
//                               onClick={() => {
//                                 Enable(ele.id);

//                               }}
//                               >enable</button>
// }
//                               </div>
//                             </div>
//                             <div className="col-md-8 col-9 ps-0">
//                               <p className="single_line">{ele.description}</p>
//                               <div className="row">
//                                 <div className="col-12 mb-2">
//                                   Package Details:
//                                 </div>
//                                 <div className="col-6">

//                                   <p>
//                                     l x b x h :{" "}

//                                     {ele.additional_details.package_height ?
//                                     <small className="text-dark">
//                                       {/* 6 */}
//                                       <span className="text-dark">
//                                         {/* {ele.additional_details
//                                           .package_breadth +
//                                           " " +
//                                           ele.additional_details
//                                             .package_bread

//                                             th_unit_name}
//                                       </span>{" "} */}

// {ele.additional_details.package_length +
//                                           " " +
//                                           ele.additional_details
//                                             .package_length_unit_name}
//                                             {" "}  x{" "}

// {ele.additional_details
//                                           .package_breadth +
//                                           " " +
//                                           ele.additional_details
//                                             .package_breadth_unit_name}

// {" "}  x{" "}

//                                             {ele.additional_details.package_height +
//                                           " " +
//                                           ele.additional_details
//                                             .package_height_unit_name}

//                                       </span>{" "}
//                                     </small>
// : "-"}
//                                   </p>
//                                 </div>
//                                 {/* <div className="col-6">
//                                   <p>
//                                     Height :{" "}
//                                     <small className="text-dark">
//                                       {" "}
//                                       <span className="text-dark">
//                                         {ele.additional_details.package_height +
//                                           " " +
//                                           ele.additional_details
//                                             .package_height_unit_name}
//                                       </span>
//                                     </small>
//                                   </p>
//                                 </div>

//                                 <div className="col-6">
//                                   <p>
//                                     Length :
//                                     <small className="text-dark">
//                                       {" "}
//                                       <span className="text-dark">
//                                         {ele.additional_details.package_length +
//                                           " " +
//                                           ele.additional_details
//                                             .package_length_unit_name}
//                                       </span>
//                                     </small>
//                                   </p>
//                                 </div> */}
//                                 <div className="col-6">
//                                   <p>
//                                     Weight :{" "}
//                                     {ele.additional_details.package_weight ?

//                                     <small className="text-dark">
//                                       {ele.additional_details.package_weight +
//                                         " " +
//                                         ele.additional_details
//                                           .package_weight_unit_name}
//                                     </small>
//                                     : "-"}
//                                   </p>
//                                 </div>
//                                 <div className="col-12 mb-2">
//                                   Tax Information:
//                                 </div>
//                                 <div className="col-6">
//                                   <p>
//                                     Gst Percent :{" "}
//                                     <small className="text-dark">
//                                       {ele.additional_details.gst_percent}
//                                     </small>
//                                   </p>
//                                 </div>
//                                 <div className="col-6">
//                                   <p>
//                                     Hsn :{" "}
//                                     <small className="text-dark">
//                                       {" "}
//                                       {ele.additional_details.hsn}
//                                     </small>
//                                   </p>
//                                 </div>

//                                 <div className="col-12 mb-2 text-center">


//                                 {ele.available?

//                             <button type="button" class="btn btn-primary "
//                               onClick={() => {
//                                 console.log("add variants");

//                                 unavailable(ele.id);
//                               }}
//                               > Unavailable</button>

//                               :

//                               <button type="button" class="btn btn-primary "
//                                 onClick={() => {
//                                   console.log("add variants");

//                                   available(ele.id);
//                                 }}>
//                           Available</button>

// }

//                                 </div>

//                               </div>
//                             </div>
//                             <div className="col-md-1 md-ps-0 d-sm-none d-md-block">

//                               {ele.available?
//                               <div className="cursor center my-3"

//                               onClick={() => {
//                                 console.log("add variants");

//                                 unavailable(ele.id);
//                               }}
//                               >
//                                 <MaterialIcons
//                                   name="do-not-disturb"
//                                   size={16}
//                                   color="gray"
//                                 />
//                               </div>
//                               :

//                               <div
//                                 className="cursor center my-3"
//                                 onClick={() => {
//                                   console.log("add variants");

//                                   available(ele.id);
//                                 }}
//                               >
//                                 <MaterialIcons
//                                   name="check-circle"
//                                   size={16}
//                                   color="gray"
//                                 />
//                               </div>

// }


//                               <div className="cursor center my-3"
//                                   onClick={() => {
//                                     SetDeleteModal({
//                                       is_open: true,
//                                      id: ele.id,
//                                     });
//                                   }}
//                               >
//                                 <MaterialIcons
//                                   name="delete-outline"
//                                   size={16}
//                                   color="gray"
//                                 />
//                               </div>

//                               <div
//                                 className="cursor center my-3"
//                                 onClick={() => {
//                                   // console.log("add variants");
//                                   // SetModalVariants({
//                                   //   // prevValue.is_open = true;
//                                   //   is_open: true,
//                                   //   product_name: ele.name,
//                                   //   product_id: ele.id,
//                                   //   product_checked: ele.checked,

//                                   //   data: ele.variants,
//                                   //   type: "edit",

//                                   //   // return { ...prevValue }
//                                   // });

//                                   console.log("SetEditUserModal :");

//                                   SetEditUserModal((prevValue: any) => {
//                                     prevValue.is_open = true;
//                                     prevValue.type = "edit";
//                                     prevValue.data = ele.additional_details;
//                                     prevValue.units = units;
//                                     prevValue.id = ele.id;




//                                     return { ...prevValue };
//                                   });
//                                   console.log("Edit");
//                                 }}
//                               >
//                                 <Feather name="edit-3" size={16} color="gray" />
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                   </div>

//                   <div className="cursor center my-3">
//                     <MaterialIcons
//                       name="delete-outline"
//                       size={16}
//                       color="gray"
//                     />
//                   </div>

//                   <div
//                     className="cursor center my-3"
//                     onClick={() => {
//                       // console.log("add variants");
//                       // SetModalVariants((prevValue: any) => {
//                       //   prevValue.is_open = true;
//                       //   return { ...prevValue };
//                       // });

//                     }}
//                   >
//                     <Feather name="edit-3" size={16} color="gray" />
//                   </div>
//                 </>
//               ) : //         </div>
//               //       </div>
//               //     </div>
//               //   </div>
//               // </div>
//               null}
//             </div>
//           </div>

//           {next_page ? (
//           <p
//             className="text-blue text-center my-2 cursor"
//             onClick={() => {
//               load_more();
//             }}
//           >
//             Load More
//           </p>
//         ) : null}
//         </div>
//       </div>

//       {/* PR */}

//       {/* <ModalVariants
//                 open={modal_variants.is_open}
//                 close={(data: any) => {
//                     console.log("Variants modl on close :", data)
//                     SetModalVariants((prevValue: any) => {
//                         prevValue.is_open = false;
//                         return { ...prevValue }
//                     })
//                 }}
//             /> */}
//       {["left", "right", "top", "bottom"].map((anchor) => (
//         <React.Fragment key={anchor}>
//           <SwipeableDrawer
//             anchor={anchor}
//             open={state[anchor]}
//             onClose={(event) => {
//               sample_fun(anchor, false, event);
//             }}
//             onOpen={(event) => {
//               sample_fun(anchor, true, event);
//             }}
//           >
//             <div className="mt-3">
//               <div>
//                 <div className="d-flex justify-content-center">
//                   <div className="w-25 px-4">
//                     <hr className="h-3" />
//                   </div>
//                 </div>
//                 <ul>
//                   <li
//                     className="p-2 pb-3 cursor align-center"
//                     onClick={(event) => {
//                       console.log("Inactive");
//                       sample_fun(anchor, false, event);
//                     }}
//                   >
//                     <MaterialIcons
//                       name="do-not-disturb"
//                       size={16}
//                       color="gray"
//                     />
//                     &nbsp; Inactive
//                   </li>
//                   {/* Monthly Salary */}

//                   <li
//                     className="p-2 pb-3 cursor align-center text-dark"
//                     onClick={(event) => {
//                       console.log("Remove");
//                       sample_fun(anchor, false, event);
//                     }}
//                   >
//                     <MaterialIcons
//                       name="delete-outline"
//                       size={16}
//                       color="gray"
//                     />
//                     &nbsp; Remove
//                   </li>
//                   {/* Work Salary */}
//                   <li
//                     className="p-2 pb-3 cursor align-center text-dark"
//                     onClick={(event) => {
//                       let set_data = {
//                         // value: ele,
//                         // index: ele.i,
//                       };
//                       console.log("SetEditUserModal :");

//                       SetEditUserModal((prevValue: any) => {
//                         prevValue.is_open = true;
//                         prevValue.type = "edit";
//                         prevValue.data = set_data;
//                         return { ...prevValue };
//                       });
//                       console.log("Edit");
//                       // sample_fun(anchor, false, event);
//                     }}
//                   >
//                     <Feather name="edit-3" size={16} color="gray" />
//                     &nbsp; Edit
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </SwipeableDrawer>
//         </React.Fragment>
//       ))}

//       <ModalEditAdditional
//         open={edit_user_modal.is_open}
//         data={edit_user_modal.data}
//         units={edit_user_modal.units}
//         id={edit_user_modal.id}


//         type={edit_user_modal.type}
//         index={edit_user_modal.index}
//         close={(data: any) => {
//           console.log("Edit Outlet Modal On Close :", data);
//           SetEditUserModal({
//             is_open: false,
//             data: {},
//             units: {},
//             id:"",
//             type: "",
//             index: "",
//           });
//           if (data.action == "edit") {
//             get_products();
//         }}}
//       />

// <ModalDeleteVarient
//         open={deleteModal.is_open}
//         id={deleteModal.id}
//         close={(data: any) => {
//           // console.log("Franchise modl on close :", data);
//           SetDeleteModal((prevValue: any) => {
//             prevValue.is_open = false;
//             return { ...prevValue };
//           });
//           if (data.action == "save") {
//             get_products(1, count, min_max_value, type);
//           }
//         }}
//       />
//     </div>
//   );
//     }


import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import { Feather, FontAwesome, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { AppHelper } from "../../../utils/Helper";
// import ModalEditAdditional from "./ModalEditAdditional";

import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import toast from "react-hot-toast";
import { AppConfig } from "../../../config";
// import ModalDeleteVarient from "./ModalDeleteVarient";


function valuetext(value) {
  return `${value}°C`;
}

export default function Products(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });
  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    product_name: "",
    product_id: "",
    product_checked: "",
    unavailable: false,
    data: {},
    type: "",
  });
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [edit_user_modal, SetEditUserModal] = useState({
    is_open: false,
    data: {},
    units: {},
    id: "",
    index: "",
    type: "",
  });
  const [text, setText] = useState("");

  const [id_value, SerIdValue] = useState(useParams());
  const [type, setType] = useState("");

  const [grid_view, SetGridView] = useState(false);
  const [list_view, SetListView] = useState(true);
  const [add_product, SetAddProduct] = useState(true);
  const [filter, SetFilter] = useState([]);
  const [request_product, SetRequestProduct] = useState(false);
  const [width, SetWidth] = React.useState(window.innerWidth);
  const [product_data, SetProductData] = useState([]);
  const [option, SetOption] = useState({});
  const [units, SetUnits] = useState([]);
  const [collection_data, SetCollectionData] = useState([]);



  const [data, SetData] = useState([]);
  const [brand, SetBrand] = useState([]);
  const [category, SetCategory] = useState([]);
  const [sort, SetSort] = useState("");
  const [f_products, SetFproducts] = useState({
    own_products: false,
    others_products: false,
  });
  const [next_page, SetNextPage] = useState(false);
  const [min_max_value, SetMinMaxValue] = React.useState(["", ""]);
  const [count, SetCount] = useState(10);
  const [page_no, SetPageNo] = useState(1);
  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const PRICE_WAIT_INTERVAL = 2000;

  const [deleteModal, SetDeleteModal] = useState({
    is_open: false,
    id: "",
  });

  async function get_products(
    page_value: any,
    search_value: any,

  ) {

    SetPageNo(page_value);

    let pass_data = {};

    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,

          search: search_value,
          collection_id: id_value.collect_id,

        },
      }
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,

          collection_id: id_value.collect_id,
        },
      }
    }
    let response: any = await api("/brand/collection_products", pass_data);
    console.log("/brand/collection_products:", response);
    if (response.status_code == 200) {
      SetData(response.response);

      if (response.response.hasOwnProperty("data")) {
        SetProductData(response.response.data);

        // SetFilter(response.response.filter);
        SetNextPage(response.response.next_page);
      } else {
        SetProductData([]);
      }

      // SetUnits(response.response.options.units);
      // SetOption(response.response.filter);
      // console.log("response.response.options :", response.response.filter);

      //   if(response.response.hasOwnProperty("options")){
      //     SetOption(response.response.options);
      //   }
    }
  }




  useEffect(() => {
    console.log("Franchise On mount :", props);
    console.log("Franchise context :", context);
    get_products(page_no, text);
  }, []);

  function sample_fun(anchor, open, event) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  }



  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          collection_id: id_value.collect_id,


        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          collection_id: id_value.collect_id,



        },
      };
    }
    let response: any = await api("/brand/collection_products", pass_data);
    console.log("/brand/collection_products", response);
    SetProductData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  async function Pause(e) {
    let pass_data = {};
    let arr = [];
    arr.push(e);
    pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        products: arr,
      }
    };
    let response: any = await api("/brand/remove_collection_products_v2", pass_data);
    console.log("/brand/add_brand_product_list :", response);

    if (response.status_code == 200) {
      get_products(1, text);
      toast.success(
        "Product is Paused"
      );
      // SetProductData((prevValue) => {
      //   response.response.data.map((item) => {
      //     prevValue.push(item);
      //   });
      //   return [...prevValue];
      // });
      // SetNextPage(response.response.next_page);
    }
  }

  async function Enable_product(e) {

    let pass_data = {};
    let arr = [];
    arr.push(e);
    pass_data = {
      get: {
        brand: id_value.brand_id,
        collection_id: id_value.collect_id,
      },
      post: {
        products: arr,
      }
    };

    let response: any = await api("/brand/add_collection_products_v2", pass_data);
    console.log("/brand/add_brand_variant :", response);


    if (response.status_code == 200) {
      get_products(1, text);
      toast.success(
        "Product is Enabled"
      );
      // SetProductData((prevValue) => {
      //   response.response.data.map((item) => {
      //     prevValue.push(item);
      //   });
      //   return [...prevValue];
      // });
      // SetNextPage(response.response.next_page);
    }
  }

  async function Enable_variant(e) {

    let pass_data = {};
    let arr = [];


    arr.push(e);

    pass_data = {
      get: {
        brand: id_value.brand_id,
        collection_id: id_value.collect_id,
      },
      post: {
        variants: arr,
      }
    };
    let response: any = await api("/brand/add_collection_products_v2", pass_data);
    console.log("/brand/add_brand_variant :", response);

    if (response.status_code == 200) {
      get_products(1, text);
      toast.success(
        "Product is Enabled"
      );
      // SetProductData((prevValue) => {
      //   response.response.data.map((item) => {
      //     prevValue.push(item);
      //   });
      //   return [...prevValue];
      // });
      // SetNextPage(response.response.next_page);
    }
  }

  function onTextChanged(e: any) {
    clearTimeout(timer)
    const search_text = e.target.value
    setText(search_text)
    console.log('Text onchange: ', search_text)
    if (search_text == '') {
      let page_no_value = 1
      SetPageNo(page_no_value)
      get_products(page_no_value, search_text)
    } else {
      SetTimer(
        setTimeout(() => {
          get_products(1, search_text)
        }, WAIT_INTERVAL),
      )
    }
  }



  return (
    <div className="">
      <div className={OutletContext ? "head-bar sticky-sm" : "head-bar  sticky-sm "}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="row">
          <div className="col-12 col-md-4 sm-mb-2">
            <div className="d-flex align-items-center">
              <div className="px-1 cursor" onClick={() => {
                window.history.back(1);
              }}>
                {/* <Link to={"/brand/" + id_value.brand_id + "/collection_products/" + id_value.collect_id}> */}
                <img
                src={
                  AppConfig.CDN_Media_url +
                  "5f1c84a5b1601dde2c5c53b5fab5c150.svg"
                }
                className="type-img-big"
              />
                {/* <MaterialCommunityIcons name="arrow-left" size={20} color="black" /> */}
                {/* </Link> */}
              </div>
              <div>
              <h6 className="mb-0">Collection Products</h6>
            <div className="ps-1">{data?.collection?.name}</div>


              </div>
          


            </div>

          </div>
          <div className="col-12 col-md-4 sm-mb-2">
            <div className="row">
              <div className="input-icons form-group">

                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search products"
                  defaultValue={text}
                  onChange={(e) => {
                    onTextChanged(e)
                  }}
                />
                <div className="cursor ps-2 border-left">
                <img
                    src={
                      AppConfig.CDN_Media_url +
                      "c241785c497fb98f5f7d4e587b3857ba.svg"
                    }
                    className="w-20px"
                  />
                  {/* <Ionicons name="md-search" size={18} color="#663fc3" /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 d-flex align-items-center ms-auto">
            {/* <Link to={"/brand/" + id_value.brand_id + "/addproducts_new"}> */}
            <div className="ms-auto">
              <Link to={"/brand/" + id_value.brand_id + "/add_collection_products/" + id_value.collect_id}>
                <button className="btn btn-custom-outline px-3 btn-sm me-2 ">Add Products</button>
              </Link>
            </div>

            {/* <Link to={"/brand/" + id_value.brand_id + "/addproducts_new"}> */}
            <div >
              <Link to={"/brand/" + id_value.brand_id + "/add_collection_product_group/" + id_value.collect_id}>

                <button className="btn btn-custom-outline px-3 btn-sm">Add Product Group</button>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div className={OutletContext ? "mt-3 px-md-2 px-sm-0" : "mt-3 px-md-2 px-sm-0"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="mb-5">
          <div className="row">


            <div className="col-md-12  px-sm-0 px-md-2">
              <div className="">
                {add_product ? (
                  <div>
                     {product_data.length > 0 ? 
                    <div className="row">
                      {product_data.map((ele: any) => (
                        <div className="col-md-6">
                          <div
                            className="card p-3 mb-2"
                            onClick={(event) => {
                              if (width < 768) {
                                console.log("width", width);
                                // SetSelectedData({
                                //     value: data,
                                //     i: u_index,
                                //     type: "active",
                                //   });
                                sample_fun("bottom", true, event);
                              }
                            }}
                          >
                            <label className="form-label row mb-0 cursor">
                              <div className="col-1 p-0 text-end">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={ele.checked}
                                  value={ele.checked ? ele.cpid : ele.id}
                                  // disabled={unavailablity_check}
                                  onChange={(e) => {
                                    ele.checked ?
                                      (
                                        Pause(ele.id)
                                      ) : (
                                        ele.variant_id ?
                                          Enable_variant(ele.variant_id)
                                          :
                                          Enable_product(ele.product_id)
                                      )
                                  }}
                                  id="switch"
                                />

                              </div>

                              <div className="col-11 d-flex align-items-center">
                                <h6 className="mb-2">{ele.name}</h6>
                                <div className="ms-auto">
                                  {ele.available ?
                                    (
                                      <img src={AppConfig.CDN_Media_url+"e29f3e93fc7d72c80c0a2241e8b6066a.svg"} className="w-14px" />

                                      // <FontAwesome
                                      //   name="circle"
                                      //   size={14}
                                      //   color="#18b901"
                                      // />
                                    ) : (
                                      <img src={AppConfig.CDN_Media_url+"a073b0130ced7a6d7a5411834ca46bf9.svg"} className="w-14px" />
                                      // <FontAwesome
                                      //   name="circle"
                                      //   size={14}
                                      //   color="#ccc"
                                      // />
                                    )}
                                </div>
                              </div>
                            </label>

                            <div className="row">
                              <div className="col-3">
                                {ele.images ? (
                                  <img
                                  src={AppHelper.imageSrc(
                                    data.image_base_url,
                                    ele.images[0]?.url,
                                    { format: "webp", width: 100 }
                                  )}
                                    className="prod-group-img"
                                  />
                                ) : (
                                  <img
                                    src={AppConfig.CDN_Image_url+"33c67a26ab58ada3ee31b91f7efb32bb.png"}
                                    alt="pic"
                                    className="prod-group-img"
                                  />
                                )}
                              </div>
                              <div className="col-9 p-0">
                                <p className="disc-overflow text-gray small mb-2">
                                  {ele.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                      :
                      <div className="text-center">
                       <img
                    src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img pb-2"
                  />
                      <p className="v-small mb-0">No Data, Please Add Products And Product Group</p>
                      <Link to={"/brand/" + id_value.brand_id + "/add_collection_products/" + id_value.collect_id}>
                <button className="btn btn-custom-outline px-3 btn-sm me-2 ">Add Products</button>
              </Link>

              <Link to={"/brand/" + id_value.brand_id + "/add_collection_product_group/" + id_value.collect_id}>

<button className="btn btn-custom-outline px-3 btn-sm">Add Product Group</button>
</Link>
                    </div>
          
          
                        }

                    {/* <div className="cursor center my-3">
                    <MaterialIcons
                      name="delete-outline"
                      size={16}
                      color="gray"
                    />
                  </div>

                  <div
                    className="cursor center my-3"
                    onClick={() => {
                      // console.log("add variants");
                      // SetModalVariants((prevValue: any) => {
                      //   prevValue.is_open = true;
                      //   return { ...prevValue };
                      // });
                    
                    }}
                  >
                    <Feather name="edit-3" size={16} color="gray" />
                  </div> */}
                  </div>
                ) : null}
              </div>
            </div>

            {next_page ? (
              <p
                className="text-blue text-center my-2 cursor"
                onClick={() => {
                  load_more();
                }}
              >
                Load More
              </p>
            ) : null}
          </div>
        </div>
      </div>

      {/* PR */}

      {/* <ModalVariants
                open={modal_variants.is_open}
                close={(data: any) => {
                    console.log("Variants modl on close :", data)
                    SetModalVariants((prevValue: any) => {
                        prevValue.is_open = false;
                        return { ...prevValue }
                    })
                }}
            /> */}
      {/* {["left", "right", "top", "bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={(event) => {
              sample_fun(anchor, false, event);
            }}
            onOpen={(event) => {
              sample_fun(anchor, true, event);
            }}
          >
            <div className="mt-3">
              <div>
                <div className="d-flex justify-content-center">
                  <div className="w-25 px-4">
                    <hr className="h-3" />
                  </div>
                </div>
                <ul>
                  <li
                    className="p-2 pb-3 cursor align-center"
                    onClick={(event) => {
                      console.log("Inactive");
                      sample_fun(anchor, false, event);
                    }}
                  >
                    <MaterialIcons
                      name="do-not-disturb"
                      size={16}
                      color="gray"
                    />
                    &nbsp; Inactive
                  </li>

                  <li
                    className="p-2 pb-3 cursor align-center text-dark"
                    onClick={(event) => {
                      console.log("Remove");
                      sample_fun(anchor, false, event);
                    }}
                  >
                    <MaterialIcons
                      name="delete-outline"
                      size={16}
                      color="gray"
                    />
                    &nbsp; Remove
                  </li>
                  <li
                    className="p-2 pb-3 cursor align-center text-dark"
                    onClick={(event) => {
                      let set_data = {
                        // value: ele,
                        // index: ele.i,
                      };
                      console.log("SetEditUserModal :");

                      SetEditUserModal((prevValue: any) => {
                        prevValue.is_open = true;
                        prevValue.type = "edit";
                        prevValue.data = set_data;
                        return { ...prevValue };
                      });
                      console.log("Edit");
                      // sample_fun(anchor, false, event);
                    }}
                  >
                    <Feather name="edit-3" size={16} color="gray" />
                    &nbsp; Edit
                  </li>
                </ul>
              </div>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))} */}

    </div>
  );
}
