import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext
} from "react";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";

import { useParams } from "react-router-dom";
import { api } from "../../../utils/Api";
import makeAnimated from "react-select/animated";
import { mobileApp } from "../../../utils/MobileApp";

// import BottomSheet,{BottomSheetView} from '@gorhom/bottom-sheet'
// import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import { functions } from "lodash";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { AppConfig } from "../../../config";

var ex = <script src="all-js/01001/015.js"></script>

var lat: any, long: any;

var driver_marker: any;
const myLatlng = { lat: 11.3836032, lng: 77.7650176 };

let map: any;

let marker_img_url = "https://cdn3.1superapp.com/superapp/deliveryboy.png";

function initMap(): void {
  const directionsService = new google.maps.DirectionsService();
  const directionsRenderer = new google.maps.DirectionsRenderer();
  map = new google.maps.Map(
    document.getElementById("map-test") as HTMLElement,
    {
      zoom: 12,
      center: myLatlng,
      mapTypeControl: false,
      fullscreenControl: false,
      zoomControl: false,
      streetViewControl: false,
      mapTypeId: "roadmap",
    }
  );

  directionsRenderer.setMap(map);

  map.addListener("center_changed", () => {
    lat = map.getCenter()?.lat();
    long = map.getCenter()?.lng();

    console.log(lat);
    console.log(long);
  });

  // Create the search box and link it to the UI element.
  const button = document.getElementById("pac-input") as HTMLInputElement;

  // Create the search box and link it to the UI element.
  const input = document.getElementById("pac-input") as HTMLInputElement;
  const options = {
    fields: ["formatted_address", "geometry", "name"],
    strictBounds: false,
    types: ["establishment"],
  };
  const autocomplete = new google.maps.places.Autocomplete(input, options);
  driver_marker = new google.maps.Marker({
    position: null,
    map: map,
    icon: {
      url: marker_img_url,
      scaledSize: new google.maps.Size(23, 35),
    },
  });

  autocomplete.bindTo("bounds", map);
  console.log("hihjjjsk");
  map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

  autocomplete.addListener("place_changed", () => {
    // infowindow.close();
    // marker.setVisible(true);

    const place = autocomplete.getPlace();

    if (!place.geometry || !place.geometry.location) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    // If the place has a geometry, then present it on a map.
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    // marker.setPosition(place.geometry.location);
    // marker.setVisible(true);

    // infowindowContent.children["place-name"].textContent = place.name;
    // infowindowContent.children["place-address"].textContent =
    //   place.formatted_address;
    // infowindow.open(map, marker);
  });

  const tempArray = [{ lat: 11.341, lng: 77.7172 }];
}

const outlet_initial_value = {
  channel_id: "",
};

let res_id: any;
let driver_id: any;

export default function ModalLocationLive(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  let myModal: any;

  console.log(props);
  const [id_value, SerIdValue] = useState(useParams());
  const [outlet_data, SetOutletData] = useState(outlet_initial_value);
  const [open, setOpen] = useState(false);
  const [drive_org, setDriveOrg] = useState(false);

  const [type, setType] = useState("roadmap");
  const [del_lat, setDelLat] = useState("");
  const [del_lng, setDelLng] = useState("");
  const [page, setPage] = useState("");
  const [oldlatlng, SetOldLatLng] = useState([]);

  const [current_location, setCurrentLocation] = useState([]);

  // declare global {
  //   interface Window {
  //     initMap: () => void;
  //   }
  // }
  // window.initMap = initMap;

  useEffect(() => {
    if (props.open) {
      // const directionsService = new google.maps.DirectionsService();
      // const directionsRenderer = new google.maps.DirectionsRenderer();
      // calculateAndDisplayRoute(directionsService, directionsRenderer);

      // function getLocation() {

      console.log("ModelDeleteUserRoles On props.open :", props);

      myModal = new bootstrap.Modal(document.getElementById("DelLoc"), {});

      var importedScript = document.createElement("script");
      importedScript.src =
        "https://maps.googleapis.com/maps/api/js?key="+AppConfig.google_map_api+"&callback=initMap&v=weekly&libraries=places";
      document.head.appendChild(importedScript);

      window.initMap = initMap;
      setDelLat(props.lat);
      setDelLng(props.lon);

      // map.setMapTypeId("terrain");
      //   new google.maps.Marker({
      //     position: { lat:parseFloat(props.lat),lng:parseFloat(data.response.data[0]..lng) },
      //     map: map,
      //     icon: "https://img.icons8.com/external-flaticons-flat-flat-icons/33/external-customer-internet-marketing-service-flaticons-flat-flat-icons-3.png",
      //     // title: title
      // });
      live_track(props.id, props.lat, props.lon, "", true);

      res_id = setInterval(function () {
        live_track(props.id, props.lat, props.lon, page);
      }, 10000);

      console.log(res_id);

      driver_id = setInterval(function () {
        driver_move(props.lat, props.lon);
      }, 100);

      console.log(res_id);

      console.log(map);
      myModal.show();
    }
  }, [props.open]);

  useEffect(() => {
    console.log("ModelDeleteUserRoles On mount :", props);
    let myModal1 = document.getElementById("DelLoc");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      console.log("close modal :");
      // alert("Never Gonna Give You Up");
      // console.log(res_id);
      // setTimeout(function() {
      clearInterval(res_id);
      // }, 5000);

      // setTimeout(function() {
      clearInterval(driver_id);
      // }, 5000);

      props.close(close_data);
    });
  }, []);

  async function live_track(id, lat, lng, page, first_call = false) {
    console.log(page);
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        delivery_id: id,
        page: page,
      },
    };
    let data:any = await api("/outlet/live_tracking", pass_data);

    console.log("brand_users response :", data);
    if (data.status_code == 200) {
      console.log(lat);
      // console.log(data.response.location[0].pageno);

      // setPage(data.response.location[0].pageno);
      // console.log(data.response.location[data.response.location?.length -1].pageno)
      setPage(data.response?.location[data.response.location?.length - 1].pageno);
      for (var i = 0; i < data.response.location?.length; i++) {
        current_location.push(data.response.location[i]);
      }

      console.log(current_location);
      setCurrentLocation(current_location);

      // new google.maps.Marker({
      //     position: {  lat: parseFloat(data.response.location[0].lat ),lng : parseFloat(data.response.location[0].lng), },
      //     map: map,
      //     icon: "https://img.icons8.com/external-fauzidea-outline-color-fauzidea/33/external-bike-e-commerce-fauzidea-outline-color-fauzidea.png",
      //     // title: title
      // });

      // new google.maps.Marker({
      //     position: {   lat:parseFloat(data.response.data[0].outlet.lat), lng:parseFloat(data.response.data[0].outlet.lng) },
      //     map: map,
      //     icon: "https://img.icons8.com/fluency/33/restaurant-.png",
      //     // title: title
      // });
      if (first_call) {
        showDirections(
          { lat: parseFloat(data.response.location[0].lat), lng: parseFloat(data.response.location[0].lng) },
          { lat: parseFloat(lat), lng: parseFloat(lng) }
        );
       
        driver_move(props.lat, props.lon);
      }

      new google.maps.Marker({
        position: { lat: parseFloat(lat), lng: parseFloat(lng) },
        map: map,
        icon: {
          url: "https://cdn3.1superapp.com/superapp/markerpinup.png",
          scaledSize: new google.maps.Size(23, 35),
        },
        // title: title
      });

      // setInterval(function() {
      //   live_track(id,lat,lng,page +10);
      // }, 10000);
    }
  }

  function driver_move(lat, lon) {





    ///tr76yt9ouy0iuyhi
    // const start = new google.maps.LatLng(
    //   parseFloat(oldlatlng[oldlatlng.length - 1]?.lat),
    //   parseFloat(oldlatlng[oldlatlng.length - 1]?.lng)
    // );
    // const end = new google.maps.LatLng(
    //   parseFloat(current_location[0]?.lat),
    //   parseFloat(current_location[0]?.lng)
    // );

    // const heading = google.maps.geometry.spherical.computeHeading(start, end);


    // console.log(heading);
    console.log( current_location[0]?.heading);

    const heading_api = current_location[0]?.heading;

    let deg = parseInt(heading_api); // based on api heading value
    let doc_var: any = document.querySelector(
      'img[src="' + marker_img_url + '"]'
    );
    if (doc_var != null) {
      doc_var.style.transform = "rotate(" + deg + "deg)";
      doc_var.parentElement.style.overflow = "inherit";
    }



    // var car = "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
    // var icon = {
    //   path: car,
    //   scale: .7,
    //   strokeColor: 'white',
    //   strokeWeight: .10,
    //   fillOpacity: 1,
    //   fillColor: '#404040',
    //   offset: '5%',
    //   // rotation: parseInt(heading[i]),
    //   rotation: parseInt(heading_api),
    //   anchor: new google.maps.Point(10, 25) // orig 10,50 back of car, 10,0 front of car, 10,25 center of car
    // };

    

    // driver_marker.setIcon(icon);
    // var car =
    //   "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";

    // //   var arrow = 
    // //   "M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"

    //   // var bikePlain = `M1759 8090 c-88 -16 -173 -97 -199 -190 -5 -19 -10 -85 -10 -146 l0
    //   // -111 -162 -87 c-280 -151 -332 -191 -380 -292 -36 -78 -80 -269 -81 -356 l-1
    //   // -68 -57 0 c-75 0 -154 -16 -207 -41 -24 -12 -95 -71 -158 -132 -109 -104 -115
    //   // -112 -108 -141 3 -17 10 -36 15 -42 15 -19 137 -38 239 -38 84 0 101 3 147 27
    //   // 28 14 79 49 113 78 l61 51 -3 -58 -3 -58 -216 -71 c-120 -38 -226 -79 -238
    //   // -89 -25 -23 -28 -72 -5 -105 15 -21 15 -23 -5 -37 -12 -9 -31 -34 -44 -57 -22
    //   // -42 -22 -44 -25 -611 -3 -607 0 -647 47 -690 16 -14 18 -27 13 -82 -8 -105 -3
    //   // -112 308 -399 l277 -256 5 -102 c6 -115 28 -187 78 -248 16 -21 30 -41 30 -44
    //   // 0 -4 -12 -22 -26 -40 -14 -19 -38 -69 -52 -112 -24 -72 -26 -91 -26 -248 0
    //   // -144 3 -182 22 -248 23 -85 81 -211 136 -297 l35 -55 -39 -315 c-30 -241 -37
    //   // -323 -30 -351 40 -147 216 -198 328 -95 34 31 51 68 65 143 l12 66 80 -97 c44
    //   // -54 81 -93 83 -87 7 21 174 178 177 167 2 -6 6 -32 9 -59 13 -109 82 -178 185
    //   // -185 89 -6 161 38 196 120 21 51 20 53 -21 395 l-37 302 41 63 c64 99 102 183
    //   // 129 287 59 228 32 493 -63 617 l-35 45 31 39 c59 75 74 123 78 256 l4 120 263
    //   // 243 c296 272 302 281 292 390 -4 50 -2 62 14 77 47 43 50 83 47 691 l-3 570
    //   // -32 51 -32 52 24 25 c27 27 31 51 13 89 -13 30 -38 40 -278 116 -91 28 -168
    //   // 55 -171 58 -4 4 -9 33 -13 66 l-6 58 60 -54 c120 -109 211 -133 391 -103 57 9
    //   // 107 20 112 24 4 4 12 23 17 41 10 34 9 35 -98 141 -142 139 -198 168 -339 176
    //   // l-98 6 3 56 c4 68 -28 234 -65 333 -44 120 -109 172 -408 330 l-115 61 0 121
    //   // c0 67 -5 138 -10 158 -35 123 -163 209 -281 188z m81 -946 c234 -9 426 -17
    //   // 427 -18 1 -1 17 -54 37 -118 33 -110 66 -282 55 -292 -2 -3 -43 6 -89 20 -126
    //   // 36 -301 71 -425 85 -203 21 -412 -11 -617 -96 -15 -6 -18 -1 -18 31 0 55 24
    //   // 160 65 292 l36 112 52 0 c29 0 243 -7 477 -16z m-878 -855 c2 -17 20 -28 71
    //   // -48 52 -20 73 -34 92 -63 l25 -37 -22 -3 c-25 -4 -48 -64 -105 -278 l-37 -135
    //   // -3 -375 -4 -375 109 -162 c80 -120 111 -160 120 -151 14 14 70 -72 77 -121 3
    //   // -20 8 -49 10 -64 3 -16 1 -27 -4 -25 -5 2 -119 104 -253 227 l-243 224 -5 602
    //   // -5 601 -24 36 c-14 19 -37 44 -53 54 -15 10 -28 21 -28 24 0 5 246 87 267 89
    //   // 6 1 13 -9 15 -20z m1783 -18 c108 -34 118 -39 95 -49 -33 -13 -86 -70 -99
    //   // -105 -7 -19 -11 -227 -11 -615 l-1 -587 -226 -208 c-124 -114 -228 -206 -230
    //   // -204 -1 2 3 25 11 51 18 58 65 124 76 106 5 -8 47 47 120 155 l112 168 -5 361
    //   // c-5 320 -7 371 -26 451 -29 121 -99 343 -110 347 -5 2 -11 16 -13 32 -3 29 0
    //   // 31 82 62 66 25 86 36 88 53 2 11 6 21 10 21 4 0 61 -17 127 -39z m-834 -776
    //   // c143 -51 270 -217 313 -412 50 -226 -11 -484 -152 -634 -231 -247 -560 -140
    //   // -689 226 -26 75 -28 90 -28 240 0 150 2 165 28 240 98 276 314 415 528 340z
    //   // m-513 -2984 c29 -47 60 -110 69 -141 21 -73 38 -106 83 -160 l37 -45 -9 -65
    //   // c-11 -84 -14 -94 -45 -130 -36 -43 -74 -60 -136 -60 -71 0 -127 38 -152 104
    //   // -17 44 -17 52 14 303 17 142 34 276 37 297 l6 40 22 -28 c11 -16 45 -67 74
    //   // -115z m931 -432 c1 -65 -23 -109 -79 -147 -29 -20 -45 -23 -95 -20 -107 7
    //   // -156 64 -169 198 -7 62 -6 67 20 90 41 39 70 90 93 166 11 39 45 108 77 159
    //   // 95 153 84 165 121 -131 18 -143 32 -284 32 -315z`;

    // driver_marker.setIcon({
    //   // anchor: new google.maps.Point(16, 16),
    //   // url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
    //   // rotation:heading,
    //   // path: bikePlain,
    //   path: car,


    //   // url:"https://deliverypartner.1superapp.com/map_images/bike.png",
    //   scale: 0.005,
    //   strokeColor: "white",
    //   strokeWeight: 0.1,
    //   fillOpacity: 1,
    //   fillColor: "#404040",
    //   offset: "5%",
    //   // anchor: new google.maps.Point(20, 20), // for bike
    //   rotation: parseInt(heading_api),
    //   anchor: new google.maps.Point(10, 25), // orig 10,50 back of car, 10,0 front of car, 10,25 center of car
    // });
    // console.log(current_location[0]?.lat)

    const newPosition = new google.maps.LatLng(
      parseFloat(current_location[0]?.lat),
      parseFloat(current_location[0]?.lng)
    );

    console.log(newPosition)

    // Set the marker's position to the new position
    driver_marker.setPosition(newPosition);

    if (current_location.length > 1) {
      let old = current_location.shift();
      // console.log(old);
      // for (let i = 0; i < 2; i++) {
      //   // console.log(oldlatlng[0]);

      //   showDirections(
      //     { lat: parseFloat(old?.lat), lng: parseFloat(old?.lng) },
      //     { lat: parseFloat(lat), lng: parseFloat(lon) }
      //   );
      // }

      oldlatlng.push(old);
      SetOldLatLng(oldlatlng);
      setCurrentLocation(current_location);
      // console.log("Current location: " + current_location)
    }
  }

  function update_type(type: String) {
    close_bottom();

    map.setMapTypeId(type);
    console.log(type);
    // close_bottom();
  }

  function close_bottom() {
    console.log("hi");
    setOpen(false);
  }

  let counter = 0;
  function showDirections(origin, destination) {
    // console.log(origin);
    // console.log(destination);
    if (counter < 5) {
      var directionsService = new google.maps.DirectionsService();
      var directionsRenderer = new google.maps.DirectionsRenderer({
        map: map,
        suppressMarkers: true,
      });

      // Calculate the route between the two locations
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        function (response, status) {
          if (status == "OK") {
            // Display the route on the map
            directionsRenderer.setDirections(response);
            map.setZoom(14);
          } else {
            // Handle errors
            console.error(status);
          }
        }
      );

      // Add the map and the directions display to the page
      directionsRenderer.setMap(map);
      directionsRenderer.setPanel(document.getElementById("directions-panel"));
    }
    counter++;
  }

  function handleClick() {
    clearInterval(res_id);
    clearInterval(driver_id);
  }

  return (
    <div
      className="modal fade"
      id="DelLoc"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >

      {ex}
      {console.log(ex)}

      {console.log(navigator.geolocation)}
      {console.log(type)}
      <div className="modal-dialog modal-fullscreen-sm-down">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="ModalLabel">
              Track Order
            </h6>
            <button
              type="button"
              onClick={() => handleClick()}
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body pt-0
          ">
            {/* <div className="map-search-div d-flex">
              <input
                id="pac-input"
                className="form-control pac-target-input"
                type="text"
                // style={{position: "absolute", left: "188px", top: "0px"}}
                placeholder="Search Box"
              />
            </div>

            <div id="floating-panel">
              <b>Start: </b>
              <select id="start">
                <option value="chicago, il">Chicago</option>
                <option value="st louis, mo">St Louis</option>
                <option value="joplin, mo">Joplin, MO</option>
                <option value="oklahoma city, ok">Oklahoma City</option>
                <option value="amarillo, tx">Amarillo</option>
                <option value="gallup, nm">Gallup, NM</option>
                <option value="flagstaff, az">Flagstaff, AZ</option>
                <option value="winona, az">Winona</option>
                <option value="kingman, az">Kingman</option>
                <option value="barstow, ca">Barstow</option>
                <option value="san bernardino, ca">San Bernardino</option>
                <option value="los angeles, ca">Los Angeles</option>
              </select>
              <b>End: </b>
              <select id="end">
                <option value="delhi, in">Delhi</option>
                <option value="st louis, mo">St Louis</option>
                <option value="joplin, mo">Joplin, MO</option>
                <option value="oklahoma city, ok">Oklahoma City</option>
                <option value="amarillo, tx">Amarillo</option>
                <option value="gallup, nm">Gallup, NM</option>
                <option value="flagstaff, az">Flagstaff, AZ</option>
                <option value="winona, az">Winona</option>
                <option value="kingman, az">Kingman</option>
                <option value="barstow, ca">Barstow</option>
                <option value="san bernardino, ca">San Bernardino</option>
                <option value="los angeles, ca">Los Angeles</option>
              </select>
            </div> */}
            

            <div id="map-test" ></div>

            <div className="map-bottom-div">
              <div className="w-100 ">
                <div className="w-fit ms-auto ">
                  <div
                    className="p-2 bg-fff cursor radius mb-3"
                    onClick={() => (open ? setOpen(false) : setOpen(true))}
                  >
                    <MaterialCommunityIcons
                      name="layers-outline"
                      size={20}
                      color="#000"
                    />
                    {/* <p className="ps-1 text-white fw-bold">Layer</p> */}
                  </div>
                </div>

              </div>
            </div>


            {/* <button
              type="button"
              className="material-icons map-type map-type-picker"
              onClick={() => (open ? setOpen(false) : setOpen(true))}
            >
              layers
            </button>

            <p id="demo"></p> */}
          </div>

          <SwipeableBottomSheet
            overflowHeight={0}
            open={open}
            overlay={true}
            // defaultOpen={false}
            onChange={() => {
              setOpen(false);
            }}
          >
            <div style={{ height: "240px" }}>
              <div className=" drawer-fixed">
                <div className="w-100 center">
                  <hr className="hr-drawer" />
                </div>
              </div>
              <div className="p-3 pt-5">
                <div
                  className="py-3 cursor  "
                  data-type="roadmap"
                  onClick={() => update_type("roadmap")}
                >
                 <p className="text-black"> Road Map</p>
                </div>
                <div
                  className="py-3 cursor  "
                  data-type="hybrid"
                  onClick={() => update_type("hybrid")}
                  id="satellite"
                >
                   <p className="text-black">Satellite</p>
                </div>

                <div
                  className="py-3 cursor  "
                  data-type="terrain"
                  onClick={() => update_type("terrain")}
                >
                   <p className="text-black">Terrain</p>
                </div>
              </div>
            </div>
          </SwipeableBottomSheet>
          {/* <button >Open </button> */}
          {/* <BottomSheet open={open}>My awesome content here</BottomSheet> */}

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-secondary btn-radius btn-sm px-4 btn-sm me-2"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn bg-prime btn-radius btn-sm px-4 text-white "
              name="channel_id"
              value={props.id}

            // onClick={(e) => {
            //                  deleteUserRole(e.target.name,e.target.value)

            //                 }}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
