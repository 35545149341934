import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import { AppHelper } from "../../../utils/Helper";
import { useNavigate } from 'react-router-dom';

import {
  Feather,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import ModalVariants from "../../Brands/Products/ModalVariants";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";

export default function OutletProducts(props: any) {
  const context:any = useContext(DataContext);
  const [text, setText] = useState("");

  const OutletContext = useOutletContext();
  const [grid_view, SetGridView] = useState(false);
  const [search, SetSearch] = useState("");

  const [list_view, SetListView] = useState(true);
  const [id_value, SerIdValue] = useState(useParams());
  const [next_page, SetNextPage] = useState(false);
  const [page_no, SetPageNo] = useState(1);
  const [width, SetWidth] = useState(innerWidth);
  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const PRICE_WAIT_INTERVAL = 2000;

  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    product_name: "",
    product_id: "",
    product_checked: "",
    data: {},
  });

  const [product_data, SetProductData] = useState([]);
  const [data, SetData] = useState([]);
  const [is_loading,SetIsLoading] = useState(false);

  async function get_products(search_value: any) {
    console.log(id_value);
    SetIsLoading(true);
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        page: 1,
        // search: search_value,
      },
    };
    SetSearch((s_prevalue) => {
      pass_data.get.search = s_prevalue;
      return s_prevalue;
    });

    let response: any = await api("/outlet/get_products", pass_data);
    console.log("/brand/brand_products :", response.response.data);
    if (response.status_code == 200) {
      SetIsLoading(false);
      SetData(response.response);

      if (response.response.hasOwnProperty("data")) {
        SetProductData(response.response.data);
        SetNextPage(response.response.next_page);
      } else {
        SetProductData([]);
      }
    }
  }
  const navigate = useNavigate();

  useEffect(() => {
   if(context.app_data.pages.hasOwnProperty('18')){
    if(context.app_data.pages[18]){
    get_products(text);
    }else{
      console.log("hi")
      navigate("/no_access");

    }
  }else{
    SetIsLoading(true)
  }


  }, [context.app_data.pages?.[18]]);

  async function load_more() {
    let page_no_value = page_no + 1;
    SetPageNo(page_no_value);
    let pass_data : any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        page: page_no_value,
        // search: search,
      },
    };
    SetSearch((s_prevalue) => {
      pass_data.get.search = s_prevalue;
      return s_prevalue;
    });
    let response: any = await api("/outlet/get_products", pass_data);
    console.log("/brand/brand_products :", response.response.data);
    console.log("/brand/brand_products :", response.response);

    if (response.status_code == 200) {
      SetData(response.response);

      if (response.response.hasOwnProperty("data")) {
        SetProductData((prevValue) => {
          response.response.data.map((item) => {
            prevValue.push(item);
          });
          return [...prevValue];
        });
        // SetProductDetails(response.response.product_details);
        SetNextPage(response.response.next_page);
      } else {
        SetProductData([]);
      }
    }
    SetNextPage(response.response.next_page);
  }

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const search_text = e.target.value;
    setText(search_text);
    SetSearch(search_text);
    console.log("Text onchange: ", search_text);
    if (search_text == "") {
      let page_no_value = 1;
      SetPageNo(page_no_value);
      get_products(search_text);
    } else {
      SetTimer(
        setTimeout(() => {
          get_products(search_text);
        }, WAIT_INTERVAL)
      );
    }
  }

  return (
    <div className="">
      <div
        className={OutletContext ? "head-bar sticky-sm" : "head-bar  sticky-sm"}
        style={width < 780 ? { width: width } : {}}
      >
        <div className=" row align-items-center">
          <div className="col-md-7 sm-mb-2 p-0">
            <h6 className="  mb-1">Products</h6>
          </div>
          {/* <div className="col-5 ps-0 d-sm-block d-md-none text-end sm-mb-2">
            <Link
              to={
                "/outlet/" +
                id_value.brand_id +
                "/" +
                id_value.outlet_id +
                "/addproducts"
              }
            >
              <button className="btn btn-custom-outline px-3 btn-sm">Add New</button>
            </Link>
          </div> */}
          <div className="col-md-5 p-0">
            <div className="row px-md-3 md-none">
              <div className="input-icons form-group">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search products"
                  defaultValue={text}
                  onChange={(e) => {
                    onTextChanged(e);
                  }}
                />
                <div className="cursor ps-2 border-left">
                  <Ionicons name="md-search" size={18} color="#170f56" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="my-3 px-sm-0 mb-5 pb-4"
        style={
          width < 786
            ? OutletContext == false
              ? { width: width }
              : {}
            : OutletContext == false
            ? { width: width }
            : {}
        }
      >
        <div className="row ">
          <div className="col-md-6 ">
            {!is_loading ? (
              product_data.length > 0 ? (
                <InfiniteScroll
                  dataLength={product_data.length}
                  next={() => {
                    console.log("hi")
                    let get_data = {
                      load_more: true,

                      page_no: page_no,
                    };
                    // console.log(pass_data)
                    console.log(get_data);

                    // get_products(get_data, count, min_max_value, type, text, sort);
                    load_more();
                  }}
                  hasMore={next_page}
                  loader={
                    <div className="center m-5">
                      <div className="lds-dual-ring"></div>
                      <p className="text-prime small ps-2">Just a second...</p>
                    </div>
                  }
                >
                  {product_data.map((ele: any) => (
                    <div className="">
                      <div
                        className="card p-3 mb-2"
                        // onClick={(event) => {
                        //     if (width < 768) {
                        //         console.log("width", width);
                        //         // SetSelectedData({
                        //         //     value: data,
                        //         //     i: u_index,
                        //         //     type: "active",
                        //         //   });
                        //         sample_fun("bottom", true, event);
                        //     }
                        // }}
                      >
                        {/* <div className="row align-items-center mb-2">
                      <div className="col-10">
                        <h6 className="mb-1">{ele.name}</h6>
                        <small>{ele.category}</small>
                      </div>
                      <div className="col-2 text-end">
                        <FontAwesome name="circle" size={14} color="#18b901" />
                      </div>
                    </div> */}
                        <Link
                          to={
                            "/outlet/" +
                            id_value.brand_id +
                            "/" +
                            id_value.outlet_id +
                            "/product_inventory/" +
                            ele.id
                          }
                        >
                          <div className="row align-items-center">
                            <div className="p-0 col-2">
                              {ele.images ? (
                                <img
                                  src={AppHelper.imageSrc(
                                    data.options.image_base_url,
                                    ele.images[0]?.url,
                                    { format: "webp", width: 100 }
                                  )}
                                  className="prod-outlet-img"
                                />
                              ) : (
                                <img
                                  src={require("../../../assets/img/images.png")}
                                  alt="pic"
                                  className="prod-outlet-img"
                                />
                              )}
                            </div>
                            <div className="col-9 pe-0 ps-2 ">
                              <p className="text-dark small fw-bold  double">
                                {ele.name}
                              </p>
                              {ele.description != null ? (
                                <p className="mt-1 text-lightgray extra-small">
                                  {ele.description}
                                </p>
                              ) : null}
                            </div>
                            <div className="col-1 text-end">
                              <MaterialIcons
                                name="keyboard-arrow-right"
                                size={18}
                                color="#170f56"
                              />
                            </div>
                          </div>
                        </Link>

                        {/* <div className="end mt-2">
                      
                          <div  className=" bg-lightprime border text-prime radius v-small px-3 py-2 fw-bold">
                            View Inventory
                          </div>

                      </div> */}

                        {/* <button>View Inventory</button> */}
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              ) : (
                <div className="card p-3 text-center">
                  <img
                    src={require("../../../assets/icon/empty.png")}
                    className="empty-img pb-2"
                  />
                  <p className="v-small mb-2">No Data Found!</p>
                </div>
              )
            ) : (
              [1, 2, 3, 4, 5, 6].map(() => (
                <div className="card p-3 mb-2">
                  <div className="row align-items-center">
                    <div className="p-0 col-2">
                      <Skeleton width="100%" height={40} />
                    </div>
                    <div className="col-9 pe-0 ps-2 ">
                      <Skeleton height={10} width="100%" />
                      {/* <Skeleton height={10} width={60} /> */}
                      <div className="mt-1">
                        <Skeleton height={8} width={160} />
                      </div>
                    </div>
                    <div className="col-1 text-end">
                      <Skeleton height={18} width={18} />
                    </div>
                  </div>
                </div>
              ))
            )}

            {/* <div className="pb-3 pt-4">
              {next_page ? (
                <p
                  className="text-prime fw-500 text-center cursor"
                  onClick={() => {
                    load_more();
                  }}
                >
                  Load More
                </p>
              ) : null}
            </div> */}
          </div>
        </div>
      </div>
      <ModalVariants
        open={modal_variants.is_open}
        product={modal_variants.product_name}
        id={modal_variants.product_id}
        checked={modal_variants.product_checked}
        data={modal_variants.data}
        close={(data: any) => {
          console.log("Variants modl on close :", data);
          // SetModalVariants((prevValue: any) => {
          //     prevValue.is_open = false;
          //     return { ...prevValue }
          // })
          // SetModalVariants({
          //   is_open: false,
          //   product_name: "",
          //   product_id: "",
          //   product_checked: "",

          //   // return { ...prevValue }
          //   data: {},
          // });

          SetModalVariants((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });

          if (data.action == "create") {
            get_products(text);
          }
        }}
      />
    </div>
  );
}
