import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../utils/Api";
import { AntDesign, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import DataContext from "../../utils/Context/DataContext";
import Footer from "../../components/Footer/Footer";

const animatedComponents = makeAnimated();
const initial_value: any = {
  is_org: false,
  selected_org: {},
  brand_name: "",
};

export default function CreateBrand(props: any) {
  const context = useContext(DataContext);
  const [selected_type, SetSelectedType]: any = useState(null);
  const [save_data, SetSaveData] = useState(initial_value);
  const [organization_data, SetOrganizationData] = useState([]);
  const navigate = useNavigate();
  const [file, SetFile] = React.useState([]);

  useEffect(() => {
    console.log("CreateBrand On mount :", props);
    console.log("Header context :", context);
    get_init();
  }, []);

  async function get_init() {
    let response = await api("/account/signup_init");
    console.log("signup_init :", response);
    SetOrganizationData(response.response.organization);
  }

  async function create_brand() {
    console.log("Create Brand Save Data :", save_data);
    let post_value: any = {
      name: save_data.brand_name,
    };
    if (save_data.is_org) {
      post_value.organization = save_data.selected_org.value;
    }
    let pass_data = {
      post: post_value,
    };
    console.log("pass_data :", pass_data);
    let response: any = await api("/account/signup", pass_data);
    console.log("/account/signup post response :", response);
    if (response.status_code == 201) {
      let view_data_value = {
        name: save_data.brand_name,
        brand_name: "",
        type: "brand",
      };
      localStorage.setItem("view_data", JSON.stringify(view_data_value));
      SetSaveData(initial_value);
      navigate("/brand/" + response.response.id);
    }
  }

  function upload(event) {
    let images = [];

    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]))
    }
    SetFile(images);
    console.log("Previews :", images);
  }

  return (
    <div className="page-main">
      <div>
        {/* navbar-custom */}
        <nav className="navbar navbar-expand-lg navbar fixed-top navbar-light navbar-custom-new">
          <div className="row w-100 align-items-center">
            <div className="col-md-10 col-10">
              <div className="d-flex align-items-center py-2 px-1">
                <img src={require("../../assets/img/shopping-cart.png")} className="logo-img" />
                <h6 className="mb-0 ps-1">
                  SELLERS
                </h6>
              </div>
            </div>
            <div className="col-md-2 col-2 pe-0 ">
              <div className="sidebar-options dropdown text-right">
                <Link to="/" className="p-0 mt-0">
                  <div className="pe-3 cursor" data-bs-toggle="tooltip" title="Home">
                    <AntDesign name="home" size={20} color="#777" />
                  </div>
                </Link>

                <div className="d-md-block d-sm-none">

                  <div className="pe-3 cursor" data-bs-toggle="tooltip" title="Notifications">
                    <Ionicons name="ios-notifications-outline" size={22} color="#777" />
                  </div>
                </div>

                <div className="d-md-block d-sm-none">
                  <Link to="" className="p-0 mt-0">
                    <div className="pe-3 cursor" data-bs-toggle="tooltip" title="Logout">
                      <AntDesign name="logout" size={17} color="#777" />
                    </div>
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className=" pt-3 mb-5">
        {selected_type == null ? (
          <div className="p-2 ">
            <div className="text-center">
              <h6 className="fs-5">Choose your Account type</h6>
              <p className="small">Select your shop type individual or organization</p>
            </div>
            <div className="center w-md-40 m-auto">
              <div className="pt-4 w-100 px-3">
                <div className="mb-3 cursor card p-3">
                  <div
                    className="row align-items-center "
                    onClick={() => {
                      SetSelectedType("individual");
                    }}
                  >
                    <div className="col-md-3 px-0">
                      <img
                        src={require("../../assets/img/5.svg")}
                        className="acc-img"
                      />
                    </div>
                    <div className="col-md-9 text-sm-center text-md-left pt-sm-3 pt-md-0">
                      <p className="fs-7 fw-bold text-dark">Individual</p>
                      <p className="text-gray small pt-1">Create your own individual account</p>
                    </div>
                  </div>
                </div>
                <div className="mb-3 cursor card p-3">
                  <div
                    className="row align-items-center "
                    onClick={() => {
                      SetSelectedType("organization");
                      SetSaveData((oldValues: any) => ({
                        ...oldValues,
                        is_org: true,
                      }));
                    }}
                  >
                    <div className="col-md-3 px-0">
                      <img
                        src={require("../../assets/img/4.svg")}
                        className="acc-img"
                      />
                    </div>
                    <div className="col-md-9 text-sm-center text-md-left pt-sm-3 pt-md-0">
                      <p className="fs-7 fw-bold text-dark">Organization</p>
                      <p className="text-gray small pt-1">Create your own organization account</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : selected_type == "individual" ? (
          <div className="container px-md-3 px-sm-2 py-3">

            <div className="w-md-45 m-auto">
              <h6 className="text-center">Create your Individual Account</h6>

              <div className="text-center w-100 pt-3">
                {file == [] ? (
                  <img
                    src={require("../../assets/img/profile.png")}
                    className="acc-prof radius"
                  />
                ) : (
                  <img src={file[0]} className="acc-prof radius" />
                )}

                <div className="mt-2">
                  <input
                    accept="image/*, video/*"
                    className="d-none"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={upload}
                  />
                  <label htmlFor="contained-button-file">
                    <p className="text-prime extra-small cursor">Change profile image</p>
                  </label>
                </div>
                <div className="mt-3">
                  <label className="form-label text-black">Brand Name</label>
                  <input
                    type="text"
                    className="form-control btn-radius"
                    placeholder="Enter your brand name here"
                    value={save_data.brand_name}
                    required
                    onChange={(e: any) => {
                      SetSaveData((oldValues: any) => ({
                        ...oldValues,
                        brand_name: e.target.value,
                      }));
                    }}
                  />
                  <div className="text-center">
                    <button
                      className="btn bg-prime btn-radius text-white mt-3 "
                      onClick={() => {
                        create_brand();
                      }}
                    >
                      <span className="small">Create Brand</span>                      
                    </button>
                  </div>

                  <div
                    className="text-center w-100 mt-3"
                    onClick={() => {
                      SetSelectedType(null);
                      SetSaveData(initial_value);
                    }}
                  >
                    <p className="cursor">
                      <small className="text-prime">Change Account Type</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        ) : (
          <div className="container px-md-3 px-sm-0">
            {Object.keys(save_data.selected_org).length == 0 ? (
              <div className="px-2">
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="px-2 cursor"
                      onClick={() => {
                        SetSelectedType(null);
                        SetSaveData(initial_value);
                      }}
                    >
                      <MaterialCommunityIcons name="arrow-left" size={18} color="#000" />
                    </div>
                    <h6 className="mb-0">Organization</h6>
                  </div>
                  <div className="ms-auto">
                    <button className="btn-custom-outline px-3 btn fw-bold v-small">
                      Create New
                    </button>
                  </div>
                </div>
                <div className="row justify-content-center pt-4">
                  <div className="row p-0">
                    {organization_data.map((data: any, o_index: any) => (
                      <div className="col-md-4 mb-3">
                        <div
                          className="card p-3 cursor"
                          key={o_index}
                          onClick={(e) => {
                            SetSaveData((oldValues: any) => ({
                              ...oldValues,
                              selected_org: data,
                            }));
                          }}
                        >
                          <div className="row align-items-center">
                            <div className="col-2 p-0">
                              {data.dp_url == null ? (
                                <img
                                  src={require("../../assets/img/profile.png")}
                                  className="w-100 radius "
                                />
                              ) : (
                                <img
                                  src={data.dp_url}
                                  className="w-100 radius "
                                />
                              )}
                            </div>
                            <div className="col-10">
                              <p className=" text-dark">{data.label}</p>
                              <p className="text-gray v-small pt-1">{data.user_name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="container px-md-3 px-sm-2 py-2">

                <div className=" m-auto text-center">
                  <div className="d-flex align-items-center pb-3">
                    <div className="px-2 cursor"
                      onClick={() => {
                        SetSaveData((oldValues: any) => ({
                          ...oldValues,
                          selected_org: {},
                        }));
                      }}
                    >
                      <MaterialCommunityIcons name="arrow-left" size={16} color="#000" />
                    </div>
                    <h6 className="mb-0">{save_data.selected_org.label}</h6>
                  </div>
                    <div className="center">
                    <div>
  
  <p className="my-3 fs-7 text-dark">
                      Creating your organization account
                    </p>
  
                    {file == [] ? (
                      <img
                        src={require("../../assets/img/profile.png")}
                        className="acc-prof radius"
                      />
                    ) : (
                      <img src={file[0]} className="acc-prof radius" />
                    )}
                    <div className="mt-2">
                      <input
                        accept="image/*, video/*"
                        className="d-none "
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={upload}
                      />
                      <label htmlFor="contained-button-file">
                        <p className="text-prime extra-small cursor">Change profile image</p>
                      </label>
                    </div>
  
  
                    {/* <p className="text-center v-small pt-2 pb-2">
                      {save_data.selected_org.label} -
                      <span
                        className="cursor text-blue "
                        onClick={() => {
                          SetSaveData((oldValues: any) => ({
                            ...oldValues,
                            selected_org: {},
                          }));
                        }}
                      >
                        change
                      </span>
                    </p> */}
  
                    
  
                    <div className="pt-3">
                      <label className="form-label text-black">Brand Name</label>
                      <input
                        type="text"
                        className="form-control btn-radius"
                        placeholder="Enter your brand name here"
                        value={save_data.brand_name}
                        required
                        onChange={(e) => {
                          SetSaveData((oldValues: any) => ({
                            ...oldValues,
                            brand_name: e.target.value,
                          }));
                        }}
                      />
                      <div className="text-center">
                        <div
                          className="btn bg-prime btn-radius text-white mt-3"
                          onClick={() => {
                            create_brand();
                          }}
                        >
                          Create Brand
                        </div>
                        <div
                          className="text-center w-100 mt-3"
                          onClick={() => {
                            // SetSaveData((oldValues) => ({
                            //   ...oldValues,
                            //   selected_org: {},
                            // }));
                            SetSelectedType(null);
                            SetSaveData(initial_value);
                          }}
                        >
                          <p className="cursor">
                            <small className="text-prime">Change Account Type</small>
                          </p>
                        </div>
                      </div>
                    </div>
  </div>
                    </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer
        menu_click={(data: any) => {
          // on_menu_click(data);
        }}
        from="main"
      />
    </div>
  );
}
