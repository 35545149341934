import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { api } from "../../../utils/Api";
import { Link, useParams, useOutletContext } from "react-router-dom";
import ModalCreateSector from "./ModalCreateSector";
import { Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

export default function BrandSector(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext()
  const [id_value, SerIdValue] = useState(useParams());
  const [outlet_data, SetOutletData] = useState([]);
  const [modal_sector, SetModalSector] = useState({
    is_open: false,
    data: "",
    type: ""
  });
  const [width, SetWidth] = useState(innerWidth);
  const [grid_view, SetGridView] = useState(false);
  const [list_view, SetListView] = useState(true);

  useEffect(() => {
    console.log("Sector On mount :", props);
    console.log("Sector context :", context);
    console.log("ProductAssign OutletContext :", OutletContext);
  }, []);

  // async function get_outlets() {
  //   let pass_data = {
  //     get: {
  //       brand: id_value.brand_id,
  //     },
  //   };
  //   let response: any = await api("/brand/outlets", pass_data);
  //   console.log("/brand/outlets response :", response);
  //   if (response.status_code == 200) {
  //     if (response.response.hasOwnProperty("outlets")) {
  //       SetOutletData(response.response.outlets);
  //     } else {
  //       SetOutletData([]);
  //     }
  //   }
  // }

  // async function delete_outlet(data: any, index: any) {
  //   let pass_data = {
  //     get: {
  //       brand: id_value.brand_id,
  //     },
  //     post: {
  //       outlet_id: data.id,
  //     },
  //   };
  //   let response: any = await api("/brand/delete_outlet", pass_data);
  //   console.log("/brand/delete_outlet response :", response);
  //   if (response.status_code == 200) {
  //     let old_outlet_data = outlet_data;
  //     old_outlet_data.splice(index, 1);
  //     SetOutletData([...old_outlet_data]);
  // get_outlets();
  //   }
  // }

  return (
    <div className="">
      <div className={OutletContext ? "head-bar sticky-sm" : "head-bar sticky-sm"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className=" row align-items-center">
          <div className="col-md-4 col-7 sm-mb-2">
            <h6 className="mb-0">Sector</h6>
          </div>
          <div className="col-5 ps-0 d-sm-block d-md-none text-end sm-mb-2">
            <button
              className="btn btn-custom-outline btn-sm px-3"
              onClick={() => {
                console.log("Sector new");
                SetModalSector((prevValue: any) => {
                  prevValue.is_open = true;
                  prevValue.type = "create";
                  return { ...prevValue }
                })
              }}
            >
              Create New
            </button>
          </div>
          <div className="col-md-5 col-9 ">
            <div className="row px-md-3 md-none">
              <div className="input-icons form-group">

                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search products"

                />
                <div className="cursor ps-2 border-left">
                  <Ionicons name="md-search" size={18} color="#663fc3" />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center col-md-1 col-3 ps-sm-2 p-md-0">
            <select className="form-control btn-radius" defaultValue="10">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
          <div className="d-sm-none d-md-block text-center col-md-2">
            <button
              className="btn btn-custom-outline btn-sm px-3"
              onClick={() => {
                console.log("Sector new");
                SetModalSector((prevValue: any) => {
                  prevValue.is_open = true;
                  prevValue.type = "create";
                  return { ...prevValue }
                })
              }}
            >
              Create New
            </button>
          </div>
        </div>
      </div>

      <div className="mt-3 px-md-3 px-sm-0" 
       style={OutletContext == false ? { width: width, paddingTop: "2.1rem" } : {paddingTop: "0.3rem"}} >
        {list_view ? (
          <div className="mt-3 bg-fff p-3">
            {/* -----------------------------------------------------------------------------Length*/}
            {outlet_data.length > 0 ? (
              outlet_data.map((iteminfo: any) => (
                <div className="p-2 row list border-bottom">
                  <div className="col-md-4">
                    <h6 className="mb-1">{`Outlet : ${iteminfo.id}`}</h6>
                    <small>{`Name : ${iteminfo.name}`}</small>
                  </div>
                  <div className="col-md-3">
                    <p className="mb-0">
                      <small className="text-dark">Address : </small>
                      {iteminfo.address}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <small className="text-dark">GST</small>
                    <p className="mb-0">ACV4565F565E</p>
                  </div>
                  <div className="col-md-2">
                    <p className="mb-0">{`Mobile : ${iteminfo.mobile}`}</p>
                  </div>
                  <div className="col-md-2">
                    <p className="mb-0">{`Whatsapp : ${iteminfo.whatsapp}`}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">
                <img
                  src={require("../../../assets/icon/empty.png")}
                  className="empty-img pb-2"
                />
                <p className="v-small mb-0">No Data, Please Add Sectors</p>
              </div>
            )}

            
          </div>
        ) : null}

        {/* {grid_view ? (
          <div className="mt-4">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-12">
                <Link to={"/brand/" + id_value.brand_id + "/viewfranchise"}>
                  <div className="card p-3">
                    <h6 className="mb-1">Franchise 1</h6>
                    <small>Franchise type</small>
                    <p className="my-2">Pallipalayam, Namakkal, Tamilnadu</p>
                    <small>GST : ACV4565F565E</small>
                    <p className="mb-0">9638527410</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        ) : null} */}
      </div>

      <ModalCreateSector
        open={modal_sector.is_open}
        type={modal_sector.type}
        close={(data: any) => {
          console.log("Outlet modl on close :", data)
          SetModalSector((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.type = "create";
            return { ...prevValue }
          })
        }}
      />
    </div>
  );
}
