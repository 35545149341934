import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap.min.js";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import { omit } from "lodash";
import { useWizard, Wizard } from "react-use-wizard";

const animatedComponents = makeAnimated();
const outlet_initial_value = {
  selected_user: {},
  user_id: "",
  selected_roles: [],
  selected_custom_roles: [],
  selected_franchise: {},
  roles: { roles: [], custom_roles: [] },
  name: "",
  address: "",
  mobile: "",
  whatsapp: "",
  email: "",
  website: "",
  country: "",
  state: "",
  door_no: "",
  city: "",
  pincode: "",
};

let myModal: any = {};

export default function ModalCreateOutlet(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [errors, setErrors] = useState({});
  const [outlet_data, SetOutletData] = useState(outlet_initial_value);
  const [user_role_options, SetUserRoleOptions] = useState([]);
  const [custom_role_options, SetCustomRoleOptions] = useState([]);
  const [countries_options, SetCountriesOptions] = useState([]);
  const [states, SetStates] = useState([]);


  const [text, setText] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [user_data, SetUserData] = useState([]);
  const [next_page, SetNextPage] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const [new_outlet_id, SetNewOutletId] = useState(null);
  const [save_error, SetSaveError] = useState(false);

  const [franchise_options, SetFranchiseOptions] = useState([]);
  //OutletData End

  useEffect(() => {
    console.log("ModalCreateOutlet On mount :", props);
    let myModal1: any = document.getElementById("CreateOutletModal");
    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      console.log("event :", event);
      SetOutletData(outlet_initial_value);
      setText("");
      SetPageNo(1);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("ModalCreateOutlet On mount :", props);

      myModal = new bootstrap.Modal(
        document.getElementById("CreateOutletModal"),
        {}
      );
      myModal.show();
      get_init();
      get_states();
      get_users(page_no);
    }
  }, [props.open]);

  async function get_init() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
    };

    if (props.franchise_id != null) {
      pass_data.get.franchise = props.franchise_id;
    }

    let data_res = await api("/brand/create_outlet_init", pass_data);
    console.log("get_outlet_init response :", data_res.response);
    // let option_data = [];
    // Object.keys(data_res.response).map((key, index) => {
    //   let push_data = {};
    //   if (key == "custom_roles") {
    //     push_data.label = "Custom Roles";
    //     for (var i = 0; i < data_res.response[key].length; i++) {
    //       data_res.response[key][i].type = "custom_roles";
    //     }
    //   } else if (key == "roles") {
    //     push_data.label = "Roles";
    //     for (var i = 0; i < data_res.response[key].length; i++) {
    //       data_res.response[key][i].type = "roles";
    //     }
    //   }
    //   push_data.options = data_res.response[key];
    //   option_data.push(push_data);
    // });
    // console.log("Option Data :", option_data);
    // SetUserRoleOptions(option_data);

    if (data_res.response.hasOwnProperty("roles")) {
      SetUserRoleOptions(data_res.response.roles);
    }

    if (data_res.response.hasOwnProperty("custom_roles")) {
      SetCustomRoleOptions(data_res.response.custom_roles);
    }

    if (data_res.response.hasOwnProperty("franchise")) {
      SetFranchiseOptions(data_res.response.franchise);
    }

    if (data_res.response.hasOwnProperty("countries")) {
      SetCountriesOptions(data_res.response.countries);
    }
  }

  async function get_users(page_value: any, search_value = null) {
    let pass_data = {};
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,
        },
      };
    }
    let response = await api("/brand/get_users", pass_data);
    console.log("Outlet create /brand/get_users response :", response);
    if (response.response.data) {
      SetUserData(response.response.data);
    }
    SetNextPage(response.response.next_page);
  }

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const value = e.target.value;
    setText(value);
    console.log("Text onchange: ", value);
    if (value == "") {
      let page_no_value = 1;
      SetPageNo(page_no_value);
      get_users(page_no_value);
    } else {
      SetTimer(
        setTimeout(() => {
          get_users(1, value);
        }, WAIT_INTERVAL)
      );
    }
  }

  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != "") {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
        },
      };
    }
    let response = await api("/brand/get_users", pass_data);
    console.log("Users get_users response :", response);
    SetUserData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  function outlet_value_on_change(name: any, value: any) {
    validate(name, value);
    SetOutletData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  async function create_outlet() {
    console.log("create_outlet data :", outlet_data);
    console.log("create_outlet data :", id_value);



    if (outlet_data.name !== "" || outlet_data.user_id !== "") {
      let post_value = {
        user_id: outlet_data.user_id,
        roles: {},
        name: outlet_data.name,
        address: outlet_data.address,
        mobile: outlet_data.mobile,
        whatsapp: outlet_data.whatsapp,
        email: outlet_data.email,
        website: outlet_data.website,
        country: outlet_data.country,
        state: outlet_data.state,
        door_no: outlet_data.door_no,
        city: outlet_data.city,
        pincode: outlet_data.pincode,
      };
      let roles_value = {
        roles: [],
        custom_roles: [],
      };
      outlet_data.selected_roles.map((item: any, index: any) => {
        roles_value.roles.push(item.value);
      });

      outlet_data.selected_custom_roles.map((item: any, index: any) => {
        roles_value.custom_roles.push(item.value);
      });

      post_value.roles = roles_value;
      console.log("post_value :", post_value);
      let pass_data: any = {
        get: {
          brand: id_value.brand_id,
          franchise: id_value.franchise_id,
        },
        post: post_value,
      };

      // if (props.franchise_id != null) {
      //   pass_data.get.franchise = props.franchise_id;
      // }

      // if (outlet_data.selected_franchise != {}) {
      //   pass_data.get.franchise = outlet_data.selected_franchise.value;
      // }

      console.log("post_value :", pass_data);

      let data_res = await api("/brand/create_outlet", pass_data);
      console.log("get_outlet_init response :", data_res.response);
      if (data_res.status_code == 201) {
        let item = close_data;
        item.action = "save";
        let outlet_value = {
          id: data_res.response.id,
          name: outlet_data.name,
          address: outlet_data.address,
          mobile: outlet_data.mobile,
        };
        item.value = outlet_value;
        console.log("Close data : ", item);
        SetCloseData(item);
        SetOutletData(outlet_initial_value);
        myModal.hide();
      }
    } else {
      SetSaveError(true);
    }
  }

  function validate(name: any, value: any) {
    //A function to validate each input values
    console.log("name :", name);
    console.log("value  :", value);
    switch (name) {
      case "name":
        if (value.length <= 2) {
          // we will set the error state

          setErrors({
            ...errors,
            name: "Outlet name atleast have 3 letters",
          });
        } else {
          let newObj = omit(errors, "name");
          setErrors(newObj);
        }
        break;

      case "address":
        if (value.length <= 0) {
          setErrors({
            ...errors,
            address: "Enter valid address",
          });
        } else {
          let newObj = omit(errors, "address");
          setErrors(newObj);
        }
        break;

      case "mobile":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            mobile: "Enter valid mobile number",
          });
        } else {
          let newObj = omit(errors, "mobile");
          setErrors(newObj);
        }
        break;

      case "whatsapp":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            whatsapp: "Enter valid whatsapp number",
          });
        } else {
          let newObj = omit(errors, "whatsapp");
          setErrors(newObj);
        }
        break;

      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "Enter email not vaild",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      case "website":
        if (
          !new RegExp(
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            website: "Enter website not vaild",
          });
        } else {
          let newObj = omit(errors, "website");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  }

  async function get_states() {
    let pass_data = {
      get: {
        country_id: 99,
      },
    };
    let response = await api("/init/get_states", pass_data);
    console.log("/brand/settings response :", response);
    if (response.status_code == 200) {
      SetStates(response.response.date);

    }

  }

  return (
    //     <form
    //       className="modal fade"
    //       id="CreateOutletModal"
    //       tabIndex={-1}
    //       aria-labelledby="ModalLabel"
    //       aria-hidden="true"
    //       // data-bs-backdrop="static"
    //     >
    //       <div className="modal-dialog">
    //         <div className="modal-content">
    //           <div className="modal-header">
    //             {userOpen ? (
    //               <div className="d-flex align-items-center">
    //                 <div
    //                   className="pe-2 cursor"
    //                   onClick={() => {
    //                     console.log("back outlet");
    //                     setUserOpen(false);
    //                   }}
    //                 >
    //                   <img
    //                     src={require("../../../assets/icon/svg/arrow-left.svg")}
    //                     className="action_menu_img"
    //                   />
    //                 </div>
    //                 <h6 className="  modal-title" id="ModalLabel">
    //                   Select User
    //                 </h6>
    //               </div>
    //             ) : (
    //               <h6 className="  modal-title" id="ModalLabel">
    //                 Create Outlet
    //               </h6>
    //             )}

    //             <button
    //               type="button"
    //               className="btn-close"
    //               aria-label="Close"
    //               onClick={() => {
    //                 let item = close_data;
    //                 item.action = "close";
    //                 SetCloseData(item);
    //                 SetOutletData(outlet_initial_value);
    //                 myModal.hide();
    //               }}
    //             ></button>

    //           </div>
    //           <div className="modal-body">
    //             <div>
    //               {userOpen ? (
    //                 <div className="row">
    //                   <div className="col-12">
    //                     <label>Select user</label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       placeholder="Select User Name"
    //                       defaultValue={text}
    //                       onChange={(e) => {
    //                         onTextChanged(e);
    //                       }}
    //                     />
    //                   </div>

    //                   <h6 className="mt-3">Available Users</h6>
    //                   <div className="mt-2 px-3">
    //                     {user_data.map((item, sugIndex) => (
    //                       <div
    //                         className="cursor"
    //                         key={sugIndex}
    //                         onClick={() => {
    //                           SetOutletData((oldValues) => ({
    //                             ...oldValues,
    //                             selected_user: item,
    //                             user_id: item.id,
    //                           }));
    //                           setUserOpen(false);
    //                           console.log("Select item:", item);
    //                         }}
    //                       >
    //                         <p>
    //                           <i className="fas fa-user"></i> {item.name}
    //                         </p>
    //                       </div>
    //                     ))}
    //                   </div>
    //                   {next_page ? (
    //                     <p
    //                       onClick={() => {
    //                         load_more();
    //                       }}
    //                     >
    //                       Load More
    //                     </p>
    //                   ) : null}
    //                 </div>
    //               ) : (
    //                 <div className="row">
    //                   {/* User Name */}
    //                   <div className="col-12 col-md-6 mt-2 ">
    //                     <label htmlFor="userName" className="form-label">
    //                       User Name
    //                     </label>
    //                     <div
    //                       onClick={() => {
    //                         setUserOpen(true);
    //                         SetSaveError(false);
    //                       }}
    //                       className="cursor"
    //                     >
    //                       <input
    //                         type="text"
    //                         className="form-control"
    //                         id="userName"
    //                         value={outlet_data.selected_user.name}
    //                         placeholder="Select User Name"
    //                       />
    //                     </div>
    //                     {errors.userName && (
    //                       <small className="text-red">{errors.userName}</small>
    //                     )}
    //                     {save_error == true ? (
    //                       <small className="text-red">* Required fields</small>
    //                     ) : null}
    //                   </div>

    //                   {/* Franchise */}
    //                   <div className="col-12 col-md-6 mt-2 ">
    //                     <label className="form-label" htmlFor="selected_roles" >
    //                       Select Franchise
    //                     </label>

    //                     {console.log(franchise_options)}
    //                     {console.log(props.franchise_id)}

    //                     <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" disabled>

    //        <option selected>
    //        {/* {document.getElementById(props.franchise_id).value} */}
    //       { franchise_options.filter(x => x.id === props.franchise_id).map(x => x.label)}
    //           </option>
    //   {/* <option selected>Open this select menu</option> */}
    //   <option value="1">One</option>
    //   <option value="2">Two</option>
    //   <option value="3">Three</option>
    // </select>
    //                     {/* <Select
    //                       components={animatedComponents}
    //                       className="basic-select"
    //                       classNamePrefix="select Franchise"
    //                       placeholder="Select Franchise"
    //                       id="selected_roles"
    //                       isMulti
    //                       Group

    //                       value={outlet_data.selected_franchise}
    //                       options={franchise_options}

    //                       onChange={(e, action) => {
    //                         console.log("User Role on change e :", e);
    //                         console.log("User Role on change action :", action);

    //                         SetOutletData((oldValues: any) => ({
    //                           ...oldValues,
    //                           selected_franchise: e,
    //                         }));
    //                         SetSaveError(false);
    //                       }}


    //                     /> */}
    //                     {errors.selected_roles && (
    //                       <small className="text-red">
    //                         {errors.selected_roles}
    //                       </small>
    //                     )}
    //                     {save_error == true ? (
    //                       <small className="text-red">* Required fields</small>
    //                     ) : null}
    //                   </div>


    //                   {/* Role */}
    //                   <div className="col-12 col-md-6 mt-2">
    //                     <label className="form-label" htmlFor="selected_roles">
    //                       Select Role
    //                     </label>
    //                     <Select
    //                       components={animatedComponents}
    //                       className="basic-select"
    //                       classNamePrefix="select Role"
    //                       placeholder="Select Role"
    //                       id="selected_roles"
    //                       isMulti
    //                       Group
    //                       value={outlet_data.selected_roles}
    //                       options={user_role_options}
    //                       onChange={(e, action) => {
    //                         console.log("User Role on change e :", e);
    //                         console.log("User Role on change action :", action);

    //                         SetOutletData((oldValues: any) => ({
    //                           ...oldValues,
    //                           selected_roles: e,
    //                         }));
    //                         SetSaveError(false);
    //                       }}
    //                     />
    //                     {errors.selected_roles && (
    //                       <small className="text-red">
    //                         {errors.selected_roles}
    //                       </small>
    //                     )}
    //                     {save_error == true ? (
    //                       <small className="text-red">* Required fields</small>
    //                     ) : null}
    //                   </div>

    //                   {/* C_Role */}
    //                   <div className="col-12 col-md-6 mt-2 ">
    //                     <label className="form-label" htmlFor="selected_roles">
    //                       Select Custom Role
    //                     </label>
    //                     <Select
    //                       components={animatedComponents}
    //                       className="basic-multi-select"
    //                       classNamePrefix="select Custom Role"
    //                       placeholder="Select Custom Role"
    //                       id="selected_roles"
    //                       isMulti
    //                       Group
    //                       value={outlet_data.selected_custom_roles}
    //                       options={custom_role_options}
    //                       onChange={(e, action) => {
    //                         console.log("User Role on change e :", e);
    //                         console.log("User Role on change action :", action);
    //                         SetOutletData((oldValues: any) => ({
    //                           ...oldValues,
    //                           selected_custom_roles: e,
    //                         }));
    //                         SetSaveError(false);
    //                       }}
    //                     />
    //                     {errors.selected_custom_roles && (
    //                       <small className="text-red">
    //                         {errors.selected_custom_roles}
    //                       </small>
    //                     )}
    //                     {save_error == true ? (
    //                       <small className="text-red">* Required fields</small>
    //                     ) : null}
    //                   </div>

    //                   {/* Outlet Name */}
    //                   <div className="col-12 col-md-6 mt-2">
    //                     <label className="form-label" htmlFor="name">
    //                       Outlet Name
    //                     </label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="name"
    //                       onChange={(e) => {
    //                         outlet_value_on_change("name", e.target.value);
    //                         SetSaveError(false);
    //                       }}
    //                     />
    //                     {errors.name && (
    //                       <small className="text-red">{errors.name}</small>
    //                     )}
    //                     {save_error == true ? (
    //                       <small className="text-red">* Required fields</small>
    //                     ) : null}
    //                   </div>

    //                   {/* Address */}
    //                   <div className="col-12 col-md-6 mt-2">
    //                     <label className="form-label">Address</label>
    //                     <textarea
    //                       rows={3}
    //                       className="form-control"
    //                       onChange={(e) => {
    //                         outlet_value_on_change("address", e.target.value);
    //                       }}
    //                     ></textarea>
    //                     {errors.address && (
    //                       <small className="text-red">{errors.address}</small>
    //                     )}
    //                   </div>

    //                   {/* Mobile Number */}
    //                   <div className="col-12 col-md-6 mt-2">
    //                     <label className="form-label">Mobile Number</label>
    //                     <input
    //                       type="number"
    //                       className="form-control"
    //                       value={outlet_data.mobile}
    //                       onChange={(e) => {
    //                         if (e.target.value.length == 11) return false;
    //                         let val = e.target.value.slice(0, 10);
    //                         outlet_value_on_change("mobile", val);
    //                         SetSaveError(false);
    //                       }}
    //                     />
    //                     {errors.mobile && (
    //                       <small className="text-red">{errors.mobile}</small>
    //                     )}
    //                     {save_error == true ? (
    //                       <small className="text-red">* Required fields</small>
    //                     ) : null}
    //                   </div>

    //                   {/* Whatsapp Number */}
    //                   <div className="col-12 col-md-6 mt-2">
    //                     <label className="form-label">Whatsapp Number</label>
    //                     <input
    //                       type="number"
    //                       value={outlet_data.whatsapp}
    //                       className="form-control"
    //                       onChange={(e) => {
    //                         if (e.target.value.length == 11) return false;
    //                         let val = e.target.value.slice(0, 10);
    //                         outlet_value_on_change("whatsapp", val);
    //                         SetSaveError(false);
    //                       }}
    //                     />
    //                     {errors.whatsapp && (
    //                       <small className="text-red">{errors.whatsapp}</small>
    //                     )}
    //                     {save_error == true ? (
    //                       <small className="text-red">* Required fields</small>
    //                     ) : null}
    //                   </div>

    //                   {/* Email */}
    //                   <div className="col-12 col-md-6 mt-2">
    //                     <label className="form-label">Email</label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       onChange={(e) => {
    //                         outlet_value_on_change("email", e.target.value);
    //                       }}
    //                     />
    //                     {errors.email && (
    //                       <small className="text-red">{errors.email}</small>
    //                     )}
    //                   </div>

    //                   {/* Website */}
    //                   <div className="col-12 col-md-6 mt-2">
    //                     <label className="form-label">Website</label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       onChange={(e) => {
    //                         outlet_value_on_change("website", e.target.value);
    //                       }}
    //                     />
    //                     {errors.website && (
    //                       <small className="text-red">{errors.website}</small>
    //                     )}
    //                   </div>
    //                 </div>
    //               )}
    //             </div>
    //           </div>
    //           <div className="modal-footer">
    //             {userOpen ? (
    //               <div>
    //                 <button
    //                   type="button"
    //                   className="btn btn-primary"
    //                   onClick={() => {
    //                     setUserOpen(false);
    //                   }}
    //                 >
    //                   Cancel
    //                 </button>
    //               </div>
    //             ) : (
    //               <div>
    //                 <button
    //                   type="button"
    //                   className="btn btn-light-outline"
    //                   onClick={() => {
    //                     create_outlet();
    //                   }}
    //                 >
    //                   Create
    //                 </button>
    //               </div>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </form>
   <div>
     <form
      className="modal fade"
      id="CreateOutletModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    // data-bs-backdrop="static"
    >
      <div className="modal-dialog  modal-fullscreen-sm-down modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            {userOpen ? (
              <div className="d-flex align-items-center">
                <div
                  className="pe-2 cursor"
                  onClick={() => {
                    console.log("back outlet");
                    setUserOpen(false);
                  }}
                >
                  <img
                    src={require("../../../assets/icon/svg/arrow-left.svg")}
                    className="action_menu_img"
                  />
                </div>
                <h6 className="  modal-title" id="ModalLabel">
                  Select User
                </h6>
              </div>
            ) : (
              <h6 className="  modal-title" id="ModalLabel">
                Create Outlet
              </h6>
            )}

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                SetOutletData(outlet_initial_value);
                myModal.hide();
              }}
            ></button>

          </div>
          <div className="modal-body">
            <div>
              {userOpen ? (
                <div className="row">
                  <div className="col-12">
                    <label>Select user</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select User Name"
                      defaultValue={text}
                      onChange={(e) => {
                        onTextChanged(e);
                      }}
                    />
                  </div>

                  <h6 className="mt-3">Available Users</h6>
                  <div className="mt-2 px-3">
                    {user_data.map((item, sugIndex) => (
                      <div
                        className="cursor"
                        key={sugIndex}
                        onClick={() => {
                          SetOutletData((oldValues) => ({
                            ...oldValues,
                            selected_user: item,
                            user_id: item.id,
                          }));
                          setUserOpen(false);
                          console.log("Select item:", item);
                        }}
                      >
                        <p>
                          <i className="fas fa-user"></i> {item.name}
                        </p>
                      </div>
                    ))}
                  </div>
                  {next_page ? (
                    <p
                      onClick={() => {
                        load_more();
                      }}
                    >
                      Load More
                    </p>
                  ) : null}
                </div>
              ) : (
                <div className="row">
                  {/* User Name */}
                  <div className="col-12 col-md-6 mt-2 ">
                    <label htmlFor="userName" className="form-label">
                      User Name
                    </label>
                    <div
                      onClick={() => {
                        setUserOpen(true);
                        SetSaveError(false);
                      }}
                      className="cursor"
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="userName"
                        value={outlet_data.selected_user.name}
                        placeholder="Select User Name"
                      />
                    </div>
                    {errors.userName && (
                      <small className="text-red">{errors.userName}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* Franchise */}
                  <div className="col-12 col-md-6 mt-2 ">
                    <label className="form-label" htmlFor="selected_roles" >
                      Select Franchise
                    </label>
                    <Select
                      components={animatedComponents}
                      className="basic-select"
                      classNamePrefix="select Franchise"
                      placeholder="Select Franchise"
                      id="selected_roles"
                      isMulti
                      Group

                      value={outlet_data.selected_franchise}
                      options={franchise_options}
                      onChange={(e, action) => {
                        console.log("User Role on change e :", e);
                        console.log("User Role on change action :", action);

                        SetOutletData((oldValues: any) => ({
                          ...oldValues,
                          selected_franchise: e,
                        }));
                        SetSaveError(false);
                      }}
                    />
                    {errors.selected_roles && (
                      <small className="text-red">
                        {errors.selected_roles}
                      </small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>


                  {/* Role */}
                  <div className="col-12 col-md-6 mt-2">
                    <label className="form-label" htmlFor="selected_roles">
                      Select Role
                    </label>
                    <Select
                      components={animatedComponents}
                      className="basic-select"
                      classNamePrefix="select Role"
                      placeholder="Select Role"
                      id="selected_roles"
                      isMulti
                      Group
                      value={outlet_data.selected_roles}
                      options={user_role_options}
                      onChange={(e, action) => {
                        console.log("User Role on change e :", e);
                        console.log("User Role on change action :", action);

                        SetOutletData((oldValues: any) => ({
                          ...oldValues,
                          selected_roles: e,
                        }));
                        SetSaveError(false);
                      }}
                    />
                    {errors.selected_roles && (
                      <small className="text-red">
                        {errors.selected_roles}
                      </small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* C_Role */}
                  <div className="col-12 col-md-6 mt-2 ">
                    <label className="form-label" htmlFor="selected_roles">
                      Select Custom Role
                    </label>
                    <Select
                      components={animatedComponents}
                      className="basic-multi-select"
                      classNamePrefix="select Custom Role"
                      placeholder="Select Custom Role"
                      id="selected_roles"
                      isMulti
                      Group
                      value={outlet_data.selected_custom_roles}
                      options={custom_role_options}
                      onChange={(e, action) => {
                        console.log("User Role on change e :", e);
                        console.log("User Role on change action :", action);
                        SetOutletData((oldValues: any) => ({
                          ...oldValues,
                          selected_custom_roles: e,
                        }));
                        SetSaveError(false);
                      }}
                    />
                    {errors.selected_custom_roles && (
                      <small className="text-red">
                        {errors.selected_custom_roles}
                      </small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>



                  {/* Outlet Name */}
                  <div className="col-12 col-md-6 mt-2">
                    <label className="form-label" htmlFor="name">
                      Outlet Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      onChange={(e) => {
                        outlet_value_on_change("name", e.target.value);
                        SetSaveError(false);
                      }}
                    />
                    {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* Door No */}
                  <div className="col-12 col-md-6 mt-2">
                    <label className="form-label" htmlFor="name">
                      Door No
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      onChange={(e) => {
                        outlet_value_on_change("door_no", e.target.value);
                        SetSaveError(false);
                      }}
                    />
                    {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>
                  {/* City*/}
                  <div className="col-12 col-md-6 mt-2">
                    <label className="form-label" htmlFor="name">
                      city
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      onChange={(e) => {
                        outlet_value_on_change("city", e.target.value);
                        SetSaveError(false);
                      }}
                    />
                    {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* pincode */}
                  <div className="col-12 col-md-6 mt-2">
                    <label className="form-label" htmlFor="name">
                      Pincode
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      onChange={(e) => {
                        outlet_value_on_change("pincode", e.target.value);
                        SetSaveError(false);
                      }}
                    />
                    {errors.name && (
                      <small className="text-red">{errors.name}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* Countries */}
                  <div className="col-12 col-md-6 mt-2">
                    <label className="form-label" htmlFor="selected_roles">
                      Select Countries
                    </label>
                    <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" onChange={(e) => {

                      outlet_value_on_change("country", e.target.value);
                    }}>

                      {countries_options.map((ele: any) => (


                        <option value={ele.id}>{ele.nicename}</option>

                      ))}
                    </select>
                  </div>
                  {/* States */}

                  {outlet_data.country == "99" &&

                    <div className="col-md-6 md-ps-0 sm-p-0">

                      <label className="form-label" htmlFor="selected_roles">
                        States
                      </label>

                      <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example" onChange={(e) => {
                        outlet_value_on_change("state", e.target.value);
                      }}>

                        {states.map((ele: any) => (


                          <option value={ele.id} >{ele.nicename}</option>

                        ))}
                      </select>

                    </div>
                  }

                  {/* Address */}
                  <div className="col-12 col-md-6 mt-2">
                    <label className="form-label">Address</label>
                    <textarea
                      rows={3}
                      className="form-control"
                      onChange={(e) => {
                        outlet_value_on_change("address", e.target.value);
                      }}
                    ></textarea>
                    {errors.address && (
                      <small className="text-red">{errors.address}</small>
                    )}
                  </div>

                  {/* Mobile Number */}
                  <div className="col-12 col-md-6 mt-2">
                    <label className="form-label">Mobile Number</label>
                    <input
                      type="number"
                      className="form-control"
                      value={outlet_data.mobile}
                      onChange={(e) => {
                        if (e.target.value.length == 11) return false;
                        let val = e.target.value.slice(0, 10);
                        outlet_value_on_change("mobile", val);
                        SetSaveError(false);
                      }}
                    />
                    {errors.mobile && (
                      <small className="text-red">{errors.mobile}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* Whatsapp Number */}
                  <div className="col-12 col-md-6 mt-2">
                    <label className="form-label">Whatsapp Number</label>
                    <input
                      type="number"
                      value={outlet_data.whatsapp}
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.value.length == 11) return false;
                        let val = e.target.value.slice(0, 10);
                        outlet_value_on_change("whatsapp", val);
                        SetSaveError(false);
                      }}
                    />
                    {errors.whatsapp && (
                      <small className="text-red">{errors.whatsapp}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>

                  {/* Email */}
                  <div className="col-12 col-md-6 mt-2">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        outlet_value_on_change("email", e.target.value);
                      }}
                    />
                    {errors.email && (
                      <small className="text-red">{errors.email}</small>
                    )}
                  </div>

                  {/* Website */}
                  <div className="col-12 col-md-6 mt-2">
                    <label className="form-label">Website</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        outlet_value_on_change("website", e.target.value);
                      }}
                    />
                    {errors.website && (
                      <small className="text-red">{errors.website}</small>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            {userOpen ? (
              <div>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm px-3"
                  onClick={() => {
                    setUserOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                <button
                  type="button"
                  className="btn bg-prime text-white btn-radius btn-sm px-3"
                  onClick={() => {
                    create_outlet();
                  }}
                >
                  Create
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
   </div>
  );
}
