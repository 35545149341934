import React, { useState, useEffect, useContext } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { api } from "../../../utils/Api";
import DataContext from "../../../utils/Context/DataContext";
import { AppHelper } from "../../../utils/Helper";
import Skeleton from "react-loading-skeleton";


import {
  AntDesign,
  Entypo,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
  FontAwesome,
} from "@expo/vector-icons";

import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppConfig } from "../../../config";

const initial_time = [{ start: "", end: "" }];
const week_initial_time = [
  { timeRanges: [{ start: "", end: "", day: 1 }] },
  { timeRanges: [{ start: "", end: "", day: 2 }] },
  { timeRanges: [{ start: "", day: 3, end: "" }] },
  { timeRanges: [{ start: "", day: 4, end: "" }] },
  { timeRanges: [{ start: "", day: 5, end: "" }] },
  { timeRanges: [{ start: "", day: 6, end: "" }] },
  { timeRanges: [{ start: "", day: 7, end: "" }] },
];

export default function ProductTiming(props: any) {
  console.log(props.type);
  const [id_value, SetIdValue] = useState(useParams());

  const [deleted_ids, SetDeletedIds] = useState([]);
  const [id, SetId] = useState("");
  const [outlet_id, SetOutletId] = useState("");


  const [next_page, SetNextPage] = useState(false);

  const [available_diff_time, SetAvailableDiffTime] = useState(false);

  const [timings, setTimings] = useState(initial_time);
  const [week_timings, setWeekTimings] = useState(week_initial_time);
  const [outer_data, SetOuterData] = useState([]);
  const [radio_val, SetRadioVal] = useState("");

  const [page_no, SetPageNo] = useState(1);
  const [pro_index, SetProIndex] = useState(-1);

  const [transaction_able, SetTransactionAble] = useState(false);
  const [filter_data, setFilterData] = useState("");
  const context = useContext(DataContext);
  const [selected_details, SetSelectedDetails] = useState();
  // State to store the timeout ID
  const [timeoutId, setTimeoutId] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [product_list, setProductList] = useState([]);
  const [is_loading, SetIsLoading] = useState(false);

  const [payments, setPayments] = useState([]);
  const [appliction, SetApplication] = useState([]);
  const [visible_content, SetVisibleContent] = useState(false);
  const [diff_time, SetDiffTime] = useState(false);
  const [product_time, SetProductTime] = useState(id_value.product_timing);

  const [transaction_details, SetTransactionDetails] = useState({});

  const [width, SetWidth] = useState(innerWidth);
  const [selectedApplication, setSelectedApplication] = useState("");

  const handleApplicationChange = (event: any) => {
    console.log(event.target.value);
    SetPageNo(1);
    let send_data = {
      page_no: 1,
    };
    ProductList(event.target.value, filter_data, send_data);
    SetOutletId(event.target.value);
    setSelectedApplication(event.target.value);
    let pass_data = {
      sort: "",
      page_no: 1,
    };
    setPayments([]);
    // SetTransactionAble(false)
    // get_categories(pass_data, filter_data,event.target.value);
  };

  // function diff_time_able() {
  //   SetDiffTime(true);
  // }
  // function diff_time_disable() {
  //   SetDiffTime(false);
  // }

  function diff_time_disable(e) {
    SetRadioVal(e.target.value);
    if (e.target.value == 1) {
      SetDiffTime(false);
    } else {
      SetDiffTime(true);
    }
  }

  async function ProductList(outlet_id: any, search: any, get_data: any) {
    if(!get_data.load_more){
      SetIsLoading(true)

    }
    // console.log(send_data)
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: outlet_id,
        page: get_data.page_no,
        search: search,
        // application : app
        // seller_brand_id: get_data.seller_brand_id,
        // seller_outlet_id: get_data.seller_outlet_id,
        // sort: get_data.sort
      },
    };
    // if (context.app_data.app_from == 'seller') {
    // if (id_value.hasOwnProperty("brand_id")) {
    //   pass_data.get.brand = id_value.brand_id;
    // } else {
    //   pass_data.get.m_id = id_value.m_id;
    // }

    let response: any;
    if (props.type == "product_timing") {
      response = await api(
        "/brand/outlet_product_variant_timing_list",
        pass_data
      );
    } else if (props.type == "product_group_timing") {
      response = await api("/brand/outlet_product_timing_list", pass_data);
    }
    if (response.status_code == 200) {
      // setOutlets
      console.log(response);
      // setProductList(response.response.data)

   

      if (response.response.hasOwnProperty("data")) {
        if (get_data.load_more) {
          setProductList((prevValue: any) => {
            response?.response?.data?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          setProductList(response.response.data);

          SetOuterData(response.response);

          disable();
        }
        SetIsLoading(false)
      }
      SetVisibleContent(true);
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
      // SetApplication(response.response.outlets);

      //   console.log(response.response.applications);
      //   SetApplication(response.response.applications);

      // SetVisibleContent(true);

      //   if (response.response.hasOwnProperty("data")) {
      //     if (get_data.load_more) {
      //       SetApplication((prevValue: any) => {
      //         response?.response?.data?.map((ele) => {
      //           prevValue.push(ele);
      //         });
      //         return [...prevValue];
      //       });
      //     } else {
      //       SetApplication(response.response.data);
      //     }
      //   }

      //   if (response.response.hasOwnProperty("next_page")) {
      //     SetNextPage(response.response.next_page);
      //     SetPageNo((prevalue) => {
      //       prevalue = prevalue + 1;
      //       return prevalue;
      //     });
      //   }
      //   setPayments((prevValue) => {
      //     response.response.data.map((item) => {
      //       prevValue.push(item);
      //     });
      //     return [...prevValue];
      //   });
      //   SetNextPage(response.response.next_page);
      // if (response.response.hasOwnProperty("next_page")) {
      //   SetFilterData((prevValue: any) => {
      //     prevValue.next_page = response.response.next_page;
      //     return { ...prevValue };
      //   });
      // }
      // if (response.response.hasOwnProperty("sizes")) {
      //   SetSizeData(response.response.sizes);
      // }
      // if (response.response.hasOwnProperty("image_base_url")) {
      //   SetImageBaseUrl(response.response.image_base_url);
      // }
      // if (response.response.hasOwnProperty("data")) {
      //   let gallery_value = response.response.data;
      //   // gallery_value.map((ele: any) => {
      //   //   ele.checked = false
      //   // })
      //   if (fetch_value) {
      //     SetGalleryData((prevValue: any) => {
      //
      //       // response.response.data.map((ele: any) => {
      //       //   ele.checked = false
      //       //   prevValue.push(ele)
      //       // })
      //
      //       // prevValue = response.response.data
      //       let old_data = prevValue,
      //         resp_data = response.response.data,
      //         new_data = [];
      //       old_data.map((ele: any) => {
      //         new_data.push(ele);
      //       });
      //       resp_data.map((ele: any) => {
      //         new_data.push(ele);
      //       });
      //       prevValue = new_data;
      //       // prevValue.push.apply(prevValue, response.response.data)
      //       SetFilterData((prevValue1: any) => {
      //         if (prevValue.every((v_ele) => v_ele.checked == true)) {
      //           prevValue1.is_all_checked = true;
      //         } else {
      //           prevValue1.is_all_checked = false;
      //         }
      //         return { ...prevValue1 };
      //       });
      //       return [...prevValue];
      //     });
      //   } else {
      //     SetFilterData((prevValue: any) => {
      //       if (gallery_value.every((v_ele) => v_ele.checked == true)) {
      //         prevValue.is_all_checked = true;
      //       } else {
      //         prevValue.is_all_checked = false;
      //       }
      //       return { ...prevValue };
      //     });
      //     SetGalleryData(response.response.data);
      //   }

      //   // SetGalleryData(response.response.data)
      // }
    }
  }

  async function Save(i: any) {
    console.log(i);
    let deleted_ids = [];

    console.log(timings);
    timings.map((ele) => {
      if (ele.hasOwnProperty("id")) {
        deleted_ids.push(ele.id);
      }
    });

    week_timings.map((ele) => {
      ele.timeRanges.map((m_ele) => {
        if (m_ele.hasOwnProperty("id")) {
          deleted_ids.push(m_ele.id);
        }
      });
    });

    console.log(deleted_ids);
    console.log(week_timings);

    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: outlet_id,
        // page: get_data.page_no,
        // search: search,
        // application : app
        // seller_brand_id: get_data.seller_brand_id,
        // seller_outlet_id: get_data.seller_outlet_id,
        // sort: get_data.sort
      },
      post: {
        id: id,
        delete_id: deleted_ids,
      },
    };

    let response;
    if (props.type == "product_timing") {
      response = await api(
        "/brand/delete_outlet_product_variant_timing",
        pass_data
      );
    } else if (props.type == "product_group_timing") {
      response = await api("/brand/delete_outlet_product_timing", pass_data);
    }

    if (response.status_code == 200) {
      console.log(response);

      toast.success("Saved");
      SetAvailableDiffTime(false);
      setTimings(initial_time);

      const week_ini_time = [
        { timeRanges: [{ start: "", end: "", day: 1 }] },
        { timeRanges: [{ start: "", end: "", day: 2 }] },
        { timeRanges: [{ start: "", day: 3, end: "" }] },
        { timeRanges: [{ start: "", day: 4, end: "" }] },
        { timeRanges: [{ start: "", day: 5, end: "" }] },
        { timeRanges: [{ start: "", day: 6, end: "" }] },
        { timeRanges: [{ start: "", day: 7, end: "" }] },
      ];

      setWeekTimings(week_ini_time);
    }
  }

  useEffect(() => {
    // Update product_time when id_value.product_timing changes
    console.log(props.type);
    // SetProductTime(id_value.product_timing);
    get_categories("from_top");
    // return () => {
    SetDeletedIds([]);
    SetId("");
    SetOutletId("");
    SetNextPage(false);
    SetAvailableDiffTime(false);
    setTimings(initial_time);
    setWeekTimings(week_initial_time);
    SetOuterData([]);
    SetRadioVal("");
    SetPageNo(1);
    SetProIndex(-1);
    SetTransactionAble(false);
    setFilterData("");
    SetSelectedDetails();
    setTimeoutId(null);
    setOutlets([]);
    setProductList([]);
    setPayments([]);
    SetApplication([]);
    SetVisibleContent(false);
    SetDiffTime(false);
    SetProductTime(id_value.product_timing);
    SetTransactionDetails({});
    SetWidth(innerWidth);
    setSelectedApplication("");
    console.log(props.type);
    // }
  }, [id_value.product_timing, props.type]);

  useEffect(() => {
    let pass_data = {
      sort: "",
      page_no: 1,
    };
    // get_categories(pass_data, filter_data,selectedApplication);
    // get_categories("from_top");
    // // return () => {
    //   SetDeletedIds([]);
    //   SetId("");
    //   SetOutletId("");
    //   SetNextPage(false);
    //   SetAvailableDiffTime(false);
    //   setTimings(initial_time);
    //   setWeekTimings(week_initial_time);
    //   SetOuterData([]);
    //   SetRadioVal("");
    //   SetPageNo(1);
    //   SetProIndex(-1);
    //   SetTransactionAble(false);
    //   setFilterData("");
    //   SetSelectedDetails();
    //   setTimeoutId(null);
    //   setOutlets([]);
    //   setProductList([]);
    //   setPayments([]);
    //   SetApplication([]);
    //   SetVisibleContent(false);
    //   SetDiffTime(false);
    //   SetProductTime(id_value.product_timing);
    //   SetTransactionDetails({});
    //   SetWidth(innerWidth);
    //   setSelectedApplication("");
    //   console.log(props.type)
    //   // }
    var currentURL = window.location.href;

    // Split the URL by "/"
    var urlParts = currentURL.split("/");

    // Get the last part of the URL
    var lastPart = urlParts[urlParts.length - 1];

    console.log(lastPart);
    // console.log(id_value);
  }, [props.type]);

  async function get_categories(props: any) {

    SetIsLoading(true)
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };

    let response: any = await api("/brand/outlet_list", pass_data);
    if (response.status_code == 200) {
      // setOutlets

      let pass_data;
      if (props == "from_top") {
        pass_data = {
          sort: "",
          page_no: 1,
        };
        SetPageNo(1);
      } else {
        pass_data = {
          sort: "",
          page_no: page_no,
        };
      }
      console.log(response.response.outlets);
      setOutlets(response.response.outlets);
      SetOutletId(response.response.outlets[0]?.id);
      setFilterData("");
      ProductList(response.response.outlets[0]?.id, "", pass_data);
      setSelectedApplication(response.response.outlets[0]?.id);
      SetIsLoading(false)
      // SetVisibleContent(true);
    }
  }

  function enable(ele: any, i: any) {
    const week_ini_time = [
      { timeRanges: [{ start: "", end: "", day: 1 }] },
      { timeRanges: [{ start: "", end: "", day: 2 }] },
      { timeRanges: [{ start: "", day: 3, end: "" }] },
      { timeRanges: [{ start: "", day: 4, end: "" }] },
      { timeRanges: [{ start: "", day: 5, end: "" }] },
      { timeRanges: [{ start: "", day: 6, end: "" }] },
      { timeRanges: [{ start: "", day: 7, end: "" }] },
    ];
    console.log(week_timings);
    SetId(ele.id);
    SetTransactionDetails(ele);
    SetProIndex(i);
    if ((ele?.daily && ele?.daily.length > 0) || ele?.weekly.length > 0) {
      SetRadioVal(2);
      SetDiffTime(true);
    } else {
      SetRadioVal(1);

      SetDiffTime(false);
    }
    console.log(ele.daily);
    if (ele?.daily && ele?.daily.length > 0) {
      const formattedTimings = ele?.daily.map((dailyTiming) => ({
        start: dailyTiming.start_time_24,
        end: dailyTiming.end_time_24,
        id: dailyTiming.id,
      }));

      setTimings(formattedTimings);
    } else {
      const ini_time = [{ start: "", end: "" }];
      setTimings(ini_time);
    }

    if (ele?.weekly && ele?.weekly.length > 0) {
      let new_week = week_ini_time;
      const formattedWeekTimings = new_week.map((defaultData) => {
        console.log(ele.weekly);

        const defaultDay = defaultData?.timeRanges[0]?.day;

        const matchingData = ele?.weekly.filter(
          (data) => Number(data.day) === defaultDay
        );

        if (matchingData && matchingData.length > 0) {
          // Handle multiple matching data
          console.log(matchingData);
          let timeRanges = matchingData.map(
            ({ id, start_time_24, end_time_24 }) => ({
              start: start_time_24 || "",
              end: end_time_24 || "",
              id,
              day: defaultDay,
            })
          );

          console.log(timeRanges);

          return { timeRanges };
        } else {
          return defaultData;
        }
      });
      console.log(formattedWeekTimings);
      setWeekTimings(formattedWeekTimings);
    } else {
      console.log("hi");

      setWeekTimings(week_initial_time);
    }
    SetTransactionAble(true);
  }

  function disable() {
    SetTransactionAble(false);
  }

  const handleInputChange = (e: any) => {
    const searchValue = e.target.value;
    setFilterData(searchValue);

    // Clear previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      // Call your API with the search value
      SetPageNo(1);

      let send_data = {
        page_no: 1,
      };
      ProductList(outlet_id, searchValue, send_data);
      disable();
    }, 1000); // 5 milliseconds delay

    // Save the timeout ID
    setTimeoutId(newTimeoutId);
  };

  const handleTimeChange = (
    index: any,
    field: any,
    value: any,
    timing: any
  ) => {
    const newTimings = [...timings];
    newTimings[index][field] = value;
    console.log(timing);
    const { id, ...timingWithoutId } = newTimings[index];
    newTimings[index] = timingWithoutId;
    console.log(deleted_ids);
    if (id != undefined) {
      let updatedDeletedIds = [...deleted_ids, id];

      // Set the updated deleted_ids array using the SetDeletedIds function
      SetDeletedIds(updatedDeletedIds);
    }
    setTimings(newTimings);
    // console
  };

  const addTimingField = () => {
    setTimings([...timings, { start: "", end: "" }]);
  };

  function loadMore() {
    SetPageNo((prevalue: any) => {
      let pass_data = {
        sort: "",
        page_no: prevalue + 1,
      };
      console.log(filter_data);
      get_categories(pass_data, filter_data, selectedApplication);
      return prevalue + 1;
    });
  }

  const PaymentCard = () => {
    return (
      <div className="col-md-6 sm-mt-6 sm-pb-5 mb-3 px-sm-0 px-md-2">
     
   
          <>
            <InfiniteScroll
              dataLength={product_list.length}
              next={() => {
                let send_data = {
                  page_no: page_no,

                  load_more: true,
                };
                ProductList(outlet_id, filter_data, send_data);
              }}
              hasMore={next_page}
              loader={
                <div className="center m-5">
                  <div className="lds-dual-ring"></div>
                  <p className="text-prime small ps-2">Just a second ...</p>
                </div>
              }
            >
              {visible_content ?
              
            
          <div>
            {
          product_list.length != 0 ? (
              product_list?.map((ele, i) => (
                <div
                  onClick={() => {
                    enable(ele, i);
                    console.log("ele", ele);
                    console.log("i", i);
                    console.log("key", i);
                    SetSelectedDetails(i);
                  }}
                >
                  {/*  key={i} */}
                  <div
                    className={
                      transaction_able == true && selected_details == i
                        ? "active-shadow-layout radius  mb-2"
                        : "card radius mb-2"
                    }
                  >


         
                 
                      <div>
                        {console.log(ele.name)}
                        <div className="row">
                          <div className="col-3 p-0">
                            {ele.hasOwnProperty("images") &&
                            ele.images[0] != null ? (
                              <img
                                // src={"https://cdn.1superapp.com/images/320/" + data.image}

                                src={AppHelper.imageSrc(
                                  outer_data.image_base_url,
                                  ele.images[0].url,
                                  { format: "webp", width: 100 }
                                )}
                                className="prod-timing-image"
                              />
                            ) : (
                              <img
                                src={
                                  AppConfig.CDN_Image_url +
                                  "33c67a26ab58ada3ee31b91f7efb32bb.png"
                                }
                                className="prod-timing-image"
                              />
                            )}
                          </div>
                          <div className="col-9 p-2">
                            <div className="pb-1 d-flex align-items-center">
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "6c1258f33cc071c4c59f707e93326cc6.svg"
                                }
                                className="w-8px"
                              />
                              <p className="ps-1 text-black extra-small">
                                {ele?.category}
                              </p>
                              <div className="ms-auto">
                                <img
                                  src={
                                    AppConfig.CDN_Media_url +
                                    "71bf08539ef1d84421db0126a682630d.svg"
                                  }
                                  className="w-14px"
                                />
                              </div>
                            </div>
                            {/* <p className="text-black">Product Name: </p> */}
                            <p className="text-dark fw-bold"> {ele?.name}</p>

                            {/* <p className="text-black"> {ele?.category}</p> */}
                            {/* <div className="heading pb-2">Category:</div> */}

                            {ele?.description != null ? (
                              <div className="pt-2">
                                <p className="text-gray extra-small">
                                  {ele?.description}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      </div>
                      </div>
              ))) :
                        <div className="card h-100 w-100 p-3 text-center">
                          <img
                            src={
                              AppConfig.CDN_Image_url + "e38cbe608dba40c81ec58d266c09055f.png"
                            }
                            className="w-30 mx-auto"
                          />
                          <p className="text-gray mt-2">No product</p>
                        </div>
                        
                  
               
                 
                     }
                     </div>:(
          Array.from({ length: 5 }).map((_, i) => (
            <div key={i}>
              <div className="card radius mb-2">
                <div className="row">
                  <div className="col-3 p-0">
                    <Skeleton className="prod-timing-image" width={100} height={100} />
                  </div>
                  <div className="col-9 p-2">
                    <div className="pb-1 d-flex align-items-center">
                      <Skeleton width={8} height={8} className="w-8px" />
                      <p className="ps-1 text-black extra-small">
                        <Skeleton width={60} />
                      </p>
                      <div className="ms-auto">
                        <Skeleton width={14} height={14} className="w-14px" />
                      </div>
                    </div>
                    <p className="text-dark fw-bold">
                      <Skeleton width={120} />
                    </p>
                    <div className="pt-2">
                      <p className="text-gray extra-small">
                        <Skeleton count={3} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )))
                     }
          
            </InfiniteScroll>{" "}
          </>
 
      </div>
    );
  };

  const handleWeekTimeChange = (
    dayIndex: any,
    timeIndex: any,
    property: any,
    value: any
  ) => {
    const updatedWeekTimings = [...week_timings];
    updatedWeekTimings[dayIndex].timeRanges[timeIndex][property] = value;
    // newTimings[index][field] = value;
    console.log(week_timings);
    const { id, ...timingWithoutId } =
      updatedWeekTimings[dayIndex].timeRanges[timeIndex];
    console.log(timingWithoutId);
    updatedWeekTimings[dayIndex].timeRanges[timeIndex] = timingWithoutId;
    console.log(deleted_ids);
    if (id != undefined) {
      let updatedDeletedIds = [...deleted_ids, id];

      // Set the updated deleted_ids array using the SetDeletedIds function
      SetDeletedIds(updatedDeletedIds);
    }
    console.log(updatedWeekTimings);
    setWeekTimings(updatedWeekTimings);
  };

  const addWeekTimingField = (dayIndex: any) => {
    setWeekTimings((prevWeekTimings) => {
      const updatedWeekTimings = [...prevWeekTimings];
      updatedWeekTimings[dayIndex].timeRanges.push({
        start: "",
        end: "",
        day: dayIndex + 1,
      });
      return updatedWeekTimings;
    });
  };

  async function handleSubmit(i: any) {
    // Perform actions you want when the button is clicked
    console.log(i);
    let error = false;
    const updatedTimings = timings.filter(
      (timing) =>
        !timing.hasOwnProperty("id") && timing.start != "" && timing.end != ""
    );
    // const updatedWeekTimings = week_timings.filter(timing => !timing.hasOwnProperty('id'));

    timings.map((ele) => {
      if (
        (ele.start == "" && ele.end != "") ||
        (ele.start != "" && ele.end == "")
      ) {
        error = true;
      }

      if (ele.start != "" && ele.end != "" && ele.start > ele.end) {
        error = true;
      }
    });
    const updatedweekTimings = week_timings;

    const week: any = [];
    updatedweekTimings.map((element) => {
      console.log(element);

      element.timeRanges.map((subele) => {
        if (
          !subele.hasOwnProperty("id") &&
          subele.start != "" &&
          subele.end != ""
        ) {
          week.push(subele);
        }

        if (
          (subele.start == "" && subele.end != "") ||
          (subele.start != "" && subele.end == "")
        ) {
          error = true;
        }

        if (
          subele.start != "" &&
          subele.end != "" &&
          subele.start > subele.end
        ) {
          error = true;
        }
      });
    });

    console.log(updatedTimings);

    console.log(week_timings);
    console.log(deleted_ids);
    if (!error) {
      let pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: selectedApplication,
        },
        post: {
          daily_timing: updatedTimings,
          weekly_timing: week,
          id: id,
          delete_id: deleted_ids,
        },
      };

      console.log(pass_data);

      let response;
      if (props.type == "product_timing") {
        response = await api(
          "/brand/save_outlet_product_variant_timing",
          pass_data
        );
      } else if (props.type == "product_group_timing") {
        response = await api("/brand/save_outlet_product_timing", pass_data);
      }
      if (response.status_code == 200) {
        let ele = response.response.data[0];
        console.log(product_list);
        const updatedProductList = [...product_list];
        updatedProductList[i] = ele;
        console.log(updatedProductList);

        setProductList(updatedProductList);

        if (ele?.daily && ele?.daily.length > 0) {
          const formattedTimings = ele?.daily.map((dailyTiming) => ({
            start: dailyTiming.start_time_24,
            end: dailyTiming.end_time_24,
            id: dailyTiming.id,
          }));

          setTimings(formattedTimings);
        } else {
          const ini_time = [{ start: "", end: "" }];
          setTimings(ini_time);
        }
        const week_ini_time = [
          { timeRanges: [{ start: "", end: "", day: 1 }] },
          { timeRanges: [{ start: "", end: "", day: 2 }] },
          { timeRanges: [{ start: "", day: 3, end: "" }] },
          { timeRanges: [{ start: "", day: 4, end: "" }] },
          { timeRanges: [{ start: "", day: 5, end: "" }] },
          { timeRanges: [{ start: "", day: 6, end: "" }] },
          { timeRanges: [{ start: "", day: 7, end: "" }] },
        ];
        if (ele?.weekly && ele?.weekly.length > 0) {
          let new_week = week_ini_time;
          const formattedWeekTimings = new_week.map((defaultData) => {
            console.log(ele.weekly);

            const defaultDay = defaultData?.timeRanges[0]?.day;

            const matchingData = ele?.weekly.filter(
              (data) => Number(data.day) === defaultDay
            );

            if (matchingData && matchingData.length > 0) {
              // Handle multiple matching data
              console.log(matchingData);
              let timeRanges = matchingData.map(
                ({ id, start_time_24, end_time_24 }) => ({
                  start: start_time_24 || "",
                  end: end_time_24 || "",
                  id,
                  day: defaultDay,
                })
              );

              console.log(timeRanges);

              return { timeRanges };
            } else {
              return defaultData;
            }
          });
          console.log(formattedWeekTimings);
          setWeekTimings(formattedWeekTimings);
        } else {
          console.log("hi");

          setWeekTimings(week_initial_time);
        }

        toast.success("Saved");
      }
    } else {
      toast.error("Check the fields properly");
    }
  }

  function daily_delete(i: any) {
    setTimings((prevTimings) => {
      // Create a copy of the array
      const newTimings = [...prevTimings];

      console.log(newTimings.length);
      if (newTimings.length == 1) {
        newTimings.splice(i, 1);
        newTimings.push({ start: "", end: "" });
      } else {
        newTimings.splice(i, 1);
      }

      return newTimings;
    });
    const newTimings = [...timings];

    const { id, ...timingWithoutId } = newTimings[i];
    let updatedDeletedIds = [...deleted_ids, id];

    // Set the updated deleted_ids arraysetWeekTimings using the SetDeletedIds function
    SetDeletedIds(updatedDeletedIds);
  }

  function weekly_delete(dayIndex: any, timeIndex: any) {
    const updatedWeekTimings = [...week_timings];

    // Extract id and create a copy of the time range without id
    const { id, ...timingWithoutId }: any =
      updatedWeekTimings[dayIndex].timeRanges[timeIndex];
    let updatedDeletedIds: any = [...deleted_ids, id];

    // Set the updated deleted_ids array using the SetDeletedIds function
    SetDeletedIds(updatedDeletedIds);
    // Remove the time range from the array
    if (updatedWeekTimings[dayIndex].timeRanges.length == 1) {
      updatedWeekTimings[dayIndex].timeRanges.splice(timeIndex, 1);
      updatedWeekTimings[dayIndex].timeRanges.push({
        start: "",
        end: "",
        day: dayIndex + 1,
      });
    } else {
      updatedWeekTimings[dayIndex].timeRanges.splice(timeIndex, 1);
    }

    // Log the updated arrays for debugging
    console.log(updatedWeekTimings);
    console.log(deleted_ids);

    setWeekTimings(updatedWeekTimings);
  }

  return (
    <div className="">
      {/* Header */}
      <div className="head-bar row px-sm-0 px-md-3 pb-sm-2">
        <div className="col-md-4">
          <h6 className="  mb-2">Product Timing</h6>
        </div>

        <div className="col-md-6 sm-pt-2 end">
          {/* search */}
          <div className="input-icons form-group p-2">
            <input
              type="text"
              className="form-control search-bar p-0"
              placeholder="Search here..."
              value={filter_data}
              onChange={handleInputChange}
            />
            <div className="cursor px-2 border-left">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "c241785c497fb98f5f7d4e587b3857ba.svg"
                }
                className="w-20px"
              />
            </div>
          </div>
        </div>
        <div className="col-md-2 sm-mt-2">
          <div className="form-group d-flex align-items-center ps-0">
            {/* <label for="exampleFormControlSelect1">Example select</label> */}
            {console.log(appliction)}
            <select
              data-show-content="true"
              className="form-control product-timing-location"
              id="exampleFormControlSelect1"
              value={selectedApplication}
              onChange={handleApplicationChange}
            >
              {outlets?.map((ele, i) => (
                <option key={i} value={ele?.id}>
                  {ele?.name}
                </option>
              ))}
            </select>
            <div className=" ms-auto pe-2">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "6b8041f390f1ce51def0c7659b39a408.svg"
                }
                className="w-10px"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3 px-md-3 px-sm-0 mb-5">
        <div className="row">
          {width < 768 && transaction_able == false ? (
            <PaymentCard />
          ) : width > 768 ? (
            <PaymentCard />
          ) : null}

          {transaction_able && (
            <div className="col-md-6  sm-pb-5 sm-mt-6 px-sm-0 px-md-2">
              <div className="overflowY-90vh pb-5 position-sticky-top-50px">
                <div className="card bg-fff p-3">
                  <div className="d-flex align-items-center mb-3">
                    <div
                      className="cursor pe-3"
                      onClick={() => {
                        disable();
                      }}
                    >
                      <MaterialCommunityIcons
                        name="arrow-left"
                        size={20}
                        color="black"
                      />
                    </div>
                    <h6 className="mb-0">Product Timing Details</h6>
                  </div>

                  <div className="border-bottom-gray pb-2">
                    <div className="row">
                      <div className="col-3 p-0">
                        {transaction_details.hasOwnProperty("images") &&
                        transaction_details.images[0] != null ? (
                          <img
                            // src={"https://cdn.1superapp.com/images/320/" + data.image}

                            src={AppHelper.imageSrc(
                              outer_data.image_base_url,
                              transaction_details.images[0].url,
                              { format: "webp", width: 100 }
                            )}
                            className="prod-timing-image"
                          />
                        ) : (
                          <img
                            src={
                              AppConfig.CDN_Image_url +
                              "33c67a26ab58ada3ee31b91f7efb32bb.png"
                            }
                            className="prod-timing-image"
                          />
                        )}
                      </div>
                      <div className="col-9 p-2">
                        <div className="pb-1 d-flex align-items-center">
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "0b7cc24da5bed6522cfcd959414a7a0e.svg"
                            }
                            className="w-8px"
                          />
                          <p className="ps-1 text-black extra-small">
                            {transaction_details?.category}
                          </p>
                        </div>
                        {/* <p className="text-black">Product Name: </p> */}
                        <p className="text-dark fw-bold">
                          {" "}
                          {transaction_details?.name}
                        </p>

                        {/* <p className="text-black"> {transaction_details?.category}</p> */}
                        {/* <div className="heading pb-2">Category:</div> */}

                        {transaction_details?.description != null ? (
                          <div className="pt-2">
                            <p className="text-gray extra-small">
                              {transaction_details?.description}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-lg-6 col-md-4 pb-3">
                      <div className="heading pb-2">Product Name:</div>
                      <div className="value">
                        {console.log(transaction_details)}
                        <p>{transaction_details?.name}</p>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-4 pb-3">
                      <div className="heading pb-2">Product Description:</div>
                      <div className="value">
                        <p> {transaction_details?.description}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-4 pb-3">
                      <div className="heading pb-2">Product Category:</div>
                      <div className="value">
                        <p>{transaction_details?.category}</p>
                      </div>
                    </div>
                  </div> */}

                  <h6 className="mt-3 pt-1">Timing</h6>
                  <div>
                    <div className="py-2">
                      <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          checked={radio_val == "1"}
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          onChange={(e) => diff_time_disable(e)}
                          value={1}
                        />
                        <label
                          className="form-check-label text-black"
                          htmlFor="flexRadioDefault1"
                        >
                          Available all the time
                        </label>
                      </div>

                      <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          checked={radio_val == "2"}
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          onChange={(e) => diff_time_disable(e)}
                          value={2}
                        />
                        <label
                          className="form-check-label text-black"
                          htmlFor="flexRadioDefault2"
                        >
                          Available at particular time
                        </label>
                      </div>

                      <div className="ps-3">
                        {diff_time && (
                          <div className="">
                            <div className="mb-3 px-2 pt-3 bg-lightprime radius">
                              <div className="d-flex align-items-center pb-3">
                                <p className="text-dark fw-bold">
                                  Same time for all days of the week
                                </p>
                              </div>

                              <div className="row align-items-center">
                                <div className="col-10 row p-0">
                                  <div className="col-6 ps-0">
                                    <p className="ps-2 pb-1 text-darkgray">
                                      Start Time
                                    </p>
                                  </div>
                                  <div className="col-6 ps-0">
                                    <p className="ps-2 pb-1 text-darkgray">
                                      End Time
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {timings.map((timing, index) => (
                                <div className="pb-3">
                                  <div className="row align-items-center">
                                    <div className="col-5 ps-0">
                                      {/* <p className="ps-2 pb-1">Start Time</p> */}
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={timing.start}
                                        onChange={(e) =>
                                          handleTimeChange(
                                            index,
                                            "start",
                                            e.target.value,
                                            timing
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col-5 ps-0">
                                      {/* <p className="ps-2 pb-1">End Time</p> */}
                                      <input
                                        className="form-control"
                                        type="time"
                                        value={timing.end}
                                        onChange={(e) =>
                                          handleTimeChange(
                                            index,
                                            "end",
                                            e.target.value,
                                            timing
                                          )
                                        }
                                        min={timing.start}
                                        required
                                      />
                                    </div>
                                    <div className="col-1 p-0">
                                      <button
                                        className="btn btn-text text-gray px-0"
                                        onClick={() => {
                                          daily_delete(index);
                                        }}
                                      >
                                        <img
                                          src={
                                            AppConfig.CDN_Media_url +
                                            "42db3e197b6a62fd0e26f3e3ad362049.svg"
                                          }
                                          className="w-14px"
                                        />
                                        {/* x */}
                                      </button>
                                    </div>
                                    {index == timings.length - 1 && (
                                      <div className="col-1  ps-0 pe-1">
                                        <button
                                          className="btn btn-text text-prime fw-bold px-0"
                                          onClick={addTimingField}
                                        >
                                          <img
                                            src={
                                              AppConfig.CDN_Media_url +
                                              "d0b386cb4931d229a69c0315316342b0.svg"
                                            }
                                            className="w-20px"
                                          />
                                        </button>
                                      </div>
                                    )}{" "}
                                  </div>
                                  {timing.start != "" &&
                                    timing.end != "" &&
                                    timing.start >= timing.end && (
                                      <p className="extra-small p-2 text-red">
                                        End time should be greater than start
                                        time
                                      </p>
                                    )}
                                </div>
                              ))}
                            </div>
                            <div className="mb-3 p-2 bg-lightprime radius pt-3">
                              {console.log(week_timings)}
                              <p className="text-dark fw-bold ">
                                Different times during different days of the
                                week
                              </p>

                              {week_timings.map((weekTiming, dayIndex) => (
                                <div key={dayIndex} className="mt-4">
                                  {console.log(weekTiming?.timeRanges)}
                                  <div className="d-flex align-items-center pb-2">
                                    <p className="text-dark fw-bold ps-2">
                                      {weekTiming &&
                                        weekTiming.timeRanges &&
                                        weekTiming.timeRanges.length > 0 &&
                                        weekTiming.timeRanges[0]?.day === 1 &&
                                        "Monday"}
                                      {weekTiming.timeRanges[0].day === 2 &&
                                        "Tuesday"}
                                      {weekTiming.timeRanges[0].day === 3 &&
                                        "Wednesday"}
                                      {weekTiming.timeRanges[0].day === 4 &&
                                        "Thursday"}
                                      {weekTiming.timeRanges[0].day === 5 &&
                                        "Friday"}
                                      {weekTiming.timeRanges[0].day === 6 &&
                                        "Saturday"}
                                      {weekTiming.timeRanges[0].day === 7 &&
                                        "Sunday"}
                                    </p>
                                    {/* <div className="ms-auto">
                                  <button
                                    className="btn btn-text text-prime fw-bold"
                                    onClick={() => addWeekTimingField(dayIndex)}
                                  >
                                    Add Another Field
                                  </button>
                                </div> */}
                                  </div>
                                  <div className="row align-items-center">
                                    <div className="col-10 row p-0">
                                      <div className="col-6 ps-0">
                                        <p className="ps-2 pb-1 text-darkgray">
                                          Start Time
                                        </p>
                                      </div>
                                      <div className="col-6 ps-0">
                                        <p className="ps-2 pb-1 text-darkgray">
                                          End Time
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {weekTiming.timeRanges.map(
                                    (timeRange, timeIndex) => (
                                      <div className="" key={timeIndex}>
                                        <div className="row align-items-center pb-2">
                                          <div className="col-5 ps-0">
                                            <input
                                              type="time"
                                              className="form-control"
                                              value={timeRange.start}
                                              onChange={(e) =>
                                                handleWeekTimeChange(
                                                  dayIndex,
                                                  timeIndex,
                                                  "start",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-5 ps-0">
                                            <input
                                              type="time"
                                              className="form-control"
                                              value={timeRange.end}
                                              onChange={(e) =>
                                                handleWeekTimeChange(
                                                  dayIndex,
                                                  timeIndex,
                                                  "end",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-1 p-0">
                                            <button
                                              className="btn btn-text text-gray mt-2 px-0"
                                              onClick={() => {
                                                weekly_delete(
                                                  dayIndex,
                                                  timeIndex
                                                );
                                              }}
                                            >
                                              <img
                                                src={
                                                  AppConfig.CDN_Media_url +
                                                  "42db3e197b6a62fd0e26f3e3ad362049.svg"
                                                }
                                                className="w-14px"
                                              />
                                              {/* x */}
                                            </button>
                                          </div>
                                          {timeIndex ==
                                            weekTiming.timeRanges.length -
                                              1 && (
                                            <div className="col-1  ps-0 pe-1">
                                              <button
                                                className="btn btn-text text-prime fw-bold px-0 mt-2"
                                                onClick={() =>
                                                  addWeekTimingField(dayIndex)
                                                }
                                              >
                                                <img
                                                  src={
                                                    AppConfig.CDN_Media_url +
                                                    "d0b386cb4931d229a69c0315316342b0.svg"
                                                  }
                                                  className="w-20px"
                                                />
                                              </button>
                                            </div>
                                          )}{" "}
                                        </div>
                                        {timeRange.start != "" &&
                                          timeRange.end != "" &&
                                          timeRange.start >= timeRange.end && (
                                            <p className="extra-small p-2 text-red">
                                              End time should be greater than
                                              start time
                                            </p>
                                          )}
                                      </div>
                                    )
                                  )}
                                </div>
                              ))}
                              <div className="center p-2">
                                <button
                                  className="btn bg-prime text-white  mt-3 ms-auto"
                                  onClick={() => handleSubmit(pro_index)}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {!diff_time && (
                        <div className="center p-2">
                          <button
                            className="btn bg-prime text-white  mt-3 ms-auto"
                            onClick={() => Save(pro_index)}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="form-check">
  <input className="form-check-input" type="radio"

checked={!available_diff_time}

onClick={() => diff_time_disable}
  name="exampleRadios" id="exampleRadios1" value="option1" cheked/>
  <label className="form-check-label" for="exampleRadios1">
    Default radio
  </label>
</div>
<div className="form-check">
  <input className="form-check-input" type="radio" name="exampleRadios" 
     onClick={() => diff_time_able}

      checked={available_diff_time}

  id="exampleRadios2" value="option2"/>
  <label className="form-check-label" for="exampleRadios2">
    Second default radio
  </label>
</div> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
