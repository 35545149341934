import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
// import ProductAssigningComponent from "../../../components/ProductAssigningComponent/ProductAssigningComponent";
import ProductAssigningAvailableComponent from "../../../components/ProductAssigningAvailableComponent/ProductAssigningAvailableComponent";

export default function ProductAssign(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext()
  const [id_value, SerIdValue] = useState(useParams());

  useEffect(() => {
    console.log("ProductAssign On mount :", props);
    console.log("ProductAssign context :", context);
    console.log("ProductAssign OutletContext :", OutletContext);

  }, []);

  return (
    <div>
      <ProductAssigningAvailableComponent
        is_menu_open={OutletContext}
        type={"assign"}
        title="Products Assigning"
      />
    </div>
  );
}
