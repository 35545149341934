import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { api } from "../../../utils/Api";
import {
  Feather,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import ModalVariants from "../../Brands/Products/ModalVariants";
import ModalCreateAddonsInventory from "./ModalCreateAddonsInventory";
import Skeleton from "react-loading-skeleton";
import { AppConfig } from "../../../config";

export default function OutletAddonsInventory(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [grid_view, SetGridView] = useState(false);
  const [list_view, SetListView] = useState(true);
  const [id_value, SerIdValue] = useState(useParams());
  const [next_page, SetNextPage] = useState(false);
  const [count, SetCount] = useState(10);
  const [width, SetWidth] = useState(innerWidth);
  const [page_no, SetPageNo] = useState(1);
  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    product_name: "",
    product_id: "",
    product_checked: "",
    data: {},
  });
  const [inventory_option_modal, SetInventoryOptionModal] = useState({
    is_open: false,
    batches: {},
    currencies: {},
    inventory_types: {},
    mrp: {},
  });
  const [product_data, SetProductData] = useState([]);
  const [product_details, SetProductDetails] = useState([]);
  const [data, SetData] = useState([]);
  const [is_loading, SetIsLoading] = useState(false);

  useEffect(() => {
    console.log("Products On mount :", props);
    console.log("Products context :", context);
    get_products(null);
  }, []);

  async function available_inventory(el_id: any) {
    let available_arr = [];
    // let unavailable_arr = [];

    available_arr.push(el_id);
    // product_data.map((ele: any) => (
    //   ele.id != el_id  &&
    //   (unavailable_arr.push(ele.id))
    //   ))

    console.log(available_arr);
    // console.log(unavailable_arr);

    // available_arr.push(id);
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },

      post: {
        available: available_arr,
        // unavailable:unavailable_arr,
      },
    };
    let response: any = await api(
      "/outlet/addon_inventory_available",
      pass_data
    );

    if (response.status_code == 200) {
      get_products(1);
    }
  }

  async function unavailable_inventory(el_id: any) {
    let unavailable_arr = [];
    unavailable_arr.push(el_id);
    // available_arr.push(id);
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
      post: {
        unavailable: unavailable_arr,
      },
    };
    let response: any = await api(
      "/outlet/addon_inventory_available",
      pass_data
    );
    if (response.status_code == 200) {
      get_products(1);
    }
  }

  async function get_products(default_page: any) {
    SetIsLoading(true);
    console.log(id_value);
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        // page: page_no,

        addon_id: id_value.addons_id,
      },
    };

    if (default_page != null) {
      pass_data.get.page = default_page;
      SetPageNo(default_page);
    } else {
      pass_data.get.page = page_no;
    }
    let response: any = await api("/outlet/view_addon_inventory", pass_data);
    console.log("/brand/brand_products :", response.response.data);
    console.log("/brand/brand_products :", response.response);

    if (response.status_code == 200) {
      SetIsLoading(false);
      SetData(response.response);
      if (response.response.hasOwnProperty("data")) {
        SetProductData(response.response.data);

        SetProductDetails(response.response.addon_details);
        SetNextPage(response.response.next_page);
      } else {
        SetProductData([]);
      }
    }
  }

  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    console.log(id_value);
    pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        page: page_no_value,

        product_id: id_value.product_id,
      },
    };
    let response: any = await api("/outlet/view_product_inventory", pass_data);
    console.log("/brand/brand_products :", response.response.data);
    console.log("/brand/brand_products :", response.response);

    if (response.status_code == 200) {
      SetData(response.response);

      if (response.response.hasOwnProperty("data")) {
        SetProductData((prevValue) => {
          response.response.data.map((item) => {
            prevValue.push(item);
          });
          return [...prevValue];
        });
        SetProductDetails(response.response.product_details);
        SetNextPage(response.response.next_page);
      } else {
        SetProductData([]);
      }
    }
    SetNextPage(response.response.next_page);
  }

  return (
    <div className="">
      <Toaster position="top-center" reverseOrder={false} />
      <div
        className={OutletContext ? "head-bar sticky-sm" : "head-bar sticky-sm "}
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className=" row align-items-center">
          <div className="col-md-3 col-6 px-0">
            <div className="d-flex">
              <div
                className="ps-1 pe-2 cursor"
                onClick={() => {
                  window.history.go(-1);
                }}
              >
                <MaterialCommunityIcons
                  name="arrow-left"
                  size={18}
                  color="black"
                />
              </div>
              <div>
                <h6 className="mb-1">Addons</h6>
              </div>
            </div>
          </div>
          {/* create Inventory */}
          {!is_loading && (
            <div className="text-end col-md-9 col-6 pe-sm-0 ">
                    {context.app_data.pages[32] && 
              <button
                className="btn bg-fff radius text-prime btn-sm ps-3 pe-0 ms-auto fw-bold"
                onClick={() => {
                  // console.log("add variants");
                  // SetModalVariants({
                  //   // prevValue.is_open = true;
                  //   is_open: true,
                  //   product_name: ele.name,
                  //   product_id: ele.id,
                  //   product_checked: ele.checked,

                  //   data: ele.variants,
                  //   type: "edit",

                  //   // return { ...prevValue }
                  // });

                  console.log("SetEditUserModal :");
                  SetInventoryOptionModal((prevValue: any) => {
                    prevValue.is_open = true;
                    prevValue.batches = data.options.batches;
                    prevValue.currencies = data.options.currencies;
                    prevValue.inventory_types = data.options.inventory_types;
                    prevValue.mrp = data.options.mrp;
                    return { ...prevValue };
                  });
                }}
              >
                Create Inventory
              </button>
}
            </div>
          )}
        </div>
        {is_loading ? (
          //  Skeleton start
          <div className="d-flex p-2 align-items-center">
            <div className="">
              <Skeleton width={14} height={14} />
            </div>
            <div className="ps-2">
              <Skeleton width={200} height={10} />
            </div>
          </div>
        ) : (
          // Skeleton end
          <div className="d-flex align-items-center p-2">
            <img
              src={AppConfig.CDN_Media_url+"867ba9660b93599267117a65f8adc5e3.svg"}
              className="w-14px"
            />
            <p className="mb-0 text-dark fw-bold ps-2 small">
              {product_details?.name}
            </p>
          </div>
        )}
      </div>

      <div
        className={
          OutletContext ? "mt-3 px-md-3 px-sm-0 " : "mt-3 px-md-3 px-sm-0 mb-5"
        }
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="row">
          {is_loading ? (
            <div className="col-md-6 col-lg-4">
              {/* Skeleton start */}
              <div className="card px-2 py-3 mb-3">
                <div className="ps-0">
                  <div className="row">
                    <div className="col-4">
                      <Skeleton height={10} width={70} />

                      <div className="mt-2">
                        <Skeleton height={10} width={30} />
                      </div>
                    </div>
                    <div className="col-4">
                      <Skeleton height={10} width={50} />

                      <div className="mt-2">
                        <Skeleton height={10} width={70} />
                      </div>
                    </div>

                    <div className="col-4">
                      <Skeleton height={10} width={80} />

                      <div className="mt-2">
                        <Skeleton height={16} width={30} borderRadius={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Skeleton end */}
            </div>
          ) : product_data.length > 0 ? (
            product_data.map((ele: any, i) => (
              <div
                className={
                  OutletContext == false
                    ? "col-md-6 col-lg-4"
                    : "col-md-6 col-lg-4"
                }
              >
                <div
                  className="card py-3 px-2 mb-2"
                  // onClick={(event) => {
                  //     if (width < 768) {
                  //         console.log("width", width);
                  //         // SetSelectedData({
                  //         //     value: data,
                  //         //     i: u_index,
                  //         //     type: "active",
                  //         //   });
                  //         sample_fun("bottom", true, event);
                  //     }
                  // }}
                >
                  <div className="row align-items-center mb-2">
                    {/* <div className="col-10"> */}
                    {/* <h6 className="mb-1">{ele.name}</h6> */}
                    {/* <h6 className="mb-1">Based on</h6> */}

                    {/* <small>{ele.category}</small> */}
                  </div>
                  {/* <div className="col-2 text-end">
                    <FontAwesome name="circle" size={14} color="#18b901" />
                  </div> */}
                  {/* </div> */}

                  <div className="ps-0">
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black fw-bold">Based On</p>
                        <p className="mt-2 text-darkgray fw-500 v-small">
                          {ele.inventory_type}
                        </p>
                      </div>
                      <div className="col-4">
                        <p className="text-black fw-bold ">Value</p>
                        <p className="mt-2 text-darkgray fw-500 v-small ">
                          {ele.inventory_type_id == 1
                            ? ele.mrp.currency_symbol + " " + ele.mrp.amount
                            : ele.batch_number}
                        </p>
                      </div>
                      {context.app_data.pages[33] && 
                      <div className="col-4">
                        <p className="text-black fw-bold">Availability</p>
                        <div className="form-check form-switch mt-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={"id" + i}
                            checked={!ele.unavailable}
                            onClick={() => {
                              ele.unavailable
                                ? available_inventory(ele.id)
                                : unavailable_inventory(ele.id);
                            }}
                          />
                        </div>
                      </div>
}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="center p-4 bg-fff">
              <div>
              <img
                    src={
                      AppConfig.CDN_Image_url +
                      "e38cbe608dba40c81ec58d266c09055f.png"
                    }
                    className="empty-img"
                  />
                <p className="mt-2 text-gray text-center">No Addons</p>
              </div>
            </div>
          )}
        </div>

        {next_page ? (
          <p
            className="text-prime fw-bold text-center my-2 cursor"
            onClick={() => {
              load_more();
            }}
          >
            Load More
          </p>
        ) : null}
      </div>
      <ModalCreateAddonsInventory
        open={inventory_option_modal.is_open}
        batches={inventory_option_modal.batches}
        currencies={inventory_option_modal.currencies}
        inventory_types={inventory_option_modal.inventory_types}
        mrp={inventory_option_modal.mrp}
        close={(data: any) => {
          console.log("Variants modl on close :", data);
          // SetModalVariants((prevValue: any) => {
          //     prevValue.is_open = false;
          //     return { ...prevValue }
          // })
          SetInventoryOptionModal((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.batches = {};
            prevValue.currencies = {};
            prevValue.inventory_types = {};
            prevValue.mrp = {};
            return { ...prevValue };
          });

          if (data.action == "create") {
            get_products(null);
            toast.success("Inventory Created");
          }
        }}
      />
    </div>
  );
}
