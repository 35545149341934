import React, { useState, useEffect } from "react";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { omit } from "lodash";
import { api } from "../../../utils/Api";
// import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const initial_value = {
  user: "",
  username: "",
  user_id: "",
  email: "",
  mobile: "",
  whatsapp: "",
  address: "",
  roles: { roles: [], custom_roles: [] },
};

let myModal = {};

const animatedComponents = makeAnimated();

export default function ModelViewUsers(props: any) {
  const [save_data, SetSaveData] = useState(initial_value);
  const [text, setText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [id_value, SerIdValue] = useState(useParams());
  const [page, SetPage] = useState(1);
  const [search, SetSerach] = useState("");
  const [errors, setErrors] = useState({});
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [is_read_only, SetIsReadOnaly] = useState(true);
  const [userOpen, setUserOpen] = useState(false);
  const [user_role, SetUserRole] = useState({});
  const [timer, SetTimer] = useState(null);
  const [next_page, SetNextPage] = useState(false);
  const [user_data, SetUserData] = useState([]);
  const WAIT_INTERVAL = 700;

  const [selected_system_role, SetSelectedSystemRole] = useState([]);
  const [selected_custom_role, SetSelectedCustomRole] = useState([]);

  useEffect(() => {
    console.log("ModelViewUsers On mount :", props);
    let myModal1 = document.getElementById("ViewServiceModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      console.log("event :", event);
      setUserOpen(false);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.data.is_open) {
      console.log("ModelViewUsers On mount :", props);
      get_user_role();
      // get_users();

      myModal = new bootstrap.Modal(
        document.getElementById("ViewServiceModal"),
        {}
      );
      myModal.show();
      let value_data = {
        user: props.data.record.user,
        username: props.data.record.username,
        user_id: props.data.record.id,
        email: props.data.record.hasOwnProperty("email")
          ? props.data.record.email
          : "",
        mobile: props.data.record.hasOwnProperty("mobile")
          ? props.data.record.mobile
          : "",
        whatsapp: props.data.record.hasOwnProperty("whatsapp")
          ? props.data.record.whatsapp
          : "",
        address: props.data.record.hasOwnProperty("address")
          ? props.data.record.address
          : "",
        roles: {
          roles: [],
          custom_roles: [],
        },
      };
      SetSaveData(value_data);
      if (props.data.type == "view") {
        SetIsReadOnaly(true);
      } else {
        SetIsReadOnaly(false);
      }
      console.log("props type:", value_data);
    }
  }, [props.data.is_open]);

  async function save(event: any) {
    if (event) event.preventDefault();

    if (
      Object.keys(errors).length === 0 &&
      Object.keys(save_data).length !== 0
    ) {
      console.log("Form Values :", save_data);
      let post_data = {
        user_id: save_data.user_id,
        mobile: save_data.mobile,
        whatsapp: save_data.whatsapp,
        email: save_data.email,
        address: save_data.address,
        roles: save_data.roles,
      };
      let pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: id_value.outlet_id,
        },
        post: post_data,
      };
      let response = await api("/outlet/edit_user", pass_data);
      console.log("User edit post response :", response);
      let item = close_data;
      item.action = "edit";
      item.value = response.response.outlet_users[0];
      item.index = props.data.record_index;
      console.log("Close data : ", item);
      SetCloseData(item);
      SetSaveData(initial_value);
      myModal.hide();
    } else {
      alert("There is an Error!");
    }
  }

  async function get_user_role() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
    };
    let response = await api("/outlet/get_roles", pass_data);
    console.log("get user role response :", response);
    let rs_roles = props.data.record.roles;
    let rc_roles = props.data.record.custom_roles;
    let s_roles = response.response.roles;
    let c_roles = response.response.custom_roles;
    if (s_roles != undefined && rs_roles != undefined) {
      let s_res = s_roles.filter((item) =>
        rs_roles.find((element) => element.role_id == item.value)
      );
      SetSelectedSystemRole(s_res);
      let role_data = [];
      s_res.map((ele) => {
        role_data.push(ele.value);
      });
      SetSaveData((prevValue) => {
        let value_data = {
          ...prevValue,
          roles: {
            roles: role_data,
            custom_roles: prevValue.roles.custom_roles,
          },
        };
        return value_data;
      });
    }
    if (c_roles != undefined && rc_roles != undefined) {
      let c_res = c_roles.filter((item) =>
        rc_roles.find((element) => element.role_id == item.value)
      );
      SetSelectedCustomRole(c_res);
      let role_data = [];
      c_res.map((ele) => {
        role_data.push(ele.value);
      });
      SetSaveData((prevValue) => {
        let value_data = {
          ...prevValue,
          roles: {
            roles: prevValue.roles.roles,
            custom_roles: role_data,
          },
        };
        return value_data;
      });
    }
    SetUserRole(response.response);
  }

  function set(name: any, value: any, event: any) {
    // event.persist();
    validate(event, name, value);

    if (name == "system_roles") {
      let old_data = save_data;
      let role_data = [];
      value.map((e) => {
        role_data.push(e.value);
      });
      old_data.roles.roles = role_data;
      SetSaveData(old_data);
    } else if (name == "custom_roles") {
      let old_data = save_data;
      let role_data = [];
      value.map((e) => {
        role_data.push(e.value);
      });
      old_data.roles.custom_roles = role_data;
      SetSaveData(old_data);
    } else {
      SetSaveData((oldValues) => ({ ...oldValues, [name]: value }));
    }
  }

  const validate = (event: any, name: any, value: any) => {
    //A function to validate each input values
    console.log("name :", name);
    console.log("value  :", value);
    switch (name) {
      case "username":
        if (value.length <= 2) {
          // we will set the error state

          setErrors({
            ...errors,
            username: "User name atleast have 3 letters",
          });
        } else {
          // set the error state empty or remove the error for username input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "username");
          setErrors(newObj);
        }
        break;

      case "email":
        if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "Enter a valid email address",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      case "mobile":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            mobile: "mobile should contains 10 numbers",
          });
        } else {
          let newObj = omit(errors, "mobile");
          setErrors(newObj);
        }
        break;

      case "whatsapp":
        if (!new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/).test(value)) {
          setErrors({
            ...errors,
            whatsapp: "whatsapp should contains 10 numbers",
          });
        } else {
          let newObj = omit(errors, "whatsapp");
          setErrors(newObj);
        }
        break;

      case "address":
        if (value.length <= 0) {
          setErrors({
            ...errors,
            address: "Address not vaild",
          });
        } else {
          let newObj = omit(errors, "address");
          setErrors(newObj);
        }
        break;

      case "system_roles":
        if (value.length <= 0 || value == {} || value == "") {
          setErrors({
            ...errors,
            system_roles: "System roles not vaild",
          });
        } else {
          let newObj = omit(errors, "system_roles");
          setErrors(newObj);
        }
        break;

      case "custom_roles":
        if (value.length <= 0 || value == {} || value == "") {
          setErrors({
            ...errors,
            custom_roles: "Custom roles not vaild",
          });
        } else {
          let newObj = omit(errors, "custom_roles");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  function userOpenSelect() {
    if (!is_read_only) {
      setUserOpen(true);
    }
  }

  function onTextChanged(e: any) {
    const value = e.target.value;
    let suggestions: any = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = usernames.sort().filter((v) => regex.test(v));
    }
    // setState(() => ({suggestions, text: value}));
    setSuggestions(suggestions);
    setText(value);
    console.log("Text onchange: ", value);
  }

  function suggestionSelected(value: any) {
    // this.setState(() => ({
    //   text: value,
    //   suggestions: []
    // }));
    setSuggestions([]);
    setText(value);
  }

  function handleChange(event: any) {
    clearTimeout(timer);
    let value = event.target.value;
    console.log("value :", value);

    if (value == "") {
      get_users();
    } else {
      SetTimer(
        setTimeout(() => {
          triggerSearch();
        }, WAIT_INTERVAL)
      );
    }
  }

  async function get_users() {
    console.log("hfd");
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        search: search,
        page: page,
      },
    };

    let response = await api("/brand/get_users", pass_data);

    console.log("get_super_users response :", response);
    // if (response.status_code == 200) {
    console.log("status code 200 ");
    let new_user_data = response.response.data;
    SetUserData(new_user_data);
    console.log("get_super_users :", user_data);

    // }

    // if (response.response.hasOwnProperty("data")) {
    //   let new_page_value;
    //   let new_user_data = save_data.user;

    //   if (type == "new") {
    //     new_user_data = response.response.data;
    //     if (response.response.next_page) {
    //       new_page_value = page_no + 1;
    //     } else {
    //       new_page_value = page_no;
    //     }
    //   } else {
    //     response.response.data.map((ele: any, index: any) => {
    //       new_user_data.push(ele);
    //     });
    //     if (response.response.next_page) {
    //       new_page_value = page_no + 1;
    //     } else {
    //       new_page_value = page_no;
    //     }
    //   }

    // this.setState({
    //   user_data: new_user_data,
    //   next_page: response.response.next_page,
    //   page_no: new_page_value,
    // });
    //   SetPage(new_page_value);
    //   SetNextPage(response.response.next_page);

    // } else {
    // toast.error("Unable to get super user");
    // toast.dismiss();
    // }
  }

  async function triggerSearch() {
    console.log("triggerSearch data :");
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        search: search,
        page: page,
      },
    };

    let response = await api("/brand/get_users", pass_data);

    console.log("get_super_users response :", response);
    if (response.response.status_code == 200) {
      let new_user_data = response.response.data;
      SetUserData(new_user_data);
    }

    // let page_no = 1;
    // let search = save_data.search_name;
    // let check_access = "";

    // const response = await Api.get_super_users(
    //   m_id,
    //   page_no,
    //   search,
    //   check_access
    // );

    // console.log("get_super_users response :", response);
    // if (response.response.hasOwnProperty("data")) {
    //   this.setState({
    //     user_data: response.response.data,
    //   });
    // } else {
    //   toast.error("Unable to get super user");
    //   toast.dismiss();
    // }
  }

  return (
    <div
      className="modal fade"
      id="ViewServiceModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-sm-down">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="  modal-title" id="ModalLabel">
              {is_read_only ? "User Details" : "Edit Service"}
            </h6>
            <button
              type="button"
              className="btn-close"
              // data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                SetSaveData(initial_value);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <form className="g-3">
              {userOpen ? (
                <div className="row">
                  <div className="col-12">
                    <label>Select user</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select User Name"
                      // value={text}
                      // onChange={(e) => { onTextChanged(e), set("user", e, e) }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    // required
                    // readOnly={is_read_only}
                    />
                  </div>

                  <h6 className="mt-3">Available Users</h6>
                  <div className="mt-2">
                    {/* {suggestions.map((item, sugIndex) => <p className="cursor" key={sugIndex} onClick={() => {
                      suggestionSelected(item);
                      SetSaveData(oldValues => ({ ...oldValues, user: item }))
                      setUserOpen(false);
                      console.log("Select item:", item);
                    }}><i className="fas fa-user"></i> {item}</p>)} */}
                    {user_data.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          console.log("On Select :", item);
                        }}
                      >
                        <i className="fas fa-user"></i> {item.name}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12 col-md-6 ">
                    <label className="form-label">User Name</label>
                    <div className="cursor">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select User Name"
                        value={save_data.user}
                        required
                        readOnly={true}
                      />
                      {errors.username && (
                        <small className="text-red">{errors.username}</small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mt-3 mt-md-0">
                    <label className="form-label">Whatsapp Number</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fab fa-whatsapp"></i>
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        value={save_data.whatsapp}
                        onChange={(e) => {
                          set("whatsapp", e.target.value, e);
                        }}
                        required
                        readOnly={is_read_only}
                      />
                      {errors.whatsapp && (
                        <small className="text-red">{errors.whatsapp}</small>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mt-3">
                    <label className="form-label">Mobile Number</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="fas fa-phone-alt"></i>
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        value={save_data.mobile}
                        onChange={(e) => {
                          set("mobile", e.target.value, e);
                        }}
                        required
                        readOnly={is_read_only}
                      />
                      {errors.mobile && (
                        <small className="text-red">{errors.mobile}</small>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mt-3">
                    <label className="form-label">Email Address</label>
                    <input
                      type="email"
                      className="form-control"
                      value={save_data.email}
                      onChange={(e) => {
                        set("email", e.target.value, e);
                      }}
                      required
                      readOnly={is_read_only}
                    />
                    {errors.email && (
                      <small className="text-red">{errors.email}</small>
                    )}
                  </div>

                  <div className="col-12 col-md-6 mt-3">
                    <label className="form-label">System roles</label>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      placeholder="Select User roles"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isMulti
                      value={selected_system_role}
                      options={user_role.roles}
                      onChange={(e) => {
                        SetSelectedSystemRole(e);
                        set("system_roles", e, e);
                      }}
                      isDisabled={is_read_only}
                    />
                    {/* {
                      errors.user_role.roles && <small className="text-red">{errors.system_roles}</small>
                    } */}
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <label className="form-label">Custom roles</label>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      placeholder="Select User roles"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isMulti
                      value={selected_custom_role}
                      options={user_role.custom_roles}
                      onChange={(e) => {
                        SetSelectedCustomRole(e);
                        set("custom_roles", e, e);
                      }}
                      isDisabled={is_read_only}
                    />
                    {/* {
                      errors.user_role.custom_roles && <small className="text-red">{errors.user_role.custom_roles}</small>
                    } */}
                  </div>

                  <div className="col-12 mt-3">
                    <label className="form-label">Address</label>
                    <textarea
                      className="form-control"
                      rows={2}
                      value={save_data.address}
                      onChange={(e) => {
                        set("address", e.target.value, e);
                      }}
                      required
                      readOnly={is_read_only}
                    />
                    {errors.address && (
                      <small className="text-red">{errors.address}</small>
                    )}
                  </div>

                  {/* <h6 className="mt-4">Salery Baciss</h6>
                      <div className="col-12 col-md-6 mt-2">
                        <label htmlFor="salaryAmount" className="form-label">Salery Amount</label>
                        <div className="input-group">
                          <span className="input-group-text">₹</span>
                          <input type="number" className="form-control" id="salaryAmount" defaultValue={save_data.salaryAmount} required onChange={(e) => {
                            set('salaryAmount', e.target.value, e)
                          }} />
                        </div>
                      </div>

                      <h6 className="mt-4">Work Baciss</h6>
                      <div className="col-12 mt-3">
                        <label htmlFor="userRole" className="form-label">Select Services</label>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          placeholder="Select Services"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isMulti
                          options={services}
                          onChange={(e) => {
                            serviceSelect(e)
                          }}
                        // onChange={(e)=>{
                        //   set('roles', e);
                        // }}
                        />
                      </div>
                      {save_data.workservices.map((service, index) => (
                        <div className="col-md-6 mt-2" key={index}>
                          {service.type == "Item wise" ? (
                            <div>
                              <label htmlFor="amount" className="form-label"><b className="text-dark pr-2">{service.value}</b> <small className="text-gray">Item Wise Amount</small></label>
                              <div className="input-group">
                                <span className="input-group-text">₹</span>
                                <input type="number" className="form-control" id="amount" defaultValue={service.amount} required onChange={(e) => {
                                  console.log("hggf :", e);
                                  // set('amount', e);
                                  let data = values;
                                  data.workservices[index].amount = e.target.value;
                                  setValues(data);

                                }} />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {service.type == "Duration Wise" ? (
                            <div>
                              <label htmlFor="amount" className="form-label"><b className="text-dark pr-2">{service.value}</b> <small className="text-gray">Duration Wise Amount</small></label>
                              <div className="input-group">
                                <span className="input-group-text">₹</span>
                                <input type="number" className="form-control" id="amount" defaultValue={service.amount} required onChange={(e) => {
                                  console.log("hggf :", e);
                                  // set('amount', e);
                                  let data = values;
                                  data.workservices[index].amount = e.target.value;
                                  setValues(data);

                                }} />
                              </div>
                            </div>
                          ) : ("")}

                        </div>
                      ))} */}
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-dark-outline btn-sm px-3 btn-radius"
              // data-bs-dismiss="modal"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                SetSaveData(initial_value);
                myModal.hide();
              }}
            >
              Close
            </button>
            {!is_read_only ? (
              <button
                type="button"
                className="btn bg-prime text-white fw-bold btn-sm px-3"
                onClick={(e) => {
                  save(e);
                }}
              >
                Save
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
